import React, { useState } from 'react'
import ExpandMore from '@material-ui/icons/ExpandMore'
import ExpandLess from '@material-ui/icons/ExpandLess'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CircleIcon from '@material-ui/icons/Lens';
import { PartnerProfileListResponse } from '../../../../model/response/partner-profile';
import { DEFAULT_FRONT } from '../../../../api/conf';
type Props = {
    parnerProfileList: PartnerProfileListResponse;
    success: boolean;
    submitted: boolean;
    setParnerProfileListChange: (parnerProfileList: PartnerProfileListResponse) => void;
    hasSummaryLink?: boolean
}

const PartnerKnowledgeList = (props: Props) => {
    const [expand, setExpand] = useState(true);
    const frontUrlSummary = DEFAULT_FRONT + "/partner/summary-suggestion";
    const handleOpenSummary = () => {
        sessionStorage.setItem("publicAccessCode", JSON.stringify(props.parnerProfileList.publicAccessCode))
        sessionStorage.setItem("summaryState", "COMPLETED");
        window.open(frontUrlSummary,"_blank")
    }
    return (
        <React.Fragment>
            <div className="text-left" style={{ position: "relative", marginBottom: 5 }}>
                <div className='d-flex yellow-button' style={{ padding: "15px", borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}>
                    {((expand === undefined) || (expand === false)) &&
                        <div onClick={(e) => setExpand(true)}><ExpandMore className="pointer mr-2" ></ExpandMore></div>
                    }
                    {(expand === true) &&
                        <div onClick={(e) => setExpand(false)}><ExpandLess className="pointer mr-2" ></ExpandLess></div>
                    }
                    <label className='ml-2 my-auto'><span style={{ fontWeight: 600, marginRight: 20 }}>รายละเอียดที่ระบบแนะนำหลักสูตรและสื่อความรู้</span></label>
                </div>
                {(expand === true) && (
                    <div style={{ padding: "15px", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px", border: "3px solid #fab232" }}>
                        <div>
                            {props.hasSummaryLink && ( <label className='ml-2 my-auto'><span style={{ marginRight: 20 }}>ดูรายละเอียดหลักสูตรและสื่อความรู้ : <a className='link' onClick={() => handleOpenSummary()} >{frontUrlSummary}</a> </span></label>)}
                           
                            <table className='table mt-3' style={{ fontSize: "20px" }}>
                                <thead className='black-button'>
                                    <tr>
                                        <th style={{ width: '50%' }}>ประเภทหัวข้อที่สนใจ</th>
                                        <th style={{ width: '50%' }}>หมวดหมู่หัวข้อที่สนใจ</th>
                                    </tr>
                                </thead>
                                <tbody className='light-gray-button'>
                                    {props.parnerProfileList?.knowledgeList && props.parnerProfileList?.knowledgeList.map(x => (
                                        <tr key={'knowledge' + x.categoryId}>
                                            <td>{x.categoryName}</td>
                                            <td>
                                                {x.subCategories && x.subCategories.map(subCat => (
                                                    <div key={"subCat" + subCat.subCategoryId}>
                                                        {subCat.checked && <CheckCircleIcon fontSize="small" style={{ color: '#4ba31e' }} />}
                                                        {subCat.subCategoryName}
                                                    </div>
                                                ))}
                                                {!x.subCategories || x.subCategories.length == 0 && (
                                                    <div>
                                                        <CircleIcon fontSize="small" style={{ color: '#707070' }} />
                                                        ยังไม่สนใจ
                                                    </div>
                                                )}

                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
            </div>
        </React.Fragment>
    )
}

export default PartnerKnowledgeList