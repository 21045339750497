import { MenuState,MenuActionInterface, MenuActionType } from "./types";

const initialState: MenuState = {
    collapsed: false,
};

export function MenuReducer(state = initialState, action: MenuActionInterface): MenuState {
    switch (action.type) {
        case MenuActionType.MENU : 
            return {
                ...state,
                collapsed: action.collapsed
            }    
        default: 
            return state;
    }
}
