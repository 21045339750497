import { BrowserRouter as Router, Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SignIn from './admin/signin';
import GetToken from './admin/gettoken';
import SearchClass from './admin/searchclass';
import EditClass from './admin/editclass';
import EditStudent from './admin/editstudent';
import EditDoc from './admin/editdoc';
import EditAssignment from './admin/editassignment';
import Assignment from './admin/assignment';
import EditHistory from './admin/edithistory';
import EditResult from './admin/editresult';
import EditElearning from './admin/editelearning';

import Partner from './admin/searchpartner';
import EditPartner from './admin/editpartner';

import SearchTrainer from './admin/searchtrainer';
import Trainer from './admin/edittrainer';

import EditProjectDoc from './admin/editprojectdoc';

import SearchSurveyGroup from './admin/searchsurvey';
import EditSurveyGroup from './admin/editsurveygroup';

import Survey from './survey/survey';
import AdminLogout from './admin/logout';
import AccessDenied from './admin/accessdenied';

import StudentGetToken from './student/studentgettoken';
import StudentResult from './student/studentresult';
import StudentAssignment from './student/studentassignment';
import StudentLanding from './student/studentlanding';
import StudentLogout from './student/logout';
import StudentRedirect from './student/studentredirect';

import { getIsLoggedIn, getIsAdmin, getIsStudent } from "../store/auth/selectors";
import EToolKitAssignment from './e-toolkit/etoolkitassignment';
import EditProjectInfo from './admin/editprojectinfo';
import { EditSurvey } from './admin/editSurvey';
import EditResultNumber from './admin/editResultNumber';
import TestUpload from './share/TestUpload';
import AnnounceManage from './admin/annouceManage/announceManage';
import StudentCourse from './student/studentcourse/studentcourse';
import StudentCoursePagination from './student/studentcourse/studentcoursePagination';
import TrainerSearchClass from './trainer/TrainerSearchClass';
import TrainerEditAssignment from './trainer/TrainerEditassignment';
import TrainerAssignment from './trainer/TrainerAssignment';
import ManageSurvey from './admin/manageSurvey';
import EditSurveyQuestion from './admin/editSurveyQuestion';
import EditProjectGroupInfo from './admin/editProjectGroupInfo';
import PartnerMain from './partner/PartnerMain';
import PartnerChooseTopic from './partner/PartnerChooseTopic';
import PartnerSummaryChooseTopic from './partner/PartnerSummaryChooseTopic';
import PartnerSummarySuggestion from './partner/partnerSummarySuggestion/PartnerSummarySuggestion';
import PartnerRegistForm from './partner/partnerRegistForm/PartnerRegistForm';
import PartnerThankyou from './partner/PartnerThankyou';
import PartnerOtp from './partner/PartnerOtp';
import ManageKnowledge from './admin/manageKnowledge/ManageKnowledge';
import PartnerThankyouNotInterest from './partner/PartnerThankyouNotInterest';
import LogRequest from './admin/logRequest/LogRequest';
import LogRequestHistory from './admin/logRequest/LogRequestHistory';
import ManagePartner from './admin/managePartner/ManagePartner';
import PartnerThankyouVerfyEmail from './partner/PartnerThankyouVerifyEmail';
import MergePartnerSearch from './admin/mergePartner/MergePartnerSearch';
import MergePartnerToDatabase from './admin/mergePartner/MergePartnerToDatabase';
import CreatePartnerToDatabase from './admin/mergePartner/CreatePartnerToDatabase';
import PartnerFillMasterData from './partner/partnerFillMasterData/PartnerFillMasterData';
import PartnerFillMasterDataOtp from './partner/partnerFillMasterData/PartnerFillMasterDataOtp';
import PartnerSearch from './admin/partner/PartnerSearch';
import PartnerProfile from './admin/partner/component/PartnerProfile';
import PartnerActivityOrganizeTraining from './admin/partner/component/PartnerActivityOrganizeTraining';
import PartnerPackage from './admin/partner/component/PartnerPackage';
import PartnerFillMasterDataThankyou from './partner/partnerFillMasterData/component/PartnerFillMasterDataThankyou';
import PartnerActivityReceiveMedia from './admin/partner/component/PartnerActivityReceiveMedia';
import PartnerThankyouVerifyEmailPartnerProfile from './partner/PartnerThankyouVerifyEmailPartnerProfile';
import PartnerFillMasterDataValidateLink from './partner/partnerFillMasterData/component/PartnerFillMasterDataValidateLink';

const ScreenNavigators = () => {
    const isLoggedIn = useSelector(getIsLoggedIn);
    const isAdmin = useSelector(getIsAdmin);
    const isStudent = useSelector(getIsStudent);
    const { pathname,search } = useLocation();
    const isRedirect = new URLSearchParams(search).get('isRedirect');
    const getRedirectLocation = (): boolean => {
        return pathname.includes('admin');
    }
    return (<>
        <Router>
            <Switch>
                <Route exact path='/admin/search/class'>
                    {isLoggedIn ? <SearchClass /> : <Redirect to="/admin" />}
                </Route>
                <Route exact path='/admin/class/:id/student'>
                    {isLoggedIn ? <EditStudent /> : <Redirect to="/admin" />}
                </Route>
                <Route exact path='/admin/class/:id/doc'>
                    {isLoggedIn ? <EditDoc /> : <Redirect to="/admin" />}
                </Route>
                <Route exact path='/admin/class/:id/assignment'>
                    {isLoggedIn ? <EditAssignment /> : <Redirect to="/admin" />}
                </Route>
                <Route exact path='/admin/assignment/:id/:student_id'>
                    {isLoggedIn ? <Assignment /> : <Redirect to="/admin" />}
                </Route>
                <Route exact path='/admin/class/:id/elearning/:class_course_id'>
                    {isLoggedIn ? <EditElearning /> : <Redirect to="/admin" />}
                </Route>
                <Route exact path='/admin/class/:id/history'>
                    {isLoggedIn ? <EditHistory /> : <Redirect to="/admin" />}
                </Route>
                <Route exact path='/admin/class/:id/result'>
                    {isLoggedIn ? <EditResult /> : <Redirect to="/admin" />}
                </Route>
                <Route exact path='/admin/class/:id/result-number'>
                    {isLoggedIn ? <EditResultNumber /> : <Redirect to="/admin" />}
                </Route>
                <Route exact path='/admin/class/:id/survey'>
                    {isLoggedIn ? <EditSurvey /> : <Redirect to="/admin" />}
                </Route>
                <Route exact path='/admin/class/:id'>
                    {isLoggedIn ? <EditClass /> : <Redirect to="/admin" />}
                </Route>
                <Route exact path='/admin/class'>
                    {isLoggedIn && isAdmin ? <EditClass /> : <Redirect to="/admin" />}
                </Route>

                {/* <Route exact path='/admin/search/partner'>
                    {isLoggedIn && isAdmin ? <Partner /> : <Redirect to="/admin" />}
                </Route>
                <Route exact path='/admin/partner/:id'>
                    {isLoggedIn && isAdmin ? <EditPartner /> : <Redirect to="/admin" />}
                </Route> */}
                <Route exact path='/admin/search/partner'>
                    {isLoggedIn && isAdmin ? <PartnerSearch /> : <Redirect to="/admin" />}
                </Route>
                <Route exact path='/admin/partner/profile/:id'>
                    {isLoggedIn && isAdmin ? <PartnerProfile /> : <Redirect to="/admin" />}
                </Route>
                <Route exact path='/admin/partner/activity/:id/organize-training'>
                    {isLoggedIn && isAdmin ? <PartnerActivityOrganizeTraining /> : <Redirect to="/admin" />}
                </Route>
                <Route exact path='/admin/partner/activity/:id/receive-media'>
                    {isLoggedIn && isAdmin ? <PartnerActivityReceiveMedia /> : <Redirect to="/admin" />}
                </Route>
                <Route exact path='/admin/partner/package/:id'>
                    {isLoggedIn && isAdmin ? <PartnerPackage /> : <Redirect to="/admin" />}
                </Route>
                <Route exact path='/admin/announce/info'>
                    {isLoggedIn && isAdmin ? <AnnounceManage /> : <Redirect to="/admin" />}
                </Route>
                {/* <Route exact path='/admin/partner'>
                    {isLoggedIn ? <EditPartner /> : <Redirect to="/admin" />}
                </Route> */}
                <Route exact path='/admin/manage-knowledge'>
                    {isLoggedIn && isAdmin ? <ManageKnowledge /> : <Redirect to="/admin" />}
                </Route>
                <Route exact path='/admin/log-request'>
                    {isLoggedIn && isAdmin ? <LogRequest /> : <Redirect to="/admin" />}
                </Route>
                <Route exact path='/admin/log-request/:id'>
                    {isLoggedIn && isAdmin ? <ManagePartner /> : <Redirect to="/admin" />}
                </Route>
                <Route exact path='/admin/log-request-history'>
                    {isLoggedIn && isAdmin ? <LogRequestHistory /> : <Redirect to="/admin" />}
                </Route>
                <Route exact path='/admin/log-request/merge-partner/:logRequestId/:orgTypeId/:orgGroupId/:partnerName'>
                    {isLoggedIn && isAdmin ? <MergePartnerSearch /> : <Redirect to="/admin" />}
                </Route>
                <Route exact path='/admin/log-request/merge-partner/convert-data/:logRequestId/:orgTypeId/:orgGroupId/:partnerName/:partnerId'>
                    {isLoggedIn && isAdmin ? <MergePartnerToDatabase /> : <Redirect to="/admin" />}
                </Route>
                <Route exact path='/admin/log-request/merge-partner/convert-data/:logRequestId/:orgTypeId/:orgGroupId/:partnerName'>
                    {isLoggedIn && isAdmin ? <CreatePartnerToDatabase /> : <Redirect to="/admin" />}
                </Route>

                <Route exact path='/admin/search/trainer'>
                    {isLoggedIn && isAdmin ? <SearchTrainer /> : <Redirect to="/admin" />}
                </Route>
                <Route exact path='/admin/trainer/:id'>
                    {isLoggedIn && isAdmin ? <Trainer /> : <Redirect to="/admin" />}
                </Route>
                <Route exact path='/admin/trainer'>
                    {isLoggedIn && isAdmin ? <Trainer /> : <Redirect to="/admin" />}
                </Route>

                <Route exact path='/admin/search/survey'>
                    {isLoggedIn && isAdmin ? <SearchSurveyGroup /> : <Redirect to="/admin" />}
                </Route>
                <Route exact path='/admin/manage/survey'>
                    {isLoggedIn && isAdmin ? <ManageSurvey /> : <Redirect to="/admin" />}
                </Route>
                <Route exact path='/admin/survey_group/:id'>
                    {isLoggedIn && isAdmin ? <EditSurveyGroup /> : <Redirect to="/admin" />}
                </Route>
                <Route exact path='/admin/survey_group'>
                    {isLoggedIn && isAdmin ? <EditSurveyGroup /> : <Redirect to="/admin" />}
                </Route>
                <Route exact path='/admin/survey_question/:id'>
                    {isLoggedIn && isAdmin ? <EditSurveyQuestion /> : <Redirect to="/admin" />}
                </Route>
                <Route exact path='/admin/survey_question'>
                    {isLoggedIn && isAdmin ? <EditSurveyQuestion /> : <Redirect to="/admin" />}
                </Route>
                <Route exact path='/admin/survey/:group_id/preview' component={Survey} />
                <Route exact path='/admin/survey/:group_id/class/:class_id/page/:page/preview' component={Survey} />
                <Route exact path='/admin/survey/:group_id/class/:class_id/page/:page' component={Survey} />
                <Route exact path='/admin/survey/:group_id/class/:class_id' component={Survey} />
                <Route exact path='/admin/survey/:group_id/class_course/:class_course_id/preview' component={Survey} />
                <Route exact path='/admin/survey/:group_id/class_course/:class_course_id' component={Survey} />
                <Route exact path='/admin/survey/:group_id/class_course_session/:class_course_session_id' component={Survey} />

                <Route exact path='/admin/project/doc'>
                    {isLoggedIn && isAdmin ? <EditProjectDoc /> : <Redirect to="/admin" />}
                </Route>
                <Route exact path='/admin/project/info'>
                    {isLoggedIn && isAdmin ? <EditProjectInfo /> : <Redirect to="/admin" />}
                </Route>
                <Route exact path='/admin/project-group/info'>
                    {isLoggedIn && isAdmin ? <EditProjectGroupInfo /> : <Redirect to="/admin" />}
                </Route>
                <Route exact path='/admin/etoolkit/assignment/:document_id'>
                    {isLoggedIn && isAdmin ? <EToolKitAssignment /> : <Redirect to="/admin" />}
                </Route>

                <Route exact path='/admin/login' component={SignIn} />
                <Route exact path='/admin/get-token' component={GetToken} />
                <Route exact path='/admin/access-denied' component={AccessDenied} />
                <Route exact path='/admin/logout' component={AdminLogout} />
                <Route exact path='/admin/test/upload' component={TestUpload} />
                <Route exact path='/admin' component={SignIn} />

                <Route exact path='/student/landing'>
                    <StudentLanding />
                </Route>
                <Route exact path='/student/result'>
                    {isLoggedIn && isStudent ? <StudentResult /> : <StudentRedirect pathname={pathname} isRedirect={isRedirect}/>}
                </Route>
                <Route exact path='/student/assignment/:class_id'>
                    {isLoggedIn && isStudent ? <StudentAssignment /> : <StudentLanding pathname={pathname} isRedirect={isRedirect}/>}
                </Route>

                <Route exact path='/student/course'>
                    {isLoggedIn && isStudent ? <StudentCourse /> : <StudentLanding pathname={pathname} isRedirect={isRedirect}/>}
                </Route>
                <Route exact path='/student/course/:assignmentType'>
                    {isLoggedIn && isStudent ? <StudentCoursePagination /> : <StudentLanding pathname={pathname} isRedirect={isRedirect}/>}
                </Route>

                <Route exact path='/student/setmember/callback' component={StudentGetToken} />
                <Route exact path='/student/logout' component={StudentLogout} />
                <Route exact path='/student/login'><StudentRedirect /></Route>

                <Route exact path='/student/survey/:group_id/class/:class_id/page/:page' component={Survey}/>
                <Route exact path='/student/survey/:group_id/class_course/:class_course_id' component={Survey} />

                <Route exact path='/student/etoolkit/assignment/:document_id'>
                    {isLoggedIn && isStudent ? <EToolKitAssignment /> : <StudentRedirect pathname={pathname} isRedirect={isRedirect}/>}
                </Route>
                <Route exact path='/trainer/search/class'>
                    {isLoggedIn ? <TrainerSearchClass /> : <StudentLanding pathname={pathname} isRedirect={isRedirect}/>}
                </Route>
                <Route exact path='/trainer/etoolkit/assignment/:document_id'>
                    {isLoggedIn ? <EToolKitAssignment /> : <StudentLanding pathname={pathname} isRedirect={isRedirect}/>}
                </Route>
                <Route exact path='/trainer/class/:id/assignment'>
                    {isLoggedIn ? <TrainerEditAssignment /> : <StudentLanding pathname={pathname} isRedirect={isRedirect}/>}
                </Route>
                <Route exact path='/trainer/assignment/:id/:student_id'>
                    {isLoggedIn ? <TrainerAssignment /> : <StudentLanding pathname={pathname} isRedirect={isRedirect}/>}
                </Route>
                <Route exact path='/partner/otp/:userKey' component={PartnerOtp} />
                <Route exact path='/partner/choose-topic' component={PartnerChooseTopic} />
                <Route exact path='/partner/summary-choose-topic' component={PartnerSummaryChooseTopic} />
                <Route exact path='/partner/summary-suggestion' component={PartnerSummarySuggestion} />
                <Route exact path='/partner/summary-suggestion' component={PartnerSummarySuggestion} />
                <Route exact path='/partner/regist-form' component={PartnerRegistForm} />
                <Route exact path='/partner/thank' component={PartnerThankyou} />
                <Route exact path='/partner/thankNotInterest' component={PartnerThankyouNotInterest} />
                <Route exact path='/partner/verify-email/:userKey/:refCode/:otp' component={PartnerThankyouVerfyEmail}/>
                <Route exact path='/partner/verify-email/partner-profile/:userKey/:refCode/:otp' component={PartnerThankyouVerifyEmailPartnerProfile}/>
                <Route exact path='/partner/fill-master/otp/:userKey/:code/:key' component={PartnerFillMasterDataValidateLink} />
                <Route exact path='/partner/fill-master/verify-otp/:userKey/:code/:key' component={PartnerFillMasterDataOtp} />
                <Route exact path='/partner/fill-master/getDetail/:userKey/:code/:key' component={PartnerFillMasterData} />
                <Route exact path='/partner/fill-master/thank' component={PartnerFillMasterDataThankyou} />
                <Route exact path='/partner' component={PartnerMain} />
                <Route path='/partner' component={PartnerMain} >
                    <Redirect to="/partner" />
                </Route>
                <Route>
                    <Redirect to="/student/landing" />
                </Route>
            </Switch>
        </Router></>
    )
}

export default ScreenNavigators;
