import React, { useEffect, useRef, useState } from 'react'
import { Button, InputGroup, Modal, ModalProps } from 'react-bootstrap';
import { SingleValue } from 'react-select';
import { AnnouncePageableResponse } from '../../../../model/response/announce.model';
import { ProjectGroupResponse } from '../../../../model/response/project.model';
import { SingleSearch } from '../../../tab/search-component';
import { ThaiDateTimePicker } from '../../../tab/thai-datetime-picker';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { Util } from '../../../../api/util';
import { AnnouncementManageRequest } from '../../../../model/request/announce.model';

type Props = ModalProps & {
    onOk: (value: any) => void;
    projectGroupList: ProjectGroupResponse[];
    error: string | null;
    editItem: AnnouncePageableResponse | undefined;
}

const AnnounceManageModal = (props: Props) => {
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [id, setId] = useState<number>();
    const [topic, setTopic] = useState<string | undefined>();
    const [description, setDescription] = useState<string | undefined>();
    const [oldSection, setOldSection] = useState<string>();
    const [section, setSection] = useState<SingleValue<{ label: string, value: string }>>();
    const [projectGroup, setProjectGroup] = useState<SingleValue<{ label: string, value: number }>>();
    const [fromDate, setFromDate] = useState<any>();
    const [toDate, setToDate] = useState<any>();
    const [status, setStatus] = useState<string>();
    const [externalLink,setExternalLink] = useState<string>();

    const sectionList = [
        { label: 'Announcement', value: 'ANNOUNCEMENT' },
        { label: 'Top Banner', value: 'BANNER' }
    ]
    const bannerRef = useRef(null);
    const [fileBanner, setfileBanner] = useState();
    useEffect(() => {
        setSubmitted(false);
        if (props.show && props.editItem) {
            setId(props.editItem.id);
            setTopic(props.editItem.topic);
            setDescription(props.editItem.description);
            setOldSection(props.editItem.section);
            setExternalLink(props.editItem.externalLink);
            for (let x of sectionList) {
                if (x.value == props.editItem!.section) {
                    setSection({ label: x.label, value: x.value })
                    break;
                }
            }
            for (let x of props.projectGroupList) {
                if (x.id == props.editItem.projectGroupId) {
                    setProjectGroup({ label: x.name, value: x.id })
                }
            }
            if (props.editItem.fromDate) {
                setFromDate(new Date(props.editItem.fromDate));
            } else {
                setFromDate(null);
            }
            if (props.editItem.toDate) {
                setToDate(new Date(props.editItem.toDate));
            } else {
                setToDate(null)
            }

            setStatus(props.editItem.status);
            setfileBanner(undefined);

        } else {
            setId(undefined);
            setTopic(undefined);
            setDescription(undefined)
            setOldSection(undefined);
            setSection(undefined);
            setProjectGroup(undefined);
            setExternalLink(undefined);
            setFromDate(null);
            setToDate(null);
            setStatus('ACTIVE');
            setfileBanner(undefined);
            if (bannerRef && bannerRef.current) (bannerRef.current as any).value = null;

        }
    }, [props.show])
    const handleSubmit = () => {
        setSubmitted(true);
        if (checkValidField()) {
            const announcementManageRequest: AnnouncementManageRequest = {
                file: fileBanner,
                announcementRequest: {
                    id: id,
                    topic: topic,
                    description: description,
                    section: section ? section.value : undefined,
                    oldSection: oldSection,
                    fromDate: fromDate ? Util.getLocalISOString(fromDate) : undefined,
                    toDate: toDate ? Util.getLocalISOString(toDate) : undefined,
                    status: status,
                    projectGroupId: projectGroup ? projectGroup.value : undefined,
                    externalLink: externalLink
                }
            }
            props.onOk(announcementManageRequest)
        }
    }
    const checkValidField = (): boolean => {
        if (checkRequire(topic) ||
            checkRequire(description) ||
            checkRequire(section) ||
            checkRequireFile(fileBanner) ||
            checkRequire(status) ||
            checkRequire(fromDate)) {
            return false
        }
        return true;
    }
    const createProjectGroupList = () => {
        var res: any[] = [];
        for (var i = 0; i < props.projectGroupList.length; i++) {
            res.push({ label: props.projectGroupList[i].name, value: props.projectGroupList[i].id });
        }
        return res;
    }
    const getErrorRequire = (value: any) => {
        return submitted && !value ? true : false;
    }
    const getErrorRequireFile = (value: any) => {
        if (props.editItem) {
            return submitted && !value && !props.editItem.url ? true : false;
        } else {
            return submitted && !value ? true : false;
        }

    }

    const checkRequire = (value: any) => {
        return !value ? true : false;
    }
    const checkRequireFile = (value: any) => {
        if (props.editItem) {
            return !value && !props.editItem.url ? true : false;
        } else {
            return !value ? true : false;
        }

    }
    return (
        <Modal
            {...props}
            size='xl'
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    {props.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form>
                    {props?.editItem && <div className="row col-12 mb-4 ml-2">
                        <img src={props?.editItem.url} height={150} />
                    </div>}
                    <div className="row col-12">
                        <div className="col-3" style={{ marginTop: "auto", marginBottom: "auto" }}>
                            <label className="form-check-label">
                                ชื่อหัวข้อประชาสัมพันธ์ <span className="red">*</span>
                            </label>
                        </div>
                        <div className="col-6" style={{ marginTop: "auto", marginBottom: "auto" }}>
                            <input type="text" className={'form-control ' + ((getErrorRequire(topic)) ? "is-invalid" : "")} value={topic} onChange={e => setTopic(e.target.value)} />
                        </div>
                    </div>
                    <div className="row col-12 mt-4">
                        <div className="col-3" style={{ marginBottom: "auto" }}>
                            <label className="form-check-label">
                                รายละเอียดเพิ่มเติม <span className="red">*</span>
                            </label>
                        </div>
                        <div className="col-6" style={{ marginTop: "auto", marginBottom: "auto" }}>
                            <textarea rows={3} className={'form-control ' + ((getErrorRequire(description)) ? "is-invalid" : "")} value={description} onChange={e => setDescription(e.target.value)} />
                        </div>
                    </div>
                    <div className="row col-12 mt-4">
                        <div className="col-3" style={{ marginBottom: "auto" }}>
                            <label className="form-check-label">
                                External Link
                            </label>
                        </div>
                        <div className="col-6" style={{ marginTop: "auto", marginBottom: "auto" }}>
                        <input type="text" className={'form-control'} value={externalLink} onChange={e => setExternalLink(e.target.value)} />
                        </div>
                    </div>
                    <div className="row col-12 mt-4">
                        <div className="col-3" style={{ marginTop: "auto", marginBottom: "auto" }}>
                            <label className="form-check-label">
                                กลุ่มโครงการ
                            </label>
                        </div>
                        <div className="col-6" style={{ marginTop: "auto", marginBottom: "auto" }}>
                            <SingleSearch
                                isSearchable={true} isMulti={false} placeholder=""
                                onChange={(id: string, e: any) => setProjectGroup(e)}
                                value={projectGroup}
                                options={createProjectGroupList()}
                                style={{ width: "100%" }}
                            />
                        </div>
                    </div>
                    <div className="row col-12 mt-4">
                        <div className="col-3" style={{ marginBottom: "auto" }}>
                            <label className="form-check-label">
                                Section <span className="red">*</span>
                            </label>
                        </div>
                        <div className="col-3" style={{ marginTop: "auto", marginBottom: "auto" }}>
                            <SingleSearch
                                isError={getErrorRequire(section)}
                                isSearchable={true} isMulti={false} placeholder=""
                                onChange={(id: string, e: any) => setSection(e)}
                                value={section}
                                options={sectionList}
                                style={{ width: "100%" }}
                            />
                        </div>
                        <div className="col-auto" style={{ marginTop: "auto", marginBottom: "auto" }}>
                            <label className="form-check-label">
                                Banner
                                {(section && section.value == 'BANNER') ? <span> (1140 x 340 px)</span> : <span> (480 x 270 px)</span>}
                                <span className="red"> *</span>
                            </label>
                        </div>
                        <div className="col" style={{ marginTop: "auto", marginBottom: "auto" }}>
                            <input type="file"
                                accept="image/jpeg, image/png"
                                className={'form-control ' + ((getErrorRequireFile(fileBanner)) ? "is-invalid" : "")}
                                ref={bannerRef}
                                onChange={(e: any) => {
                                    setfileBanner(e.target.files[0]);
                                }}
                            />

                        </div>
                    </div>

                    <div className='row col-12 mt-4'>
                        <div className="col-3">
                            <label className="form-check-label">
                                วันที่เริ่มประชาสัมพันธ์ <span className="red">*</span>
                            </label>
                        </div>
                        <div className='col-3'>
                            <InputGroup hasValidation>
                                <label  className='position-relative'>
                                    <ThaiDateTimePicker className={"form-control " + ((getErrorRequire(fromDate)) ? "is-invalid" : "")} selected={fromDate} onChange={(e: any) => setFromDate(e)} />
                                    <div className="search-icon" style={{ visibility: (getErrorRequire(fromDate)) ? "hidden" : "visible", zIndex: 0 }}>
                                        <CalendarTodayIcon style={{ color: "gray", position: "absolute", right: "10px", top: "5px" }} />
                                    </div>
                                </label>
                            </InputGroup>
                        </div>
                        <div className="col-auto">
                            <label className="form-check-label">
                                วันที่สิ้นสุด
                            </label>
                        </div>
                        <div className='col'>
                            <InputGroup hasValidation>
                                <label  className='position-relative'>
                                    <ThaiDateTimePicker className={"form-control "} selected={toDate} onChange={(e: any) => setToDate(e)} />
                                    <div className="search-icon" style={{ zIndex: 0 }}>
                                        <CalendarTodayIcon style={{ color: "gray", position: "absolute", right: "10px", top: "5px" }} />
                                    </div>
                                </label>
                            </InputGroup>
                        </div>
                    </div>
                    <div className='row col-12 mt-4'>
                        <div className="col-3">
                            <label className="form-check-label">
                                สถานะ <span className="red">*</span>
                            </label>
                        </div>
                        <div className="col-2 ml-4" style={{ marginTop: "auto", marginBottom: "auto" }}>
                            <input className={"form-check-input " + ((getErrorRequire(status)) ? "is-invalid" : "")} type="radio" checked={status === 'ACTIVE'} onClick={e => { setStatus('ACTIVE') }} />
                            <label className="form-check-label">
                                ใช้งาน
                            </label>
                        </div>
                        <div className="col-2" style={{ marginTop: "auto", marginBottom: "auto" }}>
                            <input className={"form-check-input "+ ((getErrorRequire(status)) ? "is-invalid" : "") } type="radio" checked={status === 'INACTIVE'} onClick={e => { setStatus('INACTIVE') }} />
                            <label className="form-check-label">
                                ไม่ใช้งาน
                            </label>
                        </div>
                    </div>

                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.onHide}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default AnnounceManageModal