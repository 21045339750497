export interface MenuState {
    collapsed: boolean
}

export enum MenuActionType {
    MENU = "MENU",
}

export interface MenuActionInterface {
    type: MenuActionType,
    collapsed: boolean
}
