import { useEffect, useState } from 'react';
import { useDispatch, useStore } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Util } from '../../api/util';
import MainMenu from './menu';
import Footer from './adminfooter';
import { Button, Modal, Table } from 'react-bootstrap';
import loadinglogo from '../../img/loading.gif';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '@material-ui/icons/SearchOutlined';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { createPage } from '../tab/pagetab';
import { listsurveygroup, deletesurveygroup, duplicateSurveyGroup, listSurveyQuestion, listQuestionGroup, previewSurveyQuestion } from '../../store/surveygroup/actions';
import { queryclear } from '../../store/search/actions';
import { NOT_FOUND_LABEL } from '../../api/label';
import SurveyTab from './surveyTab';
import { SingleSearch } from '../tab/search-component';
import { SurveyQuestion } from '../../model/response/survey.model';
import PreviewQuestionModal from './previewQuestionModal';


type Props = {}

const ManageSurvey = (props: Props) => {
  const [init, setInit] = useState(1)
  const {questionGroupSearch,keywordSearch,pageSearch,pageSizeSearch} = window.sessionStorage.getItem("searchManageQuestion")? JSON.parse(window.sessionStorage.getItem("searchManageQuestion") as any):"{questionGroupSearch:'',keywordSearch:'',pageSearch:'',pageSizeSearch:''}" as any;
  const [questionGroup, setQuestionGroup] = useState(questionGroupSearch?questionGroupSearch:"")
  const [keyword, setKeyword] = useState(keywordSearch?keywordSearch:"");
  const [page, setPage] = useState(pageSearch?pageSearch:0)
  const [limit, setLimit] = useState(pageSizeSearch?pageSizeSearch:10)
  const [totalItem, setTotalItem] = useState(0)
  const [totalPage, setTotalPage] = useState(0)
  const [loading, setLoading] = useState(false)
  const [items, setItem] = useState<Array<SurveyQuestion> | null>(null);
  const [error, setError] = useState('');
  const [deleteId, setDeleteId] = useState<string>("");
  const [questionGroupItem, setQuestionGroupItem] = useState<Array<{ id: number, name: string }>>([])
  const store = useStore();
  const [modalShow, setModalShow] = useState<boolean | undefined>();
  const [modalItem, setModalItem] = useState<any>();
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      var res = store.getState().SearchReducer;
      if (res.isLoading) {
        setError('');
        setLoading(true);
      } else if (res.isLoaded) {
        setError('');
        setLoading(false);
        if (res.table == 'list_question_group') {
          setQuestionGroupItem(res.data);
        } else if(res.table == 'preview_survey_question') {
          setModalItem(res.data);
          setModalShow(true);
        } else {
          if (res.data != null) {
            setItem(res.data.surveyQuestionList);
            setTotalPage(res.data.totalPage);
            setTotalItem(res.data.totalItem);
          } else {
            setError(NOT_FOUND_LABEL);
          }
          setInit(1);
        }

      } else if (res.isNotFound) {
        setError(NOT_FOUND_LABEL);
        setItem([]);
        setLoading(false);
      } else if (res.isUpdated) {
        dispatch(queryclear);

      } else if (res.isFailed) {
        setError(res.data.message);
        setItem([]);
        setLoading(false);
      }
    });
    //dispatch(listsurveygroup("",0,0));
    dispatch(listQuestionGroup());
    if (questionGroup || keyword) {
      dispatch(listSurveyQuestion(questionGroup, keyword, 0, limit));
    }
    removeSession();
    window.scroll(0, 0);
    return unsubscribe;
  }, [])

  const removeSession = () => {
    sessionStorage.removeItem("searchDetails");
    sessionStorage.removeItem("searchPartnerDetails");
    sessionStorage.removeItem("searchSurveyDetails");
    sessionStorage.removeItem("searchTrainerDetails");
    // sessionStorage.removeItem("searchManageQuestion");
    sessionStorage.removeItem("searchLogRequest");
    sessionStorage.removeItem("searchLogRequestHistory");
  }

  const handleClear = () => {
    setPage(0);
    setLimit(10);
    setTotalItem(0);
    setTotalPage(0);
    setItem(null);
    setQuestionGroup(null);
    setKeyword('');
    window.sessionStorage.setItem("searchManageQuestion",JSON.stringify({questionGroupSearch:'',keywordSearch:'',pageSearch:0,pageSizeSearch:10}))
    dispatch(queryclear());
  }

  const handleSubmit = () => {
    setPage(0);
    dispatch(listSurveyQuestion(questionGroup, keyword, 0, limit));
    window.sessionStorage.setItem("searchManageQuestion",JSON.stringify({questionGroupSearch:questionGroup,keywordSearch:keyword,pageSearch:0,pageSizeSearch:limit}))
  }

  const handleLimit = (l: number) => {
    setLimit(l);
    setPage(0)
    dispatch(listSurveyQuestion(questionGroup, keyword, 0, l));
    window.sessionStorage.setItem("searchManageQuestion",JSON.stringify({questionGroupSearch:questionGroup,keywordSearch:keyword,pageSearch:0,pageSizeSearch:l}))
  }

  const handlePage = (l: number) => {
    setPage(l);
    dispatch(listSurveyQuestion(questionGroup, keyword, l, limit));
    window.sessionStorage.setItem("searchManageQuestion",JSON.stringify({questionGroupSearch:questionGroup,keywordSearch:keyword,pageSearch:l,pageSizeSearch:limit}))
  }

  const handleAdd = () => {
    dispatch(queryclear());
    history.push("/admin/survey_question");
  }

  const handleEdit = (id: number) => {
    history.push("/admin/survey_question/" + id);
  }

  const handleCopy = (id: number) => {
    // dispatch(duplicateSurveyGroup(id))
    history.push("/admin/survey_question?duplicate="+id);
  }

  const redirectToDuplicateSurveyPage = (data: any) => {
    // history.push("/admin/survey_question?duplicate="+id);
  }

  const handleDelete = (id: number) => {
    setDeleteId(id.toString());
  }

  const handleDeleteClose = () => {
    setDeleteId("");
  }

  const handleDeleteConfirm = () => {
    dispatch(queryclear());
    dispatch(deletesurveygroup(deleteId));
    if ((items !== undefined) && (items !== null)) {
      var newItem = [];
      for (var i = 0; i < items.length; i++) {
        if (items[i].id !== parseInt(deleteId)) {
          newItem.push(items[i]);
        }
      }
      setItem(newItem);
    }
    setDeleteId("");
  }

  const createBr = (n: number) => {
    var res: any[] = [];
    for (var j = 0; j < n; j++) {
      res.push(<br key={j + "br"} />);
    }
    return res;
  }
  const handleSearch = () => {
    history.push("/admin/search/survey");
  }

  const handleManage = () => {
    history.push("/admin/manage/survey");
  }
  const getQuestionGroup = () => {
    for (var i = 0; i < questionGroupItem.length; i++) {
      if (questionGroupItem[i].id === parseInt(questionGroup)) {
        return { label: questionGroupItem[i].name, value: questionGroup };
      }
    }
    return null;
  }
  const createQuestionGroupSearchFilter = () => {
    var res: any[] = [];
    for (var i = 0; i < questionGroupItem.length; i++) {
      res.push({ label: questionGroupItem[i].name, value: questionGroupItem[i].id });
    }
    return res;
  }
  const handlePreview = (id:number) => {
    dispatch(previewSurveyQuestion(String(id),null,null))
  } 

  return (<>
    <MainMenu>
      {(init === 1) &&
        <>
          <div>
            <div className="query-wrapper">
              <br />
              <div className="yellow-button" style={{ textAlign: "left", borderTopLeftRadius: "30px", borderTopRightRadius: "30px", height: "70px", paddingTop: "15px", paddingLeft: "15px" }}><h4 className="header">การจัดการแบบประเมินผล</h4></div>
              <br />
              {SurveyTab(2, true, handleSearch, handleManage)}
              <div style={{ background: "white", padding: "20px", borderRadius: "5px" }}>
                <form>
                  <div style={{ background: '#EEEEEE', borderRadius: '5px', paddingLeft: "15px", paddingRight: "15px", paddingBottom: '20px', textAlign: "left" }}>
                    <div className="row">
                      <div className="col-1">
                      </div>
                      <div className="col-5">
                        <label className="col-form-label">กลุ่มคำถาม</label>
                        <SingleSearch
                          isSearchable={true} isMulti={false} isClearable={true} placeholder=""
                          onChange={(id: string, e: any) => setQuestionGroup(e == null ? null : e?.value)}
                          value={getQuestionGroup()}
                          options={createQuestionGroupSearchFilter()}
                        />
                      </div>
                      <div className="col-5">
                        <label className="col-form-label">คำถาม</label>
                        <input className="form-control" type="text" name="keyword" onChange={e => setKeyword(e.target.value)} value={keyword}></input>
                      </div>
                      <div className="col-1">
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="row" style={{ justifyContent: "center" }}>
                    <button type="button" className="btn btn-outline-primary" style={{ width: "150px" }}
                      onClick={() => handleClear()} >
                      Clear
                    </button>&nbsp;
                    <button type="button" className="btn btn-primary" style={{ width: "150px" }}
                      onClick={() => handleSubmit()} disabled={loading}>
                      <div>
                        {(loading) &&
                          <div style={{ textAlign: "center" }}><img src={loadinglogo} width="30" height="30" className="d-inline-block align-top" alt="" /></div>
                        }
                        {(!loading) &&
                          <>Search</>
                        }
                      </div>
                    </button>
                  </div>
                </form>
              </div>

              <br />
              <div className="row">
                <div className="col-5" style={{ justifyContent: "left", textAlign: "left", marginTop: "auto", marginBottom: "auto" }}>
                  จำนวนทั้งหมด {Util.integerWithCommas(totalItem)} รายการ
                </div>
                <div className="col-1">
                </div>
                <div className="col-6 row" style={{ justifyContent: "right", paddingRight: "0px" }}>
                  <div style={{ textAlign: "right", marginTop: "auto", marginBottom: "auto" }}>จำนวนรายการต่อหน้า</div>&nbsp;
                  <select className="form-control" style={{ width: "80px" }} name="page" value={limit} onChange={e => handleLimit(parseInt(e.target.value))}>
                    <option value="0">All</option>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>&nbsp;
                  <Button type="button" className="btn btn-warning yellow-button" style={{ width: "200px" }}
                    onClick={() => handleAdd()} >
                    <AddIcon />
                    สร้างคำถาม
                  </Button>
                </div>
              </div>
              {((!loading) && (items !== null) && (items !== undefined) && (items.length === 0) && (error === "")) &&
                <><br /><br /><div className="text-center">{NOT_FOUND_LABEL}</div></>
              }
              {(error !== "") &&
                <div style={{ color: 'red', textAlign: "center" }}>{error}</div>
              }
              {((items !== null) && (items !== undefined) && (items.length >= 1)) &&
                <>
                  <br />
                  <Table striped hover>
                    <thead className="black-button">
                      <tr>
                        <th className='text-left'>คำถามที่แสดง</th>
                        <th className='text-left'>วันที่บันทึก</th>
                        <th className='text-left'>กลุ่มคำถาม</th>
                        <th className='text-left'>แก้ไขล่าสุด</th>
                        <th className='text-left'>วันที่แก้ไขล่าสุด</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody style={{ fontSize:"23px" }}>
                      {items.map((item, index) => {
                        return <tr key={item.id + "_table_list"}>
                          <td className='text-left' style={{width:'30%'}}> {item.name} </td>
                          <td className='text-left'> {item.version?Util.datetimetostr(new Date(item.version)):''} </td>
                          <td className='text-left' style={{width:'20%'}}> {item.questionGroupName} </td>
                          <td className='text-left'> {item.updatedBy} </td>
                          <td className='text-left'> {Util.datetimetostr(new Date(item.updatedDate))} </td>
                          <td>
                            <div className="row">
                              {!item.basicQuestion && (
                                <>
                                  <div onClick={(e) => handleEdit(item.id)}><EditIcon className="yellow pointer" ></EditIcon></div>
                                  <div onClick={(e) => handleCopy(item.id)}><FileCopyIcon className="yellow pointer ml-2" ></FileCopyIcon></div>
                                </>
                              )}
                              <div onClick={(e) => handlePreview(item.id)}><SearchIcon className="yellow pointer ml-2" /></div>
                            </div>
                          </td>
                        </tr>
                      })}
                    </tbody>
                  </Table>
                  <br />

                  <div className="row" style={{ justifyContent: "center", position: "relative" }}>
                    {createPage(page, totalPage, handlePage)}
                    <div className="col-5" style={{ justifyContent: "left", textAlign: "left", position: "absolute", left: "0px" }}>
                      จำนวนทั้งหมด {Util.integerWithCommas(totalItem)} รายการ
                    </div>
                  </div>
                </>
              }
              <br />
              <br />

              {((items === null) || (items === undefined) || (items.length < 5)) &&
                <>{createBr(((items === null) || (items === undefined)) ? 8 : 8 - items.length)}
                </>
              }
            </div>
            <Footer empty={((items === null) || (items === undefined) || (items.length === 0))} />
          </div></>
      }
      {(init < 1) &&
        <>
          {(error === "") &&
            <div style={{ textAlign: "center", width: "1200px" }}><img src={loadinglogo} width="30" height="30" className="d-inline-block align-top" alt="" /></div>
          }
          {(error !== "") &&
            <div style={{ color: 'red', textAlign: "center" }}>{error}</div>
          }
        </>
      }
    </MainMenu>
    <Modal show={deleteId !== ""} onHide={handleDeleteClose}>
      <Modal.Header closeButton>
        <Modal.Title>ยืนยันการลบ?</Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleDeleteClose}>
          Cancel
        </Button>
        <Button variant="danger" onClick={handleDeleteConfirm}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
    <PreviewQuestionModal backdrop="static" show={modalShow != undefined} onHide={() => setModalShow(undefined)} onOk={() => { }} editItem={modalItem} />
  </>
  )
}

export default ManageSurvey