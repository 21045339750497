import React, { useEffect, useRef, useState } from 'react'
import { ManageKnowledge, ManageKnowledgePartnerSubCategory, ManageKnowledgeResponse } from '../../../../model/response/manage-knowledge';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ArrowDropDownIcon from '@material-ui/icons/ExpandMore';
import ArrowDropUpIcon from '@material-ui/icons/ExpandLess';
import MenuIcon from '@material-ui/icons/Menu';
import iconTrashRed from '../../../../img/iconTrashRed.png'
import iconNote from '../../../../img/iconNote.png'
import iconEdit from '../../../../img/iconEdit.png'
import { Tooltip } from '@material-ui/core';
import ManageKnowledgeMediaEdit from './ManageKnowledgeMediaEdit';
import { MediaType } from '../../../../api/define';
import ConfirmModal from '../../../share/ConfirmModal';
import { Dot } from '../../../../style/partnerStylemain';
import { AlertContainer, alert } from 'react-custom-alert';
import ManageKnowledgeMediaDescriptionModal from './ManageKnowledgeMediaDescription';
type Props = {
    subCat: ManageKnowledgePartnerSubCategory;
    indexCat: number;
    subCatIndex: number;
    knowledgeList: ManageKnowledgeResponse[];
    editable?: boolean;
    setKnowledgeList: (knowledgeList: ManageKnowledgeResponse[]) => void;
    submitted: boolean
}

const ManageKnowledgeMedia = ({ subCat, indexCat, subCatIndex, knowledgeList, setKnowledgeList, editable, submitted }: Props) => {
    const dragInfographicItem = useRef<any>(null)
    const dragInfographicOverItem = useRef<any>(null)
    const [disabledDragInfographic, setDisabledDragInfographic] = useState(false);
    const [expandInfoGraphic, setExpandInfoGraphic] = useState(false);

    const dragVideoItem = useRef<any>(null)
    const dragVideoOverItem = useRef<any>(null)
    const [disabledDragVideo, setDisabledDragVideo] = useState(false);
    const [expandVideo, setExpandVideo] = useState(false);

    const dragArticleItem = useRef<any>(null)
    const dragArticleOverItem = useRef<any>(null)
    const [disabledDragArticle, setDisabledDragArticle] = useState(false);
    const [expandArticle, setExpandArticle] = useState(false);

    const dragElearningItem = useRef<any>(null)
    const dragElearningOverItem = useRef<any>(null)
    const [disabledDragElearning, setDisabledDragElearning] = useState(false);
    const [expandElearning, setExpandElearning] = useState(false);

    const dragEbookItem = useRef<any>(null)
    const dragEbookOverItem = useRef<any>(null)
    const [disabledDragEbook, setDisabledDragEbook] = useState(false);
    const [expandEbook, setExpandEbook] = useState(false);

    const dragPublishedDocItem = useRef<any>(null)
    const dragPublishedDocOverItem = useRef<any>(null)
    const [disabledDragPublishedDoc, setDisabledDragPublishedDoc] = useState(false);
    const [expandPublishedDoc, setExpandPublishedDoc] = useState(false);

    const dragInhouseItem = useRef<any>(null)
    const dragInhouseOverItem = useRef<any>(null)
    const [disabledDragInhouse, setDisabledDragInhouse] = useState(false);
    const [expandInhouse, setExpandInhouse] = useState(false);

    const dragCalculatorItem = useRef<any>(null)
    const dragCalculatorOverItem = useRef<any>(null)
    const [disabledDragCalculator, setDisabledDragCalculator] = useState(false);
    const [expandCalculator, setExpandCalculator] = useState(false);

    const [mediaType, setMediaType] = useState<any>();
    const [mediaItem, setMediaItem] = useState();
    const [showMediaModal, setShowMediaModal] = useState(false);
    const [mediaIndex, setMediaIndex] = useState<number | undefined>();
    const [itemDeleteMedia, setItemDeleteMedia] = useState<any>();
    const [confirmDeleteMedia, setConfirmDeleteMedia] = useState(false);
    const [showDescriptionModal, setShowDescriptionModal] = useState(false);

    const handleInfographicDrag = () => {
        let _knowledgeList = [...knowledgeList];
        const dragItemContent = _knowledgeList[indexCat].subCategories[subCatIndex].infographics.splice(dragInfographicItem.current, 1)[0];
        _knowledgeList[indexCat].subCategories[subCatIndex].infographics.splice(dragInfographicOverItem.current, 0, dragItemContent)
        dragInfographicItem.current = null;
        dragInfographicOverItem.current = null
        setKnowledgeList(_knowledgeList);
    }
    const handleVideoDrag = () => {
        let _knowledgeList = [...knowledgeList];
        const dragItemContent = _knowledgeList[indexCat].subCategories[subCatIndex].videos.splice(dragVideoItem.current, 1)[0];
        _knowledgeList[indexCat].subCategories[subCatIndex].videos.splice(dragVideoOverItem.current, 0, dragItemContent)
        dragVideoItem.current = null;
        dragVideoOverItem.current = null
        setKnowledgeList(_knowledgeList);
    }
    const handleArticleDrag = () => {
        let _knowledgeList = [...knowledgeList];
        const dragItemContent = _knowledgeList[indexCat].subCategories[subCatIndex].articles.splice(dragArticleItem.current, 1)[0];
        _knowledgeList[indexCat].subCategories[subCatIndex].articles.splice(dragArticleOverItem.current, 0, dragItemContent)
        dragArticleItem.current = null;
        dragArticleOverItem.current = null
        setKnowledgeList(_knowledgeList);
    }
    const handleElearningDrag = () => {
        let _knowledgeList = [...knowledgeList];
        const dragItemContent = _knowledgeList[indexCat].subCategories[subCatIndex].elearnings.splice(dragElearningItem.current, 1)[0];
        _knowledgeList[indexCat].subCategories[subCatIndex].elearnings.splice(dragElearningOverItem.current, 0, dragItemContent)
        dragElearningItem.current = null;
        dragElearningOverItem.current = null
        setKnowledgeList(_knowledgeList);
    }

    const handleEbookDrag = () => {
        let _knowledgeList = [...knowledgeList];
        const dragItemContent = _knowledgeList[indexCat].subCategories[subCatIndex].ebooks.splice(dragEbookItem.current, 1)[0];
        _knowledgeList[indexCat].subCategories[subCatIndex].ebooks.splice(dragEbookOverItem.current, 0, dragItemContent)
        dragEbookItem.current = null;
        dragEbookOverItem.current = null
        setKnowledgeList(_knowledgeList);
    }
    const handlePublishedDrag = () => {
        let _knowledgeList = [...knowledgeList];
        const dragItemContent = _knowledgeList[indexCat].subCategories[subCatIndex].publishedDocuments.splice(dragPublishedDocItem.current, 1)[0];
        _knowledgeList[indexCat].subCategories[subCatIndex].publishedDocuments.splice(dragPublishedDocOverItem.current, 0, dragItemContent)
        dragPublishedDocItem.current = null;
        dragPublishedDocOverItem.current = null
        setKnowledgeList(_knowledgeList);
    }

    const handleInhouseDrag = () => {
        let _knowledgeList = [...knowledgeList];
        const dragItemContent = _knowledgeList[indexCat].subCategories[subCatIndex].inHouses.splice(dragInhouseItem.current, 1)[0];
        _knowledgeList[indexCat].subCategories[subCatIndex].inHouses.splice(dragInhouseOverItem.current, 0, dragItemContent)
        dragInhouseItem.current = null;
        dragInhouseOverItem.current = null
        setKnowledgeList(_knowledgeList);
    }

    const handleCalculatorDrag = () => {
        let _knowledgeList = [...knowledgeList];
        const dragItemContent = _knowledgeList[indexCat].subCategories[subCatIndex].calculators.splice(dragCalculatorItem.current, 1)[0];
        _knowledgeList[indexCat].subCategories[subCatIndex].calculators.splice(dragCalculatorOverItem.current, 0, dragItemContent)
        dragCalculatorItem.current = null;
        dragCalculatorOverItem.current = null
        setKnowledgeList(_knowledgeList);
    }
    const handleAddMedia = () => {
        if (knowledgeList[indexCat].categoryName == null || knowledgeList[indexCat].categoryName == undefined) {
            alert({ message: "กรุณากรอกชื่อประเภทความรู้", type: 'error' });
            return;
        }
        if (subCat.subCategoryName == null || subCat.subCategoryName == undefined) {
            alert({ message: "กรุณากรอกชื่อหมวดหมู่ความรู้", type: 'error' });
            return;
        }
        setMediaItem(undefined);
        setMediaType(undefined);
        setShowMediaModal(true);
    }
    const handleEditMedia = (indexMedia: number, mediaType: MediaType) => {
        setMediaIndex(indexMedia);
        let media = getMediaItem(indexMedia, mediaType)
        setMediaItem(media as any);
        setShowMediaModal(true);
    }
    const hideMedia = () => {
        setMediaItem(undefined);
        setMediaType(undefined);
        setShowMediaModal(false);
    }
    const handleDeleteSubCategory = (indexMedia: number, name: string, mediaType: MediaType) => {
        const item = {
            indexMedia: indexMedia,
            index: indexCat,
            subCatIndex: subCatIndex,
            mediaType: mediaType,
            name: name
        }
        setItemDeleteMedia(item as any);
        setConfirmDeleteMedia(true);

    }
    const getMediaItem = (indexMedia: number, mediaType: MediaType) => {
        let _knowledgeList = [...knowledgeList];
        switch (mediaType) {
            case MediaType.INFOGRAPHIC:
                setMediaType(MediaType.INFOGRAPHIC);
                return _knowledgeList[indexCat].subCategories[subCatIndex].infographics[indexMedia];
            case MediaType.ARTICLE:
                setMediaType(MediaType.ARTICLE);
                return _knowledgeList[indexCat].subCategories[subCatIndex].articles[indexMedia];
            case MediaType.VIDEO:
                setMediaType(MediaType.VIDEO);
                return _knowledgeList[indexCat].subCategories[subCatIndex].videos[indexMedia];
            case MediaType.ELEARNING:
                setMediaType(MediaType.ELEARNING);
                return _knowledgeList[indexCat].subCategories[subCatIndex].elearnings[indexMedia];
            case MediaType.EBOOK:
                setMediaType(MediaType.EBOOK);
                return _knowledgeList[indexCat].subCategories[subCatIndex].ebooks[indexMedia];
            case MediaType.PUBLISHED_DOC:
                setMediaType(MediaType.PUBLISHED_DOC);
                return _knowledgeList[indexCat].subCategories[subCatIndex].publishedDocuments[indexMedia];
            case MediaType.INHOUSE:
                setMediaType(MediaType.INHOUSE);
                return _knowledgeList[indexCat].subCategories[subCatIndex].inHouses[indexMedia];
            case MediaType.CALCULATOR:
                setMediaType(MediaType.CALCULATOR);
                return _knowledgeList[indexCat].subCategories[subCatIndex].calculators[indexMedia];
            default:
                return null;
        }
    }
    const handleUpdateMedia = (e: any) => {
        setShowMediaModal(false);
        setKnowledgeList(e);
        alert({ message: "แก้ไขสื่อเรียบร้อยกรุณากด save ด้านล่างเพื่อบันทึก", type: 'success' });
        setMediaItem(undefined);
        setMediaIndex(undefined);
    }
    const deleteSubCat = () => {
        var newItem = knowledgeList == null ? [] : [...knowledgeList];
        if (itemDeleteMedia) {
            const { index, subCatIndex, indexMedia, mediaType } = itemDeleteMedia as any
            switch (mediaType) {
                case MediaType.INFOGRAPHIC:
                    newItem[index].subCategories[subCatIndex].infographics.splice(indexMedia, 1);
                    break;
                case MediaType.VIDEO:
                    newItem[index].subCategories[subCatIndex].videos.splice(indexMedia, 1);
                    break;
                case MediaType.ARTICLE:
                    newItem[index].subCategories[subCatIndex].articles.splice(indexMedia, 1);
                    break;
                case MediaType.ELEARNING:
                    newItem[index].subCategories[subCatIndex].elearnings.splice(indexMedia, 1);
                    break;
                case MediaType.EBOOK:
                    newItem[index].subCategories[subCatIndex].ebooks.splice(indexMedia, 1);
                    break;
                case MediaType.PUBLISHED_DOC:
                    newItem[index].subCategories[subCatIndex].publishedDocuments.splice(indexMedia, 1);
                    break;
                case MediaType.INHOUSE:
                    newItem[index].subCategories[subCatIndex].inHouses.splice(indexMedia, 1);
                    break;
                case MediaType.CALCULATOR:
                    newItem[index].subCategories[subCatIndex].calculators.splice(indexMedia, 1);
                    break;
            }

            setKnowledgeList(newItem);
        }
        setItemDeleteMedia(undefined);
        setConfirmDeleteMedia(false);

    }

    const getRequireArrayMedia = () => {
        return (submitted
            && (!subCat.infographics || subCat.infographics.length < 1)
            && (!subCat.calculators || subCat.calculators.length < 1)
            && (!subCat.inHouses || subCat.inHouses.length < 1)
            && (!subCat.ebooks || subCat.ebooks.length < 1)
            && (!subCat.publishedDocuments || subCat.publishedDocuments.length < 1)
            && (!subCat.elearnings || subCat.elearnings.length < 1)
            && (!subCat.articles || subCat.articles.length < 1)
            && (!subCat.videos || subCat.videos.length < 1))
    }
    const hideDescriptionModal = () => {
        setShowDescriptionModal(false)
        setMediaItem(undefined);
    }
    const openDescriptionModal = (item: ManageKnowledge) => {
        setMediaItem(item as any);
        setShowDescriptionModal(true)
    }
    return (
        <div>
            {subCat && (
                <>
                    <div className='d-flex mt-2'>
                        <small onClick={handleAddMedia} style={{ cursor: 'pointer' }}><AddCircleOutlineIcon className="yellow mr-2" />สื่อความรู้</small>
                        {subCat.checked && (<small className={(getRequireArrayMedia()) ? "text-danger ml-2" : ""}>{(getRequireArrayMedia()) ? "กรุณาเพิ่มสื่อความรู้" : ""}</small>)}
                    </div>
                    <table className='table mt-2' style={{ fontSize: "20px" }}>
                        <thead className='black-button'>
                            <th style={{ width: '10%' }}>ประเภทสื่อ</th>
                            <th style={{ width: '70%' }}>ชื่อสื่อความรู้</th>
                            <th></th>
                        </thead>
                        <tbody>
                            {subCat.infographics && subCat.infographics.length > 0 && <>
                                <tr className='p-bg-gray'>
                                    <td colSpan={3}>
                                        {((expandInfoGraphic === undefined) || (expandInfoGraphic === false)) &&
                                            <div onClick={(e) => setExpandInfoGraphic(true)}><ArrowDropDownIcon className="yellow-circle white pointer mr-2" ></ArrowDropDownIcon>Infographic({subCat.infographics.length})</div>
                                        }
                                        {(expandInfoGraphic === true) &&
                                            <div onClick={(e) => setExpandInfoGraphic(false)}><ArrowDropUpIcon className="yellow-circle white pointer mr-2" ></ArrowDropUpIcon>Infographic({subCat.infographics.length})</div>
                                        }
                                    </td>
                                </tr>
                                {subCat.infographics.map((item, index) => (
                                    <tr key={"item_infoGraphic" + index}                                       
                                        style={{ marginBottom: 5,display:expandInfoGraphic?'':'none' }}
                                        draggable={disabledDragInfographic}
                                        onDragStart={(e) => dragInfographicItem.current = index}
                                        onDragEnter={(e) => dragInfographicOverItem.current = index}
                                        onDragEnd={(e) => handleInfographicDrag()}
                                        onDragOver={(e) => e.preventDefault()}
                                        onDrop={e => setDisabledDragInfographic(false)}>
                                        <td><img src={ item.imageS3} width={34} height={19} /></td>
                                        <td><span className='mr-2'><Dot active={item.checked} /></span>{item.title}</td>
                                        <td className='text-right'>
                                            <img src={iconNote} onClick={(e) => openDescriptionModal(item)} width={20} height={20} id={"icon_note_infoGraphic" + index} style={{ cursor: "pointer" }} className='mr-2' />
                                            {editable && (<img src={iconEdit} width={20} height={20} id={"icon_edit_infoGraphic" + index} onClick={e => handleEditMedia(index, MediaType.INFOGRAPHIC)} style={{ cursor: "pointer" }} className='mr-2' />)}
                                            <img src={iconTrashRed} onClick={(e) => handleDeleteSubCategory(index, item.title, MediaType.INFOGRAPHIC)} width={20} height={20} id={"icon_trash_infoGraphic" + index} style={{ cursor: "pointer" }} className='mr-2' />
                                            <span className='ml-auto' onMouseDown={e => setDisabledDragInfographic(true)}
                                            ><MenuIcon width={20} height={18} id={"icon_drag_infoGraphic" + index} style={{ cursor: "grab" }} /></span>

                                        </td>
                                    </tr>
                                ))}
                            </>}
                            {subCat.videos && subCat.videos.length > 0 && <>
                                <tr className='p-bg-gray'>
                                    <td colSpan={3}>
                                        {((expandVideo === undefined) || (expandVideo === false)) &&
                                            <div onClick={(e) => setExpandVideo(true)}><ArrowDropDownIcon className="yellow-circle white pointer mr-2" ></ArrowDropDownIcon>คลิปความรู้({subCat.videos.length})</div>
                                        }
                                        {(expandVideo === true) &&
                                            <div onClick={(e) => setExpandVideo(false)}><ArrowDropUpIcon className="yellow-circle white pointer mr-2" ></ArrowDropUpIcon>คลิปความรู้({subCat.videos.length})</div>
                                        }</td>
                                </tr>
                                {subCat.videos.map((item, index) => (
                                    <tr key={"item_videos" + index}
                                        style={{ marginBottom: 5,display:expandVideo?'':'none' }}
                                        draggable={disabledDragVideo}
                                        onDragStart={(e) => dragVideoItem.current = index}
                                        onDragEnter={(e) => dragVideoOverItem.current = index}
                                        onDragEnd={(e) => handleVideoDrag()}
                                        onDragOver={(e) => e.preventDefault()}
                                        onDrop={e => setDisabledDragVideo(false)}>
                                        <td><img src={ item.imageS3} width={34} height={19} /></td>
                                        <td><span className='mr-2'><Dot active={item.checked} /></span>{item.title}</td>
                                        <td className='text-right'>
                                            <img src={iconNote} onClick={(e) => openDescriptionModal(item)} width={20} height={20} id={"icon_note_videos" + index} style={{ cursor: "pointer" }} className='mr-2' />
                                            {editable && <img src={iconEdit} width={20} height={20} id={"icon_edit_videos" + index} onClick={e => handleEditMedia(index, MediaType.VIDEO)} style={{ cursor: "pointer" }} className='mr-2' />}
                                            <img src={iconTrashRed} onClick={(e) => handleDeleteSubCategory(index, item.title, MediaType.VIDEO)} width={20} height={20} id={"icon_trash_videos" + index} style={{ cursor: "pointer" }} className='mr-2' />
                                            <span className='ml-auto' onMouseDown={e => setDisabledDragVideo(true)}
                                            ><MenuIcon width={20} height={18} id={"icon_drag_videos" + index} style={{ cursor: "grab" }} /></span>

                                        </td>
                                    </tr>
                                ))}
                            </>}
                            {subCat.articles && subCat.articles.length > 0 && <>
                                <tr className='p-bg-gray'>
                                    <td colSpan={3}>
                                        {((expandArticle === undefined) || (expandArticle === false)) &&
                                            <div onClick={(e) => setExpandArticle(true)}><ArrowDropDownIcon className="yellow-circle white pointer mr-2" ></ArrowDropDownIcon>บทความ({subCat.articles.length})</div>
                                        }
                                        {(expandArticle === true) &&
                                            <div onClick={(e) => setExpandArticle(false)}><ArrowDropUpIcon className="yellow-circle white pointer mr-2" ></ArrowDropUpIcon>บทความ({subCat.articles.length})</div>
                                        }</td>
                                </tr>
                                {subCat.articles.map((item, index) => (
                                    <tr key={"item_articles" + index}
                                        style={{ marginBottom: 5,display:expandArticle?'':'none' }}
                                        draggable={disabledDragArticle}
                                        onDragStart={(e) => dragArticleItem.current = index}
                                        onDragEnter={(e) => dragArticleOverItem.current = index}
                                        onDragEnd={(e) => handleArticleDrag()}
                                        onDragOver={(e) => e.preventDefault()}
                                        onDrop={e => setDisabledDragArticle(false)}>
                                        <td><img src={ item.imageS3} width={34} height={19} /></td>
                                        <td><span className='mr-2'><Dot active={item.checked} /></span>{item.title}</td>
                                        <td className='text-right'>
                                            <img src={iconNote} onClick={(e) => openDescriptionModal(item)} width={20} height={20} id={"icon_note_articles" + index} style={{ cursor: "pointer" }} className='mr-2' />
                                            {editable && <img src={iconEdit} width={20} height={20} id={"icon_edit_articles" + index} onClick={e => handleEditMedia(index, MediaType.ARTICLE)} style={{ cursor: "pointer" }} className='mr-2' />}
                                            <img src={iconTrashRed} onClick={(e) => handleDeleteSubCategory(index, item.title, MediaType.ARTICLE)} width={20} height={20} id={"icon_trash_articles" + index} style={{ cursor: "pointer" }} className='mr-2' />
                                            <span className='ml-auto' onMouseDown={e => setDisabledDragArticle(true)}
                                            ><MenuIcon width={20} height={18} id={"icon_drag_articles" + index} style={{ cursor: "grab" }} /></span>

                                        </td>
                                    </tr>
                                ))}
                            </>}
                            {subCat.elearnings && subCat.elearnings.length > 0 && <>
                                <tr className='p-bg-gray'>
                                    <td colSpan={3}>
                                        {((expandElearning === undefined) || (expandElearning === false)) &&
                                            <div onClick={(e) => setExpandElearning(true)}><ArrowDropDownIcon className="yellow-circle white pointer mr-2" ></ArrowDropDownIcon>e-Learning({subCat.elearnings.length})</div>
                                        }
                                        {(expandElearning === true) &&
                                            <div onClick={(e) => setExpandElearning(false)}><ArrowDropUpIcon className="yellow-circle white pointer mr-2" ></ArrowDropUpIcon>e-Learning({subCat.elearnings.length})</div>
                                        }</td>
                                </tr>
                                {subCat.elearnings.map((item, index) => (
                                    <tr key={"item_eLearnings" + index}
                                        style={{ marginBottom: 5,display:expandElearning?'':'none' }}
                                        draggable={disabledDragElearning}
                                        onDragStart={(e) => dragElearningItem.current = index}
                                        onDragEnter={(e) => dragElearningOverItem.current = index}
                                        onDragEnd={(e) => handleElearningDrag()}
                                        onDragOver={(e) => e.preventDefault()}
                                        onDrop={e => setDisabledDragElearning(false)}>
                                        <td><img src={ item.imageS3} width={34} height={19} /></td>
                                        <td><span className='mr-2'><Dot active={item.checked} /></span>{item.title}</td>
                                        <td className='text-right'>
                                            <img src={iconNote} onClick={(e) => openDescriptionModal(item)} width={20} height={20} id={"icon_note_eLearnings" + index} style={{ cursor: "pointer" }} className='mr-2' />
                                            {editable && <img src={iconEdit} width={20} height={20} id={"icon_edit_eLearnings" + index} onClick={e => handleEditMedia(index, MediaType.ELEARNING)} style={{ cursor: "pointer" }} className='mr-2' />}
                                            <img src={iconTrashRed} onClick={(e) => handleDeleteSubCategory(index, item.title, MediaType.ELEARNING)} width={20} height={20} id={"icon_trash_eLearnings" + index} style={{ cursor: "pointer" }} className='mr-2' />
                                            <span className='ml-auto' onMouseDown={e => setDisabledDragElearning(true)}
                                            ><MenuIcon width={20} height={18} id={"icon_drag_eLearnings" + index} style={{ cursor: "grab" }} /></span>

                                        </td>
                                    </tr>
                                ))}
                            </>}
                            {subCat.ebooks && subCat.ebooks.length > 0 && <>
                                <tr className='p-bg-gray'>
                                    <td colSpan={3}>
                                        {((expandEbook === undefined) || (expandEbook === false)) &&
                                            <div onClick={(e) => setExpandEbook(true)}><ArrowDropDownIcon className="yellow-circle white pointer mr-2" ></ArrowDropDownIcon>e-Book({subCat.ebooks.length})</div>
                                        }
                                        {(expandEbook === true) &&
                                            <div onClick={(e) => setExpandEbook(false)}><ArrowDropUpIcon className="yellow-circle white pointer mr-2" ></ArrowDropUpIcon>e-Book({subCat.ebooks.length})</div>
                                        }</td>
                                </tr>
                                {subCat.ebooks.map((item, index) => (
                                    <tr key={"item_Ebooks" + index}
                                        style={{ marginBottom: 5,display:expandEbook?'':'none' }}
                                        draggable={disabledDragEbook}
                                        onDragStart={(e) => dragEbookItem.current = index}
                                        onDragEnter={(e) => dragEbookOverItem.current = index}
                                        onDragEnd={(e) => handleEbookDrag()}
                                        onDragOver={(e) => e.preventDefault()}
                                        onDrop={e => setDisabledDragEbook(false)}>
                                        <td><img src={ item.imageS3} width={34} height={19} /></td>
                                        <td><span className='mr-2'><Dot active={item.checked} /></span>{item.title}</td>
                                        <td className='text-right'>
                                            <img src={iconNote} onClick={(e) => openDescriptionModal(item)} width={20} height={20} id={"icon_note_ebook" + index} style={{ cursor: "pointer" }} className='mr-2' />
                                            {editable && <img src={iconEdit} width={20} height={20} id={"icon_edit_ebook" + index} onClick={e => handleEditMedia(index, MediaType.EBOOK)} style={{ cursor: "pointer" }} className='mr-2' />}
                                            <img src={iconTrashRed} onClick={(e) => handleDeleteSubCategory(index, item.title, MediaType.EBOOK)} width={20} height={20} id={"icon_trash_ebook" + index} style={{ cursor: "pointer" }} className='mr-2' />
                                            <span className='ml-auto' onMouseDown={e => setDisabledDragEbook(true)}
                                            ><MenuIcon width={20} height={18} id={"icon_drag_ebook" + index} style={{ cursor: "grab" }} /></span>

                                        </td>
                                    </tr>
                                ))}
                            </>}
                            {subCat.publishedDocuments && subCat.publishedDocuments.length > 0 && <>
                                <tr className='p-bg-gray'>
                                    <td colSpan={3}>
                                        {((expandPublishedDoc === undefined) || (expandPublishedDoc === false)) &&
                                            <div onClick={(e) => setExpandPublishedDoc(true)}><ArrowDropDownIcon className="yellow-circle white pointer mr-2" ></ArrowDropDownIcon>เอกสารเผยแพร่ (สิ่งพิมพ์)({subCat.publishedDocuments.length})</div>
                                        }
                                        {(expandPublishedDoc === true) &&
                                            <div onClick={(e) => setExpandPublishedDoc(false)}><ArrowDropUpIcon className="yellow-circle white pointer mr-2" ></ArrowDropUpIcon>เอกสารเผยแพร่ (สิ่งพิมพ์)({subCat.publishedDocuments.length})</div>
                                        }</td>
                                </tr>
                                {subCat.publishedDocuments.map((item, index) => (
                                    <tr key={"item_PublishedDoc" + index}
                                        style={{ marginBottom: 5,display:expandPublishedDoc?'':'none' }}
                                        draggable={disabledDragPublishedDoc}
                                        onDragStart={(e) => dragPublishedDocItem.current = index}
                                        onDragEnter={(e) => dragPublishedDocOverItem.current = index}
                                        onDragEnd={(e) => handlePublishedDrag()}
                                        onDragOver={(e) => e.preventDefault()}
                                        onDrop={e => setDisabledDragPublishedDoc(false)}>
                                        <td><img src={ item.imageS3} width={34} height={19} /></td>
                                        <td><span className='mr-2'><Dot active={item.checked} /></span>{item.title}</td>
                                        <td className='text-right'>
                                            <img src={iconNote} onClick={(e) => openDescriptionModal(item)} width={20} height={20} id={"icon_note_publishedDoc" + index} style={{ cursor: "pointer" }} className='mr-2' />
                                            {editable && <img src={iconEdit} width={20} height={20} id={"icon_edit_publishedDoc" + index} onClick={e => handleEditMedia(index, MediaType.PUBLISHED_DOC)} style={{ cursor: "pointer" }} className='mr-2' />}
                                            <img src={iconTrashRed} onClick={(e) => handleDeleteSubCategory(index, item.title, MediaType.PUBLISHED_DOC)} width={20} height={20} id={"icon_trash_publishedDoc" + index} style={{ cursor: "pointer" }} className='mr-2' />
                                            <span className='ml-auto' onMouseDown={e => setDisabledDragPublishedDoc(true)}
                                            ><MenuIcon width={20} height={18} id={"icon_drag_publishedDoc" + index} style={{ cursor: "grab" }} /></span>

                                        </td>
                                    </tr>
                                ))}
                            </>}
                            {subCat.inHouses && subCat.inHouses.length > 0 && <>
                                <tr className='p-bg-gray'>
                                    <td colSpan={3}>
                                        {((expandInhouse === undefined) || (expandInhouse === false)) &&
                                            <div onClick={(e) => setExpandInhouse(true)}><ArrowDropDownIcon className="yellow-circle white pointer mr-2" ></ArrowDropDownIcon>In-House Training({subCat.inHouses.length})</div>
                                        }
                                        {(expandInhouse === true) &&
                                            <div onClick={(e) => setExpandInhouse(false)}><ArrowDropUpIcon className="yellow-circle white pointer mr-2" ></ArrowDropUpIcon>In-House Training({subCat.inHouses.length})</div>
                                        }</td>
                                </tr>
                                {subCat.inHouses.map((item, index) => (
                                    <tr key={"item_inHouses" + index}
                                        style={{ marginBottom: 5,display:expandInhouse?'':'none' }}
                                        draggable={disabledDragInhouse}
                                        onDragStart={(e) => dragInhouseItem.current = index}
                                        onDragEnter={(e) => dragInhouseOverItem.current = index}
                                        onDragEnd={(e) => handleInhouseDrag()}
                                        onDragOver={(e) => e.preventDefault()}
                                        onDrop={e => setDisabledDragInhouse(false)}>
                                        <td><img src={ item.imageS3} width={34} height={19} /></td>
                                        <td><span className='mr-2'><Dot active={item.checked} /></span>{item.title}</td>
                                        <td className='text-right'>
                                            <img src={iconNote} onClick={(e) => openDescriptionModal(item)} width={20} height={20} id={"icon_note_inHouses" + index} style={{ cursor: "pointer" }} className='mr-2' />
                                            {editable && <img src={iconEdit} width={20} height={20} id={"icon_edit_inHouses" + index} className='mr-2' onClick={e => handleEditMedia(index, MediaType.INHOUSE)} style={{ cursor: "pointer" }} />}
                                            <img src={iconTrashRed} onClick={(e) => handleDeleteSubCategory(index, item.title, MediaType.INHOUSE)} width={20} height={20} id={"icon_trash_inHouses" + index} style={{ cursor: "pointer" }} className='mr-2' />
                                            <span className='ml-auto' onMouseDown={e => setDisabledDragInhouse(true)}
                                            ><MenuIcon width={20} height={18} id={"icon_drag_inHouses" + index} style={{ cursor: "grab" }} /></span>

                                        </td>
                                    </tr>
                                ))}
                            </>}
                            {subCat.calculators && subCat.calculators.length > 0 && <>
                                <tr className='p-bg-gray'>
                                    <td colSpan={3}>
                                        {((expandCalculator === undefined) || (expandCalculator === false)) &&
                                            <div onClick={(e) => setExpandCalculator(true)}><ArrowDropDownIcon className="yellow-circle white pointer mr-2" ></ArrowDropDownIcon>โปรแกรมคำนวน({subCat.calculators.length})</div>
                                        }
                                        {(expandCalculator === true) &&
                                            <div onClick={(e) => setExpandCalculator(false)}><ArrowDropUpIcon className="yellow-circle white pointer mr-2" ></ArrowDropUpIcon>โปรแกรมคำนวน({subCat.calculators.length})</div>
                                        }</td>
                                </tr>
                                {subCat.calculators.map((item, index) => (
                                    <tr key={"item_calculators" + index}
                                        style={{ marginBottom: 5 ,display:expandCalculator?'':'none'}}
                                        draggable={disabledDragCalculator}
                                        onDragStart={(e) => dragCalculatorItem.current = index}
                                        onDragEnter={(e) => dragCalculatorOverItem.current = index}
                                        onDragEnd={(e) => handleCalculatorDrag()}
                                        onDragOver={(e) => e.preventDefault()}
                                        onDrop={e => setDisabledDragCalculator(false)}>
                                        <td><img src={ item.imageS3} width={34} height={19} /></td>
                                        <td><span className='mr-2'><Dot active={item.checked} /></span>{item.title}</td>
                                        <td className='text-right'>
                                            <img src={iconNote} onClick={(e) => openDescriptionModal(item)} width={20} height={20} id={"icon_note_calculators" + index} style={{ cursor: "pointer" }} className='mr-2' />
                                            {editable && <img src={iconEdit} width={20} height={20} id={"icon_edit_calculators" + index} onClick={e => handleEditMedia(index, MediaType.CALCULATOR)} className='mr-2' style={{ cursor: "pointer" }} />}
                                            <img src={iconTrashRed} onClick={(e) => handleDeleteSubCategory(index, item.title, MediaType.CALCULATOR)} width={20} height={20} id={"icon_trash_calculators" + index} style={{ cursor: "pointer" }} className='mr-2' />
                                            <span className='ml-auto' onMouseDown={e => setDisabledDragCalculator(true)}
                                            ><MenuIcon width={20} height={18} id={"icon_drag_calculators" + index} style={{ cursor: "grab" }} /></span>

                                        </td>
                                    </tr>
                                ))}
                            </>}
                        </tbody>
                    </table>
                </>
            )}
            
            {knowledgeList && (
                <ManageKnowledgeMediaEdit knowledgeList={knowledgeList}
                    indexCat={indexCat}
                    subCatIndex={subCatIndex}
                    show={showMediaModal}
                    onHide={() => hideMedia()}
                    onOk={(e) => handleUpdateMedia(e)}
                    mediaIndex={mediaIndex}
                    mediaType={mediaType}
                    modalItem={mediaItem} />
            )}
            <ConfirmModal title={'กรุณากด "ยืนยัน" เพื่อลบรายการ'} name={itemDeleteMedia?.name} show={confirmDeleteMedia}
                onHide={() => setConfirmDeleteMedia(false)} onOk={() => deleteSubCat()} />
            <ManageKnowledgeMediaDescriptionModal show={showDescriptionModal} mediaItem={mediaItem} onHide={() => hideDescriptionModal()} />
        </div>
    )
}

export default ManageKnowledgeMedia