import React, { useEffect, useState } from 'react'
import PartnerHeader from './PartnerHeader'
import PartnerStepper from './PartnerStepper'
import { StepPage } from './constant/StepPage'
import { Banner, CardBox, PButton, PButtonBack, PartnerContainer } from '../../style/partnerStylemain'
import banner from '../../img/partnerBanner.png';
import { PartnerCategory, PartnerSubCategory } from '../../model/response/partner-category'
import { useHistory } from 'react-router-dom'

type Props = {}

const PartnerSummaryChooseTopic = (props: Props) => {
  const [listCategory, setListCategory] = useState<PartnerCategory[]>([]);
  const history = useHistory();
  useEffect(() => {
    if (sessionStorage.getItem("listCategory")) {
      let listFromSession = JSON.parse(sessionStorage.getItem("listCategory") as any) as PartnerCategory[]
      setListCategory(listFromSession.filter(x => (x.notInterested == false)))
    } else{
      history.push("/partner/choose-topic")
    }
  }, []);
  const goBackStep = () => {
    history.push("/partner/choose-topic")
  }
  const goNextStep = () => {
    history.push("/partner/summary-suggestion")
  }
  return (
    <div className='bg-light-blue partner-page' style={{ height: "100vh", fontFamily: "DB Helvethaica" }}>
      <div style={{ position: 'sticky', top: 0 }}>
        <div className="student-wrapper-header-inner" style={{ background: "#38383D" }}>
          <PartnerHeader />
        </div>
        <PartnerStepper stepNumber={StepPage.CHOOSE_TOPIC} />
      </div>
      <PartnerContainer className='mt-5'>
        <Banner src={banner}></Banner>
        <h5 className='text-center mt-3' >สรุปแบบฟอร์มแจ้งความสนใจส่งเสริมความรู้ในองค์กร<br />ร่วมกับตลาดหลักทรัพย์แห่งประเทศไทย</h5>
        <CardBox>
          {listCategory && listCategory.map(x => (
            <React.Fragment key={"category" + x.categoryId}>
              <h4 className='p-text-dark-blue' >{x.categoryName}</h4>
              <ul>
                {x.subCategories.map((subCat: PartnerSubCategory) => (
                  <React.Fragment key={"subCat" + subCat.subCategoryId}>
                    {subCat.checked && <li>{subCat.subCategoryName}</li>}
                  </React.Fragment>
                ))}
              </ul>
            </React.Fragment>

          ))}
        </CardBox>
        <div className='d-flex mt-3 justify-content-between'>
          <div>
            <PButtonBack onClick={goBackStep} id="button-back">&lt; ย้อนกลับ</PButtonBack>
          </div>
          <div>
            <PButton onClick={goNextStep} id="button-next">ยืนยันความสนใจ &gt;</PButton>
          </div>

        </div>
      </PartnerContainer>
    </div>
  )
}

export default PartnerSummaryChooseTopic