import { apiClient } from './axios-intercept';
import { DEFAULT_SERVER } from './conf';

const TRAINER = "/api/v1/trainer";
const EXPORT ="/api/v1/trainer/export";

export const EXPORT_TRAINER_URL = DEFAULT_SERVER+EXPORT;
const LIST_TRAINER_URL = DEFAULT_SERVER + TRAINER;
const GET_TRAINER_URL = DEFAULT_SERVER + TRAINER;
const ADD_TRAINER_URL = DEFAULT_SERVER + TRAINER;

export class TrainerAPI {
        
    public static getParam = (name: string,partner: any[]) => {
        var param=((name===undefined)?"":"&name="+encodeURIComponent(name));
        if (partner!==undefined)
        {
            for (var i=0;i<partner.length;i++)
            {
                param=param+"&partner_id="+partner[i].value;
            }
        }
        return param;
    }

    public static listPage = async (name: string,param: any[],page: number,limit: number): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=LIST_TRAINER_URL+"?partner=true&"+TrainerAPI.getParam(name,param)+"&page="+page+"&limit="+limit;
            apiClient.get(s,
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                console.log("list trainer response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("list trainer error:", err);
                return reject(err); 
            })
        })
    }

    public static listAll = async (): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=LIST_TRAINER_URL+"/all";
            apiClient.get(s,
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                console.log("list all trainer response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("list all trainer error:", err);
                return reject(err); 
            })
        })
    }

    public static get = async (id: number): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=GET_TRAINER_URL+"/"+id;
            apiClient.get(s,
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                console.log("get trainer response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("get trainer error:", err);
                return reject(err); 
            })
        })
    }

    public static create = async (item: any): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=ADD_TRAINER_URL;
            apiClient.post(s,item,
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                console.log("add trainer response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("add trainer error:", err);
                return reject(err); 
            })
        })
    }

    public static update = async (id: number,item:any): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=ADD_TRAINER_URL+"/"+id;
            apiClient.patch(s,item,
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                console.log("update trainer response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("update trainer error:", err);
                return reject(err); 
            })
        })
    }

    public static delete = async (id: number): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=ADD_TRAINER_URL+"/"+id;
            apiClient.delete(s,
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                console.log("delete partner response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("delete partner error:", err);
                return reject(err); 
            })
        })
    }
}
      