import { OrgGroup } from '../model/response/organization.model';
import { apiClient } from './axios-intercept';
import { DEFAULT_SERVER } from './conf';
import { Util } from './util';


const SEARCH_PARTNER_WITHOUT_PAGE_URL = DEFAULT_SERVER + "/api/v1/page/trainer/partner-list";
const LIST_PROJECT_URL = DEFAULT_SERVER + "/api/v1/page/trainer/project-list";
const SEARCH_CLASS_URL = DEFAULT_SERVER + "/api/v1/page/trainer/class-pagination";
const GET_CLASS_INFO_URL = DEFAULT_SERVER + "/api/v1/page/trainer/class-info";
const LIST_COURSE_ASSIGNMENT_URL = DEFAULT_SERVER + "/api/v1/page/trainer/assignment/course";
const SEARCH_ASSIGNMENT_URL = DEFAULT_SERVER+"/api/v1/page/trainer/assignment-pagination";
const LIST_TRAINER_ASSIGNMENT_URL = DEFAULT_SERVER+"/api/v1/page/trainer/assignment/trainer";
const GET_ASSIGNMENT_URL = DEFAULT_SERVER+"/api/v1/page/trainer/assignment/student";
const SAVE_ASSIGNMENT_URL = DEFAULT_SERVER+"/api/v1/page/trainer/assignment/student/save";
const LIST_FEEDBACK_URL = DEFAULT_SERVER+"/api/v1/page/trainer/assignment/feedback";
const SAVE_FEEDBACK_URL = DEFAULT_SERVER+"/api/v1/page/trainer/assignment/feedback/create";
const LIST_FEEDBACK_STUDENT_URL = DEFAULT_SERVER+"/api/v1/page/student/assignment/feedback";
const SAVE_FEEDBACK_STUDENT_URL = DEFAULT_SERVER+"/api/v1/page/student/assignment/feedback/create";
const LIST_FEEDBACK_ADMIN_URL=DEFAULT_SERVER+"/api/v1/assignment/admin/feedback";
const SAVE_FEEDBACK_STUDENT_ADMIN_URL = DEFAULT_SERVER+"/api/v1/assignment/feedback";
export class TrainerPageAPI {
    public static listPartnerWithOutPage = async (): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=SEARCH_PARTNER_WITHOUT_PAGE_URL;
            apiClient.get(s,
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                console.log("list partner response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("list partner error:", err);
                return reject(err); 
            })
        })
    }

    public static listProject = async (): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=LIST_PROJECT_URL;
            apiClient.get(s,
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                console.log("list project response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("list project error:", err);
                return reject(err); 
            })
        })
    }

    public static listClass = async (projectId: number, partnerId: number,fromDate : Date | null, toDate: Date | null,page: number,limit: number): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=SEARCH_CLASS_URL+"?"+((isNaN(projectId))?"":"project_id="+projectId)+((isNaN(partnerId))?"":"&partner_id="+partnerId)+ ((fromDate==null)?"":"&from_date="+ encodeURIComponent(Util.getLocalISOString(fromDate)))+((toDate==null)?"":"&to_date="+encodeURIComponent(Util.getLocalISOString(toDate)))+"&page="+page+"&limit="+limit; 
        
            apiClient.get(s,
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                console.log("list class response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("list class error:", err);
                return reject(err); 
            })
        })
    }

    public static getClass = async (id: string): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=GET_CLASS_INFO_URL+"/"+id;
            apiClient.get(s,
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                console.log("get class response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("get class error:", err);
                return reject(err); 
            })
        })
    }

    public static listCourse = async (classId: number): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=LIST_COURSE_ASSIGNMENT_URL+"?classId="+classId; 
            apiClient.get(s,
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                console.log("list course for assignment response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("list course for assignment error:", err);
                return reject(err); 
            })
        })
    }

    public static listAssignment = async (classId: number,param: string,page: number,limit: number): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=SEARCH_ASSIGNMENT_URL+"?class_id="+classId+"&"+param+"&page="+page+"&limit="+limit; 
            apiClient.get(s,
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                console.log("list assignment response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("list assignment error:", err);
                return reject(err); 
            })
        })
    }

    public static listTrainer = async (classId: number): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=LIST_TRAINER_ASSIGNMENT_URL+"?class_id="+classId; 
            apiClient.get(s,
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                console.log("list trainer for assignment response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("list trainer for assignment error:", err);
                return reject(err); 
            })
        })
    }

    public static getassignment = async (classId: number,classStudentId: number): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=GET_ASSIGNMENT_URL+"?class_id="+classId+"&class_student_id="+classStudentId; 
            apiClient.get(s,
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                console.log("get assignment response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("get assignment error:", err);
                return reject(err); 
            })
        })
    }

    public static saveAssignment = async (item:any): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=SAVE_ASSIGNMENT_URL; 
            
            apiClient.post(s,item,
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                console.log("save assignment response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("save assignment error:", err);
                return reject(err); 
            })
        })
    }

    public static getFeedBack = async (classId: number,classStudentId:number,documentId:number,isStudent:boolean,isAdmin:boolean): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s;
            if(!isAdmin){
                if(isStudent){
                    s=LIST_FEEDBACK_STUDENT_URL+"?class_id="+classId+"&class_student_id="+classStudentId+"&document_id="+documentId; 
                }else{
                    s=LIST_FEEDBACK_URL+"?class_id="+classId+"&class_student_id="+classStudentId+"&document_id="+documentId; 
                }
            }else{
                s=LIST_FEEDBACK_ADMIN_URL+"?class_id="+classId+"&class_student_id="+classStudentId+"&document_id="+documentId; 
            }
            
            apiClient.get(s,
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                console.log("list feedback response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("list feedback error:", err);
                return reject(err); 
            })
        })
    }

    public static saveFeedBack = async (item:any,isStudent:boolean,isAdmin:boolean): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s; 
            if(!isAdmin){
                s=isStudent? SAVE_FEEDBACK_STUDENT_URL : SAVE_FEEDBACK_URL; 
            }else{
                s = SAVE_FEEDBACK_STUDENT_ADMIN_URL;
            }
            apiClient.post(s,item,
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                console.log("save feedback response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("save feedback error:", err);
                return reject(err); 
            })
        })
    }


}