import { SurveyAPI } from './../../api/survey-api';
import { Dispatch } from 'redux';
import { ActionType, SearchActionType } from '../search/types';
import { PageStudentAPI } from '../../api/page-student-api';
import { dispatchError } from '../search/actions'; 

export const assignment = (id:string) => (dispatch: Dispatch<any>) => {
    dispatch({
         type: SearchActionType.QUERY_LOADING
     });
     PageStudentAPI.assignment(id).then(function (response) {
         dispatch({
             type: SearchActionType.QUERY_LOADED,
             payload: response.data,
             table: "assignment",
             page: "student_assignment",
             action: ActionType.QUERY
         });
     }, function (error) {
        dispatchError("student_assignment",dispatch,error);
     })
 };

 export const validateAlreadySubmit= (surveyGroupId:number,classId:number|null,classCourseId:number|null,classCourseSeesionId:number|null,isSurveyBefore:boolean|null,table:string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    SurveyAPI.validateAlreadySubmit(surveyGroupId,classId,classCourseId,classCourseSeesionId,isSurveyBefore).then(function (response) {
        let payload = {
            surveyGroupId: surveyGroupId,
            classId: classId,
            classCourseId: classCourseId,
            classCourseSeesionId: classCourseSeesionId,
            isSurveyBefore: isSurveyBefore
        }
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: payload,
            table: table,
            page: "student_assignment",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("student_assignment",dispatch,error);
    })
 }

 export const uploadassignment = (documentId: string,file: FileList) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING,
    });
    PageStudentAPI.uploadAssignment(documentId,file).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "document",
            page: "student_assignment",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("student_assignment",dispatch,error);
    })
};

export const getconsent = (document_id: string) => (dispatch: Dispatch<any>) => {
    dispatch({
         type: SearchActionType.QUERY_LOADING
     });
     PageStudentAPI.getconsent(document_id).then(function (response) {
         dispatch({
             type: SearchActionType.QUERY_LOADED,
             payload: response.data,
             table: "consent",
             page: "student_assignment",
             action: ActionType.QUERY
         });
     }, function (error) {
        dispatchError("student_assignment",dispatch,error);
     })
 };

 export const setconsent = (class_course_id:string,result: boolean) => (dispatch: Dispatch<any>) => {
    dispatch({
         type: SearchActionType.QUERY_UPDATING
     });
     PageStudentAPI.setconsent(class_course_id,result).then(function (response) {
         dispatch({
             type: SearchActionType.QUERY_UPDATED,
             payload: response.data,
             table: "set_consent",
             page: "student_assignment",
             action: ActionType.UPDATE
         });
     }, function (error) {
        dispatchError("student_assignment",dispatch,error);
     })
 };
