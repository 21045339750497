import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import MainMenu from '../menu';
import loadinglogo from '../../../img/loading.gif';
import MergePartnerSearchSuggestion from './MergePartnerSearchSuggestion';
import { SingleSearch } from '../../tab/search-component';
import { useDispatch, useStore } from 'react-redux';
import { listorganization } from '../../../store/editpartner/actions';
import { Button, Table } from 'react-bootstrap';
import { tr } from 'date-fns/locale';
import { searchMergePartner } from '../../../store/partner/actions';
import { PartnerInfoResponse } from '../../../model/response/partner-profile';
import { NOT_FOUND_LABEL } from '../../../api/label';
import AddIcon from '@material-ui/icons/Add';
type Props = {}

const MergePartnerSearch = (props: Props) => {
    let { logRequestId } = useParams<{ logRequestId: string }>();
    let { orgTypeId } = useParams<{ orgTypeId: string }>();
    let { orgGroupId } = useParams<{ orgGroupId: string }>();
    let { partnerName } = useParams<{ partnerName: string }>();
    const [keyword, setKeyword] = useState<any>()
    const [orgType, setOrgType] = useState<any>(Number(orgTypeId));
    const [orgGroup, setOrgGroup] = useState<any>(Number(orgGroupId));
    const [orgTypeItem, setOrgTypeItem] = useState<any[]>([])
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [init, setInit] = useState(1);
    const [item, setItem] = useState<PartnerInfoResponse[]>([]);
    const [search,setSearch] = useState(false);
    const store = useStore();
    const history = useHistory()
    const dispatch = useDispatch();
    
    useEffect(() => {
        const unsubscribe = store.subscribe(() => {
            let res = store.getState().SearchReducer;
            console.log(res);
            if (res.isLoading) {
                setError('');
                setLoading(true);
            } else if ((res.isLoaded)) {
                setError('');
                setLoading(false);
                if (res.data != null) {
                    if (res.table === "org_type") {
                        setOrgTypeItem(res.data);
                    } else if (res.table === "merge_partner_search") {
                        setItem(res.data)
                    }
                }
            } else if (res.isUpdating) {
                setError('');
                setLoading(true);
            } else if (res.isUpdated) {
                setError('');
                setLoading(false);

            } else if (res.isFailed) {
                setError(res.data.message);
                setLoading(false);
                alert({ message: res.data.message, type: 'error' });
            }

        })
        dispatch(listorganization(0, 0, true));
        dispatch(searchMergePartner(keyword, orgType, orgGroup));
        return unsubscribe;
    }, []);
    const getOrgType = () => {
        for (var i = 0; i < orgTypeItem.length; i++)
            if (orgTypeItem[i].id === orgType) {
                return { label: orgTypeItem[i].name, value: orgType };
            }
        return null;
    }
    const createOrgTypeSearchFilter = () => {
        var res: any[] = [];
        for (var i = 0; i < orgTypeItem.length; i++) {
            res.push({ label: orgTypeItem[i].name, value: orgTypeItem[i].id });
        }
        return res;
    }
    const createOrgGroupSearchFilter = () => {
        var res: any[] = [];
        for (var i = 0; i < orgTypeItem.length; i++) {
            if (orgTypeItem[i].id === orgType) {
                for (var j = 0; j < orgTypeItem[i].orgGroup.length; j++) {
                    var gitem = orgTypeItem[i].orgGroup[j];
                    res.push({ label: gitem.name, value: gitem.id });
                }
            }
        }
        return res;
    }
    const getOrgGroup = () => {
        for (var i = 0; i < orgTypeItem.length; i++) {
            if (orgTypeItem[i].id === orgType) {
                for (var j = 0; j < orgTypeItem[i].orgGroup.length; j++) {
                    var gitem = orgTypeItem[i].orgGroup[j];
                    if (gitem.id === orgGroup) {
                        return { label: gitem.name, value: gitem.id };
                    }
                }
            }
        }
        return null;
    }
    const setOrgTypeChange = (e: any) => {
        setOrgType(e == null ? null : e?.value);
        setOrgGroup(null);
    }
    const handleClear = () => {
        setKeyword(undefined);
        setOrgType(undefined);
        setOrgGroup(undefined);
        dispatch(searchMergePartner(undefined, undefined, undefined));
    }
    const handleSubmit = () => {
        setSearch(true);
        dispatch(searchMergePartner(keyword, orgType, orgGroup));
    }
    const handleSelect = (partner: PartnerInfoResponse) => {
        history.push('/admin/log-request/merge-partner/convert-data/' + logRequestId + '/' + orgTypeId + '/' + orgGroupId + '/' + partnerName + '/' + partner.partnerId)
    }
    const handleCreate = () => {
        history.push('/admin/log-request/merge-partner/convert-data/' + logRequestId + '/' + orgTypeId + '/' + orgGroupId + '/' + partnerName)
    }
    const handleBack = () => {
        history.push('/admin/log-request/' + logRequestId);
    }
    return (
        <div>
            <MainMenu>
                {init == 1 && (
                    <div className="query-wrapper">
                        <br />
                        <div className="yellow-button" style={{ textAlign: "left", borderTopLeftRadius: "30px", borderTopRightRadius: "30px", height: "70px", paddingTop: "15px", paddingLeft: "15px" }}><h4 className="header">บริหารจัดการข้อมูลองค์กรพันธมิตร - บันทึกข้อมูลลงฐานข้อมูล</h4></div>
                        <br />
                        <MergePartnerSearchSuggestion />
                        <br />
                        <div style={{ background: "white" }}>
                            <div className="text-left" style={{ position: "relative", marginBottom: 5 }}>
                                <div className='d-flex yellow-button' style={{ padding: "15px", borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}>
                                    <label className='ml-2 my-auto'><span style={{ fontWeight: 600, marginRight: 10 }}>ค้นหาข้อมูลองค์กร</span><span style={{ fontWeight: 600, marginRight: 10, fontSize: "1.2rem" }}>{partnerName}</span> </label>
                                </div>
                                <div style={{ padding: "15px", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px", border: "3px solid #fab232" }}>
                                    <div className='row mt-1'>
                                        <div className="col-4">
                                            <label className="col-form-label">ชื่อองค์กร</label>
                                            <input className="form-control" type="text" name="keyword" onChange={e => setKeyword(e.target.value)} value={keyword}></input>
                                        </div>
                                    </div>
                                    <div className='row mt-1'>
                                        <div className="col-4">
                                            <label className='col-form-label'>ประเภทองค์กร</label>
                                            <SingleSearch
                                                isSearchable={true} isMulti={false} placeholder=""
                                                onChange={(id: string, e: any) => setOrgTypeChange(e)}
                                                value={getOrgType()}
                                                options={createOrgTypeSearchFilter()}
                                            />
                                        </div>
                                        <div className='col-4'>
                                            <label className='col-form-label'>หมวดหมู่</label>
                                            <SingleSearch
                                                isSearchable={true} isMulti={false} isClearable={true} placeholder=""
                                                onChange={(id: string, e: any) => setOrgGroup(e == null ? null : e?.value)}
                                                value={getOrgGroup()}
                                                options={createOrgGroupSearchFilter()}
                                            />
                                        </div>

                                    </div>
                                    <br />
                                    <div className="row" style={{ justifyContent: "center" }}>
                                        <button type="button" className="btn btn-outline-primary" style={{ width: "150px" }}
                                            onClick={() => handleClear()} disabled={(loading)}>
                                            Clear
                                        </button>&nbsp;
                                        <button type="button" className="btn btn-primary" style={{ width: "150px" }}
                                            onClick={() => handleSubmit()} disabled={loading}>
                                            <div>
                                                {(loading) &&
                                                    <div style={{ textAlign: "center" }}><img src={loadinglogo} width="30" height="30" className="d-inline-block align-top" alt="" /></div>
                                                }
                                                {(!loading) &&
                                                    <>Search</>
                                                }
                                            </div>
                                        </button>
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className='col'>
                                        <div className='d-flex'>
                                        <h5 className='mt-2'>องค์กรที่มีอยู่ในระบบ</h5>
                                        </div>
                                        
                                    </div>
                                    <div className='col ml-auto'>
                                        <div className='d-flex justify-content-end'>
                                            <div className='my-auto mr-2'>หาไม่พบข้อมูลองค์กรพันธมิตรในตาราง ให้กดปุ่ม "Create"</div>
                                            <Button disabled={!search} type="button" className="btn btn-warning yellow-button"
                                                onClick={() => handleCreate()} >
                                                <AddIcon />
                                                Create
                                            </Button>
                                        </div>

                                    </div>
                                </div>
                                {(error !== "") &&
                                    <div style={{ color: 'red', textAlign: "center" }}>{error}</div>
                                }
                                {((item !== null)) && (<>
                                    <Table striped hover>
                                        <thead className="black-button">
                                            <tr>
                                                <th style={{ width: '30%' }}>ชื่อองค์กร</th>
                                                <th style={{ width: '30%' }}>ประเภทองค์กร</th>
                                                <th style={{ width: '30%' }}>หมวดหมู่</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody style={{ fontSize: "20px" }}>
                                            {item && item.map(x => (
                                                <tr key={'merge-partner' + x.partnerId}>
                                                    <td>{x.partnerName}</td>
                                                    <td>{x.orgTypeName}</td>
                                                    <td>{x.orgGroupName}</td>
                                                    <td><a className='link' onClick={() => handleSelect(x)}>เลือก</a></td>
                                                </tr>
                                            ))}
                                            {item.length < 1 && (
                                                <tr className="text-center"><td colSpan={4}>{NOT_FOUND_LABEL}</td></tr>
                                            )}
                                        </tbody>
                                    </Table>
                                    <div className='d-flex mt-3'>
                                        <div className='ml-auto'>
                                            <button type="button" className="btn btn-secondary" style={{ width: "120px" }}
                                                onClick={() => handleBack()} >
                                                Back
                                            </button>&nbsp;
                                        </div>
                                    </div>
                                    <br />
                                </>)}
                            </div>
                        </div>
                    </div>
                )}
                {(((init < 1) || (loading)) && (error === "")) &&
                    <div style={{ textAlign: "center" }}><img src={loadinglogo} width="30" height="30" className="d-inline-block align-top" alt="" /></div>
                }
            </MainMenu>
        </div>
    )
}

export default MergePartnerSearch