import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from "react-redux";
import { store, persistor } from "./store/store";
import { PersistGate } from 'redux-persist/integration/react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Helmet from "react-helmet";
import logo from "./img/logo_set_50.png";

const Root = () => (
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
        <Helmet>
            <link rel="icon" href={logo} />
        </Helmet>
         <App />
        </PersistGate>
	</Provider>
);

ReactDOM.render(<Root />, document.getElementById("root") as HTMLElement);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
