import React, { useEffect, useState } from 'react'
import PartnerHeader from '../PartnerHeader'
import PartnerStepper from '../PartnerStepper'
import { StepPage } from '../constant/StepPage'
import { Banner, CardBox, PButton, PartnerContainer } from '../../../style/partnerStylemain'
import banner from '../../../img/partnerBanner.png';
import { TagsInput } from "react-tag-input-component";
import { AlertContainer, alert } from 'react-custom-alert'
import { PartnerCategory } from '../../../model/response/partner-category'
import { PartnerKnowledgeRegistration } from '../../../model/request/partner-knowledge-regis'
import { useDispatch, useStore } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { registerKnowledge, verifyEmailKnowledge } from '../../../store/publicPartner/action'
import { NOT_FOUND_LABEL } from '../../../api/label'
import { queryclear } from '../../../store/search/actions'
import loadinglogo from '../../../img/loading.gif';
import { Button } from 'react-bootstrap'
type Props = {}

const PartnerRegistForm = (props: Props) => {
    const [partnerName, setParnerName] = useState();
    const [firstname, setFirstname] = useState();
    const [lastname, setLastname] = useState();
    const [department, setDepartment] = useState();
    const [position, setPosition] = useState();
    const [otp, setOtp] = useState();
    const [officeNo, setOfficeNo] = useState();
    const [mobileNo, setMobileNo] = useState<string>();
    const [email, setEmail] = useState<string>();
    const [coordinatorId,setCoordinatorId] =  useState<any>()
    const [additionalDetails, setAdditionalDetails] = useState();
    const [pdpaConsent, setPdpaConsent] = useState();
    const [marketingConsent, setMarketingConsent] = useState();
    const [submitted, setSubmitted] = useState(false);
    const [listFromSession, setListfromSession] = useState<PartnerCategory[]>();
    const [loading, setLoading] = useState(false);
    const [init, setInit] = useState(0)
    const store = useStore();
    const dispatch = useDispatch();
    const history = useHistory()
    useEffect(() => {
        const unsubscribe = store.subscribe(() => {
            var res = store.getState().SearchReducer;
            if (res.isLoading) {
                setLoading(true);
            } else if (res.isLoaded) {
                setLoading(false);
                if (res.data != null) {
                    if (res.table === "register-knowledge") {
                        setInit(1);
                        history.push("/partner/thank")
                    } else if (res.table == "verify-email-knowledge") {
                        setCoordinatorId(res.data)
                    }

                } else {
                    alert({ message: NOT_FOUND_LABEL, type: 'error' });
                }
            } else if (res.isNotFound) {
                alert({ message: NOT_FOUND_LABEL, type: 'error' });
                setLoading(false);
            } else if (res.isUpdated) {
                dispatch(queryclear);
            } else if (res.isFailed) {
                alert({ message: res.data.message, type: 'error' });
                setLoading(false);
            }
        });
        if (sessionStorage.getItem("listCategory")) {
            let listFromSession = JSON.parse(sessionStorage.getItem("listCategory") as any) as PartnerCategory[]
            setListfromSession(listFromSession)
        } else {
            history.push("/partner")
        }
        return unsubscribe;
    }, []);
    useEffect(() => {
        if (sessionStorage.getItem("listCategory")) {
            let listFromSession = JSON.parse(sessionStorage.getItem("listCategory") as any) as PartnerCategory[]
            setListfromSession(listFromSession)
        }
    }, []);
    const getRequire = (value: string | undefined | any[]) => {
        return (submitted && ((value == null) || (value == '')))
    }
    const getRequireMobile = (value: string | undefined) => {
        if (value != null) {
            return !value.match('[0-9]{10}')
        }
        return (submitted && ((value == null) || (value == '')))
    }

    const getRequireEmail = (value: string | undefined) => {
        if (value != null) {
            const expression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
            return !expression.test(value)
        }
        return (submitted && ((value == null) || (value == '')))
    }

    const getRequireConsent = (value: boolean | undefined | any[]) => {
        return (submitted && (value != true))
    }
    const handleSendRequestOtp = () => {
        const expression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        if (email == null || firstname == null || lastname == null) {
            alert({ message: 'กรุณากรอกชื่อ นามสกุล และ e-Mail ให้ครบถ้วน', type: 'error' });
            return;
        }
        if (email != null && !expression.test(email)) {
            alert({ message: 'กรุณากรอกe-Mailให้ถูกต้อง', type: 'error' });
            return;
        }
        const body: PartnerKnowledgeRegistration = {
            partnerName: partnerName,
            firstname: firstname,
            lastname: lastname,
            department: department,
            officeNo: officeNo,
            mobileNo: mobileNo,
            email: email,
            otp: otp,
            position: position,
            marketingConsent: marketingConsent,
            pdpaConsent: pdpaConsent,
            knowledgeList: listFromSession
        }
        dispatch(verifyEmailKnowledge(body))

    }
    const handleSubmit = () => {
        setSubmitted(true);
        if (!validateForm()) {
            alert({ message: 'กรุณากรอกฟอร์มให้ครบ', type: 'error' });
        } else {
            const body: PartnerKnowledgeRegistration = {
                partnerName: partnerName,
                firstname: firstname,
                lastname: lastname,
                department: department,
                officeNo: officeNo,
                mobileNo: mobileNo,
                email: email,
                coordinatorId: coordinatorId,
                otp: otp,
                position: position,
                marketingConsent: marketingConsent,
                pdpaConsent: pdpaConsent,
                knowledgeList: listFromSession,
                additionalDetails: additionalDetails
            }
            dispatch(registerKnowledge(body))
        }
    }
    const validateForm = () => {
        if (mobileNo != null && !mobileNo.match('[0-9]{10}')) {
            alert({ message: 'กรุณากรอกเบอร์มือถือให้ถูกต้อง', type: 'error' });
            return false;
        }
        const expression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        if (email != null && !expression.test(email)) {
            alert({ message: 'กรุณากรอกe-Mailให้ถูกต้อง', type: 'error' });
            return false;
        }
        let error = partnerName == null || partnerName == '' ||
            firstname == null || firstname == '' ||
            lastname == null || lastname == '' ||
            department == null || department == '' ||
            officeNo == null || officeNo == '' ||
            mobileNo == null || mobileNo == '' ||
            email == null || email == '' ||
            otp == null || otp == '' ||
            pdpaConsent != true ||
            marketingConsent != true;
        return error ? false : true;
    }
    return (
        <div className='bg-light-gray partner-page' style={{ height: "100vh", fontFamily: "DB Helvethaica" }}>
            <div style={{ position: 'sticky', top: 0, zIndex: 30 }}>
                <div className="student-wrapper-header-inner" style={{ background: "#38383D" }}>
                    <PartnerHeader />
                </div>
                <PartnerStepper stepNumber={StepPage.SEND_INTEREST} />
            </div>
            <PartnerContainer className='mt-2' style={{ maxWidth: 980 }}>
                <h5 className='text-center mt-3' >ข้อมูลการติดต่อ</h5>
                <div className='text-center mb-3'>กรุณากรอกข้อมูลเพื่อให้ตลาดหลักทรัพย์ฯ ติดต่อกลับไปยังท่าน</div>
                {!loading && (
                    <>
                        <CardBox>
                            <form>
                                <div className='row'>
                                    <div className='col-5'>
                                        <label className='col-form-label'>ชื่อองค์กร <span className='text-danger'>*</span></label>
                                        <input type="text" className={'form-control ' + (getRequire(partnerName) ? "is-invalid" : "")} id="input-text-partnerName" value={partnerName} onChange={(e: any) => setParnerName(e.target.value)} />
                                    </div>
                                    <div className='col-3'>
                                        <label className='col-form-label'>ชื่อ <span className='text-danger'>*</span></label>
                                        <input type="text" className={'form-control ' + (getRequire(firstname) ? "is-invalid" : "")} id="input-text-firstname" value={firstname} onChange={(e: any) => setFirstname(e.target.value)} />
                                    </div>
                                    <div className='col-4'>
                                        <label className='col-form-label'>นามสกุล <span className='text-danger'>*</span></label>
                                        <input type="text" className={'form-control ' + (getRequire(lastname) ? "is-invalid" : "")} id="input-text-lastname" value={lastname} onChange={(e: any) => setLastname(e.target.value)} />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-3'>
                                        <label className='col-form-label'>ฝ่ายงาน <span className='text-danger'>*</span></label>
                                        <input type="text" className={'form-control ' + (getRequire(department) ? "is-invalid" : "")} id="input-text-department" value={department} onChange={(e: any) => setDepartment(e.target.value)} />
                                    </div>
                                    <div className='col-3'>
                                        <label className='col-form-label'>ตำแหน่งงาน</label>
                                        <input type="text" className={'form-control '} id="input-text-position" value={position} onChange={(e: any) => setPosition(e.target.value)} />
                                    </div>
                                    <div className='col-3'>
                                        <label className='col-form-label'>เบอร์ที่ทำงาน <span className='text-danger'>*</span></label>
                                        <input type="text" className={'form-control ' + (getRequire(officeNo) ? "is-invalid" : "")} id="input-text-officeNo" value={officeNo} onChange={(e: any) => setOfficeNo(e.target.value)} />
                                    </div>
                                    <div className='col-3'>
                                        <label className='col-form-label'>เบอร์มือถือ <span className='text-danger'>*</span></label>
                                        <input type="tel" maxLength={10} minLength={10} className={'form-control ' + (getRequireMobile(mobileNo) ? "is-invalid" : "")} id="input-text-mobileNo" value={mobileNo} onChange={(e: any) => setMobileNo(e.target.value)} />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12'>
                                        <label className='col-form-label'>e-Mail <span className='text-danger'>*</span></label>
                                        <div className='d-flex'>
                                            <input type="text" className={'form-control col mr-2 ' + (getRequireEmail(email) ? "is-invalid" : "")} id="input-text-email" value={email} onChange={(e: any) => setEmail(e.target.value)} />
                                            <div><Button variant="primary" onClick={handleSendRequestOtp} id="button-submit">ขอรหัสยืนยัน</Button></div>
                                        </div>
                                    </div>

                                </div>
                                <div className='row'>
                                    <div className='col-12'>
                                        <label className='col-form-label'>รหัสยืนยัน <span className='text-danger'>*</span> {coordinatorId && (<i className='text-danger'>(ระบบได้ส่งรหัสยืนยันไปที่ e-Mail ที่ท่านให้ไว้ข้างต้น กรุณานำรหัสที่ได้รับมาใส่ให้ครบถ้วน เพื่อดำเนินการส่งข้อมูลต่อไป)</i>)}</label>
                                        <div className={(getRequire(otp) ? "is-invalid" : "")}>
                                            <input type="text" className={'form-control col-4 ' + (getRequire(otp) ? "is-invalid" : "")} id="input-text-otp" value={otp} onChange={(e: any) => setOtp(e.target.value)} disabled={coordinatorId == null} />
                                        </div>

                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12'>
                                        <label className='col-form-label'>รายละเอียดอื่นๆ</label>
                                        <input type="text" className='form-control' id="input-text-additionalDetails" value={additionalDetails} onChange={(e: any) => setAdditionalDetails(e.target.value)} />
                                    </div>
                                </div>
                                <div className='mt-2'>
                                    <div className="gray-button text-left" style={{ padding: "15px", borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}>
                                        <div><b>ข้อตกลงและเงื่อนไข</b> (กรุณาอ่านข้อตกลงและเงื่อนไขด้านล่างนี้โดยละเอียด)</div>
                                    </div>
                                    <div className="bg-white text-left" style={{ padding: "15px", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px", border: "3px solid #DDDDDD" }}>
                                        <div className='row'>
                                            <div className='col'>
                                                <label className='col-form-label'><span>&emsp;ข้าพเจ้าได้ศึกษารายละเอียดต่างๆ ที่ระบุไว้ในคำประกาศเกี่ยวกับความเป็นส่วนตัวของกลุ่มตลาดหลักทรัพย์แห่งประเทศไทย<sup>1</sup> (“กลุ่มตลาดหลักทรัพย์ฯ”)
                                                    <a className='link-blue' href='https://www.set.or.th/th/privacy-notice.html' target='_blank'> (https://www.set.or.th/th/privacy-notice.html)</a> เป็นอย่างดีแล้วและรับทราบว่าข้อมูลส่วนบุคคลของข้าพเจ้า รวมถึงข้อมูลส่วนบุคคลของบุคคลที่สามที่ข้าพเจ้าเปิดเผยให้แก่
                                                    ตลาดหลักทรัพย์ฯ (หากมี) จะถูกประมวลผลและได้รับความคุ้มครองภายใต้คำประกาศเกี่ยวกับความเป็นส่วนตัวดังกล่าวโดยข้าพเจ้าขอยืนยันและรับรองว่าข้าพเจ้าได้
                                                    ดำเนินการให้บุคคลที่สามได้อ่านรายละเอียดที่ระบุไว้ในคำประกาศเกี่ยวกับความเป็นส่วนตัวดังกล่าวแล้วรวมทั้งได้รับความยินยอมจากบุคคลที่สามในการให้ข้อมูลส่วน
                                                    บุคคลของบุคคลที่สามนั้นแก่กลุ่มตลาดหลักทรัพย์ฯ อย่างถูกต้องและเป็นไปตามกฎหมายทุกประการ กลุ่มตลาดหลักทรัพย์แห่งประเทศไทย หมายถึง ตลาดหลักทรัพย์แห่ง
                                                    ประเทศไทย และบริษัทที่จัดตั้งโดยตลาดหลักทรัพย์แห่งประเทศไทยหรือบริษัทย่อยของตลาดหลักทรัพย์แห่งประเทศไทย และตลาดหลักทรัพย์แห่งประเทศไทยถือหุ้นไม่ว่า
                                                    ทางตรงหรือทางอ้อมเกินกว่าร้อยละ 50 (ห้าสิบ) ของทุนจดทะเบียนของบริษัท <a className='link-blue' href='https://www.set.or.th/th/about/overview/share_p1.html' target='_blank'>(https://www.set.or.th/th/about/overview/share_p1.html)</a>
                                                    <br />
                                                </span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className='row mt-1'>
                                            <div className="col mt-1">
                                                &emsp;<input id={"input-radio-pdpa-consent-true"} type="radio" checked={pdpaConsent == true} onChange={(e) => { setPdpaConsent(true as any) }} /> ยอมรับ
                                            </div>

                                        </div>
                                        <div className={(getRequireConsent(pdpaConsent)) ? "text-danger ml-3" : ""}>{(getRequireConsent(pdpaConsent)) ? "กรุณากดยินยอมเงื่อนไข" : ""}</div>
                                        <div className='row mt-1'>
                                            <div className='col'>
                                                <label className='col-form-label'><span>&emsp;นอกจากนี้ ข้าพเจ้าตกลงยินยอมให้กลุ่มตลาดหลักทรัพย์ฯ เก็บรวบรวม ใช้ เปิดเผยข้อมูลส่วนบุคคลของข้าพเจ้าที่ให้ไว้ในการลงทะเบียนนี้ เพื่อติดต่อสื่อสาร
                                                    ทางการตลาดผ่านช่องทางใด ๆ การสำรวจความคิดเห็น หรือแจ้งข้อมูลข่าวสาร เสนอบริการ สิทธิประโยชน์ โปรโมชั่นหรือกิจกรรมพิเศษของกลุ่มตลาดหลักทรัพย์ฯ หรือ
                                                    ของพันธมิตรทางธุรกิจของกลุ่มตลาดหลักทรัพย์ฯ
                                                    <br />
                                                </span>
                                                </label>
                                            </div>

                                        </div>
                                        <div className='row mt-1'>
                                            <div className="col-3 mt-1">
                                                &emsp;<input id={"input-radio-market-consent-true"} type="radio" checked={marketingConsent == true} onChange={(e) => { setMarketingConsent(true as any) }} /> ยินยอม
                                            </div>
                                            <div className="col mt-1">
                                                &emsp;<input id={"input-radio-market-consent-false"} type="radio" checked={marketingConsent == false} onChange={(e) => { setMarketingConsent(false as any) }} /> ไม่ยินยอม
                                                <span className='text-danger'> (กรณีที่ไม่ให้ความยินยอม ท่านจะไม่ได้รับข้อมูลและการติดต่อกลับใดๆ จากกลุ่มตลาดหลักทรัพย์ฯ)</span>
                                            </div>

                                        </div>
                                        <div className={(getRequireConsent(marketingConsent)) ? "text-danger ml-3" : ""}>{(getRequireConsent(marketingConsent)) ? "กรุณากดยินยอมเงื่อนไข" : ""}</div>
                                        <div className='row mt-1'>
                                            <div className='col'>
                                                <small><sup>1</sup>กลุ่มตลาดหลักทรัพย์แห่งประเทศไทย หมายถึง ตลาดหลักทรัพย์แห่งประเทศไทย และบริษัทที่จัดตั้งโดยตลาดหลักทรัพย์แห่งประเทศไทยหรือบริษัทย่อยของตลาดหลักทรัพย์แห่งประเทศไทย และตลาดหลักทรัพย์แห่ง
                                                    ประเทศไทยถือหุ้นไม่ว่าทางตรงหรือทางอ้อมเกินกว่าร้อยละ 50 (ห้าสิบ) ของทุนจดทะเบียนของบริษัท <a href='https://www.set.or.th/th/about/overview/share_p1.html' target='_blank'>(https://www.set.or.th/th/about/overview/share_p1.html)</a>
                                                </small>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </form>
                        </CardBox>
                        <div className='d-flex mt-3 justify-content-between'>
                            <div></div>
                            <div><PButton onClick={handleSubmit} id="button-submit" disabled={!pdpaConsent || !marketingConsent}>ส่งข้อมูล</PButton></div>
                        </div>
                    </>
                )}
                {(loading) &&
                    <>
                        <div className='d-flex justify-content-center'><img src={loadinglogo} width="30" height="30" className="d-inline-block align-top" alt="" /></div>
                    </>
                }
                <AlertContainer floatingTime={3000} />
            </PartnerContainer>
        </div>
    )
}

export default PartnerRegistForm