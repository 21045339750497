import { useEffect, useState } from 'react'
import { useDispatch, useStore } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import MainMenu from '../../menu';
import loadinglogo from '../../../../img/loading.gif';
import PartnerTab from '../../../tab/partner-tab';
import PartnerActivityTab from '../../../tab/partner-activity-tab';
import PartnerActivityReceiveMediaOverview from './PartnerActivityReceiveMediaOverview';
import { ListPartnerMediaResponse, PartnerMediaOverview, PartnerMediaRequest, PartnerMediaResponse, SearchPartnerMedia } from '../../../../model/response/partner-activity-media';
import { queryclear } from '../../../../store/search/actions';
import PartnerActivityReceiveMediaManagement from './PartnerActivityReceiveMediaManagement';
import { listActiveKnowledgeCategory, listActiveKnowledgeSubCategory } from '../../../../store/publicPartner/action';
import { BasicResponse, createMediaType } from '../../../../api/define';
import { createPartnerMedia, deletePartnerMedia, getReceiveMediaOverview, listPartnerMedia, updatePartnerMedia } from '../../../../store/partner/actions';
import { AlertContainer, alert } from 'react-custom-alert';
import PartnerActivityReceiveMediaModal from './PartnerActivityReceiveMediaModal';
import ConfirmModal from '../../../share/ConfirmModal';
import { KnowledgeDepartmentResponse } from '../../../../model/response/manage-knowledge';
import { listKnowledgeDepartment } from '../../../../store/editpartner/actions';

type Props = {}

const PartnerActivityReceiveMedia = (props: Props) => {
    const [init, setInit] = useState(1);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [item, setItem] = useState<PartnerMediaOverview>();
    const [categoryList, setCategoryList] = useState<BasicResponse[]>([]);
    const [departmentList, setDepartmentList] = useState<BasicResponse[]>([]);
    const [subCategoryList, setSubCategoryList] = useState<BasicResponse[]>([]);
    const [modalShow, setModalShow] = useState<boolean | undefined>();
    const [confirmModalShow, setConfirmModalShow] = useState<boolean | undefined>();
    const [deletePartnerMediaId, setDeletePartnerMediaId] = useState<number | undefined>();
    const [modalItem, setModalItem] = useState<PartnerMediaResponse>();
    const [list, setList] = useState<ListPartnerMediaResponse | undefined>();
    const [searchQuery, setSearchQuery] = useState<SearchPartnerMedia>({page: 0, size: 10});


    const mediaType = createMediaType();
    const store = useStore();
    const history = useHistory();
    let { id } = useParams<{ id: string }>();
    const dispatch = useDispatch();

    const handleProfile = () => {
        history.push("/admin/partner/profile/" + id);
    }
    const handleActivityOrganizeTraining = () => {
        history.push("/admin/partner/activity/" + id + "/organize-training");
    }
    const handleActivityReceiveMedia = () => {
        history.push("/admin/partner/activity/" + id + "/receive-media");
    }
    const handlePackage = () => {
        history.push("/admin/partner/package/" + id);
    }


    const handleAdd = () => {
        setModalItem(undefined);
        setModalShow(true);
    }

    const handleEdit = (item: PartnerMediaResponse) => {
        setModalItem(item);
        dispatch(listActiveKnowledgeSubCategory(item.categoryId!));
        setModalShow(true);
    }

    const handleDelete = (id: number) => {
        setDeletePartnerMediaId(id);
        setConfirmModalShow(true);
    }

    const hideModal = () => {
        setModalItem(undefined);
        setModalShow(false);
    }

    const hideConfirmModal = () => {
        setDeletePartnerMediaId(undefined);
        setConfirmModalShow(false);
    }

    const handleDeletedMedia = () => {
        if (deletePartnerMediaId) {
            dispatch(deletePartnerMedia(parseInt(id), deletePartnerMediaId));
            setDeletePartnerMediaId(undefined);
        }
        setConfirmModalShow(false);
    }

    const handleCreatedOrEditedMedia = (item: PartnerMediaRequest) => {
        if (item) {
            if (item.id) {
                dispatch(updatePartnerMedia(parseInt(id), item));
            } else {
                dispatch(createPartnerMedia(parseInt(id), item));
            }
        }
        setModalShow(false);
    }

    const onSearch = (item: SearchPartnerMedia) => {
        setSearchQuery(item);
        dispatch(listPartnerMedia(parseInt(id), item.page, item.size, item?.name, item?.categoryId, item?.mediaType));
    }

    const handleSearch = () => {
        dispatch(listPartnerMedia(parseInt(id), searchQuery.page, searchQuery.size, searchQuery?.name, searchQuery?.categoryId, searchQuery?.mediaType));
    }

    const handleSelectCategory = (id: number) => {
        dispatch(listActiveKnowledgeSubCategory(id));
        setSubCategoryList([]);
    }

    const handleResetModal = () => {
        setSubCategoryList([]);
    }

    const getMediaOverview = () => {
        dispatch(getReceiveMediaOverview(parseInt(id)));
    }

    useEffect(() => {
        const unsubscribe = store.subscribe(() => {
            let res = store.getState().SearchReducer;
            if (['public-partner-active-sub-category', 'public-partner-active-category'].includes(res.page)) {
                if ((res.isLoaded) && (!res.isUpdated)) {
                    setError('');
                    if (res.data != null) {
                        setInit(1);
                        if (res.table === 'public-partner-active-category') {
                            setCategoryList(res.data)
                        }
                        if (res.table === 'public-partner-active-sub-category') {
                            setSubCategoryList(res.data)
                        }
                    }
                } else if (res.isUpdating || res.isLoading) {
                    setError('');
                } else if (res.isUpdated) {
                    setError('');
                    dispatch(queryclear());
                } else if (res.isFailed) {
                    setError(res.data.message);
                    alert({ message: res.data.message, type: 'error' });
                }
            } else {
                if (res.isLoading) {
                    setError('');
                    setLoading(true);
                } else if ((res.isLoaded) && (!res.isUpdated)) {
                    setError('');
                    setLoading(false);
                    if (res.data != null) {
                        setInit(1);
                        if (res.table === 'public-partner-active-category') {
                            setCategoryList(res.data)
                        }
                        if (res.table === 'public-partner-active-sub-category') {
                            setSubCategoryList(res.data)
                        }
                        if (res.table === 'list_knowledge_department') {
                            setDepartmentList(res.data.map((m: KnowledgeDepartmentResponse) => ({ label: m.name, value: m.id })));
                        }
                        if (res.table === 'partner_media_overview') {
                            setItem(res.data);
                        }
                        if (res.table === 'partner_media_search') {
                            setList(res.data);
                        }
                        if (['partner_media_delete', 'partner_media_update', 'partner_media_create'].includes(res.table)) {
                            handleSearch();
                            getMediaOverview();
                        }
                    }
                } else if (res.isUpdating) {
                    setError('');
                    setLoading(true);
                } else if (res.isUpdated) {
                    setError('');
                    setLoading(false);
                    dispatch(queryclear());
                } else if (res.isFailed) {
                    setError(res.data.message);
                    setLoading(false);
                    alert({ message: res.data.message, type: 'error' });
                }
            }
        })
        dispatch(listActiveKnowledgeCategory());
        dispatch(listKnowledgeDepartment());
        getMediaOverview();
        handleSearch();

        return unsubscribe;
    }, [])

    return (
        <div>
            <MainMenu>
                <div>
                    <div className="query-wrapper">
                        <br />
                        <div className="yellow-button" style={{ textAlign: "left", borderTopLeftRadius: "30px", borderTopRightRadius: "30px", height: "70px", paddingTop: "15px", paddingLeft: "15px" }}><h4 className="header">ข้อมูลองค์กร</h4></div>
                        {(init === 1) &&
                            <>
                                <br />
                                {PartnerTab(2, true, handleProfile, handleActivityOrganizeTraining, handlePackage)}
                                <div className='my-2'>
                                    {PartnerActivityTab(2, true, handleActivityOrganizeTraining, handleActivityReceiveMedia)}
                                </div>
                                <PartnerActivityReceiveMediaOverview item={item} mediaType={mediaType} />
                                <PartnerActivityReceiveMediaManagement
                                    category={categoryList}
                                    mediaType={mediaType}
                                    department={departmentList}
                                    list={list}
                                    loading={loading}
                                    onSearch={onSearch}
                                    onAdd={handleAdd}
                                    onEdit={(item: PartnerMediaResponse) => handleEdit(item)}
                                    onDelete={(id: number) => handleDelete(id)}
                                />
                            </>
                        }
                        {(((init < 1) || (loading)) && (error === "")) && (modalShow !== true) &&
                            <div style={{ textAlign: "center" }}><img src={loadinglogo} width="30" height="30" className="d-inline-block align-top" alt="" /></div>
                        }
                        {(error !== "") &&
                            <div style={{ color: 'red', textAlign: "center" }}>{error}</div>
                        }
                    </div>
                    <PartnerActivityReceiveMediaModal
                        show={modalShow}
                        onOpen={modalShow}
                        modalItem={modalItem}
                        category={categoryList}
                        mediaType={mediaType}
                        department={departmentList}
                        subCategory={subCategoryList}
                        onHide={hideModal}
                        onClear={handleResetModal}
                        onSelectCategory={(categoryId: number) => handleSelectCategory(categoryId)}
                        onOk={(item: PartnerMediaRequest) => handleCreatedOrEditedMedia(item)} />
                    <ConfirmModal
                        title={'กรุณากด "ยืนยัน" เพื่อลบรายการ'}
                        show={confirmModalShow}
                        onHide={hideConfirmModal}
                        onOk={handleDeletedMedia} />
                </div>
            </MainMenu>
            <AlertContainer floatingTime={3000} />
        </div>
    )
}

export default PartnerActivityReceiveMedia