import { Dispatch } from "redux";
import { ActionType, SearchActionType } from "../search/types";
import { PageStudentAPI } from "../../api/page-student-api";
import { dispatchError } from "../search/actions";

export const getCourseList = (assignmentType: 'process' | 'coming' | 'completed', page: number, size: number|undefined, sort: string | undefined) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    PageStudentAPI.getAssignmentProcess(assignmentType, page, size, sort).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "assignment_" + assignmentType,
            page: "student_course",
            action: ActionType.QUERY
        });
    }, function (error) {
        dispatchError("student_course", dispatch, error);
    })
};