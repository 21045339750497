import { CircularProgress } from '@material-ui/core';
import React, { useEffect } from 'react'
import { Button, Modal, ModalProps } from 'react-bootstrap'

type Props = ModalProps &
{
    body: any;
}
const PartnerLoadingModal = (props: Props) => {
    useEffect(()=>{
        console.log(props.body)
    },[])
    return (
        <Modal
            {...props}
            dialogClassName="modal-40w"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            centered
        >
            <Modal.Body>
                <div className='d-flex flex-column'>
                    <div className='text-center' style={{paddingBlock:40}}><CircularProgress style={{'color': '#628395'}} size="80px"/></div>
                    <div className='text-center pb-5'>{props.body}</div>
                </div>

            </Modal.Body>
        </Modal>
    )
}

export default PartnerLoadingModal