import React, { useState } from 'react'
import { BranchInfo, Faculty, PartnerProfileListResponse, Transition } from '../../../../model/response/partner-profile';
import ExpandMore from '@material-ui/icons/ExpandMore'
import ExpandLess from '@material-ui/icons/ExpandLess'
import AddIcon from '@material-ui/icons/Add';
import { Button } from 'react-bootstrap';
import iconTrashRed from '../../../../img/iconTrashRed.png'
import ConfirmModal from '../../../share/ConfirmModal';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { createSaveStateNoNew } from '../../../../api/define';
import Select from 'react-select';
import { customStateStyles, greenStateStyles } from '../../../tab/search-component';
type Props = {
    parnerProfileList: PartnerProfileListResponse;
    success: boolean;
    submitted: boolean;
    setParnerProfileListChange: (parnerProfileList: PartnerProfileListResponse) => void;
    readonly: boolean;
    state?: string;
    submittedMerge?: boolean;
    hideDropdown?: boolean;
    nextAction?: number;
    nextActionItem?: Transition[];
}

const PartnerVocation = (props: Props) => {
    const [expand, setExpand] = useState(true);
    const [itemDeleteBranch, setItemDeleteBranch] = useState<any>();
    const [confirmDeleteBranch, setConfirmDeleteBranch] = useState(false);
    const [tabColor, setTabColor] = useState(props.state ? props.state == 'old' ? 'gray-button' : 'yellow-button' : 'yellow-button');
    const [borderColor, setBorderColor] = useState(props.state ? props.state == 'old' ? '#dddddd' : '#fab232' : '#fab232')
    const getRequire = (value: string | boolean | undefined | any[] | null | number) => {
        let moveTransition;
        if (props.nextActionItem) {
            moveTransition = props?.nextActionItem.find(x => x.id == props.nextAction);
        }
        let isSendFormToCustomer = moveTransition?.transition == 'ส่งฟอร์มให้กรอกข้อมูล'
        if (isSendFormToCustomer) {
            return false;
        } else {
            return (props.submitted && (value == null || value == undefined || value == ''))
        }
    }
    const setTeacherNo = (e: any) => {
        var newItem = { ...props.parnerProfileList };
        newItem.teacherNo = e;
        props.setParnerProfileListChange(newItem);
    }
    const setPersonnel = (e: any) => {
        var newItem = { ...props.parnerProfileList };
        newItem.personnel = e;
        props.setParnerProfileListChange(newItem);
    }
    const setStudentNo = (e: any) => {
        var newItem = { ...props.parnerProfileList };
        newItem.studentNo = e;
        props.setParnerProfileListChange(newItem);
    }
    const handleAddFaculty = () => {
        var newItem = { ...props.parnerProfileList };
        if (newItem?.faculty == null) {
            newItem.faculty = []
        }
        let newFaculty: Faculty = {
            facultyInfo: [],
            noBranch: false,
            branchInfo: [{
                id: null,
                branchName: null,
                personnel: null,
                teacherNo: null,
                studentNo: null,
                relateFinance: false
            }]
        }
        newItem.faculty.push(newFaculty)
        props.setParnerProfileListChange(newItem);
    }
    const setBranchName = (value: any, branchIndex: number, index: number) => {
        var newItem = { ...props.parnerProfileList };
        newItem.faculty[index].branchInfo[branchIndex].branchName = value;
        props.setParnerProfileListChange(newItem);
    }
    const setBranchTeacherNo = (value: any, branchIndex: number, index: number) => {
        var newItem = { ...props.parnerProfileList };
        newItem.faculty[index].branchInfo[branchIndex].teacherNo = value;
        props.setParnerProfileListChange(newItem);
    }
    const setBranchPersonnel = (value: any, branchIndex: number, index: number) => {
        var newItem = { ...props.parnerProfileList };
        newItem.faculty[index].branchInfo[branchIndex].personnel = value;
        props.setParnerProfileListChange(newItem);
    }
    const setBranchStudentNo = (value: any, branchIndex: number, index: number) => {
        var newItem = { ...props.parnerProfileList };
        newItem.faculty[index].branchInfo[branchIndex].studentNo = value;
        props.setParnerProfileListChange(newItem);
    }
    const setBranchReleateFinance = (value: any, branchIndex: number, index: number) => {
        var newItem = { ...props.parnerProfileList };
        newItem.faculty[index].branchInfo[branchIndex].relateFinance = value;
        props.setParnerProfileListChange(newItem);
    }
    const setFacultyNoBranch = (value: any, index: number) => {
        var newItem = { ...props.parnerProfileList };
        newItem.faculty[index].noBranch = value;
        newItem.faculty[index].branchInfo = [];
        props.setParnerProfileListChange(newItem);
    }
    const handleDeleteBranch = (index: number, branchIndex: number) => {
        const item = {
            index: index,
            branchIndex: branchIndex
        }
        setItemDeleteBranch(item as any);
        setConfirmDeleteBranch(true);

    }

    const deleteBranch = () => {
        var newItem = { ...props.parnerProfileList };
        if (itemDeleteBranch) {
            const { index, branchIndex } = itemDeleteBranch as any
            newItem.faculty[index].branchInfo.splice(branchIndex, 1);
            props.setParnerProfileListChange(newItem);
        }
        setItemDeleteBranch(undefined);
        setConfirmDeleteBranch(false);
    }
    const handleAddBranch = (index: number) => {
        var newItem = { ...props.parnerProfileList };
        if (newItem.faculty[index].branchInfo == null) newItem.faculty[index].branchInfo = [];
        let branch: BranchInfo = {
            id: null,
            branchName: null,
            personnel: null,
            teacherNo: null,
            studentNo: null,
            relateFinance: false
        }
        newItem.faculty[index].noBranch = false;
        newItem.faculty[index].branchInfo.push(branch);
        props.setParnerProfileListChange(newItem);
    }
    const getRequireMerge = (value: string | boolean | undefined | any[] | null | number) => {
        return (props.submittedMerge && (value == null || value == undefined))
    }
    const setMergeStateOrganizationInfo = (e: any) => {
        var newItem = { ...props.parnerProfileList };
        newItem.mergeStateOrganizationInfo = e;
        props.setParnerProfileListChange(newItem);
    }
    const getMergeStateOrganizationInfo = () => {
        let dropdown = createSaveStateNoNew()
        for (var i = 0; i < dropdown.length; i++)
            if (dropdown[i].value === props.parnerProfileList?.mergeStateOrganizationInfo) {
                return { label: dropdown[i].label, value: props.parnerProfileList?.mergeStateOrganizationInfo };
            }
        return null;
    }

    return (
        <React.Fragment>
            <div className="text-left" style={{ position: "relative", marginBottom: 5 }}>
                <div className={'d-flex ' + tabColor} style={{ padding: "15px", borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}>
                    {((expand === undefined) || (expand === false)) &&
                        <div onClick={(e) => setExpand(true)}><ExpandMore className="pointer mr-2" ></ExpandMore></div>
                    }
                    {(expand === true) &&
                        <div onClick={(e) => setExpand(false)}><ExpandLess className="pointer mr-2" ></ExpandLess></div>
                    }
                    <label className='ml-2 my-auto'><span style={{ fontWeight: 600, marginRight: 10 }}>ข้อมูลองค์กร</span>{props.state && (<span style={{ fontWeight: 600, marginRight: 10 }}>{props.state == "old" ? "(เดิม)" : "(ใหม่)"}</span>)} </label>
                </div>
                {(expand === true) && (
                    <div style={{ padding: "15px", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px", border: "3px solid " + borderColor }}>
                        <div>
                            {props.state == 'new' && !props.hideDropdown && <div className='row mt-1 d-flex justify-content-end mr-2'>
                                <span>
                                    <div style={{ fontWeight: 600 }}>Next Action<span className='text-danger'>*</span></div>
                                    <Select
                                        isSearchable={true} isMulti={false} placeholder=""
                                        onChange={(e: any) => setMergeStateOrganizationInfo(e == null ? null : e?.value)}
                                        value={getMergeStateOrganizationInfo()}
                                        options={createSaveStateNoNew()}
                                        styles={getRequireMerge(props.parnerProfileList?.mergeStateOrganizationInfo) ? customStateStyles : greenStateStyles}
                                    />
                                </span>
                            </div>}
                            <div className='row mt-1'>
                                <label className='col-2 my-auto' style={{ fontWeight: 600 }}>คณาจารย์ ทั้งหมด<span className='text-danger'>*</span></label>
                                <input type="number" disabled={props.readonly} className={'form-control col-4 ' + (getRequire(props.parnerProfileList?.teacherNo) ? "is-invalid" : "")} value={props.parnerProfileList?.teacherNo} onChange={(e) => setTeacherNo(e.target.value)} id={"input-number-teacherNo"} />
                                <label className='col-2 my-auto' style={{ fontWeight: 600 }}>คน</label>
                            </div>
                            <div className='row mt-1'>
                                <label className='col-2 my-auto' style={{ fontWeight: 600 }}>บุคคลากร ทั้งหมด<span className='text-danger'>*</span></label>
                                <input type="number" disabled={props.readonly} className={'form-control col-4 ' + (getRequire(props.parnerProfileList?.personnel) ? "is-invalid" : "")} value={props.parnerProfileList?.personnel} onChange={(e) => setPersonnel(e.target.value)} id={"input-number-personnel"} />
                                <label className='col-2 my-auto' style={{ fontWeight: 600 }}>คน</label>
                            </div>
                            <div className='row mt-1'>
                                <label className='col-2 my-auto' style={{ fontWeight: 600 }}>นิสิต/นักศึกษา ทั้งหมด<span className='text-danger'>*</span></label>
                                <input type="number" disabled={props.readonly} className={'form-control col-4 ' + (getRequire(props.parnerProfileList?.studentNo) ? "is-invalid" : "")} value={props.parnerProfileList?.studentNo} onChange={(e) => setStudentNo(e.target.value)} id={"input-number-studentNo"} />
                                <label className='col-2 my-auto' style={{ fontWeight: 600 }}>คน</label>
                            </div>
                        </div>

                        <div className='row mt-1'>
                            <label className='col-2' style={{ fontWeight: 600 }}>รายละเอียดสาขา<span className='text-danger'>*</span></label>
                            <div className='d-flex flex-column col-10 pl-0'>
                                <div>
                                    <>
                                        {props.parnerProfileList?.faculty && props.parnerProfileList.faculty.map((faculty, index) => (
                                            <div key={"faculty" + index} className='gray-button mb-3' style={{ position: 'relative', padding: "15px", borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}>
                                                <React.Fragment >
                                                    <div>
                                                        <div className='d-flex mt-2'>
                                                            {!props.readonly && <div onClick={() => handleAddBranch(index)} style={{ cursor: 'pointer' }}><AddCircleOutlineIcon className="yellow mr-2" />สาขา</div>}
                                                            {props.readonly && <div>สาขา</div>}
                                                        </div>
                                                        <table className='table mt-3' style={{ fontSize: "20px" }}>
                                                            <thead className='black-button'>
                                                                {faculty?.branchInfo && faculty?.branchInfo.length > 0 && (
                                                                    <tr>
                                                                        <th style={{ width: '30%' }}>ชื่อสาขา</th>
                                                                        <th style={{ width: '10%' }}>คณาจารย์</th>
                                                                        <th style={{ width: '10%' }}>บุคคลากร</th>
                                                                        <th style={{ width: '15%' }}>นิสิต/นักศึกษา</th>
                                                                        <th style={{ width: '20%' }}>เป็นสาขาที่เกี่ยวข้องกับการเงิน</th>
                                                                        <th></th>
                                                                    </tr>
                                                                )}

                                                            </thead>
                                                            <tbody className='light-gray-button'>
                                                                {faculty?.branchInfo && faculty.branchInfo.map((branchInfo, branchInfoIndex) => (
                                                                    <tr>
                                                                        <td>
                                                                            <div className='d-flex'>
                                                                                <input type="text" disabled={props.readonly} className={'form-control ' + (getRequire(branchInfo.branchName) ? "is-invalid" : "")} value={branchInfo.branchName as any} onChange={(e) => setBranchName(e.target.value, branchInfoIndex, index)} />
                                                                                <span className='text-danger ml-2'>*</span>
                                                                            </div>

                                                                        </td>
                                                                        <td>
                                                                            <input type="number" disabled={props.readonly} className={'form-control ' + (getRequire(branchInfo.teacherNo) ? "is-invalid" : "")} value={branchInfo.teacherNo as any} onChange={(e) => setBranchTeacherNo(e.target.value, branchInfoIndex, index)} />
                                                                        </td>
                                                                        <td>
                                                                            <input type="number" disabled={props.readonly} className={'form-control ' + (getRequire(branchInfo.personnel) ? "is-invalid" : "")} value={branchInfo.personnel as any} onChange={(e) => setBranchPersonnel(e.target.value, branchInfoIndex, index)} />
                                                                        </td>
                                                                        <td>
                                                                            <input type="number" disabled={props.readonly} className={'form-control ' + (getRequire(branchInfo.studentNo) ? "is-invalid" : "")} value={branchInfo.studentNo as any} onChange={(e) => setBranchStudentNo(e.target.value, branchInfoIndex, index)} />
                                                                        </td>
                                                                        <td className='text-center form-check checkbox-lg'><input type="checkbox" disabled={props.readonly} className={'form-check-input mt-2'} checked={branchInfo.relateFinance} onChange={(e) => setBranchReleateFinance(e.target.checked, branchInfoIndex, index)} /></td>
                                                                        <td className='text-center'>
                                                                            {!props.readonly && <img src={iconTrashRed} width={20} height={20} id={"icon_trash_branch" + index + "_" + branchInfoIndex} style={{ cursor: 'pointer' }} onClick={e => { handleDeleteBranch(index, branchInfoIndex) }} className='mr-2' />}
                                                                        </td>
                                                                    </tr>
                                                                ))}

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </React.Fragment>
                                                <ConfirmModal title={'กรุณากด "ยืนยัน" เพื่อลบรายการ'} name={''} show={confirmDeleteBranch}
                                                    onHide={() => setConfirmDeleteBranch(false)} onOk={() => deleteBranch()} />
                                            </div>
                                        ))
                                        }</>
                                </div>
                            </div>
                        </div>
                    </div>)}
            </div>

        </React.Fragment>
    )
}

export default PartnerVocation