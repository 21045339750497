import { AnnouncementManageRequest } from './../../model/request/announce.model';
import { Dispatch } from 'redux';
import { AnnouncementAPI } from '../../api/announce-manage-api';
import { dispatchError } from "../search/actions";
import { ActionType, SearchActionType } from '../search/types';

export const listAnnouncement= (keyword: string | undefined, section: string | undefined, projectGroup: string | undefined,page:number,size:number,sort: string | undefined) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    AnnouncementAPI.listAnnouncement(keyword,section,projectGroup,page,size,sort).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "list_announce",
            page: "announce_manage",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("assignment",dispatch,error);
    })
};

export const listPublishAnnouncement= (keyword: string | undefined, section: string | undefined, projectGroup: string | undefined) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    AnnouncementAPI.listPublishAnnouncement(keyword,section,projectGroup).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "list_publish_announce",
            page: "announce_manage",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("assignment",dispatch,error);
    })
};

export const createAnnouncement= (request: AnnouncementManageRequest) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_UPDATING
    });
    AnnouncementAPI.createAnnouncement(request).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_UPDATED,
            payload: response.data,
            table: "create_announce",
            page: "announce_manage",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("announce_manage",dispatch,error);
    })
};

export const updatePublish= (id:number,section:string | undefined) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_UPDATING
    });
    AnnouncementAPI.updatePublish(id,section).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_UPDATED,
            payload: response.data,
            table: "publish_announce",
            page: "announce_manage",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("announce_manage",dispatch,error);
    })
};

export const updateUnPublish= (id:number,section:string | undefined) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_UPDATING
    });
    AnnouncementAPI.updateUnPublish(id,section).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_UPDATED,
            payload: response.data,
            table: "unpublish_announce",
            page: "announce_manage",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("announce_manage",dispatch,error);
    })
};

export const updatePriority= (id:number,section:string | undefined,priority:number) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_UPDATING
    });
    AnnouncementAPI.updatePriority(id,section,priority).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_UPDATED,
            payload: response.data,
            table: "update_priority_announce",
            page: "announce_manage",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("announce_manage",dispatch,error);
    })
};


export const updateAnnouncement= (request: AnnouncementManageRequest) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_UPDATING
    });
    AnnouncementAPI.updateAnnouncement(request).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_UPDATED,
            payload: response.data,
            table: "update_announce",
            page: "announce_manage",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("announce_manage",dispatch,error);
    })
};

export const deleteAnnouncement= (section: string,id: number) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_UPDATING
    });
    AnnouncementAPI.deleteAnnouncement(section,id).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_UPDATED,
            payload: response.data,
            table: "delete_announce",
            page: "announce_manage",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("announce_manage",dispatch,error);
    })
};