import { Dispatch } from 'redux';
import { ActionType, SearchActionType } from '../search/types';
import { PartnerAPI } from '../../api/partner-api';
import { TrainerAPI } from '../../api/trainer-api';
import { dispatchError } from '../search/actions'; 

export const listpartner = () => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING,
        page: "trainer"
    });
    PartnerAPI.list("","",null,0,0,null).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "partner",
            page: "trainer",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("partner",dispatch,error);
    })
};
export const listPartnerWithOutPage = () => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING,
        page: "trainer"
    });
    PartnerAPI.listWithoutPage().then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "partner_with_out_page",
            page: "trainer",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("trainer",dispatch,error);
    })
}

export const listtrainer = (name: string,param: any[],page: number,limit: number) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING,
        page: "trainer"
    });
    TrainerAPI.listPage(name,param,page,limit).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "trainer",
            page: "trainer",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("trainer",dispatch,error);
    })
};



export const gettrainer = (id: number) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING,
        page: "trainer"
    });
    TrainerAPI.get(id).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "trainer",
            page: "trauner",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("trainer",dispatch,error);
    })
};


export const createtrainer = (item: any) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_UPDATING,
        page: "trainer"
    });
    TrainerAPI.create(item).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_UPDATED,
            payload: response.data,
            table: "trainer",
            page: "trainer",
            action: ActionType.UPDATE
        });
    }, function (error) {
        if ((error.response!==undefined) && (error.response.status===400)) {
            dispatch({
                type: SearchActionType.QUERY_ERROR,
                page: "trainer",
                payload: error.response.data.additional
            });
        } else 
       dispatchError("trainer",dispatch,error);
    })
};

export const updatetrainer = (id: number,item: any) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_UPDATING,
        page: "trainer"
    });
    TrainerAPI.update(id,item).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_UPDATED,
            payload: response.data,
            table: "trainer",
            page: "trainer",
            action: ActionType.UPDATE
        });
    }, function (error) {
        if ((error.response!==undefined) && (error.response.status===400)) {
            dispatch({
                type: SearchActionType.QUERY_ERROR,
                page: "trainer",
                payload: error.response.data.additional
            });
        } else 
       dispatchError("trainer",dispatch,error);
    })
};

export const deletetrainer = (id: number) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_UPDATING,
        page: "trainer"
    });
    TrainerAPI.delete(id).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_UPDATED,
            payload: response.data,
            table: "trainer",
            page: "trainer",
            action: ActionType.DELETE
        });
    }, function (error) {
       dispatchError("trainer",dispatch,error);
    })
};