import { apiClient } from './axios-intercept';
import axios from 'axios';
import { DEFAULT_SERVER } from './conf';
import { alert } from 'react-custom-alert';

const PAGE_STUDENT = "/api/v1/page/student";

const LANDING_PAGE_STUDENT_URL = DEFAULT_SERVER + PAGE_STUDENT+ "/landing";
const ASSIGNMENT_PAGE_STUDENT_URL = DEFAULT_SERVER + PAGE_STUDENT+ "/assignment";
const RESULT_PAGE_STUDENT_URL = DEFAULT_SERVER + PAGE_STUDENT+ "/result";
const DOCUMENT_PAGE_STUDENT_URL = DEFAULT_SERVER + PAGE_STUDENT+ "/file/assignment";
const CONSENT_PAGE_STUDENT_URL = DEFAULT_SERVER + PAGE_STUDENT+ "/file/consent";
const SET_CONSENT_PAGE_STUDENT_URL = DEFAULT_SERVER + PAGE_STUDENT+ "/consent";

export const GET_ASSIGNMENT_PAGE_STUDENT_URL = DEFAULT_SERVER + PAGE_STUDENT+ "/file/assignment";
export const GET_DOC_PAGE_STUDENT_URL = DEFAULT_SERVER + PAGE_STUDENT+ "/file/doc";
export const GO_ELEARNING_URL=DEFAULT_SERVER + "/api/v1/authentication/student/elearning";
const ASSIGNMENT_PROCESS = DEFAULT_SERVER + "/api/v1/page/student/assignment/process";
const ASSIGNMENT_COMING = DEFAULT_SERVER + "/api/v1/page/student/assignment/coming";
const ASSIGNMENT_COMPLETED = DEFAULT_SERVER + "/api/v1/page/student/assignment/completed";
export class PageStudentAPI {

    public static landing = async (search: string,auth: boolean): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=LANDING_PAGE_STUDENT_URL;
            var client=(auth)?apiClient:axios; 
            client.get(s+((search.length>0)?"?search="+encodeURIComponent(search):""),
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                console.log("landing response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("landing error:", err);
                return reject(err); 
            })
        })
    }

    public static assignment = async (id:string): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=ASSIGNMENT_PAGE_STUDENT_URL;
            apiClient.get(s+"/"+id,
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                console.log("assignment response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("assignment error:", err);
                return reject(err); 
            })
        })
    }

    public static result = async (search: string,page:number,limit:number): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=RESULT_PAGE_STUDENT_URL;
            apiClient.get(s+"?"+((search.length>0)?"search="+encodeURIComponent(search):"")+"&page="+page+"&limit="+limit,
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                console.log("result response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("result error:", err);
                return reject(err); 
            })
        })
    }

    public static uploadAssignment = async (documentId: string,file: FileList): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var formData = new FormData();
            formData.append("file", file[0]);
            var s=DOCUMENT_PAGE_STUDENT_URL+"/"+documentId;
            apiClient.post(s,formData
                ,{//options
                    headers: {
                        "Accept": "application/json"
                    }
                })
            .then((response) => {
                console.log("upload assignment response : ", response);
                alert({ message: 'ส่งงานเข้าสู่ระบบเรียบร้อย', type: 'success' });
                return resolve(response);
            }).catch(err => {
                console.log("upload assignment error:", err);
                return reject(err); 
            })
        })
    }

    public static getconsent = async (documentId: string): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=CONSENT_PAGE_STUDENT_URL+"/"+documentId;
            apiClient.get(s
                ,{//options
                    headers: {
                        "Accept": "application/json"
                    }
                })
            .then((response) => {
                console.log("get consent response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("get consent error:", err);
                return reject(err); 
            })
        })
    }

    public static setconsent = async (classCourseId: string,result: boolean): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=SET_CONSENT_PAGE_STUDENT_URL+"/"+classCourseId;
            apiClient.post(s,{
                answer: result
                },{//options
                    headers: {
                        "Accept": "application/json"
                    }
                })
            .then((response) => {
                console.log("set consent response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("set consent error:", err);
                return reject(err); 
            })
        })
    }

    public static getAssignmentProcess =async (assignmentType: 'process'|'coming'|'completed',page:number,size:number|undefined,sort: string | undefined): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s
            if(assignmentType=== 'process'){
                s = ASSIGNMENT_PROCESS;
            }else if(assignmentType=== 'coming'){
                s = ASSIGNMENT_COMING;
            }else{
                s = ASSIGNMENT_COMPLETED
            }
           
            var queryParams:string[] = [];
            queryParams.push(`page=${page}`);
            if(size){
                queryParams.push(`size=${size}`);
            }
            
            if(sort) {
                queryParams.push(`sort=${sort}`)
            }
            
            apiClient.get(s+'?'+queryParams.join('&'),
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                return resolve(response);
            }).catch(err => {
                console.log("getAssignmentProcess error:", err);
                return reject(err); 
            })
        })
    }
}