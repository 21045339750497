import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalProps } from 'react-bootstrap';
import styled from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';
import { Controller, useForm } from 'react-hook-form';
import { ProjectGroupResponse, ProjectManagementInfoResponse } from '../../model/response/project.model';
import { useDispatch, useStore } from 'react-redux';
import { queryclear } from '../../store/search/actions';
import { addProjectInfo, getListOrganization, getProjectCode, listGroupActive, updatedProjectInfo } from '../../store/editprojectinfo/action';
import Select from 'react-select';
import { customStyles } from '../tab/search-component';
import { HRDepartment } from '../../model/response/organization.model';
import { ProjectManageInfoRequest } from '../../model/request/project.model';
import loadinglogo from '../../img/loading.gif';
import { AlertContainer, alert } from 'react-custom-alert';
const CustomModal = styled(Modal)`
    .description{
        color: #5F6062;
        margin-top: 10px;
    }
    .button-close{
        position: absolute;
        top:-30px;
        right: -30px;
        color:white;
        &:hover{
            cursor: pointer;
            transform:scale(1.3,1.3);
            -webkit-transform:scale(1.3,1.3);
            -moz-transform:scale(1.3,1.3);
        }
    }
`
type Props = ModalProps &
{
    editItem: ProjectManagementInfoResponse | undefined;
    onOk: (value: any) => void
}
export const EditProjectInfoModal = (props: Props) => {
    const { control,
        handleSubmit,
        formState: { errors },
        reset, getValues,
        setValue, register,
        resetField,
        watch, clearErrors } = useForm();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const watchProjectGroupType = watch("projectGroupType", "old");
    const [projectGroupList, setProjectGroupList] = useState<ProjectGroupResponse[]>([]);
    const [organizationList, setOrganizationList] = useState<HRDepartment[]>([]);
    const store = useStore();
    useEffect(() => {
        const unsubscribe = store.subscribe(() => {
            let res = store.getState().SearchReducer;
            if (res.isLoading) {
                setError('');
                setLoading(true);
            } else if ((res.isLoaded) && (!res.isUpdated)) {
                setError('');
                setLoading(false);
                if (res.data != null) {
                    if (res.table === "list_project_group_active") {
                        setProjectGroupList(res.data);
                    } else if (res.table === 'edit_project_info_project_code') {
                        setValue('code', res.data, { shouldValidate: true });
                    } else if (res.table === 'organizer') {
                        setOrganizationList(res.data);
                    }
                }
            } else if (res.isUpdating) {
                setError('');
            } else if (res.isUpdated) {
                setError('');
                dispatch(queryclear());
                alert({ message: 'Saved', type: 'success' });
                reset()
                props.onOk(true);
            } else if (res.isFailed) {
                if (res.data.success !== undefined) {
                    alert({ message: res.data.popup ?? 'Error', type: 'error' });
                    window.scrollTo(0, 0);
                }
                setError(res.data.message);
                setLoading(false);
                dispatch(queryclear());
            }
        })
        return unsubscribe;
    }, [])
    useEffect(() => {
        if (props.show) {
            dispatch(queryclear());
            dispatch(getListOrganization())
            dispatch(listGroupActive());
            if (props.editItem) {
                reset();
                setValue('projectGroupType', 'old');
                setValue('projectGroupId', props.editItem.projectGroupId);
                setValue('code',props.editItem.projectCode)
                setValue('projectName', props.editItem.projectName);
                setValue('organizerDeptId', props.editItem.organizerDeptId);
                setValue('status',  props.editItem.status);
                setValue('passedStep',props.editItem.passedStep)
                clearErrors();
            } else {
                initialForm();
            }

        }

    }, [props.show]);

    const hideModal = () => {
        reset()
        props.onHide()
    }
    const onSubmit = (data: any) => {
        const body: ProjectManageInfoRequest = {
            projectGroupId: data.projectGroupId,
            projectGroupName: data.projectGroupName,
            projectGroupShortName: data.projectGroupShortName,
            projectName: data.projectName,
            code: data.code,
            organizerDeptId: data.organizerDeptId,
            status: data.status,
            passedStep: data.passedStep
        }
        if (props.editItem) {
            dispatch(updatedProjectInfo(body,props.editItem.projectId));
        } else {
            dispatch(addProjectInfo(body));
        }

    }
    const initialForm = () => {
        reset();
        setValue('projectGroupType', 'old');
        setValue('status', 'ACTIVE');
        clearErrors();
    }
    const createProjectGroupFilter = (): { label: string, value: number }[] => {
        var res: any[] = [];
        for (var i = 0; i < projectGroupList.length; i++) {
            res.push({ label: projectGroupList[i].shortName + ":" + projectGroupList[i].name, value: projectGroupList[i].id });
        }
        return res;
    }
    const createPassedStepDropdown = (): any[] => {
        var res: any[] = [];
        for (var i = 0; i < 20; i++) {
            res.push({ label: String(i+1), value: i+1 });
        }
        return res;
    }
    const setProjectGroup = (value: number) => {
        let result = projectGroupList.find(x => x.id === value)
        if (result) {
            return { label: result.shortName + ':' + result.name, value: result.id }
        } else {
            return null
        }
    }
    const handleChangeProjectGroupId = (value: number | undefined) => {
        if (value) {
            dispatch(getProjectCode(value));
        } else {
            resetField("code")
        }
    }
    const setOrganization = (value: string) => {
        let result = organizationList.find(x => x.id === value)
        if (result) {
            return { label: result.name, value: result.id }
        } else {
            return null
        }
    }

    const setPassedStep = (value: string) => {
        if (value) {
            return { label: String(value), value: value }
        } else {
            return null
        }
    }
    const createOrganizationList = (): { label: string, value: string }[] => {
        var res: any[] = [];
        for (var i = 0; i < organizationList.length; i++) {
            res.push({ label: organizationList[i].name, value: organizationList[i].id });
        }
        return res;
    }
    const handleChangeShortName = (e: any) => {
        if (e.target.value) {
            setValue('projectGroupShortName', e.target.value.toUpperCase(), { shouldValidate: true });
            setValue('code', e.target.value.toUpperCase() + '0001', { shouldValidate: true })
        } else {
            resetField('code')
        }
    }
    const changeProjectGroup = (e: any) => {
        setValue('projectGroupType', e.target.value);
        setValue('projectGroupId', undefined);
        setValue('projectGroupName', undefined);
        setValue('projectGroupShortName', undefined);
        setValue('code', undefined)
    }
    return (<>
        <CustomModal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <a className='button-close' onClick={hideModal}><CloseIcon /></a>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Body>
                    <div className="row">
                        <label className='col-sm-3 col-form-label text-right'>กลุ่มโครงการ <span className="text-danger">*</span></label>
                        <div className="col-sm-9 col-form-label">
                            <div className="form-check form-check-inline">
                                <input {...register("projectGroupType")} onChange={changeProjectGroup} className="form-check-input" type="radio" name="projectGroupType" id="old" value="old" disabled={props.editItem ? true : false} />
                                <label className="form-check-label" htmlFor="old">กลุ่มโครงการที่มีอยู่แล้ว</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input {...register("projectGroupType")} onChange={changeProjectGroup} className="form-check-input" type="radio" name="projectGroupType" id="new" value="new" disabled={props.editItem ? true : false} />
                                <label className="form-check-label" htmlFor="new">กลุ่มโครงการใหม่</label>
                            </div>
                        </div>
                    </div>
                    {watchProjectGroupType === 'old' && (
                        <div className='row'>
                            <label className='col-sm-3 col-form-label'></label>
                            <div className="col-sm-9 col-form-label">
                                <Controller
                                    control={control}
                                    name={"projectGroupId"}
                                    rules={{ required: true }}
                                    render={({ field: { onChange, value, name, ref } }) => (
                                        <Select
                                            placeholder="กรุณาเลือกกลุ่มโครงการ"
                                            isClearable={true}
                                            isDisabled={props.editItem ? true : false}
                                            value={setProjectGroup(value)}
                                            name={name}
                                            styles={errors.projectGroupId ? customStyles : {}}
                                            options={createProjectGroupFilter()}
                                            onChange={(selectedOption: any) => {
                                                onChange(selectedOption?.value);
                                                handleChangeProjectGroupId(selectedOption?.value);
                                            }}
                                        />
                                    )}
                                />

                            </div>
                        </div>
                    )}
                    {watchProjectGroupType === 'new' && (
                        <>
                            <div className='row'>
                                <label className='col-sm-3 col-form-label text-right'>ชื่อกลุ่มโครงการ <span className="text-danger">*</span></label>
                                <div className="col-sm-9 col-form-label">
                                    <input {...register("projectGroupName", { required: true })} className={(errors.projectGroupName) ? 'form-control is-invalid' : 'form-control'} type="text" name="projectGroupName" id="projectGroupName" />
                                </div>
                            </div>
                            <div className='row'>
                                <label className='col-sm-3 col-form-label text-right'>ชื่อย่อกลุ่มโครงการ <span className="text-danger">*</span></label>
                                <div className="col-sm-9 col-form-label">
                                    <input {...register("projectGroupShortName", { required: true, minLength: 3, maxLength: 3 })} maxLength={3} onChange={handleChangeShortName} className={(errors.projectGroupShortName) ? 'form-control is-invalid' : 'form-control'} type="text" name="projectGroupName" id="projectGroupName" />
                                </div>
                            </div>
                        </>

                    )}
                    <div className="row">
                        <label className='col-sm-3 col-form-label text-right'>รหัสโครงการ <span className="text-danger">*</span></label>
                        <div className="col-sm-3 col-form-label">
                            <input {...register("code", { required: true })} className={(errors.code) ? 'form-control is-invalid' : 'form-control'} type="text" name="code" id="code" disabled />
                        </div>
                    </div>
                    <div className="row">
                        <label className='col-sm-3 col-form-label text-right'>ชื่อโครงการ <span className="text-danger">*</span></label>
                        <div className="col-sm-9 col-form-label">
                            <input {...register("projectName", { required: true })} className={(errors.projectName) ? 'form-control is-invalid' : 'form-control'} type="text" name="projectName" id="projectName" />
                        </div>
                    </div>
                    <div className='row'>
                        <label className='col-sm-3 col-form-label text-right'>ฝ่ายงาน <span className="text-danger">*</span></label>
                        <div className="col-sm-9 col-form-label">
                            <Controller
                                control={control}
                                name={"organizerDeptId"}
                                rules={{ required: true }}
                                render={({ field: { onChange, value, name, ref } }) => (
                                    <Select
                                        placeholder=""
                                        isClearable={true}
                                        value={setOrganization(value)}
                                        name={name}
                                        styles={errors.organizerDeptId ? customStyles : {}}
                                        options={createOrganizationList()}
                                        onChange={(selectedOption: any) => {
                                            onChange(selectedOption?.value);
                                        }}
                                    />
                                )}
                            />

                        </div>
                    </div>
                    <div className='row'>
                        <label className='col-sm-3 col-form-label text-right'>Step นับจำนวนคนที่ผ่าน <span className="text-danger">*</span></label>
                        <div className="col-sm-9 col-form-label">
                            <Controller
                                control={control}
                                name={"passedStep"}
                                rules={{ required: true }}
                                render={({ field: { onChange, value, name, ref } }) => (
                                    <Select
                                        placeholder=""
                                        isClearable={true}
                                        value={setPassedStep(value)}
                                        name={name}
                                        styles={errors.passedStep ? customStyles : {}}
                                        options={createPassedStepDropdown()}
                                        onChange={(selectedOption: any) => {
                                            onChange(selectedOption?.value);
                                        }}
                                    />
                                )}
                            />

                        </div>
                    </div>
                    <div className='row'>
                        <label className='col-sm-3 col-form-label text-right'>สถานะ <span className="text-danger">*</span></label>
                        <div className="col-sm-9 col-form-label">
                            <div className="form-check form-check-inline">
                                <input {...register("status")} className="form-check-input" type="radio" name="status" id="active" value="ACTIVE" />
                                <label className="form-check-label" htmlFor="active">ใช้งาน</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input {...register("status")} className="form-check-input" type="radio" name="status" id="inactive" value="INACTIVE" />
                                <label className="form-check-label" htmlFor="inactive">ไม่ใช้งาน</label>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={hideModal}>
                        Cancel
                    </Button>
                    <Button type='submit' className="btn btn-primary">
                        <div>
                            {(loading) &&
                                <div style={{ textAlign: "center" }}><img src={loadinglogo} width="30" height="30" className="d-inline-block align-top" alt="" /></div>
                            }
                            {(!loading) &&
                                <>Submit</>
                            }
                        </div>
                    </Button>
                </Modal.Footer>
            </form>
        </CustomModal>
        <AlertContainer floatingTime={3000} />
    </>)
}