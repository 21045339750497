import { useEffect, useState } from 'react';
import { useSelector,useDispatch, useStore } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import { result, liststatus } from '../../store/studentresult/actions';
import { queryclear } from  '../../store/search/actions';
import loadinglogo from '../../img/loading.gif';
import StudentHeader from './studentheader';
import StudentFooter from './studentfooter';
import { createPage } from '../tab/pagetab';
import { Util } from '../../api/util';

const StudentResult= () => {
    const [init,setInit] = useState(0);
    const [initStatus,setInitStatus] = useState(0);
    const [items,setItems] = useState<any>({items:[]});
    const [statusItems,setStatusItems] = useState<any>({});
    const [page,setPage] = useState(0);
    const [limit,setLimit] = useState(10);
    const [search,setSearch] = useState("");
    const [loading,setLoading] = useState(false)  
    const [error, setError] = useState('');
    const store = useStore();
    const dispatch = useDispatch();
    const history = useHistory(); 

    useEffect(() => {
        const unsubscribe = store.subscribe(() => {
            let res = store.getState().SearchReducer;
            if (res.isLoading) {
                setError('');
                setLoading(true);
            } else if ((res.isLoaded) && (!res.isUpdated)) {
                if (res.data!=null) {
                    if (res.table==="status") {
                        setStatusItems(res.data);
                        setInitStatus(1);
                    } else if (res.table==="result") {
                        setLoading(false);
                        setError('');
                        setItems(res.data);
                        setInit(1);
                    }
                } 
            } else if (res.isFailed) {
                setError(res.data.message);
                setLoading(false);
            }
          })
        return unsubscribe;
       }, [page,limit])

       useEffect(() => {
        dispatch(queryclear());
        dispatch(result("",0,limit));
        dispatch(liststatus())
       }, [])

    const handlePage = (p:number) => {
        setPage(p);
        dispatch(result(search,p,limit));
    }

    const handleLimit = (l:number) => {
        setLimit(l);
        dispatch(result(search,0,l));
    }

    const handleSearch = (s:string) => {
        setSearch(s);
        dispatch(result(s,page,limit));
    }

    const getResult = (r: string) =>{
        for(var i=0;i<statusItems.length;i++)
        {
            if (statusItems[i].code===r) return statusItems[i].name;
        }
        return r;
    }

    const getResultColor = (r: string) =>{
        if ((r==="P") || (r==="PX")) return "green";
        if (r==="F")  return "red";
        return "black";
    }


    const createTable = () =>{
        var res:any[]=[];
        for (var i=0;i<items.items.length;i++)
        {
            var item=items.items[i];
            res.push(<tr style={{backgroundColor:(i%2===1)?"white":"#F5F5F5"}}>
                <td className="text-left">{item.shortName} : {item.project} รอบที่ {item.session}<br/>
                <span className="yellow">{(item.courseShortName===null)?"":item.courseShortName+" :"} {item.courseName}</span>
                </td>
                <td>
                    {Util.datetostr(new Date(item.fromDate))} - {Util.datetostr(new Date(item.toDate))}
                </td>
                <td> <span style={{color:getResultColor(item.result)}}>
                    {getResult(item.result)}<br/>
                    { (item.resultDate!==null)?Util.datetostr(new Date(item.resultDate)):""}
                    </span>
                </td>
                <td>
                </td>
                
            </tr>);
        }
        return res;
    }

    const getTotalItem = () => {
        return items.totalItem;
    }

    const getTotalPage = () => {
        return items.totalPage;
    }

    return (<><div className="App-student">
    <div className="student-wrapper">
    <div className="student-wrapper-header-inner" style={{ background: "#38383D" }}>
    <StudentHeader/>  
    <input className="search-box" type="text" placeholder="ค้นหาคอร์สเรียน..." value={search} onChange={e=>{handleSearch(e.currentTarget.value)}}/>
    { (((init===0) || (loading)) && (error==="")) &&
        <div style={{ textAlign: "center" }}><img src={loadinglogo} width="30" height="30" className="d-inline-block align-top" alt="" /></div>
    }
    { (error!=="") &&
        <div style={{ color: 'red', textAlign: "center" }}>{error}</div>
    }
    </div>
    { (init===1) &&
     <>    
    <div className="student-wrapper-inner">
    <br/>
    <Table striped hover responsive="sm" cellPadding="0px" cellSpacing="0px" style={{
        borderTopStyle: "hidden",
        borderStyle: "hidden",
    }}>
                <thead className="black" style={{
        borderTopStyle: "hidden",
        borderStyle: "hidden",
    }}>
                    <tr style={{borderRadius:"10px"}}>
                        <th className='gray-button text-left w100'>หลักสูตร</th>
                        <th className='gray-button w100'>วันที่เรียน</th>                        
                        <th className='gray-button w100'>สถานะ</th>
                        <th className='gray-button w100'>วุฒิบัตร</th>
                    </tr>
                </thead>
                <tbody style={{fontSize:"22px"}}>
                    { createTable() }
                </tbody>
    </Table>
    <div className='row'>
        <div className='col-3' style={{textAlign:"left", justifyContent:"left"}}>
            ทั้งหมด {Util.integerWithCommas(getTotalItem())} รายการ
        </div>
        <div className='col-6 row' style={{textAlign:"center", justifyContent:"center"}}>
            {
                createPage(page,getTotalPage(),handlePage)
            }
        </div>        
        <div className='col-3' style={{justifyContent:"right",paddingRight:"0px"}}>
            <div className='row' style={{justifyContent:"right",paddingRight:"0px"}}>
        <div style={{textAlign:"right",marginTop:"auto",marginBottom:"auto"}}>จำนวนรายการต่อหน้า</div>&nbsp;
        <select className="form-control" style={{width:"80px"}} name="page" value={limit} onChange={e=>handleLimit(parseInt(e.target.value))}>
                            <option value="0">All</option>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
        </select>
        </div>
        </div>
    </div>
    </div>
    <br/>
    <br/>
    </>
    }
    <StudentFooter/>
    </div>
    </div>
    </>);
}

export default StudentResult;