import { apiClient } from "./axios-intercept";
import { DEFAULT_SERVER } from "./conf";
import { PartnerRegistrationStatus } from "./define";
import { Util } from "./util";

export const LOG_REQUEST = DEFAULT_SERVER + "/api/v1/partner-log-request";
const LOG_REQUEST_KNOWLEDGE_CAT_DP = DEFAULT_SERVER + "/api/v1/partner-log-request/category-dropdown";
const LOG_REQUEST_REGISTER_STATUS = DEFAULT_SERVER + "/api/v1/partner-log-request/register-status";

export class LogRequestAPI{

    public static listLogRequest = async (responsibleDeptIds:string[]|undefined, partnerName:string|undefined, subCategoryIds:number[]|undefined, fromDate:Date|null|undefined, toDate:Date|null|undefined, statusId:number|null|undefined, page:number, size:number, sort: string|undefined,logRequestPage:"NEW"|"HISTORY"): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=LOG_REQUEST;
            var queryParams:string[] = [];
            if(responsibleDeptIds) {
                responsibleDeptIds.forEach((x:any)=>{
                    queryParams.push(`responsibleDeptId=${x.value}`);
                })
                
            }
            if(partnerName) {
                queryParams.push(`partnerName=${partnerName}`);
            }
            if(subCategoryIds) {
                subCategoryIds.forEach((x:any)=>{
                    queryParams.push(`subCategoryId=${x}`);
                })
                
            }
            if(fromDate) {
                queryParams.push(`fromDate=`+ encodeURIComponent(Util.getLocalISOString(fromDate)));
            }
            if(toDate) {
                queryParams.push(`toDate=`+ encodeURIComponent(Util.getLocalISOString(toDate)));
            }
            if(statusId) {
                queryParams.push(`statusId=${statusId}`);
            }
            if(logRequestPage) {
                queryParams.push(`logRequestPage=${logRequestPage}`);
            }
    
            queryParams.push(`page=${page}`);
            queryParams.push(`size=${size}`);
            if(sort) {
                queryParams.push(`sort=${sort}`)
            }
            apiClient.get(s+'?'+queryParams.join('&'),
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                return resolve(response);
            }).catch(err => {
                console.log("list log-request error:", err);
                return reject(err); 
            })
        })
    }

    public static getKnowledgeCategoryDropdown = async (): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=LOG_REQUEST_KNOWLEDGE_CAT_DP;
            apiClient.get(s,
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                return resolve(response);
            }).catch(err => {
                console.log("list log-request error:", err);
                return reject(err); 
            })
        })
    }

    public static getRegisterStatus = async (historyPage:boolean|false): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=LOG_REQUEST_REGISTER_STATUS;
            var queryParams:string[] = [];
            if(historyPage) {
                queryParams.push(`historyPage=${historyPage}`);
            }
            apiClient.get(s+'?'+queryParams.join('&'),
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                return resolve(response);
            }).catch(err => {
                console.log("list log-request error:", err);
                return reject(err); 
            })
        })
    }
}
