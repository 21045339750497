import React from 'react'
import { useEffect, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useHistory } from 'react-router-dom';
import MainMenu from '../menu';
import Footer from '../adminfooter';
import { Button, Modal, Table } from 'react-bootstrap';
import { listorganization, listpartner, deletepartner, listorganizationGroup } from '../../../store/partner/actions';
import loadinglogo from '../../../img/loading.gif';
import excellogo from '../../../img/excel.png';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';
import ArrowDropDownIcon from '@material-ui/icons/ExpandMore';
import ArrowDropUpIcon from '@material-ui/icons/ExpandLess';
import { createPage } from '../../tab/pagetab';
import { Util } from '../../../api/util';
import { queryclear } from '../../../store/search/actions';
import { EXPORT_PARTNER_URL } from '../../../api/partner-api';
import Select, { SingleValue } from 'react-select';
import { SingleSearch } from '../../tab/search-component';
import { getAccessToken } from '../../../store/auth/selectors';
import { OrgGroup } from '../../../model/response/organization.model';
import { NOT_FOUND_LABEL } from '../../../api/label';
type Props = {}

const PartnerSearch = (props: Props) => {
    const {nameSearch,codeSearch,orgTypeSearch,orgGroupSearch,pageSearch,pageSizeSearch} = window.sessionStorage.getItem("searchPartnerDetails")? JSON.parse(window.sessionStorage.getItem("searchPartnerDetails") as any):"{nameSearch:'',codeSearch:'',pageSearch:'',pageSizeSearch:''}" as any;
    const token = useSelector(getAccessToken)
    const [init, setInit] = useState(0);
    const [name, setName] = useState(nameSearch?nameSearch:"")
    const [code, setCode] = useState(codeSearch?codeSearch:"")
    const [orgTypeItem, setOrgTypeItem] = useState<any[]>([]);
    const [orgGroupItem, setOrgGroupItem] = useState<OrgGroup[]>([])
    const [orgType, setOrgType] = useState<SingleValue<{ label: string, value: number }> | null>(orgTypeSearch?orgTypeSearch:null);
    const [orgGroup, setOrgGroup] = useState<SingleValue<{ label: string, value: number }> | null>(orgGroupSearch?orgGroupSearch:null)
    const [deleteId, setDeleteId] = useState("")
    const [page, setPage] = useState(pageSearch?pageSearch:0)
    const [limit, setLimit] = useState(pageSizeSearch?pageSizeSearch:10)
    const [totalItem, setTotalItem] = useState(0)
    const [totalPage, setTotalPage] = useState(0)
    const [loading, setLoading] = useState(false)
    const [items, setItems] = useState<any[] | null>(null)
    const [error, setError] = useState('');
    const store = useStore();
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        const unsubscribe = store.subscribe(() => {
            let res = store.getState().SearchReducer;
            if (res.page !== "partner") return;
            if (res.isLoading) {
                setError('');
                setLoading(true);
            } else if (res.isLoaded) {
                setError('');
                setLoading(false);
                if (res.data != null) {
                    if (res.table === "org_type") {
                        setInit(1);
                        setOrgTypeItem(res.data);
                    } else if (res.table === "org_type_group") {
                        setOrgGroupItem(res.data)
                    } else {
                        setItems(res.data.partnerResponseList);
                        setTotalPage(res.data.totalPage);
                        setTotalItem(res.data.totalItem);
                    }
                } else {
                    setError(NOT_FOUND_LABEL);
                }
            } else if (res.isNotFound) {
                setError(NOT_FOUND_LABEL);
                setItems([]);
                setLoading(false);
            } else if (res.isUpdated) {
                dispatch(queryclear);
                //dispatch(listpartner(name,code,orgType,page,limit));
            } else if (res.isFailed) {
                setError(res.data.message);
                setItems([]);
                setLoading(false);
            }
        });
        dispatch(listorganization(0, 0));
        dispatch(listorganizationGroup());
        if (name || code || orgType || orgGroup || page !== 0) {
            dispatch(listpartner(name, code, (orgType == null) ? null : orgType?.value.toString(), page, limit, (orgGroup == null) ? null : orgGroup?.value.toString()));
        }
        removeSession();
        window.scroll(0, 0);
        return unsubscribe;
    }, []);

    const removeSession = () => {
        sessionStorage.removeItem("searchDetails");
        // sessionStorage.removeItem("searchPartnerDetails");
        sessionStorage.removeItem("searchSurveyDetails");
        sessionStorage.removeItem("searchTrainerDetails");
        sessionStorage.removeItem("searchManageQuestion");
        sessionStorage.removeItem("searchLogRequest");
        sessionStorage.removeItem("searchLogRequestHistory");
      }

    const handleClear = () => {
        setName("");
        setOrgType(null);
        setOrgGroup(null);
        setPage(0);
        setLimit(10);
        setTotalItem(0);
        setTotalPage(0);
        setItems(null);
        window.sessionStorage.setItem("searchPartnerDetails",JSON.stringify({nameSearch:'',codeSearch:'',orgTypeSearch:null,orgGroupSearch:null,pageSearch:0,pageSizeSearch:10}))
        dispatch(queryclear());
    }

    const handleSubmit = () => {
        setPage(0);
        dispatch(listpartner(name, code, (orgType == null) ? null : orgType?.value.toString(), 0, limit, (orgGroup == null) ? null : orgGroup?.value.toString()));
        window.sessionStorage.setItem("searchPartnerDetails",JSON.stringify({nameSearch:name,codeSearch:code,orgTypeSearch:orgType,orgGroupSearch:orgGroup,pageSearch:0,pageSizeSearch:limit}))
    }

    const handleLimit = (l: number) => {
        setLimit(l);
        dispatch(listpartner(name, code, (orgType == null) ? null : orgType?.value.toString(), page, l, (orgGroup == null) ? null : orgGroup?.value.toString()));
        window.sessionStorage.setItem("searchPartnerDetails",JSON.stringify({nameSearch:name,codeSearch:code,orgTypeSearch:orgType,orgGroupSearch:orgGroup,pageSearch:page,pageSizeSearch:l}))
    }

    const handlePage = (l: number) => {
        setPage(l);
        dispatch(listpartner(name, code, (orgType == null) ? null : orgType?.value.toString(), l, limit, (orgGroup == null) ? null : orgGroup?.value.toString()));
        window.sessionStorage.setItem("searchPartnerDetails",JSON.stringify({nameSearch:name,codeSearch:code,orgTypeSearch:orgType,orgGroupSearch:orgGroup,pageSearch:l,pageSizeSearch:limit}))
    }

    const handleAdd = () => {
        history.push("/admin/partner/profile/0");
    }

    const handleEdit = (id: number) => {
        history.push("/admin/partner/profile/" + id);
    }

    const handleRow = (i: number) => {
        if (items !== null) {
            var newItems = [...items];
            newItems[i].show = ((newItems[i].show === undefined) || (newItems[i].show === false));
            setItems(newItems);
        }
    }

    const handleExcel = () => {
        window.open(EXPORT_PARTNER_URL + "?" + ((name === "") ? "" : "name=" + name) + ((code === "") ? "" : "&code=" + code) + ((orgType === null) ? "" : "&org_type=" + orgType?.value) + "&token=" + encodeURIComponent(token));
    }

    const handleDelete = (id: number) => {
        setDeleteId(id.toString());
    }

    const handleDeleteClose = () => {
        setDeleteId("");
    }

    const handleDeleteConfirm = () => {
        dispatch(queryclear());
        dispatch(deletepartner(parseInt(deleteId)));
        if ((items !== undefined) && (items !== null)) {
            var newItem = [];
            for (var i = 0; i < items.length; i++) {
                if (items[i].id !== parseInt(deleteId)) {
                    newItem.push(items[i]);
                }
            }
            setItems(newItem);
        }
        setDeleteId("");
    }

    const getOrgType = (id: number) => {
        var res = "";
        for (var i = 0; i < orgTypeItem.length; i++) {
            if (orgTypeItem[i].id == id) res = orgTypeItem[i].name;
        }
        return res;
    }

    const getOrgGroup = (id: number) => {
        var res = "";
        for (var i = 0; i < orgTypeItem.length; i++) {
            var orgGroup: any[] = orgTypeItem[i].orgGroup;
            for (var j = 0; j < orgGroup.length; j++) {
                if (orgGroup[j].id === id) res = orgGroup[j].name;
            }
        }
        return res;
    }

    const createOrgTypeSearchFilter = () => {
        var res: any[] = [];
        for (var i = 0; i < orgTypeItem.length; i++) {
            res.push({ label: orgTypeItem[i].name, value: orgTypeItem[i].id });
        }
        return res;
    }
    const createOrgGroupSearchFilter = () => {
        var res: any[] = [];
        if (orgType) {
            for (var i = 0; i < orgTypeItem.length; i++) {
                if (orgTypeItem[i].id === orgType.value) {
                    for (var j = 0; j < orgTypeItem[i].orgGroup.length; j++) {
                        var gitem = orgTypeItem[i].orgGroup[j];
                        res.push({ label: gitem.name, value: gitem.name });
                    }
                }
            }
            return res;
        } else {
            for (var i = 0; i < orgGroupItem.length; i++) {
                res.push({ label: orgGroupItem[i].name, value: orgGroupItem[i].name });

            }
            return res;
        }


    }

    const createBr = (n: number) => {
        var res: any[] = [];
        for (var j = 0; j < n; j++) {
            res.push(<br />);
        }
        return res;
    }

    const createContact = (i: number) => {
        var res: any[] = [];
        if (items != null) {
            if (items[i].show === true) {
                if ((items[i].contact !== undefined) && items[i].contact.length > 0) {
                    res.push(
                        <tr>
                            <th></th>
                            <th style={{ fontFamily: "DB Helvethaica Bold" }}>ชื่อ-นามสกุล</th>
                            <th style={{ fontFamily: "DB Helvethaica Bold" }}>เบอร์ที่ทำงาน</th>
                            <th style={{ fontFamily: "DB Helvethaica Bold" }}>เบอร์มือถือ</th>
                            <th style={{ fontFamily: "DB Helvethaica Bold" }}>e-Mail</th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>
                    );
                }
                for (var j = 0; j < ((items[i].contact === undefined) ? 0 : items[i].contact.length); j++) {
                    var c = items[i].contact[j];
                    res.push(
                        <tr style={{ backgroundColor: "#EFF5FD" }}>
                            <th></th>
                            <th >{c.firstName} {c.lastName}</th>
                            <th >{c.phone}</th>
                            <th >{c.mobile}</th>
                            <th >{c.email}</th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>);
                }
            }
        }
        return res;
    }

    return (<>
        <MainMenu>
            {(init === 1) &&
                <div>
                    <div className="query-wrapper">
                        <br />
                        <form>
                            <div style={{ background: '#EEEEEE', borderRadius: '5px', paddingLeft: "15px", paddingRight: "15px", paddingBottom: '20px', textAlign: "left" }}>
                                <div className="row">
                                    <div className="col-4">
                                        <label className="col-form-label">ชื่อองค์กร / ผู้ประสานงาน</label>
                                        <input className="form-control" type="text" name="name" onChange={e => setName(e.target.value)} value={name}></input>
                                    </div>
                                    <div className="col-4">
                                        <label className="col-form-label">ประเภทองค์กร</label>
                                        <SingleSearch
                                            isSearchable={true} isMulti={false} isClearable={true} placeholder=""
                                            onChange={(id: string, e: any) => setOrgType(e)}
                                            value={orgType}
                                            options={createOrgTypeSearchFilter()}
                                        />
                                    </div>
                                    <div className="col-4">
                                        <label className="col-form-label">หมวดหมู่</label>
                                        <SingleSearch
                                            isSearchable={true} isMulti={false} isClearable={true} placeholder=""
                                            onChange={(id: string, e: any) => setOrgGroup(e)}
                                            value={orgGroup}
                                            options={createOrgGroupSearchFilter()}
                                        />
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className="row" style={{ justifyContent: "center" }}>
                                <button type="button" className="btn btn-outline-primary" style={{ width: "150px" }}
                                    onClick={() => handleClear()} disabled={(loading)}>
                                    Clear
                                </button>&nbsp;
                                <button type="button" className="btn btn-primary" style={{ width: "150px" }}
                                    onClick={() => handleSubmit()} disabled={loading}>
                                    <div>
                                        {(loading) &&
                                            <div style={{ textAlign: "center" }}><img src={loadinglogo} width="30" height="30" className="d-inline-block align-top" alt="" /></div>
                                        }
                                        {(!loading) &&
                                            <>Search</>
                                        }
                                    </div>
                                </button>
                            </div>
                        </form>
                        <div className="row">
                            <div className="col-5" style={{ justifyContent: "left", textAlign: "left", marginTop: "auto", marginBottom: "auto" }}>
                                จำนวนทั้งหมด {totalItem} รายการ
                            </div>
                            <div className="col-1">
                            </div>
                            <div className="col-6 row" style={{ justifyContent: "right", paddingRight: "0px" }}>
                                <div style={{ textAlign: "right", marginTop: "auto", marginBottom: "auto" }}>จำนวนรายการต่อหน้า</div>&nbsp;
                                <select className="form-control" style={{ width: "80px" }} name="page" value={limit} onChange={e => handleLimit(parseInt(e.target.value))}>
                                    <option value="0">All</option>
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>&nbsp;
                                <Button type="button" className="btn btn-warning yellow-button"
                                    onClick={() => handleAdd()} >
                                    <AddIcon />
                                    New Partner
                                </Button>&nbsp;
                                <Button type="button" className="btn btn-success green-button" style={{ width: "50px" }}
                                    onClick={() => handleExcel()} >
                                    <img src={excellogo} height="32px" alt="excel" style={{ marginLeft: "-7px" }} />
                                </Button>
                            </div>
                        </div>
                        {((!loading) && (items !== null) && (items.length === 0) && (error === "")) &&
                            <><br /><br /><div className="text-center">{NOT_FOUND_LABEL}</div></>
                        }
                        {(error !== "") &&
                            <div style={{ color: 'red', textAlign: "center" }}>{error}</div>
                        }
                        {((items !== null) && (items.length >= 1)) &&
                            <>
                                <br />
                                <Table striped hover>
                                    <thead className="black-button">
                                        <tr>
                                            <th></th>
                                            <th className='text-left'>รหัสองค์กร</th>
                                            <th className='text-left'>ชื่อองค์กร</th>
                                            <th className='text-left'>ประเภทองค์กร</th>
                                            <th className='text-left'>หมวดหมู่</th>
                                            <th className='text-left'>ผู้ดูแลองค์กร</th>
                                            <th className='text-left'>แก้ไขล่าสุดโดย</th>
                                            <th className='text-left'>วันที่แก้ไขล่าสุด</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ fontSize:"23px" }}>
                                        {items.map((item, index) => {
                                            return <><tr key={item.id}>
                                                <td>  {((item.show === undefined) || (item.show === false)) &&
                                                    <div onClick={(e) => handleRow(index)}><ArrowDropDownIcon className="yellow-circle white pointer" ></ArrowDropDownIcon></div>
                                                }
                                                    {(item.show === true) &&
                                                        <div onClick={(e) => handleRow(index)}><ArrowDropUpIcon className="yellow-circle white pointer" ></ArrowDropUpIcon></div>
                                                    }
                                                </td>
                                                <td className='text-left'> {item.code} </td>
                                                <td className='text-left'> {item.name} </td>
                                                <td className='text-left'> {getOrgType(item.orgType)} </td>
                                                <td className='text-left'> {getOrgGroup(item.orgGroup)} </td>
                                                <td className='text-left'> {item.admin.map((itemAdmin: any, indexAdmin: number) => {
                                                    return <>{itemAdmin.name}<br /></>;
                                                })} </td>
                                                <td className='text-left'> {item.updateAdmin} </td>
                                                <td className='text-left'> {Util.datetimetostr(new Date(item.lastUpdateTime))} </td>
                                                <td> <div className="row"><div onClick={(e) => handleEdit(item.id)}><EditIcon className="yellow pointer" ></EditIcon></div>&nbsp;<div onClick={(e) => handleDelete(item.id)}><CancelIcon className="red pointer"></CancelIcon></div></div></td>
                                            </tr>
                                                {createContact(index)
                                                }</>
                                        })}
                                    </tbody>
                                </Table>
                                <br />
                                <div className="row" style={{ justifyContent: "center", position: "relative" }}>
                                    {createPage(page, totalPage, handlePage)}
                                    <div className="col-5" style={{ justifyContent: "left", textAlign: "left", position: "absolute", left: "0px" }}>
                                        จำนวนทั้งหมด {Util.integerWithCommas(totalItem)} รายการ
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                    <br />
                    {((items === null) || (items.length < 5)) &&
                        <>{createBr((items === null) ? 8 : 8 - items.length)}
                        </>
                    }
                    <Footer empty={((items === null) || (items.length === 0))} />
                </div>
            }
            {(init < 1) &&
                <>
                    {(error === "") &&
                        <div style={{ textAlign: "center", width: "1200px" }}><img src={loadinglogo} width="30" height="30" className="d-inline-block align-top" alt="" /></div>
                    }
                    {(error !== "") &&
                        <div style={{ color: 'red', textAlign: "center" }}>{error}</div>
                    }
                </>
            }
        </MainMenu>
        <Modal show={deleteId !== ""} onHide={handleDeleteClose}>
            <Modal.Header closeButton>
                <Modal.Title>ยืนยันการลบ?</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleDeleteClose}>
                    Cancel
                </Button>
                <Button variant="danger" onClick={handleDeleteConfirm}>
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>
    </>)
}

export default PartnerSearch