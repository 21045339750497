import { Dispatch } from 'redux';
import { ActionType, SearchActionType } from "../search/types";
import { dispatchError } from "../search/actions";
import { PublicPartnerAPI } from "../../api/public-partner-api";
import { PartnerCategory } from '../../model/response/partner-category';
import { PartnerKnowledgeRegistration } from '../../model/request/partner-knowledge-regis';
import { PartnerKnowledgeSummaryStructure } from '../../model/request/partner-knowledge';
import { PartnerFillMasterDataStructure } from '../../model/request/partner-fill-master-data';
import { PartnerProfileListResponse } from '../../model/response/partner-profile';

export const listKnowledgeCategory = () => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING,
        page: "public-partner-choose-topic"
    });
    PublicPartnerAPI.listKnowledgeCategory().then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "category",
            page: "public-partner-choose-topic",
            action: ActionType.QUERY
        });
    }, function (error) {
        dispatchError("partner", dispatch, error);
    })
};

export const listKnowledgeSummary = (categoryId:number,request:PartnerCategory[]) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING,
        page: "public-partner-summary-knowledge"
    });
    PublicPartnerAPI.listKnowledgeSummary(categoryId,request).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "summary-knowledge",
            page: "public-partner-summary-knowledge",
            action: ActionType.QUERY
        });
    }, function (error) {
        dispatchError("partner", dispatch, error);
    })
};

export const listKnowledgeSummaryByPublicCode = (publicAccessCode:string,categoryId:number| undefined) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING,
        page: "public-partner-summary-knowledge"
    });
    PublicPartnerAPI.listKnowledgeSummaryByPublicCode(publicAccessCode,categoryId).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "summary-knowledge-public-code",
            page: "public-partner-summary-knowledge",
            action: ActionType.QUERY
        });
    }, function (error) {
        dispatchError("partner", dispatch, error);
    })
};

export const registerKnowledge = (body:PartnerKnowledgeRegistration) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING,
        page: "public-partner-register-knowledge"
    });
    PublicPartnerAPI.registerKnowledge(body).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "register-knowledge",
            page: "public-partner-register-knowledge",
            action: ActionType.QUERY
        });
    }, function (error) {
        dispatchError("partner", dispatch, error);
    })
};

export const verifyEmailKnowledge = (body:PartnerKnowledgeRegistration) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING,
        page: "public-partner-verify-email-knowledge"
    });
    PublicPartnerAPI.verifyEmailKnowledge(body).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "verify-email-knowledge",
            page: "public-partner-register-knowledge",
            action: ActionType.QUERY
        });
    }, function (error) {
        dispatchError("partner", dispatch, error);
    })
};

export const requestOTP = (userKey:string, body:PartnerKnowledgeSummaryStructure) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING,
        page: "public-partner-otp"
    });
    PublicPartnerAPI.requestOTP(userKey, body).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "partner-otp",
            page: "public-partner-otp",
            action: ActionType.QUERY
        });
    }, function (error) {
        dispatchError("partner", dispatch, error);
    })
};

export const requestOTPForPartnerFillMasterData = (userKey:string,code:string,key:string, email:string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING,
        page: "public-partner-otp-fill-master-data"
    });
    PublicPartnerAPI.requestOTPForPartnerFillMasterData(userKey,code,key,email).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "log-request-otp",
            page: "public-partner-otp-fill-master-data",
            action: ActionType.QUERY
        });
    }, function (error) {
        dispatchError("partner", dispatch, error);
    })
};

export const getSummaryStructure = (body:PartnerKnowledgeSummaryStructure) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING,
        page: "public-partner-summary-structure"
    });
    PublicPartnerAPI.getSummaryStructure(body).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "summary-structure",
            page: "public-partner-summary-structure",
            action: ActionType.QUERY
        });
    }, function (error) {
        dispatchError("partner", dispatch, error);
    })
};

export const getLogRequestDetailByOtp = (userKey:string,code:string,key:string,refCode: string | undefined,otp:string | undefined) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING,
        page: "public-partner-otp-fill-master-data"
    });
    PublicPartnerAPI.getLogRequestDetailByOtp(userKey,code,key,refCode,otp).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "log-request-detail-by-otp",
            page: "public-partner-otp-fill-master-data",
            action: ActionType.QUERY
        });
    }, function (error) {
        dispatchError("partner", dispatch, error);
    })
};

export const getAdressInfomation = () => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING,
        page: "partner-profile"
    });
    PublicPartnerAPI.getAdressInfomation().then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "address-infomation",
            page: "partner-profile",
            action: ActionType.QUERY
        });
    }, function (error) {
        dispatchError("partner", dispatch, error);
    })
};

export const getOrganization = (page: number,limit: number,partnerProfile: boolean) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    PublicPartnerAPI.getOrganization(page,limit,partnerProfile).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "org_type",
            page: "partner_fill_master",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("partner_fill_master",dispatch,error);
    })
};

export const updatePartnerProfilePublic = (body: PartnerProfileListResponse,userKey: string,code: string,key: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_UPDATING
    });
    PublicPartnerAPI.updatePartnerProfilePublic(body,userKey,code,key).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_UPDATED,
            payload: response.data,
            table: "update_partner_profile",
            page: "partner_fill_master",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("partner_fill_master",dispatch,error);
    })
};

export const getFaculty = () => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING,
        page: "partner-profile"
    });
    PublicPartnerAPI.getFaculty().then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "faculty",
            page: "partner-profile",
            action: ActionType.QUERY
        });
    }, function (error) {
        dispatchError("v", dispatch, error);
    })
};

export const partnerVerifyEmail = (userKey: string,refCode: string, otp: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING,
        page: "partner-verify-email"
    });
    PublicPartnerAPI.partnerVerifyEmail(userKey,refCode,otp).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "partner-verify-email",
            page: "partner-verify-email",
            action: ActionType.QUERY
        });
    }, function (error) {
        dispatchError("partner-verify-email", dispatch, error);
    })
};

export const partnerVerifyEmailPartnerProfile = (userKey: string,refCode: string, otp: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING,
        page: "partner-verify-email-partner-profile"
    });
    PublicPartnerAPI.partnerVerifyEmailPartnerProfile(userKey,refCode,otp).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "partner-verify-email-partner-profile",
            page: "partner-verify-email-partner-profile",
            action: ActionType.QUERY
        });
    }, function (error) {
        dispatchError("partner-verify-email-partner-profile", dispatch, error);
    })
};

export const partnerVerifyLinkEditPartnerProfile = (userKey: string,refCode: string, otp: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING,
        page: "partner-verify-link-edit-partner-profile"
    });
    PublicPartnerAPI.partnerVerifyLinkEditPartnerProfile(userKey,refCode,otp).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "partner-verify-link-edit-partner-profile",
            page: "partner-verify-link-edit-partner-profile",
            action: ActionType.QUERY
        });
    }, function (error) {
        dispatchError("partner-verify-link-edit-partner-profile", dispatch, error);
    })
};

export const listActiveKnowledgeCategory = () => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING,
        page: "public-partner-active-category"
    });
    PublicPartnerAPI.listActiveKnowledgeCategory().then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "public-partner-active-category",
            page: "public-partner-active-category",
            action: ActionType.QUERY
        });
    }, function (error) {
        dispatchError("partner", dispatch, error);
    })
};

export const listActiveKnowledgeSubCategory = (categoryId: number) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING,
        page: "public-partner-active-sub-category"
    });
    PublicPartnerAPI.listActiveKnowledgeSubCategory(categoryId).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "public-partner-active-sub-category",
            page: "public-partner-active-sub-category",
            action: ActionType.QUERY
        });
    }, function (error) {
        dispatchError("partner", dispatch, error);
    })
};
