import { MAX_PAGE } from "../../api/pagination";

    export const createPage =(page: number,totalPage: number,handlePage: Function)=>{
        var startPage:number=Math.trunc(page/MAX_PAGE)*MAX_PAGE;
        var endPage:number=(totalPage>MAX_PAGE+startPage)?MAX_PAGE+startPage:totalPage;
        let data=[];

        if (startPage>0) {
            data.push(<div  key={"s"} onClick={e=>{handlePage(startPage-1)}} style={{cursor: "pointer",background:"black", color:"white",paddingTop:"3px",width:"30px",height:"30px",borderRadius:"5px",textAlign:"center"}}>&lt;</div>);
            data.push(<div  key={"s1"} style={{color: "black",width:"15px"}}>&nbsp;</div>);
        }
        for (var i=startPage;i<endPage;i++) {
            if (i===page) {
                data.push(<div key={i} style={{color: "black",paddingTop:"3px",width:"30px",cursor: "default"}}>{i+1}</div>);
            } else {
                data.push(<div id={i.toString()} key={i} style={{color: "lightgray",paddingTop:"3px",width:"30px",cursor: "pointer"}} onClick={e=>{handlePage(parseInt(e.currentTarget.id))}}>{i+1}</div>);
            }
        }
        if (totalPage>endPage) {
            data.push(<div  key={"e1"} style={{color: "black",width:"15px"}}>&nbsp;</div>);
            data.push(<div key={"e"} onClick={e=>{handlePage(startPage+MAX_PAGE)}} style={{cursor: "pointer",background:"black", color:"white",paddingTop:"3px",width:"30px",height:"30px",borderRadius:"5px" ,textAlign:"center"}}>&gt;</div>);
        }
        return data;
    }