import React, { useEffect, useState } from 'react'
import { useDispatch, useStore } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { queryclear } from '../../../store/search/actions';
import loadinglogo from '../../../img/loading.gif';
import MainMenu from '../menu';
import AnnouceManageSearchForm from './component/annouceManageSearchForm';
import { CustomPage } from '../../share/CustomPage';
import { TableHeaderBlack } from '../../../style/tableStyle';
import { Util } from '../../../api/util';
import Sort from '../../share/Sort';
import { useForm, useWatch } from 'react-hook-form';
import { createAnnouncement, deleteAnnouncement, listAnnouncement, listPublishAnnouncement, updateAnnouncement, updatePriority, updatePublish, updateUnPublish } from '../../../store/announceManage/action';
import { AnnouncePageableResponse } from '../../../model/response/announce.model';
import { ProjectGroupResponse } from '../../../model/response/project.model';
import { listGroupActive } from '../../../store/editprojectinfo/action';
import { AlertContainer, alert } from 'react-custom-alert';
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';
import ConfirmModal from '../../share/ConfirmModal';
import AnnounceManageModal from './component/announceManageModal';
import AddIcon from '@material-ui/icons/Add';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'
import { Button } from 'react-bootstrap';
import { AnnouncementManageRequest } from '../../../model/request/announce.model';
import { tr } from 'date-fns/locale';
import { setPriority } from 'os';

const AnnounceManage = () => {
  const [error, setError] = useState('');
  const [init, setInit] = useState(1);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(10);
  const [sortBy, setSortBy] = useState<string | undefined>();
  const [sortType, setSortType] = useState<'asc' | 'desc'>('desc');
  const [totalElement, setTotalElement] = useState<number>(0);
  const [modalShow, setModalShow] = useState<boolean | undefined>();
  const [consfirmModalShow, setConfirmModalShow] = useState<boolean | undefined>();
  const [tableItem, setTableItem] = useState<AnnouncePageableResponse[]>([]);
  const [tablePublishItem, setTablePublishItem] = useState<AnnouncePageableResponse[]>([]);
  const [hoverImage, setHoverImage] = useState<number>();
  const [hoverPublishImage, setHoverPublishImage] = useState<number>();
  const [projectGroupList, setProjectGroupList] = useState<ProjectGroupResponse[]>([]);
  const [modalItem, setModalItem] = useState<AnnouncePageableResponse>();
  const store = useStore();
  const history = useHistory();
  const dispatch = useDispatch();
  const form = useForm();
  const { getValues } = form
  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      let res = store.getState().SearchReducer;
      if (res.isLoading) {
        setError('');
        setLoading(true);
      } else if ((res.isLoaded) && (!res.isUpdated)) {
        setError('');
        setLoading(false);

        if (res.data != null) {
          if (res.table === 'list_announce') {
            setInit(1);
            setTableItem(res.data.elements);
            setTotalElement(res.data.totalElements);
          } else if (res.table === "list_project_group_active") {
            setProjectGroupList(res.data);
          } else {
            setTablePublishItem(res.data);
          }
        }
      } else if (res.isUpdating) {
        setError('');
      } else if (res.isUpdated) {
        setError('');
        setConfirmModalShow(false);
        setModalShow(false);
        handleClear();
        alert({ message: 'Saved', type: 'success' });
      } else if (res.isFailed) {
        setError(res.data.message);
        setLoading(false);
        alert({ message: res.data.message, type: 'error' });
      }

    })
    dispatch(listAnnouncement(undefined, undefined, undefined, page, size, undefined));
    dispatch(listPublishAnnouncement(undefined, undefined, undefined));
    dispatch(listGroupActive());
    removeSession();
    return unsubscribe;
  }, []);

  const removeSession = () => {
    sessionStorage.removeItem("searchDetails");
    sessionStorage.removeItem("searchPartnerDetails");
    sessionStorage.removeItem("searchSurveyDetails");
    sessionStorage.removeItem("searchTrainerDetails");
    sessionStorage.removeItem("searchManageQuestion");
    sessionStorage.removeItem("searchLogRequest");
    sessionStorage.removeItem("searchLogRequestHistory");
  }

  const onSubmit = (data: any) => {
    setPage(0);
    setSortBy(undefined);
    dispatch(queryclear());
    const searchParam = getValues();
    dispatch(listAnnouncement(searchParam?.keyword, searchParam?.section, searchParam?.projectGroup, 0, size, undefined))
    dispatch(listPublishAnnouncement(searchParam?.keyword, searchParam?.section, searchParam?.projectGroup));
  }
  const handleClear = () => {
    setPage(0);
    setSortBy(undefined);
    dispatch(queryclear());
    setModalItem(undefined);
    dispatch(listAnnouncement(undefined, undefined, undefined, 0, size, undefined))
    dispatch(listPublishAnnouncement(undefined, undefined, undefined));
  }
  const onSort = (sortName: string, newSortType: 'asc' | 'desc') => {
    setSortBy(sortName);
    setSortType(newSortType);
    const searchParam = getValues();
    dispatch(listAnnouncement(searchParam?.keyword, searchParam?.section, searchParam?.projectGroup, page, size, sortName + ',' + newSortType))
  }
  const onPageChange = (value: number) => {
    setPage(value);
    let sort = sortBy ? sortBy + ',' + sortType : undefined;
    const searchParam = getValues();
    dispatch(listAnnouncement(searchParam?.keyword, searchParam?.section, searchParam?.projectGroup, value, size, sort));
  }
  const onPageSizeChange = (value: number) => {
    setPage(0);
    setSize(value);
    let sort = sortBy ? sortBy + ',' + sortType : undefined;
    const searchParam = getValues();
    dispatch(listAnnouncement(searchParam?.keyword, searchParam?.section, searchParam?.projectGroup, 0, value, sort));
  }
  const showImageSize = (index: number) => {
    setHoverImage(index);
  }
  const handleEdit = (item: AnnouncePageableResponse) => {
    setModalShow(true);
    setModalItem(item);
  }
  const handlePublish = (item: AnnouncePageableResponse) => {
    dispatch(updatePublish(item.id, item.section));
  }

  const handleUnPublish = (item: AnnouncePageableResponse) => {
    dispatch(updateUnPublish(item.id, item.section));
  }
  const handleDelete = (item: AnnouncePageableResponse) => {
    setConfirmModalShow(true);
    setModalItem(item);
  }
  const deleteRow = () => {
    if (modalItem) {
      dispatch(deleteAnnouncement(modalItem.section, modalItem.id))
    }
  }
  const addElement: any = () => {
    return <>
      <Button type="button" className="btn btn-warning yellow-button" style={{ marginInline: 10 }}
        onClick={handleAdd} >
        <AddIcon />
        เพิ่มประชาสัมพันธ์
      </Button>
    </>
  }

  const handleAdd = () => {
    setModalItem(undefined);
    setModalShow(true);
  }
  const handleCreateOrUpdate = (item: AnnouncementManageRequest) => {
    if (item.announcementRequest.id) {
      dispatch(updateAnnouncement(item))
    } else {
      dispatch(createAnnouncement(item))
    }
  }
  const setPriority = (i: number, v: any,item: AnnouncePageableResponse) => {
    var newItem: any = [ ...tablePublishItem ];
        newItem[i].priority = v;
       
        setTablePublishItem(newItem);
        if(v){
          dispatch(updatePriority(item.id,item.section,Number(v)));
        }  
}

  return (
    <div>
      <MainMenu>
        <div>
          <div className="query-wrapper">
            <br />
            <div className="yellow-button" style={{ textAlign: "left", borderTopLeftRadius: "30px", borderTopRightRadius: "30px", height: "70px", paddingTop: "15px", paddingLeft: "15px" }}><h4 className="header">การจัดการข้อมูลประชาสัมพันธ์</h4></div>
            {(init === 1) &&
              <>
                <AnnouceManageSearchForm form={form} onSubmit={onSubmit} handleClear={handleClear} loading={loading} projectGroupList={projectGroupList} />
                {/* <h5>ข้อมูลประชาสัมพันธ์ที่เผยแพร่บนเว็บไซต์</h5> */}
                <div className="text-title mt-2 mb-3" style={{ textAlign: 'start' }}>ข้อมูลประชาสัมพันธ์ที่เผยแพร่บนเว็บไซต์</div>
                <TableHeaderBlack striped hover className='mt-2 mb-3'>
                  <thead>
                    <tr>
                      <th className='text-left' style={{ verticalAlign: "top" }}>
                        Unpublish
                      </th>
                      <th className='text-left' style={{ verticalAlign: "top" }}>
                        Banner
                      </th>
                      <th className='text-left' style={{ verticalAlign: "top" }}>
                        ชื่อหัวข้อประชาสัมพันธ์
                      </th>
                      <th className='text-left' style={{ verticalAlign: "top" }}>
                        ชื่อกลุ่มโครงการ
                      </th>
                      <th className='text-left' style={{ verticalAlign: "top" }}>
                        Section
                      </th>
                      <th className='text-left' style={{ verticalAlign: "top" }}>
                        วันที่ประชาสัมพันธ์
                      </th>
                      <th className='text-left' style={{ verticalAlign: "top" }}>
                        แก้ไขล่าสุด
                      </th>
                      <th className='text-left' style={{ verticalAlign: "top" }}>
                        วันที่แก้ไขล่าสุด
                      </th>
                      <th className='text-left' style={{ verticalAlign: "top" }}>
                        สถานะ
                      </th>
                      <th className='text-left' style={{verticalAlign: 'top'}}>
                        ลำดับ
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {tablePublishItem && tablePublishItem.map((item: AnnouncePageableResponse, index: number) => (
                      <tr key={'publish_annouce_table' + item.id + '_' + index}>
                        <td>
                          <div onClick={(e) => handleUnPublish(item)}><RemoveCircleIcon className="red pointer" ></RemoveCircleIcon></div>
                        </td>
                        <td>
                          <img src={item.url} style={{ height: 30, width: 'auto' }}  onMouseOver={() => setHoverPublishImage(index)} onMouseLeave={() => setHoverPublishImage(undefined)} />
                          {hoverPublishImage === index && <div className='image-canvas-container' ><img className='image-canvas' src={tablePublishItem[hoverPublishImage].url} /></div>}
                        </td>
                        <td className='text-left'>{item.topic}</td>
                        <td className='text-left'>{item.projectGroupName ?? '-'}</td>
                        <td className='text-left'>{item.section}</td>
                        <td className='text-left'>
                          {item.fromDate && <>{Util.datetostr(new Date(item.fromDate))}</>}
                          {item.toDate && <>- {Util.datetostr(new Date(item.toDate))}</>}
                        </td>
                        <td className='text-left'>{item.updateBy}</td>
                        <td className='text-left'>{Util.datetimetostr(new Date(item.updateDate))}</td>
                        <td className='text-left'>{item.status}</td>
                        <td className='text-left'>
                          <input type="number" id={index.toString()}  value={item.priority} className='form-control' onChange={(e)=>setPriority(parseInt(e.currentTarget.id), e.target.value,item)} />
                        </td>
                        <td>
                          <div className="row">
                            <div onClick={(e) => handleEdit(item)}><EditIcon className="yellow pointer" ></EditIcon></div>&nbsp;
                            <div onClick={(e) => handleDelete(item)}><CancelIcon className="red pointer"></CancelIcon></div>
                          </div>
                        </td>
                      </tr>
                    ))}
                    {tablePublishItem.length == 0 && (
                      <tr>
                        <td colSpan={11}>ไม่มีรายการ</td>
                      </tr>
                    )}
                  </tbody>
                </TableHeaderBlack>
                <CustomPage current={page} pageSize={size} total={totalElement} onPageChange={onPageChange} onPageSizeChange={onPageSizeChange} loading={loading && (modalShow !== true)} postElement={addElement()}>
                  <TableHeaderBlack striped hover className='mt-2'>
                    <thead>
                      <tr>
                        <th className='text-left' style={{ verticalAlign: "top" }}>
                          Publish
                        </th>
                        <th className='text-left' style={{ verticalAlign: "top" }}>
                          Banner
                        </th>
                        <th className='text-left' style={{ verticalAlign: "top" }}>
                          <Sort label='ชื่อหัวข้อประชาสัมพันธ์' currentSort={sortBy} sortName='topic' handleClick={onSort} sortType={sortType} />
                        </th>
                        <th className='text-left' style={{ verticalAlign: "top" }}>
                          <Sort label='ชื่อกลุ่มโครงการ' currentSort={sortBy} sortName='projectGroupName' handleClick={onSort} sortType={sortType} />
                        </th>
                        <th className='text-left' style={{ verticalAlign: "top" }}>
                          <Sort label='Section' currentSort={sortBy} sortName='section' handleClick={onSort} sortType={sortType} />
                        </th>
                        <th className='text-left' style={{ verticalAlign: "top" }}>
                          วันที่ประชาสัมพันธ์
                        </th>
                        <th className='text-left' style={{ verticalAlign: "top" }}>
                          <Sort label='แก้ไขล่าสุด' currentSort={sortBy} sortName='updateBy' handleClick={onSort} sortType={sortType} />
                        </th>
                        <th className='text-left' style={{ verticalAlign: "top" }}>
                          <Sort label='วันที่แก้ไขล่าสุด' currentSort={sortBy} sortName='updateDate' handleClick={onSort} sortType={sortType} />
                        </th>
                        <th className='text-left' style={{ verticalAlign: "top" }}>
                          <Sort label='สถานะ' currentSort={sortBy} sortName='status' handleClick={onSort} sortType={sortType} />
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableItem && tableItem.map((item: AnnouncePageableResponse, index: number) => (
                        <tr key={'annouce_table' + item.id + '_' + index}>
                          <td>
                            <div onClick={(e) => handlePublish(item)}><AddCircleIcon className="green pointer" ></AddCircleIcon></div>
                          </td>
                          <td>
                            <img src={item.url} style={{ height: 30, width: 'auto' }} onMouseOver={() => showImageSize(index)} onMouseLeave={() => setHoverImage(undefined)} />
                            {hoverImage === index && <div className='image-canvas-container' ><img className='image-canvas' src={tableItem[hoverImage].url} /></div>}
                          </td>
                          <td className='text-left'>{item.topic}</td>
                          <td className='text-left'>{item.projectGroupName ?? '-'}</td>
                          <td className='text-left'>{item.section}</td>
                          <td className='text-left'>
                            {item.fromDate && <>{Util.datetostr(new Date(item.fromDate))}</>}
                            {item.toDate && <>- {Util.datetostr(new Date(item.toDate))}</>}
                          </td>
                          <td className='text-left'>{item.updateBy}</td>
                          <td className='text-left'>{Util.datetimetostr(new Date(item.updateDate))}</td>
                          <td className='text-left'>{item.status}</td>
                          <td>
                            <div className="row">
                              <div onClick={(e) => handleEdit(item)}><EditIcon className="yellow pointer" ></EditIcon></div>&nbsp;
                              <div onClick={(e) => handleDelete(item)}><CancelIcon className="red pointer"></CancelIcon></div>
                            </div>
                          </td>
                        </tr>
                      ))}
                      {tableItem.length == 0 && (
                        <tr>
                          <td colSpan={10}>ไม่มีรายการ</td>
                        </tr>
                      )}
                    </tbody>
                  </TableHeaderBlack>
                </CustomPage>
                <br />
              </>
            }

            {(((init < 1) || (loading) && (modalShow !== true)) && (error === "")) &&
              <div style={{ textAlign: "center" }}><img src={loadinglogo} width="30" height="30" className="d-inline-block align-top" alt="" /></div>
            }
            {(error !== "") &&
              <div style={{ color: 'red', textAlign: "center" }}>{error}</div>
            }
          </div>
        </div>
      </MainMenu>
      <AlertContainer floatingTime={3000} />
      <ConfirmModal title={'กรุณากด "ยืนยัน" เพื่อลบรายการ'} show={consfirmModalShow}
        onHide={() => setConfirmModalShow(false)} onOk={() => deleteRow()} />
      <AnnounceManageModal title={modalItem ? 'แก้ข้อมูลประชาสัมพันธ์' : 'เพิ่มข้อมูลประชาสัมพันธ์'} show={modalShow} error={error} editItem={modalItem}
        onHide={() => setModalShow(false)} onOk={handleCreateOrUpdate} projectGroupList={projectGroupList} />

    </div>
  )
}

export default AnnounceManage