import { useEffect, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import MainMenu from './menu';
import { Button, InputGroup, Modal, Table } from 'react-bootstrap';
import { queryclear } from '../../store/search/actions';
import { NOT_FOUND_LABEL } from '../../api/label';
import { SingleSearch } from '../tab/search-component';
import SmsIcon from '@material-ui/icons/Sms';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutlineOutlined';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import InfoIcon from '@material-ui/icons/Info';
import { Util } from '../../api/util';
import { AlertContainer, alert } from 'react-custom-alert';
import { MultiSelectComponent } from '../tab/multi-select-component';
import { getAccessToken } from '../../store/auth/selectors';
import { GET_DOC_URL } from '../../api/doc-api';
import loadinglogo from '../../img/loading.gif';
import Footer from '../admin/adminfooter';
import { getassignment, listTrainer, listcourse, saveassignment } from '../../store/trainerPage/action';
import CommentModal from '../share/CommentModal';
import { dark } from '@material-ui/core/styles/createPalette';

const TrainerAssignment = () => {
    const token = useSelector(getAccessToken);
    const [init, setInit] = useState(0)
    const [initCourse, setInitCourse] = useState(0)
    const [initTrainer, setInitTrainer] = useState(0)
    const [initFeedback, setInitFeedback] = useState(0)

    const [items, setItems] = useState<any>({ items: [] });
    const [defaultItem, setDefaultItem] = useState<any>({ items: [] })
    const [courseItem, setCourseItem] = useState<any>({});
    const [trainerItem, setTrainerItem] = useState<any>({});
    const [feedbackItem, setFeedbackItem] = useState<any>([]);

    const [course, setCourse] = useState<any[]>([])
    const [feedbackId, setFeedbackId] = useState('');
    const [courseNameSelect, setCourseNameSelect] = useState<any>();
    const [feedBackNameSelect, setFeedBackNameSelect] = useState<any>();
    const [loading, setLoading] = useState(false)
    const [updating, setUpdating] = useState(false)
    const [edit, setEdit] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState('')
    const [leave, setLeave] = useState("")

    const [scoreDocumentId, setScoreDocumentId] = useState("")
    const [resultDocumentId, setResultDocumentId] = useState("")

    const store = useStore();
    const dispatch = useDispatch();
    const history = useHistory();
    let { id } = useParams<{ id: string }>();
    let { student_id } = useParams<{ student_id: string }>();


    useEffect(() => {
        const unsubscribe = store.subscribe(() => {
            let res = store.getState().SearchReducer;
            if (res.page != 'comment_modal') {
                if (res.isLoading) {
                    setError('');
                    setLoading(true);
                } else if (res.isLoaded) {
                    setError('');
                    setLoading(false);
                    if (res.table === "list_course") {
                        setInitCourse(1);
                        setCourseItem(res.data);
                    } else if (res.table === "list_trainer") {
                        setInitTrainer(1);
                        setTrainerItem(res.data);
                    } else if (res.table === "list_feedback") {
                        setInitFeedback(1);
                        setFeedbackItem(res.data);
                    } else {
                        if (res.data != null) {
                            setInit(1);
                            setItems(res.data);
                            setDefaultItem(JSON.parse(JSON.stringify(res.data)));
                        } else {
                            setError(NOT_FOUND_LABEL);
                        }
                    }
                } else if (res.isNotFound) {
                    setError(NOT_FOUND_LABEL);
                    setItems({ items: [] });
                    setLoading(false);
                } else if (res.isUpdating) {
                    setError('');
                    setUpdating(true);
                } else if (res.isUpdated) {
                    setError('');
                    setUpdating(false);
                    setEdit(false);
                    setSuccess(true);
                    alert({ message: 'Saved', type: 'success' });
                    var newData = res.data;
                    setDefaultItem(JSON.parse(JSON.stringify(newData)));
                    setItems(newData);
                    dispatch(queryclear());
                } else if (res.isFailed) {
                    if (res.data.success !== undefined) {
                        setItems(res.data);
                        alert({ message: res.data.popup ?? 'Error', type: 'error' });
                        window.scrollTo(0, 0);
                    }
                    setError(res.data.message);
                    setLoading(false);
                    setUpdating(false);
                    dispatch(queryclear());
                }
            } else {
                setError('');
                setLoading(false);
            }

        });
        dispatch(queryclear());
        dispatch(listcourse(parseInt(id)));
        dispatch(listTrainer(parseInt(id)));
        // dispatch(listfeedback(parseInt(id), parseInt(student_id)));
        dispatch(getassignment(parseInt(id), parseInt(student_id)))
        return unsubscribe;
    }, [])

    const createCourseSearchFilter = () => {
        var res: any[] = [];
        for (var i = 0; i < courseItem.length; i++) {
            res.push({ label: courseItem[i].shortName + ":" + courseItem[i].name, value: courseItem[i].classCourseId });
        }
        return res;
    }

    const createTrainerSearchFilter = (id: number) => {
        var res: any[] = [];
        var classCourseId = items.items[id].classCourseId;
        for (var i = 0; i < trainerItem.length; i++) {
            if (trainerItem[i].classCourseId === classCourseId) {
                res.push({ label: trainerItem[i].firstName + " " + trainerItem[i].lastName, value: trainerItem[i].trainerId });
            }
        }
        return res;
    }

    const setTrainer = (i: number, k: number, e: number) => {
        var newItem = { ...items };
        if (newItem.items[i].trainer === undefined) {
            newItem.items[i].trainer = [];
        }
        newItem.items[i].trainer[k].trainerId = e;
        setItems(newItem);
        setEdit(true);
    }

    const getTrainer = (i: number, k: number) => {
        for (var l = 0; l < trainerItem.length; l++) {
            if (trainerItem[l].trainerId === items.items[i].trainer[k].trainerId) {
                return { label: trainerItem[l].firstName + " " + trainerItem[l].lastName, value: trainerItem[l].trainerId };
            }
        }
        return null;
    }

    const setStatus = (i: number, e: any) => {
        var newItem = { ...items };
        if (newItem.items === undefined) {
            newItem.items = [];
        }
        newItem.items[i].status = e;
        setItems(newItem);
        setEdit(true);
    }

    const setScore = (i: number, e: any) => {
        var newItem = { ...items };
        if (newItem.items === undefined) {
            newItem.items = [];
        }
        newItem.items[i].score = e;
        setItems(newItem);
        setEdit(true);
    }

    const setResult = (i: number, e: any) => {
        var newItem = { ...items };
        if (newItem.items === undefined) {
            newItem.items = [];
        }
        newItem.items[i].result = e;
        setItems(newItem);
        setEdit(true);
    }

    const handleAssignment = (filename: string) => {
        window.open(GET_DOC_URL + "?filename=" + filename + "&token=" + encodeURIComponent(token), '_blank', 'noopener,noreferrer');
    }

    const handleEtoolkit = (documentId: string, email: string) => {
        console.log('click etoolkit');
        window.open("/trainer/etoolkit/assignment/" + documentId + "?studentEmail=" + email, '_blank', 'noopener,noreferrer');
    }

    const handleAddTrainer = (id: number) => {
        var newItem = { ...items };
        if ((newItem.items == null) || (newItem.items === undefined)) return;
        if (newItem.items[id].trainer == null) newItem.items[id].trainer = [];
        newItem.items[id].trainer.push({ trainerId: null });
        setItems(newItem);
        setEdit(true);
    }

    const handleDeleteTrainer = (id: number, t: number) => {
        var newItem = { ...items };
        if ((newItem.items == null) || (newItem.items === undefined)) return;
        var newTrainer: any[] = [];
        var oldTrainer = newItem.items[id].trainer;
        for (var i = 0; i < oldTrainer.length; i++) {
            if (i < t) { newTrainer.push({ ...oldTrainer[i] }); }
            else if (i > t) {
                var c = { ...oldTrainer[i] };
                newTrainer.push(c);
            }
        }
        newItem.items[id].trainer = newTrainer;
        setItems(newItem);
        setEdit(true);
    }

    const handleScore = (id: number) => {
        setScoreDocumentId(id.toString());
    }

    const handleShowResult = (id: number) => {
        setResultDocumentId(id.toString());
    }

    const getErrorTrainer = (i: number, k: number) => {
        if ((items.items === null) || (items.items === undefined)) return null;
        if (items.items[i].trainer[k].errorTrainer === undefined) return null;
        return items.items[i].trainer[k].errorTrainer;
    }

    const createTrainer = (i: number) => {
        var res: any[] = [];
        if ((items.items[i].trainer === undefined) || (items.items[i].trainer === null)) items.items[i].trainer = [];
        for (var k = 0; k < items.items[i].trainer.length; k++) {
            res.push(<>
                <div className='row'>
                    <div className='col-11'>
                        <InputGroup hasValidation>
                            <SingleSearch
                                id={i + "_" + k} isError={getErrorTrainer(i, k)} disabled={success} isSearchable={true} isMulti={false} placeholder=""
                                onChange={(id: string, e: any) => setTrainer(parseInt(id.split("_")[0]), parseInt(id.split("_")[1]), e.value)}
                                value={getTrainer(i, k)}
                                options={createTrainerSearchFilter(i)}
                                style={{ width: "200px" }}
                            />
                            <div className={(getErrorTrainer(i, k) != null) ? "text-danger" : ""}><small>{getErrorTrainer(i, k)}</small></div>
                        </InputGroup>
                    </div>
                    {(!success) &&
                        <div id={i + "_" + k} style={{ paddingTop: "5px", marginLeft: "-15px" }} onClick={(e) => { handleDeleteTrainer(parseInt(e.currentTarget.id.split("_")[0]), parseInt(e.currentTarget.id.split("_")[1])) }}>
                            <RemoveCircleOutlineIcon className="red"></RemoveCircleOutlineIcon>
                        </div>
                    }
                </div>
            </>);
        }
        return res;
    }

    const createItem = () => {
        var res: any[] = [];
        for (var i = 0; i < items.items.length; i++) {
            var a = items.items[i];
            var show = (course.length === 0);
            if (course.length > 0) {
                for (var l = 0; l < course.length; l++)
                    if (a.classCourseId === course[l].value) {
                        show = true;
                    }
            }
            if (show) {
                res.push(
                    <tr style={{ justifyContent: "center", textAlign: "center" }}>
                        <td className='text-left'>{a.courseName}</td>
                        <td className='text-left'> {(a.file !== null) &&
                            <>
                                {a.file !== "etoolkit" && (<span className="link" id={a.file} onClick={e => handleAssignment(e.currentTarget.id)}>{a.name}</span>)}
                                {a.file === "etoolkit" && (<span className="link" id={a.documentId} onClick={e => handleEtoolkit(e.currentTarget.id, items.email)}>{a.name}</span>)}
                            </>
                        }
                            {(a.file === null) &&
                                <>{a.name}</>
                            }
                        </td>
                        <td>{(a.createTime === null) ? "" : Util.datetimetostr(new Date(a.createTime))}</td>
                        <td className="form-row" style={{ justifyContent: "center", textAlign: "center" }}>
                            <select disabled={success} id={i.toString()} className="form-control" value={a.status} style={{ width: "160px", marginTop: "-5px" }} onChange={e => { setStatus(parseInt(e.currentTarget.id), e.currentTarget.value) }}>
                                <option value="PENDING">ยังไม่ตรวจ</option>
                                <option value="PROCESSING">ระหว่างการตรวจ</option>
                                <option value="DONE">ตรวจแล้ว</option>
                            </select>
                        </td>
                        <td style={{ justifyContent: "center", textAlign: "center", paddingLeft: "40px" }}>
                            <div className="row">
                                <input type="number" id={i.toString()} className='form-control' value={a.score || ""} onChange={e => { setScore(parseInt(e.currentTarget.id), e.target.value) }} style={{ width: "100px", marginTop: "-5px" }} />
                            </div>
                        </td>
                        <td className="form-row" style={{ justifyContent: "center", textAlign: "center" }}>
                            <div className='row'>
                                <select disabled={success} id={i.toString()} className="form-control" value={(a.result === null) ? undefined : a.result} style={{ width: "100px", marginTop: "-5px" }} onChange={e => { setResult(parseInt(e.currentTarget.id), e.currentTarget.value) }}>
                                    <option value="NA">N/A</option>
                                    <option value="PASS">ผ่าน</option>
                                    <option value="FAIL">ไม่ผ่าน</option>
                                </select>&nbsp;
                                <InfoIcon id={i.toString()} onClick={(e: any) => handleShowResult(parseInt(e.currentTarget.id))}></InfoIcon>
                            </div>
                        </td>
                        <td style={{padding:'0.5rem'}}><Button className='light-gray-button-2 btn-small' id={i.toString()} onClick={e => { handleFeedback(items.items[Number(e.currentTarget.id)]) }}><SmsIcon className="blue" style={{cursor:'pointer'}} ></SmsIcon><span style={{fontSize: 18,marginLeft:4,color:'black'}}>{a.countingMessage || '0'}</span></Button></td>
                    </tr>)
            }
        }
        return res;
    }

    const getFeedbackAssignment = () => {
        for (var i = 0; i < items.items.length; i++) {
            if (items.items[i].documentId === parseInt(feedbackId)) return items.items[i].name;
        }
    }

    const getFeedbackCourseName = () => {
        for (var i = 0; i < items.items.length; i++) {
            if (items.items[i].documentId === parseInt(feedbackId)) return items.items[i].courseName;
        }
    }

    const createFeedback = () => {
        var res: any[] = [];
        var hr = false;
        for (var i = 0; i < feedbackItem.length; i++) {
            if (hr) {
                res.push(<hr />);
            }
            if (feedbackItem[i].documentId === parseInt(feedbackId)) {
                res.push(<>
                    <span style={{ fontFamily: 'DB Helvethaica Bold' }}>{feedbackItem[i].name}</span> {Util.datetimetostr(new Date(feedbackItem[i].createTime))}  <br />
                    <div style={{ paddingLeft: "20px" }}>{feedbackItem[i].message} </div>
                </>);
                hr = true;
            }
        }
        return res;
    }

    const createScoreList = () => {
        var res: any[] = [];
        var i = parseInt(scoreDocumentId);
        var item = items.items[i];
        var scoreList = (item.scoreList === undefined) ? [] : item.scoreList;
        for (var k = 0; k < scoreList.length; k++) {
            var r = scoreList[k];
            res.push(
                <>
                    {r.firstName + " " + r.lastName + " : " + r.score}<br />
                </>
            );
        }
        return res;
    }

    const createResultList = () => {
        var res: any[] = [];
        var i = parseInt(resultDocumentId);
        var item = items.items[i];
        var resultList = (item.resultList === undefined) ? [] : item.resultList;
        for (var k = 0; k < resultList.length; k++) {
            var r = resultList[k];
            var resultStr = "N/A";
            if (r.result === "PASS") resultStr = "ผ่าน";
            else if (r.result === "FAIL") resultStr = "ไม่ผ่าน";
            res.push(
                <>
                    <span style={{ fontFamily: 'DB Helvethaica Bold' }}>{r.firstName + " " + r.lastName + " : " + resultStr}</span>{" " + Util.datetimetostr(new Date(r.createTime))}<br />
                </>
            );
        }
        return res;
    }

    const handleFeedback = (item: any) => {
        setFeedbackId(item.documentId);
        setCourseNameSelect(item.courseName);
        setFeedBackNameSelect(item.name);
    }

    const handleFeedbackClose = () => {
        setFeedbackId('');
    }

    const handleBack = () => {
        if (edit) setLeave("/trainer/class/" + id + "/assignment");
        else history.push("/trainer/class/" + id + "/assignment");
    }

    const handleClear = () => {
        if (edit) setLeave("clear");
    }

    const handleSubmit = () => {
        dispatch(queryclear());
        items.classId = id;
        items.classStudentId = student_id;
        dispatch(saveassignment(items));
    }

    const clear = () => {
        console.log(defaultItem)
        var newItem = JSON.parse(JSON.stringify(defaultItem));
        setItems(newItem);
        setEdit(false);
    }

    const handleReedit = () => {
        setSuccess(false);
    }

    const handleLeave = () => {
        if (leave === "clear") {
            clear();
        } else {
            history.push(leave);
        }
        setLeave("");
    }



    return (<>
        <MainMenu>
            <div>
                <div className="query-wrapper">
                    <br />
                    <div className="yellow-button" style={{ textAlign: "left", borderTopLeftRadius: "30px", borderTopRightRadius: "30px", height: "70px", paddingTop: "15px", paddingLeft: "15px" }}><h4 className="header">งานที่ได้รับมอบหมาย</h4></div>
                    {(init + initCourse + initTrainer === 3) &&
                        <><div className="light-gray-button" style={{ borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px', paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", textAlign: "left" }}>
                            <div className="row">
                                <div className="col-6">
                                    <label className="col-form-label">โครงการ</label><br />
                                    <input className="form-control" type="text" value={items.code + ":" + items.name} readOnly={true} />
                                </div>
                                <div className="col-1">
                                    <label className="col-form-label">รอบ</label><br />
                                    <input className="form-control" type="text" value={items.session} readOnly={true} />
                                </div>
                                <div className="col-2">
                                    <label className="col-form-label">วันที่เริ่ม</label>
                                    <input className="form-control" type="text" value={Util.datetostr(new Date(items.fromDate))} readOnly={true} />
                                </div>
                                <div className="col-2">
                                    <label className="col-form-label">วันที่สิ้นสุด</label>
                                    <input className="form-control" type="text" value={Util.datetostr(new Date(items.toDate))} readOnly={true} />
                                </div>
                            </div>
                        </div>
                            <br />

                            <div className="light-gray-button" style={{ borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px', paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", textAlign: "left" }}>
                                <div className="row">
                                    <div className="col-12">
                                        <label className="col-form-label">ชื่อ: {items.firstName + " " + items.lastName}</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-4">
                                        <label className="col-form-label">ชื่อองค์กร: {items.partner}</label>
                                    </div>
                                    <div className="col-4">
                                        <label className="col-form-label">e-Mail: {items.email}</label>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-1">
                                    หลักสูตร
                                </div>
                                <div className="col-5" style={{ justifyContent: "left", textAlign: "left", marginTop: "auto", marginBottom: "auto" }}>
                                    <MultiSelectComponent
                                        options={createCourseSearchFilter()}
                                        value={course}
                                        onChange={setCourse}
                                    />
                                </div>
                            </div>
                            <br />
                            { //assignment
                                ((items.items !== undefined) && (items.items.length >= 1)) &&
                                <>
                                    <br />
                                    <Table striped hover>
                                        <thead className="black-button">
                                            <tr>
                                                <th className='text-left'>หลักสูตร</th>
                                                <th className='text-left'>หัวข้อ Assignment</th>
                                                <th>วันที่ส่ง</th>
                                                <th>สถานะที่ตรวจ</th>
                                                <th>คะแนน</th>
                                                <th>สถานะ Assignment</th>
                                                <th>ความคิดเห็น</th>
                                            </tr>
                                        </thead>
                                        <tbody style={{ fontSize:"22px" }}>
                                            {createItem()}
                                        </tbody>
                                    </Table>
                                </>
                            }
                        </>
                    }
                    <br />
                    {(((init + initCourse + initTrainer < 3) || (loading)) && (error === "")) &&
                        <div style={{ textAlign: "center" }}><img src={loadinglogo} width="30" height="30" className="d-inline-block align-top" alt="" /></div>
                    }
                    {(error !== "") &&
                        <div style={{ color: 'red', textAlign: "center" }}>{error}</div>
                    }
                    {(init + initCourse + initTrainer === 3) && (!loading) && (!success) && (!updating) &&
                        <div className="row">
                            <div className="col-6"></div>
                            <div className="col-6" style={{ justifyContent: "right", textAlign: "right" }}>
                                <button type="button" className="btn btn-outline-primary" style={{ width: "120px" }}
                                    onClick={() => handleBack()} >
                                    Back
                                </button>&nbsp;
                                <button type="button" className="btn btn-warning" style={{ width: "120px" }}
                                    onClick={() => handleClear()} >
                                    Reset
                                </button>&nbsp;
                                <button type="button" className="btn btn-success" style={{ width: "120px" }}
                                    onClick={() => handleSubmit()} >
                                    Save
                                </button>
                            </div>
                        </div>
                    }
                    {(success) &&
                        <div style={{ justifyContent: "center", textAlign: "center" }}>
                            <button type="button" className="btn btn-outline-primary" style={{ width: "120px" }}
                                onClick={() => handleBack()} >
                                Back
                            </button>&nbsp;
                            <button type="button" className="btn yellow-button" style={{ width: "120px" }}
                                onClick={() => handleReedit()} >
                                Edit
                            </button>
                        </div>
                    }
                </div>
                <br />
                <Footer />
            </div>
        </MainMenu>

        <Modal show={leave !== ""} onHide={() => { setLeave("") }}>
            <Modal.Header closeButton>
                <Modal.Title>ยกเลิกข้อมูลที่แก้ไข?</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => { setLeave("") }}>
                    Cancel
                </Button>
                <Button variant="danger" onClick={() => { handleLeave() }}>
                    OK
                </Button>
            </Modal.Footer>
        </Modal>

        <Modal size="xl" show={scoreDocumentId !== ""} onHide={() => { setScoreDocumentId("") }}>
            <Modal.Header closeButton>
                <Modal.Title>คะแนน</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {(scoreDocumentId !== "") &&
                    createScoreList()}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => { setScoreDocumentId("") }}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>

        <Modal size="xl" show={resultDocumentId !== ""} onHide={() => { setResultDocumentId("") }}>
            <Modal.Header closeButton>
                <Modal.Title>สถานะ Assignment</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {(resultDocumentId !== "") &&
                    createResultList()}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => { setResultDocumentId("") }}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>

        {/* <Modal size="xl" show={feedbackId !== ""} onHide={handleFeedbackClose}>
            <Modal.Header style={{ background: "lightgray" }} closeButton>
                <Modal.Title>
                    <table>
                        <tr>
                            <td style={{ width: "20px" }}></td>
                            <td>
                                <SmsIcon className="yellow" style={{ transform: "scale(2)" }}></SmsIcon>
                            </td>
                            <td style={{ width: "150px" }} className="header">
                                &nbsp;&nbsp;Feedback
                            </td>
                            <td style={{ width: "20px" }}></td>
                            <td style={{ fontSize: "12pt" }}>
                                <span className="header">{items.firstName} {items.lastName} หลักสูตร: {getFeedbackCourseName()} </span> <br />
                                หัวข้อ Assignment: <span style={{ textDecoration: "underline" }}>{getFeedbackAssignment()}</span>
                            </td>
                        </tr>
                    </table>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {(feedbackId !== "") &&
                    createFeedback()}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleFeedbackClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal> */}
        <CommentModal show={feedbackId !== ""}
            title='Test'
            classId={id}
            firstName={items.firstName}
            lastName={items.lastName}
            courseName={courseNameSelect}
            feedBackName={feedBackNameSelect}
            classStudentId={student_id}
            documentId={feedbackId}
            onHide={() => handleFeedbackClose()}
            onOk={() => { }}
            okbutton='Comment'
            cancelbutton='Close' />
        <AlertContainer floatingTime={10000} />
    </>);
}

export default TrainerAssignment;