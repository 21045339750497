import { apiClient } from './axios-intercept';
import { DEFAULT_SERVER } from './conf';

const HR ="/api/v1/hr";
const EMPLOYEE = HR+"";
const DEPARTMENT = HR+"/department";

const LIST_EMPLOYEE_URL = DEFAULT_SERVER + EMPLOYEE;
const LIST_DEPARTMENT_URL = DEFAULT_SERVER + DEPARTMENT;

export class HRAPI {
        
    public static listEmployee = async (): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=LIST_EMPLOYEE_URL;
             apiClient.get(s,
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                console.log("list employee response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("list employee error:", err);
                return reject(err); 
            })
        })
    }

    public static listDepartment = async (): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=LIST_DEPARTMENT_URL;
             apiClient.get(s,
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                console.log("list department response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("list department error:", err);
                return reject(err); 
            })
        })
    }
}