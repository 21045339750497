import React, { useEffect, useState } from 'react'
import { PartnerKnowledgeRegistration } from '../../../../model/request/partner-knowledge-regis';
import { PartnerProfileListResponse } from '../../../../model/response/partner-profile';
import { Button, InputGroup, Modal, ModalProps } from 'react-bootstrap';
import { ThaiDateTimePicker } from '../../../tab/thai-datetime-picker';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { AlertContainer, alert } from 'react-custom-alert';
type Props = ModalProps & {
    okButton?: string;
    cancelButton?: string;
    modalItem?: PartnerKnowledgeRegistration;
    parnerProfileList: PartnerProfileListResponse;
    modalIndex?: number;
    onOk: (parnerProfileList: PartnerProfileListResponse) => void
}

const PartnerInfoModal = (props: Props) => {
    const [firstname, setFirstname] = useState();
    const [lastname, setLastname] = useState();
    const [department, setDepartment] = useState();
    const [position, setPosition] = useState();
    const [marketingConsentAcceptedDate, setMarketingConsentAcceptedDate] = useState();
    const [marketingConsentRejectedDate, setMarketingConsentRejectedDate] = useState();
    const [pdpaConsentDate, setPdpaConsentDate] = useState();
    const [officeNo, setOfficeNo] = useState();
    const [mobileNo, setMobileNo] = useState<string>();
    const [oldEmail,setOldEmail] = useState<string>();
    const [email, setEmail] = useState<string>();
    const [additionalDetails, setAdditionalDetails] = useState();
    const [coordinatorId, setCoordinatorId] = useState();
    const [pdpaConsent, setPdpaConsent] = useState();
    const [marketingConsent, setMarketingConsent] = useState();
    const [submitted, setSubmitted] = useState(false);
    const [verify,setVerify] = useState<any>();
    useEffect(() => {
        if (!props.show) {
            setSubmitted(false);
            setFirstname(undefined);
            setLastname(undefined);
            setDepartment(undefined);
            setPosition(undefined);
            setMarketingConsentAcceptedDate(undefined);
            setMarketingConsentRejectedDate(undefined);
            setPdpaConsentDate(undefined);
            setOfficeNo(undefined);
            setMobileNo(undefined)
            setEmail(undefined);
            setOldEmail(undefined);
            setCoordinatorId(undefined);
            setAdditionalDetails(undefined);
            setPdpaConsent(undefined);
            setMarketingConsent(undefined)
            setVerify(undefined);
        }
        if (props.modalItem) {
            setSubmitted(false);
            setFirstname(props.modalItem?.firstname as any);
            setLastname(props.modalItem?.lastname as any);
            setDepartment(props.modalItem?.department as any);
            setPosition(props.modalItem?.position as any);
            setMarketingConsentAcceptedDate(props.modalItem?.marketingConsentAcceptedDate as any);
            setMarketingConsentRejectedDate(props.modalItem?.marketingConsentRejectedDate as any);
            setPdpaConsentDate(props.modalItem?.pdpaConsentDate as any);
            setCoordinatorId(props.modalItem?.coordinatorId as any);
            setOfficeNo(props.modalItem?.officeNo as any);
            setMobileNo(props.modalItem?.mobileNo as any)
            setEmail(props.modalItem?.email);
            setOldEmail(props.modalItem?.email);
            setAdditionalDetails(props.modalItem?.additionalDetails as any);
            setPdpaConsent(props.modalItem?.pdpaConsent as any);
            setMarketingConsent(props.modalItem?.marketingConsent as any)
            setVerify(props.modalItem?.verify);
            console.log(props)
        } else {
            setSubmitted(false);
            setFirstname(undefined);
            setLastname(undefined);
            setDepartment(undefined);
            setPosition(undefined);
            setMarketingConsentAcceptedDate(undefined);
            setMarketingConsentRejectedDate(undefined);
            setPdpaConsentDate(undefined);
            setCoordinatorId(undefined);
            setOfficeNo(undefined);
            setMobileNo(undefined)
            setEmail(undefined);
            setOldEmail(undefined);
            setAdditionalDetails(undefined);
            setPdpaConsent(undefined);
            setMarketingConsent(undefined);
            setVerify(undefined);
        }
    }, [props.show])
    const getRequire = (value: string | undefined | any[]) => {
        return (submitted && ((value == null) || (value == '')))
    }
    const getRequireConsentDate = (check: boolean,value: string | undefined | any[]) => {
        if(check){
            return (submitted && ((value == null) || (value == '')))
        }else {
            return false;
        }
    }
    const getRequireMobile = (value: string | undefined) => {
        if (value != null) {
            return !value.match('[0-9]{10}')
        }
        return (submitted && ((value == null) || (value == '')))
    }

    const getRequireEmail = (value: string | undefined) => {
        if (value != null) {
            const expression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
            return !expression.test(value)
        }
        return (submitted && ((value == null) || (value == '')))
    }

    const getRequireConsent = (value: boolean | undefined | any[]) => {
        return (submitted && (value != true))
    }
    const submit = () => {
        setSubmitted(true);
        if (!validateForm()) {
            alert({ message: "กรุณากรอกแบบฟอร์มให้ครบ", type: 'error' });
            return;
        } else {
            const item: PartnerKnowledgeRegistration | any = {
                coordinatorId: coordinatorId,
                firstname: firstname,
                lastname: lastname,
                department: department,
                officeNo: officeNo,
                mobileNo: mobileNo,
                email: email,
                position: position,
                marketingConsent: marketingConsent,
                pdpaConsent: pdpaConsent,
                marketingConsentAcceptedDate: marketingConsentAcceptedDate,
                marketingConsentRejectedDate: marketingConsentRejectedDate,
                pdpaConsentDate: pdpaConsentDate,
                additionalDetails: additionalDetails,
                verify: email == oldEmail? verify: false
            }
            let newItem = { ...props.parnerProfileList }
            if (props.modalIndex != undefined || props.modalIndex !=null) {
                newItem.partnerList[props.modalIndex] = { ...item };
                return props.onOk(newItem)
            } else {
                if (newItem.partnerList == null) {
                    newItem.partnerList = [];
                }
                newItem.partnerList.push(item);
                return props.onOk(newItem);
            }

        }
    }

    const validateForm = () => {
        if (mobileNo != null && !mobileNo.match('[0-9]{10}')) {
            alert({ message: 'กรุณากรอกเบอร์มือถือให้ถูกต้อง', type: 'error' });
            return false;
        }
        const expression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        if (email != null && !expression.test(email)) {
            alert({ message: 'กรุณากรอกe-Mailให้ถูกต้อง', type: 'error' });
            return false;
        }

        let error = firstname == null || firstname == '' ||
            lastname == null || lastname == '' ||
            department == null || department == '' ||
            officeNo == null || officeNo == '' ||
            mobileNo == null || mobileNo == '' ||
            email == null || email == '' ||
            pdpaConsent != true;
        if (pdpaConsent == true && pdpaConsentDate == null) {
            error = true;
        }
        if (marketingConsent == true){
            if(marketingConsentAcceptedDate == null) error = true;
        }else{
            if(marketingConsentRejectedDate == null) error = true;
        }
        return error ? false : true;
    }
    const setPdpaConsentChange = (e: boolean) => {
        setPdpaConsent(e as any);
        setPdpaConsentDate(new Date() as any);
    }
    const setMarketingConsentChange = (e: boolean) => {
        setMarketingConsent(e as any);
        if(e){
            setMarketingConsentAcceptedDate(new Date() as any)
            setMarketingConsentRejectedDate(undefined);
        }else{
            setMarketingConsentAcceptedDate(undefined)
            setMarketingConsentRejectedDate(new Date() as any);
        }
        
    }

    return (
        <Modal
            {...props}
            dialogClassName="modal-60w"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    ข้อมูลผู้ประสานงาน
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form>
                    <div className='row'>
                        <div className='col-4'>
                            <label className='col-form-label'>ชื่อ <span className='text-danger'>*</span></label>
                            <input type="text" className={'form-control ' + (getRequire(firstname) ? "is-invalid" : "")} id="input-text-firstname" value={firstname} onChange={(e: any) => setFirstname(e.target.value)} />
                        </div>
                        <div className='col-4'>
                            <label className='col-form-label'>นามสกุล <span className='text-danger'>*</span></label>
                            <input type="text" className={'form-control ' + (getRequire(lastname) ? "is-invalid" : "")} id="input-text-lastname" value={lastname} onChange={(e: any) => setLastname(e.target.value)} />
                        </div>
                        <div className='col-4'>
                            <label className='col-form-label'>ฝ่ายงาน <span className='text-danger'>*</span></label>
                            <input type="text" className={'form-control ' + (getRequire(department) ? "is-invalid" : "")} id="input-text-department" value={department} onChange={(e: any) => setDepartment(e.target.value)} />
                        </div>
                    </div>
                    <div className='row'>
                        
                        <div className='col-4'>
                            <label className='col-form-label'>ตำแหน่งงาน</label>
                            <input type="text" className={'form-control '} id="input-text-position" value={position} onChange={(e: any) => setPosition(e.target.value)} />
                        </div>
                        <div className='col-4'>
                            <label className='col-form-label'>เบอร์ที่ทำงาน <span className='text-danger'>*</span></label>
                            <input type="text" className={'form-control ' + (getRequire(officeNo) ? "is-invalid" : "")} id="input-text-officeNo" value={officeNo} onChange={(e: any) => setOfficeNo(e.target.value)} />
                        </div>
                        <div className='col-4'>
                            <label className='col-form-label'>เบอร์มือถือ <span className='text-danger'>*</span></label>
                            <input type="tel" maxLength={10} minLength={10} className={'form-control ' + (getRequireMobile(mobileNo) ? "is-invalid" : "")} id="input-text-mobileNo" value={mobileNo} onChange={(e: any) => setMobileNo(e.target.value)} />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12'>
                            <label className='col-form-label'>e-Mail <span className='text-danger'>*</span></label>
                            <div className='d-flex'>
                                <input type="text" className={'form-control col mr-2 ' + (getRequireEmail(email) ? "is-invalid" : "")} id="input-text-email" value={email} onChange={(e: any) => setEmail(e.target.value)} />
                            </div>
                        </div>

                    </div>
                    <div className='row'>
                        <div className='col-12'>
                            <label className='col-form-label'>รายละเอียดอื่นๆ</label>
                            <input type="text" className='form-control' id="input-text-additionalDetails" value={additionalDetails} onChange={(e: any) => setAdditionalDetails(e.target.value)} />
                        </div>
                    </div>
                    <div className='mt-2'>
                        <div className="gray-button text-left" style={{ padding: "15px", borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}>
                            <div><b>ข้อตกลงและเงื่อนไข</b> (กรุณาอ่านข้อตกลงและเงื่อนไขด้านล่างนี้โดยละเอียด)</div>
                        </div>
                        <div className="bg-white text-left" style={{ padding: "15px", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px", border: "3px solid #DDDDDD" }}>
                            <div className='row'>
                                <div className='col'>
                                    <label className='col-form-label'><span>&emsp;การใช้สิทธิเจ้าของข้อมูลส่วนบุคคล
                                        <br />
                                    </span>
                                    </label>
                                </div>
                            </div>
                            <div className='row mt-1'>
                                <div className="col mt-1">
                                    <div className='d-flex'>
                                        &emsp;<input id={"input-radio-pdpa-consent-true"} type="radio" checked={pdpaConsent == true} onChange={(e) => { setPdpaConsentChange(true as any) }} />
                                        <span className='my-auto col-auto'>ยอมรับ</span>
                                        <InputGroup hasValidation>
                                            <label className='position-relative ml-2'>
                                                <ThaiDateTimePicker className={"form-control " + ((getRequireConsentDate(pdpaConsent as any,pdpaConsentDate)) ? "is-invalid" : "")}
                                                    selected={pdpaConsentDate} onChange={(e: any, id: string) => setPdpaConsentDate(e)} />
                                                <div className="search-icon" style={{ visibility: (getRequireConsentDate(pdpaConsent as any,pdpaConsentDate)) ? "hidden" : "visible", position: "absolute", right: "10px", top: "5px", zIndex: 0 }}>
                                                    <CalendarTodayIcon style={{ color: "gray" }} />
                                                </div>
                                            </label>
                                        </InputGroup>
                                    </div>

                                </div>

                            </div>
                            <div className={(getRequireConsent(pdpaConsent)) ? "text-danger ml-3" : ""}>{(getRequireConsent(pdpaConsent)) ? "กรุณากดยินยอมเงื่อนไข" : ""}</div>
                            <div className='row mt-1'>
                                <div className='col'>
                                    <label className='col-form-label'><span>&emsp;รับข้อมูลใดๆจากกลุ่มตลาดหลักทรัพย์
                                        <br />
                                    </span>
                                    </label>
                                </div>

                            </div>
                            <div className='row mt-1'>
                                <div className="col-4 mt-1">
                                    <div className='d-flex'>
                                        &emsp;<input id={"input-radio-market-consent-true"} type="radio" checked={marketingConsent == true} onChange={(e) => { setMarketingConsentChange(true as any) }} />
                                        <div className='my-auto col-auto'>ยินยอม</div>
                                        <InputGroup hasValidation>
                                            <label className='position-relative ml-2'>
                                                <ThaiDateTimePicker className={"form-control " + ((getRequireConsentDate(marketingConsent as any,marketingConsentAcceptedDate)) ? "is-invalid" : "")}
                                                    selected={marketingConsentAcceptedDate} onChange={(e: any, id: string) => setMarketingConsentAcceptedDate(e)} />
                                                <div className="search-icon" style={{ visibility: (getRequireConsentDate(marketingConsent as any,marketingConsentAcceptedDate)) ? "hidden" : "visible", position: "absolute", right: "10px", top: "5px", zIndex: 0 }}>
                                                    <CalendarTodayIcon style={{ color: "gray" }} />
                                                </div>
                                            </label>
                                        </InputGroup>
                                    </div>

                                </div>
                                <div className="col mt-1">
                                    <div className='d-flex'>
                                        &emsp;<input id={"input-radio-market-consent-false"} type="radio" checked={marketingConsent == false} onChange={(e) => { setMarketingConsentChange(false as any) }} />
                                        <div className='my-auto col-auto'>ไม่ยินยอม</div>
                                        <InputGroup hasValidation>
                                            <label className='position-relative ml-2'>
                                                <ThaiDateTimePicker className={"form-control " + ((getRequireConsentDate(marketingConsent == false as any,marketingConsentRejectedDate)) ? "is-invalid" : "")}
                                                    selected={marketingConsentRejectedDate} onChange={(e: any, id: string) => setMarketingConsentRejectedDate(e)} />
                                                <div className="search-icon" style={{ visibility: (getRequireConsentDate(marketingConsent == false as any,marketingConsentRejectedDate)) ? "hidden" : "visible", position: "absolute", right: "10px", top: "5px", zIndex: 0 }}>
                                                    <CalendarTodayIcon style={{ color: "gray" }} />
                                                </div>
                                            </label>
                                        </InputGroup>
                                    </div>

            
                                </div>

                            </div>
                        </div>

                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.onHide}>
                    {props.cancelButton || 'Close'}
                </Button>
                <Button variant="success" onClick={() => { submit() }}>
                    {props.okButton || 'Save'}
                </Button>
            </Modal.Footer>
        </Modal>

    )
}

export default PartnerInfoModal