import { apiClient } from "./axios-intercept";
import { DEFAULT_SERVER } from "./conf";

export const ORGANIZATION = DEFAULT_SERVER + "/api/v1/organization";

export class OrganizationAPI {
    public static listOraganizationMapProject = async (): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = ORGANIZATION+'/map-project';
            apiClient.get(s,
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    return resolve(response);
                }).catch(err => {
                    console.log("list organization error:", err);
                    return reject(err);
                })
        })
    }
}   