import React, { useRef, useState } from 'react'
import { KnowledgeDepartmentResponse, ManageKnowledgeResponse } from '../../../../model/response/manage-knowledge'
import iconAddMedia from '../../../../img/iconAddMedia.png'
import iconTrash from '../../../../img/iconTrash.png'
import iconTrashRed from '../../../../img/iconTrashRed.png'
import iconEdit from '../../../../img/iconEdit.png'
import MenuIcon from '@material-ui/icons/Menu';
import AddIcon from '@material-ui/icons/Add';
import { SingleSearch } from '../../../tab/search-component'
import { Button } from 'react-bootstrap';
import ManageKnowledgeMedia from './ManageKnowledgeMedia'
import ConfirmModal from '../../../share/ConfirmModal'
import PartnerLoadingModal from '../../../partner/PartnerLoadingModal'
import { Dot } from '../../../../style/partnerStylemain'
import ExpandMore from '@material-ui/icons/ExpandMore'
import ExpandLess from '@material-ui/icons/ExpandLess'

type Props = {
    knowledgeList: ManageKnowledgeResponse[];
    departmentItem: KnowledgeDepartmentResponse[];
    success: boolean;
    setKnowledgeList: (knowledgeList: ManageKnowledgeResponse[]) => void;
    setKnowledgeListNotChange: (knowledgeList: ManageKnowledgeResponse[]) => void;
    setEdit: (value: boolean) => void;
    submitted: boolean
}

const ManageKnowledgeCategory = (props: Props) => {
    const dragCategoryItem = useRef<any>(null)
    const dragCategoryOverItem = useRef<any>(null)
    const [disabledDragCategory, setDisabledDragCategory] = useState(false);
    const dragSubCategoryItem = useRef<any>(null)
    const dragSubCategoryOverItem = useRef<any>(null)
    const [disabledDragSubCategory, setDisabledDragSubCategory] = useState(false);
    const [confirmDeleteSubCat, setConfirmDeleteSubCat] = useState(false);
    const [itemDeleteSubCat, setItemDeleteSubcat] = useState<any>();
    const [confirmDeleteCat, setConfirmDeleteCat] = useState(false);
    const [itemDeleteCat, setItemDeleteCat] = useState<any>();
    const editMode = (index: number) => {
        var newItem = props.knowledgeList == null ? [] : [...props.knowledgeList];
        newItem[index].edit = true;
        props.setKnowledgeList(newItem);
        props.setEdit(true);
    }
    const setCategoryName = (e: any, index: number) => {
        var newItem = props.knowledgeList == null ? [] : [...props.knowledgeList];
        newItem[index].categoryName = e.target.value;
        props.setKnowledgeList(newItem);
        props.setEdit(true);
    }
    const createDepartment = () => {
        var res: any[] = [];

        for (var i = 0; i < props.departmentItem.length; i++) {
            res.push({ label: props.departmentItem[i].name, value: props.departmentItem[i].id });
        }
        return res;
    }
    const handleCheckCategory = (e: any, index: number) => {
        let newResult = [...props.knowledgeList as any];
        newResult[index].isActive = e.target.checked;
        props.setKnowledgeList(newResult);
        props.setEdit(true);
    }
    const handleCheckSubCategory = (e: any, index: number, subCatIndex: number) => {
        let newResult = [...props.knowledgeList as any];
        if (newResult[index].subCategories == null) newResult[index].subCategories = [];
        newResult[index].subCategories[subCatIndex].checked = e.target.checked;
        props.setKnowledgeList(newResult);
        props.setEdit(true);
    }
    const handleCategoryDrag = () => {
        let _knowledgeList = [...props.knowledgeList];
        const dragItemContent = _knowledgeList.splice(dragCategoryItem.current, 1)[0];
        _knowledgeList.splice(dragCategoryOverItem.current, 0, dragItemContent)
        dragCategoryItem.current = null;
        dragCategoryOverItem.current = null
        props.setKnowledgeList(_knowledgeList);
    }
    const handleSubCategoryDrag = (indexCat: number) => {
        let _knowledgeList = [...props.knowledgeList];
        const dragSubCategoryItemContent = _knowledgeList[indexCat].subCategories.splice(dragSubCategoryItem.current, 1)[0];
        _knowledgeList[indexCat].subCategories.splice(dragSubCategoryOverItem.current, 0, dragSubCategoryItemContent)
        dragSubCategoryItem.current = null;
        dragSubCategoryOverItem.current = null
        props.setKnowledgeList(_knowledgeList);
    }
    const getDepartment = (index: number) => {
        var c = props.knowledgeList[index];
        if ((c.departmentId === null) || (c.departmentId === undefined)) return null;
        for (var i = 0; i < props.departmentItem.length; i++) {
            if (props.departmentItem[i].id === c.departmentId) {
                return { label: props.departmentItem[i].name, value: c.departmentId };
            }
        }
        return null;
    }
    const setDepartment = (index: number, e: any) => {
        var newItem: any = [...props.knowledgeList];
        newItem[index].departmentId = e.value;
        newItem[index].department = e.label;
        props.setKnowledgeList(newItem);
        props.setEdit(true);
    }
    const setCategoryStatus = (index: number, value: boolean) => {
        var newItem: any = [...props.knowledgeList];
        newItem[index].isActive = value;
        props.setKnowledgeList(newItem);
        props.setEdit(true);
    }
    const setSubCategoryName = (e: any, index: number, subCatIndex: number) => {
        var newItem = [...props.knowledgeList];
        newItem[index].subCategories[subCatIndex].subCategoryName = e.target.value;
        props.setKnowledgeList(newItem);
        props.setEdit(true);
    }
    const setSubCategoryDescription = (e: any, index: number, subCatIndex: number) => {
        var newItem = [...props.knowledgeList];
        newItem[index].subCategories[subCatIndex].subCategoryDescription = e.target.value;
        props.setKnowledgeList(newItem);
        props.setEdit(true);
    }
    const setSubCategoryStatus = (index: number, subCatIndex: number, value: boolean) => {
        var newItem = [...props.knowledgeList];
        newItem[index].subCategories[subCatIndex].checked = value;
        props.setKnowledgeList(newItem);
        props.setEdit(true);
    }
    const handleAddSubCategory = (index: number) => {
        var newItem = props.knowledgeList == null ? [] : [...props.knowledgeList];
        if (newItem[index].subCategories == null) newItem[index].subCategories = []
        const newSubCat: any = {
            subCategoryId: null,
            subCategoryName: null,
            subCategoryDescription: null,
            checked: true,
            edit: true
        }
        newItem[index].subCategories.push(newSubCat);
        props.setKnowledgeList(newItem);
        props.setEdit(true);
    }

    const handleDeleteSubCategory = (index: number, subCatIndex: number) => {
        const item = {
            index: index,
            subCatIndex: subCatIndex,
            name: props.knowledgeList[index].subCategories[subCatIndex].subCategoryName
        }
        setItemDeleteSubcat(item as any);
        setConfirmDeleteSubCat(true);

    }
    const deleteSubCat = () => {
        var newItem = props.knowledgeList == null ? [] : [...props.knowledgeList];
        if (itemDeleteSubCat) {
            const { index, subCatIndex } = itemDeleteSubCat as any
            newItem[index].subCategories.splice(subCatIndex, 1);
            props.setKnowledgeList(newItem);
            props.setEdit(true);
        }
        setItemDeleteSubcat(undefined);
        setConfirmDeleteSubCat(false);

    }

    const handleDeleteCategory = (index: number) => {
        const item = {
            index: index,
            name: props.knowledgeList[index].categoryName
        }
        setItemDeleteCat(item as any);
        setConfirmDeleteCat(true);
    }
    const deleteCat = () => {
        var newItem = props.knowledgeList == null ? [] : [...props.knowledgeList];
        if (itemDeleteCat) {
            const { index } = itemDeleteCat as any
            newItem.splice(index, 1);
            props.setKnowledgeList(newItem);
            props.setEdit(true);
        }
        setItemDeleteCat(undefined);
        setConfirmDeleteCat(false);

    }
    const getRequire = (value: string | boolean | undefined | any[] | null) => {
        return (props.submitted && (value == null || value == undefined || value == ''))
    }
    const getRequireArray = (value: undefined | any[]) => {
        return (props.submitted && (value == null || value == undefined || value.length < 1))
    }
    const setExpandCategory = (value: boolean, index: number) => {
        var newItem = props.knowledgeList == null ? [] : [...props.knowledgeList];
        newItem[index].collapse = value;
        props.setKnowledgeListNotChange(newItem);
    }
    const setExpandSubCategory = (value: boolean, index: number, subCatIndex: number) => {
        var newItem = [...props.knowledgeList];
        newItem[index].subCategories[subCatIndex].collapse = value;
        props.setKnowledgeListNotChange(newItem);
    }

    return (
        <div>{props.knowledgeList && props.knowledgeList.map((x, index) => (
            <React.Fragment key={"categoryId" + x.categoryId}>
                <div className="text-left" style={{ position: "relative", marginBottom: 5 }}
                    draggable={disabledDragCategory}
                    onDragStart={(e) => dragCategoryItem.current = index}
                    onDragEnter={(e) => dragCategoryOverItem.current = index}
                    onDragEnd={handleCategoryDrag}
                    onDragOver={(e) => e.preventDefault()}
                    onDrop={e => setDisabledDragCategory(false)}

                >
                    {!x.edit && (<>
                        <div className='d-flex yellow-button' style={{ padding: "15px", borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}>
                            {((x.collapse === undefined) || (x.collapse === false)) &&
                                <div onClick={(e) => setExpandCategory(true, index)}><ExpandLess className="pointer mr-2" ></ExpandLess></div>
                            }
                            {(x.collapse === true) &&
                                <div onClick={(e) => setExpandCategory(false, index)}><ExpandMore className="pointer mr-2" ></ExpandMore></div>
                            }
                            <span className='my-auto d-inline-flex'><Dot active={x.isActive} /></span>
                            <label className='ml-2 my-auto'><span style={{ fontWeight: 600, marginRight: 20 }}>ประเภทความรู้</span>{x.categoryName} </label>
                            <div className='ml-auto my-auto'>
                                <img src={iconEdit} width={20} height={20} onClick={() => editMode(index)} className='mr-2' id={"icon_edit_categoryId" + index + "_" + x.categoryId} style={{ cursor: "pointer" }} />
                                <img src={iconTrash} width={20} height={20} id={"icon_trash_categoryId" + index + "_" + x.categoryId} style={{ cursor: "pointer" }} className='mr-2' onClick={e => { handleDeleteCategory(index) }} />
                                <span onMouseDown={e => setDisabledDragCategory(true)}
                                ><MenuIcon width={20} height={18} id={"icon_drag_categoryId" + index + "_" + x.categoryId} style={{ cursor: "grab" }} /></span>

                            </div>

                        </div>
                        {((x.collapse === undefined) || (x.collapse === false)) && (
                            <div style={{ padding: "15px", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px", border: "3px solid #fab232" }}>
                                <div className='row mt-1'>
                                    <label className='col-2' style={{ fontWeight: 600 }}>ฝ่ายงานที่รับผิดชอบ</label>
                                    <div className='d-flex flex-column col-10'>
                                        <label id="label-department">{x.department}</label>

                                    </div>
                                </div>
                                <div className='row mt-1'>
                                    <label className='col-2' style={{ fontWeight: 600 }}>หมวดหมู่ความรู้</label>
                                    <div className='d-flex flex-column col-10'>
                                        {x.isActive && (<div className={(getRequireArray(x.subCategories)) ? "text-danger" : ""}>{(getRequireArray(x.subCategories)) ? "กรุณาเพิ่มหมวดหมู่ความรู้" : ""}</div>)}
                                        {x.subCategories.map((subCat, subCatIndex) => (
                                            <React.Fragment key={"subCat" + subCat.subCategoryId}>
                                                {!subCat.edit && (
                                                    <div
                                                        style={{ marginBottom: 5 }}
                                                        draggable={disabledDragSubCategory}
                                                        onDragStart={(e) => dragSubCategoryItem.current = subCatIndex}
                                                        onDragEnter={(e) => dragSubCategoryOverItem.current = subCatIndex}
                                                        onDragEnd={(e) => handleSubCategoryDrag(index)}
                                                        onDragOver={(e) => e.preventDefault()}
                                                        onDrop={e => setDisabledDragSubCategory(false)}
                                                    >

                                                        <div className='d-flex gray-button' style={{ padding: "5px", borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}>
                                                            {((subCat.collapse === undefined) || (subCat.collapse === false)) &&
                                                                <div onClick={(e) => setExpandSubCategory(true, index, subCatIndex)}><ExpandLess className="pointer mr-2" ></ExpandLess></div>
                                                            }
                                                            {(subCat.collapse === true) &&
                                                                <div onClick={(e) => setExpandSubCategory(false, index, subCatIndex)}><ExpandMore className="pointer mr-2" ></ExpandMore></div>
                                                            }
                                                            <span className='my-auto d-inline-flex'><Dot active={subCat.checked} /></span>
                                                            <label className='ml-2 my-auto'><span style={{ fontWeight: 600 }}>{subCat.subCategoryName} </span></label>
                                                            <div className='ml-auto my-auto'>
                                                                <img src={iconTrashRed} width={20} height={20} id={"icon_trash_subCategoryId" + index + "_" + subCat.subCategoryId} style={{ cursor: "pointer" }} className='mr-2' onClick={e => { handleDeleteSubCategory(index, subCatIndex) }} />
                                                                <span onMouseDown={e => setDisabledDragSubCategory(true)}
                                                                ><MenuIcon width={20} height={18} id={"icon_drag_subCategoryId" + index + "_" + subCat.subCategoryId} style={{ cursor: "grab" }} /></span>

                                                            </div>
                                                        </div>
                                                        {((subCat.collapse === undefined) || (subCat.collapse === false)) && (
                                                            <div style={{ padding: "15px", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px", border: "3px solid #DDDDDD" }}>
                                                                <div className='d-flex'><small>{subCat.subCategoryDescription}</small></div>
                                                                <ManageKnowledgeMedia subCat={subCat}
                                                                    submitted={props.submitted}
                                                                    editable={x.edit}
                                                                    indexCat={index}
                                                                    subCatIndex={subCatIndex}
                                                                    knowledgeList={props.knowledgeList}
                                                                    setKnowledgeList={props.setKnowledgeList} />
                                                            </div>
                                                        )}

                                                    </div>
                                                )}
                                                {subCat.edit && (
                                                    <div style={{ position: 'relative', padding: "15px", borderRadius: "5px", border: "1px solid #dde1e5" }} className='mb-3'>
                                                        <div className='row mt-1'>
                                                            <label className='col-2'>ชื่อหมวดหมู่ความรู้<span className='text-danger'>*</span></label>
                                                            <input type="text" className={'form-control col-8 ' + (getRequire(subCat.subCategoryName) ? "is-invalid" : "")} value={subCat.subCategoryName} onChange={(e) => setSubCategoryName(e, index, subCatIndex)} id={"input-text-subCategory-" + index + "_" + subCatIndex} />
                                                        </div>
                                                        <div className='row mt-1'>
                                                            <label className='col-2'>คำอธิบาย<span className='text-danger'>*</span></label>
                                                            <textarea className={'form-control col-8 ' + (getRequire(subCat.subCategoryDescription) ? "is-invalid" : "")} rows={4} value={subCat.subCategoryDescription} onChange={(e) => setSubCategoryDescription(e, index, subCatIndex)} id={"input-text-area-subCatDescription-" + index + "_" + subCatIndex} />
                                                        </div>
                                                        <div className='row mt-1'>
                                                            <label className='col-2'>สถานะ<span className='text-danger'>*</span></label>
                                                            <div className="col-2 mt-1">
                                                                <input id={"input-radio-subCat-active-true-" + index + "_" + subCatIndex} type="radio" checked={subCat.checked === true} onChange={(e) => { setSubCategoryStatus(index, subCatIndex, true) }} /> ใช้งาน
                                                            </div>
                                                            <div className="col-2 mt-1">
                                                                <input id={"input-radio-subCat-active-false-" + index + "_" + subCatIndex} type="radio" checked={subCat.checked === false} onChange={(e) => { setSubCategoryStatus(index, subCatIndex, false) }} /> ไม่ใช้งาน
                                                            </div>
                                                            <div className={(getRequire(subCat.checked)) ? "text-danger" : ""}>{(getRequire(subCat.checked)) ? "กรุณากรอก Status" : ""}</div>
                                                        </div>
                                                        <ManageKnowledgeMedia subCat={subCat}
                                                            submitted={props.submitted}
                                                            editable={x.edit}
                                                            indexCat={index}
                                                            subCatIndex={subCatIndex}
                                                            knowledgeList={props.knowledgeList}
                                                            setKnowledgeList={props.setKnowledgeList} />


                                                        <img src={iconTrashRed} width={20} height={20} id={"icon_trash_subCategoryId" + index + "_" + subCatIndex} style={{ position: "absolute", right: "5px", top: "5px", zIndex: 10, cursor: 'pointer' }} onClick={e => { handleDeleteSubCategory(index, subCatIndex) }} className='mr-2' />

                                                    </div>
                                                )}
                                            </React.Fragment>
                                        ))}
                                        {(!props.success) &&
                                            <div className='d-flex justify-content-center'>
                                                <Button type="button" className="btn btn-warning yellow-button"
                                                    onClick={() => handleAddSubCategory(index)} >
                                                    <AddIcon />
                                                    เพิ่มหมวดหมู่ความรู้
                                                </Button>

                                            </div>

                                        }
                                    </div>
                                </div>

                            </div>
                        )}


                    </>)}
                    {x.edit && (<>
                        <div className='d-flex yellow-button' style={{ padding: "15px", borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}>
                            <div className='ml-auto my-auto'>
                                <img src={iconTrash} width={20} height={20} id={"icon_trash_categoryId" + index + "_" + x.categoryId} onClick={e => { handleDeleteCategory(index) }} style={{ cursor: "pointer" }} className='mr-2' />
                            </div>

                        </div>
                        <div style={{ padding: "15px", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px", border: "3px solid #fab232" }}>
                            <div className='row mt-1'>
                                <label className='col-2' style={{ fontWeight: 600 }}>ประเภทความรู้<span className='text-danger'>*</span></label>
                                <input type="text" className={'form-control col-8 ' + (getRequire(x.categoryName as any) ? "is-invalid" : "")} value={x.categoryName as any} onChange={(e) => setCategoryName(e, index)} id={"input-text-categoryName-" + index} />
                            </div>
                            <div className='row mt-1'>
                                <label className='col-2' style={{ fontWeight: 600 }}>ฝ่ายงานที่รับผิดชอบ<span className='text-danger'>*</span></label>
                                <SingleSearch id={"input-dropdown-department-" + index} isSearchable={true} isMulti={false} placeholder=""
                                    onChange={(id: number, e: any) => setDepartment(index, e)}
                                    value={getDepartment(index)}
                                    options={createDepartment()}
                                    isError={getRequire(x.department)}
                                    style={{ width: "400px" }}
                                />
                            </div>
                            <div className='row mt-1'>
                                <label className='col-2' style={{ fontWeight: 600 }}>สถานะ<span className='text-danger'>*</span></label>
                                <div className="col-2 mt-1">
                                    <input id={"input-radio-categoryStatus-active-true" + index} type="radio" checked={x.isActive === true} onChange={(e) => { setCategoryStatus(index, true) }} /> ใช้งาน
                                </div>
                                <div className="col-2 mt-1">
                                    <input id={"input-radio-categoryStatus-active-false" + index} type="radio" checked={x.isActive === false} onChange={(e) => { setCategoryStatus(index, false) }} /> ไม่ใช้งาน
                                </div>
                                <div className={(getRequire(x.isActive)) ? "text-danger" : ""}>{(getRequire(x.isActive)) ? "กรุณากรอกสถานะ" : ""}</div>
                            </div>
                            <div className='row mt-1'>
                                <label className='col-2' style={{ fontWeight: 600 }}>หมวดหมู่ความรู้<span className='text-danger'>*</span></label>
                                {x.subCategories && (
                                    <div className='col-10 pl-0 mb-3'>
                                        {x.isActive && (<div className={(getRequireArray(x.subCategories)) ? "text-danger" : ""}>{(getRequireArray(x.subCategories)) ? "กรุณาเพิ่มหมวดหมู่ความรู้" : ""}</div>)}
                                        {x.subCategories.map((subCat, subCatIndex) => (
                                            <div key={'subCat' + subCatIndex} style={{ position: 'relative', padding: "15px", borderRadius: "5px", border: "1px solid #dde1e5" }} className='mb-3'>
                                                <div className='row mt-1'>
                                                    <label className='col-2'>ชื่อหมวดหมู่ความรู้<span className='text-danger'>*</span></label>
                                                    <input type="text" className={'form-control col-8 ' + (getRequire(subCat.subCategoryName) ? "is-invalid" : "")} value={subCat.subCategoryName} onChange={(e) => setSubCategoryName(e, index, subCatIndex)} id={"input-text-subCategory-" + index + "_" + subCatIndex} />
                                                </div>
                                                <div className='row mt-1'>
                                                    <label className='col-2'>คำอธิบาย<span className='text-danger'>*</span></label>
                                                    <textarea className={'form-control col-8 ' + (getRequire(subCat.subCategoryDescription) ? "is-invalid" : "")} rows={4} value={subCat.subCategoryDescription} onChange={(e) => setSubCategoryDescription(e, index, subCatIndex)} id={"input-text-area-subCatDescription-" + index + "_" + subCatIndex} />
                                                </div>
                                                <div className='row mt-1'>
                                                    <label className='col-2'>สถานะ<span className='text-danger'>*</span></label>
                                                    <div className="col-2 mt-1">
                                                        <input id={"input-radio-subCat-active-true-" + index + "_" + subCatIndex} type="radio" checked={subCat.checked === true} onChange={(e) => { setSubCategoryStatus(index, subCatIndex, true) }} /> ใช้งาน
                                                    </div>
                                                    <div className="col-2 mt-1">
                                                        <input id={"input-radio-subCat-active-false-" + index + "_" + subCatIndex} type="radio" checked={subCat.checked === false} onChange={(e) => { setSubCategoryStatus(index, subCatIndex, false) }} /> ไม่ใช้งาน
                                                    </div>
                                                    <div className={(getRequire(subCat.checked)) ? "text-danger" : ""}>{(getRequire(subCat.checked)) ? "กรุณากรอกสถานะ" : ""}</div>
                                                </div>
                                                <ManageKnowledgeMedia subCat={subCat}
                                                    submitted={props.submitted}
                                                    editable={x.edit}
                                                    indexCat={index}
                                                    subCatIndex={subCatIndex}
                                                    knowledgeList={props.knowledgeList}
                                                    setKnowledgeList={props.setKnowledgeList} />


                                                <img src={iconTrashRed} width={20} height={20} id={"icon_trash_subCategoryId" + index + "_" + subCatIndex} style={{ position: "absolute", right: "5px", top: "5px", zIndex: 10, cursor: 'pointer' }} onClick={e => { handleDeleteSubCategory(index, subCatIndex) }} className='mr-2' />

                                            </div>

                                        ))}
                                        {(!props.success) &&
                                            <div className='d-flex justify-content-center'>
                                                <Button type="button" className="btn btn-warning yellow-button"
                                                    onClick={() => handleAddSubCategory(index)} >
                                                    <AddIcon />
                                                    เพิ่มหมวดหมู่ความรู้
                                                </Button>

                                            </div>

                                        }
                                    </div>
                                )}
                            </div>
                        </div>
                    </>

                    )}
                </div>
                <ConfirmModal title={'กรุณากด "ยืนยัน" เพื่อลบรายการ'} name={itemDeleteSubCat?.name} show={confirmDeleteSubCat}
                    onHide={() => setConfirmDeleteSubCat(false)} onOk={() => deleteSubCat()} />
                <ConfirmModal title={'กรุณากด "ยืนยัน" เพื่อลบรายการ'} name={itemDeleteCat?.name} show={confirmDeleteCat}
                    onHide={() => setConfirmDeleteCat(false)} onOk={() => deleteCat()} />

            </React.Fragment>
        ))}</div>
    )
}

export default ManageKnowledgeCategory