import React from 'react'
import styled from 'styled-components'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import SortIcon from '@material-ui/icons/Sort';
type Props = {
    label: string;
    sortName: string;
    currentSort: string | undefined | null;
    sortType: 'asc' | 'desc';
    handleClick: (sortName: string,newSortType: 'asc' | 'desc') => any
}
const SortItem = styled.div`
    &:hover{
        color: orange;
        cursor: pointer;
    }
`
const Sort = (props: Props) => {
    const handleClick = () => {
        props.handleClick(props.sortName,props.sortType==='asc'?'desc':'asc');
    }
    return (
        <SortItem onClick={handleClick}>
            {props.label}
            {props.sortName === props.currentSort && (
                <>
                {props.sortType==='asc' && (<ArrowUpwardIcon/>)}
                {props.sortType==='desc' && (<ArrowDownwardIcon/>)}
                </>
            )}
            {props.sortName !== props.currentSort && (
                <SortIcon/>
            )}
        </SortItem>
    )
}

export default Sort