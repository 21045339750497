import React, { useState } from 'react'
import ExpandMore from '@material-ui/icons/ExpandMore'
import ExpandLess from '@material-ui/icons/ExpandLess'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import iconTrashRed from '../../../../img/iconTrashRed.png'
import iconEdit from '../../../../img/iconEdit.png'
import { PartnerProfileListResponse } from '../../../../model/response/partner-profile';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import { PartnerKnowledgeRegistration } from '../../../../model/request/partner-knowledge-regis';
import ConfirmModal from '../../../share/ConfirmModal';
import Select from 'react-select';
import { customStateStyles, greenStateStyles } from '../../../tab/search-component';
import { createSaveStateHaveNew } from '../../../../api/define';
import PartnerInfoModalPublic from './PartnerInfoModalPublic';
type Props = {
    parnerProfileList: PartnerProfileListResponse;
    success: boolean;
    submitted: boolean;
    setParnerProfileListChange: (parnerProfileList: PartnerProfileListResponse) => void;
    handleResendEmail: (partner: PartnerKnowledgeRegistration) => void;
    readonly: boolean;
    state?: string;
    submittedMerge?: boolean;
    oldPartnerItem?: PartnerKnowledgeRegistration[]
    hideDropdown?: boolean;
    hideResendEmail?: boolean;
    isPartner?: boolean;
}

const PartnerInfoPublic = (props: Props) => {
    const [expand, setExpand] = useState(true);
    const [partnerInfoItem, setPartnerInfoItem] = useState<any>();
    const [showPartnerInfoModal, setShowPartnerInfoModal] = useState(false);
    const [partnerInfoIndex, setPartnerInfoIndex] = useState<number | undefined>();
    const [itemDeletePartnerInfo, setItemDeletePartnerInfo] = useState<any>();
    const [confirmDeletePartnerInfo, setConfirmDeletePartnerInfo] = useState(false);
    const [tabColor, setTabColor] = useState(props.state ? props.state == 'old' ? 'gray-button' : 'yellow-button' : 'yellow-button');
    const [borderColor, setBorderColor] = useState(props.state ? props.state == 'old' ? '#dddddd' : '#fab232' : '#fab232')
    const handleAddPartnerInfo = () => {
        setPartnerInfoItem(undefined);
        setShowPartnerInfoModal(true);
    }
    const handleEditPartnerInfo = (index: number) => {
        setPartnerInfoIndex(index);
        let partnerInfo = props.parnerProfileList.partnerList[index];
        setPartnerInfoItem(partnerInfo);
        setShowPartnerInfoModal(true);
    }
    const handleDeletePartnerInfo = (index: number) => {
        const item = {
            index: index,
        }
        setItemDeletePartnerInfo(item as any);
        setConfirmDeletePartnerInfo(true);
    }
    const deletePartnerInfo = () => {
        var newItem = { ...props.parnerProfileList };
        if (itemDeletePartnerInfo) {
            const { index } = itemDeletePartnerInfo as any
            newItem.partnerList.splice(index, 1);
            props.setParnerProfileListChange(newItem);
        }
        setItemDeletePartnerInfo(undefined);
        setConfirmDeletePartnerInfo(false);
    }
    const hidePartnerModal = () => {
        setPartnerInfoItem(undefined);
        setShowPartnerInfoModal(false);
    }
    const handleUpdatePartner = (e: any) => {
        setShowPartnerInfoModal(false);
        props.setParnerProfileListChange(e);
        setPartnerInfoItem(undefined);
        setPartnerInfoIndex(undefined);
    }
    const handleResendEmail = (partner: PartnerKnowledgeRegistration) => {
        props.handleResendEmail(partner);
    }
    const getRequireMerge = (value: string | boolean | undefined | any[] | null | number) => {
        return (props.submittedMerge && (value == null || value == undefined))
    }
    const setMergeState = (value: any, index: number) => {
        var newItem = { ...props.parnerProfileList };
        newItem.partnerList[index].mergeState = value;
        props.setParnerProfileListChange(newItem);
    }
    const getMergeState = (index: number) => {
        let dropdown = createSaveStateHaveNew()
        for (var i = 0; i < dropdown.length; i++)
            if (dropdown[i].value === props.parnerProfileList?.partnerList[index]?.mergeState) {
                return { label: dropdown[i].label, value: props.parnerProfileList?.partnerList[index]?.mergeState };
            }
        return null;
    }
    const createOldCoordinatorDropdown = () => {
        var res: any[] = [];
        if (props.oldPartnerItem) {
            props.oldPartnerItem.forEach(x => {
                res.push({ label: x.firstname + ' ' + x.lastname, value: x.coordinatorId });
            })
        }
        return res
    }
    const setSelectOldCoordinatorId = (value: any, index: number) => {
        var newItem = { ...props.parnerProfileList };
        newItem.partnerList[index].selectedOldCoordinatorId = value;
        props.setParnerProfileListChange(newItem);
    }
    const getSelectOldFacultyId = (index: number) => {
        let dropdown = createOldCoordinatorDropdown()
        for (var i = 0; i < dropdown.length; i++)
            if (dropdown[i].value === props.parnerProfileList?.partnerList[index]?.selectedOldCoordinatorId) {
                return { label: dropdown[i].label, value: props.parnerProfileList?.partnerList[index]?.selectedOldCoordinatorId };
            }
        return null;
    }



    return (
        <React.Fragment>
            <div className="text-left" style={{ position: "relative", marginBottom: 5 }}>
                <div className={'d-flex ' + tabColor} style={{ padding: "15px", borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}>
                    {((expand === undefined) || (expand === false)) &&
                        <div onClick={(e) => setExpand(true)}><ExpandMore className="pointer mr-2" ></ExpandMore></div>
                    }
                    {(expand === true) &&
                        <div onClick={(e) => setExpand(false)}><ExpandLess className="pointer mr-2" ></ExpandLess></div>
                    }
                    <label className='ml-2 my-auto'><span style={{ fontWeight: 600, marginRight: 10 }}>ข้อมูลผู้ประสานงาน</span>{props.state && (<span style={{ fontWeight: 600, marginRight: 10 }}>{props.state == "old" ? "(เดิม)" : "(ใหม่)"}</span>)}</label>
                </div>
                {(expand === true) && (
                    <div style={{ padding: "15px", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px", border: "3px solid " + borderColor }}>
                        <div>
                            <div className='d-flex mt-2'>
                                {!props.readonly && <div onClick={() => handleAddPartnerInfo()} style={{ cursor: 'pointer', fontWeight: 600 }}><AddCircleOutlineIcon className="yellow mr-2" />ผู้ประสานงาน</div>}
                                {props.readonly && <div style={{ fontWeight: 600 }}>ผู้ประสานงาน</div>}
                            </div>
                            <table className='table mt-3' style={{ fontSize: "20px" }}>
                                <thead className='black-button'>
                                    <tr>
                                        <th style={{ width: '20%' }}>ชื่อ-นามสกุล</th>
                                        <th style={{ width: '20%' }}>ฝ่ายงาน/ตำแหน่งงาน</th>
                                        <th style={{ width: '20%' }}>เบอร์ที่ทำงาน / เบอร์มือถือ</th>
                                        <th style={{ width: '20%' }}>e-Mail</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody className='light-gray-button'>
                                    {props.parnerProfileList?.partnerList && props.parnerProfileList?.partnerList.map((partner, index) => (
                                        <>
                                            <tr>
                                                <td>
                                                    {partner.firstname} {partner.lastname}
                                                </td>
                                                <td>
                                                    {partner.department} <br /> {partner.position}
                                                </td>
                                                <td>
                                                    {partner.officeNo} <br /> {partner.mobileNo}
                                                </td>
                                                <td>
                                                    {partner.email}
                                                    {partner.verify && <CheckCircleIcon fontSize="small" style={{ color: '#4ba31e' }} />}
                                                </td>
                                                <td>
                                                    {!props.readonly && <>
                                                        {(!partner.verify && partner.coordinatorId && !props.hideResendEmail) && <EmailOutlinedIcon fontSize="small" style={{ color: '#007bff', cursor: "pointer" }} onClick={e => handleResendEmail(partner)} className='mr-2' />}
                                                        <img src={iconEdit} width={20} height={20} id={"icon_trash_partnerInfo" + index} onClick={e => handleEditPartnerInfo(index)} style={{ cursor: "pointer" }} className='mr-2' />
                                                        <img src={iconTrashRed} onClick={(e) => handleDeletePartnerInfo(index)} width={20} height={20} id={"icon_trash_partnerInfo" + index} style={{ cursor: "pointer" }} className='mr-2' />
                                                    </>}

                                                </td>



                                            </tr>
                                            {props.state == 'new' && !props.hideDropdown && <tr>
                                                <td colSpan={5} style={{ borderTop: 0 }}>
                                                    <div className='row col-12'>
                                                        <Select
                                                            isSearchable={true} isMulti={false} placeholder=""
                                                            onChange={(e: any) => setMergeState(e == null ? null : e?.value, index)}
                                                            value={getMergeState(index)}
                                                            options={createSaveStateHaveNew()}
                                                            styles={getRequireMerge(partner.mergeState) ? customStateStyles : greenStateStyles}

                                                        />
                                                        {partner.mergeState == 'SAVE' && (
                                                            <>
                                                                <label className='col-auto' style={{ fontWeight: 600 }}>ข้อมูลเดิม<span className='text-danger'>*</span></label>
                                                                <Select
                                                                    isSearchable={true} isMulti={false} placeholder=""
                                                                    onChange={(e: any) => setSelectOldCoordinatorId(e == null ? null : e?.value, index)}
                                                                    value={getSelectOldFacultyId(index)}
                                                                    options={createOldCoordinatorDropdown()}
                                                                    styles={getRequireMerge(partner.selectedOldCoordinatorId) ? customStateStyles : greenStateStyles}

                                                                />
                                                            </>

                                                        )}
                                                    </div>

                                                </td>
                                            </tr>}
                                        </>

                                    ))}

                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
            </div>
            {props.parnerProfileList && (
                <PartnerInfoModalPublic parnerProfileList={props.parnerProfileList}
                    show={showPartnerInfoModal}
                    onHide={() => hidePartnerModal()}
                    onOk={(e) => handleUpdatePartner(e)}
                    modalIndex={partnerInfoIndex}
                    modalItem={partnerInfoItem} />
            )}
            <ConfirmModal title={'กรุณากด "ยืนยัน" เพื่อลบรายการ'} name={''} show={confirmDeletePartnerInfo}
                onHide={() => setConfirmDeletePartnerInfo(false)} onOk={() => deletePartnerInfo()} />
        </React.Fragment>
  )
}

export default PartnerInfoPublic