import { useEffect, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import MainMenu from './menu';
import Footer from './adminfooter';
import ProjectTab from './projecttab';
import { Button, InputGroup, Modal, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { Util } from '../../api/util';
import { listdoc, adddoc, updatedoc, deletedoc, listproject } from '../../store/editprojectdoc/actions';
import { queryclear } from '../../store/search/actions';
import loadinglogo from '../../img/loading.gif';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import DescriptionIcon from '@material-ui/icons/Description';
import InfoIcon from '@material-ui/icons/Info';
import { createPage } from '../tab/pagetab';
import { AlertContainer, alert } from 'react-custom-alert';
import { createDocumentDescriptionSearchFilter } from '../../api/define';
import 'react-custom-alert/dist/index.css'; // import css file from root.
import { DocumentType } from '../../api/define';
import { MAX_UPLOAD_FILE_SIZE_LABEL, REQUIRE_LABEL, URL_LABEL } from '../../api/label';
import { GET_DOC_URL } from '../../api/doc-api';
import { SingleValue } from 'react-select';
import { SingleSearch } from '../tab/search-component';
import { MAX_FILE_UPLOAD } from '../../api/conf';
import { getAccessToken } from '../../store/auth/selectors';

const EditProjectDoc = () => {
    const token = useSelector(getAccessToken);
    const [init, setInit] = useState(0)
    const [initProject, setInitProject] = useState(0)
    const [items, setItems] = useState<any>({})
    const [projectItem, setProjectItem] = useState<any>({})
    const [projectSelect, setProjectSelect] = useState<SingleValue<{ label: string, value: number }>>()
    const [projectSearchSelect, setProjectSearchSelect] = useState<SingleValue<{ label: string, value: number }>>()

    const [fileType, setFileType] = useState<DocumentType>(DocumentType.FILE);     //file or url
    const [currentFile, setCurrentFile] = useState<FileList>();
    const [errorFile, setErrorFile] = useState("");
    const [caption, setCaption] = useState("");
    const [errorCaption, setErrorCaption] = useState("");
    const [url, setUrl] = useState("");
    const [errorUrl, setErrorUrl] = useState("");
    const [description, setDescription] = useState("");
    const [errorDescription, setErrorDescription] = useState("");

    const [search, setSearch] = useState("");  //name
    const [page, setPage] = useState(0)
    const [limit, setLimit] = useState(10)
    const [loading, setLoading] = useState(false)
    const [updating, setUpdating] = useState(false)

    const [deleteId, setDeleteId] = useState<string>("");
    const [editId, setEditId] = useState<string>("");

    const [error, setError] = useState('');
    const [upload, setUpload] = useState(false);
    const store = useStore();
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        const unsubscribe = store.subscribe(() => {
            let res = store.getState().SearchReducer;
            if (res.isLoading) {
                setError('');
                setLoading(true);
            } else if ((res.isLoaded) && (!res.isUpdated)) {
                setError('');
                setLoading(false);
                if (res.data != null) {
                    if (res.table === "list_project") {
                        setProjectItem(res.data);
                        setInitProject(1);
                    } else {
                        setPage(0);
                        setItems(res.data);
                        setInit(1);
                    }
                }
            } else if (res.isUpdating) {
                setError('');
                setUpdating(true);
            } else if (res.isUpdated) {
                setError('');
                setUpdating(false);
                setUpload(false);
                dispatch(queryclear());
                setProjectSelect(undefined);
                dispatch(listdoc("", 0, 0));
                alert({ message: 'Saved', type: 'success' });
            } else if (res.isFailed) {
                setError(res.data.message);
                setUpload(false);
                setLoading(false);
            }
        })
        return unsubscribe;
    }, [page, limit, search])

    useEffect(() => {
        dispatch(queryclear());
        dispatch(listproject());
        dispatch(listdoc("", 0, 0));
    }, [])

    const createProjectSearchFilter = () => {
        var res: any[] = [];
        for (var i = 0; i < projectItem.length; i++) {
            res.push({ label: projectItem[i].shortName + " : " + projectItem[i].name, value: projectItem[i].id });
        }
        return res;
    }

    const createProjectAllSearchFilter = () => {
        var res: any[] = [];
        res.push({ label: "All", value: NaN });
        for (var i = 0; i < projectItem.length; i++) {
            res.push({ label: projectItem[i].shortName + " : " + projectItem[i].name, value: projectItem[i].id });
        }
        return res;
    }

    const getDescription = () => {
        if (description === null) return null;
        var d = createDocumentDescriptionSearchFilter();
        for (var i = 0; i < d.length; i++) {
            if (d[i].value === description) {
                return { label: d[i].label, value: description };
            }
        }
        return null;
    }

    const createDescription = (e: string) => {
        if (e === null) return null;
        var d = createDocumentDescriptionSearchFilter();
        for (var i = 0; i < d.length; i++) {
            if (d[i].value === e) {
                return d[i].label;
            }
        }
        return "";
    }

    const createColor = (item: any) => {
        if (item.projectId !== undefined) return "light-green";
        if (item.classId !== undefined) return "red";
        return "light-blue";
    }

    const handleAdd = () => {
        setProjectSelect(null);
        setFileType(DocumentType.FILE);
        setCurrentFile(undefined);
        setUrl("");
        setCaption("");
        setDescription("");
        setEditId("");
        setDeleteId("");
        resetError();
        setUpload(true);
    }

    const setProjectId = (id: number) => {
        for (var i = 0; i < projectItem.length; i++) {
            if (projectItem[i].id === id) {
                setProjectSelect({ label: projectItem[i].shortName + " : " + projectItem[i].name, value: projectItem[i].id })
            }
        }
    }

    const getProjectName = (id: number) => {
        for (var i = 0; i < projectItem.length; i++) {
            if (projectItem[i].id === id) {
                return projectItem[i].shortName + " : " + projectItem[i].name;
            }
        }
        return "";
    }

    const handleEdit = (id: string) => {
        setEditId(id);
        resetError();
        for (var i = 0; i < items.docResponseList.length; i++) {
            var r = items.docResponseList[i];
            if (r.id === id) {
                setProjectId(r.projectId);
                if (r.docType === DocumentType.FILE) { setFileType(DocumentType.FILE); setUrl(""); }
                else { setFileType(DocumentType.URL); setUrl(r.url); }
                setCurrentFile(undefined);
                setCaption(r.caption);
                setDescription(r.description);
            }
        }
        setDeleteId("");
        setUpload(true);
    }

    const handleDelete = (id: string) => {
        setEditId("");
        setDeleteId(id);
        setUpload(true);
    }

    const handleDeleteConfirm = () => {
        setUpload(false);
        dispatch(queryclear());
        dispatch(deletedoc(deleteId));
        setDeleteId("");
    }

    const handleLimit = (l: number) => {
        setLimit(l);
    }

    const handlePage = (l: number) => {
        setPage(l);
    }

    const getExtension = (url: string) => {
        while (url.indexOf(".") !== -1) {
            url = url.substring(url.indexOf(".") + 1);
        }
        if (url.indexOf("?") !== -1) {
            url = url.substring(0, url.indexOf("?"));
        }
        return url;
    }

    const setFileChange = (selectorFiles: FileList | null) => {
        if (selectorFiles !== null) {
            setCurrentFile(selectorFiles);
        }
    }

    const resetError = () => {
        setErrorCaption("");
        setErrorFile("");
        setErrorUrl("");
        setErrorDescription("");
    }

    const validateForm = (): boolean => {
        resetError();
        var check = true;
        if (caption === "") { check = false; setErrorCaption(REQUIRE_LABEL); }
        if (description === "") { check = false; setErrorDescription(REQUIRE_LABEL); }
        if ((fileType === DocumentType.FILE) && (currentFile === undefined) && (editId === "")) { check = false; setErrorFile(REQUIRE_LABEL); }
        else if ((fileType === DocumentType.URL) && (url === "")) { check = false; setErrorUrl(REQUIRE_LABEL); }
        else if ((fileType === DocumentType.URL) && (url.indexOf("http://") !== 0) && (url.indexOf("https://") !== 0)) { check = false; setErrorUrl(URL_LABEL); }

        if ((fileType === DocumentType.FILE) && (currentFile !== undefined)) {
            if (currentFile[0].size > MAX_FILE_UPLOAD * 1000000) {
                check = false;
                setErrorFile(MAX_UPLOAD_FILE_SIZE_LABEL);
            }
        }
        return check;
    }

    const handleSubmit = () => {
        if ((validateForm()) && (projectSelect != null)) {
            dispatch(queryclear);
            setUpdating(true);
            if (editId === "") dispatch(adddoc(projectSelect?.value, caption, url, description, fileType, currentFile));
            else dispatch(updatedoc(projectSelect?.value, editId, caption, url, description, fileType, currentFile));
        }
    }

    const handleGetDoc = (id: string) => {
        window.open(GET_DOC_URL + "/" + id + "?token=" + encodeURIComponent(token), '_blank', 'noopener,noreferrer')
    }

    const handleUrl = (url: string) => {
        window.open(url, '_blank', 'noopener,noreferrer')
    }


    const handleSearch = (e: string) => {
        setSearch(e);
        setPage(0);
    }

    const getTotalItem = () => {
        return filter().length;
    }

    const getTotalPage = () => {
        if (items.docResponseList === undefined) return 0;
        return Math.ceil(getTotalItem() / limit);
    }

    const filter = () => {
        var f: any = [];
        for (var i = 0; i < items.docResponseList.length; i++) {
            var it = items.docResponseList[i];
            if (((search === "") || (it.caption.indexOf(search) !== -1)) &&
                ((projectSearchSelect === undefined) || (isNaN(projectSearchSelect?.value as number)) || (it.projectId === projectSearchSelect?.value))) {
                f.push(it);
            }
        }
        return f;
    }
    const handleDoc = () => {
        history.push("/admin/project/doc");
    }
    const handleProject = () => {
        history.push("/admin/project/info");
    }
    const handleProjectGroup = () => {
        history.push("/admin/project-group/info");
    }



    return (<>
        <MainMenu>
            <div>
                <div className="query-wrapper">
                    <br />
                    <div className="yellow-button" style={{ textAlign: "left", borderTopLeftRadius: "30px", borderTopRightRadius: "30px", height: "70px", paddingTop: "15px", paddingLeft: "15px" }}><h4 className="header">การจัดการข้อมูลรายโครงการ</h4></div>
                    {(init + initProject === 2) &&
                        <>
                            <br />
                            {ProjectTab(2, true, handleDoc, handleProject,handleProjectGroup)}
                            <div style={{ background: "white", padding: "40px", borderRadius: "5px" }}>
                                <div className="row">
                                    <div className="col-1" style={{ paddingTop: "5px" }}>
                                        Search
                                    </div>
                                    <div className="col-4" style={{ textAlign: "left" }}>
                                        <input className="form-control" value={search} onChange={(e) => { handleSearch(e.currentTarget.value) }} />
                                    </div>
                                    <div className="col-4" style={{ textAlign: "left" }}>
                                        <SingleSearch
                                            isSearchable={true} isMulti={false} placeholder=""
                                            onChange={(id: string, e: any) => setProjectSearchSelect(e)}
                                            value={projectSearchSelect}
                                            options={createProjectAllSearchFilter()}
                                            style={{ width: "350px" }}
                                        />
                                    </div>
                                    <div className="col-3" style={{ textAlign: "right" }} >
                                        <Button type="button" className="btn btn-warning yellow-button" style={{ width: "180px" }}
                                            onClick={() => handleAdd()} >
                                            <AddIcon />
                                            เพิ่มเอกสาร
                                        </Button>
                                    </div>
                                </div>
                                <br />
                                <div className="row">
                                    <div className="col-5" style={{ justifyContent: "left", textAlign: "left" }}>
                                        จำนวนทั้งหมด {Util.integerWithCommas(getTotalItem())} รายการ
                                    </div>
                                    <div className="col-1">
                                    </div>
                                    <div className="col-6 row" style={{ justifyContent: "right", textAlign: "right", paddingRight: "0px" }}>
                                        <div style={{ textAlign: "right", marginTop: "auto", marginBottom: "auto" }}>จำนวนรายการต่อหน้า</div>&nbsp;
                                        <select className="form-control" style={{ width: "80px" }} name="page" value={limit} onChange={e => handleLimit(parseInt(e.target.value))}>
                                            <option value={getTotalItem()}>All</option>
                                            <option value="10">10</option>
                                            <option value="20">20</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                    </div>
                                </div>
                                <br />
                                <Table striped hover style={{ borderRadius: "5px", borderCollapse: "collapse" }}>
                                    <thead className="black-button">
                                        <tr>
                                            <th style={{ verticalAlign: "top", width: "50px" }}>ลำดับ</th>
                                            <th className='text-left' style={{ verticalAlign: "top" }}>รายชื่อเอกสาร</th>
                                            <th className='text-left' style={{ verticalAlign: "top" }}>ระดับการเข้าถึงเอกสาร</th>
                                            <th className='text-left' style={{ verticalAlign: "top" }}>ประเภทเอกสาร</th>
                                            <th style={{ verticalAlign: "top" }}>ไฟล์</th>
                                            <th style={{ verticalAlign: "top", width: "80px" }}></th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ fontSize:"23px" }}>
                                        {filter().map((item: any, index: number) => {
                                            if ((index >= page * limit) && (index < (page + 1) * limit))
                                                return <tr key={item.id}>
                                                    <td> {index + 1}</td>
                                                    <td className='text-left'>
                                                        {item.caption}
                                                    </td>
                                                    <td className='text-left' style={{ textAlign: "left" }}>
                                                        <DescriptionIcon className={createColor(item)}></DescriptionIcon>
                                                        {(item.projectId !== undefined) &&
                                                            <>{getProjectName(item.projectId)}</>
                                                        }
                                                        {(item.classId !== undefined) &&
                                                            <>รอบการเรียน</>
                                                        }
                                                        {(item.classCourseId !== undefined) &&
                                                            <>Step {item.step} : {item.name}</>
                                                        }
                                                    </td>
                                                    <td className='text-left'>  {createDescription(item.description)}  </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        {(item.docType === DocumentType.FILE) &&
                                                            <div onClick={(e) => handleGetDoc(item.id)} style={{ cursor: "pointer", textDecoration: "underline" }}>{getExtension(item.url)}</div>
                                                        }
                                                        {(item.docType !== DocumentType.FILE) &&
                                                            <div onClick={(e) => handleUrl(item.url)} style={{ cursor: "pointer", textDecoration: "underline" }}>Link</div>
                                                        }
                                                    </td>
                                                    <td>
                                                        <div className="row"><div onClick={(e) => handleEdit(item.id)}><EditIcon className="yellow pointer" ></EditIcon></div>&nbsp;<div onClick={(e) => handleDelete(item.id)}><CancelIcon className="red pointer"></CancelIcon></div></div>
                                                    </td>
                                                </tr>
                                        })}
                                    </tbody>
                                </Table>
                                <br />
                                <div className="row" style={{ justifyContent: "center", position: "relative" }}>
                                    {createPage(page, getTotalPage(), handlePage)}
                                    <div className="col-5" style={{ justifyContent: "left", textAlign: "left", position: "absolute", left: "0px" }}>
                                        จำนวนทั้งหมด {Util.integerWithCommas(getTotalItem())} รายการ <br />
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    <br />
                    {(((init + initProject < 2) || (loading)) && (error === "")) &&
                        <div style={{ textAlign: "center" }}><img src={loadinglogo} width="30" height="30" className="d-inline-block align-top" alt="" /></div>
                    }
                    {(error !== "") &&
                        <div style={{ color: 'red', textAlign: "center" }}>{error}</div>
                    }
                </div>
                <br />
                <Footer />
            </div>
        </MainMenu>
        <Modal size="xl" show={upload} onHide={() => { setUpload(false) }} >
            <Modal.Header closeButton={!updating}>
                <Modal.Title>
                    {(deleteId !== "") &&
                        <>ยืนยันการลบ?</>
                    }
                    {((deleteId === "") && (editId === "")) &&
                        <>เพิ่มเอกสาร</>
                    }
                    {((deleteId === "") && (editId !== "")) &&
                        <>แก้ไขเอกสาร</>
                    }
                </Modal.Title>
            </Modal.Header>
            {(deleteId === "") &&
                <Modal.Body >
                    {(deleteId === "") &&
                        <>
                            <div className="row col-12">
                                <div className="col-4" style={{ marginTop: "auto", marginBottom: "auto" }}>
                                    <label className="form-check-label">
                                        โครงการ
                                    </label>
                                </div>
                                <div className="col-8" style={{ marginTop: "auto", marginBottom: "auto" }}>
                                    <SingleSearch
                                        disabled={updating}
                                        isSearchable={true} isMulti={false} placeholder=""
                                        onChange={(id: string, e: any) => setProjectSelect(e)}
                                        value={projectSelect}
                                        options={createProjectSearchFilter()}
                                        style={{ width: "100%" }}
                                    />
                                </div>
                            </div>
                            <hr /></>
                    }
                    {(deleteId === "") &&
                        <>
                            <div className="row col-12" style={{ height: "50px" }}>
                                <div className="col-2" style={{ marginTop: "auto", marginBottom: "auto" }}>
                                    <input className="form-check-input " type="radio" checked={fileType === DocumentType.FILE} onClick={e => { setFileType(DocumentType.FILE) }} disabled={updating} />
                                    <label className="form-check-label">
                                        File
                                    </label>
                                </div>
                                <div className="col-2" style={{ marginTop: "auto", marginBottom: "auto" }}>
                                    <input className="form-check-input" type="radio" checked={fileType === DocumentType.URL} onClick={e => { setFileType(DocumentType.URL) }} disabled={updating} />
                                    <label className="form-check-label">
                                        URL
                                    </label>
                                </div>
                                <div className="col-8" style={{ marginTop: "auto", marginBottom: "auto", visibility: (fileType === DocumentType.URL) ? "visible" : "hidden", position: (fileType === DocumentType.URL) ? "relative" : "absolute" }}>
                                    <InputGroup hasValidation>
                                        <input type="text" className={"form-control " + ((errorUrl !== "") ? "is-invalid" : "")} value={url} onChange={(e) => setUrl(e.target.value)} maxLength={256} disabled={updating} />
                                    </InputGroup>
                                    <div className={(errorUrl !== "") ? "text-danger" : ""}><small>{errorUrl}</small></div>
                                </div>
                                <div className="col-8" style={{ marginTop: "auto", marginBottom: "auto", visibility: (fileType === DocumentType.FILE) ? "visible" : "hidden", position: (fileType === DocumentType.FILE) ? "relative" : "absolute" }}>
                                    <InputGroup hasValidation>
                                        <input type="file" className={"form-control " + ((errorFile !== "") ? "is-invalid" : "")} accept={Util.fileAcceptType()} id="contained-button-file" onChange={(e: any) => setFileChange(e.target.files)} disabled={updating} />
                                        <OverlayTrigger
                                            key={'top'}
                                            placement={'top'}
                                            overlay={
                                                <Tooltip id={`tooltip-top`}>
                                                    Please attach file with pdf, xls, doc, ppt, jpg, png . Maximum upload size: 20MB/file Please be aware of an attachment may contain confidential information.
                                                </Tooltip>
                                            }
                                        >
                                            <span className='m-2'><InfoIcon /></span>
                                        </OverlayTrigger>
                                    </InputGroup>
                                    <div className={(errorFile !== "") ? "text-danger" : ""}><small>{errorFile}</small></div>
                                </div>
                            </div>
                            <br />
                            <div>
                                ประเภทเอกสาร <span className='red'>*</span><br />
                                <InputGroup hasValidation>
                                    <SingleSearch
                                        disabled={updating}
                                        isSearchable={true} isMulti={false} placeholder=""
                                        onChange={(id: string, e: any) => setDescription(e.value)}
                                        value={getDescription()}
                                        options={createDocumentDescriptionSearchFilter()}
                                        style={{ width: "100%" }}
                                    />
                                </InputGroup>
                                <div className={(errorDescription !== "") ? "text-danger" : ""}><small>{errorDescription}</small></div>
                            </div>
                            <br />
                            <div>
                                รายชื่อเอกสาร <span className='red'>*</span><br />
                                <InputGroup hasValidation>
                                    <input type="text" className={"form-control " + ((errorCaption !== "") ? "is-invalid" : "")} value={caption} onChange={(e) => { setCaption(e.target.value) }} maxLength={256} disabled={updating} />
                                </InputGroup>
                                <div className={(errorCaption !== "") ? "text-danger" : ""}><small>{errorCaption}</small></div>
                            </div>
                            <br />
                            {((updating) && (error === "")) &&
                                <div style={{ textAlign: "center" }}><img src={loadinglogo} width="30" height="30" className="d-inline-block align-top" alt="" /></div>
                            }
                            {(!updating) &&
                                <div style={{ textAlign: "center" }}>
                                    <Button variant="secondary" onClick={() => { setUpload(false) }} style={{ width: "100px" }}>
                                        Cancel
                                    </Button>&nbsp;
                                    <Button variant="primary" onClick={handleSubmit} style={{ width: "100px" }}>
                                        Save
                                    </Button>
                                </div>
                            }
                        </>
                    }
                </Modal.Body>
            }
            {(deleteId !== "") &&
                <Modal.Footer>
                    <Button variant="secondary" onClick={(e: any) => { setUpload(false) }}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleDeleteConfirm}>
                        Delete
                    </Button>
                </Modal.Footer>
            }
        </Modal>
        <AlertContainer floatingTime={3000} />
    </>)
}

export default EditProjectDoc;

