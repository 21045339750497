import React, { useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import AttachFileIcon from '@material-ui/icons/AttachFileOutlined';
import loadinglogo from '../../img/loading.gif';
const ButtonUpload = styled.span`
      margin-top: -5px;
     padding: 0.375rem 0.75rem;
     border: 1px solid rgb(3,110,227,.2);
     border-radius: 4px;
     background-color: #e6f7ff;
     color: #fbb034;
     transition: visibility 0.3s linear;
     &:hover{
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
     }
     .link{
      font-size: 1rem !important;
      color: #007bff !important;
      &:hover{
      text-decoration: underline;
      }
     }
`;
type FileUploaderProp = {
  id:string;
  accept:string;
  buttonTitle: string;
  fileList: any[];
  loading: boolean;
  handleFile: (file: File) => any;
  onDownload: (id: string) => any
}
const FileUploader = (props: FileUploaderProp) => {
  const hiddenFileInput = useRef<any>(null);

  const handleClick = (event: any) => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };
  const handleChange = (event: any) => {
    event.currentTarget.id = props.id;
    props.handleFile(event);
  };
  return (
    <>
      <Button onClick={handleClick} className="btn btn-primary" style={{ marginTop: "-5px" }} disabled={props.loading}>
        {(props.loading) &&
          <img src={loadinglogo} width="30" height="30" className="d-inline-block align-top" alt="" />
        }
        {(!props.loading) &&
          <>{props.buttonTitle}</>
        }
        
      </Button>
      <input type="file"
        accept={props.accept}
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: 'none' }}
      />
      {props.fileList && props.fileList.map((item: any, index) => (
        <ButtonUpload key={'file' + index}>
          <span className='link' onClick={() => props.onDownload(item.assignmentId)}><AttachFileIcon />{item.assignmentName}</span>
        </ButtonUpload>
      ))}
    </>
  );
};
export default FileUploader;