import React, { useState } from 'react'
import ExpandMore from '@material-ui/icons/ExpandMore'
import ExpandLess from '@material-ui/icons/ExpandLess'
import { PartnerActivityTrainingResponse } from '../../../../model/response/partner-activity-training';
import styled from 'styled-components';
import { TableHeaderBlack } from '../../../../style/tableStyle';
type Props = {
    item: PartnerActivityTrainingResponse | undefined;
}
const Square = styled.div<{ bgcolor?: string }>`
    width: 22px;
    height: 22px;
    background: ${p => p.bgcolor ? p.bgcolor : 'white'};
`

const PartnerActivityOrganizeTrainingOverview = (props: Props) => {
    const [expand, setExpand] = useState(true);

    const calculateSumProjectAmount = (num1: any|undefined, num2: any|undefined) => {
        if (num1 && num2) {
            return num1 + num2;
        } else if (num1) {
            return num1;
        } else if (num2) {
            return num2
        }
        return "-";
    }

    return (
        <React.Fragment>
            <div style={{ background: "white" }} className='mt-2'>
                <div className="text-left" style={{ position: "relative", marginBottom: 5 }}>
                    <div className={'d-flex yellow-button'} style={{ padding: "15px", borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}>
                        {((expand === undefined) || (expand === false)) &&
                            <div onClick={(e) => setExpand(true)}><ExpandMore className="pointer mr-2" ></ExpandMore></div>
                        }
                        {(expand === true) &&
                            <div onClick={(e) => setExpand(false)}><ExpandLess className="pointer mr-2" ></ExpandLess></div>
                        }
                        <label className='ml-2 my-auto'><span style={{ fontWeight: 600, marginRight: 10 }}>ภาพรวมโครงการ</span></label>
                    </div>
                    {(expand === true) && (
                        <div style={{ padding: "15px", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px", border: "3px solid #fab232" }}>
                            <div className='row'>
                                <label className='col' style={{ fontWeight: 600 }}>สัดส่วน (จำนวนคน) เข้าร่วมโครงการฯ ในรูปแบบจัดอบรมร่วมกับองค์กรพันธมิตร หรือ จัดอบรมทั่วไป</label>
                            </div>
                            {!props.item?.overview?.notFoundInfo && (<>
                                <div className='row'>
                                    <div className='col-1'></div>
                                    <div className='col-5' style={{ top: -13 }}>
                                        <div className='d-flex '>
                                            <Square bgcolor={'#628295'} /> <span className='ml-2'>จัดอบรมร่วมกับองค์กรพันธมิตร ({props.item?.overview?.partnerTraining}-{props.item?.trainingInfo.cooperativeAmountPartner || '0'} คน)</span>
                                        </div>
                                        <div className='d-flex'>
                                            <Square bgcolor={'#b7c6cf'} /> <span className='ml-2'>จัดอบรมทั่วไป ({props.item?.overview?.publicTraining}-{props.item?.trainingInfo.cooperativeAmountPublic || '0'} คน)</span>
                                        </div>
                                    </div>
                                    <div className='col-5'>
                                        <div className="progress" style={{ maxWidth: "100%", fontSize: 26, position: 'relative' }}>
                                            <div className="progress-bar"
                                                style={{ width: props.item?.overview?.partnerTraining, backgroundColor: "#628295" }}>
                                                {props.item?.overview?.partnerTraining}
                                            </div>
                                            <div className="progress-bar progress-bar-stripped"
                                                style={{ width: props.item?.overview?.publicTraining, backgroundColor: "#b7c6cf" }}>
                                                {props.item?.overview?.publicTraining}
                                            </div>

                                        </div>
                                    </div>


                                </div>
                                <div className='row col'>
                                    <TableHeaderBlack striped hover className='mt-2'>
                                        <thead>
                                            <tr>
                                                <th className='text-left' style={{ verticalAlign: "top", width: "40%" }}>
                                                </th>
                                                <th className='text-right' style={{ verticalAlign: "top", width: "20%" }}>
                                                    จัดอบรมร่วมกับองค์กรพันธมิตร
                                                </th>
                                                <th className='text-right' style={{ verticalAlign: "top", width: "20%" }}>
                                                    จัดอบรมทั่วไป
                                                </th>
                                                <th className='text-right' style={{ verticalAlign: "top", width: "20%" }}>
                                                    รวม
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>จำนวนโครงการ</td>
                                                <td className='text-right pr-3'>{props.item?.trainingInfo.projectAmountPartner}</td>
                                                <td className='text-right pr-3'>{props.item?.trainingInfo.projectAmountPublic}</td>
                                                <td className='text-right pr-3'>{calculateSumProjectAmount(props.item?.trainingInfo.projectAmountPartner, props.item?.trainingInfo.projectAmountPublic)}</td>
                                            </tr>
                                            <tr>
                                                <td>จำนวนคนเข้าร่วมโครงการฯ ทั้งหมด</td>
                                                <td className='text-right pr-3'>{props.item?.trainingInfo.cooperativeAmountPartner}</td>
                                                <td className='text-right pr-3'>{props.item?.trainingInfo.cooperativeAmountPublic}</td>
                                                <td className='text-right pr-3'>{calculateSumProjectAmount(props.item?.trainingInfo.cooperativeAmountPartner, props.item?.trainingInfo.cooperativeAmountPublic)}</td>
                                            </tr>
                                            <tr>
                                                <td>จำนวนคนผ่านโครงการฯ ทั้งหมด</td>
                                                <td className='text-right pr-3'>{props.item?.trainingInfo.passAmountPartner}</td>
                                                <td className='text-right pr-3'>{props.item?.trainingInfo.passAmountPublic}</td>
                                                <td className='text-right pr-3'>{calculateSumProjectAmount(props.item?.trainingInfo.passAmountPartner, props.item?.trainingInfo.passAmountPublic)}</td>
                                            </tr>
                                            {/* <tr>
                                                <td>จำนวนหลักสูตร</td>
                                                <td className='text-right pr-3'>{props.item?.trainingInfo.courseAmountPartner}</td>
                                                <td className='text-right pr-3'>{props.item?.trainingInfo.courseAmountPublic}</td>
                                            </tr> */}
                                        </tbody>
                                    </TableHeaderBlack>
                                </div>
                            </>)}
                            {props.item?.overview?.notFoundInfo && (<span>ไม่พบข้อมูลเข้าร่วมโครงการ</span>)}

                        </div>
                    )}

                </div>
            </div>
        </React.Fragment>
    )
}

export default PartnerActivityOrganizeTrainingOverview