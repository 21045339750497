import { LoginType } from "../../api/define";
import { RootState } from "../store";

export function getIsLoggedIn(state: RootState) {
    var res=false;
    if ((state.AuthReducer.isLoggedIn) && (state.AuthReducer.token!==undefined) && (state.AuthReducer.token.access_token!==undefined)) {
        var now=Math.floor(new Date().getTime()/1000);
        var refreshTime=state.AuthReducer.refreshTime;
        if (now-refreshTime<state.AuthReducer.token.refresh_expires_in) {
            res=true;
        }
    }
	return res;
}

export function getUsername(state: RootState) {
    return state.AuthReducer.username;
}

export function getDeptId(state: RootState) {
    return state.AuthReducer.depId;
}
export function getDeptName(state: RootState) {
    return state.AuthReducer.deptName;
}

export function getEmail(state: RootState) {
    return state.AuthReducer?.email ?? '';
}

export function getUserType(state: RootState) {
    return state.AuthReducer.usertype;
}

export function getIsAdmin(state: RootState) {
    return state.AuthReducer.usertype===LoginType.ADMIN;
}

export function getIsStudent(state: RootState) {
    return state.AuthReducer.usertype===LoginType.STUDENT;
}

export function getIsTrainer(state: RootState) {
    return state.AuthReducer.usertype===LoginType.TRAINER;
}

export function getAccessToken(state: RootState) {
    return state.AuthReducer.token.access_token;
}

export function getRefreshToken(state: RootState) {
    return state.AuthReducer.token.refresh_token;
}
