import React from 'react'
import { Button, Modal, ModalProps } from 'react-bootstrap'

type Props = ModalProps &
{
    body: string;
    okButton?: string;
    onOk: (value: any) => void
}
const AlertModal = (props: Props) => {
    return (
        <Modal
            {...props}
            dialogClassName="modal-30w"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            centered
        >
            {/* <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    {props.title}
                </Modal.Title>
            </Modal.Header> */}
            <Modal.Body>
                {props.body}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => { props.onOk(true) }}>
                    {props.okButton || 'ยืนยัน'}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default AlertModal