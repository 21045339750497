import { Dispatch } from 'redux';
import { ActionType, SearchActionType } from '../search/types';
import { PartnerAPI } from '../../api/partner-api';
import { dispatchError } from '../search/actions'; 
import { MediaType } from '../../api/define';

export const listpartner = (name: string ,code: string,orgType: string | null,page: number,limit: number) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    PartnerAPI.list(name,code,orgType,page,limit,null).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "partner",
            page: "edit_partner",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("edit_partner",dispatch,error);
    })
};

export const getpartner = (id: number) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    PartnerAPI.get(id).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "partner",
            page: "edit_partner",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("edit_partner",dispatch,error);
    })
};

export const listorganization = (page: number,limit: number,partnerProfile?: boolean) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    PartnerAPI.listOrganization(page,limit,partnerProfile).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "org_type",
            page: "edit_partner",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("edit_partner",dispatch,error);
    })
};

export const listhr = () => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    PartnerAPI.listHR().then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "hr",
            page: "edit_partner",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("edit_partner",dispatch,error);
    })
};

export const createpartner = (item: any) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_UPDATING
    });
    PartnerAPI.create(item).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_UPDATED,
            payload: response.data,
            table: "partner",
            page: "edit_partner",
            action: ActionType.UPDATE
        });
    }, function (error) {
        if ((error.response!==undefined) && (error.response.status===400)) {
            dispatch({
                type: SearchActionType.QUERY_ERROR,
                page: "edit_partner",
                payload: error.response.data.additional
            });
        } else 
        dispatchError("edit_partner",dispatch,error);
    })
};

export const updatepartner = (id: number,item: any) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_UPDATING
    });
    PartnerAPI.update(id,item).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_UPDATED,
            payload: response.data,
            table: "partner",
            page: "edit_partner",
            action: ActionType.UPDATE
        });
    }, function (error) {
        if ((error.response!==undefined) && (error.response.status===400)) {
            dispatch({
                type: SearchActionType.QUERY_ERROR,
                page: "edit_partner",
                payload: error.response.data.additional
            });
        } else 
            dispatchError("edit_partner",dispatch,error);
    })
};

export const listKnowledgeDepartment = () => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    PartnerAPI.listKnowledgeDepartment().then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "list_knowledge_department",
            page: "manage_knowledge",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("manage_knowledge",dispatch,error);
    })
};

export const listKnowledgeCategory = () => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    PartnerAPI.listKnowledgeCategory().then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "list_knowledge_category",
            page: "manage_knowledge",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("manage_knowledge",dispatch,error);
    })
};

export const uploadFileKnowledge = (partnerKnowledgeFileType: string,file: File) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    PartnerAPI.uploadFileKnowledge(partnerKnowledgeFileType,file).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "upload_file_knowledge",
            page: "manage_knowledge",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("manage_knowledge",dispatch,error);
    })
};

export const saveKnowledgePartner = (item: any) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_UPDATING
    });
    PartnerAPI.saveKnowledgePartner(item).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_UPDATED,
            payload: response.data,
            table: "save_knowledge",
            page: "manage_partner",
            action: ActionType.UPDATE
        });
    }, function (error) {
        if ((error.response!==undefined) && (error.response.status===400)) {
            dispatch({
                type: SearchActionType.QUERY_ERROR,
                page: "manage_partner",
                payload: error.response.data.additional
            });
        } else 
        dispatchError("manage_partner",dispatch,error);
    })
};