import { useDispatch, useSelector } from 'react-redux';
import { getCollapsed } from '../../store/menu/selectors';
import { setCollapse } from '../../store/menu/actions';
import { ProSidebar, Menu, MenuItem, SidebarHeader, SidebarContent } from 'react-pro-sidebar';
import logoa from '../../img/logoa.png';
import logoSetLearnScape from "../../img/Logo-SET-Learn-Scape.png"
import 'react-pro-sidebar/dist/css/styles.css';
import '../../Menu.scss';
import MenuIcon from '@material-ui/icons/Menu';
import AppsIcon from '@material-ui/icons/Apps';
import FolderSharedIcon from '@material-ui/icons/FolderShared';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useHistory } from 'react-router-dom';
import { getUsername } from '../../store/auth/selectors';
import { useEffect } from 'react';

const MainMenu = (prop: any) => {
  const collapsed = useSelector(getCollapsed);
  const username = useSelector(getUsername);
  const history = useHistory();
  const dispatch = useDispatch();

  const isClass = () => {
    return (window.location.pathname === "/trainer/search/class") || (window.location.pathname.includes("/trainer/class"));
  };
  const isTrainer = () => {
    return (window.location.pathname === "/trainer/search/trainer") || (window.location.pathname.includes("/trainer/trainer"));
  };
  const handleLogout = () => {
    history.push("/student/logout");
  }
  return (
    <>
      <ProSidebar collapsed={collapsed} style={{ position: "fixed", borderRadius: "20px" }}>
        <SidebarHeader style={{ backgroundColor: "#525252" }}>
          <br />
          <div className='mb-2' style={{ textAlign: "center" }} onClick={(e) => { dispatch(setCollapse(!collapsed)) }}>
            {collapsed && <>
              <div className='mt-2'></div>
              <img src={logoa} alt="Logo" height="30px" />
            </>}
            {!collapsed && <img src={logoSetLearnScape} alt="Logo" height="90px" />}
            <div className='text-white' style={{ visibility: (collapsed) ? "hidden" : "visible", height: (collapsed) ? "0px" : "",fontSize:16 }}>{username} <span style={{ fontSize: 16, color: "white" }}>(Trainer)</span></div>
            
          </div>
        </SidebarHeader>
        <SidebarContent>
          <Menu iconShape="square">
            <MenuItem style={{ fontFamily: "DB Helvethaica Bold", borderRadius: (collapsed) ? "" : "10px", margin: (collapsed) ? "" : "15px", backgroundColor: (isClass()) ? "black" : "", color: (isClass()) ? "gray" : "black" }} onClick={e => { if (!isClass()) { history.push("/trainer/search/class") } }}>
              <div className='d-flex'>
                <div><AppsIcon /></div>&nbsp;
                {!collapsed && <div style={{ whiteSpace: 'pre-wrap' }}>ข้อมูลการเรียนการสอน</div>}
              </div>
            </MenuItem>
            <MenuItem style={{ fontFamily: "DB Helvethaica Bold", borderRadius: (collapsed) ? "" : "10px", margin: (collapsed) ? "" : "15px", backgroundColor: (isTrainer()) ? "black" : "", color: (isTrainer()) ? "gray" : "black" }}  >
              <div className='d-flex'>
                <div><FolderSharedIcon /></div>&nbsp;
                {!collapsed && <div style={{ whiteSpace: 'pre-wrap' }}>ข้อมูลวิทยากร</div>}

              </div>
            </MenuItem>
            <MenuItem style={{ fontFamily: "DB Helvethaica Bold", borderRadius: (collapsed) ? "" : "10px", margin: (collapsed) ? "" : "15px", color: "black" }} onClick={e => handleLogout()} >
              <div className='d-flex'>
                <div><ExitToAppIcon /></div>&nbsp;
                {!collapsed && <div style={{ whiteSpace: 'pre-wrap' }}>ออกจากระบบ</div>}

              </div>
            </MenuItem>
          </Menu>
        </SidebarContent>
        <div style={{ position: "absolute", top: "10px", right: "20px", cursor: "pointer" }} onClick={(e) => { dispatch(setCollapse(!collapsed)) }}><MenuIcon></MenuIcon></div>
      </ProSidebar>
      <div style={{ paddingLeft: (collapsed) ? "80px" : "300px" }} >
        {prop.children}
      </div>
    </>
  );
}

export default MainMenu;
