import { Dispatch } from 'redux';
import { ActionType, SearchActionType } from '../search/types';
import { PageStudentAPI } from '../../api/page-student-api';
import { dispatchError } from '../search/actions'; 

export const landing = (search: string,auth: boolean) => (dispatch: Dispatch<any>) => {
   dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    PageStudentAPI.landing(search,auth).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "landing",
            page: "student_landing",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("student_landing",dispatch,error);
    })
};
