import { useDispatch, useStore } from "react-redux";
import { CardBox, PButton, PButtonBack, PartnerContainer } from "../../style/partnerStylemain"
import PartnerHeader from "./PartnerHeader"
import { getSummaryStructure, requestOTP } from "../../store/publicPartner/action"
import { useEffect, useState } from "react";
import { PartnerKnowledgeSummaryStructure } from "../../model/request/partner-knowledge";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { NOT_FOUND_LABEL } from "../../api/label";
import { queryclear } from "../../store/search/actions";
import { useHistory, useParams } from "react-router-dom";
import { AlertContainer } from "react-custom-alert";
import { alert } from "react-custom-alert";
type Props = {}

const PartnerOtp = (props: Props) => {

    const dispatch = useDispatch();
    const [emailSubmitted, setEmailSubmitted] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [refCode, setRefCode] = useState<string | undefined>();
    const [email, setEmail] = useState<string | undefined>();
    const [otp, setOtp] = useState();
    const [timeLeft, setTimeLeft] = useState<number | undefined>();
    const [loading, setLoading] = useState(false);
    const store = useStore();
    const history = useHistory()
    let { userKey } = useParams<{ userKey: string }>();

    useEffect(() => {
        const unsubscribe = store.subscribe(() => {
            var res = store.getState().SearchReducer;
            if (res.isLoading) {
                setLoading(true);
            } else if (res.isLoaded) {
                setLoading(false);
                if (res.data != null) {
                    if (res.table === 'partner-otp') {
                        setRefCode(res.data.refCode);
                        setEmail(res.data.email);
                        setTimeLeft(res.data.timeLeftSecond);

                    } else if (res.table === 'summary-structure') {
                        sessionStorage.setItem("publicAccessCode", JSON.stringify(res.data.publicAccessCode))
                        sessionStorage.setItem("summaryState", "COMPLETED");
                        history.push(res.data.link)
                    }
                } else {
                    alert({ message: NOT_FOUND_LABEL, type: 'error' });
                }
            } else if (res.isNotFound) {
                alert({ message: NOT_FOUND_LABEL, type: 'error' });
                setLoading(false);
            } else if (res.isUpdated) {
                dispatch(queryclear);
            } else if (res.isFailed) {
                alert({ message: res.data.message, type: 'error' });
                setLoading(false);
            }
        });
        return unsubscribe;
    }, []);

    useEffect(() => {
        // exit early when we reach 0
        if (!timeLeft) return;

        // component re-renders
        const intervalId = setInterval(() => {
          setTimeLeft(timeLeft - 1);
        }, 1000);

        // clear interval on re-render to avoid memory leaks
        return () => clearInterval(intervalId);
      }, [timeLeft]);

    const handleReuqestOTP = () => {
        console.log(email)
        setEmailSubmitted(true);
        if (validateFormEmail()) {
            const body: PartnerKnowledgeSummaryStructure = {
                userKey: userKey,
                refCode: refCode,
                otp: otp,
                email: email
            }
            dispatch(requestOTP(userKey, body));
        }
    }

    const handleCancel = () => {
        window.close();
    }

    const handleSubmit = () => {
        setSubmitted(true);
        if (validateForm()) {
            const body: PartnerKnowledgeSummaryStructure = {
                userKey: userKey,
                refCode: refCode,
                otp: otp,
                email: email
            }
            dispatch(getSummaryStructure(body));
        }
    }

    const validateFormEmail = () => {
        let error = (email == null || email === "");
        return error ? false : true;
    }

    const validateForm = () => {
        let error = (email == null || email === "") 
                    || (refCode == null || refCode === "")
                    || (otp == null || otp === "");
        return error ? false : true;
    }

    const getRequireEmail = (value: string | undefined | any[]) => {
        return (emailSubmitted && (value == null || value === ""))
    }

    const getRequire = (value: string | undefined | any[]) => {
        return (submitted && (value == null || value === ""))
    }

    return (
        <div className='bg-light-gray partner-page' style={{ height: "100vh", fontFamily: "DB Helvethaica" }}>
            <div style={{ position: 'sticky', top: 0 }}>
            </div>
            <div className="student-wrapper-header-inner" style={{ background: "#38383D" }}>
                <PartnerHeader />
            </div>
            <PartnerContainer className='mt-5'>
                <CardBox>
                   
                    {!refCode && (<>
                        <div className='text-center mb-3' style={{fontSize:22,fontWeight:300}}>
                            <span>กรุณาระบุ e-Mail ของท่านที่ใช้ในปัจจุบัน โดยรหัส OTP จะส่งไปยัง e-Mail</span>
                            <br />
                            <span>เพื่อใช้ในการเปิดดูข้อมูลการแนะนำหลักสูตรและสื่อความรู้ที่ท่านสนใจ</span>
                        </div>
                        <form>
                            {/* <label className='col-form-label'>ระบุe-Mailของท่านที่ใช้ปัจจุบัน</label> */}
                            <input type="text" className={'form-control ' + (getRequireEmail(email) ? "is-invalid" : "")} id="input-text-email" value={otp} onChange={(e: any) => setEmail(e.target.value)}  />
                        </form>
                        <div className='d-flex justify-content-center mt-3'>
                            <div className='mr-2'><PButton onClick={handleReuqestOTP} id="button-submit">ขอรหัส OTP</PButton></div>
                            <div><PButtonBack onClick={handleCancel} id="button-back">ยกเลิก</PButtonBack></div>
                        </div>
                   </>)}
                   {refCode && (<>
                        <div className='text-left mb-3' style={{fontSize:22}}>
                            <span style={{fontWeight:300}}>กรุณากรอกรหัส OTP ที่ได้รับผ่านe-Mail<br />เพื่อใช้ในการเปิดดูข้อมูลการแนะนำหลักสูตรและสื่อความรู้ที่ท่านสนใจ <br/>รหัสถูกส่งไปที่e-Mail </span>
                            <b>{email}</b>
                        </div>
                        <form>
                            <label className='col-form-label'>Ref. Code: {refCode}</label>
                            <br />
                            <label className='col-form-label'>OTP</label>
                            <input type="text" className={'form-control ' + (getRequire(otp) ? "is-invalid" : "")} id="input-text-otp" value={otp} onChange={(e: any) => setOtp(e.target.value)}  />
                        </form>
                        <div className='text-left mt-1 mb-3'>
                            <AccessTimeIcon className='mr-1' style={{fontSize:15}}/> 
                            <span style={{fontSize:16,fontWeight:300}}>หากไม่ได้รหัส OTP ภายใน 5 นาที กรุณากดลิ้งขอรับรหัสใหม่ (รับรหัสใหม่ใน {timeLeft} วินาที)</span>
                        </div>

                        <div className='d-flex flex-column mt-3 align-items-center'>
                            <div className='mt-3'><PButton onClick={handleSubmit} id="button-submit" style={{padding:"6px 90px 6px 90px"}}>ยืนยัน</PButton></div>
                            {(timeLeft === undefined || timeLeft === 0) && (<>
                                <div className='mt-3'><a className='link-not-underline' onClick={handleReuqestOTP}>รับรหัสใหม่</a></div>
                            </>)}
                            {timeLeft !== 0 && (<>
                                <div className='mt-3 link-disabled'>รับรหัสใหม่</div>
                            </>)}
                        </div>
                        
                   </>)}
                </CardBox>
                <AlertContainer floatingTime={3000} />
            </PartnerContainer>
        </div>
    )
}

export default PartnerOtp