import { useEffect, useState } from 'react';
import { useDispatch, useStore } from 'react-redux';
import loadinglogo from '../../img/loading.gif';
import { useLocation, useHistory } from "react-router-dom";
import { getToken } from '../../store/auth/actions';

const GetToken = () => {
    const search = useLocation().search;
    const code = new URLSearchParams(search).get('code');
    const sessionState = new URLSearchParams(search).get('sessionState');
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState("")
    const dispatch = useDispatch();
    const store = useStore();
    const history = useHistory();

    useEffect(() => {
        const unsubscribe = store.subscribe(() => {
            let res = store.getState().AuthReducer;
            if (res.isLoading) {
                setError('');
                setLoading(true);
            } else if (res.isLoggedIn) {
                setError('');
                setLoading(false);
                history.push("/admin/search/class");
            } else if (res.isFailed) {
                //setError(res.msg);
                //setLoading(false);
                window.location.href="/admin/access-denied";
            }
          });

        dispatch(getToken(code!,sessionState!));
        return unsubscribe;
        }, 
    []);
    

    return <>
      { ((loading) && (error==="")) &&
        <div style={{ textAlign: "center",  display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        width: "50%" }}><img src={loadinglogo} width="30" height="30" className="d-inline-block align-top" alt="" /></div>
      }
      { (error!=="") &&
            <div style={{ color: 'red', textAlign: "center" }}>{error}</div>
      }
    </>;
}

export default GetToken;