import React, { useState } from 'react'
import { Button, Modal, ModalProps } from 'react-bootstrap'
import styled from 'styled-components'
import CloseIcon from '@material-ui/icons/Close';
import { useForm } from 'react-hook-form';
import loadinglogo from '../../img/loading.gif';

type SurveyPublicModalProps = ModalProps & {
    onOk: (value: any) => void;
    error: string | null
}
const CustomModal = styled(Modal)`
    .description{
        color: #5F6062;
        margin-top: 10px;
    }
    .button-close{
        position: absolute;
        top:-30px;
        right: -30px;
        color:white;
        &:hover{
            cursor: pointer;
            transform:scale(1.3,1.3);
            -webkit-transform:scale(1.3,1.3);
            -moz-transform:scale(1.3,1.3);
        }
    }
`
const SurveyPublicModal = (props: SurveyPublicModalProps) => {
    const { control, handleSubmit, formState: { errors }, reset, getValues, register } = useForm();
    const onSubmit = (data: any) => {
        const body: any = {
            code: data.code
        }
        reset();
        props.onOk(body)
        // dispatch(addProjectInfo(body));
    }
    const hideModal = () => {
        reset()
        props.onHide();
    }
    const [loading, setLoading] = useState(false);
    return (
        <CustomModal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
        >
            {/* <a className='button-close' onClick={props.onHide}><CloseIcon /></a> */}
            <form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Body>
                    <div style={{ padding: 50 }}>
                        <div className='row'>
                            <label className='col-sm-3 col-form-label text-right'>Code <span className="text-danger">*</span></label>
                            <div className="col-sm-9 col-form-label">
                                <input {...register("code", { required: true })} className={(errors.code) ? 'form-control is-invalid' : 'form-control'} type="text" name="code" id="code" />
                                {(props.error !== "") &&
                                    <div style={{ color: 'red' }}>{props.error}</div>
                                }
                            </div>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {/* <Button variant="secondary" onClick={hideModal}>
                        Cancel
                    </Button> */}
                    <Button type='submit' className="btn btn-primary">
                        <div>
                            {(loading) &&
                                <div style={{ textAlign: "center" }}><img src={loadinglogo} width="30" height="30" className="d-inline-block align-top" alt="" /></div>
                            }
                            {(!loading) &&
                                <>Submit</>
                            }
                        </div>
                    </Button>
                </Modal.Footer>
            </form>
        </CustomModal>
    )
}

export default SurveyPublicModal