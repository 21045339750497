import { useEffect, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Footer from '../admin/adminfooter';
import loadinglogo from '../../img/loading.gif';
import { SurveyType } from '../../api/define';
import { getsurvey, getSurveyPublic, submitsurvey } from '../../store/survey/actions';
import { AlertContainer, alert } from 'react-custom-alert';
import 'react-custom-alert/dist/index.css'; // import css file from root.
import { Util } from '../../api/util';
import parse from 'html-react-parser'
import { queryclear } from '../../store/search/actions';
import { getIsStudent, getUsername, getEmail, getIsLoggedIn } from '../../store/auth/selectors';
import SurveyPublicModal from './surveyPublicModal';

const Survey = () => {
    const isStudent = useSelector(getIsStudent);
    const studentEmail = useSelector(getEmail);
    const [init, setInit] = useState(0);
    const [loading, setLoading] = useState(false)
    const [updating, setUpdating] = useState(false)
    const [items, setItems] = useState<any>({});
    const [resultItems, setResultItems] = useState<any>({});
    const [error, setError] = useState('');
    const [thankYou, setThankYou] = useState('');
    const [preview, setPreview] = useState(true)
    const [success, setSuccess] = useState(false);
    const isLogin = useSelector(getIsLoggedIn);
    const [isShowModalCode, setIsShowModalCode] = useState(!isLogin);
    const [code, setCode] = useState<string>();
    const store = useStore();
    const dispatch = useDispatch();
    let { group_id } = useParams<{ group_id: string }>();
    let { class_id } = useParams<{ class_id: string }>();
    let { page } = useParams<{ page: string }>();
    let { class_course_id } = useParams<{ class_course_id: string }>();
    let { class_course_session_id } = useParams<{ class_course_session_id: string }>();

    useEffect(() => {
        const unsubscribe = store.subscribe(() => {
            var res = store.getState().SearchReducer;
            if (res.page !== "survey") return;
            if (res.isLoading) {
                setError('');
                setLoading(true);
            } else if (res.isLoaded) {
                setError('');
                setLoading(false);
                if (res.table === 'survey_public') {
                    if (code) sessionStorage.setItem('code', code);
                    setIsShowModalCode(false);
                    setPreview(false);
                }
                setItems(res.data);
                createResult(res.data.survey);
                setInit(1);
            } else if (res.isUpdated) {
                if (res.data.success === true) {
                    setThankYou(items.thankYou);
                    setSuccess(true);
                } else {
                    alert({ message: res.data.popup ?? 'Error', type: 'error' });
                    window.scroll(0, 0);
                }
                setResultItems(res.data);
                dispatch(queryclear());
            } else if (res.isFailed) {
                setError(res.data.message);
                setLoading(false);
            }
        });
        // dispatch(getsurvey(group_id, class_id, class_course_id, class_course_session_id));
        // if ((class_id !== undefined) || (class_course_id !== undefined) || (class_course_session_id !== undefined)) {
        //     setPreview(false);
        // } else {
        //     setSuccess(true);
        // }
        // window.scroll(0, 0);
        return unsubscribe;
    }, [])

    useEffect(() => {
        if (!isShowModalCode && !code) {
            dispatch(getsurvey(group_id, class_id, class_course_id, class_course_session_id));
            if (urlContainsPreview(window.location.href)) {
                setPreview(true);
                setSuccess(true);
            } else if ((class_id !== undefined) || (class_course_id !== undefined) || (class_course_session_id !== undefined)) {
                setPreview(false);
            } else {
                setSuccess(true);
            }
            window.scroll(0, 0);
        }
        // else {
        //     if (sessionStorage.getItem('code') != null && isShowModalCode) {
        //         fillCode({ code: sessionStorage.getItem('code')! })
        //     }
        // }
    }, [isShowModalCode])

    function urlContainsPreview(url: string): boolean {
        const regex = /preview/;
        return regex.test(url);
      }

    const handleClear = () => {
        setResultItems({});
    }

    const fillCode = (data: { code: string }) => {
        setCode(data.code);
        dispatch(getSurveyPublic(group_id, class_id, class_course_id, class_course_session_id, data.code))
    }

    const handleSubmit = () => {
        dispatch(queryclear());
        if (!isStudent) {
            var email = new URLSearchParams(window.location.search).get("email");
            resultItems.email = ((email === undefined) || (email === null)) ? "anonymous" : email;
            console.log("testing email: " + resultItems.email);
        } else {
            resultItems.email = studentEmail;
        }
        resultItems.beforeAfter = (page === "before");
        console.log(JSON.stringify(resultItems));
        dispatch(submitsurvey(group_id, class_id, class_course_id, class_course_session_id, resultItems, code));
    }

    const handleClose = () => {
        window.close();
    }

    const getName = () => {
        return items.name;
    }

    const setResult = (i: number, index: number, value: string|null) => {
        resultItems.items[i].result[index] = value;
    }

    const getError = (i: number) => {
        return resultItems.items[i].error;
    }

    const getResult = (i: number, index: number) => {
        if ((items.survey[i].surveyType === SurveyType.CHOICE) || (items.survey[i].surveyType === SurveyType.ASSISTANT)) {
            console.log(resultItems.items[i].result[0]);
            return (parseInt(resultItems.items[i].result[0]) === index);
        }
        return resultItems.items[i].result[index];
    }

    const getThankYouStyle = () => {
        var len = (items.thankYou === undefined) ? 0 : items.thankYou.length;
        return (len <= 55) ? "center" : "left";
    }
    const createResult = (survey: any[]) => {
        var items: any[] = [];
        for (var i = 0; i < survey.length; i++) {
            if (survey[i].surveyType === SurveyType.CHOICE) {
                items.push({
                    "surveyId": survey[i].surveyId,
                    "result": [""]
                })
            } else if ((survey[i].surveyType === SurveyType.DEGREE) || (survey[i].surveyType === SurveyType.TRAINER)) {
                var result: any[] = [];
                for (var j = 0; j < survey[i].item.length; j++) {
                    result.push("");
                }
                items.push({
                    "surveyId": survey[i].surveyId,
                    "result": result
                })
            } else if (survey[i].surveyType === SurveyType.ASSISTANT) {
                var result: any[] = [];
                for (var j = 0; j < survey[i].item.length; j++) {
                    result.push("");
                }
                items.push({
                    "surveyId": survey[i].surveyId,
                    "result": result
                })
            } else if (survey[i].surveyType === SurveyType.INPUT) {
                var result: any[] = [];
                for (var j = 0; j < survey[i].item.length; j++) {
                    result.push("");
                }
                items.push({
                    "surveyId": survey[i].surveyId,
                    "result": result
                })
            } else {
                items.push({
                    "surveyId": survey[i].surveyId,
                    "result": [""]
                })
            }
        }
        var res = { items: items };
        setResultItems(res);
    }

    const createSurvey = () => {
        var res: any[] = [];
        //main & logo
        res.push(<>
            <div style={{ textAlign: "left" }}>
                {Util.convertLink(items.description)}<br />
            </div><br />
        </>);
        for (var i = 0; i < items.survey.length; i++) {
            var item = items.survey[i];
            if ((item.surveyType === SurveyType.MULTIPLE) || (item.surveyType === SurveyType.ASSISTANT)) {
                var itemres: any[] = [];
                for (var j = 0; j < item.item.length; j++) {
                    var it = item.item[j];
                    itemres.push(
                        <>
                            <input type="checkbox" name={"checkbox_" + i} value="1" id={i + "_" + j} onChange={(e) => { setResult(parseInt(e.currentTarget.id.split("_")[0]), parseInt(e.currentTarget.id.split("_")[1]), e.currentTarget.checked ? "1" : null) }} disabled={success} />
                            &nbsp;{it.title}<br />
                        </>
                    )
                }
                res.push(<>
                    <div style={{ textAlign: "left" }}>
                        {(item.required) ? <span className="red">*</span> : <></>} <b>{i + 1}. {item.title}</b><br /><br />
                        <div style={{ paddingLeft: "50px" }}>
                            {itemres}
                        </div>
                    </div>
                    <div className={(getError(i) != null) ? "text-danger" : ""}><small>{getError(i)}</small></div>
                    <br />
                </>);
            } else if (item.surveyType === SurveyType.CHOICE) {
                var itemres: any[] = [];
                for (var j = 0; j < item.item.length; j++) {
                    var it = item.item[j];
                    itemres.push(
                        <>
                            <input type="radio" name={"choice_" + i} value="1" id={i + "_" + j} onChange={(e) => { setResult(parseInt(e.currentTarget.id.split("_")[0]), 0, e.currentTarget.id.split("_")[1]) }} disabled={success} />
                            &nbsp;{it.title}<br />
                        </>
                    )
                }
                res.push(<>
                    <div style={{ textAlign: "left" }}>
                        {(item.required) ? <span className="red">*</span> : <></>} <b>{i + 1}. {item.title}</b><br /><br />
                        <div style={{ paddingLeft: "50px" }}>
                            {itemres}
                        </div>
                    </div>
                    <div className={(getError(i) != null) ? "text-danger" : ""}><small>{getError(i)}</small></div>
                    <br />
                </>);
            } else if ((item.surveyType === SurveyType.DEGREE) || (item.surveyType === SurveyType.TRAINER) || (item.surveyType === SurveyType.COURSE) || (item.surveyType === SurveyType.ASSISTANT2)) {
                var itemres: any[] = [];
                for (var j = 0; j < item.item.length; j++) {
                    var it = item.item[j];
                    itemres.push(
                        <><tr className={(j % 2 === 0) ? "gray-button" : "light-gray-button"} style={{ textAlign: "center", justifyContent: "center" }}>
                            <td style={{ textAlign: "left" }}>{parse(it.title)}</td>
                            <td style={{ textAlign: "center", justifyContent: "center" }}><input style={{ height: "2em" }} name={"choice_" + i + "_" + j} type="radio" value="1" id={i + "_" + j} onChange={(e) => { setResult(parseInt(e.currentTarget.id.split("_")[0]), parseInt(e.currentTarget.id.split("_")[1]), "4") }} disabled={success} /></td>
                            <td style={{ textAlign: "center", justifyContent: "center" }}><input style={{ height: "2em" }} name={"choice_" + i + "_" + j} type="radio" value="1" id={i + "_" + j} onChange={(e) => { setResult(parseInt(e.currentTarget.id.split("_")[0]), parseInt(e.currentTarget.id.split("_")[1]), "3") }} disabled={success} /></td>
                            <td style={{ textAlign: "center", justifyContent: "center" }}><input style={{ height: "2em" }} name={"choice_" + i + "_" + j} type="radio" value="1" id={i + "_" + j} onChange={(e) => { setResult(parseInt(e.currentTarget.id.split("_")[0]), parseInt(e.currentTarget.id.split("_")[1]), "2") }} disabled={success} /></td>
                            <td style={{ textAlign: "center", justifyContent: "center" }}><input style={{ height: "2em" }} name={"choice_" + i + "_" + j} type="radio" value="1" id={i + "_" + j} onChange={(e) => { setResult(parseInt(e.currentTarget.id.split("_")[0]), parseInt(e.currentTarget.id.split("_")[1]), "1") }} disabled={success} /></td>
                            <td style={{ textAlign: "center", justifyContent: "center" }}><input style={{ height: "2em" }} name={"choice_" + i + "_" + j} type="radio" value="1" id={i + "_" + j} onChange={(e) => { setResult(parseInt(e.currentTarget.id.split("_")[0]), parseInt(e.currentTarget.id.split("_")[1]), "0") }} disabled={success} /></td>
                        </tr></>
                    )
                }
                res.push(<>
                    <div style={{ textAlign: "left" }}>
                        {(item.required) ? <span className="red">*</span> : <></>} <b>{i + 1}. {parse(item.title)}</b><br /><br />
                        <div style={{ paddingLeft: "50px" }}>
                            <table style={{ width: "100%" }}>
                                <tr style={{ textAlign: "center" }}><th></th><th style={{ width: "150px" }}>มากที่สุด</th><th style={{ width: "150px" }}>มาก</th><th style={{ width: "150px" }}>ปานกลาง</th><th style={{ width: "150px" }}>น้อย</th><th style={{ width: "150px" }}>น้อยที่สุด</th></tr>
                                {itemres}
                            </table>
                        </div>
                    </div>
                    <div className={(getError(i) != null) ? "text-danger" : ""}><small>{getError(i)}</small></div>
                    <br />
                </>);
            } else if ((item.surveyType === SurveyType.INPUT) || (item.surveyType === SurveyType.NUMBER)) {
                var itemres: any[] = [];
                for (var j = 0; j < item.item.length; j++) {
                    var it = item.item[j];
                    if (item.surveyType === SurveyType.INPUT) {
                        itemres.push(
                            <>{it.title}<br />
                                <div className="row">
                                    <div className={"col-12"}>
                                        <input className="form-control" type="text" id={i + "_" + j} onChange={(e) => { setResult(parseInt(e.currentTarget.id.split("_")[0]), parseInt(e.currentTarget.id.split("_")[1]), e.target.value) }} disabled={success} />
                                    </div>
                                </div>
                                <br /></>
                        )
                    } else {
                        itemres.push(
                            <>
                                <div className="row">
                                    <table>
                                        <tr>
                                            <td>
                                                {it.title}&nbsp;
                                            </td>
                                            <td>
                                                <input className="form-control" type="text" id={i + "_" + j} onChange={(e) => { setResult(parseInt(e.currentTarget.id.split("_")[0]), parseInt(e.currentTarget.id.split("_")[1]), e.target.value) }} disabled={success} 
                                                    onKeyPress={(event) => {if (!/^\-?\d*\.?\d*$/.test(event.key)) {event.preventDefault();}}}
                                                />
                                            </td>
                                            <td>
                                                &nbsp;{it.unit}
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <br /></>
                        )
                    }
                }
                res.push(<>
                    <div style={{ textAlign: "left" }}>
                        {(item.required) ? <span className="red">*</span> : <></>} <b>{i + 1}. {item.title}</b><br /><br />
                        <div style={{ paddingLeft: "50px" }}>
                            {itemres}
                        </div>
                    </div>
                    <div className={(getError(i) != null) ? "text-danger" : ""}><small>{getError(i)}</small></div>
                    <br />
                </>);
            } else if (item.surveyType === SurveyType.RANKING) {
                var itemres: any[] = [];
                for (var j = 0; j < item.item.length; j++) {
                    var it = item.item[j];
                    itemres.push(
                        <>
                            <div className="row">
                                <div className="col-2">
                                    <input className="form-control" type="text" id={i + "_" + j} onChange={(e) => { setResult(parseInt(e.currentTarget.id.split("_")[0]), parseInt(e.currentTarget.id.split("_")[1]), e.target.value) }} disabled={success} />
                                </div>
                                <div className="col-10">
                                    {it.title}
                                </div>
                            </div>
                            <br /></>
                    )
                }
                res.push(<>
                    <div style={{ textAlign: "left" }}>
                        {(item.required) ? <span className="red">*</span> : <></>} <b>{i + 1}. {item.title}</b><br /><br />
                        <div style={{ paddingLeft: "50px" }}>
                            {itemres}
                        </div>
                    </div>
                    <div className={(getError(i) != null) ? "text-danger" : ""}><small>{getError(i)}</small></div>
                    <br />
                </>);
            } else {
                res.push(<>
                    <div style={{ textAlign: "left" }}>
                        {(item.required) ? <span className="red">*</span> : <></>} <b>{i + 1}. {item.title}</b><br /><br />
                        <div style={{ paddingLeft: "50px" }}>
                            <textarea className="form-control" maxLength={250} cols={50} id={i.toString()} onChange={(e) => { setResult(parseInt(e.currentTarget.id.split("_")[0]), 0, e.target.value) }} disabled={success}>{getResult(i, 0)}</textarea>
                        </div>
                    </div>
                    <div className={(getError(i) != null) ? "text-danger" : ""}><small>{getError(i)}</small></div>
                    <br />
                </>);
            }
        }
        return res;
    }

    return (<>
        <div style={{ width: "100%" }}>
            <div className="survey-wrapper">
                <br />
                {(init === 1) &&
                    <>
                        <div className="yellow-button" style={{ textAlign: "left", borderTopLeftRadius: "30px", borderTopRightRadius: "30px", height: "70px", paddingTop: "15px", paddingLeft: "15px" }}>
                            <h4 className="header">{getName()}</h4>
                        </div>
                        <div style={{ background: "white", padding: "20px", borderRadius: "5px" }}>
                            <div className="row">
                                <div className="col-2" style={{ textAlign: "left" }}>
                                    {(items.logoUrl !== undefined && items.logoUrl) &&
                                        <img src={items.logoUrl} className="survey-logo"  onError={(i:any) => i.target.style.display='none'}></img>

                                    }
                                </div>
                                <div className="col-10" style={{ textAlign: "center" }}>
                                    {(items.headerUrl !== undefined && items.headerUrl) &&
                                        <img src={items.headerUrl} className="survey-header"  onError={(i:any) => i.target.style.display='none'}></img>
                                    }
                                </div>
                            </div>
                            <br />
                            {
                                createSurvey()
                            }
                        </div>
                    </>
                }
                <br />
                {(((init < 1) || (loading) || (updating)) && (error === "")) &&
                    <div style={{ textAlign: "center" }}><img src={loadinglogo} width="30" height="30" className="d-inline-block align-top" alt="" /></div>
                }
                {(error !== "") &&
                    <div style={{ color: 'red', textAlign: "center" }}>{error}</div>
                }
                {(init === 1) && (!loading) && (!success) && (!updating) && (!preview) &&
                    <div style={{ justifyContent: "center", textAlign: "center" }}>
                        <button type="button" className="btn btn-outline-primary" style={{ width: "120px" }}
                            onClick={() => handleClose()} >
                            Close
                        </button>&nbsp;
                        <button type="button" className="btn btn-success" style={{ width: "120px" }}
                            onClick={() => handleSubmit()} >
                            Submit
                        </button>
                    </div>
                }
                {((success) || (preview)) &&
                    <div style={{ justifyContent: "center", textAlign: "center" }}>
                        <button type="button" className="btn btn-outline-primary" style={{ width: "120px" }}
                            onClick={() => {
                                if (preview) setThankYou(items.thankYou)
                                else handleClose();
                            }} >
                            Close
                        </button>
                    </div>
                }
                <br />
            </div>
        </div>

        <Modal show={thankYou !== ""} onHide={handleClose}>
            <Modal.Body style={{ textAlign: getThankYouStyle() }}>
                {items.thankYou}
            </Modal.Body>
        </Modal>

        <AlertContainer floatingTime={3000} />
        <SurveyPublicModal backdrop="static" show={isShowModalCode} onOk={fillCode} error={error} />
    </>);
}

export default Survey;
