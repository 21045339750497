import React, { useEffect } from 'react'
import { getIsTrainer } from '../../store/auth/selectors';
import { useSelector } from 'react-redux';
import { Util } from '../../api/util';

type Props = {
    resultItems: any;
    success: boolean;
    setResultItems: (v:any) => any
}

const TrainerComment = (props: Props) => {

    const setAnalysisSummary = (v:string) => {
        var newResultItems = { ...props.resultItems };
        newResultItems.analysisSummary = v;
        props.setResultItems(newResultItems);
    }
    const setRecommendedSolution = (v:string) => {
        var newResultItems = { ...props.resultItems };
        newResultItems.recommendedSolution = v;
        props.setResultItems(newResultItems);
    }
    const setOtherSuggestion = (v:string) => {
        var newResultItems = { ...props.resultItems };
        newResultItems.otherSuggestion = v;
        props.setResultItems(newResultItems);
    }
    const setStudentName = (v:string) => {
        var newResultItems = { ...props.resultItems };
        newResultItems.studentName = v;
        props.setResultItems(newResultItems);
    }
    const setTrainerName = (v:string) => {
        var newResultItems = { ...props.resultItems };
        newResultItems.trainerName = v;
        props.setResultItems(newResultItems);
    }
    return (
        <div className='p-3'>
            <div className="row">
                <div className="col-md-2 text-md-right">
                    ชื่อพี่เลี้ยงการเงิน: 
                </div>
                <div className="col-md-10">
                    {props.resultItems.studentName || '-'}
                    <br />
                </div>
            </div>
            <div className="row">
                <div className="col-md-2 text-md-right">
                    ชื่อผู้รับคำแนะนำ:
                </div>
                <div className="col-md-10">
                    {props.resultItems.name || '-'}
                    <br />
                </div>
            </div>
            <br />
            <div>สรุปผลการวิเคราะห์งบการเงินส่วนบุคคล</div>
            <hr />
            <div className="row">
                <div className="col-md-12">
                    <textarea className="form-control" value={props.resultItems.analysisSummary} onChange={e => setAnalysisSummary(e.currentTarget.value)} disabled={props.success} rows={4} />
                    <br />
                </div>
            </div>
            <div >สรุปคำแนะนำแนวทางการแก้ไขด้านการเงิน</div>
            <hr />
            <div className="row">
                <div className="col-md-12">
                    <textarea className="form-control" value={props.resultItems.recommendedSolution} onChange={(e=>setRecommendedSolution(e.currentTarget.value))} disabled={props.success} rows={4} />
                    <br />
                </div>
            </div>
            <div >คำแนะนำอื่นๆ (หากมี)</div>
            <hr />
            <div className="row">
                <div className="col-md-12">
                    <textarea className="form-control" value={props.resultItems.otherSuggestion} onChange={(e=>setOtherSuggestion(e.currentTarget.value))} disabled={props.success} rows={4} />
                    <br />
                </div>
            </div>
            <div className="row">
                <div className="col-md-3 text-md-right">
                    ชื่อวิทยากรผู้ให้ความเห็น: 
                </div>
                <div className="col-md-9">
                    {props.resultItems.trainerName || '-'}
                    <br />
                </div>
            </div>
            <div className="row">
                <div className="col-md-3 text-md-right">
                    ข้อมูล ณ วันที่:
                </div>
                <div className="col-md-9">
                    {props.resultItems?.informationDate?Util.datetimetostr(new Date(props.resultItems.informationDate)):'-'}
                    <br />
                </div>
            </div>
        </div>
    )
}

export default TrainerComment