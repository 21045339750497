import React, { useState } from 'react'
import ExpandMore from '@material-ui/icons/ExpandMore'
import ExpandLess from '@material-ui/icons/ExpandLess'
import CheckIcon from '@material-ui/icons/Check';
type Props = {}

const ManagePartnerSuggestion = (props: Props) => {
    const [expand, setExpand] = useState(true)
    return (
        <div>
            <div className="gray-button text-left" style={{ padding: "15px", borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}>
                {((expand === undefined) || (expand === false)) &&
                    <div onClick={(e) => setExpand(true)}><ExpandMore className="pointer mr-2" ></ExpandMore>คำอธิบายการใช้งาน</div>
                }
                {(expand === true) &&
                    <div onClick={(e) => setExpand(false)}><ExpandLess className="pointer mr-2" ></ExpandLess>คำอธิบายการใช้งาน</div>
                }
            </div>
            {expand && (
                <div className="bg-white text-left" style={{ padding: "15px", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px" }}>
                    <div className='row'>
                        <div className="col-12">
                            ขั้นตอนการใช้งาน ดังนี้
                        </div>
                        <div className="col-12 pl-4">
                            1. ทำเครื่องหมาย <CheckIcon fontSize='small' /> หน้าหัวข้อที่ต้องการส่งให้องค์กรพันธมิตรกรอกข้อมูล โดยระบบจะแสดงหัวข้อตามที่กำหนดไว้ (Config) ให้ท่านเลือกเปลี่ยนสถานะเป็น <u>ส่งฟอร์มให้กรอกข้อมูล</u> และให้ท่านเลือกผู้ประสานงานที่ต้องการส่งข้อมูล

                        </div>
                        <div className="col-12 pl-4">
                            2. กรณียังไม่สรุปข้อมูลองค์กรพันธมิตร ให้ท่านเลือกเปลี่ยนสถานะเป็น <u>อยู่ระหว่างดำเนินการ</u>
                        </div>
                        <div className='col-12 pl-4'>
                            3. กรณีองค์กรพันธมิตรไม่สนใจเข้าร่วมโครงการ ให้ท่านเลือกเปลี่ยนสถานะเป็น <u>องค์กรยังไม่สนใจ</u>
                        </div>
                        <div className='col-12 pl-4'>
                            4. กรณีองค์กรพันธมิตรกรอกข้อมูลครบถ้วน และต้องการเก็บลงในฐานข้อมูล ให้ท่านเลือกเปลี่ยนสถานะเป็น <u>บันทึกลงฐานข้อมูล</u>
                        </div>
                        <div className='col-12 pl-4'>
                            5. เมื่อดำเนินการครบตามขั้นตอน ให้ท่านกดปุ่ม <div style={{ width: "100px",cursor:'text' }} className="btn btn-success btn-sm"
                            > Save</div>
                        </div>
                    </div>
                </div>
            )}

        </div>
    )
}

export default ManagePartnerSuggestion