import { Dispatch } from 'redux';
import { ActionType, SearchActionType } from '../search/types';
import { AssignmentAPI } from '../../api/assignment-api';
import { ClassAPI } from '../../api/class-api';
import { dispatchError } from '../search/actions'; 

export const listcourse= (classId: number) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    AssignmentAPI.listCourse(classId).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "list_course",
            page: "edit_assignment",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("edit_assignment",dispatch,error);
    })
};

export const listassignment= (classId: number,param: string,page: number,limit: number) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    AssignmentAPI.listAssignment(classId,param,page,limit).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "list_assignment",
            page: "edit_assignment",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("edit_assignment",dispatch,error);
    })
};

export const getassignment= (classId: number,classStudentId: number) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    AssignmentAPI.get(classId,classStudentId).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "list_assignment",
            page: "edit_assignment",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("edit_assignment",dispatch,error);
    })
};

export const saveassignment = (item: any) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_UPDATING
    });
    AssignmentAPI.save(item).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_UPDATED,
            payload: response.data,
            table: "list_assignment",
            page: "edit_assignment",
            action: ActionType.UPDATE
        });
    }, function (error) {
        if ((error.response!==undefined) && (error.response.status===400)) {
            dispatch({
                type: SearchActionType.QUERY_ERROR,
                page: "edit_assignment",
                payload: error.response.data.additional
            });
        } else 
            dispatchError("edit_assignment",dispatch,error);
    })
};

    export const getclass= (classId: number) => (dispatch: Dispatch<any>) => {
        dispatch({
            type: SearchActionType.QUERY_LOADING
        });
        ClassAPI.getClass(classId.toString()).then(function (response) {
            dispatch({
                type: SearchActionType.QUERY_LOADED,
                payload: response.data,
                table: "class",
                page: "edit_assignment",
                action: ActionType.QUERY
            });
        }, function (error) {
        dispatchError("edit_assignment",dispatch,error);
        })
    };


