import { apiClient } from './axios-intercept';
import { DEFAULT_SERVER } from './conf';
import { CourseType } from './define';

const COURSE = "/api/v1/course";

const LIST_COURSE_URL = DEFAULT_SERVER + COURSE;

export class CourseAPI {

    public static list = async (name: string | null,courseType: CourseType| null,classId:number | null): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=LIST_COURSE_URL+"?"+((courseType!=null)?"course_type="+courseType:"")+((name!=null)?"&name="+encodeURIComponent(name):"")+((classId!=null?"class_id="+encodeURIComponent(classId):""));
            apiClient.get(s,
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                console.log("list course response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("list course error:", err);
                return reject(err); 
            })
        })
    }

    public static listByClassId = async (classId:number|null): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=LIST_COURSE_URL+'/list/'+classId
            apiClient.get(s,
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                return resolve(response);
            }).catch(err => {
                console.log("list course by classId error:", err);
                return reject(err); 
            })
        })
    }
}