const Footer = (prop: any) => {

    const empty:any={textAlign:"center", fontSize:"12pt", background: '#EEEEEE',width:"100%",maxWidth:"1300px",position:"absolute",bottom:0};
    const notEmpty:any={textAlign:"center", fontSize:"12pt", background: '#EEEEEE',minWidth:"1200px"};
    return (
    <footer style={(prop.empty)?empty:notEmpty}>
        <br/>
        ตลาดหลักทรัพย์แห่งประเทศไทย | สงวนลิขสิทธิ์<br/>
        เนื้อหาทั้งหมดบนเว็บไซด์นี้ มีขึ้นเพื่อวัตถุประสงค์ในการให้ข้อมูลและเพื่อการศึกษาเท่านั้น ตลาดหลักทรัพย์ฯ มิได้ให้การรับรองและขอปฎิเสธต่อความรับผิดชอบใดๆ ในเว็บไซด์นี้<br/>
        <span className="yellow link-hover" onClick={()=>{window.open('https://www.set.or.th/th/terms-and-conditions','_blank')}}>ข้อตกลงและเงื่อนไขการใช้งานเว็บไซด์</span> |&nbsp;
        <span className="yellow link-hover" onClick={()=>{window.open('https://www.set.or.th/th/privacy-notice','_blank')}}>การคุ้มครองข้อมูลส่วนบุคคล</span> |&nbsp;
        <span className="yellow link-hover" onClick={()=>{window.open('https://www.set.or.th/th/cookies-policy','_blank')}}>นโยบายการใช้คุกกี้</span> |&nbsp;
        <span className="yellow link-hover" onClick={()=>{window.open('https://www.set.or.th/th/thirdparty-terms','_blank')}}>เงื่อนไขการใช้ข้อมูลของผู้ให้บริการรายอื่น</span>
    </footer>
    )
}

export default Footer;