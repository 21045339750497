import { StudentSessionResponse } from './student-session.model';

export interface StudentStepResponse{
    activityType: string;
    bannerUrl: string;
    certificateUrl: string;
    classCourseId: number;
    collapse: boolean;
    consent: boolean;
    courseName: string;
    courseType: string;
    document: any;
    eligible: boolean;
    fromDate: string;
    session: StudentSessionResponse[];
    stepResult: StepResultType;
    surveyGroupId: number;
    surveyName: string;
    toDate: string;
}

enum StepResultType {
    DONE = "DONE",
    PROGRESS = "PROGRESS",
    NONE = "NONE",
    FAIL = "FAIL"
}
export {StepResultType}
// export default StepResultType;