import React from 'react'
import { Button, Modal, ModalProps } from 'react-bootstrap'

type Props = ModalProps &
{
    title: string;
    name?: string
    okButton?: string;
    cancelButton?: string;
    onOk: (value: any) => void
}
const ConfirmModal = (props: Props) => {
    return (
        <Modal
            {...props}
            dialogClassName="modal-30w"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    {props.title}
                    {props.name && (<>
                        <br />
                        <h6>{props.name}</h6>
                    </>)}
                </Modal.Title>
            </Modal.Header>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.onHide}>
                    {props.cancelButton || 'ยกเลิก'}
                </Button>
                <Button variant="danger" onClick={() => { props.onOk(true) }}>
                    {props.okButton || 'ยืนยัน'}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ConfirmModal