import { MultiSelect } from "react-multi-select-component";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ClearIcon from '@material-ui/icons/Clear';

export const MultiSelectComponent = ((props: any) => {
    return (<div style={props.style}>
      <MultiSelect
                options={props.options}
                value={props.value}
                onChange={props.onChange}
                labelledBy=""
                hasSelectAll={false}
                disableSearch={false}
                ClearIcon={()=> 
                    <ClearIcon style={{color:"lightgray"}}/>
                }
                ArrowRenderer={()=> 
                      <ExpandMoreIcon style={{color:"lightgray"}}/>
                   }
                overrideStrings={{
                    "allItemsAreSelected": "All items are selected.",
                    "clearSearch": "Clear Search",
                    "clearSelected": "Clear Selected",
                    "noOptions": "No options",
                    "search": "Search",
                    "selectAll": "Select All",
                    "selectAllFiltered": "Select All (Filtered)",
                    "selectSomeItems": " ",
                    "create": "Create",
                  }}
            />
    </div>
        );
});