import { SurveyAPI } from './../../api/survey-api';
import { dispatchError } from "../search/actions";
import { ActionType, SearchActionType } from "../search/types";
import { Dispatch } from 'redux';

export const getSummarySurvey = (classId: string | undefined, page: number, size: number, sort: string | undefined) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING,
        page: "edit_summary_survey"
    });
    SurveyAPI.getSummarySurvey(classId, page, size, sort).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "get_summary_survey",
            page: "edit_summary_survey",
            action: ActionType.QUERY
        });
    }, function (error) {
        dispatchError("edit_summary_survey", dispatch, error);
    })
};