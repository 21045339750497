export enum CourseType {
    ONLINE = "ON_LINE",
    ONSITE = "ON_SITE",
    ELEARNING = "E_LEARNING",
    OTHER = "OTHER",
    HYBRID = "HYBRID"
}

export const getCourseType = (t: CourseType) => {
    if (t===CourseType.ONLINE) return "Online classroom";
    if (t===CourseType.ONSITE) return "On-Site";
    if (t===CourseType.ELEARNING) return "E-Learning";
    if (t===CourseType.OTHER) return "Other";
    if (t===CourseType.HYBRID) return "Hybrid";
    return t;
}

export const createCourseType =()=>{
    return (<><option value={CourseType.ONLINE}>Online classroom</option>
    <option value={CourseType.ONSITE}>On-site</option>
    <option value={CourseType.ELEARNING}>e-Learning</option>
    <option value={CourseType.HYBRID}>Hybrid</option>
    <option value={CourseType.OTHER}>Other</option></>);
}

export enum MediaType {
    INFOGRAPHIC = "INFOGRAPHIC",
    VIDEO = "VIDEO",
    ARTICLE = "ARTICLE",
    ELEARNING = "E_LEARNING",
    EBOOK = "E_BOOK",
    INHOUSE = "IN_HOUSE",
    CALCULATOR = "CALCULATOR",
    PUBLISHED_DOC = "PUBLISHED_DOC"
}

export const createMediaType =()=>{
    var res:BasicResponse[]=[];   
    res.push({label:"Infographic",value:MediaType.INFOGRAPHIC});
    res.push({label:"คลิปความรู้",value:MediaType.VIDEO});
    res.push({label:"บทความ",value:MediaType.ARTICLE});
    res.push({label:"e-Learning",value:MediaType.ELEARNING});
    res.push({label:"e-Book",value:MediaType.EBOOK});
    res.push({label:"เอกสารเผยแพร่(สิ่งพิมพ์)",value: MediaType.PUBLISHED_DOC})
    res.push({label:"In-House Training",value:MediaType.INHOUSE});
    res.push({label:"โปรแกรมคำนวณ",value:MediaType.CALCULATOR});
    return res.sort(function(a, b){
        if(a.label.toLocaleLowerCase() < b.label.toLocaleLowerCase()) { return -1; }
        if(a.label.toLocaleLowerCase() > b.label.toLocaleLowerCase()) { return 1; }
        return 0;
    })
}

export const createSaveStateNoNew = () => {
    var res:any[]=[];   
    res.push({label:"ไม่บันทึกข้อมูลเข้าระบบ",value:"NOTSAVE"});
    res.push({label:"บันทึกแทนข้อมูลเดิม",value:"SAVE"});
    return res
}

export const createSaveStateHaveNew = () => {
    var res:any[]=[];   
    res.push({label:"ไม่บันทึกข้อมูลเข้าระบบ",value:"NOTSAVE"});
    res.push({label:"บันทึกแทนข้อมูลเดิม",value:"SAVE"});
    res.push({label:"บันทึกข้อมูลใหม่",value:"NEW"});
    return res
}



export const createLogRequestStatusType =()=>{
    var res:any[]=[];   
    res.push({label:"รายการใหม่",value:PartnerRegistrationStatus.NEW});
    res.push({label:"ส่งฟอร์มให้กรอกข้อมูล",value:PartnerRegistrationStatus.PENDING_CUSTOMER});
    res.push({label:"อยู่ระหว่างดำเนินการ",value:PartnerRegistrationStatus.PROCESS});
    res.push({label:"องค์กรยังไม่สนใจ",value:PartnerRegistrationStatus.CANCEL});
    res.push({label:"บันทึกลงฐานข้อมูล",value:PartnerRegistrationStatus.COMPLETE});
    return res
}

export enum ELearningType {
    SUPER_USER = "SUPER_USER",
    SSO = "SSO",
    PUBLIC = "PUBLIC",
}

export const createELearningType =()=>{
    return (<><option value={ELearningType.SUPER_USER}>Super User Account</option>
    <option value={ELearningType.SSO}>Single Sign On</option>
    <option value={ELearningType.PUBLIC}>Public</option></>);
}

export enum OnlineType {
    MS_TEAM = "MS_TEAM",
    ZOOM = "ZOOM",
    WEB_EX = "WEB_EX",
    OTHER = "OTHER"
}

export const createOnlineType =()=>{
    return (<><option value={OnlineType.MS_TEAM}>MS Team</option>
    <option value={OnlineType.ZOOM}>Zoom</option>
    <option value={OnlineType.WEB_EX}>Web EX</option>
    <option value={OnlineType.OTHER}>Other</option></>);
}

export enum ActivityType {
    ARTICLE = "ARTICLE",
    INVESTORY = "INVESTORY",
    BUDGET_PLANNER = "BUDGET_PLANNER",
    COMPANY_VISIT = "COMPANY_VISIT",
    OTHER = "OTHER"
}

export const createActivityType =()=>{
    return (<><option value={ActivityType.ARTICLE}>บทความ</option>
    <option value={ActivityType.INVESTORY}>เยี่ยมชม INVESTORY</option>
    <option value={ActivityType.BUDGET_PLANNER}>รับคำปรึกษากับนักวางแผนการเงิน</option>
    <option value={ActivityType.COMPANY_VISIT}>e-Learning</option>
    <option value={ActivityType.OTHER}>Other</option></>);
}

export const getActivityType = (a:any) => {
    if (a===ActivityType.ARTICLE) return "บทความ";
    else if (a===ActivityType.INVESTORY) return "เยี่ยมชม INVESTORY";
    else if (a===ActivityType.BUDGET_PLANNER) return "รับคำปรึกษากับนักวางแผนการเงิน";
    else if (a===ActivityType.COMPANY_VISIT) return "e-Learning";
    return "อื่นๆ";
}

export const getOnlineType = (a:any) => {
    if (a===OnlineType.MS_TEAM) return "MS Team";
    else if (a===OnlineType.ZOOM) return "Zoom";
    else if (a===OnlineType.WEB_EX) return "Web EX";
    return "อื่นๆ";
}

export enum DocumentType {
    YOUTUBE = "YOUTUBE",
    FILE = "FILE",
    URL = "URL"
}

export enum TrainerType {
    TRAINER = "TRAINER",
    ASSISTANT = "ASSISTANT"
}

export enum StudentType {
    LIST = "LIST",
    NUMBER = "NUMBER"
}

export enum SurveyType {
    CHOICE ="CHOICE",           //อายุ
    MULTIPLE = "MULTIPLE",      //เลือกหลายอันได้
    DEGREE = "DEGREE",          //มาก-น้อย
    RANKING = "RANKING",      //เรียงลำดับ
    NUMBER = "NUMBER",          //ใส่่ตัวเลข
    INPUT = "INPUT",            //ใส่่ข้อความ
    TEXT = "TEXT",              //text area
    ASSISTANT ="ASSISTANT",
    TRAINER = "TRAINER",
    COURSE = "COURSE",
    ASSISTANT2 = "ASSISTANT2"
}

export enum QuestionType {
    CUSTOM ="CUSTOM",   
    BASIC = "BASIC",
}

export enum BasicSurveyType {
    COURSE = "COURSE",
    TRAINER = "TRAINER",
    LEARNING_IMPRESSION = "LEARNING_IMPRESSION",
    EMP_IMPRESSION = "EMP_IMPRESSION"
}

export const createSurveyType =()=>{
    return (<>
    <option value={undefined}>-</option>
    <option value={SurveyType.CHOICE}>Single Answer</option>
    <option value={SurveyType.MULTIPLE}>Multiple Answer</option>
    <option value={SurveyType.DEGREE}>Rating Scale</option>
    <option value={SurveyType.NUMBER}>Numeric Summary</option>
    <option value={SurveyType.RANKING}>Ranking</option>
    <option value={SurveyType.TEXT}>Free Text</option>
    </>);
}

export const basicSurveyDropdown = () => {
    return [{label:'ประเมินความพึงพอใจหลักสูตร',value:BasicSurveyType.COURSE},
    {label:'ประเมินวิทยากร (รวมถึงวิทยากรผู้ช่วย ถ้ามี)',value: BasicSurveyType.TRAINER},
    {label:'ประเมินรูปแบบการเรียนรู้และระยะเวลา',value: BasicSurveyType.LEARNING_IMPRESSION},
    {label:'ประเมินเจ้าหน้าที่จัดอบรม',value: BasicSurveyType.EMP_IMPRESSION},
]
}

export enum SurveyPermissionType {
    PUBLIC="PUBLIC",
    PRIVATE="PRIVATE",
    PROJECT="PROJECT",
    ORGANIZER="ORGANIZER"
}

export enum ClassStepType {
    STEP="STEP",
    NO_STEP="NO_STEP"
}
export enum TrainingType {
    PARTNER="PARTNER",
    PUBLIC="PUBLIC"
}

export const createDocumentDescriptionSearchFilter = () =>{
    var res:any[]=[];   
    res.push({label:"E-toolkit",value:"ETOOLKIT"});
    res.push({label:"คู่มือ",value:"คู่มือ"});
    res.push({label:"งานที่ได้รับมอบหมาย",value:"ASSIGNMENT"});
    res.push({label:"ตัวอย่างการสอน",value:"ตัวอย่างการสอน"});
    res.push({label:"เอกสารประกอบการเรียน",value:"เอกสารประกอบการเรียน"});
    res.push({label:"แบบฟอร์มยินยอมการใช้ข้อมูลส่วนบุคคล",value:"CONSENT"});
    return res;
}

export const createListedComapny = () =>{
    var res:any[]=[];   
    res.push({label:"SET",value:"SET"});
    res.push({label:"MAI",value:"MAI"});
    res.push({label:"ไม่ใช่บริษัทจดทะเบียน",value:"NONE"});
    return res;
}

export const getDocumentDescription = (t: string) =>{
    if (t==="ASSIGNMENT") return "งานที่ได้รับมอบหมาย";
    else if (t==="CONSENT") return "กรุณากดยอมรับเงื่อนไขในการเข้าเรียน";
    else if (t==="ETOOLKIT") return "งาน e-Toolkit";
    return t;
}

export enum LoginType {
    STUDENT="STUDENT",
    ADMIN="ADMIN",
    TRAINER="TRAINER"
}

export const thmonth=[
"ม.ค.",
"ก.พ.",
"มี.ค.",
"เม.ย.",
"พ.ค.",
"มิ.ย.",
"ก.ค.",
"ส.ค.",
"ก.ย.",
"ต.ค.",
"พ.ย.",
"ธ.ค."]

export enum PartnerRegistrationStatus {
    NEW = "NEW",
    PENDING_CUSTOMER = "PENDING_CUSTOMER",
    PROCESS = "PROCESS",
    CANCEL = "CANCEL",
    COMPLETE = "COMPLETE"
}

export const getPartnerRegistrationStatus = (t: PartnerRegistrationStatus) => {
    if (t===PartnerRegistrationStatus.NEW) return "รายการใหม่";
    if (t===PartnerRegistrationStatus.PENDING_CUSTOMER) return "ส่งฟอร์มให้กรอกข้อมูล";
    if (t===PartnerRegistrationStatus.PROCESS) return "อยู่ระหว่างดำเนินการ";
    if (t===PartnerRegistrationStatus.CANCEL) return "องค์กรยังไม่สนใจ";
    if (t===PartnerRegistrationStatus.COMPLETE) return "บันทึกลงฐานข้อมูล";
    return t;
}

export interface BasicResponse {
    label: string,
    value: any
}