import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import MainMenu  from './menu';
import Footer from './adminfooter';
import ClassTab from './classtab';
import { Button, Modal, InputGroup, Table } from 'react-bootstrap';
import { Util } from '../../api/util';
import { EXPORT_RESULT_URL, TEMPLATE_RESULT_URL } from '../../api/result-api';
import { listresult,listcoursewithname,save,importresult,liststatus } from '../../store/result/actions';
import { queryclear } from  '../../store/search/actions';
import loadinglogo from '../../img/loading.gif';
import excellogo from '../../img/excel.png';
import { createPage } from '../tab/pagetab';
import { AlertContainer, alert } from 'react-custom-alert';
import InfoIcon from '@material-ui/icons/Info';
import 'react-custom-alert/dist/index.css'; // import css file from root.
import { MAX_UPLOAD_FILE_SIZE_LABEL, REQUIRE_LABEL } from '../../api/label';
import { MAX_FILE_UPLOAD } from '../../api/conf';
import { SingleValue } from 'react-select';
import { SingleSearch } from '../tab/search-component';
import { getAccessToken } from '../../store/auth/selectors';

const EditResult = () => {
  const token=useSelector(getAccessToken);
  const [initClass,setInitClass] = useState(0)
  const [initResult,setInitResult] = useState(0)

  const [page,setPage] = useState(0)
  const [limit,setLimit] = useState(10)
  const [name,setName] = useState("")
  const [classCourseId,setClassCourseId] = useState<SingleValue<{label:string,value:number}>>(null);

  const [items,setItems] = useState<any>({totalItem:0,totalPage:0,items:[]})
  const [courseItem,setCourseItem] = useState<any>({})
  
  const [resultItem,setResultItem] = useState<any>([
    {code:"E",name:"ลงทะเบียน"},
    {code:"C",name:"ยืนยันการลงทะเบียน"},
    {code:"O",name:"กำลังเรียน"},
    {code:"X",name:"ยกเลิกการลงทะเบียน"},
    {code:"P",name:"ผ่าน"},
    {code:"PX",name:"ผ่านแบบมีเงื่อนไขพิเศษ"},
    {code:"F",name:"ไม่ผ่าน"},
    {code:"N",name:"ยังไม่ได้เริ่มเรียน"}
  ])

  const [status,setStatus] = useState("");
  const [currentFile, setCurrentFile] = useState<FileList>();
  const [errorUpload,setErrorUpload] = useState("");
  const [errorUpdate,setErrorUpdate] = useState("");
  const ref = useRef<any>();
  const [hint, setHint] = useState(false);
  const [loading,setLoading] = useState(false)
  const [updating,setUpdating] = useState(false)
  
  const [error, setError] = useState('');
  const [upload, setUpload] = useState(false);
  const [confirmUpload,setConfirmUpload] = useState(false);
  const [update,setUpdate] =useState(false);
  const [confirmUpdate,setConfirmUpdate] = useState(false);
  const store = useStore();
  const dispatch = useDispatch();
  const history = useHistory(); 
  let { id } = useParams<{ id: string}>();
  
  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
        let res = store.getState().SearchReducer;
        if (res.isLoading) {
            setError('');
            setLoading(true);
        } else if ((res.isLoaded) && (!res.isUpdated)) {
            setError('');
            setLoading(false);
            if (res.data!=null) {
                if (res.table==="class_course") {
                    setCourseItem(res.data);
                    if ((res.data.courseList!==undefined) && (res.data.courseList.length>0)) {
                        setClassCourseId({label: res.data.courseList[0].shortName+" : "+res.data.courseList[0].name,value:res.data.courseList[0].id});
                    }
                    setInitClass(1);
                } else if (res.table==="status") {
                    setInitResult(1);
                    setResultItem(res.data);
                } else {    
                    setItems(JSON.parse(JSON.stringify(res.data)));
                }
            } 
        } else if (res.isUpdating) {
            setError('');
            setUpdating(true);
        } else if (res.isUpdated) {
            setError('');
            setUpdating(false);
            setUpload(false);
            dispatch(queryclear());
            if (classCourseId!==null)
                dispatch(listresult(parseInt(id),name,classCourseId.value,page,limit));
            alert({ message: 'Saved', type: 'success' });
        } else if (res.isFailed) {
            if (res.data.success!==undefined) {
                alert({ message: res.data.popup??'Error', type: 'error' });
                window.scrollTo(0, 0);
              }   
              setError(res.data.message);
              setLoading(false);
              setUpdating(false);
              dispatch(queryclear());
        }
      })
    return unsubscribe;
   }, [name,classCourseId,page,limit])

   useEffect(() => {
    dispatch(queryclear());
    dispatch(listcoursewithname(parseInt(id)));
    dispatch(liststatus());
    },[])

  const checkAssignment = () =>{
    var courseList=(courseItem.courseList===undefined)?[]:courseItem.courseList;
    for (var i=0;i<courseList.length;i++) {
        var c=courseList[i];
        if ((c.isAssignment!==null) && (c.isAssignment!==undefined) && (c.isAssignment===true)) return true;
    }
    return false;
 }

  const createClassCourseSearchFilter = ():{label:string,value:number}[] => {
    var res:any[]=[];

    if (courseItem.courseList!==undefined) {
    for (var i=0;i<courseItem.courseList.length;i++)
    {
        res.push({label: courseItem.courseList[i].shortName+" : "+courseItem.courseList[i].name,value:courseItem.courseList[i].id});
    }
    }
    return res;
   }

   const getTotalPage = () => {
      return items.totalPage;
    }

   const getTotalItem = () => {
    return items.totalItem;
   }

 const handleBack = () => {
    history.push("/admin/search/class");
 }

 const handleClass=()=>{
    history.push("/admin/class/"+id);
 }

 const handleStudent=()=>{
    history.push("/admin/class/"+id+"/student");
 }

 const handleDoc=()=>{
    history.push("/admin/class/"+id+"/doc");
 }

 const handleAssignment=()=>{
    history.push("/admin/class/"+id+"/assignment");
}

const handleHistory=()=>{
    history.push("/admin/class/"+id+"/history");
}
const handleSurvey=()=>{
    history.push("/admin/class/"+id+"/survey");
}

const handleLimit = (l: number) => {
    setLimit(l);
    if (classCourseId!==null) {
        setPage(0);
        dispatch(queryclear);
        dispatch(listresult(parseInt(id),name,classCourseId.value,0,l));
    }
}

const handlePage = (p: number) => {
    if (classCourseId!==null) {
        setPage(p);
        dispatch(queryclear);
        dispatch(listresult(parseInt(id),name,classCourseId.value,p,limit));
    }
}

const getStatusName = (code: string) => {
    if (resultItem!==undefined) {
        for (var i=0;i<resultItem.length;i++)
        {
            if (resultItem[i].code===code) return resultItem[i].name;
        }
    }
    return code;
}

const createHint = () => {
    var res:any[]=[];
    if (resultItem!==undefined) {
        var status=resultItem;
        for (var i=0;i<status.length;i++)
        {
            //check dup code
            var found=false;
            for (var j=0;j<i;j++)
            {
                if (status[j].code===status[i].code) found=true;
            }
            if (!found) {
                res.push(<tr><td>{status[i].code}</td><td>{status[i].name}</td></tr>);
            }
        }
    }
    return res;
}

const handleSearch= () => {
    if (classCourseId!==null) {
        setPage(0);
        dispatch(listresult(parseInt(id),name,classCourseId.value,0,limit));
    }
}

const handleClear= () => {
    setPage(0);
    setName("");
}

const checkIsAbleSubmit=()=>{
    for (var i=0;i<items.items.length;i++)
    {
        if (items.items[i].checked) return true;
    }
    return false;
}

const handleSubmit=()=>{    
    var submit:any=[];
    for (var i=0;i<items.items.length;i++)
    {
        if (items.items[i].checked) {
            submit.push({id:items.items[i].id as number,classStudentId:items.items[i].classStudentId as number,result:status});
        }
    }
    setConfirmUpdate(false);
    if (submit.length===0) {
        setErrorUpdate("Please select some rows");
        return ;
    }
    dispatch(queryclear);
    dispatch(save(parseInt(id),classCourseId?.value as number,{items:submit}));
    setUpdate(false);
}

const validateFile = (): boolean => {
    setErrorUpload('');
    if ((currentFile !== undefined) && (currentFile.length > 0)) {
        let file_size = currentFile[0].size;
        //let file_name = currentFile[0].name;
        //let file_type = currentFile[0].type;
        if (file_size > MAX_FILE_UPLOAD*1000000) {
            setErrorUpload(MAX_UPLOAD_FILE_SIZE_LABEL);
            return false;
        }
    }
    if ((currentFile === undefined) || (currentFile.length === 0)) {
        setErrorUpload(REQUIRE_LABEL);
        return false;
    }
    return true;
}

const handleFileChange = (selectorFiles: FileList | null) => {
    if (selectorFiles !== null) {
        setCurrentFile(selectorFiles);
    }
 }

const handleImport=()=>{    
    var res=validateFile();
    setConfirmUpload(false);
    if ((res) && (currentFile!==undefined)) {
        dispatch(queryclear());
        dispatch(importresult(parseInt(id),classCourseId?.value as number,currentFile));
        ref.current.value="";
        setUpload(false);
    }
}

const handleTemplate=()=>{    
    window.open(TEMPLATE_RESULT_URL +"/"+id+"/class_course/"+classCourseId?.value+"?name="+encodeURIComponent(name)+"&token="+encodeURIComponent(token)); 
}

const handleExcel=()=>{
    window.open(EXPORT_RESULT_URL +"/"+id+"/class_course/"+classCourseId?.value+"?name="+encodeURIComponent(name)+"&token="+encodeURIComponent(token)); 
}

const setAll=(e:boolean)=>{
    var newItem={...items};
    for (var i=0;i<items.items.length;i++)
    {
        newItem.items[i].checked=e;
    }
    setItems(newItem);
}

const getAll=()=>{
    if (items.items.length===0) return false;
    for (var i=0;i<items.items.length;i++)
    {
        if (!items.items[i].checked) return false;
    }
    return true;
}

const setChecked=(i:number,e:boolean) =>{
    var newItems={...items};
    newItems.items[i].checked=e;   
    setItems(newItems); 
}

const getChecked=(i:number)=>{
    if (items.items[i].checked===undefined) return false;
    return items.items[i].checked;
}

const handleHintClose =() =>{
    setHint(false);
}

return (<>
    <MainMenu>
    <div>
    <div className="query-wrapper">
    <br/>
    <div className="yellow-button" style={{textAlign:"left" ,borderTopLeftRadius:"30px", borderTopRightRadius:"30px", height:"70px",paddingTop:"15px",paddingLeft:"15px"}}><h4 className="header">รอบการเรียน</h4></div>
    { (initClass===1) &&
    <>
    <div className="light-gray-button" style={{ borderBottomLeftRadius: '5px',borderBottomRightRadius: '5px',paddingLeft:"20px",paddingRight:"20px",paddingBottom:"20px",textAlign:"left"}}>
        <div className="row">
            <div className="col-4">                           
                <label className="col-form-label">โครงการ</label><br/>
                <input className="form-control" type="text" value={courseItem.code+":"+courseItem.name} readOnly={true}/>
            </div>
            <div className="col-1">                          
                <label className="col-form-label">รอบ</label><br/>
                <input className="form-control" type="text" value={courseItem.session} readOnly={true}/>
            </div>
            <div className="col-2">                          
                <label className="col-form-label">วันที่เริ่ม</label>
                <input className="form-control" type="text" value={Util.datetostr(new Date(courseItem.fromDate))} readOnly={true}/>
            </div>
            <div className="col-2">                           
                <label className="col-form-label">วันที่สิ้นสุด</label>
                <input className="form-control" type="text" value={Util.datetostr(new Date(courseItem.toDate))} readOnly={true}/>
            </div>
            <div className="col-3">
                <label className="col-form-label">ผู้รับผิดชอบโครงการ</label><br/>
                <input className="form-control" type="text" value={courseItem.organizer} readOnly={true}/>
            </div>
        </div>
    </div>
    <br/>
    { ClassTab(6,true,checkAssignment(),true,handleClass,handleStudent,handleDoc,handleAssignment,handleHistory,null,handleSurvey) }
    <div style={{background:"white",padding:"40px",borderRadius:"5px"}}>
         <div  className="light-gray-button" style={{position:"relative",textAlign:"left",padding:"20px",borderRadius:"5px"}}>
        <div className="row" style={{paddingRight:"40px",justifyContent:"center"}}>
            <div className="col-1" style={{textAlign:"right",paddingTop:"5px"}}>
            <label>ผู้เรียน</label>
            </div>
            <div className="col-4">
            <input className="form-control" value={name} onChange={(e)=>{setName(e.target.value)}}/>
            </div>
            <div className="col-1" style={{textAlign:"right",paddingTop:"5px"}}>
            <label>หลักสูตร</label>
            </div>
            <div className="col-4">
            <SingleSearch
                options={createClassCourseSearchFilter()}
                value={classCourseId}
                onChange={(id: string,e: any)=>{setClassCourseId(e)}} 
                isSearchable={true} isMulti={false} placeholder=""
            />
            </div>
        </div>
        <br/>
        <div className="row" style={{justifyContent:"center"}}>
            <button type="button" className="btn btn-outline-primary" style={{width:"150px"}}
                    onClick={() => handleClear()} >
                    Clear
            </button>&nbsp;
            <button type="button" className="btn btn-primary" style={{width:"150px"}}
                        onClick={() => handleSearch()} disabled={loading}>
                        <div>
                        { (loading) &&
                            <div  style={{ textAlign: "center" }}><img src={loadinglogo} width="30" height="30" className="d-inline-block align-top" alt="" /></div>
                        }
                        { (!loading) &&
                            <>Search</>
                        }
                        </div>
            </button>
        </div>
        </div>
            <br/>
            <div className="row">
                <div className="col-5" style={{justifyContent:"left",textAlign:"left"}}>
                จำนวนทั้งหมด {Util.integerWithCommas(getTotalItem())} รายการ 
                </div>
                <div className="col-1">                
                </div>
                <div className="col-6 row" style={{justifyContent:"right",textAlign:"right",paddingRight:"0px"}}>
                    <div style={{textAlign:"right",marginTop:"auto",marginBottom:"auto"}}>จำนวนรายการต่อหน้า</div>&nbsp;
                    <select className="form-control" style={{width:"80px"}} name="page" value={limit} onChange={e=>handleLimit(parseInt(e.target.value))}>
                            <option value={getTotalItem()}>All</option>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                    </select>&nbsp;
                    <Button type="button" className="btn btn-success green-button" style={{width:"50px"}}
                            onClick={() => handleExcel()} >
                            <img src={excellogo} height="32px" alt="excel" style={{marginLeft:"-7px"}}/>
                    </Button>
                </div>
            </div>
            <br/>
            <Table striped hover style={{borderRadius:"5px",borderCollapse: "collapse"}}>  
                <thead className="black-button">
                    <tr>
                        <th style={{ verticalAlign: "top",width:"50px"}}><input type="checkbox" checked={getAll()} onClick={(e)=>{setAll(e.currentTarget.checked)}} disabled={updating}/></th>
                        <th className='text-left' style={{ verticalAlign: "top"}}>ชื่อผู้เรียน</th>
                        <th className='text-left' style={{ verticalAlign: "top"}}>องค์กร</th>
                        <th className='text-left' style={{ verticalAlign: "top"}}>e-Mail</th>
                        <th style={{ verticalAlign: "top"}}>สถานะ</th>
                    </tr>
                </thead>
                <tbody style={{fontSize:"24px"}}>
                {items.items.map((item:any, index:number) => {
                return <tr key={index}>
                <td> <input type="checkbox" checked={getChecked(index)} onClick={(e)=>{setChecked(index,e.currentTarget.checked)}} disabled={updating}/></td>                               
                <td className='text-left'> {item.firstName} {item.lastName}</td>   
                <td className='text-left'> {item.code}:{item.partner} </td>
                <td className='text-left'> {item.email} </td>
                <td> {getStatusName(item.result)} </td>
                </tr>
                })
                }
                </tbody>
            </Table>
            <br/>
            <div className="row" style={{justifyContent:"center",position:"relative"}}>
                { createPage(page,getTotalPage(),handlePage) }
                <div className="col-5" style={{justifyContent:"left",textAlign:"left",position:"absolute",left:"0px"}}>
                    จำนวนทั้งหมด {Util.integerWithCommas(getTotalItem())} รายการ <br/>
                </div>
            </div>
            <br/>
            <div className="row" style={{justifyContent:"center",position:"relative"}}>
                <Button style={{marginTop:"-5px"}} onClick={e=>{setErrorUpdate(""); setStatus(""); setUpdate(true)}} disabled={!checkIsAbleSubmit()}>Update Status</Button>&nbsp; or &nbsp;<Button style={{marginTop:"-5px"}} onClick={e=>{setErrorUpload(""); setUpload(true);}}>Upload File</Button> &nbsp;
                <span className="link" onClick={e=>{ handleTemplate();}}>Download Template file for Update Status</span>
            </div>
    </div>
    </>
    }
    <br/>
    { (((initClass<1) || (loading) || (updating)) && (error==="")) &&
        <div style={{ textAlign: "center" }}><img src={loadinglogo} width="30" height="30" className="d-inline-block align-top" alt="" /></div>
    }
    { (error!=="") &&
        <div style={{ color: 'red', textAlign: "center" }}>{error}</div>
    }
    { ((initClass===1) && (!loading) && (!updating)) &&
        <div style={{justifyContent:"right",textAlign:"right"}}>
            <div className="col-2" style={{justifyContent:"left",textAlign:"left"}}>
            <InfoIcon onClick={(e:any)=>setHint(true)}></InfoIcon>
            </div>
            <div className="col-8"></div>
            <button type="button" className="btn btn-outline-primary" style={{width:"120px"}}
                    onClick={() => handleBack()} >
                    Back
            </button>
        </div>
    }
    </div>
    <br/>
    <Footer/>
    </div>
    </MainMenu>

    <Modal size="lg" show={update} onHide={()=>{ setUpdate(false)}} >
    <Modal.Header closeButton={!updating}>
    <Modal.Title>เปลี่ยนสถานะ</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        ปรับสถานะการเรียนของผู้เรียนในหลักสูตร : { classCourseId?.label}<br/>
        <select className="form-control" value={status} onChange={e=>{setStatus(e.target.value)}}>
            <option></option>
            <option value="X">ยกเลิกการลงทะเบียน</option>
            <option value="P">ผ่าน</option>
            <option value="PX">ผ่านแบบมีเงื่อนไขพิเศษ</option>
            <option value="F">ไม่ผ่าน</option>
        </select>
        <div className={(errorUpdate!=="")?"text-danger":""}><small>{errorUpdate}</small></div>
    </Modal.Body>
    <Modal.Footer>
          <Button variant="secondary" onClick={(e:any) => { setUpdate(false)}}>
            Cancel
          </Button>
          <Button variant="primary" onClick={e=>{setConfirmUpdate(true)}}>
            Update
          </Button>
    </Modal.Footer>
   </Modal>

   <Modal show={confirmUpdate} onHide={()=>{setConfirmUpdate(false)}}>
        <Modal.Header closeButton={!updating}>
          <Modal.Title>ยืนยันการเปลี่ยนสถานะการเรียน</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="primary" onClick={e=>{handleSubmit()}}>
            OK
          </Button>&nbsp;
          <Button variant="secondary" onClick={e=>{setConfirmUpdate(false)}}>
            Cancel
          </Button>
        </Modal.Footer>
    </Modal>

    <Modal size="lg" show={upload} onHide={()=>{ setUpload(false)}} >
    <Modal.Header closeButton={!updating}>
    <Modal.Title>เปลี่ยนสถานะ</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        ปรับสถานะการเรียนของผู้เรียนในหลักสูตร : { classCourseId?.label}<br/>
        <InputGroup hasValidation>
        <input type="file" className={"form-control "+((errorUpload!=="")?"is-invalid":"")} ref={ref} accept=".xlsx" id="contained-button-file" onChange={(e: any) => handleFileChange(e.target.files)}/><br/>
        </InputGroup>
        <div className={(errorUpload!=="")?"text-danger":""}><small>{errorUpload}</small></div>
    </Modal.Body>
    <Modal.Footer>
          <Button variant="secondary" onClick={(e:any) => { setUpload(false)}}>
            Cancel
          </Button>
          <Button variant="primary" onClick={e=>{setConfirmUpload(true)}}>
            Upload
          </Button>
    </Modal.Footer>
   </Modal>

   <Modal show={confirmUpload} onHide={()=>{setConfirmUpload(false)}}>
        <Modal.Header closeButton>
          <Modal.Title>ยืนยันการเปลี่ยนสถานะการเรียน</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="primary" onClick={e=>{handleImport()}}>
            OK
          </Button>&nbsp;
          <Button variant="secondary" onClick={e=>{setConfirmUpload(false)}}>
            Cancel
          </Button>
        </Modal.Footer>
    </Modal>

   <Modal show={hint===true} onHide={()=>{handleHintClose()}}>
        <Modal.Header closeButton>
          <Modal.Title>สถานะ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <table>
        { createHint() 
        }
        </table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleHintClose}>
            Close
          </Button>
        </Modal.Footer>
    </Modal>
    <AlertContainer floatingTime={3000}/>
    </>)
}

export default EditResult;
