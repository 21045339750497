import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';
import { logout } from '../../store/auth/actions';
import Footer from './adminfooter';

const AccessDenied = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    useEffect(() => {
        dispatch(logout());
    }, []);

    const handleBack = () => {
        history.push("/admin/login");
    }
    
    return (<><div>
        <div className="query-wrapper">
        <br/>
        <div style={{ background: '#EEEEEE', borderRadius: '5px',paddingLeft:"15px", paddingRight:"15px", paddingBottom:'20px',textAlign:"left"}}>
                <br/>
                <div className="row">
                    <div className="col-12" style={{textAlign:"center"}}>  
                    Access Denied<br/>        
                    รออีก 5 นาทีแล้วกด Back<br/>
                    </div> 
                </div>
                <div className="row">
                    <div className="col-12" style={{textAlign:"center"}}>  
                    <Button type="button" className="btn btn-warning yellow-button" style={{width:"150px"}}
                            onClick={() => handleBack()} >
                            Back
                    </Button>      
                    </div> 
                </div>
        </div>
        </div>
        <br/>
        <br/>
        </div>
        <Footer empty={true}/>
        </>);
}

export default AccessDenied;