import React, { useEffect, useState } from 'react'
import { useDispatch, useStore } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { queryclear } from '../../store/search/actions';
import { AlertContainer, alert } from 'react-custom-alert';
import MainMenu from './menu';
import Footer from './adminfooter';
import CourseItem from '../share/CourseItem';
import { ListClassCourseWithNameResponse, StudentResultTypeNoContactDetailResponse, StudentResultTypeNoContactResponse } from '../../model/response/class-course.model';
import { listcoursewithname, listResultTypeNoContact, listResultTypeNoContactDetail, saveResultTypeNoContactDetail } from '../../store/result/actions';
import ClassTab from './classtab';
import loadinglogo from '../../img/loading.gif';
import { TableHeaderBlack } from '../../style/tableStyle';
import ArrowDropDownIcon from '@material-ui/icons/ExpandMore';
import ArrowDropUpIcon from '@material-ui/icons/ExpandLess';
import { InputGroup } from 'react-bootstrap';
import { SingleSearch } from '../tab/search-component';
import { listpartnerByClassId } from '../../store/partner/actions';
import { listcourseByClassId } from '../../store/editclass/actions';
import { StudentResultTypeNoContactDetailRequest } from '../../model/request/class-course.model';
const EditResultNumber = () => {
    const store = useStore();
    const dispatch = useDispatch();
    const history = useHistory();
    let { id } = useParams<{ id: string }>();
    const [initClass, setInitClass] = useState(0);
    const [tableItem, setTableItems] = useState<StudentResultTypeNoContactDetailResponse[] | null>();
    const [courseItem, setCourseItem] = useState<ListClassCourseWithNameResponse | any>({});
    const [summaryItem, setSummaryItem] = useState<StudentResultTypeNoContactResponse[] | undefined>()
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [success, setSuccess] = useState(false);
    const [partnerId, setPartnerId] = useState<{ label: string, value: number } | undefined>();
    const [classCourseId, setClassCourse] = useState<{ label: string, value: number } | undefined>();
    const [partnerList, setPartnerList] = useState<any[]>();
    const [classCourseList, setClassCourseList] = useState<any[]>();
    useEffect(() => {
        const unsubscribe = store.subscribe(() => {
            let res = store.getState().SearchReducer;
            if (res.isLoading) {
                setError('');
                setLoading(true);
            } else if ((res.isLoaded) && (!res.isUpdated)) {
                setError('');
                setLoading(false);
                if (res.data != null) {
                    if (res.table === "class_course") {
                        setCourseItem(res.data);
                        setInitClass(1);
                    } else if (res.table === "result_number") {
                        setSummaryItem(res.data);
                    } else if (res.table === "result_number_table") {
                        setTableItems(res.data);
                    } else if (res.table === "partner_by_classId") {
                        setPartnerList(res.data)
                    } else if (res.table === "course_by_classId") {
                        setClassCourseList(res.data)
                    }
                }
            } else if (res.isUpdating) {
                setError('');
                setUpdating(true);
            } else if (res.isUpdated) {
                setError('');
                setSuccess(true);
                setUpdating(false);
                dispatch(queryclear());
                handleClear();
                alert({ message: 'Saved', type: 'success' });
            } else if (res.isFailed) {
                if (res.data.success !== undefined) {
                    alert({ message: res.data.popup ?? 'Error', type: 'error' });
                    window.scrollTo(0, 0);
                }
                setError(res.data.message);
                setLoading(false);
                setUpdating(false);
                dispatch(queryclear());
            }
        })
        return unsubscribe;
    }, [])
    useEffect(() => {
        dispatch(queryclear());
        dispatch(listpartnerByClassId(parseInt(id)));
        dispatch(listcourseByClassId(parseInt(id)))
        dispatch(listcoursewithname(parseInt(id)));
        dispatch(listResultTypeNoContact(parseInt(id), undefined, undefined))
    }, [])

    const handleBack = () => {
        history.push("/admin/search/class");
    }

    const handleClass = () => {
        history.push("/admin/class/" + id);
    }

    const handleStudent = () => {
        history.push("/admin/class/" + id + "/student");
    }

    const handleDoc = () => {
        history.push("/admin/class/" + id + "/doc");
    }
    const handleResultNumber = () => {
        history.push("/admin/class/" + id + "/result-number");
    }

    const handleSurvey = () => {
        history.push("/admin/class/" + id + "/survey");
    }

    const SummaryStatus = () => {
        return <React.Fragment>
            {   //summary
                ((summaryItem !== undefined) && (summaryItem.length > 0)) &&
                <>
                    {summaryItem.map((item: StudentResultTypeNoContactResponse, index: any) => {
                        return <React.Fragment key={'summary' + item.classCourseId}>
                            <div className='row'>
                                <div className="col-2 yellow-button text-center" style={{ position: "relative", textAlign: "left", padding: "20px", borderTopLeftRadius: "5px", borderBottomLeftRadius: "5px" }}>
                                    {item.courseShortName}
                                </div>
                                <div className="col-10 light-gray-button" style={{ position: "relative", textAlign: "left", padding: "20px", borderTopRightRadius: "5px", borderBottomRightRadius: "5px" }}>
                                    <div className="row">
                                        <div className="col-8">
                                            {item.courseName}
                                        </div>
                                        <div className="col-4 row text-center justify-content-end">
                                            <div className='col-auto'>
                                                <div className="green">{item.studentPass}</div>
                                                <div>ผ่าน</div>
                                            </div>
                                            <div className='col-auto'>
                                                <div className="red">{item.studentFail}</div>
                                                <div>ไม่ผ่าน</div>
                                            </div>
                                            <div className='col-auto'>
                                                <div className="blue">{item.studentTotal}</div>
                                                <div>ผู้เรียนทั้งหมด</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br />
                        </React.Fragment>
                    })
                    }
                </>
            }
        </React.Fragment>
    }

    const getCountPass = (i: number, j: number) => {
        return tableItem ? Number(tableItem[i].students[j].studentPass).toString() : 0;
    }

    const setCountPass = (i: number, j: number, v: string) => {
        if (updating) return;
        var newItems: any = [...tableItem!];
        newItems[i].students[j].studentPass = parseInt(v);
        if (isNaN(newItems[i].students[j].studentPass)) newItems[i].students[j].studentPass = 0;
        if (newItems[i].students[j].studentPass > newItems[i].students[j].studentTotal) {
            newItems[i].students[j].studentPass = newItems[i].students[j].studentTotal
        }
        newItems[i].students[j].studentFail = newItems[i].students[j].studentTotal - newItems[i].students[j].studentPass;
        setTableItems(newItems);
        // setEdit(true);
    }

    const handleRow = (i: number) => {
        if (tableItem !== null) {
            var newItems = [...tableItem!];
            newItems[i].show = ((newItems[i].show === false));
            setTableItems(newItems);
        }
    }

    const createPartnerSearchFilter = () => {
        var res: any[] = [];
        if (partnerList !== undefined) {
            for (var i = 0; i < partnerList.length; i++) {
                res.push({ label: partnerList[i].name, value: partnerList[i].id });
            }
        }
        return res;
    }

    const createCourseSearchFilter = () => {
        var res: any[] = [];
        if (classCourseList !== undefined) {
            for (var i = 0; i < classCourseList.length; i++) {
                res.push({ label: classCourseList[i].shortName + ': ' + classCourseList[i].name, value: classCourseList[i].classCourseId });
            }
        }
        return res;
    }
    const handleClear = () => {
        setPartnerId(undefined);
        setClassCourse(undefined);
        dispatch(queryclear());
        dispatch(listResultTypeNoContactDetail(parseInt(id), undefined, undefined));
        dispatch(listResultTypeNoContact(parseInt(id), undefined, undefined));
    }
    const handleSearch = () => {
        dispatch(queryclear());
        dispatch(listResultTypeNoContactDetail(parseInt(id), partnerId?.value, classCourseId?.value));
        dispatch(listResultTypeNoContact(parseInt(id), partnerId?.value, classCourseId?.value));
    }
    const handleSubmit = () => {
        dispatch(queryclear());
        var request:StudentResultTypeNoContactDetailRequest[]=[];
        if(tableItem){
            tableItem.forEach(item=>{
                if(item.students && item.students.length>0){
                    item.students.forEach(student=>{
                        let studentItemRequest:StudentResultTypeNoContactDetailRequest = {
                            classCourseId:student.classCourseId,
                            classStudentId:student.classStudentId,
                            studentPass:student.studentPass,
                            studentTotal: student.studentTotal,
                            studentFail: student.studentFail
                        }
                        request.push(studentItemRequest)
                    })
                }
                
            })
            dispatch(saveResultTypeNoContactDetail(parseInt(id),request));   
        }
    }

    const SearchForm = () => {
        return <>
            <div className="light-gray-button" style={{ position: "relative", textAlign: "left", padding: "20px", borderRadius: "5px" }}>
                <div className="row" style={{ paddingRight: "40px" }}>
                    <div className="col-1" style={{ textAlign: "right", paddingTop: "5px" }}>
                        <label>องค์กร</label>
                    </div>
                    <div className="col-5">
                        <SingleSearch
                            isClearable={true}
                            isSearchable={true} isMulti={false} placeholder=""
                            onChange={(id: string, e: any) => setPartnerId(e)}
                            value={partnerId}
                            options={createPartnerSearchFilter()}
                        />
                    </div>
                    <div className="col-1" style={{ textAlign: "right", paddingTop: "5px" }}>
                        <label>หลักสูตร</label>
                    </div>
                    <div className="col-5">
                        <SingleSearch
                            isClearable={true}
                            isSearchable={true} isMulti={false} placeholder=""
                            onChange={(id: string, e: any) => setClassCourse(e)}
                            value={classCourseId}
                            options={createCourseSearchFilter()}
                        />
                    </div>
                </div>
            </div>
            <br />
            <div className="row" style={{ justifyContent: "center" }}>
                <button type="button" className="btn btn-outline-primary" style={{ width: "150px" }}
                    onClick={() => handleClear()} >
                    Clear
                </button>&nbsp;
                <button type="button" className="btn btn-primary" style={{ width: "150px" }}
                    onClick={() => handleSearch()} disabled={loading}>
                    <div>
                        {(loading) &&
                            <div style={{ textAlign: "center" }}><img src={loadinglogo} width="30" height="30" className="d-inline-block align-top" alt="" /></div>
                        }
                        {(!loading) &&
                            <>Search</>
                        }
                    </div>
                </button>
            </div>
            <br />
        </>
    }
    const createOrganizer = (i: number) => {
        var res: any[] = [];
        if (tableItem != null) {
            if (tableItem[i].show === true || tableItem[i].show === undefined) {
                if ((tableItem[i].students !== null) && tableItem[i].students!.length > 0) {
                    res.push(
                        <tr style={{ backgroundColor: 'white' }}>
                            <th></th>
                            <th className='text-left' style={{ fontFamily: "DB Helvethaica Bold" }}>รหัสองค์กร</th>
                            <th className='text-left' style={{ fontFamily: "DB Helvethaica Bold" }}>ชื่อองค์กร</th>
                            <th className='text-left' style={{ fontFamily: "DB Helvethaica Bold" }}>รุ่นที่</th>
                            <th className='text-left' style={{ fontFamily: "DB Helvethaica Bold" }}>จำนวนคนที่ผ่าน</th>
                            <th className='text-left' style={{ fontFamily: "DB Helvethaica Bold" }}>จำนวนคนที่ไม่ผ่าน</th>
                            <th className='text-left' style={{ fontFamily: "DB Helvethaica Bold" }}>ผู้เรียนทั้งหมด</th>
                        </tr>
                    );
                }
                for (var j = 0; j < ((tableItem[i].students === null) ? 0 : tableItem[i].students!.length); j++) {
                    var c = tableItem[i].students![j];
                    res.push(
                        <tr style={{ backgroundColor: "#EFF5FD" }} key={'tableItem' + c.classCourseId}>
                            <td></td>
                            <td className='text-left' style={{ verticalAlign: "middle" }}>{c.partnerCode}</td>
                            <td className='text-left' style={{ verticalAlign: "middle" }}>{c.partnerName}</td>
                            <td className='text-left' style={{ verticalAlign: "middle" }}>{c.studentGeneration}</td>
                            <td className='text-left' style={{ verticalAlign: "middle" }}>
                                <InputGroup hasValidation>
                                    <input type='number' id={j.toString()} className={"form-control"} value={getCountPass(i, j)} onChange={(e) => { setCountPass(i, parseInt(e.currentTarget.id), e.target.value) }} disabled={success} style={{ width: "50px" }} /><br />
                                </InputGroup>
                            </td>
                            <td className='text-left' style={{ verticalAlign: "middle" }}>{c.studentFail}</td>
                            <td className='text-left' style={{ verticalAlign: "middle" }}>{c.studentTotal}</td>
                        </tr>);
                }
            }
        }
        return res;
    }

    const ResultTable = () => {
        return <React.Fragment>
            <TableHeaderBlack striped hover className='mt-2'>
                <thead>
                    <tr>
                        <th></th>
                        <th className='text-left' style={{ verticalAlign: "top" }}>
                            Step
                        </th>
                        <th className='text-left' style={{ verticalAlign: "top" }}>
                            หลักสูตร
                        </th>
                        <th className='text-left' colSpan={4} style={{ verticalAlign: "top" }}>

                        </th>
                    </tr>
                </thead>
                <tbody>
                    {tableItem && tableItem.map((item: StudentResultTypeNoContactDetailResponse, index: number) => (
                        <React.Fragment key={'editResultTable' + item.classCourseId}>
                            <tr style={{ backgroundColor: '#f1eeee' }}>
                                <td className='text-left'>
                                    {(item.show === false) &&
                                        <div onClick={(e) => handleRow(index)}><ArrowDropDownIcon className="yellow-circle white pointer" ></ArrowDropDownIcon></div>
                                    }
                                    {((item.show === undefined) || (item.show === true)) &&
                                        <div onClick={(e) => handleRow(index)}><ArrowDropUpIcon className="yellow-circle white pointer" ></ArrowDropUpIcon></div>
                                    }
                                </td>
                                <td className='text-left'>{index + 1}</td>
                                <td className='text-left'>{item.courseName}</td>
                                <td colSpan={4}></td>
                            </tr>
                            {createOrganizer(index)}
                        </React.Fragment>

                    ))}
                </tbody>
            </TableHeaderBlack>
        </React.Fragment>
    }
    return (
        <div>
            <MainMenu>
                <div>
                    <div className='query-wrapper'>
                        <br />
                        <div className="yellow-button" style={{ textAlign: "left", borderTopLeftRadius: "30px", borderTopRightRadius: "30px", height: "70px", paddingTop: "15px", paddingLeft: "15px" }}><h4 className="header">รอบการเรียน</h4></div>
                        {(initClass === 1) &&
                            <>
                                <CourseItem courseItem={courseItem} />
                                <br />
                                {ClassTab(8, true, false, false, handleClass, handleStudent, handleDoc, null, null, null, handleSurvey, handleResultNumber,courseItem.showResultStudentTab)}
                                <div style={{ background: "white", padding: "40px", borderRadius: "5px" }}>
                                    {SearchForm()}
                                    {SummaryStatus()}
                                    {ResultTable()}
                                </div>
                            </>
                        }
                        {(((initClass < 1) || (loading) || (updating)) && (error === "")) &&
                            <div style={{ textAlign: "center" }}><img src={loadinglogo} width="30" height="30" className="d-inline-block align-top" alt="" /></div>
                        }
                        {(error !== "") &&
                            <div style={{ color: 'red', textAlign: "center" }}>{error}</div>
                        }
                        {((initClass === 1) && (!loading) && (!updating)) &&
                            <div style={{ justifyContent: "right", textAlign: "right", marginTop: 10 }}>
                                {!success && <>
                                    <button type="button" className="btn btn-outline-primary" style={{ width: "120px" }}
                                        onClick={() => handleBack()} >
                                        Back
                                    </button>&nbsp;
                                    <button type="button" className="btn btn-success" style={{ width: "120px" }}
                                        onClick={() => handleSubmit()} >
                                        Save
                                    </button>
                                </>}
                                {success && <>
                                    <button type="button" className="btn btn-outline-primary" style={{ width: "120px" }}
                                        onClick={() => handleBack()} >
                                        Back
                                    </button>&nbsp;
                                    <button type="button" className="btn yellow-button" style={{ width: "120px" }}
                                        onClick={() => setSuccess(false)} >
                                        Edit
                                    </button>
                                </>}

                            </div>
                        }
                    </div>
                    <br />
                    <Footer />
                </div>
            </MainMenu>
            <AlertContainer floatingTime={3000} />
        </div>
    )
}

export default EditResultNumber;