import React, { useState } from 'react'
import ExpandMore from '@material-ui/icons/ExpandMore'
import ExpandLess from '@material-ui/icons/ExpandLess'
import { Button } from 'react-bootstrap'
import AddIcon from '@material-ui/icons/Add';
import iconEdit from '../../../../img/iconEdit.png'
import iconNote from '../../../../img/iconNote.png'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
type Props = {}

const ManageKnowledgeSuggestion = (props: Props) => {
    const [expand, setExpand] = useState(true)
    return (
        <div>
            <div className="gray-button text-left" style={{ padding: "15px", borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}>
                {((expand === undefined) || (expand === false)) &&
                    <div onClick={(e) => setExpand(true)}><ExpandMore className="pointer mr-2" ></ExpandMore>คำอธิบายการใช้งาน</div>
                }
                {(expand === true) &&
                    <div onClick={(e) => setExpand(false)}><ExpandLess className="pointer mr-2" ></ExpandLess>คำอธิบายการใช้งาน</div>
                }
            </div>
            {expand && (
                <div className="bg-white text-left" style={{ padding: "15px", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px" }}>
                    <div className='row'>
                        <div className="col-12">
                            ขั้นตอนการใช้งาน ดังนี้
                        </div>
                        <div className="col-12 pl-4">
                            1. กรณีที่ยังไม่มีการสร้างประเภทและหมวดหมู่สื่อความรู้ จะต้องทำการสร้างก่อนการใช้งานโดย กด <span><div className="btn btn-warning yellow-button btn-sm"
                            >
                                <AddIcon />
                                เพิ่มประเภทความรู้
                            </div></span> และหากต้องการสร้างหมวดหมู่ให้ กด <span><div className="btn btn-warning yellow-button btn-sm"
                            >
                                <AddIcon />
                                เพิ่มหมวดหมู่ความรู้
                            </div></span> สร้างหมวดหมู่ และใส่ข้อมูลตามที่กำหนด
                        </div>
                        <div className="col-12 pl-4">
                            2. กรณีที่เคยสร้างประเภทและหมวดหมู่สื่อความรู้แล้ว หากต้องการแก้ไขให้คลิก <span className='ml-1'><img src={iconEdit} width={20} height={20} className='mr-2' /></span> หรือ เพิ่มเติมในส่วนที่เกี่ยวข้องให้คลิก <span className='ml-1'><img src={iconNote} width={20} height={20} className='mr-2' /></span>

                        </div>
                        <div className='col-12 pl-4'>
                            3. หากต้องการสร้างสื่อความรู้ใหม่ สามารถกดเครื่องหมาย  <small><AddCircleOutlineIcon className="yellow mr-1" />สื่อความรู้</small> และระบุรายละเอียดตามที่ต้องการ
                        </div>
                    </div>
                </div>
            )}

        </div>
    )
}

export default ManageKnowledgeSuggestion