import { apiClient } from './axios-intercept';
import { DEFAULT_SERVER } from './conf';

const RESULT ="/api/v1/history/status";
const HISTORY ="/api/v1/history/class";
const TRAINER ="/api/v1/history/trainer";
const SEPARATE_GROUP ="/api/v1/history/separate";
const EXPORT ="/api/v1/history/export";

export const EXPORT_HISTORY_URL = DEFAULT_SERVER+EXPORT;

const SEARCH_RESULT_URL = DEFAULT_SERVER + RESULT;
const SEARCH_HISTORY_URL = DEFAULT_SERVER + HISTORY;
const LIST_TRAINER_URL = DEFAULT_SERVER + TRAINER;
const SET_SEPARATE_GROUP_URL = DEFAULT_SERVER + SEPARATE_GROUP;

export class HistoryAPI {
        
    public static listResult = async (classId: number): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=SEARCH_RESULT_URL+"/"+classId;
             apiClient.get(s,
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                console.log("list result response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("list result error:", err);
                return reject(err); 
            })
        })
    }

    public static listHistory = async (classId: number,param: string,page: number,limit: number): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=SEARCH_HISTORY_URL+"/"+classId+"?"+param+"&page="+page+"&limit="+limit; 
            apiClient.get(s,
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                console.log("list history response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("list history error:", err);
                return reject(err); 
            })
        })
    }

    public static listTrainerEachCourse = async (classId: number): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=LIST_TRAINER_URL+"/"+classId;
             apiClient.get(s,
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                console.log("list trainer response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("list trainer error:", err);
                return reject(err); 
            })
        })
    }


    public static generateSeparateGroup = async (class_course_id: number): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=SET_SEPARATE_GROUP_URL+"/"+class_course_id;
             apiClient.post(s,
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                console.log("generate separate group response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("generate separate group error:", err);
                return reject(err); 
            })
        })
    }

    public static setSeparateGroup = async (class_course_id: number,class_student_id: number,class_course_sessiont_trainer_id: number): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=SET_SEPARATE_GROUP_URL+"/"+class_course_id;
             apiClient.patch(s,
                {
                    classCourseId: class_course_id,
                    classStudentId: class_student_id,
                    classCourseSessionTrainerId: class_course_sessiont_trainer_id
                },
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                console.log("set separate group response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("set separate group error:", err);
                return reject(err); 
            })
        })
    }

    public static getParam = (name: string,partner: any[],course: any[],resultStatus: any[]) => {
        //param
        var param=((name===undefined)?"":"&name="+encodeURIComponent(name));
        if (partner!==undefined)
        {
            for (var i=0;i<partner.length;i++)
            {
                param=param+"&partner_id="+partner[i].value;
            }
        }
        if (course!==undefined)
        {
            for (var i=0;i<course.length;i++)
            {
                param=param+"&class_course_id="+course[i].value;
            }
        }
        if (resultStatus!==undefined)
        {
            for (var i=0;i<resultStatus.length;i++)
            {
                param=param+"&result="+resultStatus[i].value;
            }
        }
        return param;
    }
}