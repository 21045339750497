export class Util {

    public static datetostr = (_date: Date): string => {
        if (isNaN(_date.getDate())) return "";
        var dd = String(_date.getDate()).padStart(2, '0');
        var mm = String(_date.getMonth() + 1).padStart(2, '0');
        var yyyy = _date.getFullYear() + 543;
        return dd + '/' + mm + '/' + yyyy;
    }

    public static datetostrInThai = (_date: Date): string => {
        if (isNaN(_date.getDate())) return "";
        var dd = String(_date.getDate()).padStart(2, '0');
        var mm = String(_date.getMonth() + 1).padStart(2, '0');
        var yyyy = _date.getFullYear() + 543;
        return dd + ' ' + Util.monthToThai(mm) + ' ' + yyyy;
    }

    public static monthToThai = (month: string) => {
        switch(month){
            case '01': return 'ม.ค.';
            case '02': return 'ก.พ.';
            case '03': return 'มี.ค.';
            case '04': return 'เม.ย.';
            case '05': return 'พ.ค.';
            case '06': return 'มิ.ย.';
            case '07': return 'ก.ค.';
            case '08': return 'ส.ค.';
            case '09': return 'ก.ย.';
            case '10': return 'ต.ค.';
            case '11': return 'พ.ย.';
            case '12': return 'ธ.ค.';
        }
    }

    public static datetimetostr = (_date: Date): string => {
        if (isNaN(_date.getDate())) return "";
        var dd = String(_date.getDate()).padStart(2, '0');
        var mm = String(_date.getMonth() + 1).padStart(2, '0');
        var yyyy = _date.getFullYear() + 543;
        var hh = String(_date.getHours()).padStart(2, '0');
        var min = String(_date.getMinutes()).padStart(2, '0');

        return dd + '/' + mm + '/' + yyyy + " " + hh + ":" + min;
    }

    public static datetimetofullstr = (_date: Date): string => {
        if (isNaN(_date.getDate())) return "";
        var dd = String(_date.getDate()).padStart(2, '0');
        var mm = String(_date.getMonth() + 1).padStart(2, '0');
        var yyyy = _date.getFullYear() + 543;
        var hh = String(_date.getHours()).padStart(2, '0');
        var min = String(_date.getMinutes()).padStart(2, '0');
        var ss = String(_date.getSeconds()).padStart(2, '0');

        return dd + '/' + mm + '/' + yyyy + " " + hh + ":" + min + ":" + ss;
    }

    public static passwordComplexityloss = (password: string): string => {
        let result = ""
        if (!Util.isContainUpperCase(password)) { result = "Must contain least 1 uppercase letter." }
        else if (!Util.isContainlowerCase(password)) { result = "Must contain least 1 lowercase letter." }
        else if (!Util.isContain8Char(password)) { result = "Must contain 8 characters or more." }
        return result
    }

    public static isContainUpperCase = (text: string): boolean => {
        const expression: RegExp = /(?=.*[A-Z])/
        return expression.test(text)
    }

    public static isContainlowerCase = (text: string): boolean => {
        const expression: RegExp = /(?=.*[a-z])/
        return expression.test(text)
    }

    public static isContain8Char = (text: string): boolean => {
        const expression: RegExp = /^(?=.*[a-zA-Z]).{8,}$/
        return expression.test(text)
    }

    public static getLocalISOString(date: Date) {
        const offset = date.getTimezoneOffset()
        const offsetAbs = Math.abs(offset)
        const isoString = new Date(date.getTime() - offset * 60 * 1000).toISOString()
        return `${isoString.slice(0, -1)}${offset > 0 ? '-' : '+'}${String(Math.floor(offsetAbs / 60)).padStart(2, '0')}:${String(offsetAbs % 60).padStart(2, '0')}`
    }

    public static getToday() {
        var now: Date = new Date();
        now.setHours(0, 0, 0, 0);
        return now;
    }

    public static convertTimetoDateString(date:Date,timeStr:string){
        const [ h, m ] = timeStr.split(":");
        const ms = date.setHours(+h,+m,0,0);
        return new Date(ms)
    }

    public static getYesterday(date: Date, day: number) {
        var timeStamp = date.getTime();
        const yesterdayTimeStamp = timeStamp - day * 24 * 60 * 60 * 1000;
        return new Date(yesterdayTimeStamp);
    }

    public static getLastHour(date: Date, hour: number) {
        const t = date.getTime() - hour * 60 * 60 * 1000;
        return new Date(t);
    }

    public static getZeroHour(date: Date) {
        return new Date(new Date(date).setHours(0, 0, 0, 0));
    }

    public static getLastDay(date: Date, day: number) {
        const t = date.getTime() - day * 24 * 60 * 60 * 1000;
        return new Date(t);
    }

    public static getDiffHour(date1: Date, date2: Date) {
        return (date2.getTime() - date1.getTime()) / (60 * 60 * 1000);
    }

    public static integerWithCommas(x: number) {
        if (x === undefined) return "";
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    public static br(text: string) {
        if (text === undefined) return "";
        return text.split('\n').map((line, i) => (
            <span key={i}>
                {line}
                <br />
            </span>
        ))
    }

    public static convertLink(text: string) {
        if (text === undefined || !text) return "";
        var res: any[] = [];
        var texts = text.split(/(https?:\/\/[\w!\?/\+\-_~=;\.,\*&@#\$%\(\)'\[\]]+)/)
        for (var i = 0; i < texts.length; i++) {
            var v = texts[i];
            if (i & 1) {
                res.push(<a key={i} href={v}>
                    {v}
                </a>)
            } else {
                var vs = v.split('\n');
                console.log(vs);
                var isV = false;
                for (var j = 0; j < vs.length; j++) {
                    if (vs[j] !== '') {
                        if (isV) {
                            res.push(<span key={"o" + j}><br /></span>)
                            res.push(<span key={"p" + j}>
                                {vs[j]}
                            </span>)
                        } else {
                            res.push(<span key={"p" + j}>
                                {vs[j]}
                            </span>)
                        }
                        isV = true;
                    } else {
                        res.push(<span key={"b" + j}><br /></span>);
                    }
                }
            }
        }
        return res;
    }

    

    public static fileAcceptType() {
        const fileTypeAcceptList = [
            'image/jpeg',
            'image/png',
            'application/pdf',
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.ms-powerpoint',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            '.csv',
        ];
        return fileTypeAcceptList.join(', ');
      }

    public static numberWithCommas(x:string) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

}

//target="_blank"

/* return text
.split(/(https?:\/\/[\w!\?/\+\-_~=;\.,\*&@#\$%\(\)'\[\]]+)/)
.map((v, i) =>
  i & 1 ? (
    <a key={i} href={v}>
      {v}
    </a>
  ) : (
    v.split('\n').map((line,j)=>{
      <span key={j}>
      {line}
      <br/>
      </span>
    })
  )
)*/