import { useEffect, useState } from "react"
import MainMenu from "../menu"
import LogRequestSuggestion from "./component/LogRequestSuggestion"
import LogRequestTab from "./component/LogRequestTab"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { queryclear } from "../../../store/search/actions"
import loadinglogo from '../../../img/loading.gif';
import { Util } from "../../../api/util"
import { SearchPanel, TableHeaderBlack } from "../../../style/tableStyle"
import { useForm } from "react-hook-form"
import { CustomPage } from "../../share/CustomPage"
import Sort from "../../share/Sort"
import { LogRequstResponse, ResponsibleDept } from "../../../model/response/log-request"
import { listLogRequest } from "../../../store/logRequest/action"
import { store } from "../../../store/store"
import { AlertContainer, alert } from 'react-custom-alert';
import PartnerLoadingModal from "../../partner/PartnerLoadingModal"
import { KnowledgeDepartmentResponse, ManageKnowledgeResponse } from "../../../model/response/manage-knowledge"
import { Tooltip } from 'antd';
import { MultiSelectComponent } from "../../tab/multi-select-component"
import { getDeptId } from "../../../store/auth/selectors"
import { listKnowledgeDepartment } from "../../../store/editpartner/actions"
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import { Button } from "react-bootstrap"
import SmsIcon from '@material-ui/icons/Sms';

type Props = {}

const LogRequest = (props: Props) => {
    const [error, setError] = useState('');
    const [init, setInit] = useState(1);
    const [initDepartment, setInitDepartment] = useState(0);
    const {responsibleDepIdsSearch,keywordSearch,pageSearch,pageSizeSearch,sortBySearch,sortTypeSearch} = window.sessionStorage.getItem("searchLogRequest")? JSON.parse(window.sessionStorage.getItem("searchLogRequest") as any):"{responsibleDepIdsSearch:[],keywordSearch:'',pageSearch:'',pageSizeSearch:'',sortBySearch:'',sortTypeSearch:''}" as any;
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const deptId = useSelector(getDeptId);
    const [responsibleDepIds, setResponsibleDepIds] = useState<any[]>(responsibleDepIdsSearch?responsibleDepIdsSearch:[]);
    const [partnerName, setPartnerName] = useState<string | undefined>(keywordSearch?keywordSearch:undefined);
    const [logRequestItem, setLogRequestItem] = useState<LogRequstResponse[]>([]);
    const [departmentItem, setDepartmentItem] = useState<KnowledgeDepartmentResponse[]>([])
    const [page, setPage] = useState(pageSearch?pageSearch:0);
    const [size, setSize] = useState(pageSizeSearch?pageSizeSearch:10);
    const [sortBy, setSortBy] = useState(sortBySearch?sortBySearch:undefined);
    const [sortType, setSortType] = useState<'asc' | 'desc'>(sortTypeSearch?sortTypeSearch:'desc');
    const [totalElement, setTotalElement] = useState<number>(0);
    const { control, handleSubmit, formState: { errors }, reset, getValues, register } = useForm();

    const handleInitPage = () => {
        history.push("/admin/log-request");
    }

    const handleHistoryPage = () => {
        history.push("/admin/log-request-history");
    }

    useEffect(() => {
        const unsubscribe = store.subscribe(() => {
            let res = store.getState().SearchReducer;
            if (res.isLoading) {
                setError('');
                setLoading(true);
            } else if ((res.isLoaded)) {
                if (res.data != null) {
                    if (res.table === 'list_knowledge_department') {
                        setDepartmentItem(res.data);
                        setInitDepartment(1)
                        
                        if (responsibleDepIds.length>0||partnerName||page!==0) {
                            var sort = sortBy&&sortType? sortBy + ',' + sortType : undefined;
                            dispatch(listLogRequest(responsibleDepIds,partnerName,undefined,undefined,undefined,undefined,page,size,sort,"NEW"))
                        } else {
                            let department = findDepartmentSet(res.data);
                            dispatch(listLogRequest(department, undefined, undefined, undefined, undefined, undefined, 0, size, undefined,"NEW"))
                        }
                       
                    } else if (res.table === "log_request") {
                        setLogRequestItem(res.data.elements);
                        setTotalElement(res.data.totalElements);
                        setInit(1);
                    }
                    setError('');
                    setLoading(false);
                }
            } else if (res.isUpdating) {
                setError('');
                setLoading(true);
            } else if (res.isUpdated) {
                setError('');
                setLoading(false);
                dispatch(queryclear());
                alert({ message: 'Saved', type: 'success' });
            } else if (res.isFailed) {
                setError(res.data.message);
                setLoading(false);
                alert({ message: res.data.message, type: 'error' });
            }
        })
        dispatch(listKnowledgeDepartment());
        removeSession();
        return unsubscribe;
    }, []);

    const removeSession = () => {
        sessionStorage.removeItem("searchDetails");
        sessionStorage.removeItem("searchPartnerDetails");
        sessionStorage.removeItem("searchSurveyDetails");
        sessionStorage.removeItem("searchTrainerDetails");
        sessionStorage.removeItem("searchManageQuestion");
        // sessionStorage.removeItem("searchLogRequest");
        sessionStorage.removeItem("searchLogRequestHistory");
      }

    const findDepartmentSet = (knowledgeDepartment:KnowledgeDepartmentResponse[]) => {
        let res = undefined;
        if(knowledgeDepartment){
            knowledgeDepartment.forEach(element => {
                if(element.id == deptId){
                    setResponsibleDepIds([{label: element.name,value: element.id}])
                    res = [{label: element.name,value: element.id}];
                    return;
                }
            });
        }else{
            return undefined
        }
        return res;
    }

    const createDepartment = () => {
        var res: any[] = [];
        if (departmentItem) {
            for (var i = 0; i < departmentItem.length; i++) {
                res.push({ label: departmentItem[i].name, value: departmentItem[i].id });
            }
        }
        return res;
    }

    const handleClear = () => {
        setPage(0);
        setSize(10);
        setSortBy(undefined);
        setPartnerName("");
        setResponsibleDepIds([]);
        dispatch(queryclear());
        dispatch(listLogRequest(undefined,undefined,undefined,undefined,undefined,undefined,0,10,undefined,"NEW"));
        window.sessionStorage.setItem("searchLogRequest",JSON.stringify({responsibleDepIdsSearch:[],keywordSearch:'',pageSearch:0,pageSizeSearch:10,sortBySearch:undefined,sortTypeSearch:undefined}))
    }

    const onSubmit = () => {
        setPage(0);
        setSortBy(undefined);
        dispatch(queryclear());
        dispatch(listLogRequest(responsibleDepIds,partnerName,undefined,undefined,undefined,undefined,0,size,undefined,"NEW"))
        window.sessionStorage.setItem("searchLogRequest",JSON.stringify({responsibleDepIdsSearch:responsibleDepIds,keywordSearch:partnerName,pageSearch:0,pageSizeSearch:size,sortBySearch:undefined,sortTypeSearch:undefined}))
    }

    const onPageChange = (value: number) => {
        setPage(value);
        let sort = sortBy ? sortBy + ',' + sortType : undefined;
        dispatch(listLogRequest(responsibleDepIds,partnerName,undefined,undefined,undefined,undefined,value,size,sort,"NEW"));
        window.sessionStorage.setItem("searchLogRequest",JSON.stringify({responsibleDepIdsSearch:responsibleDepIds,keywordSearch:partnerName,pageSearch:value,pageSizeSearch:size,sortBySearch:sortBy,sortTypeSearch:sortType}))
    }

    const onPageSizeChange = (value: number) => {
        setPage(0);
        setSize(value);
        let sort = sortBy ? sortBy + ',' + sortType : undefined;
        dispatch(listLogRequest(responsibleDepIds,partnerName,undefined,undefined,undefined,undefined,0,size,sort,"NEW"));
        window.sessionStorage.setItem("searchLogRequest",JSON.stringify({responsibleDepIdsSearch:responsibleDepIds,keywordSearch:partnerName,pageSearch:0,pageSizeSearch:value,sortBySearch:sortBy,sortTypeSearch:sortType}))
    }

    const onSort = (sortName: string, newSortType: 'asc' | 'desc') => {
        setSortBy(sortName);
        setSortType(newSortType);
        dispatch(listLogRequest(responsibleDepIds,partnerName,undefined,undefined,undefined,undefined,page,size,sortName + ',' + newSortType,"NEW"));
        window.sessionStorage.setItem("searchLogRequest",JSON.stringify({responsibleDepIdsSearch:responsibleDepIds,keywordSearch:partnerName,pageSearch:page,pageSizeSearch:size,sortBySearch:sortName,sortTypeSearch:newSortType}))
    }

    const getKnowledgeDescription = (knowledgeList: ManageKnowledgeResponse[]) => {
        if(knowledgeList && knowledgeList.length>0){
            return knowledgeList && knowledgeList.map(x => (
                <div>
                    <div>{x.categoryName}</div>
                    <div><ul>
                        {x.subCategories.map(subCat => (
                            <li>{subCat.subCategoryName}</li>
                        ))}
                    </ul></div>
                </div>
            ))
        }else{
            return "-"
        }
    }

    const modalBodyLoadingData = () => {
        return <span>กรุณารอสักครู่</span>
    }
    const handleOpenPartnerProfile = (registrationId: number) => {
        history.push("/admin/log-request/"+ registrationId);
    }

    return (
        <div>
            <MainMenu>
                {(init + initDepartment === 2) &&
                    <>
                        <div className="query-wrapper">
                            <br />
                            <div className="yellow-button" style={{ textAlign: "left", borderTopLeftRadius: "30px", borderTopRightRadius: "30px", height: "70px", paddingTop: "15px", paddingLeft: "15px" }}><h4 className="header">การจัดการ Log</h4></div>
                            <br />
                            <LogRequestSuggestion />
                            <br />
                            {LogRequestTab(1, true, handleInitPage, handleHistoryPage)}
                            <div style={{ background: "white", padding: "20px", borderRadius: "5px" }}>
                                <form>
                                    <SearchPanel>
                                        <div className='row'>
                                            <div className='col-6'>
                                                <label className="col-form-label">ชื่อองค์กร</label>
                                                <input className="form-control" type="text" name="partnerName" id="input-text-partner-name" onChange={e => setPartnerName(e.target.value)} value={partnerName}></input>
                                            </div>
                                            <div className='col-6'>
                                                <label className="col-form-label">ฝ่ายงานที่รับผิดชอบ</label>
                                                <MultiSelectComponent options={createDepartment()}
                                                    value={responsibleDepIds}
                                                    onChange={setResponsibleDepIds} />
                                            </div>
                                        </div>
                                    </SearchPanel>
                                </form>
                                <div className="row mt-3" style={{ justifyContent: "center" }}>
                                    <button type="button" className="btn btn-outline-primary" style={{ width: "150px" }}
                                        onClick={() => handleClear()} >
                                        Clear
                                    </button>&nbsp;
                                    <button type="submit" className="btn btn-primary" style={{ width: "150px" }} disabled={loading} onClick={onSubmit}>
                                        <div >
                                            {(loading) &&
                                                <div style={{ textAlign: "center" }}><img src={loadinglogo} width="30" height="30" className="d-inline-block align-top" alt="" /></div>
                                            }
                                            {(!loading) &&
                                                <>Search</>
                                            }
                                        </div>
                                    </button>
                                </div>
                            </div>
                            <br/>
                            <CustomPage current={page} pageSize={size} total={totalElement} onPageChange={onPageChange} onPageSizeChange={onPageSizeChange} loading={loading}>
                                    <TableHeaderBlack striped hover className='mt-2'>
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th className='text-left' style={{ verticalAlign: "top" }}>
                                                    <Sort label='วันที่-เวลา' currentSort={sortBy} sortName='createDate' handleClick={onSort} sortType={sortType} />
                                                </th>
                                                <th className='text-left' style={{ verticalAlign: "top",width:'35%' }}>องค์กร</th>
                                                <th className='text-left' style={{ verticalAlign: "top" }}>ฝ่ายงานที่รับผิดชอบ</th>
                                                <th style={{ verticalAlign: "top" }}>จำนวนรูปแบบ</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {logRequestItem && logRequestItem.map((item: LogRequstResponse, index: number) => (
                                                <tr key={'registrationId' + item.registrationId}>
                                                    <td>
                                                        {(item.partnerAnswered) && <ErrorOutline style={{color:"#F9B232", height:"25px", width:"25px"}} />}
                                                    </td>
                                                    <td className='text-left'>{Util.datetimetostr(new Date(item.registrationDate))}</td>
                                                    <td className='text-left'><a className="link" onClick={()=>handleOpenPartnerProfile(item.registrationId)}  target="_blank" >{item.partnerName}</a></td>
                                                    <td className='text-left'>
                                                        {item.responsibleDeptList && item.responsibleDeptList.map((dept: ResponsibleDept, i: number) => (
                                                            <span>
                                                                {(dept.checked) && 
                                                                    <span style={{margin:"0px 2px 0px 2px", padding:"1px 12px 1px 12px", borderRadius:"5px", border:"1px solid #2878CC", background:"#D5F0F6", fontSize:"17px", color:"#2878CC"}}>{dept.shortName}</span>
                                                                }
                                                                {(!dept.checked) && 
                                                                    <span style={{margin:"0px 2px 0px 2px", padding:"1px 12px 1px 12px", borderRadius:"5px", border:"1px solid #c9d2d4",  background:"#FFFFFF", fontSize:"17px", color:"#c9d2d4"}}>{dept.shortName}</span>
                                                                }
                                                            </span>
                                                        ))}
                                                    </td>
                                                    <td className='text-center'>
                                                        <Tooltip placement="left" title={getKnowledgeDescription(item.knowledgeList)}>
                                                            <Button className='light-gray-button-2 btn-small'><SmsIcon className="blue" style={{ cursor: 'pointer' }} ></SmsIcon><span style={{ fontSize: 18, marginLeft: 4, color: 'black' }}>{item.knowledgeList?.length}</span></Button>
                                                        </Tooltip>
                                                    </td>
                                                </tr>
                                            ))}
                                            {logRequestItem.length == 0 && (
                                                <tr>
                                                    <td colSpan={10}>ไม่มีรายการ</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </TableHeaderBlack>
                                </CustomPage>
                                <br />

                        </div>
                    </>
                }
            </MainMenu>
            
            <AlertContainer floatingTime={3000} />
        </div>
    )
}

export default LogRequest
