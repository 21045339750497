import { useEffect, useState } from 'react';
import { useDispatch, useStore, useSelector} from 'react-redux';
import { useHistory,useParams } from 'react-router-dom';
import { Util } from '../../api/util';
import MainMenu  from './menu';
import Footer from './adminfooter';
import ClassTab from './classtab';
import { Button, Table } from 'react-bootstrap';
import loadinglogo from '../../img/loading.gif';
import excellogo from '../../img/excel.png';
import { EXPORT_ASSIGNMENT_URL } from '../../api/assignment-api';
import { AssignmentAPI } from '../../api/assignment-api';
import { listcourse,listassignment,getclass } from '../../store/editassignment/actions';
import { queryclear } from '../../store/search/actions';
import { NOT_FOUND_LABEL } from '../../api/label';
import { createPage } from '../tab/pagetab';
import { StudentType } from '../../api/define';
import { MultiSelectComponent } from '../tab/multi-select-component';
import { getAccessToken } from '../../store/auth/selectors';

const EditAssignment = () => {
    const token = useSelector(getAccessToken);
    const [initCourse,setInitCourse] = useState(0)
    const [initClass,setInitClass] = useState(0)
    const [init,setInit] = useState(0)
    
    const [page,setPage] = useState(0)
    const [limit,setLimit] = useState(10)
    const [name,setName] = useState("")
    const [course,setCourse] = useState<any[]>([])
    const [resultStatus,setResultStatus] = useState<any[]>([])
    
    const [loading,setLoading] = useState(false)
    const [items, setItems] = useState<any>({});
    const [courseItem,setCourseItem] = useState<any>({});
    const [classItem,setClassItem] = useState<any>({});
    const [error, setError] = useState('');
    const store = useStore();
    const dispatch = useDispatch();
    const history = useHistory();
    let { id } = useParams<{ id: string}>();

    useEffect(() => {
        const unsubscribe = store.subscribe(() => {
            let res = store.getState().SearchReducer;
            if (res.isLoading) {
                setError('');
                setLoading(true);
            } else if (res.isLoaded) {
                setError('');
                setLoading(false);
                if (res.data!=null) {
                    if (res.table==="list_course") {
                        setInitCourse(1);
                        setCourseItem(res.data);
                    } else if (res.table==="class") {
                        setInitClass(1);
                        setClassItem(res.data);
                    } else {    
                        setInit(1);
                        setItems(res.data); 
                    } 
                } else {
                    setError(NOT_FOUND_LABEL);
                }
            } else if (res.isNotFound) {
                setError(NOT_FOUND_LABEL);
                setItems([]);
                setLoading(false);
            } else if (res.isFailed) {
                setError(res.data.message);
                setItems([]);
                setLoading(false);
            }
        });
        dispatch(queryclear());
        dispatch(getclass(parseInt(id)));
        dispatch(listcourse(parseInt(id)));
        dispatch(listassignment(parseInt(id),AssignmentAPI.getParam(name,course,resultStatus),0,limit))
        return unsubscribe;
    }, [])

    const handleClear = () => {
        setName("");
        setCourse([]);
        setResultStatus([]);
    }

    const handleExcel = () => {    
        window.open(EXPORT_ASSIGNMENT_URL +"?class_id="+id+"&"+AssignmentAPI.getParam(name,course,resultStatus)+"&token="+encodeURIComponent(token)); 
    }
  
    const handleSubmit = () => {
        setPage(0);
        dispatch(queryclear());
        dispatch(listassignment(parseInt(id),AssignmentAPI.getParam(name,course,resultStatus),0,limit));
    }

    const handleLimit = (l: number) => {
        setLimit(l);
        dispatch(listassignment(parseInt(id),AssignmentAPI.getParam(name,course,resultStatus),page,l)); 
    }

    const handlePage = (l: number) => {
        setPage(l);
        dispatch(listassignment(parseInt(id),AssignmentAPI.getParam(name,course,resultStatus),l,limit));
    }

    const handleBack = () => {
        history.push("/admin/search/class");
    }
    
    const handleClass=()=>{
        history.push("/admin/class/"+id);
    }
    
    const handleStudent=()=>{
        history.push("/admin/class/"+id+"/student");
    }

    const handleDoc=()=>{
        history.push("/admin/class/"+id+"/doc");
    }

    const handleHistory=()=>{
        history.push("/admin/class/"+id+"/history");
    }
    
    const handleAssignment =(class_student_id: number)=>{
        history.push("/admin/assignment/"+id+"/"+class_student_id);
    }

    const handleResult=()=>{
        history.push("/admin/class/"+id+"/result");
    }
    const handleSurvey=()=>{
        history.push("/admin/class/"+id+"/survey");
    }

    const createItem = () => {
        var res:any[]=[];
        if (items.items!==undefined) {
            for (var i=0;i<items.items.length;)
            {
                var result=[];
                var item=items.items[i];
                var email=items.items[i].email;
                while ((i<items.items.length) && (items.items[i].email===email))
                {
                    var it=items.items[i];
                    if (it.courseName!==null) {
                        if (result.length!==0) result.push(<br/>);
                        result.push(it.courseName+": "+(it.result===null?"N/A":(it.result?"ส่งครบถ้วน":"ส่งไม่ครบ")));
                    }
                    i++;
                }
                res.push(<tr key={i}>
                    <td className='text-left' id={item.classStudentId} onClick={e=>{handleAssignment(parseInt(e.currentTarget.id))}} style={{textDecoration:"underline",cursor:"pointer"}}> {item.firstName} </td>
                    <td className='text-left'> {item.lastName} </td>
                    <td className='text-left'> {item.partner} </td>
                    <td className='text-left'> {item.email} </td>
                    <td className='text-left'> {result}</td>
                    </tr>);   
            }
        }
        return res;
    }

    const getTotalItem = () => {
        if (items.items===undefined) return 0;
        return items.totalItem;
    }
    
    const getTotalPage = () => {
        if (items.items===undefined) return 0;
        return items.totalPage;
    }

    const createCourseSearchFilter = () => {
        var res:any[]=[];
        for (var i=0;i<courseItem.length;i++)
        {
            res.push({label:courseItem[i].shortName+":" + courseItem[i].name,value:courseItem[i].classCourseId});
        }
        return res;
    }

    const createStatusSearchFilter = () => {
        var res:any[]=[];
        res.push({label:"ส่งครบถ้วน",value:true});
        res.push({label:"ส่งไม่ครบ",value:false});
        return res;
    }

    return (<>
    <MainMenu>
    <div>
    <div className="query-wrapper">
    <br/>
    <div className="yellow-button" style={{textAlign:"left" ,borderTopLeftRadius:"30px", borderTopRightRadius:"30px", height:"70px",paddingTop:"15px",paddingLeft:"15px"}}><h4 className="header">รอบการเรียน</h4></div>
    { (initCourse+initClass+init===3)  &&
    <>
    <div className="light-gray-button" style={{ borderBottomLeftRadius: '5px',borderBottomRightRadius: '5px',paddingLeft:"20px",paddingRight:"20px",paddingBottom:"20px",textAlign:"left"}}>
        <div className="row">
            <div className="col-4">                           
                <label className="col-form-label">โครงการ</label><br/>
                <input className="form-control" type="text" value={classItem.code+":"+classItem.name} readOnly={true}/>
            </div>
            <div className="col-1">                          
                <label className="col-form-label">รอบ</label><br/>
                <input className="form-control" type="text" value={classItem.session} readOnly={true}/>
            </div>
            <div className="col-2">                          
                <label className="col-form-label">วันที่เริ่ม</label>
                <input className="form-control" type="text" value={Util.datetostr(new Date(classItem.fromDate))} readOnly={true}/>
            </div>
            <div className="col-2">                           
                <label className="col-form-label">วันที่สิ้นสุด</label>
                <input className="form-control" type="text" value={Util.datetostr(new Date(classItem.toDate))} readOnly={true}/>
            </div>
            <div className="col-3">
                <label className="col-form-label">ฝ่ายผู้รับผิดชอบโครงการ</label><br/>
                <input className="form-control" type="text" value={classItem.organizer} readOnly={true}/>
            </div>
        </div>
    </div>
    <br/>
    { ClassTab(4,true,true,true,handleClass,handleStudent,handleDoc,null,handleHistory,handleResult,handleSurvey) }
    <br/>
    { (classItem.studentType===StudentType.NUMBER) &&
        <>ผู้เรียนเป็นแบบระบุจำนวน</>
    }
    { (classItem.studentType===StudentType.LIST) &&
    <>
    <div  className="light-gray-button" style={{position:"relative",textAlign:"left",padding:"20px",borderRadius:"5px"}}>
        <div className="row" style={{paddingRight:"40px",justifyContent:"center"}}>
            <div className="col-1" style={{textAlign:"right",paddingTop:"5px"}}>
            <label>ผู้เรียน</label>
            </div>
            <div className="col-2">
            <input className="form-control" value={name} onChange={(e)=>{setName(e.target.value)}} style={{width:"200px"}}/>
            </div>
            <div className="col-1" style={{textAlign:"right",paddingTop:"5px"}}>
            <label>หลักสูตร</label>
            </div>
            <div className="col-3">
            <MultiSelectComponent
                options={createCourseSearchFilter()}
                value={course}
                onChange={setCourse} 
            />
            </div>
            <div className="col-2" style={{textAlign:"right",paddingTop:"5px"}}>
            <label>สถานะงานที่รับมอบหมาย</label>
            </div>
            <div className="col-2">
            <MultiSelectComponent
                options={createStatusSearchFilter()}
                value={resultStatus}
                onChange={setResultStatus}
            />
            </div>
        </div>
    </div>
    <br/>
    <div className="row" style={{justifyContent:"center"}}>
    <button type="button" className="btn btn-outline-primary" style={{width:"150px"}}
            onClick={() => handleClear()} >
            Clear
    </button>&nbsp;
    <button type="button" className="btn btn-primary" style={{width:"150px"}}
                onClick={() => handleSubmit()} disabled={loading}>
                <div>
                { (loading) &&
                    <div  style={{ textAlign: "center" }}><img src={loadinglogo} width="30" height="30" className="d-inline-block align-top" alt="" /></div>
                }
                { (!loading) &&
                    <>Search</>
                }
                </div>
    </button>
    </div>
    <br/>
    <div className="row">
                <div className="col-5" style={{justifyContent:"left",textAlign:"left",marginTop:"auto",marginBottom:"auto"}}>
                จำนวนทั้งหมด {Util.integerWithCommas(getTotalItem())} รายการ 
                </div>
                <div className="col-1">                
                </div>
                <div className="col-6 row" style={{justifyContent:"right",paddingRight:"0px"}}>
                    <div style={{textAlign:"right",marginTop:"auto",marginBottom:"auto"}}>จำนวนรายการต่อหน้า</div>&nbsp;
                    <select className="form-control" style={{width:"80px"}} name="page" value={limit} onChange={e=>handleLimit(parseInt(e.target.value))}>
                            <option value="0">All</option>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                    </select>&nbsp;
                    <Button type="button" className="btn btn-success green-button" style={{width:"50px"}}
                            onClick={() => handleExcel()} >
                            <img src={excellogo} height="32px" alt="excel" style={{marginLeft:"-7px"}}/>
                    </Button>
                </div>
        </div>
        { //history
            ((items.items!==undefined) && (items.items.length >= 1)) &&
            <>
            <br/>
            <Table striped hover>
                <thead className="black-button">
                    <tr>
                        <th className='text-left'>ชื่อผู้เรียน</th>
                        <th className='text-left'>นามสกุล</th>                                          
                        <th className='text-left'>ชื่อองค์กร</th>
                        <th className='text-left'>e-Mail</th>
                        <th className='text-left'>Assignment</th>
                    </tr>
                </thead>
                <tbody style={{fontSize:"24px"}}>
                    {createItem() }
                </tbody>
            </Table>
            <br/>

            <div className="row" style={{justifyContent:"center",position:"relative"}}>
                { createPage(page,getTotalPage(),handlePage) }
                <div className="col-5" style={{justifyContent:"left",textAlign:"left",position:"absolute",left:"0px"}}>
                    จำนวนทั้งหมด {Util.integerWithCommas(getTotalItem())} รายการ 
                </div>
            </div>
            </>
        }
    </>
    }
    </>
    }
    <br/>
    { (((initCourse+initClass+init<3) || (loading)) && (error==="")) &&
        <div style={{ textAlign: "center" }}><img src={loadinglogo} width="30" height="30" className="d-inline-block align-top" alt="" /></div>
    }
    { (error!=="") &&
        <div style={{ color: 'red', textAlign: "center" }}>{error}</div>
    }
    { 
        <div className="row">
            <div className="col-10"></div>
            <div className="col-2" style={{paddingRight:"0px"}}>
            <button type="button" className="btn btn-outline-primary" style={{width:"120px"}}
                    onClick={() => handleBack()} >
                    Back
            </button>
            </div>
        </div>
    }
    </div>
    <br/>
    <Footer/>
    </div>
    </MainMenu>
    
    </>)
}

export default EditAssignment;
