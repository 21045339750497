import { useEffect, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useHistory } from 'react-router-dom';
import MainMenu  from './menu';
import Footer from './adminfooter';
import { Button, Modal, Table } from 'react-bootstrap';
import { listPartnerWithOutPage, deletetrainer, listtrainer } from '../../store/trainer/actions';
import  loadinglogo  from '../../img/loading.gif';
import excellogo from '../../img/excel.png';
import AddIcon  from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';
import { createPage } from '../tab/pagetab';
import { NOT_FOUND_LABEL } from '../../api/label';
import { Util } from '../../api/util';
import { queryclear } from '../../store/search/actions';
import { EXPORT_TRAINER_URL, TrainerAPI } from '../../api/trainer-api';
import { MultiSelectComponent } from '../tab/multi-select-component';
import { getAccessToken } from '../../store/auth/selectors';

const Trainer= () => {
    const token=useSelector(getAccessToken);
    const {nameSearch,partnerSearch,pageSearch,pageSizeSearch} = window.sessionStorage.getItem("searchTrainerDetails")? JSON.parse(window.sessionStorage.getItem("searchTrainerDetails") as any):"{nameSearch:'',partnerSearch:[],pageSearch:'',pageSizeSearch:''}" as any;
    const [init,setInit] = useState(0)
    const [name, setName] = useState(nameSearch?nameSearch:"")
    const [partner, setPartner] = useState<any[]>(partnerSearch?partnerSearch:[])
    const [deleteId,setDeleteId] = useState("")
    const [page,setPage] = useState(pageSearch?pageSearch:0)
    const [limit,setLimit] = useState(pageSizeSearch?pageSizeSearch:10)
    const [totalItem,setTotalItem] = useState(0)
    const [totalPage,setTotalPage] = useState(0)
    const [loading,setLoading] = useState(false)
    const [items, setItems] = useState<any[] |null>(null)
    const [partnerItems, setPartnerItems] = useState<any[]>([])
    const [error, setError] = useState('');
    const store = useStore();
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        const unsubscribe = store.subscribe(() => {
            let res = store.getState().SearchReducer;
            if (res.page!=="trainer") return ;
            if (res.isLoading) {
                setError('');
                setLoading(true);
            } else if (res.isLoaded) {
                setError('');
                setLoading(false);
                if (res.data!=null) {
                    if (res.table==="partner_with_out_page") {
                        setInit(1);
                        setPartnerItems(res.data);
                    } else { 
                        setItems(res.data.trainerResponseList);
                        setTotalPage(res.data.totalPage);
                        setTotalItem(res.data.totalItem);
                    } 
                } else {
                    setError(NOT_FOUND_LABEL);
                }
            } else if (res.isNotFound) {
                setError(NOT_FOUND_LABEL);
                setItems([]);
                setLoading(false);
            } else if (res.isUpdated) {
                dispatch(queryclear);
            } else if (res.isFailed) {
                setError(res.data.message);
                setItems([]);
                setLoading(false);
            }
        });
        dispatch(listPartnerWithOutPage());
        if (name || partner || page !== 0) {
            dispatch(listtrainer(name,partner,page,limit));
        }
        removeSession();
        window.scroll(0,0);
        return unsubscribe;
    }, [])

    const removeSession = () => {
        sessionStorage.removeItem("searchDetails");
        sessionStorage.removeItem("searchPartnerDetails");
        sessionStorage.removeItem("searchSurveyDetails");
        // sessionStorage.removeItem("searchTrainerDetails");
        sessionStorage.removeItem("searchManageQuestion");
        sessionStorage.removeItem("searchLogRequest");
        sessionStorage.removeItem("searchLogRequestHistory");
      }

    const handleClear = () => {
        setName("");
        setPartner([]);
        setPage(0);
        setLimit(10);
        setTotalItem(0);
        setTotalPage(0);
        setItems(null);
        window.sessionStorage.setItem("searchTrainerDetails",JSON.stringify({nameSearch:'',partnerSearch:[],pageSearch:0,pageSizeSearch:10}))
        dispatch(queryclear());
    }

    const handleSubmit = () => {
       setPage(0);
       dispatch(listtrainer(name,partner,0,limit));
       window.sessionStorage.setItem("searchTrainerDetails",JSON.stringify({nameSearch:name,partnerSearch:partner,pageSearch:0,pageSizeSearch:limit}))
    }

    const handleLimit = (l: number) => {
        setLimit(l);
        setPage(0);
        dispatch(listtrainer(name,partner,0,l));
        window.sessionStorage.setItem("searchTrainerDetails",JSON.stringify({nameSearch:name,partnerSearch:partner,pageSearch:0,pageSizeSearch:l}))
     }

    const handlePage = (l: number) => {
        setPage(l);
        dispatch(listtrainer(name,partner,l,limit));
        window.sessionStorage.setItem("searchTrainerDetails",JSON.stringify({nameSearch:name,partnerSearch:partner,pageSearch:l,pageSizeSearch:limit}))
    }

    const handleAdd = () => {
        history.push("/admin/trainer");
    }

    const handleEdit = (id: number) => {
        history.push("/admin/trainer/"+id);
    }

    const handleExcel = () => {
        window.open(EXPORT_TRAINER_URL +"?"+TrainerAPI.getParam(name,partner)+"&token="+encodeURIComponent(token)); 
    }

    const handleDelete = (id: number) => {
        setDeleteId(id.toString());
    }

    const handleDeleteClose = () => {
        setDeleteId("");
    }

    const handleDeleteConfirm = () =>{
        dispatch(queryclear());
        dispatch(deletetrainer(parseInt(deleteId)));
        if ((items!==undefined) && (items!==null))
        {
            var newItem=[];
            for (var i=0;i<items.length;i++)
            {
                if (items[i].id!==parseInt(deleteId)) 
                {
                    newItem.push(items[i]);
                }
            }
            setItems(newItem);
        }
        setDeleteId("");
    }

    const createBr = (n: number) => {
        var res:any[]=[];
        for (var j=0;j<n;j++) {
            res.push(<br/>);
        }   
        return res;
    }

    const createPartnerSearchFilter = () => {
        var res:any[]=[];
        if (partnerItems!==undefined) {
            for (var i=0;i<partnerItems.length;i++)
            {
                res.push({label:partnerItems[i].name,value:partnerItems[i].id});
            }
        }
        return res;
    }


    return (<>
        <MainMenu>
        { (init===1) &&
        <div>
        <div className="query-wrapper">
        <br/>
        <form>
            <div style={{ background: '#EEEEEE', borderRadius: '5px',paddingLeft:"15px", paddingRight:"15px", paddingBottom:'20px',textAlign:"left"}}>
                <div className="row">
                    <div className='col-2'></div>
                    <div className="col-4">                           
                        <label className="col-form-label">ชื่อ</label>
                        <input className="form-control" type="text" name="name" onChange={e=>setName(e.target.value) } value={name}></input>
                    </div>
                    <div className="col-4">                           
                        <label className="col-form-label">องค์กร</label>
                        <MultiSelectComponent options={createPartnerSearchFilter()}
                        value={partner}
                        onChange={setPartner} />
                    </div>
                    <div className='col-2'></div>
                </div>
              </div>
              <br/>
            <div className="row" style={{justifyContent:"center"}}>
                    <button type="button" className="btn btn-outline-primary" style={{width:"150px"}}
                        onClick={() => handleClear()} disabled={(loading)}>
                        Clear
                    </button>&nbsp;
                    <button type="button" className="btn btn-primary" style={{width:"150px"}}
                        onClick={() => handleSubmit()} disabled={loading}>
                        <div>
                        { (loading) &&
                            <div  style={{ textAlign: "center" }}><img src={loadinglogo} width="30" height="30" className="d-inline-block align-top" alt="" /></div>
                        }
                        { (!loading) &&
                            <>Search</>
                        }
                        </div>
                    </button>
            </div>
        </form>   
        <div className="row">
                <div className="col-5" style={{justifyContent:"left",textAlign:"left",marginTop:"auto",marginBottom:"auto"}}>
                จำนวนทั้งหมด {totalItem} รายการ 
                </div>
                <div className="col-1">                
                </div>
                <div className="col-6 row" style={{justifyContent:"right",paddingRight:"0px"}}>
                <div style={{textAlign:"right",marginTop:"auto",marginBottom:"auto"}}>จำนวนรายการต่อหน้า</div>&nbsp;
                <select className="form-control" style={{width:"80px"}} name="page" value={limit} onChange={e=>handleLimit(parseInt(e.target.value))}>
                        <option value="0">All</option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                </select>&nbsp;
                <Button type="button" className="btn btn-warning yellow-button"
                        onClick={() => handleAdd()} >
                        <AddIcon />
                        สร้างวิทยากร
                </Button>&nbsp;
                <Button type="button" className="btn btn-success green-button" style={{width:"50px"}}
                        onClick={() => handleExcel()} >
                        <img src={excellogo} height="32px" alt="excel" style={{marginLeft:"-7px"}}/>
                </Button>
                </div>
        </div>
        { ((!loading) && (items !== null) &&(items.length === 0) && (error==="")) &&
            <><br/><br/><div className="text-center">{NOT_FOUND_LABEL}</div></>
        }
        { (error!=="") &&
            <div  style={{ color: 'red', textAlign: "center" }}>{error}</div>
        }
        { ((items !== null) && (items.length >= 1)) &&
            <>
            <br/>
            <Table striped hover>
                <thead className="black-button">
                    <tr>
                        <th></th>
                        <th className='text-left'>ชื่อ</th>
                        <th className='text-left'>นามสกุล</th>
                        <th className='text-left'>องค์กร</th>
                        <th className='text-left'>e-Mail</th>
                        <th style={{width:"80px"}}></th>
                    </tr>
                </thead>
                <tbody style={{fontSize:"24px"}}>
                    {items.map((item, index) => {
                            return <><tr key={item.id}>
                                    <td></td>
                                    <td className='text-left'> {item.firstName} </td>
                                    <td className='text-left'> {item.lastName} </td>
                                    <td className='text-left'> {item.partner} </td>
                                    <td className='text-left'> {item.email} </td>
                                    <td> <div className="row"><div onClick={(e)=>handleEdit(item.id)}><EditIcon className="yellow pointer" ></EditIcon></div>&nbsp;<div onClick={(e)=>handleDelete(item.id)}><CancelIcon className="red pointer"></CancelIcon></div></div></td>
                                    </tr>
                                    </>
                        })}
                </tbody>
            </Table>
            <br/>
            <div className="row" style={{justifyContent:"center",position:"relative"}}>
                { createPage(page,totalPage,handlePage) }
                <div className="col-5" style={{justifyContent:"left",textAlign:"left",position:"absolute",left:"0px"}}>
                    จำนวนทั้งหมด {Util.integerWithCommas(totalItem)} รายการ 
                </div>
            </div>
            </>
        }
        </div>
        <br/>
        {   ((items === null) || (items.length < 5)) &&
            <>{ createBr((items === null)?8:8-items.length)} 
            </>
        }
        <Footer empty={((items === null) || (items.length === 0))}/>
        </div> 
        }
        { (init<1) && 
            <>        
            { (error==="") &&
                <div  style={{ textAlign: "center",width:"1200px"}}><img src={loadinglogo} width="30" height="30" className="d-inline-block align-top" alt="" /></div>
            }
            { (error!=="") &&
                <div  style={{ color: 'red', textAlign: "center" }}>{error}</div>
            }
            </>
        }
      </MainMenu>
        <Modal show={deleteId!==""} onHide={handleDeleteClose}>
        <Modal.Header closeButton>
          <Modal.Title>ยืนยันการลบ?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleDeleteClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteConfirm}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      </>)
}

export default Trainer;