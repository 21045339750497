import { Table } from "react-bootstrap";
import styled from "styled-components";
export const TableHeaderBlack = styled(Table)`
  border-radius: 5px;
  border-collapse: collapse;
  thead {
    background-color: black !important;
    color: white !important;
  }
  tbody {
    font-size:22px;
  }
`

export const SearchPanel = styled.div`
    background: #EEEEEE;
    border-radius: 5px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 20px;
    text-align: left;
`