import Carousel from 'better-react-carousel'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Knowledge } from '../../../../model/response/partner-category'
import iconArticle from '../../../../img/iconArticle.png'

type Props = {
    knowledge: Knowledge[] | undefined
}
const CardBoxBanner = styled.div`
    border-radius: 5px;
    flex-direction: column;
    width: 340px;
    border: 1px solid rgb(255 255 255 / 13%);
    background: #FFFFFF;
    box-shadow: 0px 4px 16px 0px #0000000D;
    height: 100%;
    transition: all 0.5s;
    &:hover{
        cursor: pointer;
        opacity: 0.6;
    }
    img.card-img-top{
        width: 340px;
        height: 190px;
        filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.1));
        border-radius: 5px 5px 0px 0px;
    }
    .card-text{
        .title{
            font-size: 22px;
            font-weight: 600;
        }
        
        line-height: 1.125; // Default is 1.2, but varies by browser
        min-height: 2lh; // 2 * 1em * 1.125
                      // (number of lines) * (font-size) * (line-height multiplier)
        padding: 1rem;
        .description {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }

    }
`

const PartnerArticle = (props: Props) => {
    useEffect(() => {
    }, [props.knowledge])
    const responsiveLayout = [
        {
            breakpoint: 1900,
            cols: 4,
            rows: 1,
            gap: 10,
            loop: false,
            autoplay: undefined
        },
        {
            breakpoint: 1920,
            cols: 5,
            rows: 1,
            gap: 10,
            loop: false,
            autoplay: undefined
        },
        {
            breakpoint: 3020,
            cols: 6,
            rows: 1,
            gap: 10,
            loop: false,
            autoplay: undefined
        },
        {
            breakpoint: 6200,
            cols: 6,
            rows: 1,
            gap: 10,
            loop: false,
            autoplay: undefined
        }
    ]
    const MyDot = (prop: any) => (
        <span
            style={{
                display: 'inline-block',
                marginTop: '30px',
                height: '8px',
                width: '8px',
                background: prop.isActive ? '#fcb034' : '#ccc',
                borderRadius: '50%'
            }}
        ></span>
    )
    const handleOpenLink = (link: string) => {
        if (link) {
            window.open(link, "_blank")
        }

    }
    return (
        <div>
            <div className='d-flex align-items-center'>
                <img src={iconArticle} height={30} />
                <h6 className='bold ml-2 my-auto'>บทความ</h6>
            </div>
            {props.knowledge && (
                <div className='mt-2'>
                    <Carousel responsiveLayout={responsiveLayout} hideArrow={false} showDots={true} dot={MyDot} style={{zIndex:1}}>
                        {props.knowledge.map(k => (
                            <Carousel.Item>
                                <CardBoxBanner onClick={() => handleOpenLink(k.link)}>
                                    <img className='card-img-top' src={k.imageS3} />
                                    <div className='card-text'>
                                        <div className='title'>{k.title}</div>
                                        <div className='p-text-dark-blue description'>{k.description}</div>
                                    </div>
                                </CardBoxBanner>
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </div>
            )}
        </div>
    )
}

export default PartnerArticle