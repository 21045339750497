export enum ActionType {
    QUERY = "QUERY",
    UPDATE = "UPDATE",
    DELETE = "DELETE"
}

export interface SearchState {
    isLoading: boolean;
    isLoaded: boolean;
    isUpdating: boolean;
    isUpdated: boolean;
    isFailed: boolean;
    data: any;
    table: string;
    page: string;
    action: ActionType;
}

export enum SearchActionType {
    QUERY_CLEAR = "CLEAR",
    QUERY_LOADING = "LOADING",
    QUERY_LOADED = "LOADED",
    QUERY_UPDATING = "UPDATING",
    QUERY_UPDATED = "UPDATED",
    QUERY_NOTFOUND = "NOTFOUND",
    QUERY_ERROR = "ERROR"
}
export type SearchAction = SearchActionType;

export interface SearchActionInterface {
    type: SearchAction;
    payload?: any;
    table: string;
    page: string;
    action: ActionType;
}
