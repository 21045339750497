import React, { useEffect, useState } from 'react'
import MainMenu from '../menu'
import ManagePartnerSuggestion from './component/ManagePartnerSuggestion';
import { Faculty, PartnerProfileListResponse, Transition } from '../../../model/response/partner-profile';
import PartnerPartSetting from './component/PartnerPartSetting';
import { useDispatch, useStore } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { listorganization } from '../../../store/editpartner/actions';
import PartnerGeneralInfo from './component/PartnerGeneralInfo';
import PartnerOrgPrivateCompany from './component/PartnerOrgPrivateCompany';
import PartnerOrgGoverment from './component/PartnerOrgGoverment';
import { getAdressInfomation, getFaculty } from '../../../store/publicPartner/action';
import { AdressResponse } from '../../../model/response/adress.model';
import { AlertContainer, alert } from 'react-custom-alert';
import PartnerOrgIndepandent from './component/PartnerOrgIndepandent';
import PartnerUniversity from './component/PartnerUniversity';
import PartnerVocation from './component/PartnerVocation';
import PartnerSchool from './component/PartnerSchool';
import PartnerWelfareBenefit from './component/PartnerWelfareBenefit';
import { getPartnerProfile, resendVerifyEmail, updatePartnerProfileByRegistraion } from '../../../store/partner/actions';
import PartnerInfo from './component/PartnerInfo';
import PartnerKnowledgeList from './component/PartnerKnowledgeList';
import { SingleSearch } from '../../tab/search-component';
import loadinglogo from '../../../img/loading.gif';
import { message } from 'antd';
import { PartnerKnowledgeRegistration } from '../../../model/request/partner-knowledge-regis';
import { queryclear } from '../../../store/search/actions';
import ManagePartnerSelectEmailCustomer from './component/ManagePartnerSelectEmailCustomer';
import PartnerHistory from './component/PartnerHistory';
type Props = {}

const ManagePartner = (props: Props) => {
    const [init, setInit] = useState(1);
    const [partnerProfileList, setPartnerProfileList] = useState<PartnerProfileListResponse>();
    const [orgTypeItem, setOrgTypeItem] = useState<any[]>([])
    const [province, setProvince] = useState<AdressResponse[]>([]);
    const [facultyItem, setFacultyItem] = useState<any[]>([])
    const [success, setSuccess] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [edit, setEdit] = useState(false);
    const [error, setError] = useState('');
    const store = useStore();
    const history = useHistory();
    let { id } = useParams<{ id: string }>();
    const [nextAction, setNextAction] = useState();
    const [nextActionItem, setNextActionItem] = useState<Transition[]>([]);
    const [messageApi, contextHolder] = message.useMessage();
    const dispatch = useDispatch();
    const [showSelectEmailCustomerModal, setShowSelectEmailCustomerModal] = useState(false);

    useEffect(() => {
        const unsubscribe = store.subscribe(() => {
            let res = store.getState().SearchReducer;
            console.log(res);
            if (res.isLoading) {
                setError('');
                setLoading(true);
            } else if ((res.isLoaded) && (!res.isUpdated)) {
                setError('');
                setLoading(false);
                if (res.data != null) {
                    if (res.table === "org_type") {
                        setOrgTypeItem(res.data);
                    } else if (res.table === "address-infomation") {
                        setProvince(res.data);
                    } else if (res.table === "faculty") {
                        setFacultyItem(res.data);
                    } else if (res.table === "get_partner_profile") {
                        setPartnerProfileList(res.data);
                        setNextActionItem(res.data.transitions)
                        setInit(1);
                    } else if (res.table === "partner_resend_verify_email") {
                        alert({ message: 'ส่ง e-Mail สำเร็จ', type: 'success' });
                    }
                }
            } else if (res.isUpdating) {
                setError('');
                setLoading(true);
            } else if (res.isUpdated) {
                setError('');
                setLoading(false);
                setSuccess(true);
                dispatch(queryclear());
                if (res.table == 'update_partner_profile') {
                    alert({ message: 'บันทึกข้อมูลสำเร็จ', type: 'success' });
                    if (res.data.moveTransition?.transition == 'บันทึกลงฐานข้อมูล') {
                        setTimeout(() => { history.push('/admin/log-request/merge-partner/' + id + '/' + res.data?.orgType + '/' + res.data?.orgGroup + '/' + res.data?.orgName) }, 300)
                    } else {
                        setTimeout(() => { history.push('/admin/log-request') }, 300)
                    }
                }


            } else if (res.isFailed) {
                setError(res.data.message);
                setLoading(false);
                alert({ message: res.data.message, type: 'error' });
            }

        })
        dispatch(listorganization(0, 0, true));
        dispatch(getAdressInfomation())
        dispatch(getFaculty())
        dispatch(getPartnerProfile(parseInt(id)));
        return unsubscribe;
    }, []);
    const getRequire = (value: string | boolean | undefined | any[] | null | number) => {
        return (submitted && (value == null || value == undefined || value == ''))
    }
    const validateRequired = (value: string | boolean | undefined | any[] | null | number) => {
        return (value == null || value == undefined || value == '')
    }
    const validateRequireBenefit = (check: boolean, value: string | boolean | undefined | any[] | null | number) => {
        if (check) {
            return (value == null || value == undefined || value == '')
        } else {
            return false
        }
    }
    const getOrgTypeName = (id: any) => {
        if (orgTypeItem) {
            let orgTypeName = orgTypeItem.find(x => x.id == id)?.name;
            return orgTypeName ? orgTypeName : null
        }
        return null

    }
    const getOrgGroupName = (id: any) => {
        let orgGroup = null
        if (orgTypeItem) {

            orgTypeItem.forEach(x => {
                let orgGroupName = x.orgGroup.find((y: any) => y.id == id);
                if (orgGroupName) {
                    orgGroup = orgGroupName.name
                }
            })

        }
        return orgGroup
    }
    const createNextAction = () => {
        var res: any[] = [];
        for (var i = 0; i < nextActionItem.length; i++) {
            res.push({ label: nextActionItem[i].transition, value: nextActionItem[i].id });

        }
        return res;
    }
    const getNextAction = () => {
        for (var i = 0; i < nextActionItem.length; i++)
            if (nextActionItem[i].id === nextAction) {
                return { label: nextActionItem[i].transition, value: nextActionItem[i].id };
            }
        return null;
    }
    const handleClear = () => {
        dispatch(getPartnerProfile(parseInt(id)));
    }
    const handleSubmit = () => {
        let messageError = [];
        setSubmitted(true);
        if (invalidPartSetting()) {
            messageError.push('กรุณาเลือกข้อมูลอย่างน้อย 1')
        }
        let moveTransition;
        if (validateRequired(nextAction)) {
            messageError.push('กรุณากรอก Next Action')
        } else {
            moveTransition = nextActionItem.find(x => x.id == nextAction)
        }
        let isSendFormToCustomer = moveTransition?.transition == 'ส่งฟอร์มให้กรอกข้อมูล'

        if (!isSendFormToCustomer && invalidGeneralInfo()) {
            messageError.push('กรุณากรอกข้อมูลทั่วไปให้ครบถ้วน')
        }
        if (isSendFormToCustomer && invalidGeneralInfoSendFormToCustomer()) {
            messageError.push('กรุณากรอกข้อมูลทั่วไปให้ครบถ้วน')
        }
        if (!isSendFormToCustomer && invalidOrganization()) {
            messageError.push('กรุณากรอกข้อมูลองค์กรให้ครบถ้วน')
        }
        if (!isSendFormToCustomer && invalidWelfareBenefit()) {
            messageError.push('กรุณากรอกข้อมูลสวัสดิการการออมให้ครบถ้วน')
        }
        if (!isSendFormToCustomer && invalidPartnerInfo()) {
            messageError.push('กรุณากรอกผู้ประสานงานอย่างน้อย 1 คน')
        }

        if (messageError.length > 0) {
            let content = <span className='text-left'>
                <b>กรุณากรอกแบบฟอร์มให้ครบ&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</b>
                {messageError.map(x => (<div>
                    - {x}
                </div>))}
            </span>
            messageApi.error({
                type: 'error',
                content: content,
                className: 'custom-class'
            });
        } else {
            const body = { ...partnerProfileList }
            body.moveTransition = moveTransition;
            if (body.moveTransition?.transition == 'ส่งฟอร์มให้กรอกข้อมูล') {
                if (body.partnerList?.some(x => x.verify)) {
                    setShowSelectEmailCustomerModal(true);
                } else {
                    messageError.push('กรุณา verify e-Mail อย่างน้อย 1')
                    let content = <span className='text-left'>
                        <b>กรุณากรอกแบบฟอร์มให้ครบ&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</b>
                        {messageError.map(x => (<div>
                            - {x}
                        </div>))}
                    </span>
                    messageApi.error({
                        type: 'error',
                        content: content,
                        className: 'custom-class'
                    });
                }

            } else {
                dispatch(updatePartnerProfileByRegistraion(parseInt(id), body as any))
            }

        }

    }
    const handleSubmitSelectEmail = (e: PartnerKnowledgeRegistration | undefined) => {
        const body = { ...partnerProfileList }
        body.moveTransition = nextActionItem.find(x => x.id == nextAction)
        body.sendEmailToCustomer = e;
        dispatch(updatePartnerProfileByRegistraion(parseInt(id), body as any))
        setShowSelectEmailCustomerModal(false);
    }

    const invalidGeneralInfo = () => {
        let error = false;
        if (validateRequired(partnerProfileList?.orgType) ||
            validateRequired(partnerProfileList?.orgGroup) ||
            validateRequired(partnerProfileList?.houseNo) ||
            validateRequired(partnerProfileList?.province) ||
            validateRequired(partnerProfileList?.district) ||
            validateRequired(partnerProfileList?.subDistrict) ||
            validateRequired(partnerProfileList?.zipCode)) {
            error = true;
        }
        return error
    }
    const invalidGeneralInfoSendFormToCustomer = () => {
        let error = false;
        if (validateRequired(partnerProfileList?.orgType) ||
            validateRequired(partnerProfileList?.orgGroup)) {
            error = true;
        }
        return error
    }
    const invalidPartSetting = () => {
        if (partnerProfileList?.showGeneralInfo == true ||
            partnerProfileList?.showOrganizationInfo == true ||
            partnerProfileList?.showPartner == true ||
            partnerProfileList?.showWelfareBenefit == true) {
            return false;
        } else {
            return true;
        }
    }
    const invalidOrganization = () => {
        let error = false
        if ((getOrgTypeName(partnerProfileList?.orgType) == 'เอกชน' || getOrgTypeName(partnerProfileList?.orgType) == 'รัฐวิสาหกิจ')) {
            if (validateRequired(partnerProfileList?.employee)) {
                error = true;
            }
        } else if ((getOrgTypeName(partnerProfileList?.orgType) == 'รัฐ')) {
            if (validateRequired(partnerProfileList?.personnel) ||
                validateRequired(partnerProfileList?.governmentOfficer) ||
                validateRequired(partnerProfileList?.governmentEmployee) ||
                validateRequired(partnerProfileList?.employee) ||
                validateRequired(partnerProfileList?.memberUnderSupervision)) {
                error = true;
            }
        } else if ((getOrgTypeName(partnerProfileList?.orgType) == 'องค์กรอิสระ')) {
            if (validateRequired(partnerProfileList?.employee) ||
                validateRequired(partnerProfileList?.memberUnderSupervision)) {
                error = true;
            }
        } else if ((getOrgTypeName(partnerProfileList?.orgType) == 'สถาบันการศึกษา')) {
            if (getOrgGroupName(partnerProfileList?.orgGroup) == 'มหาวิทยาลัย') {
                error = invalidUniversity()
            } else if (getOrgGroupName(partnerProfileList?.orgGroup) == 'วิทยาลัย') {
                error = invalidUniversity()
            } else if (getOrgGroupName(partnerProfileList?.orgGroup) == 'อาชีวศึกษา') {
                error = invalidVocation()
            } else {
                error = validateRequired(partnerProfileList?.teacherNo) ||
                    validateRequired(partnerProfileList?.personnel) ||
                    validateRequired(partnerProfileList?.studentNo)
            }
        }
        return error;
    }
    const invalidUniversity = () => {
        let error = false;
        if (validateRequired(partnerProfileList?.teacherNo) ||
            validateRequired(partnerProfileList?.personnel) ||
            validateRequired(partnerProfileList?.studentNo)) {
            error = true;
        }
        if (partnerProfileList?.faculty && partnerProfileList?.faculty.length > 0) {
            partnerProfileList?.faculty.forEach(faculty => {
                faculty.facultyInfo && faculty.facultyInfo.forEach(x => {
                    if (x.facultyName == 0) {
                        error = validateRequired(x.otherFacultyName) || validateRequired(x.teacherNo) || validateRequired(x.personnel) || validateRequired(x.studentNo)
                    } else {
                        console.log("Test", validateRequired(x.studentNo))
                        error = validateRequired(x.teacherNo) || validateRequired(x.personnel) || validateRequired(x.studentNo)
                    }
                })
                faculty.branchInfo && faculty.branchInfo.forEach(x => {
                    error = validateRequired(x.branchName) || validateRequired(x.teacherNo) || validateRequired(x.teacherNo) || validateRequired(x.personnel) || validateRequired(x.studentNo)

                })
            })
        }
        return error
    }
    const invalidVocation = () => {
        let error = false;
        if (validateRequired(partnerProfileList?.teacherNo) ||
            validateRequired(partnerProfileList?.personnel) ||
            validateRequired(partnerProfileList?.studentNo)) {
            error = true;
        }
        if (partnerProfileList?.faculty && partnerProfileList?.faculty.length > 0) {
            partnerProfileList?.faculty.forEach(faculty => {
                faculty.branchInfo && faculty.branchInfo.forEach(x => {
                    error = validateRequired(x.branchName) || validateRequired(x.teacherNo) || validateRequired(x.teacherNo) || validateRequired(x.personnel) || validateRequired(x.studentNo)

                })
            })
        }
        return error
    }
    const invalidPartnerInfo = () => {
        let error = false;
        if (!partnerProfileList?.partnerList || partnerProfileList?.partnerList.length <= 0) {
            error = true
        }
        return error;
    }
    const invalidWelfareBenefit = () => {
        let error = false;
        if (validateRequireBenefit(partnerProfileList?.checkProvidentFund as any, partnerProfileList?.providentFundAmount)) {
            error = true;
        }
        if (validateRequireBenefit(partnerProfileList?.checkSocialSecurity as any, partnerProfileList?.socialSecurityAmount)) {
            error = true;
        }
        if (validateRequireBenefit(partnerProfileList?.checkGovPensionFund as any, partnerProfileList?.govPensionFundAmount)) {
            error = true;
        }
        if (validateRequireBenefit(partnerProfileList?.checkSavingsCooperative as any, partnerProfileList?.savingsCooperativeAmount)) {
            error = true;
        }
        if (partnerProfileList?.welfareBenefitList && partnerProfileList?.welfareBenefitList.length > 0) {
            error = partnerProfileList?.welfareBenefitList.some(x => (x.welfareBenefitName == null || x.welfareBenefitName == '' || validateRequired(x.memberAmount)))
        }
        return error
    }
    const handleResendEmail = (partner: PartnerKnowledgeRegistration) => {
        dispatch(resendVerifyEmail((parseInt(id)), partner))
    }
    const handleBack = () => {
        history.push('/admin/log-request')
    }

    return (
        <div>
            {contextHolder}
            <MainMenu>

                <div>
                    {init == 1 && (
                        <div className="query-wrapper">
                            <br />
                            <div className="yellow-button" style={{ textAlign: "left", borderTopLeftRadius: "30px", borderTopRightRadius: "30px", height: "70px", paddingTop: "15px", paddingLeft: "15px" }}><h4 className="header">บริหารจัดการข้อมูลองค์กรพันธมิตร</h4></div>
                            <br />
                            <ManagePartnerSuggestion />
                            <br />
                            <div style={{ background: "white" }}>
                                <PartnerPartSetting parnerProfileList={partnerProfileList as any}
                                    success={success}
                                    setParnerProfileListChange={setPartnerProfileList}
                                />
                            </div>
                            <div style={{ background: "white" }} className='mt-2'>
                                <PartnerGeneralInfo parnerProfileList={partnerProfileList as any}
                                    success={success}
                                    orgTypeItem={orgTypeItem}
                                    submitted={submitted}
                                    province={province}
                                    readonly={false}
                                    setParnerProfileListChange={setPartnerProfileList}
                                    isDisableOrganizeGroup={false}
                                    nextAction={nextAction}
                                    nextActionItem={nextActionItem}
                                />
                            </div>
                            {/* เอกชน รัฐวิสาหกิจ */}
                            {(getOrgTypeName(partnerProfileList?.orgType) == 'เอกชน' || getOrgTypeName(partnerProfileList?.orgType) == 'รัฐวิสาหกิจ') && (
                                <PartnerOrgPrivateCompany parnerProfileList={partnerProfileList as any}
                                    submitted={submitted}
                                    readonly={false}
                                    setParnerProfileListChange={setPartnerProfileList}
                                    nextAction={nextAction}
                                    nextActionItem={nextActionItem}
                                    success={success} />
                            )}
                            {(getOrgTypeName(partnerProfileList?.orgType) == 'รัฐ') && (
                                <PartnerOrgGoverment parnerProfileList={partnerProfileList as any}
                                    submitted={submitted}
                                    readonly={false}
                                    setParnerProfileListChange={setPartnerProfileList}
                                    nextAction={nextAction}
                                    nextActionItem={nextActionItem}
                                    success={success} />
                            )}
                            {(getOrgTypeName(partnerProfileList?.orgType) == 'องค์กรอิสระ') && (
                                <PartnerOrgIndepandent parnerProfileList={partnerProfileList as any}
                                    submitted={submitted}
                                    readonly={false}
                                    setParnerProfileListChange={setPartnerProfileList}
                                    nextAction={nextAction}
                                    nextActionItem={nextActionItem}
                                    success={success} />
                            )}
                            {(getOrgTypeName(partnerProfileList?.orgType) == 'สถาบันการศึกษา') && (<>
                                {(getOrgGroupName(partnerProfileList?.orgGroup) == 'มหาวิทยาลัย') && (
                                    <PartnerUniversity parnerProfileList={partnerProfileList as any}
                                        submitted={submitted}
                                        facultyItem={facultyItem}
                                        setParnerProfileListChange={setPartnerProfileList}
                                        readonly={false}
                                        nextAction={nextAction}
                                        nextActionItem={nextActionItem}
                                        success={success} />
                                )}
                                {(getOrgGroupName(partnerProfileList?.orgGroup) == 'วิทยาลัย') && (
                                    <PartnerUniversity parnerProfileList={partnerProfileList as any}
                                        submitted={submitted}
                                        facultyItem={facultyItem}
                                        setParnerProfileListChange={setPartnerProfileList}
                                        readonly={false}
                                        nextAction={nextAction}
                                        nextActionItem={nextActionItem}
                                        success={success} />
                                )}
                                {(getOrgGroupName(partnerProfileList?.orgGroup) == 'อาชีวศึกษา') && (
                                    <PartnerVocation parnerProfileList={partnerProfileList as any}
                                        submitted={submitted}
                                        setParnerProfileListChange={setPartnerProfileList}
                                        readonly={false}
                                        nextAction={nextAction}
                                        nextActionItem={nextActionItem}
                                        success={success} />
                                )}
                                {(getOrgGroupName(partnerProfileList?.orgGroup) == 'โรงเรียน') && (
                                    <PartnerSchool parnerProfileList={partnerProfileList as any}
                                        submitted={submitted}
                                        setParnerProfileListChange={setPartnerProfileList}
                                        readonly={false}
                                        nextAction={nextAction}
                                        nextActionItem={nextActionItem}
                                        success={success} />
                                )}
                                {(getOrgGroupName(partnerProfileList?.orgGroup) == 'อื่น ๆ') && (
                                    <PartnerSchool parnerProfileList={partnerProfileList as any}
                                        submitted={submitted}
                                        setParnerProfileListChange={setPartnerProfileList}
                                        readonly={false}
                                        nextAction={nextAction}
                                        nextActionItem={nextActionItem}
                                        success={success} />
                                )}

                            </>)}
                            <div style={{ background: "white" }} className='mt-2'>
                                <PartnerWelfareBenefit parnerProfileList={partnerProfileList as any}
                                    success={success}
                                    readonly={false}
                                    submitted={submitted}
                                    setParnerProfileListChange={setPartnerProfileList}
                                />
                            </div>
                            <div style={{ background: "white" }} className='mt-2'>
                                <PartnerInfo parnerProfileList={partnerProfileList as any}
                                    success={success}
                                    submitted={submitted}
                                    readonly={false}
                                    handleResendEmail={handleResendEmail}
                                    setParnerProfileListChange={setPartnerProfileList} />
                            </div>
                            <div>
                                <PartnerKnowledgeList parnerProfileList={partnerProfileList as any}
                                    success={success}
                                    submitted={submitted}
                                    hasSummaryLink={false}
                                    setParnerProfileListChange={setPartnerProfileList} />
                            </div>
                            <div>
                                <PartnerHistory parnerProfileList={partnerProfileList as any}
                                    success={success}
                                    submitted={submitted}/>
                            </div>
                            {!partnerProfileList?.completed && (
                                <React.Fragment>
                                    <div className="text-left" style={{ position: "relative", marginBottom: 5 }}>
                                        <div className='d-flex yellow-button' style={{ padding: "15px", borderRadius: "5px" }}>
                                            <div className='d-flex'>
                                                <label className='col-auto' style={{ fontWeight: 600 }}>Next Action<span className='text-danger'>*</span></label>
                                                <SingleSearch isError={getRequire(nextAction)}
                                                    isSearchable={true} isMulti={false} placeholder=""
                                                    onChange={(id: string, e: any) => setNextAction(e == null ? null : e?.value)}
                                                    value={getNextAction()}
                                                    options={createNextAction()}
                                                    style={{ width: "400px" }}
                                                    disabled={success}
                                                />
                                                <span className='text-danger ml-2'>*</span>

                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            )}

                            <div className='d-flex mt-3'>
                                <div className='ml-auto mb-3'>
                                    <button type="button" className="btn btn-secondary" style={{ width: "120px" }}
                                        onClick={() => handleBack()} >
                                        Back
                                    </button>
                                    {(!success && !partnerProfileList?.completed) &&
                                        <span>
                                            &nbsp;
                                            <button type="button" className="btn btn-warning" style={{ width: "120px" }}
                                                onClick={() => handleClear()} >
                                                Clear
                                            </button>&nbsp;
                                            <button type="button" className="btn btn-success" style={{ width: "120px" }}
                                                onClick={() => handleSubmit()} >
                                                Save
                                            </button>
                                        </span>
                                    }
                                </div>
                            </div>
                           
                        </div>
                    )}

                    {(((init < 1) || (loading)) && (error === "")) &&
                        <div style={{ textAlign: "center" }}><img src={loadinglogo} width="30" height="30" className="d-inline-block align-top" alt="" /></div>
                    }
                </div>
            </MainMenu>
            <ManagePartnerSelectEmailCustomer show={showSelectEmailCustomerModal} onHide={() => setShowSelectEmailCustomerModal(false)} partnerList={partnerProfileList?.partnerList} onOk={handleSubmitSelectEmail} />
            <AlertContainer floatingTime={3000} />
        </div>
    )
}

export default ManagePartner