import { useEffect, useState } from 'react';
import { useSelector, useDispatch, useStore } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Button, InputGroup, Container, Row, Col, Modal } from 'react-bootstrap';
import { assignment, uploadassignment, setconsent, getconsent, validateAlreadySubmit } from '../../store/studentassignment/actions';
import { queryclear } from '../../store/search/actions';
import loadinglogo from '../../img/loading.gif';
import progresslogo from '../../img/progress.png';
import manuallogo from '../../img/manual.svg';
import passlogo from '../../img/pass.png';
import faillogo from '../../img/fail.png';
import uplogo from '../../img/up.png';
import downlogo from '../../img/down.png';
import StudentHeader from './studentheader';
import StudentFooter from './studentfooter';
import { GET_ASSIGNMENT_PAGE_STUDENT_URL, GET_DOC_PAGE_STUDENT_URL, GO_ELEARNING_URL } from '../../api/page-student-api';
import { Util } from '../../api/util';
import { CourseType, DocumentType, getActivityType, getCourseType, getDocumentDescription } from '../../api/define';
import { MAX_UPLOAD_FILE_SIZE_LABEL, REQUIRE_LABEL } from '../../api/label';
import { MAX_FILE_UPLOAD } from '../../api/conf';
import { getAccessToken, getRefreshToken, getUsername } from '../../store/auth/selectors';
import QuestionAnswerOutlinedIcon from '@material-ui/icons/QuestionAnswerOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { thmonth } from '../../api/define';
import { getOnlineType } from '../../api/define';
import styled, { withTheme } from 'styled-components';
import { StudentSessionResponse } from '../../model/response/student-session.model';
import FileUploader from '../share/FileUploader';
import { StepResultType, StudentStepResponse } from '../../model/response/student-step.model';
import { getCollapsed } from '../../store/menu/selectors';
import noPhoto from '../../img/CommonBanner.png';
import { alert } from 'react-custom-alert';
import { AlertContainer } from 'react-custom-alert';
import CommentModal from '../share/CommentModal';
const ListLink = styled.div`
    font-size: 0.95rem;
    display: inline-block;
    span{
        margin-left: 5px;
       
    }   
    &:hover{
        text-decoration: underline;
        cursor: pointer;    
        opacity: 0.5;
    }
    
`
const ListLinkSpan = styled.span`
    font-size: 0.95rem;
    span{
        margin-left: 5px;
       
    }   
    &:hover{
        text-decoration: underline;
        cursor: pointer;    
        opacity: 0.5;
    }
    
`
const ContainerStepper = styled.div`
    font-size: 0.93rem;
    div{
        font-size: 0.93rem;
    }
    span{
        font-size: 0.93rem;
    }
`
const Title = styled.span`
    font-weight: 500;
    font-size: 0.95rem !important;
`
const StepTitle = styled.div`
    font-weight: 600;
`
const StepCircle = styled.div<{ bgcolor?: string, textColor?: string, borderColor?: string; }>`
    background: ${p => p.bgcolor ? p.bgcolor : 'white'};
    border-radius: 50%;
    border: 2px solid ${p => p.borderColor ? p.borderColor : 'white'};
    width: 32px;
    color: ${p => p.textColor ? p.textColor : 'white'};
    height: 32px;
    text-align: center;
`
const CollapseLogo = styled.img`
    margin-left: 5px;
    margin-right: 5px;
    height: 30px;
    &:hover {
        cursor: pointer;    
        opacity: 0.5;
    }
`
const ContainerImageProject = styled.div`
    img{
        width: 285px;
        height: 158.6px;
        //transform: scale(0.85);
    }
    img.small-img{
        width: 226px;
        height: 126.88px;
    }

    img.small-img-hover{
        width: 226px;
        height: 126.88px;
        &:hover {
        cursor: pointer;    
        opacity: 0.5;
        }
    }
`



const StudentAssignment = () => {
    const token = useSelector(getAccessToken);
    const sessionToken = useSelector(getRefreshToken);
    const [init, setInit] = useState(0)
    const [items, setItems] = useState<any>({})
    const [loading, setLoading] = useState(false);
    const [consentId, setConsentId] = useState("");
    const [consentFile, setConsentFile] = useState("");
    const [error, setError] = useState('');
    const [feedbackId, setFeedbackId] = useState('');
    const [courseNameSelect, setCourseNameSelect] = useState<any>();
    const [feedBackNameSelect, setFeedBackNameSelect] = useState<any>();
    const [trainerName, setTrainerName] = useState<any>();
    const username = useSelector(getUsername);
    const store = useStore();
    const dispatch = useDispatch();
    const history = useHistory();
    let { class_id } = useParams<{ class_id: string }>();

    useEffect(() => {
        const unsubscribe = store.subscribe(() => {
            let res = store.getState().SearchReducer;
            if (res.page != 'comment_modal') {
                if (res.isLoading) {
                    setError('');
                    setLoading(true);
                } else if ((res.isLoaded) && (!res.isUpdated)) {
                    setError('');
                    setLoading(false);
                    console.log(res.data)
                    if (res.data != null) {
                        if (res.table === "assignment") {
                            setItems(res.data);
                            setInit(1);
                        } else if (res.table === "document") {
                            var newItem = { ...items };
                            if (newItem.step?.length != null) {
                                for (var i = 0; i < newItem.step?.length; i++) {
                                    if (newItem.step[i].document?.length != null) {
                                        for (var j = 0; j < newItem.step[i].document.length; j++)
                                            if (newItem.step[i].document[j].id === res.data.documentId) {
                                                newItem.step[i].document[j].assignmentId = res.data.assignmentId;
                                                newItem.step[i].document[j].assignmentFiles = [{ assignmentId: res.data.assignmentId, assignmentName: res.data.originalFilename }]
                                                newItem.step[i].document[j].loadingAssignment = undefined;
                                            }
                                    }

                                }
                            }

                            setItems(newItem);
                        } else if (res.table === "consent") {
                            setConsentFile(res.data);
                        } else if (res.table === "handleSurvey") {
                            window.open("/student/survey/" + res.data.surveyGroupId + "/class_course/" + res.data.classCourseId, '_blank', 'noopener,noreferrer');
                        } else if (res.table === "handleSurveyAfter") {
                            window.open("/student/survey/" + res.data.surveyGroupId + "/class/" + res.data.classId + "/page/after", '_blank', 'noopener,noreferrer');
                        } else if (res.table === "handleSurveyBefore") {
                            window.open("/student/survey/" + res.data.surveyGroupId + "/class/" + res.data.classId + "/page/before", '_blank', 'noopener,noreferrer');
                        }
                    }
                } else if (res.isUpdated) {
                    dispatch(queryclear());
                    dispatch(assignment(class_id));
                } else if (res.isFailed) {
                    alert({ message: res.data.message, type: 'error' })
                    setLoading(false);
                }
            }

        })
        return unsubscribe;
    }, [items])

    useEffect(() => {
        dispatch(queryclear());
        dispatch(assignment(class_id));
    }, [])

    const handleGetAssignment = (id: string) => {
        window.open(GET_ASSIGNMENT_PAGE_STUDENT_URL + "/" + id + "?token=" + token, '_blank', 'noopener,noreferrer')
    }

    const handleGetDoc = (id: string) => {
        window.open(GET_DOC_PAGE_STUDENT_URL + "/" + id + "?token=" + token, '_blank', 'noopener,noreferrer')
    }

    const handleUrl = (url: string) => {
        window.open(url, '_blank', 'noopener,noreferrer')
    }

    const handleConsent = (classCourseId: string, documentId: string) => {
        setConsentId(classCourseId);
        dispatch(getconsent(documentId));
    }

    const handleConsentClose = () => {
        setConsentFile("");
    }

    const handleConsentSubmit = () => {
        dispatch(setconsent(consentId, true));
        setConsentFile("");
    }

    const handleEToolkitAssignment = (documentId: string) => {
        window.open("/student/etoolkit/assignment/" + documentId, '_blank', 'noopener,noreferrer');
    }

    const errorAssignmentUpload = (i: number, j: number) => {
        if (items.step[i].document[j].errorAssignment === undefined) return null;
        return items.step[i].document[j].errorAssignment;
    }

    const handleAssignmentUploadChange = (i: number, j: number, selectorFiles: FileList | null) => {
        var newItem: any = { ...items };
        newItem.step[i].document[j].assignmentFile = selectorFiles;
        setItems(newItem);
    }

    const validateAssignment = (i: number, j: number) => {
        var newItem: any = { ...items };
        var currentFile = newItem.step[i].document[j].assignmentFile;
        if (currentFile === undefined) {
            newItem.step[i].document[j].errorAssignment = REQUIRE_LABEL;
            setItems(newItem);
            return false;
        }
        if (currentFile[0].size > MAX_FILE_UPLOAD * 1000000) {
            newItem.step[i].document[j].errorAssignment = MAX_UPLOAD_FILE_SIZE_LABEL;
            setItems(newItem);
            return false;
        }
        return true;
    }

    const handleAssignmentUpload = (i: number, j: number) => {
        if (validateAssignment(i, j)) {
            var c = items.step[i].document[j];
            dispatch(queryclear());
            dispatch(uploadassignment(c.id, c.assignmentFile));
            var newItem = { ...items };
            newItem.step[i].document[j].loadingAssignment = true;
            newItem.step[i].document[j].errorAssignment = undefined;
            newItem.step[i].document[j].assignmentFile = undefined;
            setItems(newItem);
        }
    }
    const handleClickUploadFile = (e: any, i: number, j: number) => {
        if (e.target.files) {
            handleAssignmentUploadChange(i, j, e.target.files)
            handleAssignmentUpload(i, j);
        }

    }

    const handleEditAssignment = (i: number, j: number) => {
        var newItem: any = { ...items };
        if (newItem.step[i].document[j].edit === true) newItem.step[i].document[j].edit = false;
        else newItem.step[i].document[j].edit = true;
        setItems(newItem);
    }

    const getLoadingAssignment = (i: number, j: number) => {
        if (items.step[i].document[j].loadingAssignment === undefined) return false;
        return items.step[i].document[j].loadingAssignment;
    }

    const handleSurveyBefore = (id: number, classId: number) => {
        dispatch(validateAlreadySubmit(id, classId, null, null, true, 'handleSurveyBefore'))

    }

    const handleSurveyAfter = (id: number, classId: number) => {
        dispatch(validateAlreadySubmit(id, classId, null, null, false, 'handleSurveyAfter'))
    }

    const handleSurvey = (id: number, classCourseId: number) => {
        dispatch(validateAlreadySubmit(id, null, classCourseId, null, null, 'handleSurvey'))
    }

    const getDocumentTitle = (description: string, caption: string) => {
        if (description === "CONSENT") return getDocumentDescription(description);
        else
            return getDocumentDescription(description) + " : " + caption;

    }

    const getCourseTypeColor = (t: string) => {
        if (t === CourseType.ONLINE) return "#e9f1fe";
        else if (t === CourseType.ONSITE) return "#e8f7de";
        else if (t === CourseType.ELEARNING) return "#fbe8c5";
        else if (t === CourseType.HYBRID) return "#f0def7";
        else if (t === CourseType.OTHER) return "white";
    }

    const getCourseTypeBorderColor = (t: string) => {
        if (t === CourseType.ONLINE) return "#528ff0";
        else if (t === CourseType.ONSITE) return "#9ccf5e";
        else if (t === CourseType.ELEARNING) return "#f4cf57";
        else if (t === CourseType.HYBRID) return "#c04af1";
        else if (t === CourseType.OTHER) return "black";
        return "black";
    }

    const createClassCourseDocument = (i: number, document: any, eligible: boolean, consent: boolean) => {
        var res: any[] = [];
        for (var j = 0; j < document.length; j++) {
            var item = document[j];
            res.push(
                <>
                    <table style={{ width: "100%" }}><tr>
                        <td style={{ verticalAlign: "top", width: "30px" }}>
                            {(item.description !== "CONSENT") &&
                                <>{(item.description === 'ETOOLKIT' || item.description === 'ASSIGNMENT') ?
                                    <EditOutlinedIcon className={(eligible) ? "light-blue" : "gray"} /> :
                                    <img src={manuallogo} alt="" className={(eligible) ? "light-blue" : "gray"} style={{ height: "20px" }} />
                                }

                                </>
                            }

                            {(item.description === "CONSENT") &&
                                <>
                                    {((eligible) && (consent === null)) &&
                                        <input type="checkbox" style={{ marginLeft: "5px" }} id={item.classCourseId + "_" + item.id} onClick={e => { handleConsent(e.currentTarget.id.split("_")[0], e.currentTarget.id.split("_")[1]) }} checked={false} />
                                    }
                                    {((!eligible) || (consent !== null)) &&
                                        <input type="checkbox" style={{ marginLeft: "5px" }} className="gray" checked={true} />
                                    }
                                </>
                            }
                        </td>
                        <td>
                            {(item.description === "CONSENT") && (eligible) && (consent === null) &&
                                <ListLinkSpan id={item.classCourseId + "_" + item.id} onClick={e => { handleConsent(e.currentTarget.id.split("_")[0], e.currentTarget.id.split("_")[1]) }}>
                                    {getDocumentTitle(item.description, item.caption)}
                                </ListLinkSpan>
                            }
                            {(item.description !== "CONSENT" && item.description !== "ETOOLKIT") && (eligible) && (consent) &&
                                <ListLinkSpan id={(item.docType === DocumentType.FILE) ? item.id.toString() : item.url} onClick={(e) => { if (item.docType === DocumentType.FILE) handleGetDoc(e.currentTarget.id); else handleUrl(e.currentTarget.id); }} className={(eligible ? "black" : "gray")}>{getDocumentTitle(item.description, item.caption)}
                                </ListLinkSpan>
                            }
                            {(item.description === "ETOOLKIT") && (eligible) && (consent) &&
                                <>
                                    <ListLinkSpan id={(item.docType === DocumentType.FILE) ? item.id.toString() : item.url} onClick={(e) => { if (item.docType === DocumentType.FILE) handleEToolkitAssignment(e.currentTarget.id); else handleUrl(e.currentTarget.id); }} className={(eligible ? "black" : "gray")}>{getDocumentTitle(item.description, item.caption)}
                                    </ListLinkSpan>
                                    {(item.docType === DocumentType.FILE) && <div><span className="pointer" style={{ textDecoration: "underline" }} id={j.toString()} onClick={e => { handleFeedback(document[Number(e.currentTarget.id)]) }}> สนทนากับวิทยากร</span></div>}
                                </>

                            }
                            &nbsp;
                            {(item.description == "ASSIGNMENT") && (eligible) && (consent) && (item.assignmentId !== null) &&
                                <><i style={{ whiteSpace: "nowrap" }}>(ส่งงานแล้ว)</i></>
                            }
                            {(item.description == "ASSIGNMENT") && (eligible) && (consent) && (item.assignmentId === null) &&
                                <><i style={{ whiteSpace: "nowrap" }}>(ยังไม่ได้ส่งงาน)</i></>
                            }
                            {(((item.description !== "CONSENT") && ((!eligible) || (consent === false) || (consent === null))) || ((item.description === "CONSENT") && ((!eligible) || (consent !== null)))) &&
                                <span className="gray">{getDocumentTitle(item.description, item.caption)}</span>
                            }
                        </td></tr>
                    </table>
                    {((item.description === "ASSIGNMENT") && (eligible) && (consent)) &&
                        <>
                            {(item.assignmentId !== null) &&
                                <>
                                    <div style={{ paddingLeft: "30px" }}>
                                        <span className="pointer" style={{ textDecoration: "underline" }} id={item.assignmentId.toString()} onClick={e => { handleGetAssignment(e.currentTarget.id) }}>ดูงานที่ส่งแล้ว</span> / <span className="pointer" style={{ textDecoration: "underline" }} id={i.toString() + "_" + j.toString()} onClick={e => handleEditAssignment(parseInt(e.currentTarget.id.split("_")[0]), parseInt(e.currentTarget.id.split("_")[1]))}>แก้ไขงาน</span>
                                        <span> / </span><span className="pointer" style={{ textDecoration: "underline" }} id={j.toString()} onClick={e => { handleFeedback(document[Number(e.currentTarget.id)]) }}> สนทนากับวิทยากร</span>
                                    </div>
                                </>
                            }
                            {((item.assignmentId === null) || (item.edit === true)) &&
                                <Container style={{ paddingLeft: "30px" }}>
                                    <Row style={{ marginBottom: 10 }}>
                                        <Col><span>ส่งงานเข้าสู่ระบบ:</span></Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FileUploader
                                                id={i + "_" + j}
                                                buttonTitle='ส่งงาน'
                                                accept='.pdf'
                                                handleFile={(e: any) => handleClickUploadFile(e, parseInt(e.currentTarget.id.split("_")[0]), parseInt(e.currentTarget.id.split("_")[1]))}
                                                fileList={item.assignmentFiles ?? []}
                                                onDownload={(e) => handleGetAssignment(e)}
                                                loading={getLoadingAssignment(i, j)} />
                                            <div className={(errorAssignmentUpload(i, j) !== null) ? "text-danger" : ""}><small>{errorAssignmentUpload(i, j)}</small></div>
                                        </Col>

                                    </Row>
                                </Container>
                            }
                        </>
                    }
                </>);
        }
        return res;
    }

    const createTrainerDetail = (s: any) => {
        var res: any[] = [];
        for (var k = 0; k < s.trainer.length; k++) {
            var t = s.trainer[k];
            res.push(<>
                {(k + 1)}. {t.name}<br />
            </>);
        }
        return res;
    }

    const createTrainerAssitantDetail = (s: StudentSessionResponse) => {
        var res: any[] = [];
        for (var k = 0; k < s.trainerAssistant.length; k++) {
            var t = s.trainerAssistant[k];
            res.push(<>
                {(k + 1)}. {t.name}<br />
            </>);
        }
        return res;
    }

    const datefromtostr = (fromDate: Date, toDate: Date): string => {
        if ((isNaN(fromDate.getDate())) || (isNaN(toDate.getDate()))) return "";
        var dd = String(fromDate.getDate()).padStart(2, '0');
        var mm = thmonth[fromDate.getMonth()];
        var yyyy = fromDate.getFullYear() + 543;

        var hh = String(fromDate.getHours()).padStart(2, '0');
        var min = String(fromDate.getMinutes()).padStart(2, '0');
        var hh2 = String(toDate.getHours()).padStart(2, '0');
        var min2 = String(toDate.getMinutes()).padStart(2, '0');

        return dd + ' ' + mm + ' ' + yyyy + " เวลา " + hh + "." + min + "น." + " - " + hh2 + "." + min2 + "น.";
    }

    const datetodatetostr = (fromDate: Date, toDate: Date): string => {
        if (isNaN(fromDate.getDate()) || isNaN(toDate.getDate())) return "";
        var dd = String(fromDate.getDate()).padStart(2, '0');
        var mm = thmonth[fromDate.getMonth()];
        var yyyy = fromDate.getFullYear() + 543;

        var dd2 = String(toDate.getDate()).padStart(2, '0');
        var mm2 = thmonth[toDate.getMonth()];
        var yyyy2 = toDate.getFullYear() + 543;

        return dd + ' ' + mm + ' ' + yyyy + " - " + dd2 + ' ' + mm2 + ' ' + yyyy2;
    }

    const getStepColor = (stepResult: StepResultType | undefined, typeColor: 'bgColor' | 'borderColor'): string => {
        if (typeColor == 'bgColor') {
            switch (stepResult) {
                case StepResultType.DONE: return '#9ed062';
                case StepResultType.PROGRESS: return 'white';
                case StepResultType.NONE: return 'white';
                case StepResultType.FAIL: return '#f24726';
                default: return '#9ed062';

            }
        } else if (typeColor == 'borderColor') {
            switch (stepResult) {
                case StepResultType.DONE: return '#9ed062';
                case StepResultType.PROGRESS: return '#53b8f6';
                case StepResultType.NONE: return 'black';
                case StepResultType.FAIL: return '#f24726'
                default: return 'black'
            }
        } else {
            return 'white';
        }

    }

    const createCourseDetail = (i: number) => {
        var item = items.step[i];
        var res: any[] = [];
        if (item.courseType === CourseType.ELEARNING) {
            res.push(<>
                {datetodatetostr(new Date(item.fromDate), new Date(item.toDate))} <br />
                {/* {(item.bannerPic !== null) &&
                    <img src={item.bannerPic} height="100px" alt="" />
                } */}
            </>);
        } else if (item.courseType === CourseType.OTHER) {
            res.push(<>
                {datetodatetostr(new Date(item.fromDate), new Date(item.toDate))} {getActivityType(item.activityType)} <br />
            </>);
        } else {
            for (var j = 0; j < item.session.length; j++) {
                var s = item.session[j];
                var trainer = createTrainerDetail(s);
                var trainerAssitant = createTrainerAssitantDetail(s)
                var place = "";
                if ((item.courseType === CourseType.ONSITE) || (item.courseType === CourseType.HYBRID)) place = s.place;
                else if (item.courseType === CourseType.ONLINE) place = getOnlineType(s.onlineType);
                res.push(<>
                    {datefromtostr(new Date(s.fromDate), new Date(s.toDate))} {place} <br />
                    <span style={{ textDecoration: "underline" }}>วิทยากรหลัก</span><br />
                    {trainer}
                    <span style={{ textDecoration: "underline" }}>วิทยากรผู้ช่วย</span><br />
                    {trainerAssitant}
                </>);
            }
        }
        return res;
    }
    const handleCollapse = (i: number) => {
        var newItem: any = { ...items };
        newItem.step[i].collapse = !newItem.step[i].collapse;
        console.log(newItem.step[i]);
        setItems(newItem);
    }

    const createProjectDocument = () => {
        var res = <></>;
        var pres: any[] = [];
        if (items.projectDocument.length > 0) {
            for (var i = 0; i < items.projectDocument.length; i++) {
                var item = items.projectDocument[i];
                pres.push(<>
                    {(item.docType === DocumentType.FILE) &&
                        <div>
                            <ListLink id={item.id.toString()} onClick={(e) => handleGetDoc(e.currentTarget.id)}>
                                <img src={manuallogo} alt="" style={{ height: "20px", marginTop: "-4px" }} /><span>{item.caption}</span>

                            </ListLink>
                        </div>

                    }
                    {(item.docType !== DocumentType.FILE) &&
                        <div>
                            <ListLink id={item.url} onClick={(e) => handleUrl(e.currentTarget.id)}>
                                <img src={manuallogo} alt="" style={{ height: "20px", marginTop: "-4px", color: "#f2c946" }} /><span>{item.caption}</span>
                            </ListLink>
                        </div>

                    }
                </>
                );
            }
        }

        if (items.beforeSurveys && items.beforeSurveys.length > 0) {

            items.beforeSurveys.forEach((element: any) => {
                if (element.doSurvey) {
                    pres.push(
                        <div className='gray'>
                            <QuestionAnswerOutlinedIcon style={{ marginTop: "-4px", color: "#f2c946" }}></QuestionAnswerOutlinedIcon><span style={{ marginLeft: 5 }}>แบบประเมินก่อนเข้าโครงการ {element.label} <i>(ส่งแบบประเมินผลแล้ว)</i></span>
                        </div>

                    )
                } else {
                    pres.push(
                        <div>
                            <ListLink id={element.value.toString() + "_" + items.classId} onClick={(e) => handleSurveyBefore(parseInt(e.currentTarget.id.split("_")[0]), parseInt(e.currentTarget.id.split("_")[1]))}>
                                <QuestionAnswerOutlinedIcon style={{ marginTop: "-4px", color: "#f2c946" }}></QuestionAnswerOutlinedIcon><span>แบบประเมินก่อนเข้าโครงการ {element.label}</span>
                            </ListLink>
                        </div>

                    )
                }

            });

        }
        if (items.afterSurveys && items.afterSurveys.length > 0) {
            items.afterSurveys.forEach((element: any) => {
                pres.push(
                    <div>
                        <ListLink id={element.value.toString() + "_" + items.classId} onClick={(e) => handleSurveyAfter(parseInt(e.currentTarget.id.split("_")[0]), parseInt(e.currentTarget.id.split("_")[1]))}>
                            <QuestionAnswerOutlinedIcon style={{ marginTop: "-4px", color: "#f2c946" }}></QuestionAnswerOutlinedIcon><span>แบบประเมินหลังเข้าโครงการ {element.label}</span>
                        </ListLink>
                    </div>

                )
            })

        }

        if (pres.length > 0) {
            res = <div className="row" style={{ paddingTop: "10px", position: "relative" }}>
                <div className="col-12">
                    <table>
                        <tr>
                            <td>
                                {/* <div style={{ background: "#9ed062", borderRadius: "50%", border: "2px solid #9ed062", width: "40px", height: "40px", textAlign: "center" }}>
                                        <h3><img src={passlogo} style={{ height: "20px" }} alt="" /></h3>
                                    </div> */}
                                <StepCircle bgcolor={getStepColor(items.projectDocumentStepResult, 'bgColor')} textColor='white' borderColor={getStepColor(items.projectDocumentStepResult, 'borderColor')}>
                                    <h3>
                                        {items.projectDocumentStepResult === StepResultType.DONE && (<img src={passlogo} style={{ height: "22px", marginTop: "-25px" }} alt="" />)}
                                        {items.projectDocumentStepResult === StepResultType.PROGRESS && (<img src={progresslogo} style={{ height: "32px", marginTop: "-25px", marginLeft: "-1px" }} alt="" />)}
                                        {items.projectDocumentStepResult === StepResultType.FAIL && (<img src={faillogo} style={{ height: "22px", marginTop: "-25px" }} alt="" />)}
                                    </h3>
                                </StepCircle>
                            </td>
                            <td style={{ paddingLeft: "15px", paddingTop: "3px" }}>
                                <StepTitle style={{ fontSize: '1.1rem' }}>ภาพรวมของโครงการ</StepTitle>
                            </td>
                        </tr>
                    </table>
                </div>
                <div style={{ paddingLeft: "100px", width: "100%" }}>
                    {pres}
                </div>
                <div style={{ position: "absolute", left: "31px", top: "43px", border: "1px solid black", width: "0px", height: "100%", zIndex: 0 }}></div>
            </div>
        }
        return res;
    }

    const getCollapsed = (stepIndex: number): boolean => {
        return items.step[stepIndex].collapse;
    }
    const handleELearning = (classCourseId: number) => {
        window.open(GO_ELEARNING_URL + "/" + classCourseId + "?token=" + token + "&session=" + sessionToken, '_blank');
    }
    const handleOpenUrl = (url: string) => {
        console.log(url);
        const newWindow  = window.open(url, '_blank');
        if (newWindow) newWindow.opener = null
    }
    const createStep = () => {
        var res: any[] = [];
        for (var i = 0; i < items.step.length; i++) {
            var item = items.step[i];
            res.push(
                <div className="row" style={{ paddingTop: "5px", position: "relative" }}>
                    <div className="col-12">
                        <table>
                            <tr>
                                <td>
                                    <StepCircle bgcolor={getStepColor(item.stepResult, 'bgColor')} textColor='white' borderColor={getStepColor(item.stepResult, 'borderColor')}>
                                        <h3>
                                            {item.stepResult == StepResultType.DONE && (<img src={passlogo} style={{ height: "20px", marginTop: "-20px" }} alt="" />)}
                                            {item.stepResult == StepResultType.PROGRESS && (<img src={progresslogo} style={{ height: "32px", marginTop: "-25px", marginLeft: "-1px" }} alt="" />)}
                                            {item.stepResult == StepResultType.FAIL && (<img src={faillogo} style={{ height: "20px", marginTop: "-20px" }} alt="" />)}
                                        </h3>
                                    </StepCircle>
                                </td>
                                <td style={{ paddingLeft: "15px", paddingTop: "3px", display: "flex", alignItems: 'center', marginTop: 3 }}>
                                    {item.courseType == CourseType.ELEARNING && (<StepTitle style={{ fontSize: '1.1rem', cursor: 'pointer' }} id={String(item.classCourseId)} onClick={(e) => handleELearning(parseInt(e.currentTarget.id))}>{i + 1}. {item.courseName}</StepTitle>)}
                                    {item.courseType != CourseType.ELEARNING && (<StepTitle style={{ fontSize: '1.1rem' }}>{item.classCourseId && (<>{i + 1}.</>)} {item.courseName}</StepTitle>)}

                                    <CollapseLogo src={getCollapsed(i) ? downlogo : uplogo} height="30px" alt="" id={String(i)} onClick={(e) => handleCollapse(parseInt(e.currentTarget.id))} />
                                    {item.courseType && (<span style={{ fontSize: "8pt", backgroundColor: getCourseTypeColor(item.courseType), borderRadius: "5px", padding: "3px", border: "2px solid " + getCourseTypeBorderColor(item.courseType) }}>&nbsp;{getCourseType(item.courseType)}&nbsp;</span>)}
                                </td>
                            </tr>
                        </table>
                    </div>
                    {!getCollapsed(i) && (<div style={{ paddingLeft: "100px", width: "100%" }}>
                        {item.bannerPic && (
                            <>
                                {item.courseType == CourseType.ELEARNING && (
                                    <div className='d-flex'>
                                        {item.bannerUrl && (
                                            <a className='hover15' href={item.bannerUrl} target='_blank' rel='noopener noreferrer'>
                                                <ContainerImageProject className='mb-3 figure'>
                                                    <img className='small-img-hover' id={String(item.classCourseId)} onClick={(e) => handleELearning(parseInt(e.currentTarget.id))} src={item.bannerPic ? item.bannerPic : noPhoto} alt="..." onError={(e: any) => { e.target.src = noPhoto }} />
                                                </ContainerImageProject>
                                            </a>
                                        )}
                                        {!item.bannerUrl && (
                                            <ContainerImageProject className='mb-3 figure'>
                                                <img className='small-img-hover' id={String(item.classCourseId)} onClick={(e) => handleELearning(parseInt(e.currentTarget.id))} src={item.bannerPic ? item.bannerPic : noPhoto} alt="..." onError={(e: any) => { e.target.src = noPhoto }} />
                                            </ContainerImageProject>
                                        )}

                                        <div style={{ paddingLeft: "50px" }}>{item.bannerDescription}</div>
                                    </div>
                                )
                                }
                                {item.courseType != CourseType.ELEARNING && (
                                    <div className='d-flex'>
                                        {item.bannerUrl && (
                                            <a className='hover15' href={item.bannerUrl} target='_blank' rel='noopener noreferrer'>
                                                <ContainerImageProject className='mb-3 figure'>
                                                    <img className='small-img' src={item.bannerPic ? item.bannerPic : noPhoto} alt="..." onError={(e: any) => { e.target.src = noPhoto }} />
                                                </ContainerImageProject>
                                            </a>
                                        )}
                                        {!item.bannerUrl && (
                                            <ContainerImageProject className='mb-3 figure'>
                                                <img className='small-img' src={item.bannerPic ? item.bannerPic : noPhoto} alt="..." onError={(e: any) => { e.target.src = noPhoto }} />
                                            </ContainerImageProject>
                                        )}

                                        <div style={{ paddingLeft: "50px" }}>{item.bannerDescription}</div>
                                    </div>
                                )}
                            </>

                        )}

                        {item.classCourseId && (
                            <>
                                <Title className="yellow">รายละเอียดการเข้าเรียน</Title><br />
                                <div style={{ paddingLeft: "30px" }}>
                                    {createCourseDetail(i)}
                                </div>
                            </>
                        )}

                        {(item.document?.length > 0) &&
                            <>
                                <Title className="yellow">เอกสารประกอบการเรียน</Title><br />
                                <div style={{ paddingLeft: "30px" }}>
                                    {createClassCourseDocument(i, item.document, item.eligible === true, item.consent)}
                                </div>
                            </>
                        }
                        {(item.surveys != null && item.surveys.length > 0) &&
                            <>
                                <Title className="yellow">แบบประเมิน</Title><br />
                                {item.surveys.map((x: any) => (
                                    <div style={{ paddingLeft: "30px" }} >
                                        {item.stepResult == StepResultType.NONE && (
                                            <div id={x.value + "_" + item.classCourseId} style={{ color: "gray" }}>
                                                <QuestionAnswerOutlinedIcon style={{ marginTop: "-4px", color: "#f2c946" }}></QuestionAnswerOutlinedIcon> {x.label}
                                            </div>
                                        )}
                                        {(!x.doSurvey && item.stepResult != StepResultType.NONE) && (
                                            <div id={x.value + "_" + item.classCourseId} className='link' onClick={(e) => handleSurvey(parseInt(e.currentTarget.id.split("_")[0]), parseInt(e.currentTarget.id.split("_")[1]))}>
                                                <QuestionAnswerOutlinedIcon style={{ marginTop: "-4px", color: "#f2c946" }}></QuestionAnswerOutlinedIcon> {x.label}
                                            </div>
                                        )}
                                        {(x.doSurvey && item.stepResult != StepResultType.NONE) && (
                                            <div id={x.value + "_" + item.classCourseId} style={{ color: "gray" }}>
                                                <QuestionAnswerOutlinedIcon style={{ marginTop: "-4px", color: "#f2c946" }}></QuestionAnswerOutlinedIcon> {x.label} <i>(ส่งแบบประเมินผลแล้ว)</i>
                                            </div>
                                        )}

                                    </div>
                                ))}


                            </>
                        }
                        <br /><br />
                    </div>)}

                    {(i !== items.step.length - 1) &&
                        <div style={{ position: "absolute", left: "31px", top: "43px", border: "1px solid black", width: "0px", height: "100%", zIndex: 0 }}></div>
                    }
                </div>)
        }
        return res;
    }

    const handleFeedback = (item: any) => {
        setFeedbackId(item.id);
        let courseName = items.step.find((x: any) => x.classCourseId === item.classCourseId)?.courseName
        setCourseNameSelect(courseName);
        setFeedBackNameSelect(item.caption);
        setTrainerName(item.trainerName);

    }

    const handleFeedbackClose = () => {
        setFeedbackId('');
        setLoading(false);
    }

    return (<>
        <div className="App-student">
            <div className="student-wrapper">
                <div className="student-wrapper-header-inner" style={{ background: "#38383D" }}>
                    <StudentHeader />
                    {(((init === 0) || (loading)) && (error === "") && (feedbackId === "")) &&
                        <div style={{ textAlign: "center" }}><img src={loadinglogo} width="30" height="30" className="d-inline-block align-top" alt="" /></div>
                    }
                    {(error !== "") &&
                        <div style={{ color: 'red', textAlign: "center" }}>{error}</div>
                    }
                </div>
                {(init === 1) &&
                    <>
                        <div className="student-wrapper-inner">
                            <br />
                            <br />
                            <div style={{ boxShadow: "0px 0px 10px 5px lightgray" }}>
                                <div className='d-flex yellow-button '>

                                    <ContainerImageProject>
                                        <img src={items.banner ? items.banner : noPhoto} alt="..." onError={(e: any) => { e.target.src = noPhoto }} />
                                    </ContainerImageProject>


                                    <div className="text-left flex-fill align-self-center" style={{ padding: "30px", borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}>
                                        <Container>
                                            <h3>โครงการ : {items.name}</h3>
                                        </Container>
                                        <Container>
                                            <Row>
                                                <Col md="4" style={{ textAlign: "left" }}>
                                                    รอบที่ : {items.session}
                                                </Col>
                                                <Col md="4" style={{ textAlign: "left" }}>
                                                    วันที่เริ่ม: {Util.datetostr(new Date(items.fromDate))}
                                                </Col>
                                                <Col md="4" style={{ textAlign: "left" }}>
                                                    วันที่สิ้นสุด: {Util.datetostr(new Date(items.toDate))}
                                                </Col>
                                            </Row>
                                        </Container>
                                    </div>
                                </div>
                                <ContainerStepper className="text-left white-button" style={{ padding: "40px", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px" }}>
                                    <h3>Step</h3>
                                    {
                                        createProjectDocument()
                                    }
                                    {
                                        createStep()
                                    }
                                </ContainerStepper>
                            </div>
                            <br />
                            <br />
                        </div>
                        <AlertContainer floatingTime={10000} />
                    </>
                }

                <Modal show={consentFile !== ""} onHide={handleConsentClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>แบบฟอร์มยินยอมการใช้ข้อมูลส่วนบุคคล</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <object width="100%" height="400" data={consentFile} type="application/pdf"></object>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={handleConsentSubmit}>
                            ยอมรับ
                        </Button>
                        <Button variant="secondary" onClick={handleConsentClose}>
                            ปิด
                        </Button>
                    </Modal.Footer>
                </Modal>
                <CommentModal show={feedbackId !== ""}
                    title='Test'
                    classId={items.classId}
                    firstName={trainerName}
                    lastName={''}
                    courseName={courseNameSelect}
                    feedBackName={feedBackNameSelect}
                    classStudentId={items.classStudentId}
                    documentId={feedbackId}
                    onHide={() => handleFeedbackClose()}
                    onOk={() => { }}
                    okbutton='Comment'
                    cancelbutton='Close' />

                <StudentFooter />
            </div>
        </div>
    </>);
}

export default StudentAssignment;



/*const getDocColor = (item: any) => {
    if ((item.projectId!==undefined) && (item.projectId!==null)) return "light-green";
    if ((item.classId!==undefined) && (item.classId!==null)) return "red";
    return "light-blue";
}

const getDocName = (item: any) =>{
    if ((item.projectId!==undefined) && (item.projectId!==null)) return "โครงการ";
    if ((item.classId!==undefined) && (item.classId!==null)) return "รอบการเรียน";
}*/