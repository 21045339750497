import axios from 'axios';
import { apiClient } from './axios-intercept';
import { DEFAULT_SERVER } from './conf';
import { SurveyQuestionRequest } from '../model/response/survey.model';

export const PREVIEW_SURVEY_URL = "/admin/survey";

const SURVEY = "/api/v1/survey/form";
const SURVEY_GROUP = "/api/v1/survey";

const SURVEY_URL = DEFAULT_SERVER + SURVEY;
const SEARCH_SURVEY_GROUP_URL = DEFAULT_SERVER + SURVEY_GROUP;
const ADD_SURVEY_GROUP_URL = DEFAULT_SERVER + SURVEY_GROUP;
const GET_SURVEY_GROUP_URL = DEFAULT_SERVER + SURVEY_GROUP;
const SURVEY_SUMMARY = DEFAULT_SERVER + "/api/v1/survey/summary";
const SURVEY_PUBLIC_URL = DEFAULT_SERVER + "/api/v1/survey/form/public-link";
const SEARCH_SURVEY_QUESTION_URL = DEFAULT_SERVER + "/api/v1/survey/question";
const GET_QUESTION_GROUP_URL = DEFAULT_SERVER + "/api/v1/survey/question-group";
const CREATE_SURVEY_QUESTION = DEFAULT_SERVER + "/api/v1/survey/question/create";
const UPDATE_SURVEY_QUESTION = DEFAULT_SERVER + "/api/v1/survey/question/update";
const PREVIEW_SURVEY_QUESTION = DEFAULT_SERVER + "/api/v1/survey/question/preview";
const DUPLICATE_SURVEY_QUESTION = DEFAULT_SERVER + "/api/v1/survey/question/duplicate";
const LIST_PROJECT_SURVEY = DEFAULT_SERVER + "/api/v1/project"
export class SurveyAPI {
    public static getSummarySurvey = async (classId: string | undefined, page: number, size: number, sort: string | undefined): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = SURVEY_SUMMARY + `/${classId}`
            var queryParams: string[] = [];
            queryParams.push(`page=${page}`);
            queryParams.push(`size=${size}`);
            if (sort) {
                queryParams.push(`sort=${sort}`)
            }
            apiClient.get(s + '?' + queryParams.join('&'),
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    return resolve(response);
                }).catch(err => {
                    console.log("survey summary:", err);
                    return reject(err);
                })
        })
    }

    public static getDownloadSummarySurvey = (classId: string, token: any) => {
        var queryParams: string[] = [];
        var url = SURVEY_SUMMARY + `/${classId}/export`
        if (token) {
            queryParams.push(`token=${token}`)
        }
        return queryParams.length > 0 ? url + '?' + queryParams.join('&') : url;
    }
    public static getDownloadSurveyResult = (classId: string, classCourseId: number | undefined, isSurveyBefore: boolean | undefined, token: any,surveyGroupId: number | undefined) => {
        var queryParams: string[] = [];
        var url = SURVEY_SUMMARY + `/${classId}/result/export`
        if (classCourseId) {
            queryParams.push(`classCourseId=${classCourseId}`)
        }
        if (isSurveyBefore) {
            queryParams.push(`isSurveyBefore=${isSurveyBefore}`)
        }
        if (token) {
            queryParams.push(`token=${token}`)
        }
        if(surveyGroupId){
            queryParams.push(`surveyGroupId=${surveyGroupId}`)
        }
        return queryParams.length > 0 ? url + '?' + queryParams.join('&') : url;
    }
    public static validateAlreadySubmit = async (surveyGroupId: number, classId: number | null, classCourseId: number | null, classCourseSeesionId: number | null, isSurveyBefore: boolean | null): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = SURVEY_URL + `/${surveyGroupId}/validate-already-submitted`
            var queryParams: string[] = [];
            if (classId) {
                queryParams.push(`class_id=${classId}`)
            }
            if (classCourseId) {
                queryParams.push(`class_course_id=${classCourseId}`)
            }
            if (classCourseSeesionId) {
                queryParams.push(`class_course_session_id=${classCourseSeesionId}`)
            }
            if (isSurveyBefore) {
                queryParams.push(`is_survey_before=${isSurveyBefore}`)
            }
            apiClient.get(s + '?' + queryParams.join('&'),
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    return resolve(response);
                }).catch(err => {
                    console.log("survey validate:", err);
                    return reject(err);
                })
        })
    }

    public static listPage = async (name: string, project: string, page: number, limit: number): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = SEARCH_SURVEY_GROUP_URL;
            var queryParams: string[] = [];
            if (name) {
                queryParams.push(`name=${encodeURIComponent(name)}`)
            }
            if (page != undefined) {
                queryParams.push(`page=${page}`)
                // s = s + "&page=" + page;
            }
            if (limit != undefined) {
                queryParams.push(`limit=${limit}`)
                // s = s + "&limit=" + limit;
            }
            if (project) {
                queryParams.push(`projectId=${encodeURIComponent(project)}`)
            }
            apiClient.get(s + '?' + queryParams.join('&'),
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    return resolve(response);
                }).catch(err => {
                    console.log("list survey group error:", err);
                    return reject(err);
                })
        })
    }

    public static  listProjectForSurvey = async (): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = LIST_PROJECT_SURVEY;
            var queryParams: string[] = [];
                queryParams.push(`usedInSurvey=true`)

            apiClient.get(s + '?' + queryParams.join('&'),
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    return resolve(response);
                }).catch(err => {
                    console.log("list survey group error:", err);
                    return reject(err);
                })
        })
    }

    public static listSurveyQuestionPage = async (questionGroupId: string, keyword: string, page: number, limit: number): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = SEARCH_SURVEY_QUESTION_URL;
            var queryParams: string[] = [];
            if (questionGroupId) {
                queryParams.push(`questionGroupId=${encodeURIComponent(questionGroupId)}`)
            }
            if (page != undefined) {
                queryParams.push(`page=${page}`)
                // s = s + "&page=" + page;
            }
            if (limit != undefined) {
                queryParams.push(`limit=${limit}`)
                // s = s + "&limit=" + limit;
            }
            if (keyword) {
                queryParams.push(`keyword=${encodeURIComponent(keyword)}`)
            }
            apiClient.get(s + '?' + queryParams.join('&'),
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    return resolve(response);
                }).catch(err => {
                    console.log("list survey question error:", err);
                    return reject(err);
                })
        })
    }

    public static previewSurveyQuestion = async (questionId: string|null, required: string | null, otherAnswer: boolean | null): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = PREVIEW_SURVEY_QUESTION;
            var queryParams: string[] = [];
            if (questionId) {
                queryParams.push(`questionId=${encodeURIComponent(questionId)}`)
            }    // s = s + "&limit=" + limit;
            if (required) {
                queryParams.push(`required=${encodeURIComponent(required)}`)
            }
            if (otherAnswer) {
                queryParams.push(`otherAnswer=${encodeURIComponent(otherAnswer)}`)
            }
            apiClient.get(s + '?' + queryParams.join('&'),
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    return resolve(response);
                }).catch(err => {
                    console.log("list survey question error:", err);
                    return reject(err);
                })
        })
    }
    public static listSurveyQuestionGroup = async (isSuryvey:boolean): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = GET_QUESTION_GROUP_URL;
            var queryParams: string[] = [];
            if (isSuryvey) {
                queryParams.push(`getSurveyQuestion=true`)
            } 
            apiClient.get(s+ '?' + queryParams.join('&'),
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    return resolve(response);
                }).catch(err => {
                    console.log("list question group error:", err);
                    return reject(err);
                })
        })
    }

    public static getQuestionDetail = async (id: string): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = SEARCH_SURVEY_QUESTION_URL + "/" + id;
            apiClient.get(s,
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    
                    return resolve(response);
                }).catch(err => {
                    console.log("get detail question group error:", err);
                    return reject(err);
                })
        })
    }

    public static duplicateSurveyQuestion = async (id: string): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = DUPLICATE_SURVEY_QUESTION + "/" + id;
            apiClient.post(s,
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    
                    return resolve(response);
                }).catch(err => {
                    console.log("get duplicate survey question error:", err);
                    return reject(err);
                })
        })
    }

    public static listAll = async (): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = SEARCH_SURVEY_GROUP_URL + "/all";
            apiClient.get(s,
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    return resolve(response);
                }).catch(err => {
                    console.log("list all survey group error:", err);
                    return reject(err);
                })
        })
    }

    public static addSurveyGroup = async (item: any, logoFile: FileList | undefined, headerFile: FileList | undefined): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var formData = new FormData();
            if (logoFile !== undefined) formData.append("logo", logoFile[0]);
            if (headerFile !== undefined) formData.append("header", headerFile[0]);
            formData.append("items", new Blob([JSON.stringify(item)], { type: 'application/json' }));
            var s = ADD_SURVEY_GROUP_URL;
            apiClient.post(s, formData
                , {//options
                    headers: {
                        "Content-Type": "multipart/form-data; charset=utf-8",
                        'Accept': 'application/json'
                    }
                })
                .then((response) => {
                    return resolve(response);
                }).catch(err => {
                    console.log("add survey group error:", err);
                    return reject(err);
                })
        })
    }

    public static updateSurveyGroup = async (id: number, item: any, logoFile: FileList | undefined, headerFile: FileList | undefined): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var formData = new FormData();
            if (logoFile !== undefined) formData.append("logo", logoFile[0]);
            if (headerFile !== undefined) formData.append("header", headerFile[0]);
            formData.append("items", new Blob([JSON.stringify(item)], { type: 'application/json' }));

            var s = ADD_SURVEY_GROUP_URL + "/" + id;
            apiClient.post(s, formData
                , {//options
                    headers: {
                        "Content-Type": "multipart/form-data; charset=utf-8",
                        'Accept': 'application/json'
                    }
                })
                .then((response) => {
                    return resolve(response);
                }).catch(err => {
                    console.log("update survey group error:", err);
                    return reject(err);
                })
        })
    }

    public static getSurveyGroup = async (id: string): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = GET_SURVEY_GROUP_URL + "/" + id;
            apiClient.get(s,
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    return resolve(response);
                }).catch(err => {
                    console.log("get survey group error:", err);
                    return reject(err);
                })
        })
    }

    public static deleteSurveyGroup = async (id: string): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = GET_SURVEY_GROUP_URL + "/" + id;
            apiClient.delete(s,
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    return resolve(response);
                }).catch(err => {
                    console.log("delete survey group error:", err);
                    return reject(err);
                })
        })
    }

    public static deleteSurveyQuestion = async (id: string): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = SEARCH_SURVEY_QUESTION_URL + "/" + id;
            apiClient.delete(s,
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    return resolve(response);
                }).catch(err => {
                    console.log("delete question group error:", err);
                    return reject(err);
                })
        })
    }

    public static duplicateSurveyGroup = async (id: number): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = GET_SURVEY_GROUP_URL + "/" + id + "/duplicate";
            apiClient.post(s,
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    //console.log("get duplicate group response : ", response);
                    return resolve(response);
                }).catch(err => {
                    console.log("get duplicate group error:", err);
                    return reject(err);
                })
        })
    }

    public static createSurveyQuestion = async (body: SurveyQuestionRequest): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = CREATE_SURVEY_QUESTION
            apiClient.post(s, body,
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    //console.log("get duplicate group response : ", response);
                    return resolve(response);
                }).catch(err => {
                    console.log("get duplicate group error:", err);
                    return reject(err);
                })
        })
    }
    public static updateSurveyQuestion = async (body: SurveyQuestionRequest): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = UPDATE_SURVEY_QUESTION
            apiClient.put(s, body,
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    //console.log("get duplicate group response : ", response);
                    return resolve(response);
                }).catch(err => {
                    console.log("get duplicate group error:", err);
                    return reject(err);
                })
        })
    }


    //form
    public static get = async (id: string, class_id: string | null, class_course_id: string | null, class_course_session_id: string | null): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = SURVEY_URL + "/" + id;
            if (class_id !== undefined && class_id !== null) s = s + "/class/" + class_id;
            else if (class_course_id !== undefined && class_course_id !== null) s = s + "/class_course/" + class_course_id;
            else if (class_course_session_id !== undefined && class_course_session_id !== null) s = s + "/class_course_session/" + class_course_session_id;
            apiClient.get(s,
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    return resolve(response);
                }).catch(err => {
                    console.log("get survey error:", err);
                    return reject(err);
                })
        })
    }

    public static getSurveyPublic = async (id: string, class_id: string | null, class_course_id: string | null, class_course_session_id: string | null, code: string): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = SURVEY_PUBLIC_URL + "/" + id;
            var queryParams: string[] = [];
            if (class_id) {
                queryParams.push(`class_id=${class_id}`)
            }
            if (class_course_id) {
                queryParams.push(`class_course_id=${class_course_id}`)
            }
            if (class_course_session_id) {
                queryParams.push(`class_course_session_id=${class_course_session_id}`)
            }
            if (code) {
                queryParams.push(`code=${code}`)
            }
            axios.get(s + '?' + queryParams.join('&'),
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    console.log("get survey response : ", response);
                    return resolve(response);
                }).catch(err => {
                    console.log("get survey error:", err);
                    return reject(err);
                })
        })
    }


    public static submit = async (id: string, class_id: string | null, class_course_id: string | null, class_course_session_id: string | null, item: any, code: string | undefined): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = SURVEY_URL + "/" + id;
            if (class_id !== undefined) s = s + "/class/" + class_id;
            else if (class_course_id !== undefined) s = s + "/class_course/" + class_course_id;
            else if (class_course_session_id !== undefined) s = s + "/class_course_session/" + class_course_session_id;
            if (!code) {
                apiClient.post(s, item,
                    {
                        headers: {
                            "Accept": "application/json"
                        }
                    })
                    .then((response) => {
                        return resolve(response);
                    }).catch(err => {
                        console.log("submit survey error:", err);
                        return reject(err);
                    })
            } else {
                axios.post(s, item,
                    {
                        headers: {
                            "Accept": "application/json"
                        }
                    })
                    .then((response) => {
                        return resolve(response);
                    }).catch(err => {
                        console.log("submit survey error:", err);
                        return reject(err);
                    })
            }

        })
    }

}