import { Dispatch } from 'redux';
import { ActionType, SearchActionType } from '../search/types';
import { ClassAPI } from '../../api/class-api';
import { ProjectAPI } from '../../api/project-api';
import { PartnerAPI } from '../../api/partner-api';
import { TrainerPageAPI } from '../../api/trainer-page-api';
import { dispatchError } from '../search/actions'; 

export const listPartnerWithOutPage = () => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING,
        page: "class"
    });
    TrainerPageAPI.listPartnerWithOutPage().then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "partner_with_out_page",
            page: "class",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("class",dispatch,error);
    })
}

export const listproject = () => (dispatch: Dispatch<any>) => {
    dispatch({
         type: SearchActionType.QUERY_LOADING
     });
     TrainerPageAPI.listProject().then(function (response) {
         dispatch({
             type: SearchActionType.QUERY_LOADED,
             payload: response.data,
             table: "project",
             page: "class",
             action: ActionType.QUERY
         });
     }, function (error) {
        dispatchError("class",dispatch,error);
     })
 };

 export const listclass = (project: number , partner: number, fromDate: Date | null, toDate: Date | null, page: number,limit: number) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    TrainerPageAPI.listClass(project,partner,fromDate, toDate,page,limit).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "class",
            page: "class",
            action: ActionType.QUERY
        });
    }, function (error) {
       /* dispatch({
            type: SearchActionType.QUERY_ERROR,
            payload: error.response.data
        });*/
       dispatchError("class",dispatch,error);
    })
};

// export const deleteclass = (id: string) => (dispatch: Dispatch<any>) => {
//     dispatch({
//         type: SearchActionType.QUERY_UPDATING
//     });
//     ClassAPI.deleteClass(id).then(function (response) {
//         dispatch({
//             type: SearchActionType.QUERY_UPDATED,
//             payload: response.data,
//             table: "class",
//             page: "class",
//             action: ActionType.DELETE
//         });
//     }, function (error) {
//        dispatchError("class",dispatch,error);
//     })
// };

export const getclass= (classId: number) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    TrainerPageAPI.getClass(classId.toString()).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "class",
            page: "edit_assignment",
            action: ActionType.QUERY
        });
    }, function (error) {
    dispatchError("edit_assignment",dispatch,error);
    })
};

export const listcourse= (classId: number) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    TrainerPageAPI.listCourse(classId).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "list_course",
            page: "edit_assignment",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("edit_assignment",dispatch,error);
    })
};

export const listassignment= (classId: number,param: string,page: number,limit: number) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    TrainerPageAPI.listAssignment(classId,param,page,limit).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "list_assignment",
            page: "edit_assignment",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("edit_assignment",dispatch,error);
    })
};

export const listTrainer= (classId: number) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    TrainerPageAPI.listTrainer(classId).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "list_trainer",
            page: "assignment",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("assignment",dispatch,error);
    })
};

export const getassignment= (classId: number,classStudentId: number) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    TrainerPageAPI.getassignment(classId,classStudentId).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "assignment",
            page: "assignment",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("assignment",dispatch,error);
    })
};

export const saveassignment = (item: any) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_UPDATING
    });
    TrainerPageAPI.saveAssignment(item).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_UPDATED,
            payload: response.data,
            table: "assignment",
            page: "assignment",
            action: ActionType.UPDATE
        });
    }, function (error) {
        if ((error.response!==undefined) && (error.response.status===400)) {
            dispatch({
                type: SearchActionType.QUERY_ERROR,
                page: "assignment",
                payload: error.response.data.additional
            });
        } else 
            dispatchError("assignment",dispatch,error);
    })
};

export const getFeedBack= (classId: number,classStudentId:number,documentId:number,isStudent:boolean,isAdmin:boolean) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    TrainerPageAPI.getFeedBack(classId,classStudentId,documentId,isStudent,isAdmin).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "feedback_list",
            page: "comment_modal",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("comment_modal",dispatch,error);
    })
};

export const saveFeedback = (item: any,isStudent:boolean,isAdmin:boolean) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_UPDATING
    });
    TrainerPageAPI.saveFeedBack(item,isStudent,isAdmin).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_UPDATED,
            payload: response.data,
            table: "save_feedback",
            page: "comment_modal",
            action: ActionType.UPDATE
        });
    }, function (error) {
        if ((error.response!==undefined) && (error.response.status===400)) {
            dispatch({
                type: SearchActionType.QUERY_ERROR,
                page: "comment_modal",
                payload: error.response.data.additional
            });
        } else 
            dispatchError("comment_modal",dispatch,error);
    })
};

