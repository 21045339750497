import { LoginType } from "../../api/define";

export interface AuthState {
    isLoading: boolean;
    isLoggedIn: boolean;
    isFailed: boolean;
    isChanged: boolean;
    username: string;
    email: string;
    depId: string;
    deptName: string;
    token: any;
    refreshTime: number;
    usertype: LoginType;
    msg: string;
}

export enum AuthActionType {
    LOGIN_LOADING = "LOGIN_LOADING",
    LOGIN_SUCCESS = "LOGIN_SUCCESS",
    LOGIN_FAIL = "LOGIN_FAIL",
    REFRESH_SUCCESS = "REFRESH_SUCCESS",
    CHANGE_PASSWORD_LOADING = "CHANGE_PASSWORD_LOADING",
    CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS",
    CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL",
    LOGOUT = "LOGOUT",
}

export type AuthAction = AuthActionType;

export interface AuthActionInterface {
    type: AuthAction;
    payload?: any;
}