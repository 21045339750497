import React, { useState } from 'react'
import { PartnerProfileListResponse } from '../../../../model/response/partner-profile';
import ExpandMore from '@material-ui/icons/ExpandMore'
import ExpandLess from '@material-ui/icons/ExpandLess'
import { Util } from '../../../../api/util';
type Props = {
    parnerProfileList: PartnerProfileListResponse;
    success: boolean;
    submitted: boolean;
}

const PartnerHistory = (props: Props) => {
    const [expand, setExpand] = useState(false);
  return (
    <React.Fragment>
            <div className="text-left" style={{ position: "relative", marginBottom: 5 }}>
                <div className='d-flex yellow-button' style={{ padding: "15px", borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}>
                    {((expand === undefined) || (expand === false)) &&
                        <div onClick={(e) => setExpand(true)}><ExpandMore className="pointer mr-2" ></ExpandMore></div>
                    }
                    {(expand === true) &&
                        <div onClick={(e) => setExpand(false)}><ExpandLess className="pointer mr-2" ></ExpandLess></div>
                    }
                    <label className='ml-2 my-auto'><span style={{ fontWeight: 600, marginRight: 20 }}>ประวัติรายการ</span></label>
                </div>
                {(expand === true) && (
                    <div style={{ padding: "15px", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px", border: "3px solid #fab232" }}>
                        <div>
                            <table className='table mt-3' style={{ fontSize: "20px" }}>
                                <thead className='black-button'>
                                    <tr>
                                        <th style={{ width: '30%' }}>วันเวลาที่ปรับปรุงล่าสุด</th>
                                        <th style={{ width: '50%' }}>ปรับปรุงล่าสุดโดย</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody className='light-gray-button'>
                                    {props.parnerProfileList?.partnerRegistrationHistoryResponses && props.parnerProfileList?.partnerRegistrationHistoryResponses.map(x => (
                                        <tr key={'history' + x.id}>
                                            <td>{Util.datetimetostr(new Date(x.createDate))}</td>
                                            <td>
                                                {x.createdBy}
                                            </td>
                                            <td>{x.trainsition}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
            </div>
        </React.Fragment>
  )
}

export default PartnerHistory