import React, { useState } from 'react'
import ExpandMore from '@material-ui/icons/ExpandMore'
import ExpandLess from '@material-ui/icons/ExpandLess'
type Props = {}

const MergePartnerToDatabaseSuggestion = (props: Props) => {
    const [expand, setExpand] = useState(true)
    return (
        <div>
            <div className="gray-button text-left" style={{ padding: "15px", borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}>
                {((expand === undefined) || (expand === false)) &&
                    <div onClick={(e) => setExpand(true)}><ExpandMore className="pointer mr-2" ></ExpandMore>คำอธิบายการใช้งาน</div>
                }
                {(expand === true) &&
                    <div onClick={(e) => setExpand(false)}><ExpandLess className="pointer mr-2" ></ExpandLess>คำอธิบายการใช้งาน</div>
                }
            </div>
            {expand && (
                <div className="bg-white text-left" style={{ padding: "15px", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px" }}>
                    <div className='row'>
                        <div className="col-12">
                            ขั้นตอนการใช้งานเป็นดังนี้
                        </div>
                        <div className="col-12 pl-4">
                            1. เพิ่มประเภทความรู้
                        </div>
                        <div className="col-12 pl-4">
                            2. เพิ่มประเภทความรู้ในแต่ละหัวข้อ
                        </div>
                        <div className='col-12 pl-4'>
                            3. เพิ่มเอกสารในแต่ละ Media Type
                        </div>
                    </div>
                </div>
            )}

        </div>
    )
}

export default MergePartnerToDatabaseSuggestion