import React, { useEffect, useState } from 'react'
import PartnerHeader from './PartnerHeader'
import PartnerStepper from './PartnerStepper'
import { Banner, CardBox, PButton, PartnerContainer } from '../../style/partnerStylemain'
import banner from '../../img/partnerBanner.png';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { useHistory } from 'react-router-dom';
import { StepPage } from './constant/StepPage';
type Props = {}

const PartnerMain = (props: Props) => {
    const history = useHistory()
    const goToChooseTopic = () => {
        history.push('/partner/choose-topic');
    }
    useEffect(()=>{
        sessionStorage.removeItem("publicAccessCode");
        sessionStorage.removeItem("listCategory");
        sessionStorage.removeItem("summaryState");
    },[])
    return (
        <div className='bg-light-gray' style={{ minHeight: "100vh", fontFamily: "DB Helvethaica" }}>
            <div style={{ position: 'sticky', top: 0 }}>
                <div className="student-wrapper-header-inner" style={{ background: "#38383D" }}>
                    <PartnerHeader />
                </div>
                
            </div>

            <PartnerContainer className='mt-4'>
                <Banner src={banner}></Banner>
                <h5 className='text-center mt-3' >แบบฟอร์มแจ้งความสนใจส่งเสริมความรู้ในองค์กร <br /> ร่วมกับตลาดหลักทรัพย์แห่งประเทศไทย</h5>
                <CardBox>
                    <h4 className='p-text-orange' ><InfoOutlinedIcon /> คำชี้แจง</h4>
                    <div>กรุณากรอกข้อมูลและเลือกหัวข้อที่สนใจส่งเสริมความรู้ทางการเงินในองค์กร โดยมีขั้นตอนดังนี้</div>
                    <PartnerStepper stepNumber={StepPage.MAIN} />
                </CardBox>
                <PButton className='ml-auto mt-3' onClick={goToChooseTopic}>ถัดไป &gt;</PButton>
            </PartnerContainer>
        </div>
    )
}

export default PartnerMain