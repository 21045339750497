import { Dispatch } from 'redux';
import { ActionType, SearchActionType } from '../search/types';
import { ClassAPI } from '../../api/class-api';
import { ProjectAPI } from '../../api/project-api';
import { PartnerAPI } from '../../api/partner-api';
import { dispatchError } from '../search/actions'; 

export const listproject = () => (dispatch: Dispatch<any>) => {
   dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    ProjectAPI.list().then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "project",
            page: "class",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("class",dispatch,error);
    })
};

export const listpartner = (name: string ,code: string,orgType: string | null,page: number,limit: number) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING,
        page: "class"
    });
    PartnerAPI.list(name,code,orgType,page,limit,null).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "partner",
            page: "class",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("class",dispatch,error);
    })
};


export const listPartnerWithOutPage = () => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING,
        page: "class"
    });
    PartnerAPI.listWithoutPage().then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "partner_with_out_page",
            page: "class",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("class",dispatch,error);
    })
}

export const listclass = (project: number , partner: number, fromDate: Date | null, toDate: Date | null, page: number,limit: number,session:string|null,sort:string|null) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    ClassAPI.list(project,partner,fromDate, toDate,page,limit,session,sort).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "class",
            page: "class",
            action: ActionType.QUERY
        });
    }, function (error) {
       /* dispatch({
            type: SearchActionType.QUERY_ERROR,
            payload: error.response.data
        });*/
       dispatchError("class",dispatch,error);
    })
};

export const deleteclass = (id: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_UPDATING
    });
    ClassAPI.deleteClass(id).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_UPDATED,
            payload: response.data,
            table: "class",
            page: "class",
            action: ActionType.DELETE
        });
    }, function (error) {
       dispatchError("class",dispatch,error);
    })
};

