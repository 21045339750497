import { LoginType } from "../../api/define";
import { AuthState, AuthActionType, AuthActionInterface } from "./types";

const initialState: AuthState = {
    isLoading: false,
    isLoggedIn: false,
    isFailed: false,
    isChanged: false,
    username: '',
    email: '',
    depId:'',
    deptName:'',
    usertype: LoginType.ADMIN,
    token: {},
    refreshTime: 0,
    msg: '',
};

export function AuthReducer(state = initialState, action: AuthActionInterface): AuthState {
    switch (action.type) {
        case AuthActionType.LOGIN_LOADING:
            return {
                ...state,
                isLoading: true,
                isLoggedIn: false,
                isFailed: false
            };
        case AuthActionType.LOGIN_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isLoggedIn: true,
                token: action.payload,
                username: action.payload.username,
                usertype: action.payload.usertype,
                email: action.payload?.email,
                depId: action.payload?.hrDepartment?.id,
                deptName: action.payload?.hrDepartment?.name,
                refreshTime: Math.floor(new Date().getTime()/1000),
                isFailed: false
            };
        case AuthActionType.LOGIN_FAIL:
            return {
                ...state,
                isLoading: false,
                isLoggedIn: false,
                isFailed: true,
                msg: action.payload
            }
        case AuthActionType.LOGOUT:
            return {
                ...state,
                isLoading: false,
                isLoggedIn: false,
                isFailed: false,
                username: '',
                email: '',
                token: {},
                refreshTime: 0,
                msg: '',
            }
        case AuthActionType.REFRESH_SUCCESS:
            return {
                ...state,
                token: action.payload,
                refreshTime: Math.floor(new Date().getTime()/1000),
            }
        case AuthActionType.CHANGE_PASSWORD_LOADING:
            return {
                ...state,
                isLoading: true,
                isChanged: false,
                isFailed: false
            }
        case AuthActionType.CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isChanged: true,
                isFailed: false,
                msg: action.payload
            }
        case AuthActionType.CHANGE_PASSWORD_FAIL:
            return {
                ...state,
                isLoading: false,
                isChanged: false,
                isFailed: true,
                msg: action.payload
            }
        default:
            return state;
    }
}
