import { useEffect, useState } from 'react';
import { Button, InputGroup, Modal } from 'react-bootstrap';
import { useDispatch, useStore } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { INTERNAL_ERROR_LABEL } from '../../api/label';
import { queryclear } from '../../store/search/actions';
import MainMenu  from './menu';
import loadinglogo from '../../img/loading.gif';
import Footer from './adminfooter';
import { listpartner,listPartnerWithOutPage,gettrainer,createtrainer,updatetrainer } from '../../store/trainer/actions';
import { AlertContainer, alert } from 'react-custom-alert';
import { SingleValue } from 'react-select';
import { SingleSearch } from '../tab/search-component';

const EditTrainer = () => {
  const [initTrainer,setInitTrainer] = useState(0)
  const [initPartner,setInitPartner] = useState(0)
  const [edit,setEdit] = useState(false)
  const [leave,setLeave] = useState("")
  const [partnerItem,setPartnerItem] = useState<Array<any>>([])
  const [item,setItem] = useState<any>({})
  const [defaultItem,setDefaultItem] = useState<any>({firstName:"",lastName:"",position:"",email:null,phone:null,mobile:null,detail:null,isPartner:false,partnerId:undefined})
  const [loading,setLoading] = useState(false)
  const [updating,setUpdating] = useState(false)
  const [success,setSuccess] = useState(false)
  const [error, setError] = useState('');
  const store = useStore();
  const dispatch = useDispatch();
  const history = useHistory(); 
  let { id } = useParams<{ id: string}>();

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      let res = store.getState().SearchReducer;
      if (res.isLoading) {
          setError('');
          setLoading(true);
      } else if (res.isLoaded) {
          setError('');
          setLoading(false);
          if (res.data!=null) {
              if (res.table==="partner_with_out_page") { 
                setInitPartner(1);
                setPartnerItem(res.data);    
              } else {
                  setInitTrainer(1);
                  setDefaultItem(JSON.parse(JSON.stringify(res.data))); 
                  setItem(res.data);
                  setEdit(false);
              }
          } else {
              setError(INTERNAL_ERROR_LABEL);
          } 
      } else if (res.isUpdating) {
          setError('');
          setUpdating(true);
      } else if (res.isUpdated) {
          setError('');
          setUpdating(false);
          setEdit(false);
          setSuccess(true);
          alert({ message: 'Saved', type: 'success' });
          setDefaultItem(JSON.parse(JSON.stringify(res.data)));
          setItem(res.data);
          dispatch(queryclear());
      } else if (res.isFailed) {
          if (res.data.success!==undefined) {
            setItem(res.data);
            alert({ message: res.data.popup??'Error', type: 'error' });
            window.scrollTo(0, 0);
          }
          setError(res.data.message);
          setLoading(false);
          setUpdating(false);
      } 
    });
    dispatch(queryclear());
    dispatch(listPartnerWithOutPage());
    window.scroll(0,0);
    if (id!=null) { 
       dispatch(gettrainer(parseInt(id)));
    } else {
        setInitTrainer(1);
    }
    return unsubscribe;
   }, [])

  const handleSubmit = () => {
    if (updating) return;
    setSuccess(false);
    dispatch(queryclear());
    if (item.id===undefined) dispatch(createtrainer(item)); else dispatch(updatetrainer(parseInt(item.id),item));
  }

  const clear = () => {
    setItem(JSON.parse(JSON.stringify(defaultItem)));
    setEdit(false);
  }
  const handleClear = () => {
    if (edit) setLeave("clear"); else 
    clear();
  }

  const handleClose = () => {
    history.push("/admin/search/trainer");
 }

  const handleBack = () => {
    if (edit) setLeave("/admin/search/trainer"); else   
    history.push("/admin/search/trainer");
  }

  const handleReedit = () => {
    setSuccess(false);
  }

  const handleLeave = ()=> {
    if (leave==="clear")
    {
        clear();
    } else {
        history.push(leave); 
    }
    setLeave("");
  }

  const setFirstName = (e: string)  => {
    if (updating) return ;
    var newItem = {...item};
    newItem.firstName=e;
    setItem(newItem);
    setEdit(true);
  }

  const getFirstName = ()  => {
    return item.firstName;
  }

  const getErrorFirstName = ()  => {
    return item.errorFirstName;
  }
  const setLastName = (e: string)  => {
    if (updating) return ;
    var newItem = {...item};
    newItem.lastName=e;
    setItem(newItem);
    setEdit(true);
  }

  const getLastName = ()  => {
    return item.lastName;
  }

  const getErrorLastName = ()  => {
    return item.errorLastName;
  }

  const setPosition = (e: string)  => {
    if (updating) return ;
    var newItem = {...item};
    newItem.position=e;
    setItem(newItem);
    setEdit(true);
  }

  const getPosition = ()  => {
    return item.position;
  }

  const getErrorPosition = ()  => {
    return item.errorPosition;
  }

  const setEmail = (e: string)  => {
    if (updating) return ;
    var newItem = {...item};
    newItem.email=e;
    setItem(newItem);
    setEdit(true);
  }

  const getEmail = ()  => {
    return item.email;
  }

  const getErrorEmail = ()  => {
    return item.errorEmail;
  }

  const setPartner = (e: number)  => {
    if (updating) return ;
    var newItem = {...item};
    newItem.partnerId=e;
    for (var i=0;i<partnerItem.length;i++)
    if (partnerItem[i].id===e) {
        newItem.partner=partnerItem[i].name;
    }
    setItem(newItem);
    setEdit(true);
  }

  const getPartner = ()  => {
    var name="";
    var e=item.partnerId;
    for (var i=0;i<partnerItem.length;i++)
    if (partnerItem[i].id===e) {
        name=partnerItem[i].name;
    }
    return {value: item.partnerId,label:name};
  }

  const setIsPartner = (e: boolean)  => {
    if (updating) return ;
    var newItem = {...item};
    newItem.isPartner=e;
    if (!e) {
      newItem.partner=undefined;
      newItem.partnerId=undefined;
    }
    setItem(newItem);
    setEdit(true);
  }

  const getIsPartner = () => {
    if (item.isPartner!==undefined) {
      return item.isPartner;
    }
    return (item.partnerId!==undefined); 
  }

  const getErrorPartner = ()  => {
    return item.errorPartner;
  }

  const setMobile = (e: string)  => {
    if (updating) return ;
    var newItem = {...item};
    newItem.mobile=e;
    setItem(newItem);
    setEdit(true);
  }

  const getMobile = ()  => {
    return item.mobile;
  }

  const getErrorMobile = ()  => {
    return item.errorMobile;
  }

  const setPhone = (e: string)  => {
    if (updating) return ;
    var newItem = {...item};
    newItem.phone=e;
    setItem(newItem);
    setEdit(true);
  }

  const getPhone = ()  => {
    return item.phone;
  }

  const getErrorPhone = ()  => {
    return item.errorPhone;
  }

  const setDetail = (e: string)  => {
    if (updating) return ;
    var newItem = {...item};
    newItem.detail=e;
    setItem(newItem);
    setEdit(true);
  }

  const getDetail = ()  => {
    return item.detail;
  }

  const getErrorDetail = ()  => {
    return item.errorDetail;
  }

  const createPartnerSearchFilter = () => {
    var res:any[]=[];
    for (var i=0;i<partnerItem.length;i++)
    {
        res.push({label:partnerItem[i].name,value:partnerItem[i].id});
    }
    return res;
}

  return (<>
        <MainMenu>
        <div style={{minWidth:"1200px"}}>
        <div className="query-wrapper">
        <br/>
        <div className="yellow-button" style={{textAlign:"left" ,borderTopLeftRadius:"30px", borderTopRightRadius:"30px", height:"70px",paddingTop:"15px",paddingLeft:"15px"}}><h4 className="header">ข้อมูลวิทยากร</h4></div>
        { (initPartner+initTrainer===2) &&
        <>
        <div style={{background: '#EEEEEE', borderBottomLeftRadius: '5px',borderBottomRightRadius: '5px',paddingLeft:"20px",paddingRight:"20px",paddingBottom:"20px",textAlign:"left"}}>
            <div className="row">
                <div className="col-6">                           
                    <label className="col-form-label">ชื่อ <span className='red'>*</span></label><br/>
                    <InputGroup hasValidation>
                    <input maxLength={128} className={"form-control "+((getErrorFirstName()!=null)?"is-invalid":"")} onChange={e=>{ setFirstName(e.target.value)} } value={getFirstName()} disabled={success}/><br/>
                    <div className={(getErrorFirstName()!=null)?"invalid-feedback":""}>{getErrorFirstName()}</div>
                    </InputGroup>
                </div>
                <div className="col-6">                           
                    <label className="col-form-label">นามสกุล <span className='red'>*</span></label><br/>
                    <InputGroup hasValidation>
                    <input maxLength={128} className={"form-control "+((getErrorLastName()!=null)?"is-invalid":"")} onChange={e=>{ setLastName(e.target.value)} } value={getLastName()} disabled={success}/><br/>
                    <div className={(getErrorLastName()!=null)?"invalid-feedback":""}>{getErrorLastName()}</div>
                    </InputGroup>
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <label className="col-form-label">ตำแหน่ง</label><br/>
                    <InputGroup hasValidation>
                    <input maxLength={256} className={"form-control "+((getErrorPosition()!=null)?"is-invalid":"")} onChange={e=>{ setPosition(e.target.value)} }  value={getPosition()} disabled={success}/><br/>
                    <div className={(getErrorPosition()!=null)?"invalid-feedback":""}>{getErrorPosition()}</div>
                    </InputGroup>
                </div>
                <div className="col-6">

                <label className="col-form-label">องค์กร</label><br/>
                    <div className="row" style={{paddingLeft:"40px"}}>
                      <input className="form-check-input" type="radio" checked={!getIsPartner()} onClick={e=>{ setIsPartner(false); }} disabled={success}/>
                    <label className="form-check-label" onClick={e=>{setIsPartner(false);}}>
                      ไม่มีสังกัด
                    </label>
                    </div>
                    <div className="row" style={{paddingLeft:"40px"}}>
                    <input className="form-check-input" type="radio" checked={getIsPartner()} onClick={e=>{ setIsPartner(true); }} disabled={success}/>
                    <label className="form-check-label" onClick={e=>{setIsPartner(true);}}>
                      มีสังกัด
                    </label>
                    </div>
                    <InputGroup hasValidation>
                    <SingleSearch isError={getErrorPartner()!=null} disabled={success || !(getIsPartner())} isSearchable={true} isMulti={false} placeholder=""
                        onChange={(id: string,e: any)=>setPartner(e.value)} 
                        value={getPartner()} 
                        options={createPartnerSearchFilter()}
                        style={{width:"600px"}}
                        />
                    <div className={(getErrorPartner()!=null)?"invalid-feedback":""}>{getErrorPartner()}</div>
                    </InputGroup>
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <label className="col-form-label">เบอร์ที่ทำงาน</label><br/>
                    <InputGroup hasValidation>
                    <input maxLength={128} className={"form-control "+((getErrorPhone()!=null)?"is-invalid":"")} onChange={e=>{ setPhone(e.target.value)} }  value={getPhone()} disabled={success}/><br/>
                    <div className={(getErrorPhone()!=null)?"invalid-feedback":""}>{getErrorPhone()}</div>
                    </InputGroup>
                </div>
                <div className="col-6">
                    <label className="col-form-label">เบอร์โทรศัพท์มือถือ (ไม่มีขีด)</label><br/>
                    <InputGroup hasValidation>
                    <input maxLength={256} className={"form-control "+((getErrorMobile()!=null)?"is-invalid":"")} onChange={e=>{ setMobile(e.target.value)} }  value={getMobile()} disabled={success}/><br/>
                    <div className={(getErrorMobile()!=null)?"invalid-feedback":""}>{getErrorMobile()}</div>
                    </InputGroup>
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <label className="col-form-label">e-Mail</label><br/>
                    <InputGroup hasValidation>
                    <input maxLength={256} className={"form-control "+((getErrorEmail()!=null)?"is-invalid":"")} onChange={e=>{ setEmail(e.target.value)} }  value={getEmail()} disabled={success}/><br/>
                    <div className={(getErrorEmail()!=null)?"invalid-feedback":""}>{getErrorEmail()}</div>
                    </InputGroup>
                </div>
                <div className="col-6">
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <label className="col-form-label">รายละเอียดอื่นๆ</label><br/>
                    <InputGroup hasValidation>
                    <input maxLength={256} className={"form-control "+((getErrorDetail()!=null)?"is-invalid":"")} onChange={e=>{ setDetail(e.target.value)} }  value={getDetail()} disabled={success}/><br/>
                    <div className={(getErrorDetail()!=null)?"invalid-feedback":""}>{getErrorDetail()}</div>
                    </InputGroup>
                </div>
            </div>
        </div>
        <br/>
        </>
        }
        <br/>
        { (((initTrainer+initPartner<2) || (loading) || (updating)) && (error==="")) &&
            <div style={{ textAlign: "center" }}><img src={loadinglogo} width="30" height="30" className="d-inline-block align-top" alt="" /></div>
        }
        { (error!=="") &&
            <div style={{ color: 'red', textAlign: "center" }}>{error}</div>
        }
        { ((initTrainer+initPartner===2) && (!loading) && (!updating) && (!success)) &&
            <div style={{justifyContent:"right",textAlign:"right"}}>
                <button type="button" className="btn btn-outline-primary" style={{width:"120px"}}
                        onClick={() => handleBack()} >
                        Back
                </button>&nbsp;
                <button type="button" className="btn btn-danger" style={{width:"120px"}}
                        onClick={() => handleClear()} >
                        Reset
                </button>&nbsp;
                <button type="button" className="btn btn-success" style={{width:"120px"}}
                        onClick={() => handleSubmit()} >
                        Save
                </button>
            </div>
        }

    {  (success) && 
        <div style={{justifyContent:"center",textAlign:"center"}}>
        <button type="button" className="btn btn-outline-primary" style={{width:"120px"}}
                onClick={() => handleClose()} >
                Back
        </button>&nbsp;
        <button type="button" className="btn yellow-button" style={{width:"120px"}}
                onClick={() => handleReedit()} >
                Edit
        </button>
        </div>
    }
        </div>
        <br/>
        <Footer/>
        </div>
        </MainMenu>
        <Modal show={leave!==""} onHide={()=>{ setLeave("") }}>
        <Modal.Header closeButton>
            <Modal.Title>ยกเลิกข้อมูลที่แก้ไข?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
            <Button variant="secondary" onClick={()=>{ setLeave("") }}>
            Cancel
            </Button>
            <Button variant="danger" onClick={()=>{ handleLeave(); }}>
            OK
            </Button>
        </Modal.Footer>
        </Modal>
        <AlertContainer floatingTime={10000} />
        </>)
}

export default EditTrainer;
