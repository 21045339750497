import { useEffect } from 'react';
import { useDispatch, useStore } from 'react-redux';
import { logout } from '../../store/auth/actions';
import { LOGIN_URL } from '../../api/auth-api';

const SignIn = () => {
    const dispatch = useDispatch();
    const store = useStore();

    useEffect(() => {
        const unsubscribe = store.subscribe(() => {
            let res = store.getState().AuthReducer;
            if (!res.isLoggedIn) {
                window.location.href=LOGIN_URL;
            }
          });
        dispatch(logout);
        window.location.href=LOGIN_URL;
        return unsubscribe;
       }, [])
    return <></>;
}

export default SignIn;
