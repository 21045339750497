import { SearchState, SearchActionType, SearchActionInterface, ActionType } from "./types";

const initialState: SearchState = {
    isLoading: false,
    isLoaded: false,
    isUpdating: false,
    isUpdated: false,
    isFailed: false,
    data: null,
    table: "",
    page: "",
    action: ActionType.QUERY
};

export function SearchReducer(state = initialState, action: SearchActionInterface): SearchState {
    switch (action.type) {
        case SearchActionType.QUERY_LOADING:
            return {
                ...state,
                isLoading: true,
                isLoaded: false,
                isFailed: false,
                page: action.page,
                data: null
            };
        case SearchActionType.QUERY_LOADED:
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                isFailed: false,
                data: action.payload,
                table: action.table,
                page: action.page,
                action: action.action
        };
        case SearchActionType.QUERY_UPDATING:
            return {
                ...state,
                isUpdating: true,
                isUpdated: false,
                isFailed: false,
                page: action.page,
                data: null
            };
        case SearchActionType.QUERY_UPDATED:
            return {
                ...state,
                isUpdating: false,
                isUpdated: true,
                isFailed: false,
                data: action.payload,
                table: action.table,
                page: action.page,
                action: action.action
        };
        case SearchActionType.QUERY_NOTFOUND:
            return {
                ...state,
                isLoading: false,
                isLoaded: false,
                isFailed: true,
                page: action.page,
                data: null
            }
        case SearchActionType.QUERY_ERROR:
            return {
                ...state,
                isLoading: false,
                isLoaded: false,
                isUpdating: false,
                isUpdated: false,
                isFailed: true,
                page: action.page,
                data: action.payload,
                table: action.table
            }
        case SearchActionType.QUERY_CLEAR:
            return  {
                ...state,
                isLoading: false,
                isLoaded: false,
                isUpdating: false,
                isUpdated: false,
                isFailed: false,
                data: null
            }
        default:
            return state;
    }
}
