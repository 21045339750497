import  {forwardRef, Ref } from 'react'
import DateTimePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { registerLocale } from "react-datepicker";
import th from 'date-fns/locale/th'

registerLocale('th', th)
//setDefaultLocale('th')

function createYear(d: Date): ReadonlyArray<number> {
  var res:any[]=[];
  var y=d.getFullYear();
  for (var i=y-20;i<y+20;i++)
  {
    res.push(i);
  }
  return res;
}

const months = [
  "มกราคม",
  "กุมภาพันธ์",
  "มีนาคม",
  "เมษายน",
  "พฤษภาคม",
  "มิถุนายน",
  "กรกฎาคม",
  "สิงหาคม",
  "กันยายน",
  "ตุลาคม",
  "พฤศจิกายน",
  "ธันวาคม",
];

//Ref<CountdownHandle>
export const ThaiDateCustomInput = forwardRef((props: any, ref: Ref<HTMLInputElement>) => {
  //const [v,setV] = useState("");
  //const inputRef = useRef<HTMLInputElement>(null);
 /*useImperativeHandle(ref, () => ({
  start() {
    alert('Start');
  }
  }));*/

  var displayValue: string=props.value;
  var vs: string[]=(props.value as string).split("/");
  if (vs.length===3) {
    var year=parseInt(vs[2]);
    var month=parseInt(vs[1]);
    var date=parseInt(vs[0]);
    var d=new Date(year,month, date,0,0,0,0)
    if ( (!isNaN(d.getDate())) && (year>2000) && (year<9999) &&(date>=1) && (date<=31) && (month>=1) && (month<=12))
    {
      displayValue=date.toString().padStart(2,"0")+"/"+month.toString().padStart(2,"0")+"/"+(year+543);
    } 
  } 
  return <input className={props.className} onChange={e=>{
    //displayValue="";
   /* var vs: string[]=(e.target.value as string).split("/");
    var cc: boolean=false;
    setV(e.target.value);
    if (vs.length===3) {
      var year=parseInt(vs[2]);
      var month=parseInt(vs[1]);
      var date=parseInt(vs[0]);
      var d=new Date(year,month, date,0,0,0,0)
      if ( (!isNaN(d.getDate())) && (year>2500) && (year<9999) &&(date>=1) && (date<=31) && (month>=1) && (month))
      { 
       cc=true;
       e.target.value=d.getDate().toString().padStart(2,"0")+"/"+d.getMonth().toString().padStart(2,"0")+"/"+(d.getFullYear()-543);
      }
    } 
    console.log("c "+e.target.value);
    if (cc) props.onChange(e);*/
    if (e.target.value.length<props.value.length)  {
      e.target.value="";
      props.onChange(e);
    }
  }} value={displayValue} onClick={props.onClick} disabled={props.disabled}/>
  } 
);

export const ThaiDateCustomHeaderNoCombo = (({
  monthDate,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled
} : any) => (
  <div>
      <button
          type='button'
          className={
              "react-datepicker__navigation react-datepicker__navigation--previous"
          }
          onClick={decreaseMonth}
          disabled={prevMonthButtonDisabled}
          >
          <span
              className={
                  "react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"
              }
              style={{marginTop:"10px"}}
          >
              {"<"}
          </span>
      </button>
      <span className="react-datepicker__current-month">
          {monthDate.toLocaleString("th-TH", {
              month: "short",
              year: "numeric",
          })}
      </span>
      <button
          type='button'
          className={
              "react-datepicker__navigation react-datepicker__navigation--next"
          }
          onClick={increaseMonth}
          disabled={nextMonthButtonDisabled}
      >
          <span
              className={
                  "react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
              }
              style={{marginTop:"10px"}}
          >
              {">"}
          </span>
      </button>
  </div>
));

export const ThaiDateCustomHeader = (({
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}: any) => (
  <div
    style={{
      margin: 10,
      display: "flex",
      justifyContent: "center",
    }}
  >
    <button
          type='button'
          className={
              "react-datepicker__navigation react-datepicker__navigation--previous"
          }
          onClick={decreaseMonth}
          disabled={prevMonthButtonDisabled}
          >
          <span
              className={
                  "react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"
              }
              style={{marginTop:"30px"}}
          >
              {"<"}
          </span>
      </button>
      <select
      value={months[date.getMonth()]}
      onChange={({ target: { value } }) =>
        changeMonth(months.indexOf(value))
      }
    >
      {months.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
    &nbsp;&nbsp;
    <select
      value={date.getFullYear()}
      onChange={({ target: { value } }) => changeYear(value)}
    >
      {createYear(date).map((option) => (
        <option key={option} value={option}>
          {option+543}
        </option>
      ))}
    </select>
    <button
          type='button'
          className={
              "react-datepicker__navigation react-datepicker__navigation--next"
          }
          onClick={increaseMonth}
          disabled={nextMonthButtonDisabled}
      >
          <span
              className={
                  "react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
              }
              style={{marginTop:"30px"}}
          >
              {">"}
          </span>
      </button>
  </div>
));

export const ThaiDateTimePicker = ((props: any) => {
  
  return (<DateTimePicker locale="th" dateFormat="dd/MM/yyyy" className={props.className} selected={props.selected} value={props.value} onSelect={(e)=>{ if (props.onSelect) props.onSelect(e)}}  onChange={(e) => { props.onChange(e,props.id) }}
            onCalendarClose={() => {if (props.onCalendarClose) props.onCalendarClose()}}
            onCalendarOpen={() => {if (props.onCalendarOpen) props.onCalendarOpen()}}
            renderCustomHeader={ThaiDateCustomHeader}
            customInput={<ThaiDateCustomInput props={{props}}/>}
            disabled={props.disabled}
          />)
});


export const ThaiTimePicker = ((props: any) => {
  
  return (<DateTimePicker locale="th" className={props.className} selected={props.selected} value={props.value} onSelect={(e)=>{ if (props.onSelect) props.onSelect(e)}}  onChange={(e) => props.onChange(e,props.id)}
          dateFormat="HH:mm" showTimeSelect showTimeSelectOnly timeIntervals={30}
          disabled={props.disabled}
          />)
});

export const ThaiDateTimePickerNoCombo = ((props: any) => {
  
  return (<DateTimePicker locale="th" dateFormat="dd/MM/yyyy" className={props.className} selected={props.selected} value={props.value} onSelect={(e)=>{ if (props.onSelect) props.onSelect(e)}}  onChange={(e) => { props.onChange(e,props.id) }}
            onCalendarClose={() => {if (props.onCalendarClose) props.onCalendarClose()}}
            onCalendarOpen={() => {if (props.onCalendarOpen) props.onCalendarOpen()}}
            renderCustomHeader={ThaiDateCustomHeaderNoCombo}
            customInput={<ThaiDateCustomInput props={{props}}/>}
            disabled={props.disabled}
          />)
});


export const ThaiTimePickerNoCombo = ((props: any) => {
  
  return (<DateTimePicker locale="th" className={props.className} selected={props.selected} value={props.value} onSelect={(e)=>{ if (props.onSelect) props.onSelect(e)}}  onChange={(e) => props.onChange(e,props.id)}
          dateFormat="HH:mm" showTimeSelect showTimeSelectOnly timeIntervals={30}
          disabled={props.disabled}
          />)
});