import React from 'react'

const tabStyle = { paddingLeft: "10px", paddingRight: "10px", paddingTop: "5px", paddingBottom: "5px",minWidth:"250px" };
const tabPointerStyle = { cursor: "pointer", paddingLeft: "10px", paddingRight: "10px", paddingTop: "5px", paddingBottom: "5px",minWidth:"250px" };

const PartnerActivityTab = (step: number, nextStep: boolean, handleOrganization: Function | null,handleReceiveMedia: Function | null) => {
    return (<div className="row" style={{ justifyContent: "left", paddingLeft: "40px" }}>
        <div className={(step === 1) ? "blue-tab-active" : "blue-tab-inactive"} style={(handleOrganization == null) ? tabStyle : tabPointerStyle} onClick={e => ((handleOrganization != null) ? handleOrganization() : () => { })}>
            <b>ข้อมูลการเข้าร่วมโครงการ</b>
        </div>&nbsp;&nbsp;
        <div className={(step === 2) ? "blue-tab-active" : "blue-tab-inactive"} style={(handleReceiveMedia == null) ? tabStyle : tabPointerStyle} onClick={e => ((handleReceiveMedia != null) ? handleReceiveMedia() : () => { })}>
            <b>ข้อมูลการรับสื่อ</b>
        </div>&nbsp;
        
    </div>);
}

export default PartnerActivityTab;