import { Dispatch } from 'redux';
import { ActionType, SearchActionType } from '../search/types';
import { SurveyAPI } from '../../api/survey-api';
import { dispatchError } from '../search/actions'; 

export const getsurvey = (group_id: string,class_id: string | null ,class_course_id: string | null,class_course_session_id: string | null) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING,
        page: "survey"
    });
    SurveyAPI.get(group_id,class_id,class_course_id,class_course_session_id).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "survey",
            page: "survey",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("survey",dispatch,error);
    })
};

export const getSurveyPublic = (group_id: string,class_id: string | null,class_course_id: string | null,class_course_session_id: string | null,code:string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING,
        page: "survey"
    });
    SurveyAPI.getSurveyPublic(group_id,class_id,class_course_id,class_course_session_id,code).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "survey_public",
            page: "survey",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("survey",dispatch,error);
    })
};

export const submitsurvey = (group_id: string,class_id: string | null,class_course_id: string | null,class_course_session_id: string | null,item:any,code: string | undefined) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_UPDATING,
        page: "survey"
    });
    SurveyAPI.submit(group_id,class_id,class_course_id,class_course_session_id,item,code).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_UPDATED,
            payload: response.data,
            table: "survey",
            page: "survey",
            action: ActionType.UPDATE
        });
    }, function (error) {
       dispatchError("survey",dispatch,error);
    })
};

