import React, { useEffect, useRef, useState } from 'react'
import { Button, Modal, ModalProps } from 'react-bootstrap'
import SmsIcon from '@material-ui/icons/Sms';
import ReloadIcon from '@material-ui/icons/Refresh'
import { Util } from '../../api/util';
import { FeedbackResponse } from '../../model/response/feedback.model';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { queryclear } from '../../store/search/actions';
import { getFeedBack, saveFeedback } from '../../store/trainerPage/action';
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
import { AlertContainer, alert } from 'react-custom-alert';
import { getIsAdmin, getIsStudent } from '../../store/auth/selectors';
import ReCAPTCHA from "react-google-recaptcha";
import { RECAPTCHA_SITE_KEY } from '../../api/conf';


type Props = ModalProps &
{
    title: string;
    documentId: string;
    classId: string;
    firstName: string;
    lastName: string;
    courseName: string;
    feedBackName: string;
    classStudentId: string;
    okbutton?: string;
    cancelbutton?: string;
    onOk: (value: any) => void
}
const CommentModal = (props: Props) => {
    const [feedbackItem, setFeedbackItem] = useState<FeedbackResponse[]>([]);
    const [loading, setLoading] = useState(false)
    const [updating, setUpdating] = useState(false)
    const [error, setError] = useState('');
    const [comment, setComment] = useState<string | null>();
    const [submitted, setSubmitted] = useState(false);
    const isStudent = useSelector(getIsStudent);
    const isAdmin = useSelector(getIsAdmin);
    const store = useStore();
    const dispatch = useDispatch();
    const history = useHistory();
    useEffect(() => {
        const unsubscribe = store.subscribe(() => {
            let res = store.getState().SearchReducer;
            if (res.page === 'comment_modal') {
                if (res.isLoading) {
                    setError('');
                    setLoading(true);
                } else if (res.isLoaded) {
                    setError('');
                    setLoading(false);
                    if (res.table === "feedback_list") {
                        setFeedbackItem(res.data)
                    } else if (res.table === "save_feedback") {
                        setSubmitted(false)
                        alert({ message: 'Saved', type: 'success' });
                        dispatch(getFeedBack(Number(props.classId), Number(props.classStudentId), Number(props.documentId), isStudent,isAdmin))
                    }
                } else if (res.isUpdating) {
                    setError('');
                    setUpdating(true);
                } else if (res.isUpdated) {
                    setError('');
                    setUpdating(false);
                    // alert({ message: 'Saved', type: 'success' });


                } else if (res.isFailed) {
                    console.log("Fail")
                    if (res.data.success !== undefined) {
                        alert({ message: res.data.popup ?? 'Error', type: 'error' });
                        window.scrollTo(0, 0);
                    }
                    setError(res.data.message);
                    setLoading(false);
                    setUpdating(false);
                    dispatch(queryclear());
                }
            }

        });
        dispatch(queryclear());
        if (props.documentId) {
            //loadCaptchaEnginge(6, 'lightgray', 'black');
            setComment(null);
            setSubmitted(false)
            dispatch(getFeedBack(Number(props.classId), Number(props.classStudentId), Number(props.documentId), isStudent,isAdmin))
        }

        return unsubscribe;
    }, [props.documentId])
    const handleSubmit = () => {
        setSubmitted(true);
        if (checkFormValid()) {
            const body = {
                classStudentId: props.classStudentId,
                documentId: props.documentId,
                message: comment,
                email: true,
            }
            dispatch(saveFeedback(body, isStudent,isAdmin))
            setComment(null);
            setSubmitted(false);
        }
    }
    const checkFormValid = () => {
        return comment != null;
    }

    return (
        <Modal
            size="xl"
            {...props}
            dialogClassName="modal-30w"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            centered
        >
            <Modal.Header style={{ background: "lightgray" }} closeButton>
                <Modal.Title>
                    <table>
                        <tr>
                            <td style={{ width: "20px" }}></td>
                            <td>
                                <SmsIcon className="yellow" style={{ transform: "scale(2)" }}></SmsIcon>
                            </td>
                            <td style={{ width: "150px" }} className="header">
                                &nbsp;&nbsp;Feedback
                            </td>
                            <td style={{ width: "20px" }}></td>
                            <td style={{ fontSize: "12pt" }}>
                                <span className="header">{props.firstName} {props.lastName} หลักสูตร: {props.courseName} </span> <br />
                                หัวข้อ Assignment: <span style={{ textDecoration: "underline" }}>{props.feedBackName}</span>
                            </td>
                        </tr>
                    </table>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {feedbackItem && feedbackItem.map((x: FeedbackResponse) => (
                    <React.Fragment key={x.id}>
                        <span style={{ fontFamily: 'DB Helvethaica Bold' }}>{x.name}</span> {Util.datetimetostr(new Date(x.createTime))}  <br />
                        <div style={{ paddingLeft: "20px", whiteSpace: "pre-line" }}>{x.message} </div>
                        <hr />
                    </React.Fragment>
                ))}
                <form>
                    <div className='row'>
                        <label className='col-form-label col-auto'>Comment:</label>
                        <textarea id="comment"
                            className={"form-control col-10 " + ((comment == null && submitted) ? "is-invalid" : "")}
                            rows={5}
                            value={comment || ''}
                            onChange={(e: any) => setComment(e.target.value)} ></textarea>

                    </div>
                    <div className='row mt-2'>
                        <label className='col-1'></label>

                        {(error !== "") &&
                            <div className='col-11' style={{ color: 'red'}}>{error}</div>
                        }
                    </div>

                </form>
                <AlertContainer floatingTime={10000} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.onHide}>
                    {props.cancelbutton || 'ยกเลิก'}
                </Button>
                <Button variant="success" onClick={() => { handleSubmit() }}>
                    {props.okbutton || 'ยืนยัน'}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default CommentModal