import React from 'react'
import { Util } from '../../api/util'
import { ListClassCourseWithNameResponse } from '../../model/response/class-course.model'

type CourseItemProps = {
    courseItem: ListClassCourseWithNameResponse
}

const CourseItem = ({ courseItem }: CourseItemProps) => {
    return (
        <div className="light-gray-button" style={{ borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px', paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", textAlign: "left" }}>
            <div className="row">
                <div className="col-4">
                    <label className="col-form-label">โครงการ</label><br />
                    <input className="form-control" type="text" value={courseItem.code + ":" + courseItem.name} readOnly={true} />
                </div>
                <div className="col-1">
                    <label className="col-form-label">รอบ</label><br />
                    <input className="form-control" type="text" value={courseItem.session} readOnly={true} />
                </div>
                <div className="col-2">
                    <label className="col-form-label">วันที่เริ่ม</label>
                    <input className="form-control" type="text" value={Util.datetostr(new Date(courseItem.fromDate))} readOnly={true} />
                </div>
                <div className="col-2">
                    <label className="col-form-label">วันที่สิ้นสุด</label>
                    <input className="form-control" type="text" value={Util.datetostr(new Date(courseItem.toDate))} readOnly={true} />
                </div>
                <div className="col-3">
                    <label className="col-form-label">ผู้รับผิดชอบโครงการ</label><br />
                    <input className="form-control" type="text" value={courseItem.organizer} readOnly={true} />
                </div>
            </div>
        </div>
    )
}

export default CourseItem