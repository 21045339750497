import React, { useState } from 'react'
import { PartnerProfileListResponse, QuestionResponse, SurveyProjectResponse } from '../../../../model/response/partner-profile';
import ExpandMore from '@material-ui/icons/ExpandMore'
import ExpandLess from '@material-ui/icons/ExpandLess'
import AddIcon from '@material-ui/icons/Add';
import { Button, InputGroup } from 'react-bootstrap';
import { SingleSearch } from '../../../tab/search-component';
import iconTrashRed from '../../../../img/iconTrashRed.png'
import ConfirmModal from '../../../share/ConfirmModal';
import { spaceChildren } from 'antd/es/button';
type Props = {
    parnerProfileList: PartnerProfileListResponse;
    success: boolean;
    submitted: boolean;
    setParnerProfileListChange: (parnerProfileList: PartnerProfileListResponse) => void;
    readonly: boolean;
    surveyProjectForAdd: SurveyProjectResponse[];
    projectItem: any[]
}

const PartnerExtraSurveyProject = (props: Props) => {
    const [expand, setExpand] = useState(true);
    const [itemDeleteSurveyProject, setItemDeleteSurveyProject] = useState<any>();
    const [confirmDeleteSurveyProject, setConfirmDeleteSurveyProject] = useState(false);
    const handleAddSurveyProject = () => {
        var newItem = { ...props.parnerProfileList };
        if (newItem?.surveyProject == null || newItem?.surveyProject == undefined) {
            newItem.surveyProject = []
        }
        if (props.surveyProjectForAdd.length > 0) {
            newItem.surveyProject.push(JSON.parse(JSON.stringify(props.surveyProjectForAdd[0])));
        }

        props.setParnerProfileListChange(newItem);
    }
    const setQuestion1 = (value: any, index: number, questionIndex: number) => {
        var newItem = { ...props.parnerProfileList };
        newItem.surveyProject[index].question1[questionIndex].checked = value;
        props.setParnerProfileListChange(newItem);
    }
    // const setQuestion2 = (value: any, index: number, questionIndex: number) => {
    //     var newItem = { ...props.parnerProfileList };
    //     newItem.surveyProject[index].question2[questionIndex].checked = value;
    //     props.setParnerProfileListChange(newItem);
    // }
    // const setQuestion3 = (value: any, index: number, questionIndex: number) => {
    //     var newItem = { ...props.parnerProfileList };
    //     newItem.surveyProject[index].question3[questionIndex].checked = value;
    //     props.setParnerProfileListChange(newItem);
    // }
    const setQuestion4 = (value: any, index: number, questionIndex: number) => {
        var newItem = { ...props.parnerProfileList };
        newItem.surveyProject[index].question4[questionIndex].checked = value;
        props.setParnerProfileListChange(newItem);
    }
    const setQuestion1Other = (value: any, index: number) => {
        var newItem = { ...props.parnerProfileList };
        newItem.surveyProject[index].question1Other = value;
        props.setParnerProfileListChange(newItem);
    }
    // const setQuestion2Other = (value: any, index: number) => {
    //     var newItem = { ...props.parnerProfileList };
    //     newItem.surveyProject[index].question2Other = value;
    //     props.setParnerProfileListChange(newItem);
    // }
    // const setQuestion3Other = (value: any, index: number) => {
    //     var newItem = { ...props.parnerProfileList };
    //     newItem.surveyProject[index].question3Other = value;
    //     props.setParnerProfileListChange(newItem);
    // }
    const setQuestion4Other = (value: any, index: number) => {
        var newItem = { ...props.parnerProfileList };
        newItem.surveyProject[index].question4Other = value;
        props.setParnerProfileListChange(newItem);
    }
    const setOtherInfo = (value: any, index: number) => {
        var newItem = { ...props.parnerProfileList };
        newItem.surveyProject[index].otherInfo = value;
        props.setParnerProfileListChange(newItem);
    }
    const getRequireOther = (value: string | boolean | undefined | any[] | null | number | Date, check: boolean, description: string) => {
        if (description == 'อื่นๆ โปรดระบุ' && check) {
            console.log(value)
            return (props.submitted && (value == null || value == undefined || value == ''))
        } else {
            return false
        }

    }
    const handleProject = (e: number, index: number) => {
        var newItem = { ...props.parnerProfileList };
        newItem.surveyProject[index].projectId = e;
        props.setParnerProfileListChange(newItem);
    }
    const getProject = (index: number) => {
        var c = props.parnerProfileList.surveyProject[index];
        if ((c.projectId === null) || (c.projectId === undefined)) return null;
        for (var i = 0; i < props.projectItem.length; i++) {
            if (props.projectItem[i].id == c.projectId) {
                return { label: props.projectItem[i].code + " : " + props.projectItem[i].name, value: c.projectId };
            }
        }
        return null;
    }
    const createProjectSearchFilter = () => {
        var res: any[] = [];
        for (var i = 0; i < props.projectItem.length; i++) {
            res.push({ label: props.projectItem[i].code + " : " + props.projectItem[i].name, value: props.projectItem[i].id });
        }
        return res;
    }
    const createOrganizer = (index: number) => {
        console.log(props.projectItem)
        var c = props.parnerProfileList.surveyProject[index];
        if ((c.projectId === null) || (c.projectId === undefined)) return "";
        for (var i = 0; i < props.projectItem.length; i++) {
            if (props.projectItem[i].id == c.projectId) {
                return props.projectItem[i].organizer;
            }
        }
        return "";
    }
    const handleDeleteSurveyProject = (index: number) => {
        const item = {
            index: index
        }
        setItemDeleteSurveyProject(item as any);
        setConfirmDeleteSurveyProject(true);
    }
    const deleteSurveyProject = () => {
        var newItem = { ...props.parnerProfileList };
        if (itemDeleteSurveyProject) {
            const { index } = itemDeleteSurveyProject as any
            newItem.surveyProject.splice(index, 1);
        }
        props.setParnerProfileListChange(newItem);
        setItemDeleteSurveyProject(undefined);
        setConfirmDeleteSurveyProject(false);
    }
    const getRequire = (value: string | boolean | undefined | any[] | null | number | Date) => {
        return (props.submitted && (value == null || value == undefined || value == ''))
    }
    const validateQuestion = (question: QuestionResponse[]) => {
        let error = false;
        error = !question.some(x => x.checked);
        return props.submitted && error;
    }
    return (
        <React.Fragment>
            <div className="text-left" style={{ position: "relative", marginBottom: 5 }}>
                <div className={'d-flex yellow-button'} style={{ padding: "15px", borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}>
                    {((expand === undefined) || (expand === false)) &&
                        <div onClick={(e) => setExpand(true)}><ExpandMore className="pointer mr-2" ></ExpandMore></div>
                    }
                    {(expand === true) &&
                        <div onClick={(e) => setExpand(false)}><ExpandLess className="pointer mr-2" ></ExpandLess></div>
                    }
                    <label className='ml-2 my-auto'><span style={{ fontWeight: 600, marginRight: 10 }}>แบบสอบถามรายโครงการ</span></label>
                </div>
                {(expand === true) && (
                    <div style={{ padding: "15px", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px", border: "3px solid #fab232" }}>
                        {props.parnerProfileList.surveyProject && props.parnerProfileList.surveyProject.map((x, index) => (
                            <div style={{ padding: "10px", borderRadius: 5, border: "2px solid black", position: 'relative' }} className='mb-2' key={'surveyProject' + index}>
                                <div className='row mt-1'>
                                    <label className='col-3' style={{ fontWeight: 600 }}>โครงการ<span className='text-danger'>*</span></label>
                                    <SingleSearch
                                        isSearchable={true} isMulti={false} placeholder=""
                                        onChange={(id: string, e: any) => handleProject(e == null ? null : e?.value, index)}
                                        value={getProject(index)}
                                        options={createProjectSearchFilter()}
                                        isError={getRequire(x.projectId)}
                                        style={{ width: "300px" }}
                                    />
                                </div>
                                <div className='row mt-1 mb-2'>
                                    <label className='col-3' style={{ fontWeight: 600 }}>ฝ่ายผู้รับผิดชอบโครงการ<span className='text-danger'>*</span></label>
                                    <input className="form-control col-4" value={createOrganizer(index)} readOnly={true} />
                                </div>
                                <div className='d-flex gray-button pl-2'>
                                    รู้จักข้อมูลหลักสูตรและสื่อความรู้ของตลาดหลักทรัพย์ฯ จากช่องใด
                                </div>
                                <div className='mb-2'>
                                    {validateQuestion(x.question1) && <span className='text-danger ml-2'>กรุณาเลือกอย่างน้อย 1 ข้อ</span>}
                                    {x.question1 && x.question1.map((q1, q1Index) => (
                                        <React.Fragment key={'q1' + q1.questionId}>
                                            <div className='d-flex mt-1'>
                                                <div className="form-check checkbox-lg">
                                                    <input type="checkbox" disabled={props.readonly} className='form-input-check mr-2 mt-2' id={"input-checkbox-question" + index + '-' + q1Index} checked={q1.checked} onChange={(e) => { setQuestion1(e.target.checked, index, q1Index) }} /><span style={{ fontWeight: 600 }}>{q1.description}</span>
                                                </div>
                                                {q1.description == 'อื่นๆ โปรดระบุ' && (
                                                    <div className='ml-1 col-6'>
                                                        <input type="text" id={"input-text-other1" + index + '-' + q1Index} className={'form-control ' + (getRequireOther(x.question1Other, q1.checked, q1.description) ? "is-invalid" : "")} value={x.question1Other} onChange={(e) => { setQuestion1Other(e.target.value, index) }} />
                                                    </div>
                                                )}

                                            </div>
                                        </React.Fragment>
                                    ))}
                                </div>
                                {/* <div className='d-flex gray-button pl-2'>
                                    ปัญหาและอุปสรรคในความร่วมมือกับองค์กรพันธมิตร
                                </div>
                                <div className='mb-2'>
                                    {validateQuestion(x.question2) && <span className='text-danger ml-2'>กรุณาเลือกอย่างน้อย 1 ข้อ</span>}
                                    {x.question2 && x.question2.map((q2, q2Index) => (
                                        <React.Fragment key={'q2' + q2.questionId}>
                                            <div className='d-flex mt-1'>
                                                <div className="form-check checkbox-lg">
                                                    <input type="checkbox" disabled={props.readonly} className='form-input-check mr-2 mt-2' id={"input-checkbox-question2" + index + '-' + q2Index} checked={q2.checked} onChange={(e) => { setQuestion2(e.target.checked, index, q2Index) }} /><span style={{ fontWeight: 600 }}>{q2.description}</span>
                                                </div>
                                                {q2.description == 'อื่นๆ โปรดระบุ' && (
                                                    <div className='ml-1 col-6'>
                                                        <input type="text" id={"input-text-other2" + index + '-' + q2Index} className={'form-control ' + (getRequireOther(x.question2Other, q2.checked, q2.description) ? "is-invalid" : "")} value={x.question2Other} onChange={(e) => { setQuestion2Other(e.target.value, index) }} />
                                                    </div>
                                                )}
                                            </div>
                                        </React.Fragment>
                                    ))}
                                </div> */}
                                {/* <div className='d-flex gray-button pl-2'>
                                    องค์กรพันธมิตรมีความเข้มแข็งในด้านใด
                                </div>
                                <div className='mb-2'>
                                    {validateQuestion(x.question3) && <span className='text-danger ml-2'>กรุณาเลือกอย่างน้อย 1 ข้อ</span>}
                                    {x.question3 && x.question3.map((q3, q3Index) => (
                                        <React.Fragment key={'q3' + q3.questionId}>
                                            <div className='d-flex mt-1'>
                                                <div className="form-check checkbox-lg">
                                                    <input type="checkbox" disabled={props.readonly} className='form-input-check mr-2 mt-2' id={"input-checkbox-question3" + index + '-' + q3Index} checked={q3.checked} onChange={(e) => { setQuestion3(e.target.checked, index, q3Index) }} /><span style={{ fontWeight: 600 }}>{q3.description}</span>
                                                </div>
                                                {q3.description == 'อื่นๆ โปรดระบุ' && (
                                                    <div className='ml-1 col-6'>
                                                        <input type="text" id={"input-text-other3" + index + '-' + q3Index} className={'form-control ' + (getRequireOther(x.question3Other, q3.checked, q3.description) ? "is-invalid" : "")} value={x.question3Other} onChange={(e) => { setQuestion3Other(e.target.value, index) }} />
                                                    </div>
                                                )}
                                            </div>
                                        </React.Fragment>
                                    ))}
                                </div> */}
                                <div className='d-flex gray-button pl-2'>
                                    บุคคลใดที่มีอิทธิพลต่อการตัดสินใจเข้าร่วมโครงการฯ มากที่สุด
                                </div>
                                <div className='mb-2'>
                                    {validateQuestion(x.question4) && <span className='text-danger ml-2'>กรุณาเลือกอย่างน้อย 1 ข้อ</span>}
                                    {x.question4 && x.question4.map((q4, q4Index) => (
                                        <React.Fragment key={'q4' + q4.questionId}>
                                            <div className='d-flex mt-1'>
                                                <div className="form-check checkbox-lg">
                                                    <input type="checkbox" disabled={props.readonly} className='form-input-check mr-2 mt-2' id={"input-checkbox-question4" + index + '-' + q4Index} checked={q4.checked} onChange={(e) => { setQuestion4(e.target.checked, index, q4Index) }} /><span style={{ fontWeight: 600 }}>{q4.description}</span>
                                                </div>
                                                {q4.description == 'อื่นๆ โปรดระบุ' && (
                                                    <div className='ml-1 col-6'>
                                                        <input type="text" id={"input-text-other4" + index + '-' + q4Index} className={'form-control ' + (getRequireOther(x.question4Other, q4.checked, q4.description) ? "is-invalid" : "")} value={x.question4Other} onChange={(e) => { setQuestion4Other(e.target.value, index) }} />
                                                    </div>
                                                )}
                                            </div>
                                        </React.Fragment>
                                    ))}
                                </div>
                                <div className='d-flex gray-button pl-2'>
                                    โปรดระบุข้อมูลอื่นๆ เสริม (ถ้ามี)
                                </div>
                                <div className='d-flex mb-2' >
                                    <textarea rows={4} id={'input-text-area-otherInfo' + index} className='form-control h-25' value={x.otherInfo} onChange={(e) => setOtherInfo(e.target.value, index)} ></textarea>
                                </div>
                                {props.parnerProfileList.surveyProject.length > 0 && (
                                    <img src={iconTrashRed} width={20} height={20} id={"icon_trash_survey" + index + "_"} style={{ position: "absolute", right: "5px", top: "5px", zIndex: 10, cursor: 'pointer' }} onClick={e => { handleDeleteSurveyProject(index) }} className='mr-2' />
                                )}
                            </div>))}

                        {(!props.success) &&
                            <div className='d-flex justify-content-center'>
                                <Button type="button" className="btn btn-warning yellow-button"
                                    onClick={() => handleAddSurveyProject()} >
                                    <AddIcon />
                                    เพิ่มแบบสอบถามรายโครงการ
                                </Button>

                            </div>

                        }
                    </div>
                )}
            </div>
            <ConfirmModal title={'กรุณากด "ยืนยัน" เพื่อลบรายการ'} name={''} show={confirmDeleteSurveyProject}
                onHide={() => setConfirmDeleteSurveyProject(false)} onOk={() => deleteSurveyProject()} />
        </React.Fragment>
    )
}

export default PartnerExtraSurveyProject