import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ProjectGroupResponse } from '../../model/response/project.model';
import { Button, Modal, ModalProps } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useStore } from 'react-redux';
import { queryclear } from '../../store/search/actions';
import { ProjectGroupRequest } from '../../model/request/projectGroup.model';
import { AlertContainer,alert } from 'react-custom-alert';
import CloseIcon from '@material-ui/icons/Close';
import loadinglogo from '../../img/loading.gif';
import { addProjectGroupInfo, updatedProjectGroupInfo } from '../../store/editProjectGroup/action';

const CustomModal = styled(Modal)`
    .description{
        color: #5F6062;
        margin-top: 10px;
    }
    .button-close{
        position: absolute;
        top:-30px;
        right: -30px;
        color:white;
        &:hover{
            cursor: pointer;
            transform:scale(1.3,1.3);
            -webkit-transform:scale(1.3,1.3);
            -moz-transform:scale(1.3,1.3);
        }
    }
`
type Props = ModalProps &
{
    editItem: ProjectGroupResponse | undefined;
    onOk: (value: any) => void
}
const EditProjectGroupModal = (props: Props) => {
    const { control,
        handleSubmit,
        formState: { errors },
        reset, getValues,
        setValue, register,
        resetField,
        watch, clearErrors } = useForm();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const store = useStore();
    useEffect(() => {
        const unsubscribe = store.subscribe(() => {
            let res = store.getState().SearchReducer;
            if (props.show) {
                if (res.isLoading) {
                    setError('');
                    setLoading(true);
                } else if ((res.isLoaded) && (!res.isUpdated)) {
                    setError('');
                    setLoading(false);
                } else if (res.isUpdating) {
                    setError('');
                } else if (res.isUpdated) {
                    setError('');
                    dispatch(queryclear());
                    alert({ message: 'Saved', type: 'success' });
                    reset()
                    props.onOk(true);
                } else if (res.isFailed) {
                    if (res.data.success !== undefined) {
                        alert({ message: res.data.popup ?? 'Error', type: 'error' });
                        window.scrollTo(0, 0);
                    }
                    setError(res.data.message);
                    setLoading(false);
                    dispatch(queryclear());
                }
            }
        })
        return unsubscribe;
    }, [])
    useEffect(() => {
        if (props.show) {
            dispatch(queryclear());
            if (props.editItem) {
                reset();
                setValue('projectGroupId', props.editItem.id);
                setValue('projectGroupName', props.editItem.name)
                setValue('projectGroupShortName', props.editItem.shortName);
                clearErrors();
            } else {
                initialForm();
            }

        }

    }, [props.show]);
    const initialForm = () => {
        reset();
        clearErrors();
    }

    const onSubmit = (data: any) => {
        const body: ProjectGroupRequest = {
            projectGroupId: data.projectGroupId,
            projectGroupName: data.projectGroupName,
            projectGroupShortName: data.projectGroupShortName,
        }
        if (props.editItem) {
            dispatch(updatedProjectGroupInfo(body,props.editItem.id));
        } else {
            dispatch(addProjectGroupInfo(body));
        }

    }
    const hideModal = () => {
        reset()
        props.onHide()
    }

    return (
        <div>
            <CustomModal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <a className='button-close' onClick={hideModal}><CloseIcon /></a>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Body>
                        <div className="row">
                            <label className='col-sm-3 col-form-label text-right'>ชื่อกลุ่มโครงการ <span className="text-danger">*</span></label>
                            <div className="col-sm-9 col-form-label">
                                <input {...register("projectGroupName", { required: true })} className={(errors.projectGroupName) ? 'form-control is-invalid' : 'form-control'} type="text" name="projectGroupName" id="projectGroupName" />
                            </div>
                        </div>
                        <div className="row">
                            <label className='col-sm-3 col-form-label text-right'>ชื่อย่อกลุ่มโครงการ <span className="text-danger">*</span></label>
                            <div className="col-sm-9 col-form-label">
                                <input {...register("projectGroupShortName", { required: true })} className={(errors.projectGroupShortName) ? 'form-control is-invalid' : 'form-control'} type="text" name="projectGroupShortName" id="projectGroupShortName" />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={hideModal}>
                            Cancel
                        </Button>
                        <Button type='submit' className="btn btn-primary">
                            <div>
                                {(loading) &&
                                    <div style={{ textAlign: "center" }}><img src={loadinglogo} width="30" height="30" className="d-inline-block align-top" alt="" /></div>
                                }
                                {(!loading) &&
                                    <>Submit</>
                                }
                            </div>
                        </Button>
                    </Modal.Footer>
                </form>
            </CustomModal>
            <AlertContainer floatingTime={3000} />
        </div>
    )
}

export default EditProjectGroupModal