import { Dispatch } from 'redux';
import { ActionType, SearchActionType } from '../search/types';
import { SurveyAPI } from '../../api/survey-api';
import { ProjectAPI } from '../../api/project-api';
import { HRAPI } from '../../api/hr-api';
import { dispatchError } from '../search/actions'; 

export const createsurveygroup = (item: any,logoFile: FileList | undefined, headerFile: FileList | undefined) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_UPDATING,
        page: "edit_surveygroup"
    });
    SurveyAPI.addSurveyGroup(item,logoFile,headerFile).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_UPDATED,
            payload: response.data,
            table: "surveygroup",
            page: "edit_surveygroup",
            action: ActionType.UPDATE
        });
    }, function (error) {
        if ((error.response!==undefined) && (error.response.status===400)) {
            dispatch({
                type: SearchActionType.QUERY_ERROR,
                page: "edit_surveygroup",
                payload: error.response.data.additional
            });
        } else 
            dispatchError("edit_surveygroup",dispatch,error);
    })
};

export const updatesurveygroup = (id: number,item: any,logoFile: FileList | undefined, headerFile: FileList | undefined) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_UPDATING,
        page: "edit_surveygroup"
    });
    SurveyAPI.updateSurveyGroup(id,item,logoFile,headerFile).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_UPDATED,
            payload: response.data,
            table: "surveygroup",
            page: "edit_surveygroup",
            action: ActionType.UPDATE
        });
    }, function (error) {
        if ((error.response!==undefined) && (error.response.status===400)) {
            dispatch({
                type: SearchActionType.QUERY_ERROR,
                page: "edit_surveygroup",
                payload: error.response.data.additional
            });
        } else 
            dispatchError("edit_surveygroup",dispatch,error);
    })
};


export const getsurveygroup = (id: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING,
        page: "edit_surveygroup"
    });
    SurveyAPI.getSurveyGroup(id).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "surveygroup",
            page: "edit_surveygroup",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("edit_surveygroup",dispatch,error);
    })
};

export const deletesurveygroup = (id: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_UPDATING,
        page: "edit_surveygroup"
    });
    SurveyAPI.deleteSurveyGroup(id).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_UPDATED,
            payload: response.data,
            table: "surveygroup",
            page: "edit_surveygroup",
            action: ActionType.DELETE
        });
    }, function (error) {
       dispatchError("edit_surveygroup",dispatch,error);
    })
};

export const listproject = () => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING,
        page: "edit_surveygroup"
    });
    ProjectAPI.list().then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "project",
            page: "edit_surveygroup",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("edit_surveygroup",dispatch,error);
    })
};

export const listorganization = () => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING,
        page: "edit_surveygroup"
    });
    HRAPI.listDepartment().then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "organizer",
            page: "edit_surveygroup",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("edit_surveygroup",dispatch,error);
    })
};
