import axios from 'axios';
import { DEFAULT_SERVER } from './conf';

const apiClient = axios;
const AUTH = "/api/v1/authentication";

export const LOGIN_URL = DEFAULT_SERVER + AUTH+ "/login";
const GET_TOKEN_URL = DEFAULT_SERVER + AUTH+ "/get-token";
const REFRESH_TOKEN_URL = DEFAULT_SERVER + AUTH+ "/refresh-token";
const STUDENT_LOGIN_URL = DEFAULT_SERVER + AUTH +"/student/login";
const STUDENT_REFRESH_TOKEN_URL = DEFAULT_SERVER + AUTH+ "/student/refresh-token";

export class AuthAPI {
    public static getToken = async (code: string, sessionState: string): Promise<any> => {
        return new Promise(function (resolve, reject) {
            apiClient.get(GET_TOKEN_URL+"?code="+code+"&sessionState="+sessionState)
                .then((response) => {
                    console.log("get-token response : ", response);
                    return resolve(response);
                }).catch(err => {
                    console.log("get-token error:", err);
                    return reject(err); 
                })
        });
    }

    public static refreshToken = async (refresh_token: string): Promise<any> => {
        return new Promise(function (resolve, reject) {
            apiClient.post(REFRESH_TOKEN_URL,
                {
                    token: refresh_token
                })
                .then((response) => {
                    console.log("refresh token response : ", response);
                    return resolve(response);
                }).catch(err => {
                    console.log("refresh token error:", err);
                    return reject(err); 
                })
        });
    }

    public static studentGetToken = async (token: string): Promise<any> => {
        return new Promise(function (resolve, reject) {
            apiClient.post(STUDENT_LOGIN_URL
                ,{
                    token: token
                }
                )
                .then((response) => {
                    console.log("student get token response : ", response);
                    return resolve(response);
                }).catch(err => {
                    console.log("student get token error:", err);
                    return reject(err); 
                })
        });
    }

    public static studentRefreshToken = async (refresh_token: string): Promise<any> => {
        return new Promise(function (resolve, reject) {
            apiClient.post(STUDENT_REFRESH_TOKEN_URL,
                {
                    token: refresh_token
                })
                .then((response) => {
                    console.log("student refresh token response : ", response);
                    return resolve(response);
                }).catch(err => {
                    console.log("student refresh token error:", err);
                    return reject(err); 
                })
        });
    }

    public static studentLogout = async (refresh_token: string): Promise<any> => {
        return new Promise(function (resolve, reject) {
            apiClient.post(STUDENT_REFRESH_TOKEN_URL,
                {
                    token: refresh_token
                })
                .then((response) => {
                    console.log("student refresh token response : ", response);
                    return resolve(response);
                }).catch(err => {
                    console.log("student refresh token error:", err);
                    return reject(err); 
                })
        });
    }

}

    /*public static login = async (_username: string, _password: string): Promise<any> => {
        return new Promise(function (resolve, reject) {
            apiClient.post(LOGIN_URL,
                //payload
                { 'username': _username, 'password': _password, 'role': 'ADMIN' }
                ,
                {//options
                    headers: {
                        'Content-Type' : 'application/json'
                    }
                })
                .then((response) => {
                    console.log("login response : ", response);
                    return resolve(response);
                }).catch(err => {
                    console.log("login error:", err);
                    return reject(err); 
                })
        });
    }

    public static logout = async(_token: string): Promise<any> => {
        return new Promise(function (resolve, reject) {
            apiClient.post(LOGOUT_URL, {},
                {//options
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded',
                        authorization: 'Bearer ' + _token,
                    }
                })
                .then((response) => {
                    console.log("logout response : ", response);
                    return resolve(response);
                }).catch(err => {
                    console.log("logout error:", err);
                    return reject(err);
                })
        })
    }

    public static changepassword = async(username: string, oldpassword: string, newpassword: string,repassword:string): Promise<any> => {
        return new Promise(function (resolve, reject) {
            apiClient.post(CHANGE_PASSWORD_URL,   //payload
                qs.stringify({ 'username': username,'oldpassword': oldpassword, 'newpassword': newpassword, 'repassword': repassword })
                ,
                {//options
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }
                })
                .then((response) => {
                    console.log("change password response : ", response);
                    return resolve(response);
                }).catch(err => {
                    console.log("change password error:", err);
                    return reject(err);
                })
        })
    }*/
/*if ((_username===process.env.REACT_APP_ADMIN_USERNAME) && (_password===process.env.REACT_APP_ADMIN_PASSWORD))
{
    var response: any = {
        data: "Success"
    };
    resolve(response);
} else {
    var err: any={
        response: {
            data: {
                msg: "Wrong password"
            }
        }
    };
    reject(err);
}*/

/*    public static refresh_token = async (_refresh: string,_token: string): Promise<any> => {
        return new Promise(function (resolve, reject) {
            apiClient.post(TOKEN_URL,
                //payload
                qs.stringify({ 'token': _refresh })
                ,
                {//options
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded',
                        // authorization: 'Bearer ' + _token
                    }
                })
                .then((response) => {
                    console.log("refresh-token response : ", response);
                    return resolve(response);
                }).catch(err => {
                    console.log("refresh-token error:", err);
                    return reject(err);
                })
        })
    }*/