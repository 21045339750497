import React, { useEffect, useRef, useState } from 'react'
import MainMenu from '../menu'
import ManageKnowledgeSuggestion from './component/ManageKnowledgeSuggestion'
import { KnowledgeDepartmentResponse, ManageKnowledgeResponse } from '../../../model/response/manage-knowledge'
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import iconAddMedia from '../../../img/iconAddMedia.png'
import iconTrash from '../../../img/iconTrash.png'
import iconEdit from '../../../img/iconEdit.png'
import MenuIcon from '@material-ui/icons/Menu';
import { SingleSearch } from '../../tab/search-component'
import { useDispatch, useStore } from 'react-redux'
import { useHistory } from 'react-router'
import { AlertContainer, alert } from 'react-custom-alert';
import { listKnowledgeCategory, listKnowledgeDepartment, saveKnowledgePartner } from '../../../store/editpartner/actions'
import { Button } from 'react-bootstrap';
import AddIcon from '@material-ui/icons/Add';
import { PartnerSubCategory } from '../../../model/response/partner-category'
import { queryclear } from '../../../store/search/actions';
import ManageKnowledgeCategory from './component/ManageKnowledgeCategory'
import PartnerLoadingModal from '../../partner/PartnerLoadingModal'
import loadinglogo from '../../../img/loading.gif';

type Props = {}

const ManageKnowledge = (props: Props) => {
    const [knowledgeList, setKnowledgeList] = useState<ManageKnowledgeResponse[]>([]);
    const [departmentItem, setDepartmentItem] = useState<KnowledgeDepartmentResponse[]>([]);
    const [init, setInit] = useState(0);
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [edit, setEdit] = useState(false);
    const [error, setError] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const store = useStore();
    const history = useHistory();
    const dispatch = useDispatch();
    useEffect(() => {
        const unsubscribe = store.subscribe(() => {
            let res = store.getState().SearchReducer;
            if (res.isLoading) {
                setError('');
                setLoading(true);
            } else if ((res.isLoaded)) {
                setError('');
                setLoading(false);
                if (res.data != null) {
                    if (res.table === 'list_knowledge_department') {
                        setDepartmentItem(res.data);
                    } else if (res.table === 'list_knowledge_category') {
                        setSubmitted(false);
                        setEdit(false);
                        setInit(1);
                        setKnowledgeList(res.data)

                    }
                }
            } else if (res.isUpdating) {
                setError('');
                setLoading(true);
            } else if (res.isUpdated) {
                setError('');
                setLoading(false);
                if (res.table === 'save_knowledge') {
                    dispatch(listKnowledgeCategory());
                    alert({ message: 'Saved', type: 'success' });
                }


            } else if (res.isFailed) {
                setError(res.data.message);
                setLoading(false);
                alert({ message: res.data.message, type: 'error' });
            }

        })
        dispatch(listKnowledgeDepartment());
        dispatch(listKnowledgeCategory());
        removeSession();
        return unsubscribe;
    }, []);


  const removeSession = () => {
    sessionStorage.removeItem("searchDetails");
    sessionStorage.removeItem("searchPartnerDetails");
    sessionStorage.removeItem("searchSurveyDetails");
    sessionStorage.removeItem("searchTrainerDetails");
    sessionStorage.removeItem("searchManageQuestion");
    sessionStorage.removeItem("searchLogRequest");
    sessionStorage.removeItem("searchLogRequestHistory");
  }


    const handleAddCategory = () => {
        var newItem = knowledgeList == null ? [] : [...knowledgeList];
        const knowledge: ManageKnowledgeResponse = {
            categoryId: null,
            categoryName: null,
            department: null,
            departmentId: null,
            isActive: true,
            subCategories: [],
            collapse: false,
            edit: true
        }
        newItem.push(knowledge);
        setKnowledgeList(newItem);
        setEdit(true);
    }
    const setKnowledgeListAndSetEdit = (e: any) => {
        setKnowledgeList(e);
        setEdit(true);
    }

    const handleClear = () => {
        dispatch(listKnowledgeCategory());
    }

    const handleSubmit = () => {
        dispatch(queryclear());
        setSubmitted(true);
        if (inValidateForm()) {
            alert({ message: "กรุณากรอกแบบฟอร์มให้ครบ", type: 'error' });
        } else {
            dispatch(saveKnowledgePartner(knowledgeList))
        }

    }
    const inValidateForm = () => {
        let error = false;
        for (let categoryIndex = 0; categoryIndex < knowledgeList.length; categoryIndex++) {
            if (knowledgeList[categoryIndex].categoryName == null || knowledgeList[categoryIndex].categoryName == undefined) {
                error = true;
            }
            if (knowledgeList[categoryIndex].department == null || knowledgeList[categoryIndex].department == undefined) {
                error = true;
            }
            if (knowledgeList[categoryIndex].isActive == null || knowledgeList[categoryIndex].isActive == undefined) {
                error = true;
            } else if (knowledgeList[categoryIndex].isActive == true) {
                if (knowledgeList[categoryIndex].subCategories.length < 1 || !knowledgeList[categoryIndex].subCategories) {
                    error = true;
                } else {
                    for (let subCat = 0; subCat < knowledgeList[categoryIndex].subCategories.length; subCat++) {
                        if (knowledgeList[categoryIndex].subCategories[subCat].subCategoryName == undefined || knowledgeList[categoryIndex].subCategories[subCat].subCategoryName == null) {
                            error = true;
                        }
                        if (knowledgeList[categoryIndex].subCategories[subCat].subCategoryDescription == undefined || knowledgeList[categoryIndex].subCategories[subCat].subCategoryDescription == null) {
                            error = true;
                        }
                        if (knowledgeList[categoryIndex].subCategories[subCat].checked == undefined || knowledgeList[categoryIndex].subCategories[subCat].checked == null) {
                            error = true;
                        } else if (knowledgeList[categoryIndex].subCategories[subCat].checked == true) {
                            if ((!knowledgeList[categoryIndex].subCategories[subCat].infographics || knowledgeList[categoryIndex].subCategories[subCat].infographics.length < 1)
                                && (!knowledgeList[categoryIndex].subCategories[subCat].calculators || knowledgeList[categoryIndex].subCategories[subCat].calculators.length < 1)
                                && (!knowledgeList[categoryIndex].subCategories[subCat].inHouses || knowledgeList[categoryIndex].subCategories[subCat].inHouses.length < 1)
                                && (!knowledgeList[categoryIndex].subCategories[subCat].ebooks || knowledgeList[categoryIndex].subCategories[subCat].ebooks.length < 1)
                                && (!knowledgeList[categoryIndex].subCategories[subCat].elearnings || knowledgeList[categoryIndex].subCategories[subCat].elearnings.length < 1)
                                && (!knowledgeList[categoryIndex].subCategories[subCat].articles || knowledgeList[categoryIndex].subCategories[subCat].articles.length < 1)
                                && (!knowledgeList[categoryIndex].subCategories[subCat].videos || knowledgeList[categoryIndex].subCategories[subCat].videos.length < 1)) {
                                error = true;
                            }
                        }
                    }
                }
            }
        }
        return error
    }
    const modalBodyLoadingData = () => {
        return <span>กรุณารอสักครู่</span>
    }

    return (
        <div>
            <MainMenu>
                <div>
                    {init == 1 && (
                        <div className="query-wrapper">
                        <br />
                        <div className="yellow-button" style={{ textAlign: "left", borderTopLeftRadius: "30px", borderTopRightRadius: "30px", height: "70px", paddingTop: "15px", paddingLeft: "15px" }}><h4 className="header">บริหารจัดการประเภทและหมวดหมู่สื่อความรู้</h4></div>
                        <br />
                        <ManageKnowledgeSuggestion />
                        <br />
                        <div style={{ background: "white", padding: "30px", borderRadius: "5px" }}>
                            <ManageKnowledgeCategory knowledgeList={knowledgeList}
                                departmentItem={departmentItem}
                                success={success}
                                submitted={submitted}
                                setKnowledgeListNotChange={setKnowledgeList}
                                setKnowledgeList={(e) => setKnowledgeListAndSetEdit(e)}
                                setEdit={setEdit} />
                            {(!success) &&
                                <Button type="button" className="btn btn-warning yellow-button"
                                    onClick={() => handleAddCategory()} >
                                    <AddIcon />
                                    เพิ่มประเภทความรู้
                                </Button>
                            }
                        </div>
                        {(!success) &&
                            <div className='d-flex mt-3'>
                                <div className='ml-auto'>
                                    <button type="button" className="btn btn-warning" style={{ width: "120px" }}
                                        onClick={() => handleClear()} >
                                        Clear
                                    </button>&nbsp;
                                    <button type="button" className="btn btn-success" style={{ width: "120px" }}
                                        disabled={!edit}
                                        onClick={() => handleSubmit()} >
                                        Save
                                    </button>
                                </div>
                            </div>
                        }
                        <br />
                    </div>
                    )}
                    
                    {(((init < 1) || (loading)) && (error === "")) &&
                        <div style={{ textAlign: "center" }}><img src={loadinglogo} width="30" height="30" className="d-inline-block align-top" alt="" /></div>
                    }
                </div>
            </MainMenu>

            <AlertContainer floatingTime={3000} />
        </div>
    )
}

export default ManageKnowledge