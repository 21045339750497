import { Dispatch } from 'redux';
import { SearchActionType } from './types';

export const queryclear = () => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_CLEAR,
    });
};

export const dispatchError = (p: string,dispatch: Dispatch<any>, error : any) => {
    let typep = SearchActionType.QUERY_ERROR
    if (error.response === undefined) {
        error.response = { data: { code: 500, message: error.message } }
        typep = SearchActionType.QUERY_ERROR;
    } else {
        /*if (error.response.status === 404) {
            typep = SearchActionType.QUERY_ERROR;
            error.response = { data: { code: 404, message: error.response.data.msg } }
        } else {*/
        if (error.response.status===401) {
            error.response = { data: { code: 401, message: 'Unauthorized access' } }   
        } else if (error.response.status===502) {
            error.response = { data: { code: 502, message: 'Network error' } }          
        }
        typep = SearchActionType.QUERY_ERROR;
        if (error.response.data.message===undefined)  
        {
            error.response = { data: { code: error.response.status, message: error.message }}
        } else {
            error.response = { data: { code: error.response.status, message: error.response.data.message }}
        }
        //}
    }
    dispatch({
        type: typep,
        page: p,
        payload: error.response.data
    });
}