import { useHistory, useParams } from "react-router-dom";


const tabStyle = { paddingLeft: "20px", paddingRight: "20px", paddingTop: "10px", paddingBottom: "10px", borderTopLeftRadius: "5px", borderTopRightRadius: "5px" };
const tabPointerStyle = { cursor: "pointer", paddingLeft: "20px", paddingRight: "20px", paddingTop: "10px", paddingBottom: "10px", borderTopLeftRadius: "5px", borderTopRightRadius: "5px" };

const ClassTab = (step: number, nextStep: boolean, assignment: boolean, studentlist: boolean, handleClass: Function | null, handleStudent: Function | null, handleDoc: Function | null, handleAssignment: Function | null, handleHistory: Function | null, handleResult: Function | null, handleSurvey: Function | null,handleResultNumber?: Function | null,showResultStudentTab?: boolean | null) => {
    return (<div className="row" style={{ justifyContent: "left", paddingLeft: "40px" }}>
        <div className={(step === 1) ? "yellow-button" : "yellow-secondary-button"} style={(handleClass == null) ? tabStyle : tabPointerStyle} onClick={e => ((handleClass != null) ? handleClass() : () => { })}>
            <b>ข้อมูลหลักสูตร</b>
        </div>&nbsp;
        <div className={(!nextStep) ? "gray-button" : ((step === 2) ? "yellow-button" : "yellow-secondary-button")} style={(handleStudent == null) ? tabStyle : tabPointerStyle} onClick={e => ((handleStudent != null) ? handleStudent() : () => { })}>
            <b>ข้อมูลผู้เรียน</b>
        </div>&nbsp;
        <div className={(!nextStep) ? "gray-button" : ((step === 3) ? "yellow-button" : "yellow-secondary-button")} style={(handleDoc == null) ? tabStyle : tabPointerStyle} onClick={e => ((handleDoc != null) ? handleDoc() : () => { })}>
            <b>เอกสารการเรียน</b>
        </div>&nbsp;
        {(studentlist) &&
            <>
                {(assignment) &&
                    <>
                        <div className={(!nextStep) ? "gray-button" : ((step === 4) ? "yellow-button" : "yellow-secondary-button")} style={(handleAssignment == null) ? tabStyle : tabPointerStyle} onClick={e => ((handleAssignment != null) ? handleAssignment() : () => { })}>
                            <b>งานที่ได้รับมอบหมาย</b>
                        </div>&nbsp;
                    </>
                }
                <div className={(!nextStep) ? "gray-button" : ((step === 5) ? "yellow-button" : "yellow-secondary-button")} style={(handleHistory == null) ? tabStyle : tabPointerStyle} onClick={e => ((handleHistory != null) ? handleHistory() : () => { })}>
                    <b>ประวัติการเรียน</b>
                </div>&nbsp;
                <div className={(!nextStep) ? "gray-button" : ((step === 6) ? "yellow-button" : "yellow-secondary-button")} style={(handleResult == null) ? tabStyle : tabPointerStyle} onClick={e => ((handleResult != null) ? handleResult() : () => { })}>
                    <b>ผลการเรียน</b>
                </div>&nbsp;
            </>
        }
        
        {(showResultStudentTab && !studentlist) && <>
            <div className={(!nextStep) ? "gray-button" : ((step === 8) ? "yellow-button" : "yellow-secondary-button")} style={(handleResult == null) ? tabStyle : tabPointerStyle} onClick={e => ((handleResultNumber != null) ? handleResultNumber() : () => { })}>
                <b>ผลการเรียน</b>
            </div>&nbsp;
        </>}


        <div className={(!nextStep) ? "gray-button" : ((step === 7) ? "yellow-button" : "yellow-secondary-button")} style={(handleSurvey == null) ? tabStyle : tabPointerStyle} onClick={e => ((handleSurvey != null) ? handleSurvey() : () => { })}>
            <b>แบบประเมินผล</b>
        </div>
    </div>);
}

export default ClassTab;
