import React from 'react'
import styled from 'styled-components'
import { StepPage } from './constant/StepPage'
import CheckIcon from '@material-ui/icons/Check';
const StepperContainer = styled.div<{ padding?: string | null }>`
    display: block;
    position: sticky;
    background-color: #FFFFFF;
    padding: ${p => p.padding ? p.padding : '10px 60px 10px 60px'};
`
const Step = styled.div`
    display: inline-flex;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 15px;
    
`
const Circle = styled.div<{ bgcolor?: string, textColor?: string, borderColor?: string }>`
    width: 28px;
    height: 28px;
    background: ${p => p.bgcolor ? p.bgcolor : 'white'};
    border: 3px solid ${p => p.borderColor ? p.borderColor : 'white'};
    border-radius: 50%;
    color: ${p => p.textColor ? p.textColor : 'white'};
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    .step{
        /*  */
    }
`
const TitleStep = styled.span<{ color?: string }>`
    font-size: 18px;
    margin-left: 10px;
    color: ${p => p.color ? p.color : 'black'};
`
const Line = styled.span<{ styleLine?: string, color?: string }>`
    border-top: 3px ${p => p.styleLine ? p.styleLine : 'solid'} ${p => p.color ? p.color : 'black'};
    display: inline-block;
    width: 5%;
    margin-right: 15px;
`
const LineShort = styled.span<{ styleLine?: string, color?: string }>`
    border-top: 3px ${p => p.styleLine ? p.styleLine : 'solid'} ${p => p.color ? p.color : 'black'};
    display: inline-block;
    width: 5%;
    margin-right: 15px;
`
type Props = {
    stepNumber: number;
}

const PartnerStepper = (props: Props) => {

    function getStepColor(stepNumber: number, numberOfStep: number): string | undefined {
        if (numberOfStep > stepNumber) {
            return '#9da6ad';
        } else if (numberOfStep == stepNumber) {
            return '#ff9d30';
        } else {
            return '#53a451';
        }
    }
    function getStepBorderColor(stepNumber: number, numberOfStep: number): string | undefined {
        if (numberOfStep > stepNumber) {
            return '#9da6ad';
        } else if (numberOfStep == stepNumber) {
            return '#ff9d30';
        } else {
            return '#53a451';
        }
    }
    function getLineStyle(stepNumber: number, numberOfStep: number): string | undefined {
        if (numberOfStep > stepNumber) {
            return 'dashed';
        } else {
            return 'solid';
        }
    }

    function getShowStepNumber(numberOfStep: number) {
        if (props.stepNumber > numberOfStep){
            return <CheckIcon fontSize='small'/>
        }else{
            return numberOfStep
        }
    }

    return (
        <div>
            <StepperContainer padding={props.stepNumber == StepPage.MAIN ? '10px 0px 10px 0px' : null}>
                {props.stepNumber != StepPage.MAIN && (
                    <div className='row text-center'>
                        <div className='col-12 my-auto pl-0'>
                            <Step>
                                <Circle bgcolor={getStepColor(props.stepNumber, 1)} borderColor={getStepBorderColor(props.stepNumber, 1)}><span className='step'>{getShowStepNumber(1)}</span></Circle>
                                <TitleStep color={getStepColor(props.stepNumber, 1)}> เลือกประเภทความรู้ที่สนใจ</TitleStep>
                            </Step>
                            <Line styleLine={getLineStyle(props.stepNumber, 1)} color={getStepColor(props.stepNumber, 1)}></Line>
                            <Step>
                                <Circle bgcolor={getStepColor(props.stepNumber, 2)} borderColor={getStepBorderColor(props.stepNumber, 2)}><span className='step'>{getShowStepNumber(2)}</span></Circle>
                                <TitleStep color={getStepColor(props.stepNumber, 2)}> แนะนำหลักสูตรและสื่อความรู้</TitleStep>
                            </Step>
                            <Line styleLine={getLineStyle(props.stepNumber, 2)} color={getStepColor(props.stepNumber, 2)}></Line>
                            <Step>
                                <Circle bgcolor={getStepColor(props.stepNumber, 3)} borderColor={getStepBorderColor(props.stepNumber, 3)}><span className='step'>{getShowStepNumber(3)}</span></Circle>
                                <TitleStep color={getStepColor(props.stepNumber, 3)}> ส่งข้อมูลแจ้งความสนใจ</TitleStep>
                            </Step>
                        </div>
                    </div>
                )}
                {props.stepNumber == StepPage.MAIN && (
                    <div className='d-flex text-center'>
                        <div className='col-12 my-auto pl-0'>
                            <Step>
                                <Circle bgcolor={'#ff9d30'} borderColor={'#ff9d30'}><span className='step'>1</span></Circle>
                                <TitleStep color={'#38383D'}> เลือกประเภทความรู้ที่สนใจ</TitleStep>
                            </Step>
                            <LineShort styleLine={'solid'} color={'#ff9d30'}></LineShort>
                            <Step>
                                <Circle bgcolor={'#ff9d30'} borderColor={'#ff9d30'}><span className='step'>2</span></Circle>
                                <TitleStep color={'#38383D'}> แนะนำหลักสูตรและสื่อความรู้</TitleStep>
                            </Step>
                            <LineShort styleLine={'solid'} color={'#ff9d30'}></LineShort>
                            <Step>
                                <Circle bgcolor={'#ff9d30'} borderColor={'#ff9d30'}><span className='step'>3</span></Circle>
                                <TitleStep color={'#38383D'}> ส่งข้อมูลแจ้งความสนใจ</TitleStep>
                            </Step>
                        </div>
                    </div>
                )}


            </StepperContainer>
        </div>
    )
}
export default PartnerStepper;