import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import React from "react";
import { useState } from "react";
import { ProgressBar } from "react-bootstrap";
import { PartnerMediaOverview, PartnerMediaOverviewScore } from "../../../../model/response/partner-activity-media";
import { BasicResponse } from "../../../../api/define";

type Props = {
    item: PartnerMediaOverview | undefined,
    mediaType: BasicResponse[],
}

const PartnerActivityReceiveMediaOverview = (props: Props) => {
    const [expand, setExpand] = useState(true);

    const convertEnumTypeToString = (value: string): string => {
        const result = props?.mediaType.find(f => f.value === value);
        return result ? result.label : "-";
    }

    return (<React.Fragment>
        <div style={{ background: "white" }} className='mt-2'>
            <div className="text-left" style={{ position: "relative", marginBottom: 5 }}>
                <div className={'d-flex yellow-button'} style={{ padding: "15px", borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}>
                    {((expand === undefined) || (expand === false)) &&
                        <div onClick={(e) => setExpand(true)}><ExpandMore className="pointer mr-2" ></ExpandMore></div>
                    }
                    {(expand === true) &&
                        <div onClick={(e) => setExpand(false)}><ExpandLess className="pointer mr-2" ></ExpandLess></div>
                    }
                    <label className='ml-2 my-auto'><span style={{ fontWeight: 600, marginRight: 10 }}>ภาพรวมข้อมูลการรับสื่อ</span></label>
                </div>
                {(expand === true) && (<div>
                    <div style={{ padding: "15px", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px", border: "3px solid #fab232" }}>
                        <div className='row'>
                            <label className='col' style={{ fontWeight: 600 }}>สัดส่วน (จำนวนรายการ) รูปแบบสื่อแต่ละประเภท</label>
                        </div>
                        {(props.item && props.item?.scoreList.length > 0 && (
                            props.item?.scoreList.map((score: PartnerMediaOverviewScore, index: number) => {
                                return (<div className='row d-flex align-items-center' key={index}>
                                    <div className='col-1'></div>
                                    <div className='col-3'>
                                        {convertEnumTypeToString(score.partnerMediaType)} ({score.percentScore}%)
                                    </div>
                                    <div className='col-4'>
                                        <ProgressBar now={score.amount} label={`${score.percentScore}%`} max={props.item?.scoreList[0].amount} className={index === 0 ? 'blue-bg': 'blue-light-bg'} />
                                    </div>
                                    <div className='col-1' style={{fontSize: '20px'}}>
                                        {score.amount} รายการ
                                    </div>
                                </div>)
                            })
                        ))}
                        {((!props.item || props.item.scoreList.length === 0) && (
                            <div className="row py-2">
                                <label className="col">ไม่พบข้อมูลการรับสื่อ</label>
                            </div>
                        ))}
                    </div>
                </div>)}
            </div>
        </div>
    </React.Fragment>);
}

export default PartnerActivityReceiveMediaOverview