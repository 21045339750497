import React, { useEffect, useState } from 'react'
import { Button, Modal, ModalProps } from 'react-bootstrap';
import { PartnerKnowledgeRegistration } from '../../../../model/request/partner-knowledge-regis';

type Props = ModalProps & {
    partnerList: PartnerKnowledgeRegistration[] | undefined;
    onOk: (partnerList: PartnerKnowledgeRegistration | undefined) => void
}

const ManagePartnerSelectEmailCustomer = (props: Props) => {
    const [selectEmailCustomer, setSelectEmailCustomer] = useState<any>();
    const [partnerList, setPartnerList] = useState<PartnerKnowledgeRegistration[]>([]);
    const [submitted, setSubmitted] = useState(false);
    useEffect(() => {
        if (!props.show) {
            setPartnerList([])
        }
        if (props.partnerList) {
            let partner = props.partnerList.filter(x => x.verify);
            setPartnerList(partner ? partner : [])
        } else {
            setPartnerList([])
        }
    }, [props.show])
    const submit = () => {
        setSubmitted(true);
        if (!validateForm()) {
            alert({ message: "กรุณากรอกแบบฟอร์มให้ครบ", type: 'error' });
            return;
        } else {
            let newItem = [...props.partnerList as any]
            if (newItem) {
                let result = newItem.find(x => x.coordinatorId == selectEmailCustomer);
                props.onOk(result);
            } else {
                props.onOk(undefined);
            }

        }
    }
    const validateForm = () => {
        let error = selectEmailCustomer == null || selectEmailCustomer == ''
        return error ? false : true;
    }
    return (
        <Modal
            {...props}
            dialogClassName="modal-60w"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    กรุณาเลือก e-Mail ผู้ประสานงานเพื่อให้กรอกข้อมูลองค์กรเพิ่มเติม
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='text-secondary'>ระบบจะส่ง Link ผ่าน e-Mail ที่องค์กรแจ้งไว้
                    เมื่อองค์กรได้e-Mail และต้องการกรอกข้อมูลเพิ่มเติม จะต้องขอรหัส OTP ก่อนทุกครั้ง
                    โดย e-Mail ที่ออกจากระบบจะใช้ชื่อ SET_Learnscape_no_reply@set.or.th
                    Subject “แจ้ง Link เพื่อกรอกข้อมูลพันธมิตรเพิ่มเติม”</div>
                <form>
                    {partnerList.map(x => (
                        <div className='d-flex'>
                            <input type="radio" name="partner" checked={selectEmailCustomer == x.coordinatorId} onChange={(e) => { setSelectEmailCustomer(x.coordinatorId) }} /> <span className='ml-2'>{x.firstname} {x.lastname} ({x.email})</span>
                        </div>
                    ))}
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.onHide}>
                    {props.cancelButton || 'Close'}
                </Button>
                <Button variant="success" onClick={() => { submit() }}>
                    {props.okButton || 'Send Email'}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ManagePartnerSelectEmailCustomer