import React, { useEffect, useRef, useState } from 'react'
import { Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useStore } from 'react-redux';
import { uploadAnnounce, uploadBanner } from '../../store/editdoc/actions';
import loadinglogo from '../../img/loading.gif';
type Props = {}

const TestUpload = (props: Props) => {
    const bannerRef = useRef(null);
    const announceRef = useRef(null);
    const [fileBanner, setfileBanner] = useState();
    const [fileAnnounce, setfileAnnounce] = useState();
    const [description, setDescription] = useState();
    const [topic, setTopic] = useState();
    const [createBy, setCreateBy] = useState();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const store = useStore();
    useEffect(() => {
        const unsubscribe = store.subscribe(() => {
            let res = store.getState().SearchReducer;
            if (res.isLoading) {
                setLoading(true);
            } else if (res.isLoaded) {
                setLoading(false);
                if (res.table === "upload_banner") {
                    alert('Success Banner Upload')
                    //bannerRef.current.
                }
                if (res.table === "upload_announce") {
                    alert('Success Announce Upload')
                    //bannerRef.current.
                }
            }else if (res.isFailed) {
                  alert('Error Upload'+res.data.message)
                  setLoading(false);
            }
        });

        return unsubscribe;
    }, [])
    const handleUploadBanner = () => {
        console.log(fileBanner);
        if (fileBanner) {
            dispatch(uploadBanner(fileBanner!))
            if (bannerRef && bannerRef.current) (bannerRef.current as any).value = null;
        } else {
            alert('No File')
        }

    }

    const handleUploadAnnounce = () => {
        console.log(fileAnnounce);
        if (fileAnnounce && description && topic && createBy) {
            dispatch(uploadAnnounce(fileAnnounce!, description!, topic!, createBy!))
            if (announceRef && announceRef.current) (announceRef.current as any).value = null;
        } else {
            alert('No File or Required field')
        }

    }
    return (
        <div>
            <div className='d-flex p-4 col-6'>
                <div className='col-auto'>Banner upload</div>
                <input type="file"
                    className='form-control'
                    ref={bannerRef}
                    onChange={(e: any) => {
                        setfileBanner(e.target.files[0]);
                    }}

                />
                <Button onClick={handleUploadBanner} disabled={loading}>
                    {(loading) &&
                        <div style={{ textAlign: "center" }}><img src={loadinglogo} width="30" height="30" className="d-inline-block align-top" alt="" /></div>
                    }
                    {(!loading) &&
                        <>Upload</>
                    }
                </Button>
            </div>

            <div className='d-flex p-4'>
                <div className='col-auto'>Announce upload</div>

                <div className='d-flex flex-column col-8'>
                    <input type="file"
                        className='form-control'
                        ref={announceRef}
                        onChange={(e: any) => {
                            setfileAnnounce(e.target.files[0]);
                        }}

                    />
                    <textarea
                        className='form-control'
                        placeholder='description'
                        value={description} onChange={(e: any) => setDescription(e.target.value)} />
                    <input type='text'
                        className='form-control'
                        placeholder='topic'
                        value={topic} onChange={(e: any) => setTopic(e.target.value)} />
                    <input type='text'
                        className='form-control'
                        placeholder='createBy'
                        value={createBy} onChange={(e: any) => setCreateBy(e.target.value)} />
                </div>

                <Button onClick={handleUploadAnnounce} disabled={loading}>
                    {(loading) &&
                        <div style={{ textAlign: "center" }}><img src={loadinglogo} width="30" height="30" className="d-inline-block align-top" alt="" /></div>
                    }
                    {(!loading) &&
                        <>Upload Annouce</>
                    }
                </Button>
            </div>

        </div>
    )
}

export default TestUpload