import { useEffect, useState } from "react"
import MainMenu from "../menu"
import LogRequestSuggestion from "./component/LogRequestSuggestion"
import LogRequestTab from "./component/LogRequestTab"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector, useStore } from "react-redux"
import { queryclear } from "../../../store/search/actions"
import loadinglogo from '../../../img/loading.gif';
import { Util } from "../../../api/util"
import { Button, InputGroup } from "react-bootstrap"
import { listsurveygroup } from "../../../store/surveygroup/actions"
import { SearchPanel, TableHeaderBlack } from "../../../style/tableStyle"
import { useForm } from "react-hook-form"
import { CustomPage } from "../../share/CustomPage"
import Sort from "../../share/Sort"
import { LogRequstResponse, RegisterStatus, ResponsibleDept } from "../../../model/response/log-request"
import LogRequestSuggestionHistory from "./component/LogRequestSuggestionHistory"
import { listKnowledgeDepartment } from "../../../store/editpartner/actions"
import { SingleSearch } from "../../tab/search-component"
import { KnowledgeDepartmentResponse, ManageKnowledgeResponse } from "../../../model/response/manage-knowledge"
import { MultiSelectComponent } from "../../tab/multi-select-component"
import { DropdownTree } from "../../../model/response/dropdowm-tree";
import { Tooltip, TreeSelect } from 'antd';
import { ThaiDateTimePicker } from "../../tab/thai-datetime-picker";
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';
import { PartnerRegistrationStatus, createLogRequestStatusType } from "../../../api/define"
import { getKnowledgeCategoryDropdown, getRegisterStatus, listLogRequest } from "../../../store/logRequest/action"
import { CustomChip } from "../../../style/partnerStylemain"
import { AlertContainer, alert } from "react-custom-alert"
import excellogo from '../../../img/excel.png';
import PartnerLoadingModal from "../../partner/PartnerLoadingModal"
import { getDeptId, getDeptName } from "../../../store/auth/selectors"
import ErrorOutline from "@material-ui/icons/ErrorOutline"
import SmsIcon from '@material-ui/icons/Sms';
const { SHOW_PARENT } = TreeSelect;

type Props = {}

const LogRequestHistory = (props: Props) => {
    const [init, setInit] = useState(1);
    const [initDepartment, setInitDepartment] = useState(0);
    const [initCategory, setInitCategory] = useState(0);
    const [initStatus, setInitSatus] = useState(0);
    const {responsibleDepIdsSearch,keywordSearch,subCatValueSearch,fromDateSearch,toDateSearch,statusIdSearch,pageSearch,pageSizeSearch,sortBySearch,sortTypeSearch} = window.sessionStorage.getItem("searchLogRequestHistory")? JSON.parse(window.sessionStorage.getItem("searchLogRequestHistory") as any):"{responsibleDepIdsSearch:[],keywordSearch:'',subCatValueSearch:[],fromDateSearch:null,toDateSearch:null,statusIdSearch:null,pageSearch:'',pageSizeSearch:'',sortBySearch:'',sortTypeSearch:''}" as any;
    const [loading, setLoading] = useState(false);
    const store = useStore();
    const dispatch = useDispatch();
    const history = useHistory();
    const [modalShow, setModalShow] = useState<boolean | undefined>();
    const [logRequestItem, setLogRequestItem] = useState<LogRequstResponse[]>([]);
    const [page, setPage] = useState<number>(pageSearch?pageSearch:0);
    const [size, setSize] = useState<number>(pageSizeSearch?pageSizeSearch:10);
    const [sortBy, setSortBy] = useState<string | undefined>(sortBySearch?sortBySearch:undefined);
    const [sortType, setSortType] = useState<'asc' | 'desc'>(sortTypeSearch?sortTypeSearch:'desc');
    const [error, setError] = useState('');
    const [totalElement, setTotalElement] = useState<number>(0);
    
    const [partnerName, setPartnerName] = useState<string | undefined>(keywordSearch?keywordSearch:undefined);
    const deptId = useSelector(getDeptId);
    const deptName = useSelector(getDeptName);
    const [responsibleDepIds, setResponsibleDepIds] = useState<any[]>(responsibleDepIdsSearch?responsibleDepIdsSearch:[])
    const [departmentItem, setDepartmentItem] = useState<KnowledgeDepartmentResponse[]>([])
    const [categoryTreeDropdown, setCategoryTreeDropdown] = useState<DropdownTree[]>([]);
    const [subCatValue, setSubCatValue] = useState<number[]>(subCatValueSearch?subCatValueSearch:[]);
    const [fromDate, setFromDate] = useState<Date | null>(fromDateSearch?fromDateSearch:null)
    const [toDate, setToDate] = useState<Date | null>(toDateSearch?toDateSearch:null);
    const [statusItem, setStatusItem] = useState<RegisterStatus[] | null>();
    const [statusId, setStatusId] = useState<number | null>(statusIdSearch?statusIdSearch:null);
    // const [partner, setPartner] = useState<any[]>(partnerSearch?partnerSearch:[])
    useEffect(() => {
        const unsubscribe = store.subscribe(() => {
            let res = store.getState().SearchReducer;
            if (res.isLoading) {
                setError('');
                setLoading(true);
            } else if ((res.isLoaded)) {
                setError('');
                setLoading(false);
                if (res.data != null) {
                    if (res.table === 'list_knowledge_department') {
                        setDepartmentItem(res.data);
                        setInitDepartment(1)

                        if (responsibleDepIds.length>0||partnerName||subCatValue.length>0||fromDate||toDate||statusId||page!==0) {
                            var sort = sortBy&&sortType? sortBy + ',' + sortType : undefined;
                            dispatch(listLogRequest(responsibleDepIds, partnerName, subCatValue, fromDate, toDate, statusId, page, size, sort, "HISTORY"))
                        } else {
                            let department = findDepartmentSet(res.data);
                            dispatch(listLogRequest(department, undefined, undefined, undefined, undefined, undefined, 0, size, undefined, "HISTORY"))
                        }
                        
                    } else if (res.table === "log_request") {
                        setLogRequestItem(res.data.elements);
                        setTotalElement(res.data.totalElements);
                        setInit(1);
                    } else if (res.table === "knowledge_cat_dropdown") {
                        setCategoryTreeDropdown(res.data)
                        setInitCategory(1)
                    } else if (res.table === "register_status") {
                        setStatusItem(res.data);
                        setInitSatus(1);
                    }
                }
            } else if (res.isUpdating) {
                setError('');
                setLoading(true);
            } else if (res.isUpdated) {
                setError('');
                setLoading(false);


            } else if (res.isFailed) {
                setError(res.data.message);
                setLoading(false);
                alert({ message: res.data.message, type: 'error' });
            }

        })
        dispatch(listKnowledgeDepartment());
        dispatch(getKnowledgeCategoryDropdown());
        dispatch(getRegisterStatus(true));
        removeSession();

        return unsubscribe;
    }, []);

    const removeSession = () => {
        sessionStorage.removeItem("searchDetails");
        sessionStorage.removeItem("searchPartnerDetails");
        sessionStorage.removeItem("searchSurveyDetails");
        sessionStorage.removeItem("searchTrainerDetails");
        sessionStorage.removeItem("searchManageQuestion");
        sessionStorage.removeItem("searchLogRequest");
        // sessionStorage.removeItem("searchLogRequestHistory");
      }

    const handleInitPage = () => {
        history.push("/admin/log-request");
    }

    const handleHistoryPage = () => {
        history.push("/admin/log-request-history");
    }

    const onChangeCategoryDropdown = (newValue: number[]) => {
        setSubCatValue(newValue);
    };

    const findDepartmentSet = (knowledgeDepartment: KnowledgeDepartmentResponse[]) => {
        let res = undefined;
        if (knowledgeDepartment) {
            knowledgeDepartment.forEach(element => {
                if (element.id == deptId) {
                    setResponsibleDepIds([{ label: element.name, value: element.id }])
                    res = [{ label: element.name, value: element.id }];
                    return;
                }
            });
        } else {
            return undefined
        }
        return res;

    }

    const handleClear = () => {
        setPage(0);
        setSize(10);
        setSortBy(undefined);
        setPartnerName('');
        setResponsibleDepIds([]);
        setSubCatValue([]);
        setFromDate(null);
        setToDate(null);
        setStatusId(null);
        dispatch(queryclear());
        dispatch(listLogRequest(undefined, undefined, undefined, undefined, undefined, undefined, 0, 10, undefined, "HISTORY"))
        window.sessionStorage.setItem("searchLogRequestHistory",JSON.stringify({responsibleDepIdsSearch:[],keywordSearch:'',subCatValueSearch:[],fromDateSearch:null,toDateSearch:null,statusIdSearch:null,pageSearch:0,pageSizeSearch:10,sortBySearch:undefined,sortTypeSearch:undefined}))
    }

    const onSubmit = () => {
        var pageNo = 0;
        setPage(pageNo);
        setSortBy(undefined);
        dispatch(queryclear());
        dispatch(listLogRequest(responsibleDepIds, partnerName, subCatValue, fromDate, toDate, statusId, pageNo, size, undefined, "HISTORY"))
        window.sessionStorage.setItem("searchLogRequestHistory",JSON.stringify({responsibleDepIdsSearch:responsibleDepIds,keywordSearch:partnerName,subCatValueSearch:subCatValue,fromDateSearch:fromDate,toDateSearch:toDate,statusIdSearch:statusId,pageSearch:pageNo,pageSizeSearch:size,sortBySearch:undefined,sortTypeSearch:undefined}))
    }

    const onPageChange = (value: number) => {
        setPage(value);
        let sort = sortBy ? sortBy + ',' + sortType : undefined;
        dispatch(listLogRequest(responsibleDepIds, partnerName, subCatValue, fromDate, toDate, statusId, value, size, sort, "HISTORY"))
        window.sessionStorage.setItem("searchLogRequestHistory",JSON.stringify({responsibleDepIdsSearch:responsibleDepIds,keywordSearch:partnerName,subCatValueSearch:subCatValue,fromDateSearch:fromDate,toDateSearch:toDate,statusIdSearch:statusId,pageSearch:value,pageSizeSearch:size,sortBySearch:sortBy,sortTypeSearch:sortType}))
    }

    const onPageSizeChange = (value: number) => {
        var pageNo = 0;
        setPage(pageNo);
        setSize(value);
        let sort = sortBy ? sortBy + ',' + sortType : undefined;
        dispatch(listLogRequest(responsibleDepIds, partnerName, subCatValue, fromDate, toDate, statusId, pageNo, value, sort, "HISTORY"));
        window.sessionStorage.setItem("searchLogRequestHistory",JSON.stringify({responsibleDepIdsSearch:responsibleDepIds,keywordSearch:partnerName,subCatValueSearch:subCatValue,fromDateSearch:fromDate,toDateSearch:toDate,statusIdSearch:statusId,pageSearch:pageNo,pageSizeSearch:value,sortBySearch:sortBy,sortTypeSearch:sortType}))
    }

    const onSort = (sortName: string, newSortType: 'asc' | 'desc') => {
        setSortBy(sortName);
        setSortType(newSortType);
        dispatch(listLogRequest(responsibleDepIds, partnerName, subCatValue, fromDate, toDate, statusId, page, size, sortName + ',' + newSortType, "HISTORY"))
        window.sessionStorage.setItem("searchLogRequestHistory",JSON.stringify({responsibleDepIdsSearch:responsibleDepIds,keywordSearch:partnerName,subCatValueSearch:subCatValue,fromDateSearch:fromDate,toDateSearch:toDate,statusIdSearch:statusId,pageSearch:page,pageSizeSearch:size,sortBySearch:sortName,sortTypeSearch:newSortType}))
    }




    const createDepartment = () => {
        var res: any[] = [];
        if (departmentItem) {
            for (var i = 0; i < departmentItem.length; i++) {
                res.push({ label: departmentItem[i].name, value: departmentItem[i].id });
            }
        }

        return res;
    }
    const handleFromDate = (e: Date | null) => {
        setFromDate(e);
    }

    const handleToDate = (e: Date | null) => {
        setToDate(e);
    }

    const createStatusSearchFilter = () => {
        var res: any[] = [];
        if (statusItem) {
            for (var i = 0; i < statusItem.length; i++) {
                res.push({ label: statusItem[i].transition, value: statusItem[i].id });
            }
        }
        return res;
    }
    const getStatus = () => {
        if (statusItem) {
            for (var i = 0; i < statusItem.length; i++) {
                if (statusItem[i].id === statusId) {
                    return { label: statusItem[i].transition, value: statusItem[i].id };
                }
            }
        }
        return null;
    }
    
    const getStatusChip = (status: PartnerRegistrationStatus) => {
        switch (status) {
            case PartnerRegistrationStatus.NEW:
                return <CustomChip background="#D5F0F6" color="#2878CC">รายการใหม่</CustomChip>
            case PartnerRegistrationStatus.PENDING_CUSTOMER:
                return <CustomChip background="#FFFBE5" color="#E97331">ส่งฟอร์มให้กรอกข้อมูล</CustomChip>
            case PartnerRegistrationStatus.PROCESS:
                return <CustomChip background="#E0DEED" color="#59529D">อยู่ระหว่างดำเนินการ</CustomChip>
            case PartnerRegistrationStatus.COMPLETE:
                return <CustomChip background="#FAFBEA" color="#4A8B41">บันทึกลงฐานข้อมูล</CustomChip>
            case PartnerRegistrationStatus.CANCEL:
                return <CustomChip background="#F6D5E2" color="#CA494A">องค์กรยังไม่สนใจ</CustomChip>
            default: return <></>
        }
    }
    const getKnowledgeDescription = (knowledgeList: ManageKnowledgeResponse[]) => {
        if (knowledgeList && knowledgeList.length > 0) {
            return knowledgeList && knowledgeList.map(x => (
                <div>
                    <div>{x.categoryName}</div>
                    <div><ul>
                        {x.subCategories.map(subCat => (
                            <li>{subCat.subCategoryName}</li>
                        ))}
                    </ul></div>
                </div>
            ))
        } else {
            return "-"
        }

    }
    const excelElement: any = () => {
        return <Button type="button" className="btn btn-success green-button ml-1"
            onClick={() => handleExcel()} >
            <img src={excellogo} height="26px" alt="excel" style={{ marginLeft: "-3px" }} />
        </Button>
    }
    const handleExcel = () => {
        // window.open(SurveyAPI.getDownloadSummarySurvey(id, encodeURIComponent(token)));
    }
    const modalBodyLoadingData = () => {
        return <span>กรุณารอสักครู่</span>
    }
    const handleOpenPartnerProfile = (registrationId: number) => {
        history.push("/admin/log-request/"+ registrationId);
    }

    return (
        <div>
            <MainMenu>
                {(init + initDepartment + initCategory + initStatus === 4) &&
                    <>
                        <div className="query-wrapper">
                            <br />
                            <div className="yellow-button" style={{ textAlign: "left", borderTopLeftRadius: "30px", borderTopRightRadius: "30px", height: "70px", paddingTop: "15px", paddingLeft: "15px" }}><h4 className="header">การจัดการ Log</h4></div>
                            <br />
                            <LogRequestSuggestionHistory />
                            <br />
                            {LogRequestTab(2, true, handleInitPage, handleHistoryPage)}
                            <div style={{ background: "white", padding: "20px", borderRadius: "5px" }}>
                                <form>
                                    <SearchPanel>
                                        <div className='row'>
                                            <div className='col-6'>
                                                <label className="col-form-label">ชื่อองค์กร</label>
                                                <input className="form-control" type="text" name="partnerName" id="input-text-partner-name" onChange={e => setPartnerName(e.target.value)} value={partnerName}></input>
                                            </div>
                                            <div className='col-6'>
                                                <label className="col-form-label">ฝ่ายงานที่รับผิดชอบ</label>
                                                <MultiSelectComponent options={createDepartment()}
                                                    value={responsibleDepIds}
                                                    onChange={setResponsibleDepIds} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className='col-6'>
                                                <label className="col-form-label">รูปแบบ</label>
                                                <TreeSelect treeData={categoryTreeDropdown}
                                                    treeCheckable={true}
                                                    treeNodeFilterProp='title'
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                    value={subCatValue}
                                                    treeDefaultExpandAll
                                                    showSearch
                                                    onChange={onChangeCategoryDropdown} />
                                            </div>
                                            <div className="col-3">
                                                <label className="col-form-label">วันที่เริ่ม</label>

                                                <div  className='position-relative'>
                                                    <ThaiDateTimePicker className="form-control" selected={fromDate} onChange={(fromDate: any) => handleFromDate(fromDate)}
                                                    />
                                                    <div className="search-icon" style={{ position: "absolute", right: "10px", top: "5px" }}>
                                                        <CancelIcon style={{ visibility: (fromDate == null) ? "hidden" : "visible", color: "gray" }} onClick={(e) => { setFromDate(null); }} />
                                                        <CalendarTodayIcon style={{ color: "gray" }} />
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="col-3">
                                                <label className="col-form-label">วันที่สิ้นสุด</label>
                                                <div  className='position-relative'>
                                                    <ThaiDateTimePicker locale="th" className="form-control" dateFormat="dd/MM/yyyy" selected={toDate} clear={() => setToDate(null)} onChange={(toDate: any) => handleToDate(toDate)}
                                                    />
                                                    <div className="search-icon" style={{ position: "absolute", right: "10px", top: "5px" }}>
                                                        <CancelIcon style={{ visibility: (toDate == null) ? "hidden" : "visible", color: "gray" }} onClick={(e) => setToDate(null)} />
                                                        <CalendarTodayIcon style={{ color: "gray" }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6">
                                                <label className="col-form-label">สถานะ</label>
                                                <SingleSearch id={"input-dropdown-status"} isSearchable={true} isMulti={false} placeholder=""
                                                    onChange={(id: number, e: any) => setStatusId(e.value)}
                                                    value={getStatus()}
                                                    options={createStatusSearchFilter()}
                                                    style={{ width: "100%" }}
                                                />
                                            </div>

                                        </div>

                                    </SearchPanel>
                                </form>
                                <div className="row mt-3" style={{ justifyContent: "center" }}>
                                    <button type="button" className="btn btn-outline-primary" style={{ width: "150px" }}
                                        onClick={() => handleClear()} >
                                        Clear
                                    </button>&nbsp;
                                    <button type="submit" className="btn btn-primary" style={{ width: "150px" }} onClick={onSubmit}
                                        disabled={loading}>
                                        <div>
                                            {(loading) &&
                                                <div style={{ textAlign: "center" }}><img src={loadinglogo} width="30" height="30" className="d-inline-block align-top" alt="" /></div>
                                            }
                                            {(!loading) &&
                                                <>Search</>
                                            }
                                        </div>
                                    </button>
                                </div>
                            </div>
                            <br />
                            <CustomPage current={page} pageSize={size} total={totalElement} onPageChange={onPageChange} onPageSizeChange={onPageSizeChange} loading={loading && (modalShow !== true)} postElement={excelElement()}>
                                <TableHeaderBlack striped hover className='mt-2'>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th className='text-left' style={{ verticalAlign: "top" }}>
                                                <Sort label='วันที่-เวลา' currentSort={sortBy} sortName='createDate' handleClick={onSort} sortType={sortType} />
                                            </th>
                                            <th className='text-left' style={{ verticalAlign: "top", width: '18%' }}>
                                                องค์กร

                                            </th>
                                            <th className='text-left' style={{ verticalAlign: "top" }}>
                                                ฝ่ายงานที่รับผิดชอบ
                                            </th>
                                            <th style={{ verticalAlign: "top", width: '15%' }}>
                                                สถานะ

                                            </th>
                                            <th style={{ verticalAlign: "top" }}>
                                                จำนวนรูปแบบ

                                            </th>
                                            <th style={{ verticalAlign: "top", width: '10%' }}>
                                                ปรับปรุงล่าสุดโดย

                                            </th>
                                            <th className='text-left' style={{ verticalAlign: "top" }}>
                                                วันเวลาที่ปรับปรุงล่าสุด
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {logRequestItem && logRequestItem.map((item: LogRequstResponse, index: number) => (
                                            <tr key={'logRequestItem' + item.registrationId}>
                                                <td>
                                                    {(item.partnerAnswered) && <ErrorOutline style={{ color: "#F9B232", height: "25px", width: "25px" }} />}
                                                </td>
                                                <td className='text-left'>{Util.datetimetostr(new Date(item.registrationDate))}</td>
                                                <td className='text-left'><a className="link" onClick={()=>handleOpenPartnerProfile(item.registrationId)}  target="_blank" >{item.partnerName}</a></td>
                                                <td className='text-left'>
                                                    {item.responsibleDeptList && item.responsibleDeptList.map((dept: ResponsibleDept, i: number) => (
                                                        <span>
                                                            {(dept.checked) &&
                                                                <span style={{ margin: "0px 2px 0px 2px", padding: "1px 12px 1px 12px", borderRadius: "5px", border: "1px solid #2878CC", background: "#D5F0F6", fontSize: "17px", color: "#2878CC" }}>{dept.shortName}</span>
                                                            }
                                                            {(!dept.checked) &&
                                                                <span style={{ margin: "0px 2px 0px 2px", padding: "1px 12px 1px 12px", borderRadius: "5px", border: "1px solid #c9d2d4", background: "#FFFFFF", fontSize: "17px", color: "#c9d2d4" }}>{dept.shortName}</span>
                                                            }
                                                        </span>
                                                    ))}
                                                </td>
                                                <td className="text-left">{getStatusChip(item.status)}</td>
                                                <td className='text-center'>
                                                    <Tooltip placement="left" title={getKnowledgeDescription(item.knowledgeList)}>
                                                        <Button className='light-gray-button-2 btn-small'><SmsIcon className="blue" style={{ cursor: 'pointer' }} ></SmsIcon><span style={{ fontSize: 18, marginLeft: 4, color: 'black' }}>{item.knowledgeList?.length}</span></Button>
                                                    </Tooltip></td>
                                                <td className='text-left'>{item.updateBy}</td>
                                                <td className='text-left'>{Util.datetimetostr(new Date(item.updateDate))}</td>
                                            </tr>
                                        ))}
                                        {logRequestItem.length == 0 && (
                                            <tr>
                                                <td colSpan={10}>ไม่มีรายการ</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </TableHeaderBlack>
                            </CustomPage>
                            <br />

                        </div>
                    </>
                }
            </MainMenu>
            <AlertContainer floatingTime={3000} />
        </div>
    )
}

export default LogRequestHistory

