import React from 'react'
import { PartnerCategory } from '../../model/response/partner-category';
import styled from 'styled-components';

type Props = {
    step:number;
    listCategory: PartnerCategory[]
}
const ButtonStepper = styled.div<{color?:string}>`
    display: inline-block;
    padding: 3px 12px 3px 12px;
    border-radius: 4px;
    font-size: 1.2rem;
    background-color: white;
    border: 1px solid ${p => p.color != 'black' ? p.color : '#d9d9d9'};
    color: ${p => p.color ? p.color : 'black'};
    margin-right: 3px;
    margin-left: 3px;
    transition: 0.5s;
`
const PartnerChooseTopicStepper = (props: Props) => {
    const getColor=(index:number)=>{
        if(index == props.step){
            return '#fcb23a'
        }else return 'black'
    }
  return (
    <div>{props.listCategory.map((x,index)=>(
        <ButtonStepper color={getColor(index)}>{index+1}</ButtonStepper>
    ))}</div>
  )
}

export default PartnerChooseTopicStepper