import React, { useEffect, useRef, useState } from 'react'
import { Button, InputGroup, Modal, ModalProps } from 'react-bootstrap'
import { ManageKnowledge, ManageKnowledgeResponse } from '../../../../model/response/manage-knowledge';
import { MediaType, createMediaType } from '../../../../api/define';
import { SingleSearch } from '../../../tab/search-component';
import { useDispatch, useStore } from 'react-redux';
import { uploadFileKnowledge } from '../../../../store/editpartner/actions';
import { AlertContainer, alert } from 'react-custom-alert';
import { Divider } from '@material-ui/core';
import { spacing } from 'react-select/dist/declarations/src/theme';
type Props = ModalProps &
{
    okButton?: string;
    cancelButton?: string;
    modalItem?: ManageKnowledge;
    mediaType?: MediaType
    knowledgeList: ManageKnowledgeResponse[];
    indexCat: number;
    subCatIndex: number;
    mediaIndex?: number;
    onOk: (knowledgeList: ManageKnowledgeResponse[]) => void
}
const ManageKnowledgeMediaEdit = (props: Props) => {
    const [copyKnowledgeList, setCopyKnowledgeList] = useState<ManageKnowledgeResponse[]>([...props.knowledgeList]);
    const [mediaType, setMediaType] = useState<string | undefined>(props.mediaType ?? undefined);
    const [submitted, setSubmitted] = useState(false);
    const [title, setTitle] = useState<string | undefined>();
    const [description, setDescription] = useState<string | undefined>();
    const [link, setLink] = useState<string | undefined>();
    const [status, setStatus] = useState<boolean | undefined>();
    const [fileId, setFileId] = useState<number | undefined>();
    const [fileName, setFileName] = useState();
    const [imageS3, setImageS3] = useState<string | undefined>();
    const store = useStore();
    const dispatch = useDispatch();
    const hiddenFileInput = useRef<any>(null);
    useEffect(() => {
        const unsubscribe = store.subscribe(() => {
            let res = store.getState().SearchReducer;
            if (res.isLoading) {
            } else if ((res.isLoaded)) {
                if (res.data != null) {
                    if (res.table === 'upload_file_knowledge') {
                        setFileId(res.data.fileId);
                        setImageS3(res.data.imageS3byte);
                    }
                }
            }

        })
        return unsubscribe;
    }, []);
    useEffect(() => {
        if (!props.show) {
            setSubmitted(false);
            setMediaType(undefined);
            setTitle(undefined);
            setDescription(undefined);
            setLink(undefined);
            setFileId(undefined);
            setFileName(undefined);
            setImageS3(undefined);
            setStatus(undefined);
        }
        if (props.modalItem) {
            setSubmitted(false);
            setMediaType(props.mediaType);
            setTitle(props.modalItem.title);
            setDescription(props.modalItem.description);
            setLink(props.modalItem.link);
            setFileId(props.modalItem.fileId);
            setFileName(undefined);
            setImageS3(props.modalItem.imageS3byte);
            setStatus(props.modalItem.checked);
        } else {
            setSubmitted(false);
            setMediaType(undefined);
            setTitle(undefined);
            setDescription(undefined);
            setLink(undefined);
            setFileId(undefined);
            setFileName(undefined);
            setImageS3(undefined);
            setStatus(undefined);
        }
    }, [props.show])
    const getRequire = (value: string | boolean | undefined | any[]) => {
        return (submitted && (value == null || value == undefined))
    }
    const getRequireValidate = (value: string | boolean | undefined | any[]) => {
        return ((value == null || value == undefined))
    }
    const submit = () => {
        setSubmitted(true);
        if (getRequireValidate(mediaType) || getRequireValidate(title) || getRequireValidate(imageS3) || getRequireValidate(status)) {
            alert({ message: "กรุณากรอกแบบฟอร์มให้ครบ", type: 'error' });
            return;
        } else {
            const item: ManageKnowledge | any = {
                fileId: fileId,
                title: title,
                description: description,
                imageS3byte: imageS3,
                link: link,
                checked: status
            }
            updateKnowLedList(item)

        }
    }
    const updateKnowLedList = (item: ManageKnowledge | any) => {
        if (props.mediaIndex != undefined) {
            let newItem = [...props.knowledgeList];
            switch (mediaType) {
                case MediaType.INFOGRAPHIC:
                    newItem[props.indexCat].subCategories[props.subCatIndex].infographics[props.mediaIndex] = { ...item }
                    return props.onOk(newItem);
                case MediaType.VIDEO:
                    newItem[props.indexCat].subCategories[props.subCatIndex].videos[props.mediaIndex] = { ...item }
                    return props.onOk(newItem);
                case MediaType.ARTICLE:
                    newItem[props.indexCat].subCategories[props.subCatIndex].articles[props.mediaIndex] = { ...item }
                    return props.onOk(newItem);
                case MediaType.ELEARNING:
                    newItem[props.indexCat].subCategories[props.subCatIndex].elearnings[props.mediaIndex] = { ...item }
                    return props.onOk(newItem);
                case MediaType.EBOOK:
                    newItem[props.indexCat].subCategories[props.subCatIndex].ebooks[props.mediaIndex] = { ...item }
                    return props.onOk(newItem);
                case MediaType.PUBLISHED_DOC:
                    newItem[props.indexCat].subCategories[props.subCatIndex].publishedDocuments[props.mediaIndex] = { ...item }
                    return props.onOk(newItem);
                case MediaType.INHOUSE:
                    newItem[props.indexCat].subCategories[props.subCatIndex].inHouses[props.mediaIndex] = { ...item }
                    return props.onOk(newItem);
                case MediaType.CALCULATOR:
                    newItem[props.indexCat].subCategories[props.subCatIndex].calculators[props.mediaIndex] = { ...item }
                    return props.onOk(newItem);
            }
        } else {
            let newItem = [...props.knowledgeList];
            switch (mediaType) {
                case MediaType.INFOGRAPHIC:
                    if (newItem[props.indexCat].subCategories[props.subCatIndex].infographics == null) {
                        newItem[props.indexCat].subCategories[props.subCatIndex].infographics = [];
                    }
                    newItem[props.indexCat].subCategories[props.subCatIndex].infographics.push(item)
                    return props.onOk(newItem);
                case MediaType.VIDEO:
                    if (newItem[props.indexCat].subCategories[props.subCatIndex].videos == null) {
                        newItem[props.indexCat].subCategories[props.subCatIndex].videos = [];
                    }
                    newItem[props.indexCat].subCategories[props.subCatIndex].videos.push(item)
                    return props.onOk(newItem);
                case MediaType.ARTICLE:
                    if (newItem[props.indexCat].subCategories[props.subCatIndex].articles == null) {
                        newItem[props.indexCat].subCategories[props.subCatIndex].articles = [];
                    }
                    newItem[props.indexCat].subCategories[props.subCatIndex].articles.push(item)
                    return props.onOk(newItem);
                case MediaType.ELEARNING:
                    if (newItem[props.indexCat].subCategories[props.subCatIndex].elearnings == null) {
                        newItem[props.indexCat].subCategories[props.subCatIndex].elearnings = [];
                    }
                    newItem[props.indexCat].subCategories[props.subCatIndex].elearnings.push(item)
                    return props.onOk(newItem);
                case MediaType.EBOOK:
                    if (newItem[props.indexCat].subCategories[props.subCatIndex].ebooks == null) {
                        newItem[props.indexCat].subCategories[props.subCatIndex].ebooks = [];
                    }
                    newItem[props.indexCat].subCategories[props.subCatIndex].ebooks.push(item)
                    return props.onOk(newItem);
                case MediaType.PUBLISHED_DOC:
                    if (newItem[props.indexCat].subCategories[props.subCatIndex].publishedDocuments == null) {
                        newItem[props.indexCat].subCategories[props.subCatIndex].publishedDocuments = [];
                    }
                    newItem[props.indexCat].subCategories[props.subCatIndex].publishedDocuments.push(item)
                    return props.onOk(newItem);
                case MediaType.INHOUSE:
                    if (newItem[props.indexCat].subCategories[props.subCatIndex].inHouses == null) {
                        newItem[props.indexCat].subCategories[props.subCatIndex].inHouses = [];
                    }
                    newItem[props.indexCat].subCategories[props.subCatIndex].inHouses.push(item)
                    return props.onOk(newItem);
                case MediaType.CALCULATOR:
                    if (newItem[props.indexCat].subCategories[props.subCatIndex].calculators == null) {
                        newItem[props.indexCat].subCategories[props.subCatIndex].calculators = [];
                    }
                    newItem[props.indexCat].subCategories[props.subCatIndex].calculators.push(item)
                    return props.onOk(newItem);
            }
        }
    }
    const getMediaType = () => {
        if (mediaType === null) return null;
        var d = createMediaType();
        for (var i = 0; i < d.length; i++) {
            if (d[i].value === mediaType) {
                return { label: d[i].label, value: mediaType };
            }
        }
        return null;
    }
    const handleChange = (e: any) => {
        if (e.target.files.length) {
            if (mediaType) {
                setFileName(e.target.files[0].name)
                dispatch(uploadFileKnowledge(mediaType, e.target.files[0]));
            } else {
                alert({ message: "กรุณากรอกประเภทสื่อ", type: 'error' });
            }

        }
    };
    const handleClick = (event: any) => {

        if (hiddenFileInput) {
            hiddenFileInput.current.click();
        }

    };


    return (
        <Modal
            {...props}
            dialogClassName="modal-60w"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    บริหารจัดการด้านความรู้ - จัดการหลักสูตรและสื่อความรู้
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='d-flex yellow-button' style={{ padding: "15px", borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}>
                    <label className='ml-2 my-auto' id='label-media'><span style={{ fontWeight: 600, marginRight: 20 }}>สื่อความรู้</span></label>
                </div>
                <div style={{ padding: "15px", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px", border: "3px solid #fab232" }}>
                    <div className='row mt-1'>
                        <label className='col-2' style={{ fontWeight: 600 }}>ประเภทความรู้</label>
                        <div className='d-flex flex-column col-10'>
                            <label id="label-knowledge-type">{copyKnowledgeList[props.indexCat]?.categoryName}</label>

                        </div>
                    </div>
                    <div className='row mt-1'>
                        <label className='col-2' style={{ fontWeight: 600 }}>หมวดหมู่ความรู้</label>
                        <div className='d-flex flex-column col-10'>
                            <label id="label-knowledge-type">{copyKnowledgeList[props.indexCat]?.subCategories[props.subCatIndex]?.subCategoryName}</label>

                        </div>
                    </div>
                </div>
                <br />
                <div style={{ position: 'relative', padding: "15px", borderRadius: "5px", border: "3px solid #dde1e5" }}>
                    <div className='row'>
                        <label className="col-2" style={{ fontWeight: 600 }}>ประเภทสื่อ <span className='text-danger'>*</span></label><br />
                        <div className='col-10'>
                            <SingleSearch isError={getRequire(mediaType)} id="input-dropdown-media-type" isSearchable={true} isMulti={false} placeholder=""
                                onChange={(id: number, e: any) => setMediaType(e.value)}
                                value={getMediaType()}
                                options={createMediaType()}
                                style={{ width: "100%", marginTop: "-5px" }}
                            />
                        </div>
                        <br />
                        <div className={(getRequire(mediaType)) ? "invalid-feedback" : ""}>{(getRequire(mediaType)) ? "กรุณากรอกประเภทสื่อความรู้" : ""}</div>
                    </div>
                    <div className='row mt-2'>
                        <label className="col-2" style={{ fontWeight: 600 }}>ชื่อสื่อความรู้ <span className='text-danger'>*</span></label><br />
                        <div className='col-10'>
                            <input type="text" id="input-text-title" className={'form-control ' + (getRequire(title) ? "is-invalid" : "")} value={title} onChange={(e: any) => setTitle(e.target.value)} />
                        </div>
                        <br />
                        <div className={(getRequire(title)) ? "invalid-feedback" : ""}>{(getRequire(title)) ? "กรุณากรอกชื่อสื่อความรู้" : ""}</div>
                    </div>
                    <div className='row mt-2'>
                        <label className="col-2" style={{ fontWeight: 600 }}>คำอธิบาย</label><br />
                        <div className='col-10'>
                            <textarea rows={2} id="input-text-description" className={'form-control'} value={description} onChange={(e: any) => setDescription(e.target.value)} />
                        </div>
                    </div>
                    <div className='row mt-2'>
                        <label className="col-2" style={{ fontWeight: 600 }}>Banner (480 x 270) <span className='text-danger'>*</span></label><br />
                        <div className='col-10'>
                            <input
                                type="file"
                                id="upload-button"
                                style={{ display: "none" }}
                                ref={hiddenFileInput}
                                onChange={handleChange}
                                onClick={(event: any) => {
                                    event.target.value = null
                                }}

                            />
                            <Button variant="primary" onClick={handleClick}>Upload</Button>
                            {imageS3 && <span className='ml-2'>{fileName}</span>}
                            {imageS3 && <div className='mt-2'><img src={"data:image/png;base64," + imageS3} width={390} height={190} /></div>}
                            <div className={(getRequire(imageS3)) ? "text-danger" : ""}>{(getRequire(imageS3)) ? "กรุณาอัพโหลด Banner" : ""}</div>
                        </div>
                    </div>
                    <div className='row mt-2'>
                        <label className="col-2" style={{ fontWeight: 600 }}>URL</label><br />
                        <div className='col-10'>
                            <input type='text' id="input-text-url" className={'form-control'} value={link} onChange={(e: any) => setLink(e.target.value)} />
                        </div>
                    </div>
                    <div className='row mt-2'>
                        <label className="col-2" style={{ fontWeight: 600 }}>สถานะ <span className='text-danger'>*</span></label><br />
                        <div className="col-2 mt-1">
                            <input id={"input-radio-active-true"} type="radio" checked={status === true} onChange={(e) => { setStatus(true) }} /> ใช้งาน
                        </div>
                        <div className="col-2 mt-1">
                            <input id={"input-radio-active-false"} type="radio" checked={status === false} onChange={(e) => { setStatus(false) }} /> ไม่ใช้งาน
                        </div>
                        <div className={(getRequire(status)) ? "text-danger" : ""}>{(getRequire(status)) ? "กรุณากรอก Status" : ""}</div>
                    </div>
                </div>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.onHide}>
                    {props.cancelButton || 'Close'}
                </Button>
                <Button variant="success" onClick={() => { submit() }}>
                    {props.okButton || 'Save'}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ManageKnowledgeMediaEdit