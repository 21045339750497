import { apiClient } from './axios-intercept';
import { DEFAULT_SERVER } from './conf';

const ETOOLKIT_URL =DEFAULT_SERVER +"/api/v1/etoolkit";

export const ETOOLKIT_ADDITIONAL_URL =DEFAULT_SERVER + "/api/v1/etoolkit/file/assignment";

export class EToolkitAPI {
        
    public static saveAssignment = async (documentId: number,item:any,studentEmail:string | null,saveByTrainer:boolean | null): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var queryParams: string[] = [];
            if (studentEmail) {
                queryParams.push(`studentEmail=${studentEmail}`)
            }
                queryParams.push(`saveByTrainer=${saveByTrainer}`)

            apiClient.post(ETOOLKIT_URL+"/assignment/"+documentId + '?'+queryParams.join('&'),
                //payload
                item
                ,{//options
                    headers: {
                        'Content-Type' : 'application/json'
                    }
                })
            .then((response) => {
                console.log("save etoolkit assignment response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("save etoolkit assignment error:", err);
                return reject(err); 
            })
        })
    }

    public static getAssignment = async (documentId: number,email: string | null,studentEmail: string | null): Promise<any> => {
        return new Promise(function (resolve, reject) {
            apiClient.get(ETOOLKIT_URL+"/assignment/"+documentId+(((email===null) || (email===""))?"":"?email="+encodeURIComponent(email)) + (((studentEmail===null) || (studentEmail===""))?"":"?studentEmail="+encodeURIComponent(studentEmail)) ,
                {//options
                    headers: {
                        'Content-Type' : 'application/json'
                    }
                })
            .then((response) => {
                console.log("get etoolkit assignment response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("get etoolkit assignment error:", err);
                return reject(err); 
            })
        })
    }

    public static uploadAdditional = async (documentId: string,file: FileList): Promise<any> => {
        return new Promise(function (resolve, reject) {
            console.log('uploadAdditional',file[0]);
            var formData = new FormData();
            formData.append("file", file[0]);
            var s=ETOOLKIT_ADDITIONAL_URL+"/"+documentId;
            apiClient.post(s,formData
                ,{//options
                    headers: {
                        "Accept": "application/json"
                    }
                })
            .then((response) => {
                console.log("upload additional response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("upload additional error:", err);
                return reject(err); 
            })
        })
    }
}