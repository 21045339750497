import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Util } from '../../api/util';
import { SurveyInfoResponse } from '../../model/response/survey.model';
import { listcoursewithname } from '../../store/result/actions';
import { queryclear } from '../../store/search/actions';
import { TableHeaderBlack } from '../../style/tableStyle';
import { CustomPage } from '../share/CustomPage';
import Sort from '../share/Sort';
import ClassTab from './classtab';
import MainMenu from './menu';
import { AlertContainer, alert } from 'react-custom-alert';
import 'react-custom-alert/dist/index.css'; // import css file from root.
import { getSummarySurvey } from '../../store/editSurvey/action';
import loadinglogo from '../../img/loading.gif';
import { Button } from 'react-bootstrap';
import excellogo from '../../img/excel.png';
import { SurveyAPI } from '../../api/survey-api';
import { getAccessToken } from '../../store/auth/selectors';
import { StudentType } from '../../api/define';
import { spacing } from 'react-select/dist/declarations/src/theme';
export const EditSurvey = () => {
    const store = useStore();
    const dispatch = useDispatch();
    const history = useHistory();
    const token = useSelector(getAccessToken);
    let { id } = useParams<{ id: string }>();
    const [init, setInit] = useState(0);
    const [initClass, setInitClass] = useState(0)
    const [loading, setLoading] = useState(false)
    const [updating, setUpdating] = useState(false)

    const [error, setError] = useState('');
    const [upload, setUpload] = useState(false);
    const [courseItem, setCourseItem] = useState<any>({})
    const [surveyItem, setSurveyItem] = useState<SurveyInfoResponse[]>([]);
    const [code, setCode] = useState<String>();
    const [page, setPage] = useState<number>(0);
    const [size, setSize] = useState<number>(10);
    const [sortBy, setSortBy] = useState<string | undefined>();
    const [sortType, setSortType] = useState<'asc' | 'desc'>('desc');
    const [totalElement, setTotalElwment] = useState<number>(0);
    const [showTabForStudentTypeList, setShowTabForStudentTypeList] = useState<boolean>(true);
    useEffect(() => {
        const unsubscribe = store.subscribe(() => {
            let res = store.getState().SearchReducer;
            if (res.isLoading) {
                setError('');
                setLoading(true);
            } else if ((res.isLoaded) && (!res.isUpdated)) {
                setError('');
                setLoading(false);
                if (res.data != null) {
                    if (res.table === "class_course") {
                        if (res.data.studentType && res.data.studentType === StudentType.NUMBER) {
                            setShowTabForStudentTypeList(false);
                        }
                        setCourseItem(res.data);
                        setInitClass(1);
                    } else if (res.table === "get_summary_survey") {
                        setSurveyItem(res.data.elements);
                        setTotalElwment(res.data.totalElements);
                        setCode(res.data.surveyPublicLinkPassword);
                        setInit(1);
                    }
                }
            } else if (res.isUpdating) {
                setError('');
                setUpdating(true);
            } else if (res.isUpdated) {
                setError('');
                setUpdating(false);
                setUpload(false);
                dispatch(queryclear());
                alert({ message: 'Saved', type: 'success' });
            } else if (res.isFailed) {
                if (res.data.success !== undefined) {
                    alert({ message: res.data.popup ?? 'Error', type: 'error' });
                    window.scrollTo(0, 0);
                }
                setError(res.data.message);
                setLoading(false);
                setUpdating(false);
                dispatch(queryclear());
            }
        })
        return unsubscribe;
    }, [])

    useEffect(() => {
        dispatch(queryclear());
        dispatch(listcoursewithname(parseInt(id)));
        dispatch(getSummarySurvey(id, page, size, undefined));
    }, [])
    const checkAssignment = () => {
        var courseList = (courseItem.courseList === undefined) ? [] : courseItem.courseList;
        for (var i = 0; i < courseList.length; i++) {
            var c = courseList[i];
            if ((c.isAssignment !== null) && (c.isAssignment !== undefined) && (c.isAssignment === true)) return true;
        }
        return false;
    }
    const handleClass = () => {
        history.push("/admin/class/" + id);
    }

    const handleStudent = () => {
        history.push("/admin/class/" + id + "/student");
    }

    const handleDoc = () => {
        history.push("/admin/class/" + id + "/doc");
    }

    const handleHistory = () => {
        history.push("/admin/class/" + id + "/history");
    }

    const handleResult = () => {
        history.push("/admin/class/" + id + "/result");
    }
    const handleAssignment = () => {
        history.push("/admin/class/" + id + "/assignment");
    }

    const handleResultNumber = () => {
        history.push("/admin/class/" + id + "/result-number");
    }

    const copyToClipboard = (text: string | undefined) => {
        if (text) {
            navigator.clipboard.writeText(text);
            alert({ message: 'คัดลอกข้อความสำเร็จ', type: 'success' });
        } else {
            alert({ message: 'คัดลอกข้อความไม่สำเร็จ', type: 'error' });
        }

    }

    const onSort = (sortName: string, newSortType: 'asc' | 'desc') => {
        setSortBy(sortName);
        setSortType(newSortType);
        dispatch(getSummarySurvey(id, page, size, sortName + ',' + newSortType));
    }
    const onPageChange = (value: number) => {
        setPage(value);
        let sort = sortBy ? sortBy + ',' + sortType : undefined;
        dispatch(getSummarySurvey(id, value, size, sort));
    }

    const onPageSizeChange = (value: number) => {
        setPage(0);
        setSize(value);
        let sort = sortBy ? sortBy + ',' + sortType : undefined;
        dispatch(getSummarySurvey(id, 0, value, sort));
    }
    const excelElement: any = () => {
        return <Button type="button" className="btn btn-success green-button ml-1" style={{ width: "50px" }}
            onClick={() => handleExcel()} >
            <img src={excellogo} height="26px" alt="excel" style={{ marginLeft: "-3px" }} />
        </Button>
    }
    const handleExcel = () => {
        window.open(SurveyAPI.getDownloadSummarySurvey(id, encodeURIComponent(token)));
    }

    const handleExcelResult = (classCourseId: number | undefined, isSurveyBefore: boolean | undefined,surveyGroupId: number | undefined) => {
        window.open(SurveyAPI.getDownloadSurveyResult(id, classCourseId, isSurveyBefore, encodeURIComponent(token),surveyGroupId));
    }

    return (
        <div><MainMenu>
            <div>
                <div className="query-wrapper">
                    <br />
                    <div className="yellow-button" style={{ textAlign: "left", borderTopLeftRadius: "30px", borderTopRightRadius: "30px", height: "70px", paddingTop: "15px", paddingLeft: "15px" }}><h4 className="header">รอบการเรียน</h4></div>
                    {(initClass === 1) &&
                        <>
                            <div className="light-gray-button" style={{ borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px', paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", textAlign: "left" }}>
                                <div className="row">
                                    <div className="col-4">
                                        <label className="col-form-label">โครงการ</label><br />
                                        <input className="form-control" type="text" value={courseItem.code + ":" + courseItem.name} readOnly={true} />
                                    </div>
                                    <div className="col-1">
                                        <label className="col-form-label">รอบ</label><br />
                                        <input className="form-control" type="text" value={courseItem.session} readOnly={true} />
                                    </div>
                                    <div className="col-2">
                                        <label className="col-form-label">วันที่เริ่ม</label>
                                        <input className="form-control" type="text" value={Util.datetostr(new Date(courseItem.fromDate))} readOnly={true} />
                                    </div>
                                    <div className="col-2">
                                        <label className="col-form-label">วันที่สิ้นสุด</label>
                                        <input className="form-control" type="text" value={Util.datetostr(new Date(courseItem.toDate))} readOnly={true} />
                                    </div>
                                    <div className="col-3">
                                        <label className="col-form-label">ผู้รับผิดชอบโครงการ</label><br />
                                        <input className="form-control" type="text" value={courseItem.organizer} readOnly={true} />
                                    </div>
                                </div>
                            </div>
                            <br />
                            {ClassTab(7, true, checkAssignment(), showTabForStudentTypeList, handleClass, handleStudent, handleDoc, handleAssignment, handleHistory, handleResult, null, handleResultNumber, !showTabForStudentTypeList)}
                            <div style={{ background: "white", padding: "40px", borderRadius: "5px" }}>
                                <div className='d-flex'>
                                    <label className='col-form-label'>Code: </label>
                                    <label className='col-form-label ml-1'>{code || '-'} </label>
                                </div>
                                <CustomPage current={page} pageSize={size} total={totalElement} onPageChange={onPageChange} onPageSizeChange={onPageSizeChange} loading={loading} postElement={excelElement()}>
                                    <TableHeaderBlack striped hover className='mt-2'>
                                        <thead>
                                            <tr>
                                                <th style={{ verticalAlign: "top" }} className='col-3 text-left'>
                                                    <Sort label='ประเภทแบบประเมินผล' currentSort={sortBy} sortName='typeDisplay' handleClick={onSort} sortType={sortType} />
                                                </th>
                                                <th style={{ verticalAlign: "top" }} className='col-2 text-left'>
                                                    <Sort label='ชื่อแบบประเมินผล' currentSort={sortBy} sortName='surveyNickname' handleClick={onSort} sortType={sortType} />
                                                </th>
                                                <th style={{ verticalAlign: "top" }} className='col-2 text-left'>
                                                    <Sort label='จำนวนผู้ตอบ' currentSort={sortBy} sortName='answerAmount' handleClick={onSort} sortType={sortType} />
                                                </th>
                                                <th style={{ verticalAlign: "top" }} className='col-2'>
                                                    สรุปผลแบบประเมิน
                                                </th>
                                                <th style={{ verticalAlign: "top" }} className='col-2'>
                                                    Public Link
                                                </th>
                                                <th style={{ verticalAlign: "top" }} className='col-1'>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {surveyItem && surveyItem.map((item: SurveyInfoResponse, index: number) => (
                                                <tr key={'survey_info' + index}>
                                                    {(item.step && <td className='text-left'>({item.step}) {item.typeDisplay}</td>)
                                                        || (!item.step && <td className='text-left'>{item.typeDisplay}</td>)
                                                    }
                                                    <td className='text-left'>{item.surveyNickname}</td>
                                                    <td className='text-left'>{item.answerAmount}</td>
                                                    <td >
                                                        {item.powerBiUrlResult && (
                                                            <a className='link' href={item.powerBiUrlResult} target='_blank'>ดูผล</a>
                                                        )}
                                                        {!item.powerBiUrlResult && (<span>-</span>)}</td>
                                                    <td><a className='link' onClick={() => copyToClipboard(item.publicLink)}>คัดลอก</a></td>
                                                    <td><a className='link' onClick={() => handleExcelResult(item.classCourseId, item.surveyBefore,item.surveyGroupId)}><img src={excellogo} height="26px" alt="excel" style={{ marginLeft: "-3px" }} /></a></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </TableHeaderBlack>
                                </CustomPage>
                            </div>
                            {(((init + initClass < 2) || (loading)) && (error === "")) &&
                                <div style={{ textAlign: "center" }}><img src={loadinglogo} width="30" height="30" className="d-inline-block align-top" alt="" /></div>
                            }
                        </>
                    }

                </div>
            </div>
        </MainMenu>
            <AlertContainer floatingTime={3000} />
        </div>
    )
}