import { colors } from "@material-ui/core"
import ExpandLess from "@material-ui/icons/ExpandLess"
import ExpandMore from "@material-ui/icons/ExpandMore"
import { useState } from "react"
import { CustomChip } from "../../../../style/partnerStylemain"

type Props = {}

const LogRequestSuggestionHistory = (props: Props) => {
    const [expand, setExpand] = useState(true)
    return (
        <div>
            <div className="gray-button text-left" style={{ padding: "15px", borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}>
                {((expand === undefined) || (expand === false)) &&
                    <div onClick={(e) => setExpand(true)}><ExpandMore className="pointer mr-2" ></ExpandMore>คำอธิบายการใช้งาน</div>
                }
                {(expand === true) &&
                    <div onClick={(e) => setExpand(false)}><ExpandLess className="pointer mr-2" ></ExpandLess>คำอธิบายการใช้งาน</div>
                }
            </div>
            {expand && (
                <div className="bg-white text-left" style={{ padding: "15px", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px" }}>
                    <ul>
                        <li>หน้าจอประวัติรายการ: ท่านสามารถสืบค้นประวัติรายการ และ Export File รายการไปได้</li>
                        <li>Email ที่ส่งออกจากระบบจะใช้ SET_Learnscape_no_reply@set.or.th เท่านั้น</li>
                        <li>หากพบปัญหาจากการใช้งานระบบ กรุณาติดต่อฝ่ายงาน Investor Education</li>
                        <li>สถานะรายการมี 5 สถานะ
                            <CustomChip background="#D5F0F6" color="#2878CC">รายการใหม่</CustomChip>
                            <CustomChip background="#FFFBE5" color="#E97331">ส่งฟอร์มให้กรอกข้อมูล</CustomChip>
                            <CustomChip background="#E0DEED" color="#59529D">อยู่ระหว่างดำเนินการ</CustomChip>
                            <CustomChip background="#FAFBEA" color="#4A8B41">บันทึกลงฐานข้อมูล</CustomChip>
                            <CustomChip background="#F6D5E2" color="#CA494A">องค์กรยังไม่สนใจ</CustomChip>
                        </li>
                        <li>ชื่อย่อฝ่ายงานที่รับผิดชอบ
                            <span style={{ margin: "0px 5px 0px 10px", padding: "1px 12px 1px 12px", borderRadius: "5px",border:"1px solid #2878CC", background: "#D5F0F6", fontSize: "17px", color: "#2878CC" }}>ENT</span>Enterprise Education
                            <span style={{ margin: "0px 5px 0px 10px", padding: "1px 12px 1px 12px", borderRadius: "5px",border:"1px solid #2878CC", background: "#D5F0F6", fontSize: "17px", color: "#2878CC" }}>INV</span>Investor Education
                            <span style={{ margin: "0px 5px 0px 10px", padding: "1px 12px 1px 12px", borderRadius: "5px",border:"1px solid #2878CC", background: "#D5F0F6", fontSize: "17px", color: "#2878CC" }}>PRO</span>Professional Education
                            <span style={{ margin: "0px 5px 0px 10px", padding: "1px 12px 1px 12px", borderRadius: "5px",border:"1px solid #2878CC", background: "#D5F0F6", fontSize: "17px", color: "#2878CC" }}>PUB</span>Public Education
                        </li>
                    </ul>
                </div>
            )}

        </div>
    )
}

export default LogRequestSuggestionHistory