import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux';
import { getAccessToken, getRefreshToken } from '../../../store/auth/selectors';
import StudentHeader from '../studentheader';
import loadinglogo from '../../../img/loading.gif';
import openBookLogo from '../../../img/openBook.png';
import clockLogo from '../../../img/Clock.png';
import warrantyLogo from '../../../img/Warranty.png';
import StudentCourseList from './studentcourseList';
import { useParams } from 'react-router-dom';
import { CustomPage } from '../../share/CustomPage';
import { StudentAssignmentShortResponse } from '../../../model/response/student-course';
import { queryclear } from '../../../store/search/actions';
import { getCourseList } from '../../../store/studentcourse/action';

type Props = {}

const StudentCoursePagination = (props: Props) => {
    const token = useSelector(getAccessToken);
    const sessionToken = useSelector(getRefreshToken);
    const [init, setInit] = useState(0)
    const [loading, setLoading] = useState(false);
    const [assignment, setAssignment] = useState<StudentAssignmentShortResponse[]>();
    const [error, setError] = useState('');
    const store = useStore();
    const dispatch = useDispatch();
    const [page, setPage] = useState<number>(0);
    const [size, setSize] = useState<number>(9999999);
    const [totalElement, setTotalElement] = useState<number>(0);
    let { assignmentType } = useParams<{ assignmentType: 'process' | 'coming' | 'completed' }>();
    useEffect(() => {
        const unsubscribe = store.subscribe(() => {
            let res = store.getState().SearchReducer;
            if (res.isLoading) {
                setError('');
                setLoading(true);
            } else if ((res.isLoaded) && (!res.isUpdated)) {
                setError('');
                setLoading(false);
                if (res.data != null) {
                    setAssignment(res.data.elements);
                    setTotalElement(res.data.totalElements)
                    setInit(1);
                }
            } else if (res.isFailed) {
                alert({ message: res.data.message, type: 'error' })
                setLoading(false);
            }
        })
        return unsubscribe;
    }, []);
    useEffect(() => {
        dispatch(queryclear());
        dispatch(getCourseList(assignmentType, page, undefined, undefined));
    }, [])
    const onPageChange = (value: number) => {
        setPage(value);
        dispatch(getCourseList(assignmentType, value, size, undefined));
    }
    const onPageSizeChange = (value: number) => {
        setPage(0);
        setSize(value);
        dispatch(getCourseList(assignmentType, 0, value, undefined));
    }
    return (
        <div>
            <div className="App-student">
                <div className="student-wrapper">
                    <div className="student-wrapper-header-inner" style={{ background: "#38383D" }}>
                        <StudentHeader />
                        {(((init === 0) || (loading)) && (error === "")) &&
                            <div style={{ textAlign: "center" }}><img src={loadinglogo} width="30" height="30" className="d-inline-block align-top" alt="" /></div>
                        }
                        {(error !== "") &&
                            <div style={{ color: 'red', textAlign: "center" }}>{error}</div>
                        }
                    </div>
                    {(init === 1) &&
                        <>
                            <div className="student-wrapper-inner" style={{ background: "white", minHeight: '92vh' }}>
                                {assignmentType === 'process' && (
                                    <div className='pt-4 text-left px-4'>
                                        <div className='mb-2'> <img src={openBookLogo} width="25" className="d-inline-block align-top" /><span className='text-title ml-2' >โครงการที่เปิดให้เข้าเรียน</span></div>
                                        <CustomPage current={page} pageSize={size} total={totalElement} onPageChange={onPageChange} onPageSizeChange={onPageSizeChange} loading={loading}>
                                            <StudentCourseList item={assignment} />
                                        </CustomPage>
                                    </div>
                                )}
                                {assignmentType === 'coming' && (
                                    <div className='pt-4 text-left px-4'>
                                        <div className='mb-2'>
                                            <img src={clockLogo} width="25" className="d-inline-block align-top" /><span className='text-title ml-2' >โครการที่กำลังมาถึง</span>
                                        </div>
                                        <CustomPage current={page} pageSize={size} total={totalElement} onPageChange={onPageChange} onPageSizeChange={onPageSizeChange} loading={loading}>
                                            <StudentCourseList item={assignment} />
                                        </CustomPage>
                                    </div>
                                )}
                                {assignmentType === 'completed' && (
                                    <div className='pt-4 text-left px-4'>
                                        <div className='mb-2'><img src={warrantyLogo} width="25" className="d-inline-block align-top" /><span className='text-title ml-2' >โครงการที่เรียนผ่านไปแล้ว</span></div>
                                        <CustomPage current={page} pageSize={size} total={totalElement} onPageChange={onPageChange} onPageSizeChange={onPageSizeChange} loading={loading}>
                                            <StudentCourseList item={assignment} />
                                        </CustomPage>
                                    </div>
                                )}
                                <br />
                            </div>
                            
                        </>}
                </div>

            </div>
        </div>
    )
}

export default StudentCoursePagination