import { useEffect, useState } from 'react';
import { useDispatch, useStore } from 'react-redux';
import loadinglogo from '../../img/loading.gif';
import { useLocation, useHistory } from "react-router-dom";
import { studentGetToken } from '../../store/auth/actions';
import { SET_MEMBER_SERVER } from '../../api/conf';

const StudentGetToken = () => {
    const search = useLocation().search;
    const token = new URLSearchParams(search).get('token');
    const redirectUrl = new URLSearchParams(search).get('redirectUrl');
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState("")
    const dispatch = useDispatch();
    const store = useStore();
    const history = useHistory();

    useEffect(() => {
        const unsubscribe = store.subscribe(() => {
            let res = store.getState().AuthReducer;
            if (res.isLoading) {
                setError('');
                setLoading(true);
            } else if (res.isLoggedIn) {
                setError('');
                setLoading(false);
                console.log(res);
                if(sessionStorage.getItem('redirectPath')){
                    let path = sessionStorage.getItem('redirectPath');
                    sessionStorage.removeItem('redirectPath');
                    if(path) history.push(path);
                }else{
                    history.push(res.token.redirect);
                } 
            } else if (res.isFailed) {
                setError("Network error");
                // window.location.href=SET_MEMBER_SERVER;
            }
          });

        dispatch(studentGetToken(token!));
        return unsubscribe;
        }, 
    []);
    

    return <>
      { ((loading) && (error==="")) &&
        <div style={{ textAlign: "center",  display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        width: "50%" }}><img src={loadinglogo} width="30" height="30" className="d-inline-block align-top" alt="" /></div>
      }
      { (error!=="") &&
            <div style={{ color: 'red', textAlign: "center" }}>{error}</div>
      }
    </>;
}

export default StudentGetToken;