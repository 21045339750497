import React from 'react'
import styled from 'styled-components'
import { PButton, PButtonBack } from '../../style/partnerStylemain'
import { useHistory } from 'react-router-dom'

type Props = {}
const FooterContainer = styled.div`
    display: block;
    position: sticky;
    color: white;
    background-color: #38383D;
    padding: 10px 60px 10px 60px;
    .link-new{
        color:white;
        text-decoration: underline;
        cursor: pointer;
        &:hover{
            color: #fed757
        }
    }
`
const PartnerFooter = (props: Props) => {
    const history = useHistory();
    const gotoInterestForm = () => {
        history.push('/partner/regist-form');
    }
    const goBack = () => {
        history.push('/partner/choose-topic?back=true');
    }
    const goNew = () => {
        history.push('/partner');
    }
    return (
        <div>
            <FooterContainer>
                <div className='d-flex justify-content-center'>
                    <div className='my-auto mr-2' style={{fontSize:'20px'}}>หากท่านสนใจหลักสูตรที่แนะนำ ท่านสามารคลิ๊กปุ่ม <span style={{color:'#fed757'}}>“แจ้งความสนใจ”</span> เพื่อกรอกข้อมูลส่วนตัว
                    </div>
                    <div className='mr-2'><PButton id="button-interest" onClick={gotoInterestForm}>แจ้งความสนใจ</PButton></div>
                    <div className='mr-2'><PButtonBack id="button-back" onClick={goBack}>ย้อนกลับ</PButtonBack></div>
                    <div className='my-auto'><a className='link-new'  id="link-new-survey" style={{fontSize:'20px'}} onClick={goNew}>ทำแบบสอบถามใหม่</a></div>
                </div>
            </FooterContainer>
        </div>
    )
}

export default PartnerFooter