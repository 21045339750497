import { AnnouncementManageRequest } from "../model/request/announce.model";
import { apiClient } from "./axios-intercept";
import { DEFAULT_SERVER } from "./conf";

const SEARCH_ANNOUNCE_URL = DEFAULT_SERVER + '/api/v1/announcement';
const SEARCH_PUBLISH_ANNOUNCE_URL = DEFAULT_SERVER + '/api/v1/announcement/publish-list';
const CREATE_ANNOUNCE_URL = DEFAULT_SERVER + '/api/v1/announcement/create';
const UPDATE_ANNOUNCE_URL = DEFAULT_SERVER + '/api/v1/announcement/update';
const DELETE_ANNOUNCE_URL = DEFAULT_SERVER + '/api/v1/announcement/delete'
const UPDATE_PUBLISH_ANNOUNCE_URL = DEFAULT_SERVER+ '/api/v1/announcement/publish';
const UPDATE_UNPUBLISH_ANNOUNCE_URL = DEFAULT_SERVER+ '/api/v1/announcement/unpublish';
const UPDATE_PRIORITY_ANNOUNCE_URL = DEFAULT_SERVER+ '/api/v1/announcement/priority';
export class AnnouncementAPI {
    public static createAnnouncement = async (request: AnnouncementManageRequest): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var formData = new FormData();
            if (request.file !== undefined) formData.append("file", request.file);
            if (request.announcementRequest !== undefined) {
                formData.append("announcementRequest", new Blob([JSON.stringify(request.announcementRequest)], { type: 'application/json' }));
            }


            var s = CREATE_ANNOUNCE_URL;
            apiClient.post(s, formData
                , {//options
                    headers: {
                        "Content-Type": "multipart/form-data; charset=utf-8",
                        'Accept': 'application/json'
                    }
                })
                .then((response) => {
                    return resolve(response);
                }).catch(err => {
                    console.log("create announce error:", err);
                    return reject(err);
                })
        })
    }
    public static updatePublish = async (id: number, section: string | undefined): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var queryParams: string[] = [];
            if (id) {
                queryParams.push(`id=${id}`)
            }
            if (section) {
                queryParams.push(`section=${section}`)
            }
           
            var s = UPDATE_PUBLISH_ANNOUNCE_URL;
            apiClient.put(s + '?' + queryParams.join('&')
                , {//options
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    return resolve(response);
                }).catch(err => {
                    console.log("create announce error:", err);
                    return reject(err);
                })
        })
    }
    public static updateUnPublish = async (id: number, section: string | undefined): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var queryParams: string[] = [];
            if (id) {
                queryParams.push(`id=${id}`)
            }
            if (section) {
                queryParams.push(`section=${section}`)
            }
           
            var s = UPDATE_UNPUBLISH_ANNOUNCE_URL;
            apiClient.put(s + '?' + queryParams.join('&')
                , {//options
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    return resolve(response);
                }).catch(err => {
                    console.log("create announce error:", err);
                    return reject(err);
                })
        })
    }

    public static updatePriority = async (id: number, section: string | undefined,priority: number): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var queryParams: string[] = [];
            if (id) {
                queryParams.push(`id=${id}`)
            }
            if (section) {
                queryParams.push(`section=${section}`)
            }
            queryParams.push(`priority=${priority}`)
            var s = UPDATE_PRIORITY_ANNOUNCE_URL;
            apiClient.put(s + '?' + queryParams.join('&')
                , {//options
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    return resolve(response);
                }).catch(err => {
                    console.log("create announce error:", err);
                    return reject(err);
                })
        })
    }
    public static updateAnnouncement = async (request: AnnouncementManageRequest): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var formData = new FormData();
            if (request.file !== undefined) formData.append("file", request.file);
            if (request.announcementRequest !== undefined) {
                formData.append("announcementRequest", new Blob([JSON.stringify(request.announcementRequest)], { type: 'application/json' }));
            }


            var s = UPDATE_ANNOUNCE_URL;
            apiClient.put(s, formData
                , {//options
                    headers: {
                        "Content-Type": "multipart/form-data; charset=utf-8",
                        'Accept': 'application/json'
                    }
                })
                .then((response) => {
                    return resolve(response);
                }).catch(err => {
                    console.log("update announce error:", err);
                    return reject(err);
                })
        })
    }

    public static deleteAnnouncement = async (section: string,id:number): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = DELETE_ANNOUNCE_URL;
            var queryParams: string[] = [];
            if (section) {
                queryParams.push(`section=${section}`)
            }
            if (id) {
                queryParams.push(`id=${id}`)
            }
            apiClient.delete(s+ '?' + queryParams.join('&')
                , {//options
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    return resolve(response);
                }).catch(err => {
                    console.log("delete announce error:", err);
                    return reject(err);
                })
        })
    }
    public static listAnnouncement = async (keyword: string | undefined, section: string | undefined, projectGroup: string | undefined, page: number, size: number, sort: string | undefined): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = SEARCH_ANNOUNCE_URL;
            var queryParams: string[] = [];
            if (keyword) {
                queryParams.push(`keyword=${keyword}`)
            }
            if (section) {
                queryParams.push(`section=${section}`)
            }
            if (projectGroup) {
                queryParams.push(`projectGroupId=${projectGroup}`)
            }
            queryParams.push(`page=${page}`);
            queryParams.push(`size=${size}`);
            if (sort) {
                queryParams.push(`sort=${sort}`)
            }
            apiClient.get(s + '?' + queryParams.join('&'),
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    console.log("list course for assignment response : ", response);
                    return resolve(response);
                }).catch(err => {
                    console.log("list course for assignment error:", err);
                    return reject(err);
                })
        })
    }

    public static listPublishAnnouncement = async (keyword: string | undefined, section: string | undefined, projectGroup: string | undefined): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = SEARCH_PUBLISH_ANNOUNCE_URL;
            var queryParams: string[] = [];
            if (keyword) {
                queryParams.push(`keyword=${keyword}`)
            }
            if (section) {
                queryParams.push(`section=${section}`)
            }
            if (projectGroup) {
                queryParams.push(`projectGroupId=${projectGroup}`)
            }
            apiClient.get(s+ '?' + queryParams.join('&'),
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    console.log("list course for assignment response : ", response);
                    return resolve(response);
                }).catch(err => {
                    console.log("list course for assignment error:", err);
                    return reject(err);
                })
        })
    }
}
