import { Dispatch } from 'redux';
import { ActionType, SearchActionType } from '../search/types';
import { DocAPI } from '../../api/doc-api';
import { ClassAPI } from '../../api/class-api';
import { dispatchError } from '../search/actions'; 
import { DocumentType } from '../../api/define';
import { ZtestAPI } from '../../api/z-test-api';

export const listcoursewithname = (id: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING,
        page: "edit_doc"
    });
    ClassAPI.getClassCourseWithName(id).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "list_course",
            page: "edit_doc",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("edit_doc",dispatch,error);
    })
};

export const listdoc = (classId: number,classCourseId: number, page: number,limit: number) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    DocAPI.list(classId,classCourseId,page,limit).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "doc",
            page: "edit_doc",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("edit_doc",dispatch,error);
    })
};

export const adddoc = (classId:string,classCourseId: string,caption: string,url: string,description: string,docType: DocumentType,file: FileList | undefined) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_UPDATING
    });
    DocAPI.add(classId,classCourseId,caption,url,description,docType,file).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_UPDATED,
            payload: response.data,
            table: "doc",
            page: "edit_doc",
            action: ActionType.UPDATE
        });
    }, function (error) {
       dispatchError("edit_doc",dispatch,error);
    })
};

export const updatedoc = (classId:string,classCourseId: string,id: string,caption: string,url: string,description: string,docType: DocumentType,file: FileList | undefined) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_UPDATING
    });
    DocAPI.update(classId,classCourseId,parseInt(id),caption,url,description,docType,file).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_UPDATED,
            payload: response.data,
            table: "doc",
            page: "edit_doc",
            action: ActionType.UPDATE
        });
    }, function (error) {
       dispatchError("edit_doc",dispatch,error);
    })
};


export const deletedoc = (id: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_UPDATING
    });
    DocAPI.delete(id).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_UPDATED,
            payload: response.data,
            table: "doc",
            page: "edit_doc",
            action: ActionType.DELETE
        });
    }, function (error) {
       dispatchError("doc",dispatch,error);
    })
};

export const uploadBanner = (file: File) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    ZtestAPI.uploadBanner(file).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "upload_banner",
            page: "edit_doc",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("upload_banner",dispatch,error);
    })
};

export const uploadAnnounce = (file: File,description: string,topic: string,createBy: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    ZtestAPI.uploadAnnounce(file,description,topic,createBy).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "upload_announce",
            page: "edit_doc",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("upload_announce",dispatch,error);
    })
};

/*
if ((error.response!==undefined) && (error.response.status===400)) {
            dispatch({
                type: SearchActionType.QUERY_ERROR,
                page: "edit_class",
                payload: error.response.data.additional
            });
        } else 
        */
