import React from 'react'
import logoSetLearnScape from "../../img/Logo-SET-Learn-Scape.png"

type Props = {}

const PartnerHeader = (props: Props) => {
    return (
        <div>
            <div className="row">
                <div className="col-2 text-left">
                    <img src={logoSetLearnScape} className="d-inline-block align-top" alt="" style={{ cursor: "pointer", height: 50 }} />
                </div>
            </div>
        </div>
    )
}

export default PartnerHeader