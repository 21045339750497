import React, { useState } from 'react'
import { BranchInfo, Faculty, PartnerProfileListResponse, Transition } from '../../../../model/response/partner-profile';
import ExpandMore from '@material-ui/icons/ExpandMore'
import ExpandLess from '@material-ui/icons/ExpandLess'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { SingleSearch, customStateStyles, greenStateStyles } from '../../../tab/search-component';
import { createListedComapny, createSaveStateNoNew } from '../../../../api/define';
import Select, { SingleValue } from 'react-select';
import { Tooltip } from 'antd';
import PartnerGeneralInfoAdress from '../../managePartner/component/PartnerGeneralInfoAdress';
import { InputGroup } from 'react-bootstrap';
import { EmployeeResponse } from '../../../../model/response/hr.model';
import CloseIcon from '@material-ui/icons/Close';
import { AlertContainer, alert } from 'react-custom-alert';

type Props = {
    parnerProfileList: PartnerProfileListResponse;
    success: boolean;
    submitted: boolean;
    orgTypeItem: any[];
    province: any[];
    setParnerProfileListChange: (parnerProfileList: PartnerProfileListResponse) => void;
    readonly: boolean;
    state?: string;
    submittedMerge?: boolean;
    hideDropdown?: boolean;
    nextAction?: number;
    nextActionItem?: Transition[];
    isDisableOrganizeGroup: boolean;
    adminItem: EmployeeResponse[];
}

const PartnerGeneralInfoPartner = (props: Props) => {
    const [searchAdmin, setSearchAdmin] = useState<SingleValue<{ label: string, value: number }>>()
    const [expand, setExpand] = useState(true);
    const [tabColor, setTabColor] = useState(props.state ? props.state == 'old' ? 'gray-button' : 'yellow-button' : 'yellow-button');
    const [borderColor, setBorderColor] = useState(props.state ? props.state == 'old' ? '#dddddd' : '#fab232' : '#fab232')
    const getRequire = (value: string | boolean | undefined | any[] | null | number) => {
        let moveTransition;
        if (props.nextActionItem) {
            moveTransition = props?.nextActionItem.find(x => x.id == props.nextAction);
        }
        let isSendFormToCustomer = moveTransition?.transition == 'ส่งฟอร์มให้กรอกข้อมูล'
        if (isSendFormToCustomer) {
            return false;
        } else {
            return (props.submitted && (value == null || value == undefined || value == ''))
        }

    }
    const getRequireOrgGroup = (value: string | boolean | undefined | any[] | null | number) => {
        return (props.submitted && (value == null || value == undefined || value == ''))
    }
    const getRequireAdmin = (value:  any[]) => {
        let error;
        if (!value || value.length <= 0) {
            error = true
        }
        return props.submitted && error
    }


    const setOrgType = (e: number) => {
        var newItem = { ...props.parnerProfileList };
        newItem.orgType = e;
        for (var i = 0; i < props.orgTypeItem.length; i++)
            if (props.orgTypeItem[i].id === e) {
                if ((props.orgTypeItem[i].orgGroup !== undefined) && (props.orgTypeItem[i].orgGroup.length > 0)) {
                    newItem.orgGroup = props.orgTypeItem[i].orgGroup[0].id;
                    clearFaculty(e, props.orgTypeItem[i].orgGroup[0].id, newItem);
                }
            }
        props.setParnerProfileListChange(newItem);
    }
    const createOrgTypeSearchFilter = () => {
        var res: any[] = [];
        for (var i = 0; i < props.orgTypeItem.length; i++) {
            res.push({ label: props.orgTypeItem[i].name, value: props.orgTypeItem[i].id });
        }
        return res;
    }
    const getOrgType = () => {
        for (var i = 0; i < props.orgTypeItem.length; i++)
            if (props.orgTypeItem[i].id === props.parnerProfileList?.orgType) {
                return { label: props.orgTypeItem[i].name, value: props.parnerProfileList?.orgType };
            }
        return null;
    }
    const setOrgGroup = (e: number) => {
        var newItem = { ...props.parnerProfileList };
        newItem.orgGroup = e;
        clearFaculty(newItem.orgType, e, newItem);
        props.setParnerProfileListChange(newItem);
    }
    const clearFaculty = (orgType: number, orgGroup: number, newItem: PartnerProfileListResponse) => {
        newItem.personnel = null;
        newItem.governmentOfficer = null;
        newItem.governmentEmployee = null;
        newItem.employee = null;
        newItem.memberUnderSupervision = null;
        newItem.teacherNo = null;
        newItem.studentNo = null;
        if (getOrgTypeName(orgType) == 'สถาบันการศึกษา') {
            if (getOrgGroupName(orgGroup) == 'มหาวิทยาลัย' || getOrgGroupName(orgGroup) == 'วิทยาลัย') {
                newItem.faculty = []
            } else if (getOrgGroupName(orgGroup) == 'อาชีวศึกษา') {
                newItem.faculty = [];
                let newFaculty: Faculty = {
                    facultyInfo: [{
                        id: null,
                        facultyName: null,
                        facultyDisplayName: null,
                        teacherNo: null,
                        personnel: null,
                        studentNo: null,
                        otherFacultyName: null,
                        relatedFinancial: false
                    }],
                    noBranch: false,
                    branchInfo: []
                }
                newItem.faculty.push(newFaculty)
            } else {
                newItem.faculty = []
            }
        } else {
            newItem.faculty = [];
        }
    }
    const getOrgTypeName = (id: any) => {
        if (props.orgTypeItem) {
            let orgTypeName = props.orgTypeItem.find(x => x.id == id)?.name;
            return orgTypeName ? orgTypeName : null
        }
        return null

    }
    const getOrgGroupName = (id: any) => {
        let orgGroup = null
        if (props.orgTypeItem) {

            props.orgTypeItem.forEach(x => {
                let orgGroupName = x.orgGroup.find((y: any) => y.id == id);
                if (orgGroupName) {
                    orgGroup = orgGroupName.name
                }
            })

        }
        return orgGroup
    }
    const getOrgGroup = () => {
        for (var i = 0; i < props.orgTypeItem.length; i++) {
            if (props.orgTypeItem[i].id === props.parnerProfileList?.orgType) {
                for (var j = 0; j < props.orgTypeItem[i].orgGroup.length; j++) {
                    var gitem = props.orgTypeItem[i].orgGroup[j];
                    if (gitem.id === props.parnerProfileList?.orgGroup) {
                        return { label: gitem.name, value: gitem.id };
                    }
                }
            }
        }
        return null;
    }
    const setListedCompany = (e: any) => {
        var newItem = { ...props.parnerProfileList };
        newItem.listedCompanyType = e;
        props.setParnerProfileListChange(newItem);
    }
    const createOrgGroupSearchFilter = () => {
        var res: any[] = [];
        for (var i = 0; i < props.orgTypeItem.length; i++) {
            if (props.orgTypeItem[i].id === props.parnerProfileList?.orgType) {
                for (var j = 0; j < props.orgTypeItem[i].orgGroup.length; j++) {
                    var gitem = props.orgTypeItem[i].orgGroup[j];
                    res.push({ label: gitem.name, value: gitem.id });
                }
            }
        }
        return res;
    }
    const getListedCompany = () => {
        var d = createListedComapny();
        for (var i = 0; i < d.length; i++) {
            if (d[i].value === props.parnerProfileList?.listedCompanyType) {
                return { label: d[i].label, value: props.parnerProfileList?.listedCompanyType };
            }
        }
        return null
    }
    const setPartnerShortname = (e: any) => {
        var newItem = { ...props.parnerProfileList };
        newItem.partnerShortname = e;
        props.setParnerProfileListChange(newItem);
    }
    const setOrgName = (e: any) => {
        var newItem = { ...props.parnerProfileList };
        newItem.orgName = e;
        props.setParnerProfileListChange(newItem);
    }
    const getDescription = () => {
        return <p>บริษัทจดทะเบียน เป็นบริษัทมหาชนจำกัดที่ได้รับอนุมัติจากสำนักงานคณะกรรมการกำกับหลักทรัพย์และ
            ตลาดหลักทรัพย์ (ก.ล.ต.) ให้สามารถออกและเสนอขายหลักทรัพย์ต่อประชาชน และได้รับอนุมัติจากตลาดหลักทรัพย์แห่งประเทศไทยให้เข้าเป็นบริษัทจดทะเบียนใน SET และ mai ตามขนาดของทุนจดทะเบียนชำระแล้ว และคุณสมบัติอื่นๆ
            ที่ตลาดหลักทรัพย์ฯ กำหนด</p>
    }
    const handleAddAdmin = (e: any) => {
        if ((e === undefined) || (e?.value === 0)) return;
        if (props.parnerProfileList.admin && props.parnerProfileList.admin.some((x: any) => x.employeeId == e?.value)) {
            alert({ message: 'ผู้ดูแลองค์กรซ้ำโปรดเลือกใหม่', type: 'error' });
            return;
        }
        setSearchAdmin({ label: "", value: 0 });
        var newItem = { ...props.parnerProfileList };
        if ((newItem.admin == null) || (newItem.admin === undefined)) newItem.admin = [];
        var dup = false;
        for (var i = 0; i < newItem.admin.length; i++) {
            if (newItem.admin[i].employeeId === e?.value) dup = true;
        }
        if (!dup) newItem.admin.push({ employeeId: e?.value, name: e?.label })
        props.setParnerProfileListChange(newItem);
    }
    const createAdminSearchFilter = (e: string) => {
        var res: any[] = [];
        for (var i = 0; i < props.adminItem.length; i++) {
            res.push({ label: props.adminItem[i].name, value: props.adminItem[i].id });
        }
        return res;
    }
    const getAdmin = (id: number) => {
        if ((props.parnerProfileList.admin === undefined) || (props.parnerProfileList.admin[id] === undefined)) return null;
        var v: string = props.parnerProfileList.admin[id].employeeId;
        for (var i = 0; i < props.adminItem.length; i++) {
            if (props.adminItem[i].id == v)
                return { label: props.adminItem[i].name, value: v };
        }
        return { label: v, value: v };
    }
    const handleDeleteAdmin = (t: number) => {
        var newItem = { ...props.parnerProfileList };
        if (newItem.admin == null) return;
        //correct id
        var newAdmin: any[] = [];
        var oldAdmin = newItem.admin;
        var k = 0;
        for (var i = 0; i < oldAdmin.length; i++) {
            if (i < t) { newAdmin.push({ ...oldAdmin[i] }); k++; }
            else if (i > t) {
                var c = { ...oldAdmin[i] };
                newAdmin.push(c);
                k++;
            }
        }
        newItem.admin = newAdmin;
        props.setParnerProfileListChange(newItem);
    }
    const createAdmin = () => {
        var res = [];
        if(props.parnerProfileList){
            if (!props.parnerProfileList?.admin || props.parnerProfileList?.admin === undefined) props.parnerProfileList.admin = [];
            for (var i = 0; i < props.parnerProfileList.admin.length; i++) {
                res.push(<>
    
                    <div className="col-3 px-1" style={{ position: "relative" }}>
                        <InputGroup hasValidation>
                            <input type="text" className="form-control blue-input" value={getAdmin(i)?.label} readOnly={true} /><br />
                        </InputGroup>
    
                        {(!props.success) &&
                            <div id={i.toString()} style={{ position: "absolute", right: "20px", top: "5px",cursor:"pointer" }} onClick={(e) => { handleDeleteAdmin(parseInt(e.currentTarget.id.split("_")[0])) }}>
                                <CloseIcon className="white"></CloseIcon>
                            </div>
                        }
                        <div className='mb-1'></div>
                    </div>
                </>);
            }
        }
        
        return res;
    }
    return (
        <React.Fragment>
            <div className="text-left" style={{ position: "relative", marginBottom: 5 }}>
                <div className={'d-flex ' + tabColor} style={{ padding: "15px", borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}>
                    {((expand === undefined) || (expand === false)) &&
                        <div onClick={(e) => setExpand(true)}><ExpandMore className="pointer mr-2" ></ExpandMore></div>
                    }
                    {(expand === true) &&
                        <div onClick={(e) => setExpand(false)}><ExpandLess className="pointer mr-2" ></ExpandLess></div>
                    }
                    <label className='ml-2 my-auto'><span style={{ fontWeight: 600, marginRight: 10 }}>ข้อมูลทั่วไป</span></label>
                </div>
                {(expand === true) && (
                    <div style={{ padding: "15px", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px", border: "3px solid " + borderColor }}>
                        <div className='row mt-1'>
                            <label className='col-2' style={{ fontWeight: 600 }}>รหัสองค์กร</label>
                            <label className='col-2' style={{ fontWeight: 600 }}>{props.parnerProfileList?.code}</label>
                        </div>
                        <div className='row mt-1'>
                            <label className='col-2' style={{ fontWeight: 600 }}>ชื่อองค์กร<span className='text-danger'>*</span></label>
                            <input type="text" className={'form-control col-8 '} value={props.parnerProfileList?.orgName} onChange={(e) => setOrgName(e.target.value)} id={"input-text-orgName"} disabled={props.readonly} />
                        </div>
                        <div className='row mt-1'>
                            <label className='col-2' style={{ fontWeight: 600 }}>ชื่อย่อ</label>
                            <input type="text" className={'form-control col-8 '} value={props.parnerProfileList?.partnerShortname} onChange={(e) => setPartnerShortname(e.target.value)} id={"input-text-partnerShortname"} disabled={props.readonly} />
                        </div>
                        <div className='row mt-1'>
                            <label className='col-2' style={{ fontWeight: 600 }}>ประเภทองค์กร<span className='text-danger'>*</span></label>
                            <SingleSearch isError={getRequireOrgGroup(props.parnerProfileList?.orgType)}
                                isSearchable={true} isMulti={false} placeholder=""
                                onChange={(id: string, e: any) => setOrgType(e == null ? null : e?.value)}
                                value={getOrgType()}
                                options={createOrgTypeSearchFilter()}
                                style={{ width: "300px" }}
                                disabled={props.success || props.readonly || props.isDisableOrganizeGroup}
                            />
                        </div>
                        <div className='row mt-1'>
                            <label className='col-2' style={{ fontWeight: 600 }}>หมวดหมู่<span className='text-danger'>*</span></label>
                            <SingleSearch isError={getRequireOrgGroup(props.parnerProfileList?.orgGroup)}
                                isSearchable={true} isMulti={false} placeholder=""
                                onChange={(id: string, e: any) => setOrgGroup(e == null ? null : e?.value)}
                                value={getOrgGroup()}
                                options={createOrgGroupSearchFilter()}
                                style={{ width: "300px" }}
                                disabled={props.success || props.readonly || props.isDisableOrganizeGroup}
                            />
                        </div>
                        <div className='row mt-1'>
                            <label className='col-2' style={{ fontWeight: 600 }}>ผู้ดูแลองค์กร <span className='red'>*</span></label><br />
                            <div className="row">
                                <div className="col-8">
                                    <div className="row">
                                        <div className="col-12">
                                            <InputGroup hasValidation>
                                                <SingleSearch isError={null} disabled={props.success || props.readonly} isSearchable={true} isMulti={false} placeholder=""
                                                    onChange={(id: string, e: any) => handleAddAdmin(e)}
                                                    value={searchAdmin}
                                                    options={createAdminSearchFilter("")}
                                                    style={{ width: "450px" }}
                                                />
                                            </InputGroup>
                                        </div>
                                    </div>
                                    <div className='row text-danger col'>{getRequire(props.parnerProfileList?.admin)?'กรุณาเพิ่มผู้ดูแลองค์กร':''}</div>
                                </div>

                            </div>

                        </div>
                        <div className='row'>
                            <div className='col-2'></div>
                            <div className='col-10 row mt-1'>
                                {createAdmin()}
                            </div>
                        </div>
                        <div className='row mt-1'>
                            <label className='col-2' style={{ fontWeight: 600 }}>ประเภทบริษัทจดทะเบียน<span className='text-danger'>*</span>
                                <Tooltip placement="right" title={getDescription()}>
                                    <InfoOutlinedIcon className="blue" style={{ cursor: 'pointer', fontSize: '20px' }} />
                                </Tooltip>
                            </label>
                            <SingleSearch isError={getRequire(props.parnerProfileList?.listedCompanyType)}
                                isSearchable={true} isMulti={false} placeholder=""
                                onChange={(id: string, e: any) => setListedCompany(e == null ? null : e?.value)}
                                value={getListedCompany()}
                                options={createListedComapny()}
                                style={{ width: "300px" }}
                                disabled={props.success || props.readonly}
                            />
                        </div>
                        <div className='row mt-1'>
                            <label className='col-2' style={{ fontWeight: 600 }}>ที่อยู่สำนักงานใหญ่<span className='text-danger'>*</span></label>
                            <div className='d-flex flex-column col-10 pl-0'>
                                <PartnerGeneralInfoAdress parnerProfileList={props.parnerProfileList}
                                    province={props.province}
                                    setParnerProfileListChange={props.setParnerProfileListChange}
                                    submitted={props.submitted}
                                    readonly={props.readonly}
                                    nextAction={props?.nextAction}
                                    nextActionItem={props?.nextActionItem}
                                    success={props.success} />
                            </div>
                        </div>
                    </div>)}
            </div>

        </React.Fragment >
    )
}

export default PartnerGeneralInfoPartner