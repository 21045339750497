import React, { useState } from 'react'
import { BranchInfo, FacultyInfo, PartnerProfileListResponse } from '../../../../model/response/partner-profile';
import iconTrashRed from '../../../../img/iconTrashRed.png'
import { SingleSearch } from '../../../tab/search-component';
import ConfirmModal from '../../../share/ConfirmModal';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Select from 'react-select';
import { customStateStyles, greenStateStyles } from '../../../tab/search-component';
import { createSaveStateHaveNew, createSaveStateNoNew } from '../../../../api/define';
type Props = {
    parnerProfileList: PartnerProfileListResponse;
    success: boolean;
    submitted: boolean;
    facultyItem: any[];
    setParnerProfileListChange: (parnerProfileList: PartnerProfileListResponse) => void;
    readonly: boolean;
    state?: string;
    submittedMerge?: boolean;
    facultyOldItem?: FacultyInfo[];
    hideDropdown?: boolean;
}

const PartnerUniversityFaculty = (props: Props) => {
    const [itemDeleteFaculty, setItemDeleteFaculty] = useState<any>();
    const [confirmDeleteFaculty, setConfirmDeleteFaculty] = useState(false);
    const [itemDeleteBranch, setItemDeleteBranch] = useState<any>();
    const [confirmDeleteBranch, setConfirmDeleteBranch] = useState(false);
    const getRequire = (value: string | boolean | undefined | any[] | null | number) => {
        return (props.submitted && (value == null || value == undefined || value == ''))
    }
    const getOtherFaculty = (value: string | boolean | undefined | any[] | null | number, faculty: any) => {
        if (faculty == 0) {
            return (props.submitted && (value == null || value == undefined || value == ''))
        } else {
            return false
        }

    }
    const setFacultyName = (value: any, facultyIndex: number, index: number) => {
        var newItem = { ...props.parnerProfileList };
        newItem.faculty[index].facultyInfo[facultyIndex].facultyName = value;
        props.setParnerProfileListChange(newItem);
    }
    const getFacultyName = (facultyIndex: number, index: number) => {
        for (var i = 0; i < props.facultyItem.length; i++)
            if (props.facultyItem[i].id === props.parnerProfileList?.faculty[index]?.facultyInfo[facultyIndex]?.facultyName) {
                return { label: props.facultyItem[i].name, value: props.facultyItem[i].id };
            }
        return null;
    }
    const createFacultyName = () => {
        var res: any[] = [];
        for (var i = 0; i < props.facultyItem.length; i++) {
            res.push({ label: props.facultyItem[i].name, value: props.facultyItem[i].id });

        }
        return res;
    }
    const setFacultyTeacherNo = (value: any, facultyIndex: number, index: number) => {
        var newItem = { ...props.parnerProfileList };
        newItem.faculty[index].facultyInfo[facultyIndex].teacherNo = value;
        props.setParnerProfileListChange(newItem);
    }
    const setOtherFaculty = (value: any, facultyIndex: number, index: number) => {
        var newItem = { ...props.parnerProfileList };
        newItem.faculty[index].facultyInfo[facultyIndex].otherFacultyName = value;
        props.setParnerProfileListChange(newItem);
    }
    const setFacultyPersonnel = (value: any, facultyIndex: number, index: number) => {
        var newItem = { ...props.parnerProfileList };
        newItem.faculty[index].facultyInfo[facultyIndex].personnel = value;
        props.setParnerProfileListChange(newItem);
    }
    const setFacultyStudentNo = (value: any, facultyIndex: number, index: number) => {
        var newItem = { ...props.parnerProfileList };
        newItem.faculty[index].facultyInfo[facultyIndex].studentNo = value;
        props.setParnerProfileListChange(newItem);
    }
    const setBranchName = (value: any, branchIndex: number, index: number) => {
        var newItem = { ...props.parnerProfileList };
        newItem.faculty[index].branchInfo[branchIndex].branchName = value;
        props.setParnerProfileListChange(newItem);
    }
    const setBranchTeacherNo = (value: any, branchIndex: number, index: number) => {
        var newItem = { ...props.parnerProfileList };
        newItem.faculty[index].branchInfo[branchIndex].teacherNo = value;
        props.setParnerProfileListChange(newItem);
    }
    const setBranchPersonnel = (value: any, branchIndex: number, index: number) => {
        var newItem = { ...props.parnerProfileList };
        newItem.faculty[index].branchInfo[branchIndex].personnel = value;
        props.setParnerProfileListChange(newItem);
    }
    const setBranchStudentNo = (value: any, branchIndex: number, index: number) => {
        var newItem = { ...props.parnerProfileList };
        newItem.faculty[index].branchInfo[branchIndex].studentNo = value;
        props.setParnerProfileListChange(newItem);
    }
    const setBranchReleateFinance = (value: any, branchIndex: number, index: number) => {
        var newItem = { ...props.parnerProfileList };
        newItem.faculty[index].branchInfo[branchIndex].relateFinance = value;
        props.setParnerProfileListChange(newItem);
    }
    const setFacultyNoBranch = (value: any, index: number) => {
        var newItem = { ...props.parnerProfileList };
        newItem.faculty[index].noBranch = value;
        newItem.faculty[index].branchInfo = [];
        props.setParnerProfileListChange(newItem);
    }
    const handleDeleteFaculty = (index: number) => {
        const item = {
            index: index,
        }
        setItemDeleteFaculty(item as any);
        setConfirmDeleteFaculty(true);

    }
    const handleDeleteBranch = (index: number, branchIndex: number) => {
        const item = {
            index: index,
            branchIndex: branchIndex
        }
        setItemDeleteBranch(item as any);
        setConfirmDeleteBranch(true);

    }
    const deleteFaculty = () => {
        var newItem = { ...props.parnerProfileList };
        if (itemDeleteFaculty) {
            const { index } = itemDeleteFaculty as any
            newItem.faculty.splice(index, 1);
            props.setParnerProfileListChange(newItem);
        }
        setItemDeleteFaculty(undefined);
        setConfirmDeleteFaculty(false);
    }
    const deleteBranch = () => {
        var newItem = { ...props.parnerProfileList };
        if (itemDeleteBranch) {
            const { index, branchIndex } = itemDeleteBranch as any
            newItem.faculty[index].branchInfo.splice(branchIndex, 1);
            props.setParnerProfileListChange(newItem);
        }
        setItemDeleteBranch(undefined);
        setConfirmDeleteBranch(false);
    }
    const handleAddBranch = (index: number) => {
        var newItem = { ...props.parnerProfileList };
        if (newItem.faculty[index].branchInfo == null) newItem.faculty[index].branchInfo = [];
        let branch: BranchInfo = {
            id: null,
            branchName: null,
            personnel: null,
            teacherNo: null,
            studentNo: null,
            relateFinance: false
        }
        newItem.faculty[index].noBranch = false;
        newItem.faculty[index].branchInfo.push(branch);
        props.setParnerProfileListChange(newItem);
    }
    const setMergeState = (value: any, index: number) => {
        var newItem = { ...props.parnerProfileList };
        newItem.faculty[index].mergeState = value;
        props.setParnerProfileListChange(newItem);
    }
    const getMergeState = (index: number) => {
        let dropdown = createSaveStateHaveNew()
        for (var i = 0; i < dropdown.length; i++)
            if (dropdown[i].value === props.parnerProfileList?.faculty[index]?.mergeState) {
                return { label: dropdown[i].label, value: props.parnerProfileList?.faculty[index]?.mergeState };
            }
        return null;
    }
    const getRequireMerge = (value: string | boolean | undefined | any[] | null | number) => {
        return (props.submittedMerge && (value == null || value == undefined))
    }
    const createOldFacultyDropdown = () => {
        var res: any[] = [];
        if (props.facultyOldItem) {
            props.facultyOldItem.forEach(x => {
                res.push({ label: x.facultyDisplayName, value: x.id });
            })
        }
        return res
    }
    const setSelectOldFacultyId = (value: any, index: number) => {
        var newItem = { ...props.parnerProfileList };
        newItem.faculty[index].selectedOldPartnerFacultyId = value;
        props.setParnerProfileListChange(newItem);
    }
    const getSelectOldFacultyId = (index: number) => {
        let dropdown = createOldFacultyDropdown()
        for (var i = 0; i < dropdown.length; i++)
            if (dropdown[i].value === props.parnerProfileList?.faculty[index]?.selectedOldPartnerFacultyId) {
                return { label: dropdown[i].label, value: props.parnerProfileList?.faculty[index]?.selectedOldPartnerFacultyId };
            }
        return null;
    }
    return (
        <>
            {props.parnerProfileList?.faculty && props.parnerProfileList.faculty.map((faculty, index) => (
                <div key={"faculty" + index} className='gray-button mb-3' style={{ position: 'relative', padding: "15px", borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}>
                    <React.Fragment >
                        {props.state == 'new' && !props.hideDropdown && <div className='row mt-1 d-flex justify-content-end mr-2'>
                            <Select
                                isSearchable={true} isMulti={false} placeholder=""
                                onChange={(e: any) => setMergeState(e == null ? null : e?.value, index)}
                                value={getMergeState(index)}
                                options={createSaveStateHaveNew()}
                                styles={getRequireMerge(faculty.mergeState) ? customStateStyles : greenStateStyles}

                            />
                        </div>}
                        {props.state == 'new' && !props.hideDropdown && faculty.mergeState == 'SAVE' && <div className='row mt-1 d-flex justify-content-end mr-2'>
                            <label className='col-auto' style={{ fontWeight: 600 }}>ข้อมูลเดิม<span className='text-danger'>*</span></label>
                            <Select
                                isSearchable={true} isMulti={false} placeholder=""
                                onChange={(e: any) => setSelectOldFacultyId(e == null ? null : e?.value, index)}
                                value={getSelectOldFacultyId(index)}
                                options={createOldFacultyDropdown()}
                                styles={getRequireMerge(faculty.selectedOldPartnerFacultyId) ? customStateStyles : greenStateStyles}

                            />
                        </div>}
                        <table className='table mt-3' style={{ fontSize: "20px" }}>
                            <thead className='black-button'>
                                <tr>
                                    <th style={{ width: '30%' }}>ชื่อคณะ</th>
                                    <th style={{ width: '20%' }}>คณาจารย์</th>
                                    <th style={{ width: '20%' }}>บุคคลากร</th>
                                    <th style={{ width: '20%' }}>นิสิต/นักศึกษา</th>
                                    <th></th>
                                </tr>


                            </thead>
                            <tbody className='light-gray-button'>
                                {faculty?.facultyInfo && faculty.facultyInfo.map((facultyInfo, facultyInfoIndex) => (
                                    <tr>
                                        <td>
                                            <div className='d-flex'>
                                                <SingleSearch isError={getRequire(facultyInfo?.facultyName)}
                                                    isSearchable={true} isMulti={false} placeholder=""
                                                    onChange={(id: string, e: any) => setFacultyName(e == null ? null : e?.value, facultyInfoIndex, index)}
                                                    value={getFacultyName(facultyInfoIndex, index)}
                                                    options={createFacultyName()}
                                                    style={{ width: "100%" }}
                                                    disabled={props.success || props.readonly}
                                                />
                                                <span className='text-danger ml-2'>*</span>

                                            </div>

                                            {facultyInfo.facultyName == 0 && (
                                                <div>
                                                    <input type="text" disabled={props.readonly} className={'form-control mt-2 ' + (getOtherFaculty(facultyInfo.otherFacultyName, facultyInfo.facultyName) ? "is-invalid" : "")} value={facultyInfo.otherFacultyName as any} onChange={(e) => setOtherFaculty(e.target.value, facultyInfoIndex, index)} />
                                                </div>)}

                                        </td>
                                        <td>
                                            <input type="number" disabled={props.readonly} className={'form-control ' + (getRequire(facultyInfo.teacherNo) ? "is-invalid" : "")} value={facultyInfo.teacherNo as any} onChange={(e) => setFacultyTeacherNo(e.target.value, facultyInfoIndex, index)} />
                                        </td>
                                        <td>
                                            <input type="number" disabled={props.readonly} className={'form-control ' + (getRequire(facultyInfo.personnel) ? "is-invalid" : "")} value={facultyInfo.personnel as any} onChange={(e) => setFacultyPersonnel(e.target.value, facultyInfoIndex, index)} />
                                        </td>
                                        <td>
                                            <input type="number" disabled={props.readonly} className={'form-control ' + (getRequire(facultyInfo.studentNo) ? "is-invalid" : "")} value={facultyInfo.studentNo as any} onChange={(e) => setFacultyStudentNo(e.target.value, facultyInfoIndex, index)} />
                                        </td>
                                        <td></td>
                                    </tr>
                                ))}

                            </tbody>
                        </table>
                        <div>
                            <div className='d-flex mt-2'>
                                {!props.readonly && <div onClick={() => handleAddBranch(index)} style={{ cursor: 'pointer' }}><AddCircleOutlineIcon className="yellow mr-2" />สาขา</div>}
                                {props.readonly && <div>สาขา</div>}
                                <div className='ml-3 form-check checkbox-md'><input type="checkbox" disabled={props.readonly} className='mr-1 form-check-input' checked={faculty.noBranch} onChange={(e) => setFacultyNoBranch(e.target.checked, index)} />ไม่มีสาขา</div>
                            </div>
                            <table className='table mt-3' style={{ fontSize: "20px" }}>
                                <thead className='black-button'>
                                    {faculty?.branchInfo && faculty?.branchInfo.length > 0 && (
                                        <tr>
                                            <th style={{ width: '30%' }}>ชื่อสาขา</th>
                                            <th style={{ width: '10%' }}>คณาจารย์</th>
                                            <th style={{ width: '10%' }}>บุคคลากร</th>
                                            <th style={{ width: '15%' }}>นิสิต/นักศึกษา</th>
                                            <th style={{ width: '20%' }}>เป็นสาขาที่เกี่ยวข้องกับการเงิน</th>
                                            <th></th>
                                        </tr>
                                    )}

                                </thead>
                                <tbody className='light-gray-button'>
                                    {faculty?.branchInfo && faculty.branchInfo.map((branchInfo, branchInfoIndex) => (
                                        <tr>
                                            <td>
                                                <div className='d-flex'>
                                                    <input type="text" disabled={props.readonly} className={'form-control ' + (getRequire(branchInfo.branchName) ? "is-invalid" : "")} value={branchInfo.branchName as any} onChange={(e) => setBranchName(e.target.value, branchInfoIndex, index)} />
                                                    <span className='text-danger ml-2'>*</span>
                                                </div>

                                            </td>
                                            <td>
                                                <input type="number" disabled={props.readonly} className={'form-control ' + (getRequire(branchInfo.teacherNo) ? "is-invalid" : "")} value={branchInfo.teacherNo as any} onChange={(e) => setBranchTeacherNo(e.target.value, branchInfoIndex, index)} />
                                            </td>
                                            <td>
                                                <input type="number" disabled={props.readonly} className={'form-control ' + (getRequire(branchInfo.personnel) ? "is-invalid" : "")} value={branchInfo.personnel as any} onChange={(e) => setBranchPersonnel(e.target.value, branchInfoIndex, index)} />
                                            </td>
                                            <td>
                                                <input type="number" disabled={props.readonly} className={'form-control ' + (getRequire(branchInfo.studentNo) ? "is-invalid" : "")} value={branchInfo.studentNo as any} onChange={(e) => setBranchStudentNo(e.target.value, branchInfoIndex, index)} />
                                            </td>
                                            <td className='text-center form-check checkbox-lg'><input type="checkbox" disabled={props.readonly} className={'form-check-input mt-2'} checked={branchInfo.relateFinance} onChange={(e) => setBranchReleateFinance(e.target.checked, branchInfoIndex, index)} /></td>
                                            <td className='text-center'>
                                                {!props.readonly && <img src={iconTrashRed} width={20} height={20} id={"icon_trash_branch" + index + "_" + branchInfoIndex} style={{ cursor: 'pointer' }} onClick={e => { handleDeleteBranch(index, branchInfoIndex) }} className='mr-2' />}
                                            </td>
                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                        </div>
                        {!props.readonly && (<img src={iconTrashRed} width={20} height={20} id={"icon_trash_faculty" + index} style={{ position: "absolute", right: "5px", top: "5px", zIndex: 10, cursor: 'pointer' }} onClick={e => { handleDeleteFaculty(index) }} className='mr-2' />)}
                    </React.Fragment>
                    <ConfirmModal title={'กรุณากด "ยืนยัน" เพื่อลบรายการ'} name={''} show={confirmDeleteFaculty}
                        onHide={() => setConfirmDeleteFaculty(false)} onOk={() => deleteFaculty()} />
                    <ConfirmModal title={'กรุณากด "ยืนยัน" เพื่อลบรายการ'} name={''} show={confirmDeleteBranch}
                        onHide={() => setConfirmDeleteBranch(false)} onOk={() => deleteBranch()} />
                </div>
            ))
            }</>
    )
}

export default PartnerUniversityFaculty