import React, { useEffect } from 'react'
import PartnerHeader from '../../PartnerHeader'
import { Banner, CardBox, PartnerContainer } from '../../../../style/partnerStylemain'
import banner from '../../../../img/partnerBanner.png'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
type Props = {}

const PartnerFillMasterDataThankyou = (props: Props) => {
    useEffect(()=>{
        sessionStorage.removeItem("partnerList");
    },[])
    return (
        <div className='bg-light-gray partner-page' style={{ height: "100vh", fontFamily: "DB Helvethaica" }}>
            <div style={{ position: 'sticky', top: 0 }}>
                <div className="student-wrapper-header-inner" style={{ background: "#38383D" }}>
                    <PartnerHeader />
                </div>
            </div>
            <PartnerContainer className='mt-5'>
                <Banner src={banner}></Banner>
                <h2 className='text-center mt-3' ><br/></h2>
                <CardBox>
                    <div className='text-center'>
                        <CheckCircleIcon className='my-4' style={{fontSize:80,color:"#53a451"}}/>
                    </div>
                    <div className='text-center' style={{fontSize:22}}>
                        ขอขอบคุณที่กรอกแบบสอบถาม
                    </div>
                </CardBox>
            </PartnerContainer>
        </div>
    )
}


export default PartnerFillMasterDataThankyou