import React from 'react'
import { PartnerProfileListResponse, Transition } from '../../../../model/response/partner-profile';
import { SingleSearch } from '../../../tab/search-component';
import { AdressResponse } from '../../../../model/response/adress.model';

type Props = {
    parnerProfileList: PartnerProfileListResponse;
    success: boolean;
    submitted: boolean;
    province: AdressResponse[]
    setParnerProfileListChange: (parnerProfileList: PartnerProfileListResponse) => void;
    readonly: boolean;
    nextAction?: number;
    nextActionItem?: Transition[]
}

const PartnerGeneralInfoAdress = (props: Props) => {
    const getRequire = (value: string | boolean | undefined | any[] | null | number) => {
        let moveTransition;
        if(props.nextActionItem){
            moveTransition = props?.nextActionItem.find(x => x.id == props.nextAction);
        }
        let isSendFormToCustomer = moveTransition?.transition == 'ส่งฟอร์มให้กรอกข้อมูล'
        if(isSendFormToCustomer){
            return false;
        }else{
            return (props.submitted && (value == null || value == undefined || value == ''))
        }
       
    }
    const setHouseNo = (e: any) => {
        var newItem = { ...props.parnerProfileList };
        newItem.houseNo = e;
        props.setParnerProfileListChange(newItem);
    }
    const setBuilding = (e: any) => {
        var newItem = { ...props.parnerProfileList };
        newItem.building = e;
        props.setParnerProfileListChange(newItem);
    }
    const setRoad = (e: any) => {
        var newItem = { ...props.parnerProfileList };
        newItem.road = e;
        props.setParnerProfileListChange(newItem);
    }
    const setProvince = (e: any) => {
        var newItem = { ...props.parnerProfileList };
        newItem.province = e;
        props.setParnerProfileListChange(newItem);
    }
    const getProvince = () => {
        for (var i = 0; i < props.province.length; i++)
            if (props.province[i].code === props.parnerProfileList?.province) {
                return { label: props.province[i].name, value: props.parnerProfileList?.province };
            }
        return null;
    }
    const createProvince = () => {
        var res: any[] = [];
        for (var i = 0; i < props.province.length; i++) {
            res.push({ label: props.province[i].name, value: props.province[i].code });

        }
        return res;
    }
    const setDistrict = (e: any) => {
        var newItem = { ...props.parnerProfileList };
        newItem.district = e;
        props.setParnerProfileListChange(newItem);
    }
    const getDistrict = () => {
        for (var i = 0; i < props.province.length; i++) {
            if (props.province[i].code == props.parnerProfileList?.province) {
                var district = props.province[i].districtList.find((x:any)=>x.code == props.parnerProfileList?.district)
                return { label: district?.name, value: district?.code };
            }
        }
        return null;
    }
    const createDistrict = () => {
        var res: any[] = [];
        for (var i = 0; i < props.province.length; i++) {
            if (props.province[i].code == props.parnerProfileList?.province) {
                for(var j = 0;j < props.province[i].districtList.length; j++){
                    var district = props.province[i].districtList[j];
                    res.push({ label: district.name, value: district.code });
                }
                
            }
        }
        return res;
    }
    const setSubDistrict = (e: any) => {
        var newItem = { ...props.parnerProfileList };
        newItem.subDistrict = e;
        for (var i = 0; i < props.province.length; i++) {
            if (props.province[i].code == props.parnerProfileList?.province) {
                for(var j = 0; j < props.province[i].districtList.length; j++){
                    if(props.province[i].districtList[j].code == props.parnerProfileList?.district){
                        var subDistrict = props.province[i].districtList[j].subdistrictList.find(x=>x.code ==e)
                        newItem.zipCode = subDistrict?.zipcode;
                    }
                }
                   
            }
        }
        props.setParnerProfileListChange(newItem);
    }
    const getSubDistrict = () => {
        for (var i = 0; i < props.province.length; i++) {
            if (props.province[i].code == props.parnerProfileList?.province) {
                for(var j = 0; j < props.province[i].districtList.length; j++){
                    if(props.province[i].districtList[j].code == props.parnerProfileList?.district){
                        var subDistrict = props.province[i].districtList[j].subdistrictList.find(x=>x.code ==props.parnerProfileList?.subDistrict)
                        return { label: subDistrict?.name, value: subDistrict?.code };
                    }
                }
                
            }
        }
        return null;
    }
    const createSubDistrict = () => {
        var res: any[] = [];
        for (var i = 0; i < props.province.length; i++) {
            if (props.province[i].code == props.parnerProfileList?.province) {
                for(var j = 0; j < props.province[i].districtList.length; j++){
                    if(props.province[i].districtList[j].code == props.parnerProfileList?.district){
                        props.province[i].districtList[j].subdistrictList.forEach(x=>{
                            res.push({ label: x.name, value: x.code });
                        })
                    }
                }
                
            }
        }
        return res;
    }
    const setZipcode= (e: any) => {
        var newItem = { ...props.parnerProfileList };
        newItem.zipCode = e;
        props.setParnerProfileListChange(newItem);
    }
    return (
        <div className='gray-button' style={{ padding: "15px", borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}>
            <div className='row mt-1'>
                <label className='col-2' style={{ fontWeight: 600 }}>เลขที่/ซอย<span className='text-danger'>*</span></label>
                <input type="text" disabled={props.readonly} className={'form-control col-8 ' + (getRequire(props.parnerProfileList?.houseNo) ? "is-invalid" : "")} value={props.parnerProfileList?.houseNo} onChange={(e) => setHouseNo(e.target.value)} id={"input-text-houseNo"} />
            </div>
            <div className='row mt-1'>
                <label className='col-2' style={{ fontWeight: 600 }}>อาคาร</label>
                <input type="text" disabled={props.readonly} className={'form-control col-8 '} value={props.parnerProfileList?.building} onChange={(e) => setBuilding(e.target.value)} id={"input-text-building"} />
            </div>
            <div className='row mt-1'>
                <label className='col-2' style={{ fontWeight: 600 }}>ถนน</label>
                <input type="text" disabled={props.readonly} className={'form-control col-8 '} value={props.parnerProfileList?.road} onChange={(e) => setRoad(e.target.value)} id={"input-text-road"} />
            </div>
            <div className='row mt-1'>
                <label className='col-2' style={{ fontWeight: 600 }}>จังหวัด<span className='text-danger'>*</span></label>
                <SingleSearch isError={getRequire(props.parnerProfileList?.province)}
                    isSearchable={true} isMulti={false} placeholder=""
                    onChange={(id: string, e: any) => setProvince(e == null ? null : e?.value)}
                    value={getProvince()}
                    options={createProvince()}
                    style={{ width: "300px" }}
                    disabled={props.success || props.readonly}
                />
            </div>
            <div className='row mt-1'>
                <label className='col-2' style={{ fontWeight: 600 }}>เขต/อำเภอ<span className='text-danger'>*</span></label>
                <SingleSearch isError={getRequire(props.parnerProfileList?.district)}
                    isSearchable={true} isMulti={false} placeholder=""
                    onChange={(id: string, e: any) => setDistrict(e == null ? null : e?.value)}
                    value={getDistrict()}
                    options={createDistrict()}
                    style={{ width: "300px" }}
                    disabled={props.success || props.readonly}
                />
            </div>
            <div className='row mt-1'>
                <label className='col-2' style={{ fontWeight: 600 }}>แขวง/ตำบล<span className='text-danger'>*</span></label>
                <SingleSearch isError={getRequire(props.parnerProfileList?.district)}
                    isSearchable={true} isMulti={false} placeholder=""
                    onChange={(id: string, e: any) => setSubDistrict(e == null ? null : e?.value)}
                    value={getSubDistrict()}
                    options={createSubDistrict()}
                    style={{ width: "300px" }}
                    disabled={props.success || props.readonly}
                />
            </div>
            <div className='row mt-1'>
                <label className='col-2' style={{ fontWeight: 600 }}>รหัสไปรษณีย์<span className='text-danger'>*</span></label>
                <input type="text" disabled={true} className={'form-control col-3 ' + (getRequire(props.parnerProfileList?.zipCode) ? "is-invalid" : "")} value={props.parnerProfileList?.zipCode} onChange={(e) => setZipcode(e.target.value)} id={"input-text-zipCode"} />
            </div>
        </div>
    )
}

export default PartnerGeneralInfoAdress