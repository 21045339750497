const tabStyle = { paddingLeft: "20px", paddingRight: "20px", paddingTop: "10px", paddingBottom: "10px", borderTopLeftRadius: "5px", borderTopRightRadius: "5px" };
const tabPointerStyle = { cursor: "pointer", paddingLeft: "20px", paddingRight: "20px", paddingTop: "10px", paddingBottom: "10px", borderTopLeftRadius: "5px", borderTopRightRadius: "5px" };

const SurveyTab = (step: number, nextStep: boolean, handleSearch: Function | null,handleManage: Function | null) => {
    return (<div className="row" style={{ justifyContent: "left", paddingLeft: "40px" }}>
        <div className={(step === 1) ? "yellow-button" : "yellow-secondary-button"} style={(handleSearch == null) ? tabStyle : tabPointerStyle} onClick={e => ((handleSearch != null) ? handleSearch() : () => { })}>
            <b>แบบประเมินผล</b>
        </div>&nbsp;
        <div className={(step === 2) ? "yellow-button" : "yellow-secondary-button"} style={(handleManage == null) ? tabStyle : tabPointerStyle} onClick={e => ((handleManage != null) ? handleManage() : () => { })}>
            <b>จัดการคำถาม</b>
        </div>
    </div>);
}

export default SurveyTab;