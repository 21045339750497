import React, { useEffect, useState } from 'react'
import PartnerHeader from '../../PartnerHeader'
import { Banner, CardBox, PartnerContainer } from '../../../../style/partnerStylemain'
import banner from '../../../../img/partnerBanner.png'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useDispatch, useStore } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { NOT_FOUND_LABEL } from '../../../../api/label';
import { partnerVerifyLinkEditPartnerProfile } from '../../../../store/publicPartner/action';
import { CircularProgress } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { AlertContainer, alert } from 'react-custom-alert'
type Props = {}

const PartnerFillMasterDataValidateLink = (props: Props) => {
    const history = useHistory();
    const [init, setInit] = useState(0);
    const store = useStore();
    const [error,setError] = useState();
    const dispatch = useDispatch();
    let { userKey } = useParams<{ userKey: string }>();
    let { code } = useParams<{ code: string }>();
    let { key } = useParams<{ key: string }>();
    useEffect(() => {
        const unsubscribe = store.subscribe(() => {
            var res = store.getState().SearchReducer;
            if (res.isLoading) {
            } else if (res.isLoaded) {
                if (res.data != null) {
                    if (res.table === "partner-verify-link-edit-partner-profile") {
                        history.push("/partner/fill-master/verify-otp/"+userKey+"/"+code+"/"+key);
                    }

                } else {
                    alert({ message: NOT_FOUND_LABEL, type: 'error' });
                    setError(NOT_FOUND_LABEL as any)
                }
            } else if (res.isNotFound) {
                alert({ message: NOT_FOUND_LABEL, type: 'error' });
                setError(NOT_FOUND_LABEL as any)
            } else if (res.isFailed) {
                alert({ message: res.data.message, type: 'error' });
                setInit(1);
                setError(res.data.message)
            }
        });
        dispatch(partnerVerifyLinkEditPartnerProfile(userKey,code,key))
    },[])
    return (
        <div className='bg-light-gray partner-page' style={{ height: "100vh", fontFamily: "DB Helvethaica" }}>
            <div style={{ position: 'sticky', top: 0 }}>
                <div className="student-wrapper-header-inner" style={{ background: "#38383D" }}>
                    <PartnerHeader />
                </div>
            </div>
            <PartnerContainer className='mt-5'>
                <Banner src={banner}></Banner>
                <h2 className='text-center mt-3' ><br /></h2>
                <CardBox>
                    {init == 0 && (
                        <>
                            <div className='text-center'>
                                <div className='d-flex flex-column'>
                                    <div className='text-center' style={{ paddingBlock: 40 }}><CircularProgress style={{ 'color': '#628395' }} size="80px" /></div>
                                </div>
                            </div>
                        </>
                    )}
                    {error && (
                        <>
                        <div className='text-center'>
                            <CancelIcon className='my-4' style={{ fontSize: 80, color: "#f24726" }} />
                        </div>
                        <div className='text-center' style={{ fontSize: 22 }}>
                            {error}
                        </div>
                    </>
                    )}

                </CardBox>
            </PartnerContainer>
        </div>
    )
}

export default PartnerFillMasterDataValidateLink