import { apiClient } from './axios-intercept';
import { DEFAULT_SERVER } from './conf';

const ASSIGNMENT ="/api/v1/assignment";
const COURSE ="/course";
const STUDENT = "/student";
const TRAINER = "/trainer";
const FEEDBACK = "/feedback";
const EXPORT ="/api/v1/assignment/export";
export const EXPORT_ASSIGNMENT_URL = DEFAULT_SERVER+EXPORT;
export const EXPORT_ASSIGNMENT_TRAINER_URL = DEFAULT_SERVER + '/api/v1/page/trainer/assignment-pagination/export'

const SEARCH_ASSIGNMENT_URL = DEFAULT_SERVER + ASSIGNMENT;
const LIST_COURSE_ASSIGNMENT_URL = DEFAULT_SERVER + ASSIGNMENT+COURSE;
const LIST_TRAINER_ASSIGNMENT_URL = DEFAULT_SERVER + ASSIGNMENT+TRAINER;
const LIST_FEEDBACK_ASSIGNMENT_URL = DEFAULT_SERVER + ASSIGNMENT+FEEDBACK;
const SAVE_ASSIGNMENT_URL = DEFAULT_SERVER + ASSIGNMENT+STUDENT;
const GET_ASSIGNMENT_URL = DEFAULT_SERVER + ASSIGNMENT+STUDENT;

export class AssignmentAPI {
        
    public static listAssignment = async (classId: number,param: string,page: number,limit: number): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=SEARCH_ASSIGNMENT_URL+"?class_id="+classId+"&"+param+"&page="+page+"&limit="+limit; 
            apiClient.get(s,
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                console.log("list assignment response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("list assignment error:", err);
                return reject(err); 
            })
        })
    }

    public static listCourse = async (classId: number): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=LIST_COURSE_ASSIGNMENT_URL+"?class_id="+classId; 
            apiClient.get(s,
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                console.log("list course for assignment response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("list course for assignment error:", err);
                return reject(err); 
            })
        })
    }

    public static listTrainer = async (classId: number): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=LIST_TRAINER_ASSIGNMENT_URL+"?class_id="+classId; 
            apiClient.get(s,
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                console.log("list trainer for assignment response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("list trainer for assignment error:", err);
                return reject(err); 
            })
        })
    }


    public static get = async (classId: number,classStudentId: number): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=GET_ASSIGNMENT_URL+"?class_id="+classId+"&class_student_id="+classStudentId; 
            apiClient.get(s,
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                console.log("get assignment response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("get assignment error:", err);
                return reject(err); 
            })
        })
    }

    public static save = async (item:any): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=SAVE_ASSIGNMENT_URL; 
            
            apiClient.post(s,item,
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                console.log("save assignment response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("save assignment error:", err);
                return reject(err); 
            })
        })
    }

    
    public static listFeedback = async (classId: number,classStudentId: number): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=LIST_FEEDBACK_ASSIGNMENT_URL+"?class_student_id="+classStudentId+"&class_id="+classId; 
            apiClient.get(s,
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                console.log("list feedback for assignment response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("list feedback for assignment error:", err);
                return reject(err); 
            })
        })
    }

    public static getParam = (name: string,classCourseId: any[],result: any[]) => {
        //param
        var param=((name===undefined)?"":"&name="+encodeURIComponent(name));
        if (classCourseId!==undefined)
        {
            for (var i=0;i<classCourseId.length;i++)
            {
                param=param+"&class_course_id="+classCourseId[i].value;
            }
        }
        if (result!==undefined)
        {
            if (result.length===1)
            {
                param=param+"&submit="+result[0].value;
            }
        }
        console.log(param);
        return param;
    }
}