import axios from "axios";
import { DEFAULT_SERVER } from "./conf";

export class ZtestAPI{
    public static uploadBanner = async (file:File): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = DEFAULT_SERVER+ "/api/test/banner";
            var formData = new FormData();
            if (file!==undefined) formData.append("file", file);
            axios.post(s,formData,
                {
                    headers: {
                        //"Content-Type": "multipart/form-data",
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    return resolve(response);
                }).catch(err => {
                    console.log("get banner error:", err);
                    return reject(err);
                })
        })
    }

    public static uploadAnnounce = async (file: File,description: string,topic: string,createBy: string): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = DEFAULT_SERVER+ "/api/test/announce";
            var formData = new FormData();
            if (file!==undefined) formData.append("file", file);
            formData.append("description", description);
            formData.append("topic", topic);
            formData.append("createBy", createBy);
            axios.post(s,formData,
                {
                    headers: {
                        //"Content-Type": "multipart/form-data",
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    return resolve(response);
                }).catch(err => {
                    console.log("get banner error:", err);
                    return reject(err);
                })
        })
    }
}