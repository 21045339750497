import axios from 'axios';
import { store } from '../store/store';
import { AuthAPI } from './auth-api';
import { REFRESH_TOKEN_TIME_OUT } from './conf';
import { AuthActionType } from '../store/auth/types';
import { LoginType } from './define';
var client=axios.create();
var publicClient = axios.create();

client.interceptors.request.use(
    async (config) => {
        var access_token=store.getState().AuthReducer.token.access_token;
        const refresh_token=store.getState().AuthReducer.token.refresh_token;
        const user_type=store.getState().AuthReducer.usertype;
        const expiryTime=store.getState().AuthReducer.refreshTime;
        var now=Math.floor(new Date().getTime()/1000);
        if (now-expiryTime>REFRESH_TOKEN_TIME_OUT*60) {

          if (user_type===LoginType.ADMIN) {
            await AuthAPI.refreshToken(refresh_token)
            .then(function (response) {
                store.dispatch({
                    type: AuthActionType.REFRESH_SUCCESS,
                    payload: response.data
                });
                access_token=response.data.access_token;
            }, function (error) {
            })
          } else if (user_type===LoginType.STUDENT || user_type===LoginType.TRAINER) {
            await AuthAPI.studentRefreshToken(refresh_token)
            .then(function (response) {
                store.dispatch({
                    type: AuthActionType.REFRESH_SUCCESS,
                    payload: response.data
                });
                access_token=response.data.access_token;
            }, function (error) {
            })
          }
        }

        config.headers = { 
          'Authorization': (user_type===LoginType.ADMIN)?`Bearer ${access_token}`:`Basic ${access_token}`,
        }
        return config;
    },
    (error) => {
      return Promise.reject(error);
    });

export const apiClient = client;
export const apiPublicClient = publicClient;
