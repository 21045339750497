import React, { useState } from 'react'
import { PartnerProfileListResponse, WelfareBenefit } from '../../../../model/response/partner-profile';
import ExpandMore from '@material-ui/icons/ExpandMore'
import ExpandLess from '@material-ui/icons/ExpandLess'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import iconTrashRed from '../../../../img/iconTrashRed.png'
import ConfirmModal from '../../../share/ConfirmModal';
import { createSaveStateNoNew } from '../../../../api/define';
import Select from 'react-select';
import { customStateStyles, greenStateStyles } from '../../../tab/search-component';
type Props = {
    parnerProfileList: PartnerProfileListResponse;
    success: boolean;
    submitted: boolean;
    setParnerProfileListChange: (parnerProfileList: PartnerProfileListResponse) => void;
    readonly: boolean;
    state?: string;
    submittedMerge?: boolean;
    hideDropdown?: boolean;
}

const PartnerWelfareBenefit = (props: Props) => {
    const [expand, setExpand] = useState(true);
    const [itemDeleteWelfare, setItemDeleteWelfare] = useState<any>();
    const [confirmDeleteWelfare, setConfirmDeleteWelfare] = useState(false);
    const [tabColor, setTabColor] = useState(props.state ? props.state == 'old' ? 'gray-button' : 'yellow-button' : 'yellow-button');
    const [borderColor, setBorderColor] = useState(props.state ? props.state == 'old' ? '#dddddd' : '#fab232' : '#fab232')
    const getRequire = (value: string | boolean | undefined | any[] | null | number) => {
        return (props.submitted && (value == null || value == undefined || value == ''))
    }
    const getRequireBenefit = (check: boolean, value: string | boolean | undefined | any[] | null | number) => {
        if (check) {
            return (props.submitted && (value == null || value == undefined || value == ''))
        } else {
            return false
        }
    }
    const setCheckSocialSecurity = (e: any) => {
        var newItem = { ...props.parnerProfileList };
        newItem.checkSocialSecurity = e;
        if (e == false) {
            newItem.socialSecurityAmount = '' as any;
        }
        props.setParnerProfileListChange(newItem);
    }
    const setSocialSecurity = (e: any) => {
        var newItem = { ...props.parnerProfileList };
        newItem.socialSecurityAmount = e;
        props.setParnerProfileListChange(newItem);
    }
    const setCheckProvidentFund = (e: any) => {
        var newItem = { ...props.parnerProfileList };
        newItem.checkProvidentFund = e;
        if (e == false) {
            newItem.providentFundAmount = '' as any;
        }
        props.setParnerProfileListChange(newItem);
    }
    const setProvidentFund = (e: any) => {
        var newItem = { ...props.parnerProfileList };
        newItem.providentFundAmount = e;
        props.setParnerProfileListChange(newItem);
    }
    const setCheckGovPensionFund = (e: any) => {
        var newItem = { ...props.parnerProfileList };
        newItem.checkGovPensionFund = e;
        if (e == false) {
            newItem.govPensionFundAmount = '' as any;
        }
        props.setParnerProfileListChange(newItem);
    }
    const setGovPensionFundAmount = (e: any) => {
        var newItem = { ...props.parnerProfileList };
        newItem.govPensionFundAmount = e;
        props.setParnerProfileListChange(newItem);
    }
    const setCheckSavingsCoorperative = (e: any) => {
        var newItem = { ...props.parnerProfileList };
        newItem.checkSavingsCooperative = e;
        if (e == false) {
            newItem.savingsCooperativeAmount = '' as any;
        }
        props.setParnerProfileListChange(newItem);
    }
    const setSavingsCooperativeAmount = (e: any) => {
        var newItem = { ...props.parnerProfileList };
        newItem.savingsCooperativeAmount = e;
        props.setParnerProfileListChange(newItem);
    }
    const setWelfareBenefitName = (e: any, index: number) => {
        var newItem = { ...props.parnerProfileList };
        if (newItem.welfareBenefitList == null) newItem.welfareBenefitList = [];
        newItem.welfareBenefitList[index].welfareBenefitName = e;
        props.setParnerProfileListChange(newItem);
    }
    const setMemberAmount = (e: any, index: number) => {
        var newItem = { ...props.parnerProfileList };
        if (newItem.welfareBenefitList == null) newItem.welfareBenefitList = [];
        newItem.welfareBenefitList[index].memberAmount = e;
        props.setParnerProfileListChange(newItem);
    }

    const handleAddWelfareBenefit = () => {
        var newItem = { ...props.parnerProfileList };
        if (newItem.welfareBenefitList == null) newItem.welfareBenefitList = [];
        let welfare: WelfareBenefit = {
            id: null,
            welfareBenefitName: null,
            memberAmount: null
        }
        newItem.welfareBenefitList.push(welfare);
        props.setParnerProfileListChange(newItem);
    }
    const handleDeleteWelfare = (index: number) => {
        const item = {
            index: index
        }
        setItemDeleteWelfare(item as any);
        setConfirmDeleteWelfare(true);
    }
    const deleteWelfare = () => {
        var newItem = { ...props.parnerProfileList };
        if (itemDeleteWelfare) {
            const { index } = itemDeleteWelfare as any
            newItem.welfareBenefitList.splice(index, 1);
            props.setParnerProfileListChange(newItem);
        }
        setItemDeleteWelfare(undefined);
        setConfirmDeleteWelfare(false);
    }
    const getRequireMerge = (value: string | boolean | undefined | any[] | null | number) => {
        return (props.submittedMerge && (value == null || value == undefined))
    }
    const setMergeStateWelfareBenefit = (e: any) => {
        var newItem = { ...props.parnerProfileList };
        newItem.mergeStateWelfareBenefit = e;
        props.setParnerProfileListChange(newItem);
    }
    const getMergeStateWelfareBenefit = () => {
        let dropdown = createSaveStateNoNew()
        for (var i = 0; i < dropdown.length; i++)
            if (dropdown[i].value === props.parnerProfileList?.mergeStateWelfareBenefit) {
                return { label: dropdown[i].label, value: props.parnerProfileList?.mergeStateWelfareBenefit };
            }
        return null;
    }

    return (
        <React.Fragment>
            <div className="text-left" style={{ position: "relative", marginBottom: 5 }}>
                <div className={'d-flex ' + tabColor} style={{ padding: "15px", borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}>
                    {((expand === undefined) || (expand === false)) &&
                        <div onClick={(e) => setExpand(true)}><ExpandMore className="pointer mr-2" ></ExpandMore></div>
                    }
                    {(expand === true) &&
                        <div onClick={(e) => setExpand(false)}><ExpandLess className="pointer mr-2" ></ExpandLess></div>
                    }
                    <label className='ml-2 my-auto'><span style={{ fontWeight: 600, marginRight: 10 }}>ข้อมูลสวัสดิการการออม</span>{props.state && (<span style={{ fontWeight: 600, marginRight: 10 }}>{props.state == "old" ? "(เดิม)" : "(ใหม่)"}</span>)} กรุณาเลือกสวัสดิการที่มีและระบุจำนวนสมาชิก (คน) แยกตามสวัสดิการการออม </label>
                </div>
                {(expand === true) && (
                    <div style={{ padding: "15px", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px", border: "3px solid " + borderColor }}>
                        {props.state == 'new' && !props.hideDropdown && <div className='row mt-1 d-flex justify-content-end mr-2'>
                            <span>
                                <div style={{ fontWeight: 600 }}>Next Action<span className='text-danger'>*</span></div>
                                <Select
                                    isSearchable={true} isMulti={false} placeholder=""
                                    onChange={(e: any) => setMergeStateWelfareBenefit(e == null ? null : e?.value)}
                                    value={getMergeStateWelfareBenefit()}
                                    options={createSaveStateNoNew()}
                                    styles={getRequireMerge(props.parnerProfileList?.mergeStateWelfareBenefit) ? customStateStyles : greenStateStyles}
                                />
                            </span>
                        </div>}
                        <div className='row mt-1'>
                            <div className="col-2 form-check checkbox-lg">
                                <input type="checkbox" disabled={props.readonly} className='form-input-check mr-2 mt-2' id="input-checkSocialSecurity" checked={props.parnerProfileList?.checkSocialSecurity} onChange={(e) => { setCheckSocialSecurity(e.target.checked) }} /><span style={{ fontWeight: 600 }}>ประกันสังคม</span>
                            </div>
                            <div className="col-4">
                                <div className='d-flex'>
                                    <input type="number" disabled={props.readonly || !props.parnerProfileList?.checkSocialSecurity} className={'form-control mr-2 ' + (getRequireBenefit(props.parnerProfileList?.checkSocialSecurity, props.parnerProfileList?.socialSecurityAmount) ? "is-invalid" : "")} value={props.parnerProfileList?.socialSecurityAmount} onChange={(e) => setSocialSecurity(e.target.value)} id={"input-number-socialSecurityAmount"} />
                                    <span className='my-auto' style={{ fontWeight: 600 }}>คน</span>
                                </div>
                            </div>
                        </div>
                        <div className='row mt-1'>
                            <div className="col-2 form-check checkbox-lg">
                                <input type="checkbox" disabled={props.readonly} className='form-input-check mr-2 mt-2' id="input-checkProvidentFund" checked={props.parnerProfileList?.checkProvidentFund} onChange={(e) => { setCheckProvidentFund(e.target.checked) }} /><span style={{ fontWeight: 600 }}>กองทุนสำรองเลี้ยงชีพ</span>
                            </div>
                            <div className="col-4">
                                <div className='d-flex'>
                                    <input type="number" disabled={props.readonly || !props.parnerProfileList?.checkProvidentFund} className={'form-control mr-2 ' + (getRequireBenefit(props.parnerProfileList?.checkProvidentFund, props.parnerProfileList?.providentFundAmount) ? "is-invalid" : "")} value={props.parnerProfileList?.providentFundAmount} onChange={(e) => setProvidentFund(e.target.value)} id={"input-number-providentFund"} />
                                    <span className='my-auto' style={{ fontWeight: 600 }}>คน</span>
                                </div>
                            </div>
                        </div>
                        <div className='row mt-1'>
                            <div className="col-2 form-check checkbox-lg">
                                <input type="checkbox" disabled={props.readonly} className='form-input-check mr-2 mt-2' id="input-checkGovPensionFund" checked={props.parnerProfileList?.checkGovPensionFund} onChange={(e) => { setCheckGovPensionFund(e.target.checked) }} /><span style={{ fontWeight: 600 }}>กองทุนบำเน็จบำนาญข้าราชการ</span>
                            </div>
                            <div className="col-4">
                                <div className='d-flex'>
                                    <input type="number" disabled={props.readonly || !props.parnerProfileList?.checkGovPensionFund} className={'form-control mr-2 ' + (getRequireBenefit(props.parnerProfileList?.checkGovPensionFund, props.parnerProfileList?.govPensionFundAmount) ? "is-invalid" : "")} value={props.parnerProfileList?.govPensionFundAmount} onChange={(e) => setGovPensionFundAmount(e.target.value)} id={"input-number-govPensionFundAmount"} />
                                    <span className='my-auto' style={{ fontWeight: 600 }}>คน</span>
                                </div>
                            </div>
                        </div>
                        <div className='row mt-1'>
                            <div className="col-2 form-check checkbox-lg">
                                <input type="checkbox" disabled={props.readonly} className='form-input-check mr-2 mt-2' id="input-checkSavingsCooperative" checked={props.parnerProfileList?.checkSavingsCooperative} onChange={(e) => { setCheckSavingsCoorperative(e.target.checked) }} /><span style={{ fontWeight: 600 }}>สหกรณ์ออมทรัพย์</span>
                            </div>
                            <div className="col-4">
                                <div className='d-flex'>
                                    <input type="number" disabled={props.readonly || !props.parnerProfileList?.checkSavingsCooperative} className={'form-control mr-2 ' + (getRequireBenefit(props.parnerProfileList?.checkSavingsCooperative, props.parnerProfileList?.savingsCooperativeAmount) ? "is-invalid" : "")} value={props.parnerProfileList?.savingsCooperativeAmount} onChange={(e) => setSavingsCooperativeAmount(e.target.value)} id={"input-number-savingsCooperativeAmount"} />
                                    <span className='my-auto' style={{ fontWeight: 600 }}>คน</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='d-flex mt-2'>
                                {!props.readonly && <div onClick={() => handleAddWelfareBenefit()} style={{ cursor: 'pointer', fontWeight: 600 }}><AddCircleOutlineIcon className="yellow mr-2" />สวัสดิการ</div>}
                                {props.readonly && <div style={{ fontWeight: 600 }}>สวัสดิการ</div>}
                                <div className='ml-2' >โปรดระบุสวัสดิการการออมอื่นๆ นอกเหนือจากรายการข้างต้น (ถ้ามี)</div>
                            </div>
                            <table className='table mt-3' style={{ fontSize: "20px" }}>
                                <thead className='black-button'>
                                    {props.parnerProfileList?.welfareBenefitList && props.parnerProfileList?.welfareBenefitList.length > 0 && (
                                        <tr>
                                            <th style={{ width: '80%' }}>ชื่อสวัสดิการการออม</th>
                                            <th style={{ width: '20%' }}>จำนวนสมาชิก</th>
                                            <th></th>
                                        </tr>
                                    )}

                                </thead>
                                <tbody className='light-gray-button'>
                                    {props.parnerProfileList?.welfareBenefitList && props.parnerProfileList?.welfareBenefitList.map((welfare, index) => (
                                        <tr>
                                            <td>
                                                <div className='d-flex'>
                                                    <input type="text" className={'form-control ' + (getRequire(welfare.welfareBenefitName) ? "is-invalid" : "")} value={welfare.welfareBenefitName as any} onChange={(e) => setWelfareBenefitName(e.target.value, index)} />
                                                    <span className='text-danger ml-2'>*</span>
                                                </div>

                                            </td>
                                            <td>
                                                <input type="number" className={'form-control ' + (getRequire(welfare.memberAmount) ? "is-invalid" : "")} value={welfare.memberAmount as any} onChange={(e) => setMemberAmount(e.target.value, index)} />
                                            </td>
                                            <td className='text-center'>
                                                <img src={iconTrashRed} width={20} height={20} id={"icon_trash_welfare" + index} style={{ cursor: 'pointer' }} onClick={e => { handleDeleteWelfare(index) }} className='mr-2' />
                                            </td>
                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                        </div>
                        <ConfirmModal title={'กรุณากด "ยืนยัน" เพื่อลบรายการ'} name={''} show={confirmDeleteWelfare}
                            onHide={() => setConfirmDeleteWelfare(false)} onOk={() => deleteWelfare()} />
                    </div>
                )}
            </div>
        </React.Fragment>
    )
}

export default PartnerWelfareBenefit