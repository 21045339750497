import React, { useEffect, useState } from 'react';
import { useDispatch, useStore, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import MainMenu from './menu';
import Footer from './adminfooter';
import ClassTab from './classtab';
import { Button, Form, InputGroup, Modal } from 'react-bootstrap';
import { ThaiDateTimePicker, ThaiTimePicker } from '../tab/thai-datetime-picker';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { getclass, listcourse, listproject, listtrainer, createclass, updateclass, deleteclass, listallsurvey, generateseparategroup, uploadclassbanner, uploadclasscoursebanner } from '../../store/editclass/actions';
import { queryclear } from '../../store/search/actions';
import loadinglogo from '../../img/loading.gif';
import ImageIcon from '@material-ui/icons/Image';
import AddIcon from '@material-ui/icons/Add';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ScheduleIcon from '@material-ui/icons/Schedule';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { AlertContainer, alert } from 'react-custom-alert';
import 'react-custom-alert/dist/index.css'; // import css file from root.
import { SingleValue } from 'react-select';
import { SingleSearch } from '../tab/search-component';
import ArrowDropDownIcon from '@material-ui/icons/ExpandMore';
import { CourseType, ELearningType, OnlineType, ActivityType, createCourseType, createELearningType, createActivityType, createOnlineType, TrainerType, SurveyPermissionType, ClassStepType, StudentType, TrainingType } from '../../api/define';
import { MAX_UPLOAD_FILE_SIZE_LABEL, INTERNAL_ERROR_LABEL, REQUIRE_LABEL, PLEASE_SAVE_BEFORE_UPLOAD } from '../../api/label';
import { ActionType } from '../../store/search/types';
import { PREVIEW_SURVEY_URL } from '../../api/survey-api';
import { MAX_FILE_UPLOAD } from '../../api/conf';
import { Util } from '../../api/util';
import AlertModal from '../share/AlertModal';
import { CourseResponse } from '../../model/response/course.model';
import styled from 'styled-components';

const BoxList = styled.div`
    div{
        background-color: #fcdca2;
        display: inline-block;
        border-radius: 5px;
        margin: 8px 8px 0px 8px;
        padding: 5px 8px 5px 8px;
    }
    .icon{
        &:hover{
            cursor: pointer;
            transform: scale(1.1);
        }
    }
    
    /* &:hover{
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    } */
`;

const EditClass = () => {
    const [initProject, setInitProject] = useState(0)
    const [initCourse, setInitCourse] = useState(0)
    const [initTrainer, setInitTrainer] = useState(0)
    const [initClass, setInitClass] = useState(0)
    const [initSurvey, setInitSurvey] = useState(0)

    const [projectItem, setProjectItem] = useState<Array<any>>([])
    const [courseItem, setCourseItem] = useState<Array<any>>([])
    const [trainerItem, setTrainerItem] = useState<Array<any>>([])
    const [surveyItem, setSurveyItem] = useState<Array<any>>([])

    const [item, setItem] = useState<any>({ projectId: null, nextStep: false, fromDate: new Date(), toDate: new Date(), classStepType: ClassStepType.STEP, course: [] });
    const [defaultItem, setDefaultItem] = useState<any>({ projectId: null, nextStep: false, fromDate: new Date(), toDate: new Date(), classStepType: ClassStepType.STEP, course: [] })
    const [loading, setLoading] = useState(false)
    const [updating, setUpdating] = useState(false)
    const [success, setSuccess] = useState(false)
    const [edit, setEdit] = useState(false)
    const [leave, setLeave] = useState("")

    const [deleteId, setDeleteId] = useState<string>("");

    const [error, setError] = useState('');
    const store = useStore();
    const dispatch = useDispatch();
    const history = useHistory();
    let { id } = useParams<{ id: string }>();
    const [alertModalShow, setAlertModalShow] = useState(false);
    const [titleAlertModal, setTitleAlertModal] = useState('');

    useEffect(() => {
        const unsubscribe = store.subscribe(() => {
            let res = store.getState().SearchReducer;
            if (res.page !== "edit_class") return;
            if (res.isLoading) {
                setError('');
                setLoading(true);
            } else if (res.isLoaded) {
                setError('');
                setLoading(false);
                if (res.data != null) {
                    if (res.table === "project") {
                        setInitProject(1);
                        setProjectItem(res.data);
                        var newItem = { ...item };
                        setItem(newItem);
                        setDefaultItem(JSON.parse(JSON.stringify(newItem)));
                    } else if (res.table === "trainer") {
                        setInitTrainer(1);
                        setTrainerItem(res.data);
                    } else if (res.table === "course") {
                        setInitCourse(1);
                        setCourseItem(res.data);
                    } else if (res.table === "survey") {
                        setInitSurvey(1);
                        setSurveyItem(res.data.surveyGroupResponseList);
                    } /*else if (res.table==="privacy") {
                    var newItem={...item};
                    for (var i=0;i<newItem.course.length;i++)
                    {
                        if (newItem.course[i].id===res.data.classCourseId) {
                            newItem.course[i].privacy=res.data.filename;
                            newItem.course[i].loadingPrivacy=undefined;
                        }
                    }
                    setItem(newItem);
                } */else if (res.table === "class_banner") {
                        var newItem = { ...item };
                        newItem.classBanner = res.data.filename;
                        newItem.urlClassBanner = res.data.url;
                        newItem.loadingClassBanner = undefined;
                        setItem(newItem);
                    } else if (res.table === "class_course_banner") {
                        var newItem = { ...item };
                        for (var i = 0; i < newItem.course.length; i++) {
                            if (newItem.course[i].id === res.data.classCourseId) {
                                newItem.course[i].classCourseBanner = res.data.filename;
                                newItem.course[i].urlClassCourseBanner = res.data.url;
                                newItem.course[i].loadingClassCourseBanner = undefined;
                            }
                        }
                        setItem(newItem);
                    } else {
                        setInitClass(1);
                        var newData = correctDate(res.data);
                        setDefaultItem(JSON.parse(JSON.stringify(newData)));
                        setItem(newData);
                        //to do test pop up 
                        if (res.data.errorCourseInactive) {
                            setAlertModalShow(true);
                            setTitleAlertModal(res.data.errorCourseInactive)
                        }

                    }
                } else {
                    setError(INTERNAL_ERROR_LABEL);
                }
            } else if (res.isUpdating) {
                setError('');
                setUpdating(true);
            } else if (res.isUpdated) {
                setError('');
                setUpdating(false);
                if (res.table === "generate_separategroup") {
                    alert({ message: 'แบ่งกลุ่มสำเร็จ', type: 'success' });
                    //clear updating
                    var classCourseId = res.data.classCourseId;
                    var newItem: any = { ...item };
                    for (var i = 0; i < newItem.course.length; i++) {
                        if (newItem.course[i].id === classCourseId) {
                            newItem.course[i].generating = false;
                        }
                    }
                    setItem(newItem);
                } else {
                    setEdit(false);
                    setSuccess(true);
                    if (res.action === ActionType.DELETE) {
                        alert({ message: 'Deleted', type: 'success' });
                        setItem({});
                    } else {
                        alert({ message: 'Saved', type: 'success' });
                        var newData = correctDate(res.data);
                        setDefaultItem(JSON.parse(JSON.stringify(newData)));
                        setItem(newData);
                    }
                }
                dispatch(queryclear());
            } else if (res.isFailed) {
                var newItem: any = { ...item };
                if (newItem.course) {
                    for (var i = 0; i < newItem.course.length; i++) {
                        newItem.course[i].generating = false;
                    }
                }

                setItem(newItem);
                if (res.data.success !== undefined) {
                    if ((res.data.contact === null) || (res.data.contact === undefined)) res.data.contact = [];
                    var newDataFailed = correctDate(res.data);
                    setItem(newDataFailed);
                    alert({ message: res.data.popup ?? 'Error', type: 'error' });
                    window.scrollTo(0, 0);
                }
                setError(res.data.message);
                setLoading(false);
                setUpdating(false);
                dispatch(queryclear());
            }
        });
        return unsubscribe;
    }, [item, defaultItem])

    useEffect(() => {
        setItem({ projectId: null, nextStep: false, fromDate: new Date(), toDate: new Date(), classStepType: ClassStepType.STEP, course: [] }); //reset
        setDefaultItem({ projectId: null, nextStep: false, fromDate: new Date(), toDate: new Date(), classStepType: ClassStepType.STEP, course: [] });

        dispatch(queryclear());
        dispatch(listproject());
        dispatch(listtrainer());
        if (id) {
            dispatch(listcourse(null, null, Number(id)));
        } else {
            dispatch(listcourse(null, null, null));
        }

        dispatch(listallsurvey());
        window.scroll(0, 0);
        if (id != null) {
            dispatch(getclass(id));
        } else {
            setInitClass(1);
        }
    }, []);

    const checkAssignment = () => {
        var courseList = (item.course === undefined) ? [] : item.course;
        for (var i = 0; i < courseList.length; i++) {
            var c = courseList[i];
            if ((c.isAssignment !== null) && (c.isAssignment !== undefined) && (c.isAssignment === true)) return true;
        }
        return false;
    }

    const checkStudentList = () => {
        return (item.studentType === StudentType.LIST);
    }

    const setProject = (e: string) => {
        var newItem: any = { ...item };
        newItem.projectId = parseInt(e);
        newItem.session = '';
        projectItem.forEach(projectitem => {
            if (projectitem.id === parseInt(e)) {
                newItem.session = projectitem.nextSession;
                newItem.organizer = projectitem.organizer;
            }
        });
        setItem(newItem);
        setEdit(true);
    }

    const getProject = () => {
        for (var i = 0; i < projectItem.length; i++) {
            if (projectItem[i].id === item.projectId) {
                return { label: projectItem[i].code + " : " + projectItem[i].name, value: item.projectId };
            }
        }
        return null;
    }

    const getErrorProject = () => {
        return item.errorProjectId;
    }

    const createProjectSearchFilter = () => {
        var res: any[] = [];
        for (var i = 0; i < projectItem.length; i++) {
            res.push({ label: projectItem[i].code + " : " + projectItem[i].name, value: projectItem[i].id });
        }
        return res;
    }

    const getErrorPassedStep = () => {
        return item.errorPassedStep;
    }
    const setPassedStep = (e: string) => {
        var newItem: any = { ...item };
        newItem.passedStep = parseInt(e);
        setItem(newItem);
        setEdit(true);
    }
    const getPassedStep = () => {
        for (var i = 0; i < 20; i++) {
            if (i == item.passedStep - 1) {
                return { label: String(i + 1), value: i + 1 };
            }
        }
        return null;
    }
    const getPassedStepNameCourse = () => {
        if (item.course && item.course.length > 0) {
            for (let i = 0; i < item.course.length; i++) {
                if (i == item.passedStep - 1) {
                    let v: number = item.course[i].courseId;
                    for (let j = 0; j < courseItem.length; j++) {
                        if (courseItem[j].id === v)
                            return courseItem[j].shortName + ":" + courseItem[j].name;
                    }
                }
            }
            return null
        }

    }
    const createPassedStepSearchFilter = () => {
        var res: any[] = [];
        for (var i = 0; i < 20; i++) {
            res.push({ label: String(i + 1), value: i + 1 });
        }
        return res;
    }

    const setSession = (e: string) => {
        var newItem: any = { ...item };
        newItem.session = parseInt(e);
        if (isNaN(newItem.session)) newItem.session = null;
        setItem(newItem);
        setEdit(true);
    }

    const getSession = () => {
        return item.session;
    }

    const getErrorSession = () => {
        return item.errorSession;
    }

    const setFromDate = (e: Date) => {
        var newItem: any = { ...item };
        newItem.fromDate = e;
        setItem(newItem);
        setEdit(true);
    }

    const getFromDate = () => {
        return item.fromDate;
    }

    const getErrorFromDate = () => {
        return item.errorFromDate;
    }

    const setToDate = (e: Date) => {
        var newItem: any = { ...item };
        newItem.toDate = e;
        setItem(newItem);
        setEdit(true);
    }

    const getToDate = () => {
        return item.toDate;
    }

    const getErrorToDate = () => {
        return item.errorToDate;
    }

    const setClassStepType = (e: any) => {
        var newItem: any = { ...item };
        newItem.classStepType = e;
        setItem(newItem);
        setEdit(true);
    }

    const setTrainingType = (e: any) => {
        var newItem: any = { ...item };
        newItem.trainingType = e;
        setItem(newItem);
        setEdit(true);
    }
    const getErrorTrainingType = () => {
        return item.errorTrainingType;
    }

    const getClassStepType = () => {
        return item.classStepType;
    }

    const getTrainingType = () => {
        return item.trainingType;
    }

    const setIsSurveyBefore = (e: any) => {
        var newItem: any = { ...item };
        newItem.isBeforeSurvey = e;
        if (e === false) {
            newItem.beforeSurvey = null;
            newItem.beforeSurveys = [];
        }
        setItem(newItem);
        setEdit(true);
    }

    const getIsSurveyBefore = () => {
        if ((item.isBeforeSurvey === null) || (item.isBeforeSurvey === undefined)) return false;
        return item.isBeforeSurvey;
    }

    const setIsSurveyAfter = (e: any) => {
        var newItem: any = { ...item };
        newItem.isAfterSurvey = e;
        if (e === false) {
            newItem.afterSurvey = null;
            newItem.afterSurveys = [];
        }
        setItem(newItem);
        setEdit(true);
    }

    const getIsSurveyAfter = () => {
        if ((item.isAfterSurvey === null) || (item.isAfterSurvey === undefined)) return false;
        return item.isAfterSurvey;
    }

    const setSurveyBefore = (e: any) => {
        var newItem: any = { ...item };
        //newItem.beforeSurvey = e.value;
        if (newItem.beforeSurveys == null) newItem.beforeSurveys = [];
        if (newItem.beforeSurveys.some((x: any) => x.value == e.value)) {
            alert({ message: 'Error duplicate survey', type: 'error' });
            return;
        }
        newItem.beforeSurveys.push(e);
        setItem(newItem);
        setEdit(true);
    }

    const getSurveyBefore = () => {
        if ((item.beforeSurvey === null) || (item.beforeSurvey === undefined)) return null;
        for (var i = 0; i < surveyItem.length; i++) {
            if (surveyItem[i].id === item.beforeSurvey) {
                return { label: surveyItem[i].nickname, value: item.beforeSurvey };
            }
        }
        return null;
    }

    const getErrorSurveyBefore = () => {
        return (item.errorBeforeSurvey === undefined) ? null : item.errorBeforeSurvey;
    }

    const handleDeleteBeforeSurvey = (index: number) => {
        if (index > -1) {
            var newItem: any = { ...item };
            newItem.beforeSurveys.splice(index, 1);
            setItem(newItem);
            setEdit(true);
        }
    }

    const handleDeleteAfterSurvey = (index: number) => {
        if (index > -1) {
            var newItem: any = { ...item };
            newItem.afterSurveys.splice(index, 1);
            setItem(newItem);
            setEdit(true);
        }
    }

    const handleDeleteSurvey = (index: number, courseIndex: number) => {
        if (index > -1) {
            var newItem: any = { ...item };
            newItem.course[courseIndex].surveys.splice(index, 1);
            setItem(newItem);
            setEdit(true);
        }
    }

    const setSurveyAfter = (e: any) => {
        var newItem: any = { ...item };
        //newItem.afterSurvey = e.value;
        if (newItem.afterSurveys == null) newItem.afterSurveys = [];
        if (newItem.afterSurveys.some((x: any) => x.value == e.value)) {
            alert({ message: 'Error duplicate survey', type: 'error' });
            return;
        }
        newItem.afterSurveys.push(e);
        setItem(newItem);
        setEdit(true);
    }

    const getSurveyAfter = () => {
        if ((item.afterSurvey === null) || (item.afterSurvey === undefined)) return null;
        for (var i = 0; i < surveyItem.length; i++) {
            if (surveyItem[i].id === item.afterSurvey) {
                return { label: surveyItem[i].nickname, value: item.afterSurvey };
            }
        }
        return null;
    }



    const getErrorSurveyAfter = () => {
        return (item.errorAfterSurvey === undefined) ? null : item.errorAfterSurvey;
    }

    const setIsClassBanner = (e: any) => {
        var newItem: any = { ...item };
        newItem.isClassBanner = e;
        setItem(newItem);
        setEdit(true);
    }

    const getIsClassBanner = () => {
        if ((item.isClassBanner === null) || (item.isClassBanner === undefined)) return false;
        return item.isClassBanner;
    }

    const errorClassBannerUpload = () => {
        if (item.errorClassBanner === undefined) return null;
        return item.errorClassBanner;
    }

    const validateClassBanner = () => {
        var newItem: any = { ...item };
        var currentFile = newItem.classBannerFile;
        if (currentFile === undefined) {
            newItem.errorClassBanner = REQUIRE_LABEL;
            setItem(newItem);
            return false;
        }
        if (newItem.id === undefined) {
            newItem.errorClassBanner = PLEASE_SAVE_BEFORE_UPLOAD;
            setItem(newItem);
            return false;
        }
        if (currentFile[0].size > MAX_FILE_UPLOAD * 1000000) {
            newItem.errorClassBanner = MAX_UPLOAD_FILE_SIZE_LABEL;
            setItem(newItem);
            return false;
        }
        return true;
    }

    const handleClassBannerUploadChange = (selectorFiles: FileList | null) => {
        var newItem: any = { ...item };
        newItem.classBannerFile = selectorFiles;
        setItem(newItem);
        setEdit(true);
    }

    const handleClassBannerUpload = () => {
        if (validateClassBanner()) {
            var c = item;
            dispatch(queryclear());
            dispatch(uploadclassbanner(c.id, c.classBannerFile));
            var newItem = { ...item };
            newItem.loadingClassBanner = true;
            newItem.errorClassBanner = undefined;
            newItem.classBannerFile = undefined;
            setItem(newItem);
        }
    }

    const getLoadingClassBanner = () => {
        if (item.loadingClassBanner === undefined) return false;
        return item.loadingClassBanner;
    }


    /**** Course ********/
    const setIsClassCourseBanner = (i: number, e: any) => {
        var newItem: any = { ...item };
        newItem.course[i].isClassCourseBanner = e;
        setItem(newItem);
        setEdit(true);
    }

    const getIsClassCourseBanner = (i: number) => {
        if ((item.course[i].isClassCourseBanner === null) || (item.course[i].isClassCourseBanner === undefined)) return false;
        return item.course[i].isClassCourseBanner;
    }

    const getBannerUrl = (i: number) => {
        if ((item.course[i].bannerUrl === null) || (item.course[i].bannerUrl === undefined)) return null;
        return item.course[i].bannerUrl;
    }
    const getBannerDescription = (i: number) => {
        if ((item.course[i].bannerDescription === null) || (item.course[i].bannerDescription === undefined)) return null;
        return item.course[i].bannerDescription;
    }

    const setBannerUrl = (id: number, e: any) => {
        var newItem: any = { ...item };
        newItem.course[id].bannerUrl = e;
        setItem(newItem);
        setEdit(true);
    }
    const setBannerDescription = (id: number, e: any) => {
        var newItem: any = { ...item };
        newItem.course[id].bannerDescription = e;
        setItem(newItem);
        setEdit(true);
    }

    const errorClassCourseBannerUpload = (i: number) => {
        if (item.course[i].errorClassCourseBanner === undefined) return null;
        return item.course[i].errorClassCourseBanner;
    }

    const handleClassCourseBannerUploadChange = (i: number, selectorFiles: FileList | null) => {
        var newItem: any = { ...item };
        newItem.course[i].classCourseBannerFile = selectorFiles;
        setItem(newItem);
        setEdit(true);
    }

    const validateClassCourseBanner = (i: number) => {
        var newItem: any = { ...item };
        var currentFile = newItem.course[i].classCourseBannerFile;
        if (currentFile === undefined) {
            newItem.course[i].errorClassCourseBanner = REQUIRE_LABEL;
            setItem(newItem);
            return false;
        }
        if (newItem.course[i].id === undefined) {
            newItem.course[i].errorClassCourseBanner = PLEASE_SAVE_BEFORE_UPLOAD;
            setItem(newItem);
            return false;
        }
        if (currentFile[0].size > MAX_FILE_UPLOAD * 1000000) {
            newItem.course[i].errorClassCourseBanner = MAX_UPLOAD_FILE_SIZE_LABEL;
            setItem(newItem);
            return false;
        }
        return true;
    }

    const handleClassCourseBannerUpload = (i: number) => {
        if (validateClassCourseBanner(i)) {
            var c = item.course[i];
            dispatch(queryclear());
            dispatch(uploadclasscoursebanner(c.id, c.classCourseBannerFile));
            var newItem = { ...item };
            newItem.course[i].loadingClassCourseBanner = true;
            newItem.course[i].errorClassCourseBanner = undefined;
            newItem.course[i].classCourseBannerFile = undefined;
            setItem(newItem);
        }
    }

    const getLoadingClassCourseBanner = (i: number) => {
        if (item.course[i].loadingClassCourseBanner === undefined) return false;
        return item.course[i].loadingClassCourseBanner;
    }

    const setIsCourseSurvey = (id: number, e: any) => {
        var newItem: any = { ...item };
        newItem.course[id].isSurvey = e;
        if (e === false) {
            newItem.course[id].survey = null;
            newItem.course[id].surveys = [];
        }
        setItem(newItem);
        setEdit(true);
    }

    const getIsCourseSurvey = (id: number) => {
        var c = item.course[id];
        if ((c.isSurvey === null) || (c.isSurvey === undefined)) return false;
        return c.isSurvey;
    }

    const setCourseSurvey = (id: number, e: any) => {
        var newItem: any = { ...item };
        if (newItem.course[id].surveys == null) newItem.course[id].surveys = [];
        if (newItem.course[id].surveys.some((x: any) => x.value == e.value)) {
            alert({ message: 'Error duplicate survey', type: 'error' });
            return;
        }
        newItem.course[id].surveys.push(e);
        setItem(newItem);
        setEdit(true);
    }

    const setIsTest = (id: number, e: any) => {
        var newItem: any = { ...item };
        newItem.course[id].isTest = e;
        setItem(newItem);
        setEdit(true);
    }

    const getIsTest = (id: number) => {
        var c = item.course[id];
        if ((c.isTest === null) || (c.isTest === undefined)) return false;
        return c.isTest;
    }

    const setIsAssignment = (id: number, e: any) => {
        var newItem: any = { ...item };
        newItem.course[id].isAssignment = e;
        setItem(newItem);
        setEdit(true);
    }

    const getIsAssignment = (id: number) => {
        var c = item.course[id];
        if ((c.isAssignment === null) || (c.isAssignment === undefined)) return false;
        return c.isAssignment;
    }

    const setIsCer = (id: number, e: any) => {
        var newItem: any = { ...item };
        newItem.course[id].isCer = e;
        setItem(newItem);
        setEdit(true);
    }

    const getIsCer = (id: number) => {
        var c = item.course[id];
        if ((c.isCer === null) || (c.isCer === undefined)) return false;
        return c.isCer;
    }

    const setIsSeparate = (id: number, e: any) => {
        var newItem: any = { ...item };
        newItem.course[id].isSeparate = e;
        if (!e) newItem.course[id].separateNum = null;
        setItem(newItem);
        setEdit(true);
    }

    const isSeparateDisable = (id: number) => {
        if ((item.course[id].session === undefined) || (item.course[id].session.length !== 1)) return true;
        if ((item.course[id].session[0].trainer === undefined) || (item.course[id].session[0].trainer.length === 0)) return true;
        return false;
    }

    const getIsSeparate = (id: number) => {
        var c = item.course[id];
        if (isSeparateDisable(id)) return false;

        if ((c.isSeparate === null) || (c.isSeparate === undefined)) return false;
        return c.isSeparate;
    }

    const setIsSeparateByTrainer = (id: number, e: any) => {
        var newItem: any = { ...item };
        newItem.course[id].separateByTrainer = e;
        setItem(newItem);
        setEdit(true);
    }

    const getIsSeparateByTrainer = (id: number) => {
        var c = item.course[id];
        return c.separateByTrainer;
    }

    const setIsSeparateByAssistant = (id: number, e: any) => {
        var newItem: any = { ...item };
        newItem.course[id].separateByAssistant = e;
        setItem(newItem);
        setEdit(true);
    }

    const getIsSeparateByAssistant = (id: number) => {
        var c = item.course[id];
        return c.separateByAssistant;
    }

    const getGeneratingSeparateGroup = (i: number) => {
        if (item.course[i].generating === undefined) return false;
        return item.course[i].generating;
    }

    const handleGeneratingSeparateGroup = (i: number) => {
        var newItem: any = { ...item };
        newItem.course[i].generating = true;
        setItem(newItem);
        dispatch(queryclear);
        dispatch(generateseparategroup(item.course[i].id));
    }

    const isAbleGenerating = (i: number) => {
        if ((item.course[i].id !== undefined) && (item.course[i].id !== null)) return true;
        return false;
    }

    const getSeparateNum = (id: number) => {
        var c = item.course[id];
        var num = 0;
        if (getIsSeparate(id)) {
            for (var s = 0; s < c.session.length; s++) {
                if ((c.separateByTrainer) && (c.session[s].trainer !== undefined)) num = num + c.session[s].trainer.length;
                if ((c.separateByAssistant) && (c.session[s].assistant !== undefined)) num = num + c.session[s].assistant.length;
            }
        }
        return num;
    }

    const getErrorSeparate = (id: number) => {
        return (item.course[id].errorSeparate === undefined) ? null : item.course[id].errorSeparate;
    }

    const setIsRule = (id: number, e: any) => {
        var newItem: any = { ...item };
        newItem.course[id].isRule = e;
        setItem(newItem);
        setEdit(true);
    }

    const getIsRule = (id: number) => {
        var c = item.course[id];
        if ((c.isRule === null) || (c.isRule === undefined)) return false;
        return c.isRule;
    }

    const setRulePass = (id: number, e: any) => {
        var newItem: any = { ...item };
        newItem.course[id].isRulePass = e;
        setItem(newItem);
        setEdit(true);
    }

    const getRulePass = (id: number) => {
        var c = item.course[id];
        if ((c.isRulePass === null) || (c.isRulePass === undefined)) return false;
        return c.isRulePass;
    }

    const setRuleCheckIn = (id: number, e: any) => {
        var newItem: any = { ...item };
        newItem.course[id].isRuleCheckIn = e;
        setItem(newItem);
        setEdit(true);
    }

    const getRuleCheckIn = (id: number) => {
        var c = item.course[id];
        if ((c.isRuleCheckIn === null) || (c.isRuleCheckIn === undefined)) return false;
        return c.isRuleCheckIn;
    }

    const setRuleCheckOut = (id: number, e: any) => {
        var newItem: any = { ...item };
        newItem.course[id].isRuleCheckOut = e;
        setItem(newItem);
        setEdit(true);
    }

    const getRuleCheckOut = (id: number) => {
        var c = item.course[id];
        if ((c.isRuleCheckOut === null) || (c.isRuleCheckOut === undefined)) return false;
        return c.isRuleCheckOut;
    }

    const getCourseSurvey = (id: number) => {
        // var c = item.course[id];
        // if ((c.survey === null) || (c.survey === undefined)) return null;
        // for (var i = 0; i < surveyItem.length; i++) {
        //     if (surveyItem[i].id === c.survey) {
        //         return { label: surveyItem[i].name, value: item.afterSurvey };
        //     }
        // }
        return null;
    }

    const getErrorCourseSurvey = (id: number) => {
        return (item.course[id].errorSurvey === undefined) ? null : item.course[id].errorSurvey;
    }

    const getErrorBannerUrl = (id: number) => {
        if(item.course[id].bannerUrl){
            var expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
            var urlPattern = new RegExp(expression);
            return urlPattern.test(item.course[id].bannerUrl) ? null : "Banner Url is not correct"   
        }
        return null
        
    }

    const getErrorChangeSurvey = (id: number) => {
        return (item.course[id].errorChangeSurvey === undefined) ? null : item.course[id].errorChangeSurvey;
    }

    const getCourseShow = (id: number) => {
        return item.course[id].show;
    }

    const getErrorCourse = () => {
        return item.errorCourse;
    }

    const setCourseType = (id: number, v: CourseType) => {
        var newItem: any = { ...item };
        newItem.course[id].courseType = v;
        newItem.course[id].courseId = null;
        setItem(newItem);
        setEdit(true);
    }

    const getErrorCourseType = (id: number) => {
        return item.course[id].errorCourseType;
    }

    const setELearningType = (id: number, v: ELearningType) => {
        var newItem: any = { ...item };
        newItem.course[id].elearningType = v;
        setItem(newItem);
        setEdit(true);
    }

    const getELearningType = (id: number) => {
        return item.course[id].elearningType;
    }

    const getErrorELearningType = (id: number) => {
        return item.course[id].errorElearningType;
    }

    const setActivity = (id: number, v: string) => {
        var newItem: any = { ...item };
        newItem.course[id].activityType = v;
        setItem(newItem);
        setEdit(true);
    }

    const getActivity = (id: number) => {
        return item.course[id].activityType;
    }

    const getErrorActivity = (id: number) => {
        return item.course[id].errorActivityType;
    }

    const setCourseCourseId = (id: number, v: number) => {
        var newItem: any = { ...item };
        newItem.course[id].courseId = v;
        setItem(newItem);
        setEdit(true);
    }

    const getCourseCourseId = (id: number) => {
        return item.course[id].courseId;
    }

    function isDateLessThanEqualToday(givenDate: Date) {
        var today = new Date();
        today.setHours(9, 0, 0, 0);
        return today >= givenDate;
    }

    const setCourseCourseIdSearch = (id: number, e: SingleValue<{ label: string, value: number }>) => {
        var newItem: any = { ...item };
        newItem.course[id].courseId = e?.value;
        //to do course Item
        let dataCourseItem: CourseResponse = courseItem.find(x => x.id === e?.value);
        if (dataCourseItem && (newItem.course[id].session == null || newItem.course[id].session.length == 0)) {

            if (dataCourseItem.courseType == CourseType.ONLINE || dataCourseItem.courseType == CourseType.ONSITE || dataCourseItem.courseType == CourseType.HYBRID) {
                if (newItem.course[id].session == null) newItem.course[id].session = [];
                let newSeesionDate: Date = dataCourseItem.setRegistStartDate ? new Date(dataCourseItem.setRegistStartDate) : new Date()
                let newSession = {
                    date: newSeesionDate,
                    fromTime: dataCourseItem.setRegistStartTime ? Util.convertTimetoDateString(newSeesionDate, dataCourseItem.setRegistStartTime) : new Date(newSeesionDate.setHours(9, 0, 0, 0)),
                    toTime: dataCourseItem.setRegistEndTime ? Util.convertTimetoDateString(newSeesionDate, dataCourseItem.setRegistEndTime) : new Date(newSeesionDate.setHours(11, 0, 0, 0)),
                    place: dataCourseItem.setRegistVenue ?? null,
                    onlineType: OnlineType.MS_TEAM,
                    trainer: [],
                    assistant: []
                }
                newItem.course[id].session.push(newSession);
            }

        } else if (dataCourseItem) {
            if (dataCourseItem.courseType == CourseType.ONLINE || dataCourseItem.courseType == CourseType.ONSITE || dataCourseItem.courseType == CourseType.HYBRID) {
                let newSeesionDate: Date = dataCourseItem.setRegistStartDate ? new Date(dataCourseItem.setRegistStartDate) : new Date()
                for (let i = 0; i < newItem.course[id].session.length; i++) {
                    if (i == 0) {
                        newItem.course[id].session[i].date = newSeesionDate;
                        newItem.course[id].session[i].fromTime = dataCourseItem.setRegistStartTime ? Util.convertTimetoDateString(newSeesionDate, dataCourseItem.setRegistStartTime) : new Date(newSeesionDate.setHours(9, 0, 0, 0));
                        newItem.course[id].session[i].toTime = dataCourseItem.setRegistEndTime ? Util.convertTimetoDateString(newSeesionDate, dataCourseItem.setRegistEndTime) : new Date(newSeesionDate.setHours(11, 0, 0, 0));
                        newItem.course[id].session[i].place = dataCourseItem.setRegistVenue ?? null;
                    }
                }
            }
            console.log(newItem);
        }
        setItem(newItem);
        setEdit(true);
    }

    const getCourseCourseIdSearch = (id: number) => {
        var v: number = item.course[id].courseId;
        for (var i = 0; i < courseItem.length; i++) {
            if (courseItem[i].id === v)
                return { label: courseItem[i].shortName + ":" + courseItem[i].name, value: v };
        }
        return { label: v, value: v };
    }


    const getErrorCourseCourseId = (id: number) => {
        return item.course[id].errorCourseId;
    }

    const setCourseFromDate = (id: number, v: Date) => {
        var newItem: any = { ...item };
        newItem.course[id].fromDate = v;
        setItem(newItem);
        setEdit(true);
    }

    const getCourseFromDate = (id: number) => {
        return item.course[id].fromDate;
    }

    const getErrorCourseFromDate = (id: number) => {
        return item.course[id].errorFromDate;
    }

    const setCourseToDate = (id: number, v: Date) => {
        var newItem: any = { ...item };
        newItem.course[id].toDate = v;
        setItem(newItem);
        setEdit(true);
    }

    const getCourseToDate = (id: number) => {
        return item.course[id].toDate;
    }

    const getErrorCourseToDate = (id: number) => {
        return item.course[id].errorToDate;
    }

    /*** Session  ****/
    function getSessionLessDate(id: number) {
        if (item.course[id].session && item.course[id].session.length > 0) {
            var lessDate = item.course[id].session[0].date;
            for (var i = 0; i < item.course[id].session.length; i++) {
                if (item.course[id].session[i].date < lessDate) {
                    lessDate = item.course[id].session[i].date;
                }
            }
            return lessDate;
        } else {
            const currentDate = new Date();
            return currentDate.setDate(currentDate.getDate() + 1);
        }
    }

    const getErrorCourseSession = (id: number) => {
        return item.course[id].errorSession;
    }

    const setSessionDate = (id: number, sid: number, v: Date) => {
        var newItem: any = { ...item };
        newItem.course[id].session[sid].date = v;
        setItem(newItem);
        setEdit(true);
    }

    const getSessionDate = (id: number, sid: number) => {
        return item.course[id].session[sid].date;
    }

    const getErrorSessionDate = (id: number, sid: number) => {
        return item.course[id].session[sid].errorDate;
    }

    const setSessionFromTime = (id: number, sid: number, v: Date) => {
        var newItem: any = { ...item };
        newItem.course[id].session[sid].fromTime = v;
        setItem(newItem);
        setEdit(true);
    }

    const getSessionFromTime = (id: number, sid: number) => {
        return item.course[id].session[sid].fromTime;
    }

    const getErrorSessionFromTime = (id: number, sid: number) => {
        return item.course[id].session[sid].errorFromTime;
    }

    const setSessionToTime = (id: number, sid: number, v: Date) => {
        var newItem: any = { ...item };
        newItem.course[id].session[sid].toTime = v;
        setItem(newItem);
        setEdit(true);
    }

    const getSessionToTime = (id: number, sid: number) => {
        return item.course[id].session[sid].toTime;
    }

    const getErrorSessionToTime = (id: number, sid: number) => {
        return item.course[id].session[sid].errorToTime;
    }

    const setSessionPlace = (id: number, sid: number, place: string) => {
        var newItem: any = { ...item };
        newItem.course[id].session[sid].place = place;
        setItem(newItem);
        setEdit(true);
    }

    const getSessionPlace = (id: number, sid: number) => {
        return item.course[id].session[sid].place;
    }

    const getErrorSessionPlace = (id: number, sid: number) => {
        return item.course[id].session[sid].errorPlace;
    }

    const setSessionOnline = (id: number, sid: number, online: OnlineType) => {
        var newItem: any = { ...item };
        newItem.course[id].session[sid].onlineType = online;
        setItem(newItem);
        setEdit(true);
    }

    const getSessionOnline = (id: number, sid: number) => {
        return item.course[id].session[sid].onlineType;
    }

    const getErrorSessionOnline = (id: number, sid: number) => {
        return item.course[id].session[sid].errorOnlineType;
    }

    /** Trainer ***/
    const getErrorSessionTrainer = (id: number, sid: number) => {
        return item.course[id].session[sid].errorTrainer;
    }

    const getSessionTrainer = (id: number, sid: number, tid: number, trainerOrAssistant: boolean) => {
        return (trainerOrAssistant) ? item.course[id].session[sid].trainer[tid].trainerId : item.course[id].session[sid].assistant[tid].trainerId;
    }

    const setSessionTrainer = (id: number, sid: number, tid: number, v: string, trainerOrAssistant: boolean) => {
        var newItem: any = { ...item };
        if (trainerOrAssistant) newItem.course[id].session[sid].trainer[tid].trainerId = parseInt(v);
        else newItem.course[id].session[sid].assistant[tid].trainerId = parseInt(v);
        setItem(newItem);
        setEdit(true);
    }

    const getSessionTrainerSearch = (id: number, sid: number, tid: number, trainerOrAssistant: boolean) => {
        var v: number = (trainerOrAssistant) ? item.course[id].session[sid].trainer[tid].trainerId : item.course[id].session[sid].assistant[tid].trainerId;
        for (var i = 0; i < trainerItem.length; i++) {
            if (trainerItem[i].id === v)
                return { label: trainerItem[i].firstName + " " + trainerItem[i].lastName, value: v };
        }
        return { label: v, value: v };
    }

    const setSessionTrainerSearch = (id: number, sid: number, tid: number, e: SingleValue<{ label: string, value: number }>, trainerOrAssistant: boolean) => {
        var newItem: any = { ...item };
        if (trainerOrAssistant) {
            newItem.course[id].session[sid].trainer[tid].trainerId = e?.value;
        } else {
            newItem.course[id].session[sid].assistant[tid].trainerId = e?.value;
        }
        setItem(newItem);
        setEdit(true);
    }

    const getErrorSessionTrainerId = (id: number, sid: number, t: number, trainerOrAssistant: boolean) => {
        if (trainerOrAssistant) {
            if (item.course[id].session[sid].trainer !== undefined) {
                return item.course[id].session[sid].trainer[t].errorTrainerId;
            }
        } else {
            if (item.course[id].session[sid].assistant !== undefined) {
                return item.course[id].session[sid].assistant[t].errorTrainerId;
            }
        }
        return null;
    }

    const validateTab = () => {
        return (item.nextStep !== undefined) && (item.nextStep = true) && (item.id !== undefined);
    }

    const clear = () => {
        var newItem = correctDate(JSON.parse(JSON.stringify(defaultItem)));
        setItem(newItem);
        setEdit(false);
    }

    const handleClear = () => {
        if (edit) setLeave("clear");
    }

    const handleSubmit = () => {
        dispatch(queryclear());
        if (id == null) dispatch(createclass(item)); else dispatch(updateclass(parseInt(id), item));
    }

    const handleClose = () => {
        history.push("/admin/search/class");
    }

    const handleBack = () => {
        if (edit) setLeave("/admin/search/class");
        else history.push("/admin/search/class");
    }

    const handleAddCourse = () => {
        var newItem = { ...item };
        if (newItem.course == null) newItem.course = [];
        newItem.course.push({ courseType: CourseType.ONLINE, courseId: null, fromDate: new Date(), toDate: new Date(), activityType: ActivityType.ARTICLE, elearningType: ELearningType.SUPER_USER });
        setItem(newItem);
        setEdit(true);
    }

    const handleRow = (i: number) => {
        var newItem = { ...item };
        newItem.course[i].show = !getCourseShow(i);
        setItem(newItem);
    }

    const handleDeleteCourse = (id: number) => {
        var newItem = { ...item };
        //correct id
        var newCourse: any[] = [];
        var k = 0;
        for (var i = 0; i < newItem.course.length; i++) {
            if (i < id) { newCourse.push({ ...newItem.course[i] }); k++; }
            else if (i > id) {
                var c = { ...newItem.course[i] };
                c.priority = k;
                newCourse.push(c);
                k++;
            }
        }
        newItem.course = newCourse;
        setItem(newItem);
        setEdit(true);
    }

    const handleMoveUpCourse = (id: number) => {
        if (id === 0) return;
        var newItem = { ...item };
        if (newItem.course == null) newItem.course = [];
        var temp = { ...newItem.course[id - 1] };
        newItem.course[id - 1] = newItem.course[id];
        newItem.course[id] = temp;
        setItem(newItem);
        setEdit(true);
    }

    const handleMoveDownCourse = (id: number) => {
        if (id === item.course.length - 1) return;
        var newItem = { ...item };
        if (newItem.course == null) newItem.course = [];
        var temp = { ...newItem.course[id + 1] };
        newItem.course[id + 1] = newItem.course[id];
        newItem.course[id] = temp;
        setItem(newItem);
        setEdit(true);
    }

    const handleAddSession = (id: number) => {
        var newItem = { ...item };
        if (newItem.course == null) return;
        if (newItem.course[id].session == null) newItem.course[id].session = [];
        newItem.course[id].session.push({ date: new Date(), fromTime: new Date(new Date().setHours(9, 0, 0, 0)), toTime: new Date(new Date().setHours(11, 0, 0, 0)), onlineType: OnlineType.MS_TEAM, trainer: [], assistant: [] });
        setItem(newItem);
        setEdit(true);
    }

    const handleDeleteSession = (id: number, sid: number) => {
        var newItem = { ...item };
        if (newItem.course == null) return;
        //correct id
        var newSession: any[] = [];
        var k = 0;
        for (var i = 0; i < newItem.course[id].session.length; i++) {
            if (i < sid) { newSession.push({ ...newItem.course[id].session[i] }); k++; }
            else if (i > sid) {
                var c = { ...newItem.course[id].session[i] };
                c.priority = k;
                newSession.push(c);
                k++;
            }
        }
        newItem.course[id].session = newSession;
        setItem(newItem);
        setEdit(true);
    }

    const handleAddSessionTrainer = (id: number, sid: number, trainerOrAssistant: boolean) => {
        var newItem = { ...item };
        if (newItem.course == null) return;
        if (trainerOrAssistant) {
            if (newItem.course[id].session[sid].trainer == null) newItem.course[id].session[sid].trainer = [];
            newItem.course[id].session[sid].trainer.push({ trainerId: null, trainerType: TrainerType.TRAINER })
        } else {
            if (newItem.course[id].session[sid].assistant == null) newItem.course[id].session[sid].assistant = [];
            newItem.course[id].session[sid].assistant.push({ trainerId: null, trainerType: TrainerType.ASSISTANT })
        }
        setItem(newItem);
        setEdit(true);
    }

    const handleDeleteSessionTrainer = (id: number, sid: number, t: number, trainerOrAssistant: boolean) => {
        var newItem = { ...item };
        if (newItem.course == null) return;
        //correct id
        var newTrainer: any[] = [];
        var oldTrainer = (trainerOrAssistant) ? newItem.course[id].session[sid].trainer : newItem.course[id].session[sid].assistant;
        var k = 0;
        for (var i = 0; i < oldTrainer.length; i++) {
            if (i < t) { newTrainer.push({ ...oldTrainer[i] }); k++; }
            else if (i > t) {
                var c = { ...oldTrainer[i] };
                newTrainer.push(c);
                k++;
            }
        }
        if (trainerOrAssistant) {
            newItem.course[id].session[sid].trainer = newTrainer;
        } else {
            newItem.course[id].session[sid].assistant = newTrainer;
        }
        setItem(newItem);
        setEdit(true);
    }

    const handleStudent = () => {
        if (edit) setLeave("/admin/class/" + ((id === undefined) ? item.id : id) + "/student");
        else history.push("/admin/class/" + ((id === undefined) ? item.id : id) + "/student");
    }

    const handleDoc = () => {
        if (edit) setLeave("/admin/class/" + ((id === undefined) ? item.id : id) + "/doc");
        else history.push("/admin/class/" + ((id === undefined) ? item.id : id) + "/doc");
    }

    const handleHistory = () => {
        if (edit) setLeave("/admin/class/" + ((id === undefined) ? item.id : id) + "/history");
        else history.push("/admin/class/" + ((id === undefined) ? item.id : id) + "/history");
    }

    const handleAssignment = () => {
        if (edit) setLeave("/admin/class/" + ((id === undefined) ? item.id : id) + "/assignment");
        else history.push("/admin/class/" + ((id === undefined) ? item.id : id) + "/assignment");
    }

    const handleResult = () => {
        if (edit) setLeave("/admin/class/" + ((id === undefined) ? item.id : id) + "/result");
        else history.push("/admin/class/" + ((id === undefined) ? item.id : id) + "/result");
    }
    const handleSurvey = () => {
        if (edit) setLeave("/admin/class/" + ((id === undefined) ? item.id : id) + "/survey");
        else history.push("/admin/class/" + ((id === undefined) ? item.id : id) + "/survey");
    }

    const handleResultNumber = () => {
        if (edit) setLeave("/admin/class/" + ((id === undefined) ? item.id : id) + "/result-number");
        else history.push("/admin/class/" + ((id === undefined) ? item.id : id) + "/result-number");
    }


    const isElearning = (i: number) => {
        return (item.course[i].elearningImportUser);
    }

    const handleElearning = (i: number) => {
        var classcourseid: number = item.course[i].id;
        history.push("/admin/class/" + item.id + "/elearning/" + classcourseid);
    }

    const handleClassSurvey = (b: boolean, index: number) => {
        if (b) {
            window.open(PREVIEW_SURVEY_URL + "/" + (item.beforeSurveys[index].value) + "/class/" + (item.id) + "/page/before/preview");
        } else {
            window.open(PREVIEW_SURVEY_URL + "/" + (item.afterSurveys[index].value) + "/class/" + (item.id) + "/page/after/preview");
        }
    }

    const handleClassCourseSurvey = (i: number, surveyIndex: number) => {
        window.open(PREVIEW_SURVEY_URL + "/" + (item.course[i].surveys[surveyIndex].value) + "/class_course/" + item.course[i].id + "/preview");
    }

    const handleDelete = () => {
        setDeleteId(id.toString());
    }

    const handleDeleteClose = () => {
        setDeleteId("");
    }

    const handleDeleteConfirm = () => {
        dispatch(queryclear());
        dispatch(deleteclass(deleteId));
        setDeleteId("");
    }

    const handleReedit = () => {
        setSuccess(false);
    }

    const handleLeave = () => {
        if (leave === "clear") {
            clear();
        } else {
            history.push(leave);
        }
        setLeave("");
    }

    const correctDate = (data: any) => {
        data.fromDate = (data.fromDate != null) ? new Date(data.fromDate) : null;
        data.toDate = (data.toDate != null) ? new Date(data.toDate) : null;
        data.nextStep = false;
        for (var i = 0; i < ((data.course == null) ? 0 : data.course.length); i++) {
            if (data.course[i].id !== undefined) data.nextStep = true;
            if (data.course[i].fromDate != null) data.course[i].fromDate = new Date(data.course[i].fromDate);
            if (data.course[i].toDate != null) data.course[i].toDate = new Date(data.course[i].toDate);
            var c = data.course[i];
            for (var k = 0; k < ((c.session == null) ? 0 : c.session.length); k++) {
                if (c.session[k].date != null) c.session[k].date = new Date(c.session[k].date);
                if (c.session[k].fromTime != null) c.session[k].fromTime = new Date(c.session[k].fromTime);
                if (c.session[k].toTime != null) c.session[k].toTime = new Date(c.session[k].toTime);
            }
        }
        if ((data.id === undefined) || (data.id === null)) {
            data.nextStep = false;
        }
        return data;
    }

    const createOrganizer = () => {
        for (var i = 0; i < projectItem.length; i++) {
            if (projectItem[i].id === item.projectId) return projectItem[i].organizer;
        }
        return "";
    }

    const createSurveyFilter = (surveySelected?: any[]) => {
        var res: any[] = [];
        for (var i = 0; i < surveyItem.length; i++) {
            if ((surveyItem[i].permission === SurveyPermissionType.PUBLIC) ||
                ((surveyItem[i].permission === SurveyPermissionType.PROJECT) && (item.projectId === surveyItem[i].projectId)) ||
                ((surveyItem[i].permission === SurveyPermissionType.ORGANIZER) && (item.organizer === surveyItem[i].organizerId))) {
                res.push({ label: surveyItem[i].nickname, value: surveyItem[i].id });
            }
        }
        if (surveySelected) {
            if (surveySelected.length > 0) {
                res = res.filter(x => !surveySelected.some(y => y.value == x.value))
            }
        }
        return res;
    }

    const createCourseSearchFilter = (name: string, courseType: CourseType): { label: string, value: number }[] => {
        var res: any[] = [];
        for (var i = 0; i < courseItem.length; i++) {
            if (courseItem[i].courseType === courseType)
                res.push({ label: courseItem[i].shortName + ":" + courseItem[i].name, value: courseItem[i].id });
        }
        return res;
    }

    const createTrainerSearchFilter = (name: string, courseType: CourseType) => {
        var res: any[] = [];
        for (var i = 0; i < trainerItem.length; i++) {
            res.push({ label: trainerItem[i].firstName + " " + trainerItem[i].lastName, value: trainerItem[i].id });
        }
        return res;
    }

    const createSessionTime = (s: any) => {
        var start: Date = s.fromTime;
        var end: Date = s.toTime;
        if ((start == null) || (end == null) || (start.getHours() > end.getHours()) || (((start.getHours() === end.getHours())) && (start.getMinutes() === end.getMinutes()))) return "";
        var startMin: number = start.getHours() * 60 + start.getMinutes();
        var endMin: number = end.getHours() * 60 + end.getMinutes();
        var min = endMin - startMin;
        return Math.trunc(min / 60) + " ชั่วโมง" + ((min % 60 > 0) ? (" " + (min % 60) + " นาที") : "");
    }
    const clearCourse = () => {
        for (var i = 0; i < item.course.length; i++) {
            if (item.course[i].statusCourseActive == false) {
                item.course[i].courseId = null;
            }
        }
        setAlertModalShow(false);
    }

    const createTrainer = (i: number, sid: number, courseType: CourseType, trainerOrAssistant: boolean) => {
        var res = [];
        var s = item.course[i].session[sid];
        if (s.trainer == null) s.trainer = [];
        if (s.assistant == null) s.assistant = [];

        var t = (trainerOrAssistant) ? s.trainer : s.assistant;
        for (var k = 0; k < t.length; k++) {
            if (trainerOrAssistant) {
                res.push(<>
                    <div className="row">
                        <div className="col-10">
                            <InputGroup hasValidation>
                                <SingleSearch
                                    id={i + "_" + sid + "_" + k} isError={getErrorSessionTrainerId(i, sid, k, true)} disabled={success} isSearchable={true} isMulti={false} placeholder=""
                                    onChange={(id: string, e: any) => setSessionTrainerSearch(parseInt(id.split("_")[0]), parseInt(id.split("_")[1]), parseInt(id.split("_")[2]), e, true)}
                                    value={getSessionTrainerSearch(i, sid, k, true)}
                                    options={createTrainerSearchFilter("", courseType)}
                                    style={{ width: "300px" }}
                                />
                                <div className={(getErrorSessionTrainerId(i, sid, k, true) != null) ? "text-danger" : ""}><small>{getErrorSessionTrainerId(i, sid, k, true)}</small></div>
                            </InputGroup>
                        </div>
                        {(!success) &&
                            <div className="col-2" id={i + "_" + sid + "_" + k} style={{ paddingTop: "5px", marginLeft: "-10px" }} onClick={(e) => { handleDeleteSessionTrainer(parseInt(e.currentTarget.id.split("_")[0]), parseInt(e.currentTarget.id.split("_")[1]), parseInt(e.currentTarget.id.split("_")[2]), true) }}>
                                <RemoveCircleOutlineIcon className="red"></RemoveCircleOutlineIcon>
                            </div>
                        }
                    </div><div className='mb-2'></div></>);
            } else {
                res.push(<>
                    <div className="row">
                        <div className="col-10">
                            <InputGroup hasValidation>
                                <SingleSearch
                                    id={i + "_" + sid + "_" + k} isError={getErrorSessionTrainerId(i, sid, k, false)} disabled={success} isSearchable={true} isMulti={false} placeholder=""
                                    onChange={(id: string, e: any) => setSessionTrainerSearch(parseInt(id.split("_")[0]), parseInt(id.split("_")[1]), parseInt(id.split("_")[2]), e, false)}
                                    value={getSessionTrainerSearch(i, sid, k, false)}
                                    options={createTrainerSearchFilter("", courseType)}
                                    style={{ width: "300px" }}
                                />
                                <div className={(getErrorSessionTrainerId(i, sid, k, false) != null) ? "text-danger" : ""}><small>{getErrorSessionTrainerId(i, sid, k, false)}</small></div>
                            </InputGroup>
                        </div>
                        {(!success) &&
                            <div className="col-2" id={i + "_" + sid + "_" + k} style={{ paddingTop: "5px", marginLeft: "-10px" }} onClick={(e) => { handleDeleteSessionTrainer(parseInt(e.currentTarget.id.split("_")[0]), parseInt(e.currentTarget.id.split("_")[1]), parseInt(e.currentTarget.id.split("_")[2]), false) }}>
                                <RemoveCircleOutlineIcon className="red"></RemoveCircleOutlineIcon>
                            </div>
                        }
                    </div><br /></>);
            }
        }
        return res;
    }

    const createSession = (i: number, courseType: CourseType, c: any) => {
        var alls: any[] = [];
        if (c.session === undefined) return alls;
        for (var k = 0; k < c.session.length; k++) {
            var s = c.session[k];
            alls.push(<><div key={k} className="dark-gray-button" style={{ position: "relative", padding: "20px", textAlign: "left", borderRadius: "5px" }}>
                <div className="row">
                    <div className="col-3">
                        <label>วันที่</label><br />
                        <InputGroup hasValidation>
                            <label className='position-relative'>
                                <ThaiDateTimePicker className={"form-control " + ((getErrorSessionDate(i, k) != null) ? "is-invalid" : "")}
                                    id={i + "_" + k} selected={getSessionDate(i, k)} onChange={(e: any, id: string) => setSessionDate(parseInt(id.split("_")[0]), parseInt(id.split("_")[1]), e)} disabled={success} />
                                <div className="search-icon" style={{ visibility: (getErrorSessionDate(i, k) != null) ? "hidden" : "visible", position: "absolute", right: "10px", top: "5px", zIndex: 0 }}>
                                    <CalendarTodayIcon style={{ color: "gray" }} />
                                </div>
                            </label>
                            <div className={(getErrorSessionDate(i, k) != null) ? "text-danger" : ""}><small>{getErrorSessionDate(i, k)}</small></div>
                        </InputGroup>
                    </div>
                    <div className="col-3">
                        <label>เวลาเริ่ม</label><br />
                        <InputGroup hasValidation>
                            <label>
                                <ThaiTimePicker className={"form-control " + ((getErrorSessionFromTime(i, k) != null) ? "is-invalid" : "")}
                                    id={i + "_" + k} selected={getSessionFromTime(i, k)} onChange={(e: any, id: string) => setSessionFromTime(parseInt(id.split("_")[0]), parseInt(id.split("_")[1]), e)} disabled={success} />
                                <div className="search-icon" style={{ visibility: (getErrorSessionFromTime(i, k) != null) ? "hidden" : "visible", position: "absolute", right: "5px", top: "5px", zIndex: 0 }}>
                                    <ScheduleIcon style={{ color: "gray" }} />
                                </div>
                            </label>
                            <div className={(getErrorSessionFromTime(i, k) != null) ? "text-danger" : ""}><small>{getErrorSessionFromTime(i, k)}</small></div>
                        </InputGroup>
                    </div>
                    <div className="col-3">
                        <label>เวลาสิ้นสุด</label><br />
                        <InputGroup hasValidation>
                            <label>
                                <ThaiTimePicker className={"form-control " + ((getErrorSessionToTime(i, k) != null) ? "is-invalid" : "")}
                                    id={i + "_" + k} selected={getSessionToTime(i, k)} onChange={(e: any, id: string) => setSessionToTime(parseInt(id.split("_")[0]), parseInt(id.split("_")[1]), e)} disabled={success} />
                                <div className="search-icon" style={{ visibility: (getErrorSessionToTime(i, k) != null) ? "hidden" : "visible", position: "absolute", right: "5px", top: "5px", zIndex: 0 }}>
                                    <ScheduleIcon style={{ color: "gray" }} />
                                </div>
                            </label>
                            <div className={(getErrorSessionToTime(i, k) != null) ? "text-danger" : ""}><small>{getErrorSessionToTime(i, k)}</small></div>
                        </InputGroup>
                    </div>
                    <div className="col-3" style={{ textAlign: "center" }}>
                        <br />
                        <label>{createSessionTime(s)}</label>
                    </div>
                </div>
                {(courseType !== CourseType.ONLINE) &&
                    <div className="row" style={{ paddingTop: "10px" }}>
                        <div className="col-12">
                            <label>สถานที่จัด</label><br />
                            <InputGroup hasValidation>
                                <input type="text" maxLength={256} className={"form-control " + ((getErrorSessionPlace(i, k) != null) ? "is-invalid" : "")} id={i + "_" + k} value={getSessionPlace(i, k)} onChange={(e) => { setSessionPlace(parseInt(e.currentTarget.id.split("_")[0]), parseInt(e.currentTarget.id.split("_")[1]), e.target.value) }} disabled={success} /><br />
                                <div className={(getErrorSessionPlace(i, k) != null) ? "invalid-feedback" : ""}>{getErrorSessionPlace(i, k)}</div>
                            </InputGroup>
                        </div>
                    </div>
                }
                {(courseType === CourseType.ONLINE) &&
                    <div className="row" style={{ paddingTop: "10px" }}>
                        <div className="col-12">
                            <label>ระบบ</label><br />
                            <InputGroup hasValidation>
                                <select className={"form-control " + ((getErrorSessionOnline(i, k) != null) ? "is-invalid" : "")} id={i + "_" + k} value={getSessionOnline(i, k)} onChange={(e) => { setSessionOnline(parseInt(e.currentTarget.id.split("_")[0]), parseInt(e.currentTarget.id.split("_")[1]), e.target.value as OnlineType) }} disabled={success}>
                                    {
                                        createOnlineType()
                                    }
                                </select><br />
                                <div className={(getErrorSessionOnline(i, k) != null) ? "invalid-feedback" : ""}>{getErrorSessionOnline(i, k)}</div>
                            </InputGroup>
                        </div>
                    </div>
                }
                <div className="row" style={{ paddingTop: "10px" }}>
                    <div className="col-6">
                        <label>วิทยากรหลัก</label><br />
                        <div>
                            {createTrainer(i, k, courseType, true)}
                            <div className={(getErrorSessionTrainer(i, k) != null) ? "text-danger" : ""}><small>{getErrorSessionTrainer(i, k)}</small></div>
                            {(!success) &&
                                <div className="col-1" id={i + "_" + k} style={{ paddingTop: "0px", paddingLeft: "5px" }} onClick={(e) => { handleAddSessionTrainer(parseInt(e.currentTarget.id.split("_")[0]), parseInt(e.currentTarget.id.split("_")[1]), true) }}>
                                    <AddCircleOutlineIcon className="blue"></AddCircleOutlineIcon>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="col-6">
                        <label>วิทยากรผู้ช่วย</label><br />
                        <div>
                            {createTrainer(i, k, courseType, false)}
                            {(!success) &&
                                <div className="col-1" id={i + "_" + k} style={{ paddingTop: "0px", paddingLeft: "5px" }} onClick={(e) => { handleAddSessionTrainer(parseInt(e.currentTarget.id.split("_")[0]), parseInt(e.currentTarget.id.split("_")[1]), false) }}>
                                    <AddCircleOutlineIcon className="blue"></AddCircleOutlineIcon>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                {(!success) &&
                    <div className="search-icon" id={i.toString() + "_" + k.toString()} style={{ position: "absolute", right: "5px", top: "5px", zIndex: 10 }} onClick={e => { handleDeleteSession(parseInt(e.currentTarget.id.split("_")[0] as string), parseInt(e.currentTarget.id.split("_")[1] as string)) }}>
                        <RemoveCircleOutlineIcon className="red" ></RemoveCircleOutlineIcon>
                    </div>
                }
            </div><br /></>);
        }
        return alls;
    }

    const createELearningCourse = (i: number, c: any) => {
        const dateLessThanEqualToday = isDateLessThanEqualToday(getCourseFromDate(i));
        return (<>
            <div className="col-4">
                <div>
                    <label className="col-form-label">ประเภทการเรียน</label><br />
                    <InputGroup hasValidation>
                        <select id={i.toString()} className={"form-control " + ((getErrorCourseType(i) != null) ? "is-invalid" : "")} onChange={e => setCourseType(parseInt(e.currentTarget.id), e.target.value as CourseType)} value={CourseType.ELEARNING} disabled={success || dateLessThanEqualToday}>
                            {createCourseType()}
                        </select><br />
                        <div className={(getErrorCourseType(i) != null) ? "invalid-feedback" : ""}>{getErrorCourseType(i)}</div>
                    </InputGroup>
                </div>
            </div>
            <div className="col-3">
                <label className="col-form-label">รูปแบบการเข้าถึงของผู้เรียน</label><br />
                <InputGroup hasValidation>
                    <select className={"form-control " + ((getErrorELearningType(i) != null) ? "is-invalid" : "")} onChange={e => setELearningType(i, e.target.value as ELearningType)} value={getELearningType(i)} disabled={success}>
                        {createELearningType()}
                    </select><br />
                    <div className={(getErrorELearningType(i) != null) ? "text-danger" : ""}><small>{getErrorELearningType(i)}</small></div>
                </InputGroup>
            </div>
            <div className="col-2">
                <label className="col-form-label">วันที่เริ่ม</label><br />
                <InputGroup hasValidation>
                    <label className='position-relative'>
                        <ThaiDateTimePicker className={"form-control " + ((getErrorCourseFromDate(i) != null) ? "is-invalid" : "")} selected={getCourseFromDate(i)} onChange={(e: any) => setCourseFromDate(i, e)} disabled={success} />
                        <div className="search-icon" style={{ visibility: (getErrorCourseFromDate(i) != null) ? "hidden" : "visible", position: "absolute", right: "10px", top: "5px", zIndex: 0 }}>
                            <CalendarTodayIcon style={{ color: "gray" }} />
                        </div>
                    </label>
                    <div className={(getErrorCourseFromDate(i) != null) ? "text-danger" : ""}><small>{getErrorCourseFromDate(i)}</small></div>
                </InputGroup>
            </div>
            <div className="col-2">
                <label className="col-form-label">วันที่สิ้นสุด</label><br />
                <InputGroup hasValidation>
                    <label className='position-relative'>
                        <ThaiDateTimePicker className={"form-control " + ((getErrorCourseToDate(i) != null) ? "is-invalid" : "")} selected={getCourseToDate(i)} onChange={(e: any) => setCourseToDate(i, e)} disabled={success} />
                        <div className="search-icon" style={{ visibility: (getErrorCourseToDate(i) != null) ? "hidden" : "visible", position: "absolute", right: "10px", top: "5px", zIndex: 0 }}>
                            <CalendarTodayIcon style={{ color: "gray" }} />
                        </div>
                    </label>
                    <div className={(getErrorCourseToDate(i) != null) ? "text-danger" : ""}><small>{getErrorCourseToDate(i)}</small></div>
                </InputGroup>
            </div>
            <br />
            <div className="col-4">
                <label className="col-form-label">หลักสูตร/หัวข้อ</label><br />
                <InputGroup hasValidation>
                    <SingleSearch id={i} isError={getErrorCourseCourseId(i)} disabled={success || dateLessThanEqualToday} isSearchable={true} isMulti={false} placeholder=""
                        onChange={(id: number, e: any) => setCourseCourseIdSearch(id, e)}
                        value={getCourseCourseIdSearch(i)}
                        options={createCourseSearchFilter("", CourseType.ELEARNING)}
                        style={{ width: "400px" }}
                    />
                    {
                        /*<select id={i.toString()} className={"form-control "+((getErrorCourseCourseId(i)!=null)?"is-invalid":"")} onChange={e=>setCourseCourseId(parseInt(e.currentTarget.id),parseInt(e.target.value))} value={getCourseCourseId(i)} disabled={success}>
                       { createCourseFilter("",CourseType.ELEARNING) }
                       </select>*/
                    }
                    <div className={(getErrorCourseCourseId(i) != null) ? "text-danger" : ""}><small>{getErrorCourseCourseId(i)}</small></div>
                </InputGroup>
                <div className='m-2'><small>{getCourseCourseIdSearch(i).label}</small></div>
            </div>
            {(isElearning(i)) &&
                <div className="col-3">
                    <br />
                    <span className="link" onClick={e => { handleElearning(i) }}>Import ผู้เรียนไปที่ e-learning</span>
                </div>
            }
        </>);
    }

    const createOnsiteCourse = (i: number, c: any) => {
        if (c.session == null) c.session = [];
        var alls: any[] = createSession(i, CourseType.ONSITE, c);
        const dateLessThanEqaulToday = isDateLessThanEqualToday(getSessionLessDate(i));
        return (<>
            <div className="col-4">
                <div>
                    <label className="col-form-label">ประเภทการเรียน</label><br />
                    <InputGroup hasValidation>
                        <select id={i.toString()} className={"form-control " + ((getErrorCourseType(i) != null) ? "is-invalid" : "")} onChange={e => setCourseType(parseInt(e.currentTarget.id), e.target.value as CourseType)} value={CourseType.ONSITE} disabled={success || dateLessThanEqaulToday}>
                            {createCourseType()}
                        </select><br />
                        <div className={(getErrorCourseType(i) != null) ? "invalid-feedback" : ""}>{getErrorCourseType(i)}</div>
                    </InputGroup>
                </div>
                <div>
                    <label className="col-form-label">หลักสูตร/หัวข้อ</label><br />
                    <InputGroup hasValidation>
                        <SingleSearch id={i} isError={getErrorCourseCourseId(i)} disabled={success || dateLessThanEqaulToday} isSearchable={true} isMulti={false} placeholder=""
                            onChange={(id: number, e: any) => setCourseCourseIdSearch(id, e)}
                            value={getCourseCourseIdSearch(i)}
                            options={createCourseSearchFilter("", CourseType.ONSITE)}
                            style={{ width: "400px" }}
                        />
                        {
                            /*<select id={i.toString()} className={"form-control "+((getErrorCourseCourseId(i)!=null)?"is-invalid":"")} onChange={e=>setCourseCourseId(parseInt(e.currentTarget.id),parseInt(e.target.value))} value={getCourseCourseId(i)} disabled={success}>
                            { createCourseFilter("",CourseType.ONSITE) }
                            </select>*/
                        }
                        <div className={(getErrorCourseCourseId(i) != null) ? "text-danger" : ""}><small>{getErrorCourseCourseId(i)}</small></div>
                    </InputGroup>
                    <div className='m-2'><small>{getCourseCourseIdSearch(i).label}</small></div>
                </div>
            </div>
            <div className="col-8" style={{ position: "relative", paddingTop: "20px", justifyContent: "center", textAlign: "center" }}>
                {alls}
                <div className={(getErrorCourseSession(i) != null) ? "text-danger" : ""}><small>{getErrorCourseSession(i)}</small></div>
                {(!success) &&
                    <div style={{ justifyContent: "center" }}  >
                        <Button type="button" className="btn btn-outline-dark black-button" id={i.toString()} onClick={(e: any) => handleAddSession(parseInt(e.currentTarget.id))}>
                            <AddIcon />
                            เพิ่มคลาส
                        </Button>
                    </div>
                }
            </div>
        </>);
    }

    const createHybridCourse = (i: number, c: any) => {
        if (c.session == null) c.session = [];
        var alls: any[] = createSession(i, CourseType.HYBRID, c);
        const dateLessThanEqualToday = isDateLessThanEqualToday(getSessionLessDate(i));
        //same as onsite
        return (<>
            <div className="col-4">
                <div>
                    <label className="col-form-label">ประเภทการเรียน</label><br />
                    <InputGroup hasValidation>
                        <select id={i.toString()} className={"form-control " + ((getErrorCourseType(i) != null) ? "is-invalid" : "")} onChange={e => setCourseType(parseInt(e.currentTarget.id), e.target.value as CourseType)} value={CourseType.HYBRID} disabled={success || dateLessThanEqualToday}>
                            {createCourseType()}
                        </select><br />
                        <div className={(getErrorCourseType(i) != null) ? "invalid-feedback" : ""}>{getErrorCourseType(i)}</div>
                    </InputGroup>
                </div>
                <div>
                    <label className="col-form-label">หลักสูตร/หัวข้อ</label><br />
                    <InputGroup hasValidation>
                        <SingleSearch id={i} isError={getErrorCourseCourseId(i)} disabled={success || dateLessThanEqualToday} isSearchable={true} isMulti={false} placeholder=""
                            onChange={(id: number, e: any) => setCourseCourseIdSearch(id, e)}
                            value={getCourseCourseIdSearch(i)}
                            options={createCourseSearchFilter("", CourseType.HYBRID)}
                            style={{ width: "400px" }}
                        />
                        {
                            /*<select id={i.toString()} className={"form-control "+((getErrorCourseCourseId(i)!=null)?"is-invalid":"")} onChange={e=>setCourseCourseId(parseInt(e.currentTarget.id),parseInt(e.target.value))} value={getCourseCourseId(i)} disabled={success}>
                            { createCourseFilter("",CourseType.HYBRID) }
                            </select>*/
                        }
                        <div className={(getErrorCourseCourseId(i) != null) ? "text-danger" : ""}><small>{getErrorCourseCourseId(i)}</small></div>
                    </InputGroup>
                    <div className='m-2'><small>{getCourseCourseIdSearch(i).label}</small></div>
                </div>
            </div>
            <div className="col-8" style={{ position: "relative", paddingTop: "20px", justifyContent: "center", textAlign: "center" }}>
                {alls}
                <div className={(getErrorCourseSession(i) != null) ? "text-danger" : ""}><small>{getErrorCourseSession(i)}</small></div>
                {(!success) &&
                    <div style={{ justifyContent: "center" }}  >
                        <Button type="button" className="btn btn-outline-dark black-button" id={i.toString()} onClick={(e: any) => handleAddSession(parseInt(e.currentTarget.id))}>
                            <AddIcon />
                            เพิ่มคลาส
                        </Button>
                    </div>
                }
            </div>
        </>);
    }

    const createOnlineCourse = (i: number, c: any) => {
        if (c.session == null) c.session = [];
        var alls: any[] = createSession(i, CourseType.ONLINE, c);
        const dateLessThanEqaulToday = isDateLessThanEqualToday(getSessionLessDate(i));
        return (<>
            <div className="col-4">
                <div>
                    <label className="col-form-label">ประเภทการเรียน</label><br />
                    <InputGroup hasValidation>
                        <select id={i.toString()} className={"form-control " + ((getErrorCourseType(i) != null) ? "is-invalid" : "")} onChange={e => setCourseType(parseInt(e.currentTarget.id), e.target.value as CourseType)} value={CourseType.ONLINE} disabled={success || dateLessThanEqaulToday}>
                            {createCourseType()}
                        </select><br />
                        <div className={(getErrorCourseType(i) != null) ? "invalid-feedback" : ""}>{getErrorCourseType(i)}</div>
                    </InputGroup>
                </div>
                <div>
                    <label className="col-form-label">หลักสูตร/หัวข้อ</label><br />
                    <InputGroup hasValidation>
                        <SingleSearch id={i} isError={getErrorCourseCourseId(i)} disabled={success || dateLessThanEqaulToday} isSearchable={true} isMulti={false} placeholder=""
                            onChange={(id: number, e: any) => setCourseCourseIdSearch(id, e)}
                            value={getCourseCourseIdSearch(i)}
                            options={createCourseSearchFilter("", CourseType.ONLINE)}
                            style={{ width: "400px" }}
                        />
                        {
                            /*<select id={i.toString()} className={"form-control "+((getErrorCourseCourseId(i)!=null)?"is-invalid":"")} onChange={e=>setCourseCourseId(parseInt(e.currentTarget.id),parseInt(e.target.value))} value={getCourseCourseId(i)} disabled={success}>
                            { createCourseFilter("",CourseType.ONLINE) }
                            </select>*/
                        }
                        <div className={(getErrorCourseCourseId(i) != null) ? "text-danger" : ""}><small>{getErrorCourseCourseId(i)}</small></div>
                    </InputGroup>
                    <div className='m-2'><small>{getCourseCourseIdSearch(i).label}</small></div>
                </div>
            </div>
            <div className="col-8" style={{ position: "relative", paddingTop: "20px", justifyContent: "center", textAlign: "center" }}>
                {alls}
                <div className={(getErrorCourseSession(i) != null) ? "text-danger" : ""}><small>{getErrorCourseSession(i)}</small></div>
                {(!success) &&
                    <div style={{ justifyContent: "center" }} >
                        <Button type="button" className="btn btn-outline-dark black-button" id={i.toString()} onClick={(e: any) => handleAddSession(parseInt(e.currentTarget.id))} >
                            <AddIcon />
                            เพิ่มคลาส
                        </Button>
                    </div>
                }
            </div>
        </>);
    }

    const createOtherCourse = (i: number, c: any) => {
        const dateLessThanEqaulToday = isDateLessThanEqualToday(getCourseFromDate(i));
        return (<><div className="col-4">
            <label className="col-form-label">ประเภทการเรียน</label><br />
            <InputGroup hasValidation>
                <select id={i.toString()} className={"form-control " + ((getErrorCourseType(i) != null) ? "is-invalid" : "")} onChange={e => setCourseType(parseInt(e.currentTarget.id), e.target.value as CourseType)} value={CourseType.OTHER} disabled={success || dateLessThanEqaulToday}>
                    {createCourseType()}
                </select><br />
                <div className={(getErrorCourseType(i) != null) ? "invalid-feedback" : ""}>{getErrorCourseType(i)}</div>
            </InputGroup>
        </div>
            <div className="col-3">
                <label className="col-form-label">ประเภทกิจกรรม</label><br />
                <InputGroup hasValidation>
                    <select id={i.toString()} className={"form-control " + ((getErrorActivity(i) != null) ? "is-invalid" : "")} onChange={e => setActivity(parseInt(e.currentTarget.id), e.target.value as ActivityType)} value={getActivity(i)} disabled={success}>
                        {createActivityType()}
                    </select>
                    <div className={(getErrorActivity(i) != null) ? "invalid-feedback" : ""}>{getErrorActivity(i)}</div>
                </InputGroup>
            </div>
            <div className="col-2">
                <label className="col-form-label">วันที่เริ่ม</label><br />
                <InputGroup hasValidation>
                    <label className='position-relative'>
                        <ThaiDateTimePicker className={"form-control " + ((getErrorCourseFromDate(i) != null) ? "is-invalid" : "")} selected={getCourseFromDate(i)} onChange={(e: any) => setCourseFromDate(i, e)} disabled={success} />
                        <div className="search-icon" style={{ visibility: (getErrorCourseFromDate(i) != null) ? "hidden" : "visible", position: "absolute", right: "10px", top: "5px", zIndex: 0 }}>
                            <CalendarTodayIcon style={{ color: "gray" }} />
                        </div>
                    </label>
                    <div className={(getErrorCourseFromDate(i) != null) ? "text-danger" : ""}><small>{getErrorCourseFromDate(i)}</small></div>
                </InputGroup>
            </div>
            <div className="col-2">
                <label className="col-form-label">วันที่สิ้นสุด</label><br />
                <InputGroup hasValidation>
                    <label className='position-relative'>
                        <ThaiDateTimePicker className={"form-control " + ((getErrorCourseToDate(i) != null) ? "is-invalid" : "")} selected={getCourseToDate(i)} onChange={(e: any) => setCourseToDate(i, e)} disabled={success} />
                        <div className="search-icon" style={{ visibility: (getErrorCourseToDate(i) != null) ? "hidden" : "visible", position: "absolute", right: "10px", top: "5px", zIndex: 0 }}>
                            <CalendarTodayIcon style={{ color: "gray" }} />
                        </div>
                    </label>
                    <div className={(getErrorCourseToDate(i) != null) ? "text-danger" : ""}><small>{getErrorCourseToDate(i)}</small></div>
                </InputGroup>
            </div>
        </>);
    }

    const createCourse = () => {
        var res: any[] = [];
        if (item.course === undefined) return res;
        for (var i = 0; i < item.course.length; i++) {
            var c = item.course[i];
            res.push(<><div key={"course" + i} className="light-gray-button" style={{ position: "relative", textAlign: "left", padding: "20px", borderRadius: "5px" }}>
                <div className="row">
                    {(c.courseType === CourseType.ELEARNING) && createELearningCourse(i, c)}
                    {(c.courseType === CourseType.ONSITE) && createOnsiteCourse(i, c)}
                    {(c.courseType === CourseType.ONLINE) && createOnlineCourse(i, c)}
                    {(c.courseType === CourseType.OTHER) && createOtherCourse(i, c)}
                    {(c.courseType === CourseType.HYBRID) && createHybridCourse(i, c)}
                </div>
                <br />
                <div className="row">
                    <div className="col-3">
                        <input id={i.toString()} type="checkbox" checked={getIsClassCourseBanner(i)} onChange={(e) => { setIsClassCourseBanner(parseInt(e.currentTarget.id), e.target.checked) }} disabled={success} />&nbsp;Banner (480x270px)
                    </div>
                    <div className="col-1">
                        {(c.urlClassCourseBanner !== null) && (c.urlClassCourseBanner !== undefined) &&
                            <img src={c.urlClassCourseBanner} height="64px" width="64px" alt="" style={{ marginTop: "-20px" }} />
                        }
                        {(c.urlClassCourseBanner === null) || (c.urlClassCourseBanner === undefined) &&
                            <ImageIcon></ImageIcon>
                        }
                    </div>
                    <div className="col-4">
                        <InputGroup hasValidation>
                            <input id={i.toString()} type="file" className={"form-control " + ((errorClassCourseBannerUpload(i) !== null) ? "is-invalid" : "")} accept="image/jpeg, image/png" onChange={(e: any) => handleClassCourseBannerUploadChange(parseInt(e.currentTarget.id), e.target.files)} style={{ marginTop: "-5px" }} disabled={success || getIsClassCourseBanner(i) === false || getLoadingClassCourseBanner(i)} /><br />
                        </InputGroup>
                        <div className={(errorClassCourseBannerUpload(i) !== null) ? "text-danger" : ""}><small>{errorClassCourseBannerUpload(i)}</small></div>
                    </div>
                    <div id={i.toString()} className="col-2" onClick={(e) => { handleClassCourseBannerUpload(parseInt(e.currentTarget.id)) }}>
                        <Button className="btn btn-primary" style={{ marginTop: "-5px" }} disabled={success || getIsClassCourseBanner(i) === false || getLoadingClassCourseBanner(i)}>
                            {(getLoadingClassCourseBanner(i)) &&
                                <img src={loadinglogo} width="30" height="30" className="d-inline-block align-top" alt="" />
                            }
                            {(!getLoadingClassCourseBanner(i)) &&
                                <>Upload</>
                            }
                        </Button>
                    </div>
                </div>
                {getIsClassCourseBanner(i) && (
                    <>
                        <div className='row'>
                            <div className="col-3">

                            </div>
                            <div className='col-8'>
                                <label className='col-form-label'> Banner Url</label>
                                <input type="text" id={i.toString()} className={getErrorBannerUrl(i) ? 'form-control is-invalid' : 'form-control'} value={getBannerUrl(i)} onChange={e => { setBannerUrl(parseInt(e.currentTarget.id), e.target.value) }} disabled={success} />
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-3">

                            </div>
                            <div className='col-8'>
                                <label className='col-form-label'> คำอธิบาย Banner</label>
                                <textarea className="form-control form-text" id={i.toString()} value={getBannerDescription(i)} rows={5} maxLength={200} onChange={e => { setBannerDescription(parseInt(e.currentTarget.id), e.target.value) }} disabled={success} ></textarea>

                            </div>
                        </div>
                    </>
                )}

                <br />
                {(c.courseType !== CourseType.ELEARNING) &&
                    <>
                        <div className="row gray-button" style={{ textAlign: "left", padding: "20px", borderRadius: "5px" }}>
                            <div id={i.toString()} onClick={(e) => handleRow(parseInt(e.currentTarget.id as string))}><ArrowDropDownIcon className="yellow-circle white pointer" ></ArrowDropDownIcon></div> &nbsp;เงื่อนไขเพิ่มเติมของหลักสูตร
                        </div>
                        <div className="row" style={{ backgroundColor: "white", borderRadius: "5px", visibility: getCourseShow(i) ? "visible" : "hidden", height: getCourseShow(i) ? "" : "0px" }}>
                            <div className="row" style={{ padding: "20px", width: "100%" }}>
                                <div className="col-3">
                                    แบบประเมินผล:
                                </div>
                                <div className="col-1">
                                    <input id={i.toString()} type="radio" checked={getIsCourseSurvey(i) === false} onChange={(e) => { setIsCourseSurvey(parseInt(e.currentTarget.id), false) }} disabled={success} /> ไม่มี
                                </div>
                                <div className="col-1">
                                    <input id={i.toString()} type="radio" checked={getIsCourseSurvey(i) === true} onChange={(e) => { setIsCourseSurvey(parseInt(e.currentTarget.id), true) }} disabled={success} /> มี
                                </div>
                                <div className="col-4">
                                    <SingleSearch isError={getErrorCourseSurvey(i) !== null || getErrorChangeSurvey(i) !== null} id={i} disabled={success || (getIsCourseSurvey(i) === false)} isSearchable={true} isMulti={false} placeholder=""
                                        onChange={(id: number, e: any) => setCourseSurvey(id, e)}
                                        value={getCourseSurvey(i)}
                                        options={createSurveyFilter(c.surveys)}
                                        style={{ width: "100%", marginTop: "-5px" }}
                                    />
                                    {/* <div className='m-2'>{getCourseSurvey(i)?.label}</div> */}
                                    <div className={(getErrorCourseSurvey(i) != null) ? "text-danger" : ""}><small>{getErrorCourseSurvey(i)}</small></div>
                                    <div className={(getErrorChangeSurvey(i) !== null) ? "text-danger" : ""}><small>{getErrorChangeSurvey(i)}</small></div>
                                </div>
                                <div className='col-5 '> </div>
                                <div className='col-6 '>
                                    {c.surveys && c.surveys.map((x: any, index: number) => (
                                        <React.Fragment key={x.value + '_afterSurvey_' + index + '_' + i}>
                                            <BoxList>
                                                {!success && <RemoveCircleOutlineIcon id={i.toString()} className="red icon" onClick={(e) => handleDeleteSurvey(index, parseInt(e.currentTarget.id))}></RemoveCircleOutlineIcon>}
                                                <div>{x.label}</div>
                                                <span className="col-auto link" id={i.toString()} onClick={(e) => { handleClassCourseSurvey(parseInt(e.currentTarget.id), index) }} style={{ visibility: ((item.id === undefined) || !success || !getCourseShow(i)) ? "hidden" : "visible" }}>
                                                    Preview
                                                </span>
                                            </BoxList>
                                        </React.Fragment>
                                    ))}
                                </div>
                            </div>
                            <div className="row" style={{ padding: "20px", width: "100%" }}>
                                <div className="col-3">
                                    แบบทดสอบ:
                                </div>
                                <div className="col-1">
                                    <input id={i.toString()} type="radio" checked={getIsTest(i) === false} onChange={(e) => { setIsTest(parseInt(e.currentTarget.id), false) }} disabled={success} /> ไม่มี
                                </div>
                                <div className="col-1">
                                    <input id={i.toString()} type="radio" checked={getIsTest(i) === true} onChange={(e) => { setIsTest(parseInt(e.currentTarget.id), true) }} disabled={success} /> มี
                                </div>
                            </div>
                            {(c.courseType !== CourseType.ELEARNING) && (c.courseType !== CourseType.OTHER) &&
                                <div className="row" style={{ padding: "20px", width: "100%" }}>
                                    <div className="col-3">
                                        งานที่ได้รับมอบหมาย:
                                    </div>
                                    <div className="col-1">
                                        <input id={i.toString()} type="radio" checked={getIsAssignment(i) === false} onChange={(e) => { setIsAssignment(parseInt(e.currentTarget.id), false) }} disabled={success} /> ไม่มี
                                    </div>
                                    <div className="col-1">
                                        <input id={i.toString()} type="radio" checked={getIsAssignment(i) === true} onChange={(e) => { setIsAssignment(parseInt(e.currentTarget.id), true) }} disabled={success} /> มี
                                    </div>
                                </div>
                            }
                            <div className="row" style={{ padding: "20px", width: "100%" }}>
                                <div className="col-3">
                                    วุฒิบัตร:
                                </div>
                                <div className="col-1">
                                    <input id={i.toString()} type="radio" checked={getIsCer(i) === false} onChange={(e) => { setIsCer(parseInt(e.currentTarget.id), false) }} disabled={success} /> ไม่มี
                                </div>
                                <div className="col-1">
                                    <input id={i.toString()} type="radio" checked={getIsCer(i) === true} onChange={(e) => { setIsCer(parseInt(e.currentTarget.id), true) }} disabled={success} /> มี
                                </div>
                            </div>
                            <div className="row" style={{ padding: "20px", width: "100%" }}>
                                <div className="col-3">
                                    แบ่งกลุ่มการเรียน:
                                </div>
                                <div className="col-1">
                                    <input id={i.toString()} type="radio" checked={getIsSeparate(i) === false} onChange={(e) => { setIsSeparate(parseInt(e.currentTarget.id), false) }} disabled={success || isSeparateDisable(i)} /> ไม่มี
                                </div>
                                <div className="col-1">
                                    <input id={i.toString()} type="radio" checked={getIsSeparate(i) === true} onChange={(e) => { setIsSeparate(parseInt(e.currentTarget.id), true) }} disabled={success || isSeparateDisable(i)} /> มี
                                </div>
                                <div className="col-7">
                                    <div className="row" style={{ padding: "0px" }}>
                                        <div className="col-5">
                                            เลือกวิทยากรผู้ดูแลในแต่ละกลุ่ม
                                        </div>
                                        <div className="col-3">
                                            <input id={i.toString()} type="checkbox" checked={getIsSeparateByTrainer(i)} onChange={(e) => { setIsSeparateByTrainer(parseInt(e.currentTarget.id), e.currentTarget.checked) }} style={{ marginTop: "-5px" }} disabled={success || (getIsSeparate(i) === false)} />
                                            &nbsp;วิทยากรหลัก
                                        </div>
                                        <div className="col-3">
                                            <input id={i.toString()} type="checkbox" checked={getIsSeparateByAssistant(i)} onChange={(e) => { setIsSeparateByAssistant(parseInt(e.currentTarget.id), e.currentTarget.checked) }} style={{ marginTop: "-5px" }} disabled={success || (getIsSeparate(i) === false)} />
                                            &nbsp;วิทยากรผู้ช่วย
                                        </div>
                                        <div className="col-1" style={{ padding: "0px" }}>
                                            <span>{(getSeparateNum(i) > 0) ? getSeparateNum(i) + " กลุ่ม" : ""}</span>
                                        </div>
                                    </div>
                                    <div className="row col-12" >
                                        <div className={(getErrorSeparate(i) != null) ? "text-danger col-12" : "col-12"}><small>{getErrorSeparate(i)}</small></div>
                                    </div>
                                    <div className="row col-12">
                                        <Button id={i.toString()} onClick={e => { handleGeneratingSeparateGroup(parseInt(e.currentTarget.id)) }} disabled={getGeneratingSeparateGroup(i) || !(success && getCourseShow(i) && (getIsSeparate(i)) && isAbleGenerating(i))}>
                                            {(getGeneratingSeparateGroup(i)) &&
                                                <img src={loadinglogo} width="30" height="30" className="d-inline-block align-top" alt="" />
                                            }
                                            {(!getGeneratingSeparateGroup(i)) &&
                                                <>แบ่งกลุ่ม</>
                                            }
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{ padding: "20px", width: "100%" }}>
                                <div className="col-3">
                                    เงื่อนไขการผ่านหลักสูตร:
                                </div>
                                <div className="col-1">
                                    <input id={i.toString()} type="radio" checked={getIsRule(i) === false} onChange={(e) => { setIsRule(parseInt(e.currentTarget.id), false) }} disabled={success} /> ไม่มี
                                </div>
                                <div className="col-1">
                                    <input id={i.toString()} type="radio" checked={getIsRule(i) === true} onChange={(e) => { setIsRule(parseInt(e.currentTarget.id), true) }} disabled={success} /> มี
                                </div>
                                <div className="col-3">
                                    <input id={i.toString()} type="checkbox" checked={getRulePass(i) === true} onChange={e => { setRulePass(parseInt(e.currentTarget.id), e.currentTarget.checked) }} disabled={success || (getIsRule(i) === false)} />&nbsp;สอบผ่าน<br />
                                    <input id={i.toString()} type="checkbox" checked={getRuleCheckIn(i) === true} onChange={e => { setRuleCheckIn(parseInt(e.currentTarget.id), e.currentTarget.checked) }} disabled={success || (getIsRule(i) === false)} />&nbsp;Check-in<br />
                                    <input id={i.toString()} type="checkbox" checked={getRuleCheckOut(i) === true} onChange={e => { setRuleCheckOut(parseInt(e.currentTarget.id), e.currentTarget.checked) }} disabled={success || (getIsRule(i) === false)} />&nbsp;Check-out<br />
                                </div>
                            </div>
                        </div>
                    </>
                }
                <div style={{ fontSize: "20px", position: "absolute", top: "-25px", right: "10px", zIndex: 0 }}>
                    Step {i + 1}
                </div>
                {(!success) &&
                    <div className="search-icon" id={i.toString()} style={{ position: "absolute", right: "5px", top: "5px", zIndex: 10 }} onClick={e => { handleDeleteCourse(parseInt(e.currentTarget.id as string)) }}>
                        <RemoveCircleOutlineIcon className="red" ></RemoveCircleOutlineIcon>
                    </div>
                }
                {((i > 0) && (!success)) &&
                    <div className="search-icon" id={i.toString()} style={{ cursor: "pointer", background: "white", position: "absolute", right: "-30px", top: "30px", zIndex: 10 }} onClick={e => { handleMoveUpCourse(parseInt(e.currentTarget.id as string)) }}>
                        <ExpandLessIcon  ></ExpandLessIcon>
                    </div>
                }
                {((i < item.course.length - 1) && (!success)) &&
                    <div className="search-icon" id={i.toString()} style={{ cursor: "pointer", background: "white", position: "absolute", right: "-30px", top: "60px", zIndex: 10 }} onClick={e => { handleMoveDownCourse(parseInt(e.currentTarget.id as string)) }}>
                        <ExpandMoreIcon  ></ExpandMoreIcon>
                    </div>
                }
            </div><br /></>
            );
        }
        return res;
    }

    return (<>
        <MainMenu>
            <div>
                <div className="query-wrapper">
                    <br />
                    <div className="yellow-button" style={{ textAlign: "left", borderTopLeftRadius: "30px", borderTopRightRadius: "30px", height: "70px", paddingTop: "15px", paddingLeft: "15px" }}><h4 className="header">รอบการเรียน</h4></div>
                    {(initProject + initCourse + initTrainer + initClass + initSurvey === 5) &&
                        <>
                            <div className="light-gray-button" style={{ borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px', paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", textAlign: "left" }}>
                                <div className="row">
                                    <div className="col-4">
                                        <label className="col-form-label">โครงการ</label><br />
                                        <InputGroup hasValidation>
                                            <SingleSearch isError={((getErrorProject() != null) ? "is-invalid" : "")}
                                                isSearchable={true} isMulti={false} placeholder="" disabled={success}
                                                onChange={(id: string, e: any) => setProject(e == null ? null : e?.value)}
                                                value={getProject()}
                                                options={createProjectSearchFilter()}
                                                style={{ width: "500px" }}
                                            />
                                        </InputGroup>
                                        <div className={(getErrorProject() != null) ? "text-danger" : ""}><small>{getErrorProject()}</small></div>
                                    </div>
                                    <div className="col-1">
                                        <label className="col-form-label">รอบ</label><br />
                                        <InputGroup hasValidation>
                                            <Form.Control maxLength={10} className="form-control" type="text" onChange={(e: any) => setSession(e.target.value)} value={getSession()}
                                                isInvalid={(getErrorSession() != null)} disabled={success} />
                                            <Form.Control.Feedback type="invalid">{getErrorSession()}</Form.Control.Feedback>
                                        </InputGroup>
                                    </div>
                                    <div className="col-2">
                                        <label className="col-form-label">วันที่เริ่ม</label>
                                        <InputGroup hasValidation>
                                            <label className='position-relative'>
                                                <ThaiDateTimePicker className={"form-control " + ((getErrorFromDate() != null) ? "is-invalid" : "")} selected={getFromDate()} onChange={(e: any) => setFromDate(e)} disabled={success} />
                                                <div className="search-icon" style={{ visibility: (getErrorFromDate() != null) ? "hidden" : "visible", position: "absolute", right: "10px", top: "5px", zIndex: 0 }}>
                                                    <CalendarTodayIcon style={{ color: "gray" }} />
                                                </div>
                                            </label>
                                            <div className={(getErrorFromDate() != null) ? "text-danger" : ""}><small>{getErrorFromDate()}</small></div>
                                        </InputGroup>
                                    </div>
                                    <div className="col-2">
                                        <label className="col-form-label">วันที่สิ้นสุด</label>
                                        <InputGroup hasValidation>
                                            <label className='position-relative'>
                                                <ThaiDateTimePicker className={"form-control " + ((getErrorToDate() != null) ? "is-invalid" : "")} selected={getToDate()} onChange={(e: any) => setToDate(e)} disabled={success} />
                                                <div className="search-icon" style={{ visibility: (getErrorToDate() != null) ? "hidden" : "visible", position: "absolute", right: "10px", top: "5px", zIndex: 0 }}>
                                                    <CalendarTodayIcon style={{ color: "gray" }} />
                                                </div>
                                            </label>
                                            <div className={(getErrorToDate() != null) ? "text-danger" : ""}><small>{getErrorToDate()}</small></div>
                                        </InputGroup>
                                    </div>

                                    <div className="col-3">
                                        <label className="col-form-label">ฝ่ายผู้รับผิดชอบโครงการ</label><br />
                                        <input className="form-control" value={createOrganizer()} disabled={true} />
                                    </div>
                                </div>
                                <br />
                                <div className="row">
                                    <div className="col-3">
                                        ประเภทการเรียน:
                                    </div>
                                    <div className="col-4">
                                        <input type="radio" name="class_step" checked={getClassStepType() === ClassStepType.STEP} onChange={(e) => { setClassStepType(ClassStepType.STEP) }} disabled={success} /> เรียงลำดับในการเข้าเรียนตาม Step (Pre-Requisite)
                                    </div>
                                    <div className="col-4">
                                        <input type="radio" name="class_step" checked={getClassStepType() === ClassStepType.NO_STEP} onChange={(e) => { setClassStepType(ClassStepType.NO_STEP) }} disabled={success} /> ไม่เรียงลำดับในการเข้าเรียน
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-3">
                                        ประเภทการจัดอบรม:
                                    </div>
                                    <div className="col-4">
                                        <input type="radio" name="trainig_type" checked={getTrainingType() === TrainingType.PARTNER} onChange={(e) => { setTrainingType(TrainingType.PARTNER) }} disabled={success} /> <span className={((getErrorTrainingType() != null) ? "text-danger" : "")}> จัดอบรมร่วมกับองค์กรพันธมิตร</span>

                                    </div>
                                    <div className="col-4">
                                        <input type="radio" name="trainig_type" checked={getTrainingType() === TrainingType.PUBLIC} onChange={(e) => { setTrainingType(TrainingType.PUBLIC) }} disabled={success} /> <span className={((getErrorTrainingType() != null) ? "text-danger" : "")}> จัดอบรมทั่วไป</span>
                                    </div>
                                </div>
                                <br />
                                <div className="row">
                                    <div className="col-3">
                                        แบบประเมินผลก่อน/หลังเรียน:
                                    </div>
                                    <div className="col-3">
                                        <input type="checkbox" checked={getIsSurveyBefore()} onChange={(e) => { setIsSurveyBefore(e.target.checked) }} disabled={success} /> แบบประเมินผลก่อนเรียน
                                    </div>
                                    <div className="col-4">
                                        <SingleSearch isError={getErrorSurveyBefore() !== null} disabled={success || (getIsSurveyBefore() === false)} isSearchable={true} isMulti={false} placeholder=""
                                            onChange={(id: number, e: any) => setSurveyBefore(e)}
                                            value={getSurveyBefore()}
                                            options={createSurveyFilter(item.beforeSurveys)}
                                            style={{ width: "400px", marginTop: "-5px" }}
                                        />
                                        <div className={(getErrorSurveyBefore() != null) ? "text-danger" : ""}><small>{getErrorSurveyBefore()}</small></div>
                                    </div>
                                    <div className='col-6 '> </div>
                                    <div className='col-6 '>
                                        {item.beforeSurveys && item.beforeSurveys.map((x: any, index: number) => (
                                            <React.Fragment key={x.value + '_beforeSurvey_' + index}>
                                                <BoxList>
                                                    {!success && <RemoveCircleOutlineIcon id={index.toString()} className="red icon" onClick={(e) => handleDeleteBeforeSurvey(index)}></RemoveCircleOutlineIcon>}
                                                    <div>{x.label}</div>

                                                    <span className="col-auto link" onClick={(e) => { handleClassSurvey(true, index) }} style={{ visibility: ((item.id === undefined) || !success) ? "hidden" : "visible" }}>
                                                        Preview
                                                    </span>
                                                </BoxList>
                                            </React.Fragment>
                                        ))}
                                    </div>

                                </div>
                                <br />
                                <div className="row">
                                    <div className="col-3">
                                    </div>
                                    <div className="col-3">
                                        <input type="checkbox" checked={getIsSurveyAfter()} onChange={(e) => { setIsSurveyAfter(e.target.checked) }} disabled={success} /> แบบประเมินผลหลังเรียน
                                    </div>
                                    <div className="col-4">
                                        <SingleSearch isError={getErrorSurveyAfter() !== null} disabled={success || (getIsSurveyAfter() === false)} isSearchable={true} isMulti={false} placeholder=""
                                            onChange={(id: number, e: any) => setSurveyAfter(e)}
                                            value={getSurveyAfter()}
                                            options={createSurveyFilter(item.afterSurveys)}
                                            style={{ width: "400px", marginTop: "-5px" }}
                                        />
                                        <div className={(getErrorSurveyAfter() != null) ? "text-danger" : ""}><small>{getErrorSurveyAfter()}</small></div>
                                    </div>
                                    <div className='col-6 '> </div>
                                    <div className='col-6 '>
                                        {item.afterSurveys && item.afterSurveys.map((x: any, index: number) => (
                                            <React.Fragment key={x.value + '_afterSurvey_' + index}>
                                                <BoxList>
                                                    {!success && <RemoveCircleOutlineIcon id={index.toString()} className="red icon" onClick={(e) => handleDeleteAfterSurvey(index)}></RemoveCircleOutlineIcon>}
                                                    <div>{x.label}</div>

                                                    <span className="col-auto link" onClick={(e) => { handleClassSurvey(false, index) }} style={{ visibility: ((item.id === undefined) || !success) ? "hidden" : "visible" }}>
                                                        Preview
                                                    </span>
                                                </BoxList>
                                            </React.Fragment>
                                        ))}
                                    </div>

                                </div>
                                <br />
                                <div className="row">
                                    <div className="col-3">
                                    </div>
                                    <div className="col-3">
                                        <input type="checkbox" checked={getIsClassBanner()} onChange={(e) => { setIsClassBanner(e.target.checked) }} disabled={success} />&nbsp;Banner (480x270 px)&nbsp;&nbsp;
                                        {(item.urlClassBanner !== null) && (item.urlClassBanner !== undefined) &&
                                            <img src={item.urlClassBanner} height="64px" width="64px" alt="" />
                                        }
                                        {(item.urlClassBanner === null) || (item.urlClassBanner === undefined) &&
                                            <ImageIcon></ImageIcon>
                                        }
                                    </div>
                                    <div className="col-4">
                                        <InputGroup hasValidation>
                                            <input type="file" className={"form-control " + ((errorClassBannerUpload() !== null) ? "is-invalid" : "")} accept="image/jpeg, image/png" onChange={(e: any) => handleClassBannerUploadChange(e.target.files)} style={{ marginTop: "-5px" }} disabled={success || (getIsClassBanner() === false || getLoadingClassBanner())} /><br />
                                        </InputGroup>
                                        <div className={(errorClassBannerUpload() !== null) ? "text-danger" : ""}><small>{errorClassBannerUpload()}</small></div>
                                    </div>
                                    <div className="col-2" onClick={(e) => { handleClassBannerUpload() }}>
                                        <Button className="btn btn-primary" style={{ marginTop: "-5px" }} disabled={success || (getIsClassBanner() === false) || getLoadingClassBanner()}>
                                            {(getLoadingClassBanner()) &&
                                                <img src={loadinglogo} width="30" height="30" className="d-inline-block align-top" alt="" />
                                            }
                                            {(!getLoadingClassBanner()) &&
                                                <>Upload</>
                                            }
                                        </Button>
                                    </div>
                                </div>
                                <br />
                                <div className="row mt-2">
                                    <div className="col-3">
                                        Step ที่นับจำนวนคนผ่าน:
                                    </div>
                                    <div className='col-2'>
                                        <InputGroup hasValidation>
                                            <SingleSearch isError={((getErrorPassedStep() != null) ? "is-invalid" : "")}
                                                isSearchable={true} isMulti={false} placeholder="" disabled={success}
                                                onChange={(id: string, e: any) => setPassedStep(e == null ? null : e?.value)}
                                                value={getPassedStep()}
                                                options={createPassedStepSearchFilter()}
                                                style={{ width: "500px" }}
                                            />
                                        </InputGroup>
                                    </div>
                                    <div className='col-7'>{getPassedStepNameCourse()}</div>
                                </div>
                                <div className='row'>
                                    <div className={(getErrorPassedStep() != null) ? "text-danger" : ""}><small>{getErrorPassedStep()}</small></div>
                                </div>
                            </div>

                            <br />
                            {ClassTab(1, validateTab(), checkAssignment(), checkStudentList(), null, (validateTab()) ? handleStudent : null, (validateTab()) ? handleDoc : null, (validateTab()) ? handleAssignment : null, (validateTab()) ? handleHistory : null, (validateTab()) ? handleResult : null, (validateTab()) ? handleSurvey : null, validateTab() ? handleResultNumber : null, item.showResultStudentTab)}
                            <div style={{ background: "white", padding: "40px", borderRadius: "5px" }}>
                                {createCourse()
                                }
                                <div className={(getErrorCourse() != null) ? "text-danger" : ""}><small>{getErrorCourse()}</small></div>
                                <br />
                                {(!success) &&
                                    <Button type="button" className="btn btn-warning yellow-button"
                                        onClick={() => handleAddCourse()} >
                                        <AddIcon />
                                        เพิ่มหลักสูตร
                                    </Button>
                                }
                            </div>
                        </>
                    }
                    <br />
                    {(((initProject + initCourse + initTrainer + initClass + initSurvey < 5) || (loading) || (updating)) && (error === "")) &&
                        <div style={{ textAlign: "center" }}><img src={loadinglogo} width="30" height="30" className="d-inline-block align-top" alt="" /></div>
                    }
                    {(error !== "") &&
                        <div style={{ color: 'red', textAlign: "center" }}>{error}</div>
                    }
                    {(initProject + initCourse + initTrainer + initClass + initSurvey === 5) && (!loading) && (!success) && (!updating) &&
                        <div className='d-flex'>
                            <div className='text-left'>
                                <div>สร้างโดย {item.createAdmin || "-"} เมื่อ {Util.datetimetostr(new Date(item.createTime)) || "-"}  </div>
                                <div>แก้ไขล่าสุดโดย {item.admin || "-"} เมื่อ {Util.datetimetostr(new Date(item.lastUpdateTime)) || "-"} </div>
                            </div>
                            <div className='ml-auto'>
                                <button type="button" className="btn btn-outline-primary" style={{ width: "120px" }}
                                    onClick={() => handleBack()} >
                                    Back
                                </button>&nbsp;
                                {(id !== undefined) &&
                                    <><button type="button" className="btn btn-danger" style={{ width: "120px" }}
                                        onClick={() => handleDelete()} >
                                        Delete
                                    </button>&nbsp;</>
                                }
                                <button type="button" className="btn btn-warning" style={{ width: "120px" }}
                                    onClick={() => handleClear()} >
                                    Reset
                                </button>&nbsp;
                                <button type="button" className="btn btn-success" style={{ width: "120px" }}
                                    onClick={() => handleSubmit()} >
                                    Save
                                </button>
                            </div>
                        </div>
                    }
                    {(success) &&
                        <div className='d-flex'>
                            <div className='text-left'>
                                <div>สร้างโดย {item.createAdmin || '-'} เมื่อ {Util.datetimetostr(new Date(item.createTime)) || '-'}  </div>
                                <div>แก้ไขล่าสุดโดย {item.admin || '-'} เมื่อ {Util.datetimetostr(new Date(item.lastUpdateTime)) || '-'} </div>
                            </div>
                            <div className='ml-auto'>
                                <button type="button" className="btn btn-outline-primary" style={{ width: "120px" }}
                                    onClick={() => handleClose()} >
                                    Back
                                </button>&nbsp;
                                <button type="button" className="btn yellow-button" style={{ width: "120px" }}
                                    onClick={() => handleReedit()} >
                                    Edit
                                </button>
                            </div>
                        </div>

                    }
                </div>
                <br />
                <Footer />
            </div>
        </MainMenu>
        <Modal show={leave !== ""} onHide={() => { setLeave("") }}>
            <Modal.Header closeButton>
                <Modal.Title>ยกเลิกข้อมูลที่แก้ไข?</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => { setLeave("") }}>
                    Cancel
                </Button>
                <Button variant="danger" onClick={() => { handleLeave() }}>
                    OK
                </Button>
            </Modal.Footer>
        </Modal>

        <Modal show={deleteId !== ""} onHide={handleDeleteClose}>
            <Modal.Header closeButton>
                <Modal.Title>ยืนยันการลบ?</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleDeleteClose}>
                    Cancel
                </Button>
                <Button variant="danger" onClick={handleDeleteConfirm}>
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>
        <AlertContainer floatingTime={10000} />
        <AlertModal body={titleAlertModal} show={alertModalShow}
            onOk={() => clearCourse()} />
    </>)
}

export default EditClass;
