import { useEffect, useState } from 'react';
import { useDispatch, useStore, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Util } from '../../api/util';
// import Footer from './adminfooter';
import { Button, InputGroup, Modal, Table } from 'react-bootstrap';
import { ThaiDateTimePicker } from '../tab/thai-datetime-picker';
import loadinglogo from '../../img/loading.gif';
import excellogo from '../../img/excel.png';
import AddIcon from '@material-ui/icons/Add';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';
import { createPage } from '../tab/pagetab';
import { queryclear } from '../../store/search/actions';
import { NOT_FOUND_LABEL } from '../../api/label';
import { EXPORT_CLASS_TRAINER_URL } from '../../api/class-api';
import { SingleSearch } from '../tab/search-component';
import { getAccessToken } from '../../store/auth/selectors';
import MainMenu from './menu';
import Footer from '../admin/adminfooter';
import { listPartnerWithOutPage, listclass, listproject } from '../../store/trainerPage/action';

const TrainerSearchClass = () => {
    const token = useSelector(getAccessToken);
    const { projectSearch, partnerSearch, fromDateSearch, toDateSearch } = window.sessionStorage.getItem("searchClass") ? JSON.parse(window.sessionStorage.getItem("searchClass") as any) : "{projectSearch:'',partnerSearch:''}" as any;
    const [initProject, setInitProject] = useState(0)
    const [initPartner, setInitPartner] = useState(0)
    const [projectItem, setProjectItem] = useState<Array<any>>([])
    const [partnerItem, setPartnerItem] = useState<Array<any>>([])
    const [project, setProject] = useState(projectSearch ? projectSearch : "")
    const [partner, setPartner] = useState(partnerSearch ? partnerSearch : "")
    const [fromDate, setFromDate] = useState<Date | null>(fromDateSearch ? new Date(fromDateSearch) : null)
    const [toDate, setToDate] = useState<Date | null>(toDateSearch ? new Date(toDateSearch) : null)
    const [page, setPage] = useState(0)
    const [limit, setLimit] = useState(10)
    const [totalItem, setTotalItem] = useState(0)
    const [totalPage, setTotalPage] = useState(0)
    const [loading, setLoading] = useState(false)
    const [items, setItem] = useState<Array<any> | null>(null);
    const [error, setError] = useState('');
    const [deleteId, setDeleteId] = useState<string>("");
    const store = useStore();
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        const unsubscribe = store.subscribe(() => {
            var res = store.getState().SearchReducer;
            if (res.isLoading) {
                setError('');
                setLoading(true);
            } else if (res.isLoaded) {
                setError('');
                setLoading(false);
                if (res.data != null) {
                    if (res.table === "project") {
                        setInitProject(1);
                        setProjectItem(res.data);
                    } else if (res.table === "partner_with_out_page") {
                        setInitPartner(1);
                        setPartnerItem(res.data);
                    } else {
                        setItem(res.data.classResponseList);
                        setTotalPage(res.data.totalPage);
                        setTotalItem(res.data.totalItem);
                    }
                } else {
                    setError(NOT_FOUND_LABEL);
                }
            } else if (res.isNotFound) {
                setError(NOT_FOUND_LABEL);
                setItem([]);
                setLoading(false);
            } else if (res.isUpdated) {
                dispatch(queryclear);
                //dispatch(listclass(parseInt(project),parseInt(partner),fromDate,toDate,page,limit));
            } else if (res.isFailed) {
                setError(res.data.message);
                setItem([]);
                setLoading(false);
            }
        });
        dispatch(listPartnerWithOutPage());
        dispatch(listproject());
        window.scroll(0, 0);
        return unsubscribe;
    }, [])

    const handleProject = (e: string) => {
        setProject(e);
    }

    const getProject = () => {
        for (var i = 0; i < projectItem.length; i++) {
            if (projectItem[i].id === parseInt(project)) {
                return { label: projectItem[i].code + " : " + projectItem[i].name, value: project };
            }
        }
        return null;
    }

    const getPartner = () => {
        for (var i = 0; i < partnerItem.length; i++) {
            if (partnerItem[i].id === parseInt(partner)) {
                return { label: partnerItem[i].code + " : " + partnerItem[i].name, value: partnerItem[i].id };
            }
        }
        return null;
    }

    const handleFromDate = (e: Date | null) => {
        setFromDate(e);
    }

    const handleToDate = (e: Date | null) => {
        setToDate(e);
    }

    const handleClear = () => {
        setProject("");
        setPartner("");
        setFromDate(null);
        setToDate(null);
        setPage(0);
        setLimit(10);
        setTotalItem(0);
        setTotalPage(0);
        setItem(null);
        window.sessionStorage.setItem("searchClass", JSON.stringify({ projectSearch: '', partnerSearch: '', fromDateSearch: null, toDateSearch: null }))
        dispatch(queryclear());
    }

    const handleSubmit = () => {
        setPage(0);
        dispatch(listclass(parseInt(project), parseInt(partner), fromDate, toDate, 0, limit));
        window.sessionStorage.setItem("searchClass", JSON.stringify({ projectSearch: project, partnerSearch: partner, fromDateSearch: fromDate, toDateSearch: toDate }))
    }

    const handleLimit = (l: number) => {
        setLimit(l);
        dispatch(listclass(parseInt(project), parseInt(partner), fromDate, toDate, page, l));
    }

    const handlePage = (l: number) => {
        setPage(l);
        dispatch(listclass(parseInt(project), parseInt(partner), fromDate, toDate, l, limit));
    }

    const handleExcel = () => {
        var projectId: number = parseInt(project);
        var partnerId: number = parseInt(partner);
        window.open(EXPORT_CLASS_TRAINER_URL + "?" + ((isNaN(projectId)) ? "" : "project_id=" + projectId) + ((isNaN(partnerId)) ? "" : "&partner_id=" + partnerId) + ((fromDate == null) ? "" : "&from_date=" + encodeURIComponent(Util.getLocalISOString(fromDate))) + ((toDate == null) ? "" : "&to_date=" + encodeURIComponent(Util.getLocalISOString(toDate))) + "&token=" + encodeURIComponent(token));
    }

    const handleEdit = (id: number) => {
        history.push("/trainer/class/" + id + "/assignment");
    }

    const handleDelete = (id: number) => {
        setDeleteId(id.toString());
    }

    const handleDeleteClose = () => {
        setDeleteId("");
    }

    // const handleDeleteConfirm = () => {
    //     dispatch(queryclear());
    //     dispatch(deleteclass(deleteId));
    //     if ((items!==undefined) && (items!==null))
    //     {
    //         var newItem=[];
    //         for (var i=0;i<items.length;i++)
    //         {
    //             if (items[i].id!==parseInt(deleteId)) 
    //             {
    //                 newItem.push(items[i]);
    //             }
    //         }
    //         setItem(newItem);
    //     }
    //     setDeleteId("");
    // }

    const createBr = (n: number) => {
        var res: any[] = [];
        for (var j = 0; j < n; j++) {
            res.push(<br />);
        }
        return res;
    }

    const createProjectSearchFilter = () => {
        var res: any[] = [];
        for (var i = 0; i < projectItem.length; i++) {
            res.push({ label: projectItem[i].code + " : " + projectItem[i].name, value: projectItem[i].id });
        }
        return res;
    }

    const createPartnerSearchFilter = () => {
        var res: any[] = [];
        for (var i = 0; i < partnerItem.length; i++) {
            res.push({ label: partnerItem[i].code + " : " + partnerItem[i].name, value: partnerItem[i].id });
        }
        return res;
    }
    return (<>
        <MainMenu>
            {/* Todo init */}
            <>
                <div>
                    <div className="query-wrapper">
                        <br />
                        <form>
                            <div style={{ background: '#EEEEEE', borderRadius: '5px', paddingLeft: "15px", paddingRight: "15px", paddingBottom: '20px', textAlign: "left" }}>
                                <div className="row">
                                    <div className="col-3">
                                        <label className="col-form-label">โครงการ</label>
                                        <SingleSearch
                                            isSearchable={true} isMulti={false} isClearable={true} placeholder=""
                                            onChange={(id: string, e: any) => handleProject(e == null ? null : e?.value)}
                                            value={getProject()}
                                            options={createProjectSearchFilter()}
                                            style={{ width: "300px" }}
                                        />
                                    </div>
                                    <div className="col-3">
                                        <label className="col-form-label">องค์กร</label>
                                        <SingleSearch
                                            isSearchable={true} isMulti={false} isClearable={true} placeholder=""
                                            onChange={(id: string, e: any) => setPartner(e == null ? null : e?.value)}
                                            value={getPartner()}
                                            options={createPartnerSearchFilter()}
                                            style={{ width: "300px" }}
                                        />
                                    </div>
                                    <div className="col-3">
                                        <label className="col-form-label">วันที่เริ่ม</label>
                                        <InputGroup>
                                            <label  className='position-relative'>
                                                <ThaiDateTimePicker className="form-control" selected={fromDate} onChange={(fromDate: any) => handleFromDate(fromDate)}
                                                />
                                                <div className="search-icon" style={{ position: "absolute", right: "10px", top: "5px", zIndex: 1 }}>
                                                    <CancelIcon style={{ visibility: (fromDate == null) ? "hidden" : "visible", color: "gray" }} onClick={(e) => { setFromDate(null); }} />
                                                    <CalendarTodayIcon style={{ color: "gray" }} />
                                                </div>
                                            </label>
                                        </InputGroup>
                                    </div>

                                    <div className="col-3">
                                        <label className="col-form-label">วันที่สิ้นสุด</label>
                                        <InputGroup>
                                            <label className='position-relative'>
                                                <ThaiDateTimePicker locale="th" className="form-control" dateFormat="dd/MM/yyyy" selected={toDate} clear={() => setToDate(null)} onChange={(toDate: any) => handleToDate(toDate)}
                                                />
                                                <div className="search-icon" style={{ position: "absolute", right: "10px", top: "5px", zIndex: 1 }}>
                                                    <CancelIcon style={{ visibility: (toDate == null) ? "hidden" : "visible", color: "gray" }} onClick={(e) => setToDate(null)} />
                                                    <CalendarTodayIcon style={{ color: "gray" }} />
                                                </div>
                                            </label>
                                        </InputGroup>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className="row" style={{ justifyContent: "center" }}>
                                <button type="button" className="btn btn-outline-primary" style={{ width: "150px" }}
                                    onClick={() => handleClear()} >
                                    Clear
                                </button>&nbsp;
                                <button type="button" className="btn btn-primary" style={{ width: "150px" }}
                                    onClick={() => handleSubmit()} disabled={loading}>
                                    <div>
                                        {(loading) &&
                                            <div style={{ textAlign: "center" }}><img src={loadinglogo} width="30" height="30" className="d-inline-block align-top" alt="" /></div>
                                        }
                                        {(!loading) &&
                                            <>Search</>
                                        }
                                    </div>
                                </button>
                            </div>
                        </form>
                        <br />
                        <div className="row">
                            <div className="col-5" style={{ justifyContent: "left", textAlign: "left", marginTop: "auto", marginBottom: "auto" }}>
                                จำนวนทั้งหมด {Util.integerWithCommas(totalItem)} รายการ
                            </div>
                            <div className="col-1">
                            </div>
                            <div className="col-6 row" style={{ justifyContent: "right", paddingRight: "0px" }}>
                                <div style={{ textAlign: "right", marginTop: "auto", marginBottom: "auto" }}>จำนวนรายการต่อหน้า</div>&nbsp;
                                <select className="form-control" style={{ width: "80px" }} name="page" value={limit} onChange={e => handleLimit(parseInt(e.target.value))}>
                                    <option value="0">All</option>
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>&nbsp;
                                <Button type="button" className="btn btn-success green-button" style={{ width: "50px" }}
                                    onClick={() => handleExcel()} >
                                    <img src={excellogo} height="32px" alt="excel" style={{ marginLeft: "-7px" }} />
                                </Button>
                                {/* <Button type="button" className="btn btn-warning yellow-button" style={{width:"200px"}}
                            onClick={() => handleAdd()} >
                            <AddIcon />
                            สร้างรอบการเรียน
                    </Button>&nbsp;
                    <Button type="button" className="btn btn-success green-button" style={{width:"50px"}}
                            onClick={() => handleExcel()} >
                            <img src={excellogo} height="32px" alt="excel" style={{marginLeft:"-3px"}}/>
                    </Button> */}
                            </div>
                        </div>
                        {((!loading) && (items !== null) && (items.length === 0) && (error === "")) &&
                            <><br /><br /><div className="text-center">{NOT_FOUND_LABEL}</div></>
                        }
                        {(error !== "") &&
                            <div style={{ color: 'red', textAlign: "center" }}>{error}</div>
                        }
                        {((items !== null) && (items.length >= 1)) &&
                            <>
                                <br />
                                <Table striped hover>
                                    <thead className="black-button">
                                        <tr>
                                            <th className='text-left'>ชื่อโครงการ</th>
                                            <th className='text-left'>รอบการเรียน</th>
                                            <th className='text-left'>ระยะเวลาการเรียน</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ fontSize:"22px" }}>
                                        {items.map((item, index) => {
                                            return <tr key={item.id}>
                                                <td className='text-left'> <div onClick={(e) => handleEdit(item.id)} style={{ cursor: "pointer" }}>{item.code} : {item.name}</div></td>
                                                <td className='text-left'> {item.session} </td>
                                                <td className='text-left'> {Util.datetostr(new Date(item.fromDate)) + " - " + Util.datetostr(new Date(item.toDate))} </td>
                                                <td className='text-left'> <div className="row"><div onClick={(e) => handleEdit(item.id)}><EditIcon className="yellow pointer" ></EditIcon></div>&nbsp;</div></td>
                                            </tr>
                                        })}
                                    </tbody>
                                </Table>
                                <br />

                                <div className="row" style={{ justifyContent: "center", position: "relative" }}>
                                    {createPage(page, totalPage, handlePage)}
                                    <div className="col-5" style={{ justifyContent: "left", textAlign: "left", position: "absolute", left: "0px" }}>
                                        จำนวนทั้งหมด {Util.integerWithCommas(totalItem)} รายการ
                                    </div>
                                </div>
                            </>
                        }
                        <br />
                        <br />
                    </div>
                    {((items === null) || (items.length < 5)) &&
                        <>{createBr((items === null) ? 8 : 8 - items.length)}
                        </>
                    }
                    <Footer empty={((items === null) || (items.length === 0))} />
                </div></>

            {(initProject + initPartner < 2) &&
                <>
                    {(error === "") &&
                        <div style={{ textAlign: "center", width: "1200px" }}><img src={loadinglogo} width="30" height="30" className="d-inline-block align-top" alt="" /></div>
                    }
                    {(error !== "") &&
                        <div style={{ color: 'red', textAlign: "center" }}>{error}</div>
                    }
                </>
            }
        </MainMenu>
        {/* <Modal show={deleteId!==""} onHide={handleDeleteClose}>
        <Modal.Header closeButton>
          <Modal.Title>ยืนยันการลบ?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleDeleteClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteConfirm}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal> */}
    </>)
}

export default TrainerSearchClass;