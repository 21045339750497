import { useEffect, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Util } from '../../api/util';
import MainMenu from './menu';
import Footer from './adminfooter';
import ClassTab from './classtab';
import { Button, Modal, Table } from 'react-bootstrap';
import loadinglogo from '../../img/loading.gif';
import excellogo from '../../img/excel.png';
import { EXPORT_HISTORY_URL, HistoryAPI } from '../../api/history-api';
import { getresult, gethistory, gettrainereachcourse, setseparategroup } from '../../store/history/actions';
import { queryclear } from '../../store/search/actions';
import { NOT_FOUND_LABEL } from '../../api/label';
import { createPage } from '../tab/pagetab';
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle';
import InfoIcon from '@material-ui/icons/Info';
import { StudentType } from '../../api/define';
import { MultiSelectComponent } from '../tab/multi-select-component';
import { getAccessToken } from '../../store/auth/selectors';
import { SingleSearch } from '../tab/search-component';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const EditHistory = () => {
    const token = useSelector(getAccessToken);
    const [initResult, setInitResult] = useState(0)
    const [initTrainer, setInitTrainer] = useState(0)
    const [initItem, setInitItem] = useState(0)
    const [page, setPage] = useState(0)
    const [limit, setLimit] = useState(10)
    const [name, setName] = useState("")
    const [separateCourse, setSeparateCourse] = useState("");
    const [separateIndex, setSeparateIndex] = useState(0);
    const [separateCourseId, setSeparateCourseId] = useState(0);
    const [separateStudentId, setSeparateStudentId] = useState(0);
    const [separateTrainerId, setSeparateTrainerId] = useState(0);
    const [partner, setPartner] = useState<any[]>([])
    const [course, setCourse] = useState<any[]>([])
    const [resultStatus, setResultStatus] = useState<any[]>([])
    const [loading, setLoading] = useState(false)
    const [resultItem, setResultItem] = useState<any>({})
    const [trainerItem, setTrainerItem] = useState<any>([])
    const [items, setItems] = useState<any>({});
    const [error, setError] = useState('');
    const [hint, setHint] = useState(false);
    const [separate, setSeparate] = useState(false);
    const store = useStore();
    const dispatch = useDispatch();
    const history = useHistory();
    let { id } = useParams<{ id: string }>();

    useEffect(() => {
        const unsubscribe = store.subscribe(() => {
            let res = store.getState().SearchReducer;
            if (res.isLoading) {
                setError('');
                setLoading(true);
            } else if (res.isLoaded) {
                setError('');
                setLoading(false);
                if (res.data != null) {
                    if (res.table === "result") {
                        setInitResult(1);
                        setResultItem(res.data);
                    } if (res.table === "trainer") {
                        setInitTrainer(1);
                        setTrainerItem(res.data);
                    } else {    //history
                        setInitItem(1);
                        setItems(res.data);
                    }
                } else {
                    setError(NOT_FOUND_LABEL);
                }
            } else if (res.isUpdated) {
                setSeparate(false);
                dispatch(gethistory(parseInt(id), HistoryAPI.getParam(name, partner, course, resultStatus), 0, limit));
            } else if (res.isNotFound) {
                setError(NOT_FOUND_LABEL);
                setItems([]);
                setLoading(false);
            } else if (res.isFailed) {
                setSeparate(false);
                setError(res.data.message);
                setItems([]);
                setLoading(false);
            }
        });
        return unsubscribe;
    }, [name, partner, course, resultStatus, limit])

    useEffect(() => {
        dispatch(queryclear());
        dispatch(gethistory(parseInt(id), "", 0, limit));
        dispatch(gettrainereachcourse(parseInt(id)));
        dispatch(getresult(parseInt(id)));
    }, []);

    const checkAssignment = () => {
        var courseList = (resultItem.courseList === undefined) ? [] : resultItem.courseList;
        for (var i = 0; i < courseList.length; i++) {
            var c = courseList[i];
            if ((c.isAssignment !== null) && (c.isAssignment !== undefined) && (c.isAssignment === true)) return true;
        }
        return false;
    }

    const handleClear = () => {
        setName("");
        setPartner([]);
        setCourse([]);
        setResultStatus([]);
    }

    const handleExcel = () => {
        window.open(EXPORT_HISTORY_URL + "/" + id + "?" + HistoryAPI.getParam(name, partner, course, resultStatus) + "&token=" + encodeURIComponent(token));
    }

    const handleSubmit = () => {
        setPage(0);
        dispatch(queryclear());
        dispatch(gethistory(parseInt(id), HistoryAPI.getParam(name, partner, course, resultStatus), 0, limit));
    }

    const handleLimit = (l: number) => {
        setLimit(l);
        dispatch(gethistory(parseInt(id), HistoryAPI.getParam(name, partner, course, resultStatus), page, l));
    }

    const handlePage = (l: number) => {
        setPage(l);
        dispatch(gethistory(parseInt(id), HistoryAPI.getParam(name, partner, course, resultStatus), l, limit));
    }

    const handleBack = () => {
        history.push("/admin/search/class");
    }

    const handleClass = () => {
        history.push("/admin/class/" + id);
    }

    const handleStudent = () => {
        history.push("/admin/class/" + id + "/student");
    }

    const handleDoc = () => {
        history.push("/admin/class/" + id + "/doc");
    }

    const handleAssignment = () => {
        history.push("/admin/class/" + id + "/assignment");
    }

    const handleResult = () => {
        history.push("/admin/class/" + id + "/result");
    }
    const handleSurvey = () => {
        history.push("/admin/class/" + id + "/survey");
    }



    const handleHintClose = () => {
        setHint(false);
    }

    const handleSeparateGroup = (id: number) => {
        var item = items.items[id];
        setSeparateCourse(item.courseShortName + ":" + item.courseName);
        setSeparateCourseId(item.classCourseId);
        setSeparateStudentId(item.classStudentId);
        setSeparateTrainerId(item.separateClassCourseSessionTrainerId);
        setSeparateIndex(item.separateIndex);
        setSeparate(true);
    }

    const handleSeparateSubmit = () => {
        dispatch(queryclear());
        dispatch(setseparategroup(separateCourseId, separateStudentId, separateTrainerId));
    }

    const handleSeparateClose = () => {
        setSeparate(false);
    }

    const setSessionTrainer = (e: any) => {
        var separateTrainerId = e.value;
        for (var i = 0; i < trainerItem.length; i++) {
            if (trainerItem[i].classCourseId === separateCourseId) {
                var items = trainerItem[i].items;
                for (var j = 0; j < items.length; j++)
                    if (items[j].classCourseSessionTrainerId === separateTrainerId) {
                        setSeparateIndex(j + 1);
                    }
            }
        }
        setSeparateTrainerId(separateTrainerId);
    }

    const getSessionTrainer = () => {
        for (var i = 0; i < trainerItem.length; i++) {
            if (trainerItem[i].classCourseId === separateCourseId) {
                var items = trainerItem[i].items;
                for (var j = 0; j < items.length; j++) {
                    if (items[j].classCourseSessionTrainerId === separateTrainerId) {
                        return { label: items[j].name, value: items[j].classCourseSessionTrainerId };
                    }
                }
            }
        }
    }

    const createSessiongTrainerFilter = () => {
        var id = separateCourseId;
        var res: any[] = [];
        for (var i = 0; i < trainerItem.length; i++) {
            if (trainerItem[i].classCourseId === id) {
                var items = trainerItem[i].items;
                for (var j = 0; j < items.length; j++) {
                    res.push({ label: items[j].name, value: items[j].classCourseSessionTrainerId });
                }
            }
        }
        return res;
    }

    const createItem = () => {
        var res: any[] = [];
        if (items.items !== undefined) {
            for (var i = 0; i < items.items.length;) {
                var item = items.items[i];
                var email = items.items[i].email;
                var show = false;
                while ((i < items.items.length) && (items.items[i].email === email)) {
                    var it = items.items[i];
                    if (it.courseName !== null) {
                        if (!show) {
                            res.push(<tr key={it.id}>
                                <td className='text-left'> {it.code}:{it.partner}</td>
                                <td> {it.generation} </td>
                                <td className='text-left'> {it.firstName} {it.lastName} </td>
                                <td className='text-left'> {it.email} </td>
                                <td className='text-left'> {it.courseShortName}:{it.courseName} </td>
                                <td> {it.separateIndex} </td>
                                {(((it.separateTrainer === null) || (it.separateTrainer === undefined)) && (it.isSeparate)) &&
                                    <td  id={i.toString()} className="link text-left" onClick={e => handleSeparateGroup(parseInt(e.currentTarget.id))}>ไม่มีกลุ่ม</td>
                                }
                                {(((it.separateTrainer === null) || (it.separateTrainer === undefined)) && (!it.isSeparate)) &&
                                    <td className='text-left'>ไม่มีกลุ่ม</td>
                                }
                                {((it.separateTrainer !== null) && (it.separateTrainer !== undefined)) &&
                                    <td id={i.toString()} className="link text-left" onClick={e => handleSeparateGroup(parseInt(e.currentTarget.id))}>{it.separateTrainer}</td>
                                }
                                <td> {getStatusName(it.result)}</td>
                                <td>{getSurvey(it.survey)}</td>
                            </tr>);
                            show = true;
                        } else {
                            res.push(<tr key={it.id}>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td className='text-left'> {it.courseShortName}:{it.courseName} </td>
                                <td> {it.separateIndex}</td>
                                {((it.separateTrainer === null) || (it.separateTrainer === undefined)) &&
                                    <td className='text-left'>ไม่มีกลุ่ม</td>
                                }
                                {((it.separateTrainer !== null) && (it.separateTrainer !== undefined)) &&
                                    <td id={i.toString()} className="link text-left" onClick={e => handleSeparateGroup(parseInt(e.currentTarget.id))}>{it.separateTrainer}</td>
                                }
                                <td> {getStatusName(it.result)}</td>
                                <td>{getSurvey(it.survey)}</td>
                            </tr>);
                        }
                        //if (result.length!==0) result.push(<br/>);
                        //result.push(it.courseName+":"+it.result);
                    }
                    i++;
                }
            }
        }
        return res;
    }

    const getTotalItem = () => {
        if (items.items === undefined) return 0;
        return items.totalItem;
    }

    const getTotalPage = () => {
        if (items.items === undefined) return 0;
        return items.totalPage;
    }

    const createPartnerSearchFilter = () => {
        var res: any[] = [];
        if (resultItem.partner !== undefined) {
            for (var i = 0; i < resultItem.partner.length; i++) {
                res.push({ label: resultItem.partner[i].name, value: resultItem.partner[i].id });
            }
        }
        return res;
    }

    const createCourseSearchFilter = () => {
        var res: any[] = [];
        if (resultItem.summary !== undefined) {
            for (var i = 0; i < resultItem.summary.length; i++) {
                res.push({ label: resultItem.summary[i].shortName+":"+resultItem.summary[i].name, value: resultItem.summary[i].classCourseId });
            }
        }
        return res;
    }

    const createStatusSearchFilter = () => {
        var res: any[] = [];
        if (resultItem.classRoomStatus !== undefined) {
            for (var i = 0; i < resultItem.classRoomStatus.length; i++) {
                //check dup code
                var found = false;
                for (var j = 0; j < res.length; j++) {
                    if (res[j].value === resultItem.classRoomStatus[i].code) found = true;
                }
                if (!found)
                    res.push({ label: resultItem.classRoomStatus[i].name, value: resultItem.classRoomStatus[i].code });
            }
        }
        return res;
    }

    const getStatusName = (code: string) => {
        var status = resultItem.classRoomStatus;
        if (status !== undefined) {
            for (var i = 0; i < status.length; i++) {
                if (status[i].code === code) return status[i].name;
            }
        }
        return code;
    }
    const getSurvey = (survey: 'PASS' | 'FAIL' | 'NA') => {
        switch(survey){
            case 'FAIL': return <><CancelIcon className="red"/></>
            case 'PASS': return <><CheckCircleIcon className='green'/></>
            case 'NA': return <>N/A</>
        }
    }


    const createHint = () => {
        var res: any[] = [];
        if (resultItem.classRoomStatus !== undefined) {
            var status = resultItem.classRoomStatus;
            for (var i = 0; i < status.length; i++) {
                //check dup code
                var found = false;
                for (var j = 0; j < i; j++) {
                    if (status[j].code === status[i].code) found = true;
                }
                if (!found) {
                    res.push(<tr><td>{status[i].code}</td><td>{status[i].name}</td></tr>);
                }
            }
        }
        return res;
    }

    const summary = (s: any[], result: string) => {
        var c = 0;
        for (var i = 0; i < s.length; i++) {
            if (s[i].result === result) c = c + s[i].number;
        }
        return c;
    }

    const toggleSummary = (id: string) => {
        var newItem: any = { ...resultItem };
        var v = parseInt(id);
        if ((newItem.summary[v].expanded === undefined) ||
            (newItem.summary[v].expanded === false))
            newItem.summary[v].expanded = true;
        else
            newItem.summary[v].expanded = false;
        setResultItem(newItem);
    }

    return (<>
        <MainMenu>
            <div>
                <div className="query-wrapper">
                    <br />
                    <div className="yellow-button" style={{ textAlign: "left", borderTopLeftRadius: "30px", borderTopRightRadius: "30px", height: "70px", paddingTop: "15px", paddingLeft: "15px" }}><h4 className="header">รอบการเรียน</h4></div>
                    {((initResult === 1) && (initItem === 1)) &&
                        <>
                            <div className="light-gray-button" style={{ borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px', paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", textAlign: "left" }}>
                                <div className="row">
                                    <div className="col-4">
                                        <label className="col-form-label">โครงการ</label><br />
                                        <input className="form-control" type="text" value={resultItem.code + ":" + resultItem.name} readOnly={true} />
                                    </div>
                                    <div className="col-1">
                                        <label className="col-form-label">รอบ</label><br />
                                        <input className="form-control" type="text" value={resultItem.session} readOnly={true} />
                                    </div>
                                    <div className="col-2">
                                        <label className="col-form-label">วันที่เริ่ม</label>
                                        <input className="form-control" type="text" value={Util.datetostr(new Date(resultItem.fromDate))} readOnly={true} />
                                    </div>
                                    <div className="col-2">
                                        <label className="col-form-label">วันที่สิ้นสุด</label>
                                        <input className="form-control" type="text" value={Util.datetostr(new Date(resultItem.toDate))} readOnly={true} />
                                    </div>
                                    <div className="col-3">
                                        <label className="col-form-label">ฝ่ายผู้รับผิดชอบโครงการ</label><br />
                                        <input className="form-control" type="text" value={resultItem.organizer} readOnly={true} />
                                    </div>
                                </div>
                            </div>
                            <br />
                            {ClassTab(5, true, checkAssignment(), true, handleClass, handleStudent, handleDoc, handleAssignment, null, handleResult,handleSurvey)}
                            <br />
                            {(resultItem.studentType === StudentType.NUMBER) &&
                                <>ผู้เรียนเป็นแบบระบุจำนวน</>
                            }
                            {(resultItem.studentType === StudentType.LIST) &&
                                <>
                                    <div className="light-gray-button" style={{ position: "relative", textAlign: "left", padding: "20px", borderRadius: "5px" }}>
                                        <div className="row" style={{ paddingRight: "40px" }}>
                                            <div className="col-1" style={{ textAlign: "right", paddingTop: "5px" }}>
                                                <label>ผู้เรียน</label>
                                            </div>
                                            <div className="col-2">
                                                <input className="form-control" value={name} onChange={(e) => { setName(e.target.value) }} style={{ width: "200px" }} />
                                            </div>
                                            <div className="col-1" style={{ textAlign: "right", paddingTop: "5px" }}>
                                                <label>องค์กร</label>
                                            </div>
                                            <div className="col-2">
                                                <MultiSelectComponent
                                                    options={createPartnerSearchFilter()}
                                                    value={partner}
                                                    onChange={setPartner}
                                                />
                                            </div>
                                            <div className="col-1" style={{ textAlign: "right", paddingTop: "5px" }}>
                                                <label>หลักสูตร</label>
                                            </div>
                                            <div className="col-2">
                                                <MultiSelectComponent
                                                    options={createCourseSearchFilter()}
                                                    value={course}
                                                    onChange={setCourse}
                                                />
                                            </div>
                                            <div className="col-1" style={{ textAlign: "right", paddingTop: "5px" }}>
                                                <label>สถานะ</label>
                                            </div>
                                            <div className="col-2">
                                                <MultiSelectComponent
                                                    options={createStatusSearchFilter()}
                                                    value={resultStatus}
                                                    onChange={setResultStatus}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="row" style={{ justifyContent: "center" }}>
                                        <button type="button" className="btn btn-outline-primary" style={{ width: "150px" }}
                                            onClick={() => handleClear()} >
                                            Clear
                                        </button>&nbsp;
                                        <button type="button" className="btn btn-primary" style={{ width: "150px" }}
                                            onClick={() => handleSubmit()} disabled={loading}>
                                            <div>
                                                {(loading) &&
                                                    <div style={{ textAlign: "center" }}><img src={loadinglogo} width="30" height="30" className="d-inline-block align-top" alt="" /></div>
                                                }
                                                {(!loading) &&
                                                    <>Search</>
                                                }
                                            </div>
                                        </button>
                                    </div>
                                    <br />
                                    {   //summary
                                        ((resultItem.summary !== undefined) && (resultItem.summary.length > 0)) &&
                                        <>
                                            {resultItem.summary.map((item: any, index: any) => {
                                                return <>
                                                    <div className='row'>
                                                        <div className="col-2 yellow-button text-center" style={{ position: "relative", textAlign: "left", padding: "20px", borderTopLeftRadius: "5px", borderBottomLeftRadius: "5px" }}>
                                                            {item.shortName}
                                                        </div>
                                                        <div className="col-10 light-gray-button" style={{ position: "relative", textAlign: "left", padding: "20px", borderTopRightRadius: "5px", borderBottomRightRadius: "5px" }}>
                                                            <div className="row">
                                                                <div className="col-6">
                                                                    {item.name}
                                                                </div>
                                                                <div className="col-6" style={{ textAlign: "right" }}>
                                                                    ผ่าน <span className="green">{summary(item.summaryStatus, "P") + summary(item.summaryStatus, "PX")}</span> ไม่ผ่าน <span className="red">{summary(item.summaryStatus, "F")}</span> ผู้เรียนทั้งหมด <span className="blue">{summary(item.summaryStatus, "ALL") + resultItem.walkin[index]}</span>
                                                                    &nbsp;<ArrowDropDownCircleIcon id={index} onClick={(e) => { toggleSummary(e.currentTarget.id) }}></ArrowDropDownCircleIcon>
                                                                </div>
                                                            </div>
                                                            {(item.expanded === true) &&
                                                                <>
                                                                    <div className="row">
                                                                        <div className="col-12" style={{ textAlign: "right" }}>
                                                                            ลงทะเบียน <span className="blue">{summary(item.summaryStatus, "E")}</span> ยืนยัน <span className="blue">{summary(item.summaryStatus, "C")}</span> ยังไม่เรียน <span className="blue">{summary(item.summaryStatus, "N")}</span> ยกเลิก <span className="red">{summary(item.summaryStatus, "X")}</span>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            }
                                                        </div>
                                                    </div>
                                                    <br />
                                                </>
                                            })
                                            }
                                        </>
                                    }
                                    <div className="row">
                                        <div className="col-5" style={{ justifyContent: "left", textAlign: "left", marginTop: "auto", marginBottom: "auto" }}>
                                            จำนวนทั้งหมด {Util.integerWithCommas(getTotalItem())} รายการ
                                        </div>
                                        <div className="col-1">
                                        </div>
                                        <div className="col-6 row" style={{ justifyContent: "right", paddingRight: "0px" }}>
                                            <div style={{ textAlign: "right", marginTop: "auto", marginBottom: "auto" }}>จำนวนรายการต่อหน้า</div>&nbsp;
                                            <select className="form-control" style={{ width: "80px" }} name="page" value={limit} onChange={e => handleLimit(parseInt(e.target.value))}>
                                                <option value="0">All</option>
                                                <option value="10">10</option>
                                                <option value="20">20</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>&nbsp;
                                            <Button type="button" className="btn btn-success green-button" style={{ width: "50px" }}
                                                onClick={() => handleExcel()} >
                                                <img src={excellogo} height="32px" alt="excel" style={{ marginLeft: "-7px" }} />
                                            </Button>
                                        </div>
                                    </div>
                                    { //history
                                        ((items.items !== undefined) && (items.items.length >= 1)) &&
                                        <>
                                            <br />
                                            <Table striped hover>
                                                <thead className="black-button">
                                                    <tr>
                                                        <th className='text-left'>องค์กร</th>
                                                        <th >รุ่นที่</th>
                                                        <th className='text-left'>ชื่อผู้เรียน</th>
                                                        <th className='text-left'>e-Mail</th>
                                                        <th className='text-left'>หลักสูตร</th>
                                                        <th>กลุ่มที่</th>
                                                        <th className='text-left'>วิทยากรที่ดูแล</th>
                                                        <th>สถานะการเรียน</th>
                                                        <th>แบบประเมินผล</th>
                                                    </tr>
                                                </thead>
                                                <tbody style={{ fontSize:"23px" }}>
                                                    {createItem()}
                                                </tbody>
                                            </Table>
                                            <br />

                                            <div className="row" style={{ justifyContent: "center", position: "relative" }}>
                                                {createPage(page, getTotalPage(), handlePage)}
                                                <div className="col-5" style={{ justifyContent: "left", textAlign: "left", position: "absolute", left: "0px" }}>
                                                    จำนวนทั้งหมด {Util.integerWithCommas(getTotalItem())} รายการ
                                                </div>
                                            </div>
                                        </>
                                    }
                                </>
                            }
                        </>
                    }
                    <br />
                    {(((initResult + initItem < 2) || (loading)) && (error === "")) &&
                        <div style={{ textAlign: "center" }}><img src={loadinglogo} width="30" height="30" className="d-inline-block align-top" alt="" /></div>
                    }
                    {(error !== "") &&
                        <div style={{ color: 'red', textAlign: "center" }}>{error}</div>
                    }
                    {
                        <div className="row">
                            <div className="col-2" style={{ justifyContent: "left", textAlign: "left" }}>
                                <InfoIcon onClick={(e: any) => setHint(true)}></InfoIcon>
                            </div>
                            <div className="col-8"></div>
                            <div className="col-2" style={{ paddingRight: "0px" }}>
                                <button type="button" className="btn btn-outline-primary" style={{ width: "120px" }}
                                    onClick={() => handleBack()} >
                                    Back
                                </button>
                            </div>
                        </div>
                    }
                </div>
                <br />
                <Footer />
            </div>
        </MainMenu>
        <Modal show={hint === true} onHide={handleHintClose}>
            <Modal.Header closeButton>
                <Modal.Title>สถานะ</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <table>
                    {createHint()
                    }
                </table>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleHintClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>

        <Modal show={separate === true} onHide={handleSeparateClose}>
            <Modal.Header closeButton>
                <Modal.Title>จัดการกลุ่มการเรียน</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                หลักสูตร : {separateCourse}<br />
                กลุ่มที่ : {separateIndex}<br />
                วิทยากร:
                <SingleSearch isSearchable={true} isMulti={false} placeholder=""
                    onChange={(id: string, e: any) => setSessionTrainer(e)}
                    value={getSessionTrainer()}
                    options={createSessiongTrainerFilter()}
                />
                <br />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleSeparateSubmit}>
                    Submit
                </Button>
                <Button variant="secondary" onClick={handleSeparateClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    </>)
}

export default EditHistory;
