import { message } from 'antd';
import React, { useEffect, useState } from 'react'
import MainMenu from '../../admin/menu';
import { AlertContainer,alert } from 'react-custom-alert';
import { useHistory, useParams } from 'react-router-dom';
import { PartnerProfileListResponse } from '../../../model/response/partner-profile';
import PartnerFillMasterDataSuggestion from './component/PartnerFillMasterDataSuggestion';
import PartnerGeneralInfo from '../../admin/managePartner/component/PartnerGeneralInfo';
import { AdressResponse } from '../../../model/response/adress.model';
import { useDispatch, useStore } from 'react-redux';
import PartnerOrgPrivateCompany from '../../admin/managePartner/component/PartnerOrgPrivateCompany';
import PartnerOrgGoverment from '../../admin/managePartner/component/PartnerOrgGoverment';
import PartnerOrgIndepandent from '../../admin/managePartner/component/PartnerOrgIndepandent';
import PartnerUniversity from '../../admin/managePartner/component/PartnerUniversity';
import PartnerVocation from '../../admin/managePartner/component/PartnerVocation';
import PartnerSchool from '../../admin/managePartner/component/PartnerSchool';
import PartnerWelfareBenefit from '../../admin/managePartner/component/PartnerWelfareBenefit';
import { queryclear } from '../../../store/search/actions';
import { getAdressInfomation, getFaculty, getOrganization, updatePartnerProfilePublic } from '../../../store/publicPartner/action';
import PartnerInfoPublic from './component/PartnerInfoPublic';
import PartnerKnowledgeList from '../../admin/managePartner/component/PartnerKnowledgeList';

type Props = {}

const PartnerFillMasterData = (props: Props) => {
    const [init, setInit] = useState(1);
    const [partnerProfileList, setPartnerProfileList] = useState<PartnerProfileListResponse>();
    const [orgTypeItem, setOrgTypeItem] = useState<any[]>([])
    const [province, setProvince] = useState<AdressResponse[]>([]);
    const [facultyItem, setFacultyItem] = useState<any[]>([])
    const [success, setSuccess] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [edit, setEdit] = useState(false);
    const [error, setError] = useState('');
    const store = useStore();
    const history = useHistory();
    const [messageApi, contextHolder] = message.useMessage();
    const dispatch = useDispatch();
    let { userKey } = useParams<{ userKey: string }>();
    let { code } = useParams<{ code: string }>();
    let { key } = useParams<{ key: string }>();
    useEffect(() => {
        const unsubscribe = store.subscribe(() => {
            let res = store.getState().SearchReducer;
            console.log(res);
            if (res.isLoading) {
                setError('');
                setLoading(true);
            } else if ((res.isLoaded) && (!res.isUpdated)) {
                setError('');
                setLoading(false);
                if (res.data != null) {
                    if (res.table === "org_type") {
                        setOrgTypeItem(res.data);
                    } else if (res.table === "address-infomation") {
                        setProvince(res.data);
                    } else if (res.table === "faculty") {
                        setFacultyItem(res.data);
                    } 
                }
            } else if (res.isUpdating) {
                setError('');
                setLoading(true);
            } else if (res.isUpdated) {
                setError('');
                setLoading(false);
                setSuccess(true);
                dispatch(queryclear());
                if (res.table == 'update_partner_profile') {
                    alert({ message: 'บันทึกข้อมูลสำเร็จ', type: 'success' });
                    setTimeout(() => { history.push('/partner/fill-master/thank') }, 300)
                }


            } else if (res.isFailed) {
                setError(res.data.message);
                setLoading(false);
                messageApi.error({
                    type: 'error',
                    content: res.data.message,
                    className: 'custom-class'
                });
                // alert({ message: , type: 'error' });
            }

        })
        dispatch(getOrganization(0, 0, true));
        dispatch(getAdressInfomation())
        dispatch(getFaculty())
        if (sessionStorage.getItem("partnerList")) {
            let partnerList = JSON.parse(sessionStorage.getItem("partnerList") as any) as PartnerProfileListResponse
            setPartnerProfileList(partnerList)
        } else {
            history.push("/partner")
        }
        return unsubscribe;
    }, []);

    const getRequire = (value: string | boolean | undefined | any[] | null | number) => {
        return (submitted && (value == null || value == undefined || value == ''))
    }
    const validateRequired = (value: string | boolean | undefined | any[] | null | number) => {
        return (value == null || value == undefined || value == '')
    }
    const validateRequireBenefit = (check: boolean, value: string | boolean | undefined | any[] | null | number) => {
        if (check) {
            return (value == null || value == undefined || value == '')
        } else {
            return false
        }
    }
    const getOrgTypeName = (id: any) => {
        if (orgTypeItem) {
            let orgTypeName = orgTypeItem.find(x => x.id == id)?.name;
            return orgTypeName ? orgTypeName : null
        }
        return null

    }
    const getOrgGroupName = (id: any) => {
        let orgGroup = null
        if (orgTypeItem) {

            orgTypeItem.forEach(x => {
                let orgGroupName = x.orgGroup.find((y: any) => y.id == id);
                if (orgGroupName) {
                    orgGroup = orgGroupName.name
                }
            })

        }
        return orgGroup
    }
    const invalidGeneralInfo = () => {
        let error = false;
        if (validateRequired(partnerProfileList?.orgType) ||
            validateRequired(partnerProfileList?.orgGroup) ||
            validateRequired(partnerProfileList?.houseNo) ||
            validateRequired(partnerProfileList?.province) ||
            validateRequired(partnerProfileList?.district) ||
            validateRequired(partnerProfileList?.subDistrict) ||
            validateRequired(partnerProfileList?.zipCode)) {
            error = true;
        }
        return error
    }
    const invalidOrganization = () => {
        let error = false
        if ((getOrgTypeName(partnerProfileList?.orgType) == 'เอกชน' || getOrgTypeName(partnerProfileList?.orgType) == 'รัฐวิสาหกิจ')) {
            if (validateRequired(partnerProfileList?.employee)) {
                error = true;
            }
        } else if ((getOrgTypeName(partnerProfileList?.orgType) == 'รัฐ')) {
            if (validateRequired(partnerProfileList?.personnel) ||
                validateRequired(partnerProfileList?.governmentOfficer) ||
                validateRequired(partnerProfileList?.governmentEmployee) ||
                validateRequired(partnerProfileList?.employee) ||
                validateRequired(partnerProfileList?.memberUnderSupervision)) {
                error = true;
            }
        } else if ((getOrgTypeName(partnerProfileList?.orgType) == 'องค์กรอิสระ')) {
            if (validateRequired(partnerProfileList?.employee) ||
                validateRequired(partnerProfileList?.memberUnderSupervision)) {
                error = true;
            }
        } else if ((getOrgTypeName(partnerProfileList?.orgType) == 'สถาบันการศึกษา')) {
            if (getOrgGroupName(partnerProfileList?.orgGroup) == 'มหาวิทยาลัย') {
                error = invalidUniversity()
            } else if (getOrgGroupName(partnerProfileList?.orgGroup) == 'วิทยาลัย') {
                error = invalidUniversity()
            } else if (getOrgGroupName(partnerProfileList?.orgGroup) == 'อาชีวศึกษา') {
                error = invalidVocation()
            } else {
                error = validateRequired(partnerProfileList?.teacherNo) ||
                    validateRequired(partnerProfileList?.personnel) ||
                    validateRequired(partnerProfileList?.studentNo)
            }
        }
        return error;
    }
    const invalidUniversity = () => {
        let error = false;
        if (validateRequired(partnerProfileList?.teacherNo) ||
            validateRequired(partnerProfileList?.personnel) ||
            validateRequired(partnerProfileList?.studentNo)) {
            error = true;
        }
        if (partnerProfileList?.faculty && partnerProfileList?.faculty.length > 0) {
            partnerProfileList?.faculty.forEach(faculty => {
                faculty.facultyInfo && faculty.facultyInfo.forEach(x => {
                    if (x.facultyName == 0) {
                        error = validateRequired(x.otherFacultyName) || validateRequired(x.teacherNo) || validateRequired(x.personnel) || validateRequired(x.studentNo)
                    } else {
                        console.log("Test", validateRequired(x.studentNo))
                        error = validateRequired(x.teacherNo) || validateRequired(x.personnel) || validateRequired(x.studentNo)
                    }
                })
                faculty.branchInfo && faculty.branchInfo.forEach(x => {
                    error = validateRequired(x.branchName) || validateRequired(x.teacherNo) || validateRequired(x.teacherNo) || validateRequired(x.personnel) || validateRequired(x.studentNo)

                })
            })
        }
        return error
    }
    const invalidVocation = () => {
        let error = false;
        if (validateRequired(partnerProfileList?.teacherNo) ||
            validateRequired(partnerProfileList?.personnel) ||
            validateRequired(partnerProfileList?.studentNo)) {
            error = true;
        }
        if (partnerProfileList?.faculty && partnerProfileList?.faculty.length > 0) {
            partnerProfileList?.faculty.forEach(faculty => {
                faculty.branchInfo && faculty.branchInfo.forEach(x => {
                    error = validateRequired(x.branchName) || validateRequired(x.teacherNo) || validateRequired(x.teacherNo) || validateRequired(x.personnel) || validateRequired(x.studentNo)

                })
            })
        }
        return error
    }
    const invalidWelfareBenefit = () => {
        let error = false;
        if (validateRequireBenefit(partnerProfileList?.checkProvidentFund as any, partnerProfileList?.providentFundAmount)) {
            error = true;
        }
        if (validateRequireBenefit(partnerProfileList?.checkSocialSecurity as any, partnerProfileList?.socialSecurityAmount)) {
            error = true;
        }
        if (validateRequireBenefit(partnerProfileList?.checkGovPensionFund as any, partnerProfileList?.govPensionFundAmount)) {
            error = true;
        }
        if (validateRequireBenefit(partnerProfileList?.checkSavingsCooperative as any, partnerProfileList?.savingsCooperativeAmount)) {
            error = true;
        }
        if (partnerProfileList?.welfareBenefitList && partnerProfileList?.welfareBenefitList.length > 0) {
            error = partnerProfileList?.welfareBenefitList.some(x => (x.welfareBenefitName == null || x.welfareBenefitName == '' || validateRequired(x.memberAmount)))
        }
        return error
    }
    const invalidPartnerInfo = () => {
        let error = false;
        if(!partnerProfileList?.partnerList || partnerProfileList?.partnerList.length <= 0){
            error = true
        }
        return error;
    }
    const handleClear = () => {
        if (sessionStorage.getItem("partnerList")) {
            let partnerList = JSON.parse(sessionStorage.getItem("partnerList") as any) as PartnerProfileListResponse
            setPartnerProfileList(partnerList)
        }
    }
    const handleSubmit = () => {
        let messageError = [];
        setSubmitted(true);
        if (invalidGeneralInfo()) {
            messageError.push('กรุณากรอกข้อมูลทั่วไปให้ครบถ้วน')
        }
        if (partnerProfileList?.showOrganizationInfo && invalidOrganization()) {
            messageError.push('กรุณากรอกข้อมูลองค์กรให้ครบถ้วน')
        }
        if (partnerProfileList?.showWelfareBenefit && invalidWelfareBenefit()) {
            messageError.push('กรุณากรอกข้อมูลสวัสดิการการออมให้ครบถ้วน')
        }
        if (partnerProfileList?.showPartner && invalidPartnerInfo()) {
            messageError.push('กรุณากรอกผู้ประสานงานอย่างน้อย 1 คน')
        }
        if (messageError.length > 0) {
            let content = <span className='text-left'>
                <b>กรุณากรอกแบบฟอร์มให้ครบ&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</b>
                {messageError.map(x => (<div>
                    - {x}
                </div>))}
            </span>
            messageApi.error({
                type: 'error',
                content: content,
                className: 'custom-class'
            });
        } else {
            const body = { ...partnerProfileList }
            dispatch(updatePartnerProfilePublic(body as any,userKey,code,key))
        }

    }
    return (
        <div>
            {contextHolder}
                <div>
                    {init == 1 && (
                        <div className="query-wrapper">
                            <br />
                            <div className="yellow-button" style={{ textAlign: "left", borderTopLeftRadius: "30px", borderTopRightRadius: "30px", height: "70px", paddingTop: "15px", paddingLeft: "15px" }}><h4 className="header">บริหารจัดการข้อมูลองค์กรพันธมิตร</h4></div>
                            <br />
                            <PartnerFillMasterDataSuggestion />
                            <div style={{ background: "white" }} className='mt-2'>
                                <PartnerGeneralInfo parnerProfileList={partnerProfileList as any}
                                    success={success}
                                    orgTypeItem={orgTypeItem}
                                    submitted={submitted}
                                    province={province}
                                    readonly={false}
                                    isDisableOrganizeGroup={true}
                                    setParnerProfileListChange={setPartnerProfileList}
                                />
                            </div>
                            {partnerProfileList?.showOrganizationInfo && <React.Fragment>
                                {(getOrgTypeName(partnerProfileList?.orgType) == 'เอกชน' || getOrgTypeName(partnerProfileList?.orgType) == 'รัฐวิสาหกิจ') && (
                                    <PartnerOrgPrivateCompany parnerProfileList={partnerProfileList as any}
                                        submitted={submitted}
                                        readonly={false}
                                        setParnerProfileListChange={setPartnerProfileList}
                                        success={success} />
                                )}
                                {(getOrgTypeName(partnerProfileList?.orgType) == 'รัฐ') && (
                                    <PartnerOrgGoverment parnerProfileList={partnerProfileList as any}
                                        submitted={submitted}
                                        readonly={false}
                                        setParnerProfileListChange={setPartnerProfileList}
                                        success={success} />
                                )}
                                {(getOrgTypeName(partnerProfileList?.orgType) == 'องค์กรอิสระ') && (
                                    <PartnerOrgIndepandent parnerProfileList={partnerProfileList as any}
                                        submitted={submitted}
                                        readonly={false}
                                        setParnerProfileListChange={setPartnerProfileList}
                                        success={success} />
                                )}
                                {(getOrgTypeName(partnerProfileList?.orgType) == 'สถาบันการศึกษา') && (<>
                                    {(getOrgGroupName(partnerProfileList?.orgGroup) == 'มหาวิทยาลัย') && (
                                        <PartnerUniversity parnerProfileList={partnerProfileList as any}
                                            submitted={submitted}
                                            facultyItem={facultyItem}
                                            setParnerProfileListChange={setPartnerProfileList}
                                            readonly={false}
                                            success={success} />
                                    )}
                                    {(getOrgGroupName(partnerProfileList?.orgGroup) == 'วิทยาลัย') && (
                                        <PartnerUniversity parnerProfileList={partnerProfileList as any}
                                            submitted={submitted}
                                            facultyItem={facultyItem}
                                            setParnerProfileListChange={setPartnerProfileList}
                                            readonly={false}
                                            success={success} />
                                    )}
                                    {(getOrgGroupName(partnerProfileList?.orgGroup) == 'อาชีวศึกษา') && (
                                        <PartnerVocation parnerProfileList={partnerProfileList as any}
                                            submitted={submitted}
                                            setParnerProfileListChange={setPartnerProfileList}
                                            readonly={false}
                                            success={success} />
                                    )}
                                    {(getOrgGroupName(partnerProfileList?.orgGroup) == 'โรงเรียน') && (
                                        <PartnerSchool parnerProfileList={partnerProfileList as any}
                                            submitted={submitted}
                                            setParnerProfileListChange={setPartnerProfileList}
                                            readonly={false}
                                            success={success} />
                                    )}
                                    {(getOrgGroupName(partnerProfileList?.orgGroup) == 'อื่น ๆ') && (
                                        <PartnerSchool parnerProfileList={partnerProfileList as any}
                                            submitted={submitted}
                                            setParnerProfileListChange={setPartnerProfileList}
                                            readonly={false}
                                            success={success} />
                                    )}

                                </>)}
                            </React.Fragment>}
                            {partnerProfileList?.showWelfareBenefit && (
                                <div style={{ background: "white" }} className='mt-2'>
                                    <PartnerWelfareBenefit parnerProfileList={partnerProfileList as any}
                                        success={success}
                                        readonly={false}
                                        submitted={submitted}
                                        setParnerProfileListChange={setPartnerProfileList}
                                    />
                                </div>
                            )}
                            {partnerProfileList?.showPartner && (
                                <div style={{ background: "white" }} className='mt-2'>
                                    <PartnerInfoPublic parnerProfileList={partnerProfileList as any}
                                        success={success}
                                        submitted={submitted}
                                        readonly={false}
                                        handleResendEmail={() => { }}
                                        hideResendEmail={true}
                                        setParnerProfileListChange={setPartnerProfileList} />
                                </div>
                            )}
                            <div>
                                <PartnerKnowledgeList parnerProfileList={partnerProfileList as any}
                                    hasSummaryLink={true}
                                    success={success}
                                    submitted={submitted}
                                    setParnerProfileListChange={setPartnerProfileList} />
                            </div>
                            {(!success) &&
                                <div className='d-flex mt-3'>
                                    <div className='ml-auto mb-3'>
                                        <button type="button" className="btn btn-warning" style={{ width: "120px" }}
                                            onClick={() => handleClear()} >
                                            Clear
                                        </button>&nbsp;
                                        <button type="button" className="btn btn-success" style={{ width: "120px" }}
                                            onClick={() => handleSubmit()} >
                                            Save
                                        </button>
                                    </div>
                                </div>
                            }
                        </div>
                    )}
                </div>
            {/* <AlertContainer floatingTime={3000} /> */}
        </div>
    )
}

export default PartnerFillMasterData

