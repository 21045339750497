import React, { useEffect } from 'react'
import { Button, Modal, ModalProps } from 'react-bootstrap'
import { AnnouceResponse } from '../../model/response/announce.model';
import noPhoto from '../../img/noPhoto.png';
import styled from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';
import LinkIcon from '@material-ui/icons/OpenInNew';
import { Util } from '../../api/util';
import { orange } from '@material-ui/core/colors';
type StudentLandingAnnounceModalProps = ModalProps &
{
    item: AnnouceResponse
}
const CustomModal = styled(Modal)`
    .description{
        color: #5F6062;
        margin-top: 10px;
    }
    .button-close{
        position: absolute;
        top:-30px;
        right: -30px;
        color:white;
        &:hover{
            cursor: pointer;
            transform:scale(1.3,1.3);
            -webkit-transform:scale(1.3,1.3);
            -moz-transform:scale(1.3,1.3);
        }
    }
`
export default function StudentLandingAnnounceModal(props: StudentLandingAnnounceModalProps) {
    return (
        <CustomModal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <a className='button-close' onClick={props.onHide}><CloseIcon /></a>
            <Modal.Body>
                <div style={{ padding: 50 }}>
                    <div className='d-flex'>
                        <img src={props.item?.banner} height='270' width='480' alt="..." onError={(e: any) => { e.target.src = noPhoto }} />
                        <div className='mx-3'>
                            <h5 style={{color:'orange'}}>{props.item?.topic}</h5>
                            {props.item?.createDate && (<span>{Util.datetimetostr(new Date(props.item?.createDate))}</span>)}

                        </div>
                    </div>

                    <p className='description'>
                        {props.item?.description}
                    </p>
                    <p>
                        {props.item?.externalLink && ( <a href={props.item?.externalLink} target='_blank'><LinkIcon /> {props.item?.externalLink}</a>)}
                       
                    </p>
                </div>

            </Modal.Body>
        </CustomModal>
    )
}