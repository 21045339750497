import { useEffect } from 'react';
import loadinglogo from '../../img/loading.gif';
import { SET_MEMBER_SERVER } from '../../api/conf';

const StudentRedirect = (props:any) => {
    const redirectPath = props.pathname;
    const isRedirect = props.isRedirect;
    useEffect(() => {
        if (SET_MEMBER_SERVER) {
            console.log(isRedirect)
            if(isRedirect){
                checkRedirectIsRedirect()
            }
            window.location.href = SET_MEMBER_SERVER;
        }
    },[]);
    const checkRedirectIsRedirect = () => {
        sessionStorage.setItem('redirectPath',redirectPath);

    }
    return <>
        <div style={{
            textAlign: "center", display: "block",
            marginLeft: "auto",
            marginRight: "auto",
            width: "50%"
        }}><img src={loadinglogo} width="30" height="30" className="d-inline-block align-top" alt="" /></div>
    </>;
}

export default StudentRedirect;