import styled from "styled-components";

export const Banner = styled.img`
    width: 780px;
`
export const PartnerContainer = styled.div`
    max-width: 780px;
    display: block;
    margin-right: auto;
    margin-left: auto;
    padding-bottom: 20px;
    div{
        font-size: 20px;
        
    }
`
export const PartnerSummaryContainer = styled.div`
    
    display: block;
    margin-right: 60px;
    margin-left: 60px;
    padding-bottom: 100px;
    div{
        font-size: 20px;
        text-align: left; 
    }
`
export const CardBox = styled.div`
    background: linear-gradient(0deg, #FFFFFF, #FFFFFF),linear-gradient(0deg, #D5F0F6, #D5F0F6);
    border: 1px solid #D5F0F6;
    box-shadow: 0px 14px 16px 0px #00000005;
    padding: 20px 30px 20px 30px;
    text-align: left;
`

export const PButton = styled.button`
    display: block ;
    padding: 6px 12px 6px 12px;
    border-radius: 4px;
    font-size: 20px;
    border: none;
    gap: 4px;
    background: linear-gradient(180deg, #FFCF36 0%, #FCB034 88.02%);
    transition: 0.5s;
    &:hover{
        filter: brightness(85%);
    }

`

export const PButtonBack = styled.button`
    display: block ;
    padding: 6px 12px 6px 12px;
    border-radius: 4px;
    font-size: 20px;
    border: 1px solid #0000001A;
    gap: 4px;
    background: linear-gradient(180deg, #FFFFFF 0%, #EFEFEF 88.02%),linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
    transition: 0.5s;
    &:hover{
        filter: brightness(85%);
    }

`

export const Dot = styled.div<{ active?: boolean }>`
  height: 15px;
  width: 15px;
  background-color: ${p => p.active ? '#03a439' : '#ff0000'};
  border-radius: 50%;
  display: inline-block;
`

export const CustomChip = styled.span<{background?: string,color?:string}>`
    margin-left: 10px;
    padding: 1px 12px 1px 12px;
    border-radius: 5px;
    background:  ${p => p.background ? p.background : 'black'};
    font-size: 17px;
    color: ${p => p.color ? p.color : 'white'};
`