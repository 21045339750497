import React, { useEffect, useState } from 'react';
import { useDispatch, useStore, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import StudentFooter from '../student/studentfooter';
import loadinglogo from '../../img/loading.gif';
import { AlertContainer, alert } from 'react-custom-alert';
import 'react-custom-alert/dist/index.css'; // import css file from root.
import ArrowDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle';
import { queryclear } from '../../store/search/actions';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutline from '@material-ui/icons/RemoveCircleOutline';
import { Container, Table, Row, Col, Button, Modal } from 'react-bootstrap';
import { saveassignment, getassignment, getassignmentbyemail, uploadadditional } from '../../store/etoolkitassignment/actions';
import { getAccessToken, getEmail, getIsStudent, getIsTrainer } from '../../store/auth/selectors';
import { ETOOLKIT_ADDITIONAL_URL } from '../../api/etoolkit-api';
import { MAX_UPLOAD_FILE_SIZE_LABEL, REQUIRE_LABEL } from '../../api/label';
import { MAX_FILE_UPLOAD } from '../../api/conf';
import excellogo from '../../img/excel.png';
import { GET_DOC_PAGE_STUDENT_URL } from '../../api/page-student-api';
import TrainerComment from './trainerComment';
import { Util } from '../../api/util';

const EToolKitAssignment = () => {
    const token = useSelector(getAccessToken);
    const isStudent = useSelector(getIsStudent);
    const isTrainer = useSelector(getIsTrainer);
    const [init, setInit] = useState(0);
    const [loading, setLoading] = useState(false)
    const [updating, setUpdating] = useState(false)
    const [defaultItems, setDefaultItems] = useState<any>({});
    const [resultItems, setResultItems] = useState<any>({});
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false)
    const [successTrainer, setSuccessTrainer] = useState(false);
    const [improvement, setImprovement] = useState("");
    const [benefit, setBenefit] = useState("");
    const [leave, setLeave] = useState("");
    const [edit, setEdit] = useState(false);
    const store = useStore();
    const dispatch = useDispatch();
    let { document_id } = useParams<{ document_id: string }>();
    const search = useLocation().search;
    const email = new URLSearchParams(search).get('email');
    const studentEmail = new URLSearchParams(search).get('studentEmail');
    const userEmail = useSelector(getEmail);

    useEffect(() => {
        const unsubscribe = store.subscribe(() => {
            var res = store.getState().SearchReducer;
            if (res.isLoading) {
                setError('');
                setLoading(true);
            } else if (res.isLoaded) {
                setError('');
                setLoading(false);
                setDefaultItems(JSON.parse(JSON.stringify(res.data)));
                setResultItems(JSON.parse(JSON.stringify(res.data)));
                setInit(1);
                if (!isStudent) setSuccess(true);
                if (!isTrainer) setSuccessTrainer(true);
            } else if (res.isUpdating) {
                setError('');
                setUpdating(true);
            } else if (res.isUpdated) {
                if (res.table === "additional") {
                    var newItem = { ...resultItems };
                    newItem.additional = res.data.filename;
                    newItem.loadingAdditional = undefined;
                    newItem.editAdditional = false;
                    setResultItems(newItem);
                } else {
                    if (res.data.success === true) {
                        alert({ message: 'Saved', type: 'success' });
                        setSuccess(true);
                        setSuccessTrainer(true);
                    } else {
                        alert({ message: res.data.popup ?? 'Error', type: 'error' });
                        window.scroll(0, 0);
                    }
                    setResultItems(JSON.parse(JSON.stringify(res.data)));
                    dispatch(queryclear());
                }
                setUpdating(false);
            } else if (res.isFailed) {
                if ((res.data !== undefined) && (res.data.success !== undefined)) {
                    setResultItems(JSON.parse(JSON.stringify(res.data)));
                    alert({ message: res.data.popup ?? 'Error', type: 'error' });
                    window.scrollTo(0, 0);
                }
                setError(res.data.message);
                setLoading(false);
                setUpdating(false);
            }
        });
        return unsubscribe;

    }, [resultItems])

    useEffect(() => {
        if (isStudent) {
            dispatch(getassignment(parseInt(document_id)));
        } else {
            dispatch(getassignmentbyemail(parseInt(document_id), email, studentEmail));
        }
    }, [])

    const clear = () => {
        setResultItems(defaultItems);
        setEdit(false);
    }

    const handleClear = () => {
        if (edit) setLeave("clear");
    }

    const handleSubmit = () => {
        setError("");
        dispatch(queryclear());
        if (studentEmail) {
            dispatch(saveassignment(parseInt(document_id), resultItems, studentEmail, isTrainer));
        } else {
            dispatch(saveassignment(parseInt(document_id), resultItems, userEmail, isTrainer));
        }

    }

    const handleClose = () => {
        window.close();
    }

    const getName = () => {
        return resultItems.name;
    }
    const setName = (v: string) => {
        var newResultItems = { ...resultItems };
        newResultItems.name = v;
        setResultItems(newResultItems);
        setEdit(true);
    }
    const getErrorName = () => {
        return ((resultItems.name === null) || (resultItems.errorName === undefined)) ? null : resultItems.errorName;
    }

    const getNickName = () => {
        return resultItems.nickName;
    }
    const setNickName = (v: string) => {
        var newResultItems = { ...resultItems };
        newResultItems.nickName = v;
        setResultItems(newResultItems);
        setEdit(true);
    }
    const setTrainerComment = (resultItem: any) => {
        setResultItems(resultItem);
        setEdit(true)
    }
    const getErrorNickName = () => {
        return ((resultItems.errorNickName === null) || (resultItems.errorNickName === undefined)) ? null : resultItems.errorNickName;
    }

    const getAge = () => {
        return resultItems.age;
    }
    const setAge = (v: string) => {
        var newResultItems = { ...resultItems };
        newResultItems.age = v;
        setResultItems(newResultItems);
        setEdit(true);
    }
    const getErrorAge = () => {
        return ((resultItems.errorAge === null) || (resultItems.errorAge === undefined)) ? null : resultItems.errorAge;
    }

    const getMember = () => {
        return resultItems.member;
    }
    const setMember = (v: string) => {
        var newResultItems = { ...resultItems };
        newResultItems.member = v;
        setResultItems(newResultItems);
        setEdit(true);
    }

    const getDescriptionMember = () => {
        return resultItems.descriptionMember;
    }
    const setDescriptionMember = (v: string) => {
        var newResultItems = { ...resultItems };
        newResultItems.descriptionMember = v;
        setResultItems(newResultItems);
        setEdit(true);
    }
    const getErrorMember = () => {
        return ((resultItems.errorMember === null) || (resultItems.errorMember === undefined)) ? null : resultItems.errorMember;
    }

    const getAddress = () => {
        return resultItems.address;
    }
    const setAddress = (v: string) => {
        var newResultItems = { ...resultItems };
        newResultItems.address = v;
        setResultItems(newResultItems);
        setEdit(true);
    }
    const getErrorAddress = () => {
        return ((resultItems.errorAddress === null) || (resultItems.errorAddress === undefined)) ? null : resultItems.errorAddress;
    }

    const getCompany = () => {
        return resultItems.company;
    }
    const setCompany = (v: string) => {
        var newResultItems = { ...resultItems };
        newResultItems.company = v;
        setResultItems(newResultItems);
        setEdit(true);
    }
    const getErrorCompany = () => {
        return ((resultItems.errorCompany === null) || (resultItems.errorCompany === undefined)) ? null : resultItems.errorCompany;
    }

    const getPosition = () => {
        return resultItems.position;
    }
    const setPosition = (v: string) => {
        var newResultItems = { ...resultItems };
        newResultItems.position = v;
        setResultItems(newResultItems);
        setEdit(true);
    }
    const getErrorPosition = () => {
        return ((resultItems.errorPosition === null) || (resultItems.errorPosition === undefined)) ? null : resultItems.errorPosition;
    }

    const getWelfare = () => {
        return resultItems.welfare;
    }
    const setWelfare = (v: string) => {
        var newResultItems = { ...resultItems };
        newResultItems.welfare = v;
        setResultItems(newResultItems);
        setEdit(true);
    }
    const getErrorWelfare = () => {
        return ((resultItems.errorWelfare === null) || (resultItems.errorWelfare === undefined)) ? null : resultItems.errorWelfare;
    }

    const getPurpose1 = () => {
        return resultItems.purpose1;
    }
    const setPurpose1 = (v: string) => {
        var newResultItems = { ...resultItems };
        newResultItems.purpose1 = v;
        setResultItems(newResultItems);
        setEdit(true);
    }
    const getErrorPurpose1 = () => {
        return ((resultItems.errorPurpose1 === null) || (resultItems.errorPurpose1 === undefined)) ? null : resultItems.errorPurpose1;
    }

    const getPurpose2 = () => {
        return resultItems.purpose2;
    }
    const setPurpose2 = (v: string) => {
        var newResultItems = { ...resultItems };
        newResultItems.purpose2 = v;
        setResultItems(newResultItems);
        setEdit(true);
    }

    const getPurpose3 = () => {
        return resultItems.purpose3;
    }
    const setPurpose3 = (v: string) => {
        var newResultItems = { ...resultItems };
        newResultItems.purpose3 = v;
        setResultItems(newResultItems);
        setEdit(true);
    }

    const getAsset = () => {
        return resultItems.asset;
    }
    const setAsset = (v: string) => {
        var newResultItems = { ...resultItems };
        newResultItems.asset = v;
        setResultItems(newResultItems);
        setEdit(true);
    }
    const getErrorAsset = () => {
        return ((resultItems.errorAsset === null) || (resultItems.errorAsset === undefined)) ? null : resultItems.errorAsset;
    }

    const getDebt = () => {
        return resultItems.debt;
    }
    const setDebt = (v: string) => {
        var newResultItems = { ...resultItems };
        newResultItems.debt = v;
        setResultItems(newResultItems);
        setEdit(true);
    }
    const getErrorDebt = () => {
        return ((resultItems.errorDebt === null) || (resultItems.errorDebt === undefined)) ? null : resultItems.errorDebt;
    }

    const getIncome = () => {
        return resultItems.income;
    }
    const setIncome = (v: string) => {
        var newResultItems = { ...resultItems };
        newResultItems.income = v;
        setResultItems(newResultItems);
        setEdit(true);
    }
    const getErrorIncome = () => {
        return ((resultItems.errorIncome === null) || (resultItems.errorIncome === undefined)) ? null : resultItems.errorIncome;
    }

    const getExpense = () => {
        return resultItems.expense;
    }
    const setExpense = (v: string) => {
        var newResultItems = { ...resultItems };
        newResultItems.expense = v;
        setResultItems(newResultItems);
        setEdit(true);
    }
    const getErrorExpense = () => {
        return ((resultItems.errorExpense === null) || (resultItems.errorExpense === undefined)) ? null : resultItems.errorExpense;
    }

    const getNegativeProblem = () => {
        return resultItems.negativeProblem;
    }
    const setNegativeProblem = (v: string) => {
        var newResultItems = { ...resultItems };
        newResultItems.negativeProblem = v;
        setResultItems(newResultItems);
        setEdit(true);
    }
    const getErrorNegativeProblem = () => {
        return ((resultItems.errorNegativeProblem === null) || (resultItems.errorNegativeProblem === undefined)) ? null : resultItems.errorNegativeProblem;
    }

    const getZeroProblem = () => {
        return resultItems.zeroProblem;
    }
    const setZeroProblem = (v: string) => {
        var newResultItems = { ...resultItems };
        newResultItems.zeroProblem = v;
        setResultItems(newResultItems);
        setEdit(true);
    }
    const getErrorZeroProblem = () => {
        return ((resultItems.errorZeroProblem === null) || (resultItems.errorZeroProblem === undefined)) ? null : resultItems.errorZeroProblem;
    }

    const getPositiveProblem = () => {
        return resultItems.positiveProblem;
    }
    const setPositiveProblem = (v: string) => {
        var newResultItems = { ...resultItems };
        newResultItems.positiveProblem = v;
        setResultItems(newResultItems);
        setEdit(true);
    }
    const getErrorPositiveProblem = () => {
        return ((resultItems.errorPositiveProblem === null) || (resultItems.errorPositiveProblem === undefined)) ? null : resultItems.errorPositiveProblem;
    }

    const getDebtSign = () => {
        return (resultItems.debtSign === undefined) ? null : resultItems.debtSign;
    }
    const setDebtSign = (v: boolean) => {
        if (success) return;
        var newResultItems = { ...resultItems };
        newResultItems.debtSign = v;
        setResultItems(newResultItems);
        setEdit(true);
    }
    const getDebtResult = () => {
        return resultItems.debtResult;
    }

    const setDebtResult = (v: string) => {
        var newResultItems = { ...resultItems };
        newResultItems.debtResult = v;
        setResultItems(newResultItems);
        setEdit(true);
    }
    const getErrorDebtResult = () => {
        return ((resultItems.errorDebtResult === null) || (resultItems.errorDebtResult === undefined)) ? null : resultItems.errorDebtResult;
    }
    const getDebtRemark = () => {
        return resultItems.debtRemark;
    }
    const setDebtRemark = (v: string) => {
        var newResultItems = { ...resultItems };
        newResultItems.debtRemark = v;
        setResultItems(newResultItems);
        setEdit(true);
    }
    const getErrorDebtRemark = () => {
        return ((resultItems.errorDebtRemark === null) || (resultItems.errorDebtRemark === undefined)) ? null : resultItems.errorDebtRemark;
    }

    const getExpenseSign = () => {
        return (resultItems.expenseSign === undefined) ? null : resultItems.expenseSign;
    }
    const setExpenseSign = (v: boolean) => {
        if (success) return;
        var newResultItems = { ...resultItems };
        newResultItems.expenseSign = v;
        setResultItems(newResultItems);
        setEdit(true);
    }
    const getExpenseResult = () => {
        return resultItems.expenseResult;
    }
    const setExpenseResult = (v: string) => {
        var newResultItems = { ...resultItems };
        newResultItems.expenseResult = v;
        setResultItems(newResultItems);
        setEdit(true);
    }
    const getErrorExpenseResult = () => {
        return ((resultItems.errorExpenseResult === null) || (resultItems.errorExpenseResult === undefined)) ? null : resultItems.errorExpenseResult;
    }
    const getExpenseRemark = () => {
        return resultItems.expenseRemark;
    }
    const setExpenseRemark = (v: string) => {
        var newResultItems = { ...resultItems };
        newResultItems.expenseRemark = v;
        setResultItems(newResultItems);
        setEdit(true);
    }
    const getErrorExpenseRemark = () => {
        return ((resultItems.errorExpenseRemark === null) || (resultItems.errorExpenseRemark === undefined)) ? null : resultItems.errorExpenseRemark;
    }

    const getSavingSign = () => {
        return (resultItems.savingSign === undefined) ? null : resultItems.savingSign;
    }
    const setSavingSign = (v: boolean) => {
        if (success) return;
        var newResultItems = { ...resultItems };
        newResultItems.savingSign = v;
        setResultItems(newResultItems);
        setEdit(true);
    }
    const getSavingResult = () => {
        return resultItems.savingResult;
    }
    const setSavingResult = (v: string) => {
        var newResultItems = { ...resultItems };
        newResultItems.savingResult = v;
        setResultItems(newResultItems);
        setEdit(true);
    }
    const getErrorSavingResult = () => {
        return ((resultItems.errorSavingResult === null) || (resultItems.errorSavingResult === undefined)) ? null : resultItems.errorSavingResult;
    }
    const getSavingRemark = () => {
        return resultItems.savingRemark;
    }
    const setSavingRemark = (v: string) => {
        var newResultItems = { ...resultItems };
        newResultItems.savingRemark = v;
        setResultItems(newResultItems);
        setEdit(true);
    }
    const getErrorSavingRemark = () => {
        return ((resultItems.errorSavingRemark === null) || (resultItems.errorSavingRemark === undefined)) ? null : resultItems.errorSavingRemark;
    }

    const getErrorImprovement = () => {
        return ((resultItems.errorImprovement === null) || (resultItems.errorImprovement === undefined)) ? null : resultItems.errorImprovement;
    }

    const getErrorBenefit = () => {
        return ((resultItems.errorBenefit === null) || (resultItems.errorBenefit === undefined)) ? null : resultItems.errorBenefit;
    }

    const handleAddImprovement = () => {
        var newResultItems = { ...resultItems };
        if (resultItems.improvement === undefined) newResultItems.improvement = [];
        newResultItems.improvement.push(improvement);
        setImprovement("");
        setResultItems(newResultItems);
        setEdit(true);
    }

    const handleDeleteImprovement = (id: number) => {
        var newResultItems = { ...resultItems };
        if (resultItems.improvement === undefined) resultItems.improvement = [];
        newResultItems.improvement = [];
        for (var i = 0; i < resultItems.improvement.length; i++) {
            if (i !== id) newResultItems.improvement.push(resultItems.improvement[i]);
        }
        setResultItems(newResultItems);
        setEdit(true);
    }

    const handleAddBenefit = () => {
        var newResultItems = { ...resultItems };
        if (resultItems.benefit === undefined) newResultItems.benefit = [];
        newResultItems.benefit.push(benefit);
        setBenefit("");
        setResultItems(newResultItems);
        setEdit(true);
    }
    const handleAddFamilyStatus = () => {
        var newResultItems = { ...resultItems };
        if (resultItems.familyStatus === undefined) newResultItems.familyStatus = [];
        newResultItems.familyStatus.push("");
        setResultItems(newResultItems);
        setEdit(true);
    }

    const handleDeleteBenefit = (id: number) => {
        var newResultItems = { ...resultItems };
        if (resultItems.benefit === undefined) resultItems.benefit = [];
        newResultItems.benefit = [];
        for (var i = 0; i < resultItems.benefit.length; i++) {
            if (i !== id) newResultItems.benefit.push(resultItems.benefit[i]);
        }
        setResultItems(newResultItems);
        setEdit(true);
    }

    const getImprovementItem = (i: number) => {
        if ((resultItems.improvement !== undefined) && (resultItems.improvement[i] !== undefined)) {
            return resultItems.improvement[i];
        }
        return "";
    }

    const getImprovementItemError = (i: number) => {
        if ((resultItems.errorImprovementItem === null) || (resultItems.errorImprovementItem === undefined)) {
            return null;
        } else {
            return resultItems.errorImprovementItem[i];
        }
    }

    const setImprovementItem = (i: number, v: string) => {
        var newResultItems = { ...resultItems };
        if (resultItems.improvement === undefined) newResultItems.improvement = [];
        newResultItems.improvement[i] = v;
        setResultItems(newResultItems);
        setEdit(true);
    }

    const createImprovement = () => {
        var res: any[] = [];
        if (resultItems.improvement !== undefined) {
            if (resultItems.improvement.length == 0) {
                handleAddImprovement();
            }
            for (var i = 0; i < resultItems.improvement.length; i++) {
                res.push(<React.Fragment key={'improve' + i}>
                    <div className="p-3 light-gray-button">
                        <div className="row">
                            <div className="col-11">
                                <input className={'form-control ' + ((getImprovementItemError(i) != null) ? "is-invalid" : "")} id={i.toString()} maxLength={1000} value={getImprovementItem(i)} onChange={e => setImprovementItem(parseInt(e.currentTarget.id), e.currentTarget.value)} disabled={success} />
                            </div>
                            <div className="col-1">
                                {(!success && resultItems.improvement.length > 1) &&
                                    <RemoveCircleOutline id={i.toString()} className="red" onClick={e => handleDeleteImprovement(parseInt(e.currentTarget.id))}></RemoveCircleOutline>
                                }
                            </div>
                        </div>
                    </div>
                </React.Fragment>);
            }
        }
        return res;
    }

    const getBenefitItem = (i: number) => {
        if ((resultItems.benefit !== undefined) && (resultItems.benefit[i] !== undefined)) {
            return resultItems.benefit[i];
        }
        return "";
    }

    const setBenefitItem = (i: number, v: string) => {
        var newResultItems = { ...resultItems };
        if (resultItems.benefit === undefined) newResultItems.benefit = [];
        newResultItems.benefit[i] = v;
        setResultItems(newResultItems);
        setEdit(true);
    }

    const getBenefitItemError = (i: number) => {
        if ((resultItems.errorBenefitItem === null) || (resultItems.errorBenefitItem === undefined)) {
            return null;
        } else {
            return resultItems.errorBenefitItem[i];
        }
    }

    const createBenefit = () => {
        var res: any[] = [];
        if (resultItems.benefit !== undefined) {
            if (resultItems.benefit.length == 0) {
                handleAddBenefit();
            }
            for (var i = 0; i < resultItems.benefit.length; i++) {
                res.push(<React.Fragment key={'benefitList' + i}>
                    <div className="p-3 light-gray-button" >
                        <div className="row">
                            <div className="col-11">
                                <input className={'form-control ' + ((getBenefitItemError(i) != null) ? "is-invalid" : "")} id={i.toString()} maxLength={1000} value={getBenefitItem(i)} onChange={e => setBenefitItem(parseInt(e.currentTarget.id), e.currentTarget.value)} disabled={success} />
                            </div>
                            <div className="col-1">
                                {(!success && resultItems.benefit.length > 1) &&
                                    <RemoveCircleOutline id={i.toString()} className="red" onClick={e => handleDeleteBenefit(parseInt(e.currentTarget.id))}></RemoveCircleOutline>
                                }
                            </div>
                        </div>
                    </div>
                </React.Fragment>);
            }
        }
        return res;
    }

    const getFamilyStatusError = (i: number) => {
        if ((resultItems.errorFamilyStatusItem === null) || (resultItems.errorFamilyStatusItem === undefined)) {
            return null;
        } else {
            return resultItems.errorFamilyStatusItem[i];
        }
    }

    const getFamilyStatusItem = (i: number) => {
        if ((resultItems.familyStatus !== undefined) && (resultItems.familyStatus[i] !== undefined)) {
            return resultItems.familyStatus[i];
        }
        return "";
    }

    const setFamilyStatusItem = (i: number, v: string) => {
        var newResultItems = { ...resultItems };
        if (resultItems.familyStatus === undefined) newResultItems.familyStatus = [];
        newResultItems.familyStatus[i] = v;
        setResultItems(newResultItems);
        setEdit(true);
    }

    const handleDeleteFamilyStatus = (id: number) => {
        var newResultItems = { ...resultItems };
        if (resultItems.familyStatus === undefined) resultItems.familyStatus = [];
        newResultItems.familyStatus = [];
        for (var i = 0; i < resultItems.familyStatus.length; i++) {
            if (i !== id) newResultItems.familyStatus.push(resultItems.familyStatus[i]);
        }
        setResultItems(newResultItems);
        setEdit(true);
    }
    const createFamilyStatus = () => {
        var res: any[] = [];
        if (resultItems.familyStatus !== undefined) {
            if (resultItems.familyStatus.length == 0) {
                handleAddFamilyStatus();
            }
            for (var i = 0; i < resultItems.familyStatus.length; i++) {
                res.push(<React.Fragment key={'familyStatus' + i}>
                    <div className="row mb-2">
                        <div className="col-5">
                            <input className={'form-control ' + ((getFamilyStatusError(i) != null) ? "is-invalid" : "")} id={i.toString()} maxLength={1000} value={getFamilyStatusItem(i)} onChange={e => setFamilyStatusItem(parseInt(e.currentTarget.id), e.currentTarget.value)} disabled={success} />
                        </div>
                        <div className="col-1 p-0 align-self-center">
                            {(!success && resultItems.familyStatus.length > 1) &&
                                <RemoveCircleOutline id={i.toString()} className="red mr-1" onClick={e => handleDeleteFamilyStatus(parseInt(e.currentTarget.id))}></RemoveCircleOutline>
                            }
                            {(!success && (resultItems.familyStatus.length - 1) === i) &&
                                <AddCircleOutline id={i.toString()} className="green" onClick={handleAddFamilyStatus}></AddCircleOutline>
                            }
                        </div>
                    </div>
                </React.Fragment>);
            }
        }
        return res;
    }

    const handleGetAdditional = () => {
        window.open(ETOOLKIT_ADDITIONAL_URL + "/" + document_id + "?isStudent=" + isStudent + "&token=" + token + "&filename=" + encodeURIComponent(resultItems.additional), '_blank', 'noopener,noreferrer');
    }

    const handleEditAdditional = () => {
        var newResultItems = { ...resultItems };
        if (newResultItems.editAdditional === true) newResultItems.editAdditional = false;
        else newResultItems.editAdditional = true;
        setResultItems(newResultItems);
    }

    const handleAdditionalUploadChange = (selectorFiles: FileList | null) => {
        var newItem: any = { ...resultItems };
        newItem.additionalFile = selectorFiles;
        setResultItems(newItem);
    }

    const errorAdditionalUpload = () => {
        if (resultItems.errorAdditional === undefined) return null;
        return resultItems.errorAdditional;
    }

    const validateAdditional = () => {
        var newItem: any = { ...resultItems };
        var currentFile = newItem.additionalFile;
        if (currentFile === undefined) {
            newItem.errorAdditional = REQUIRE_LABEL;
            setResultItems(newItem);
            return false;
        }
        if (currentFile[0].size > MAX_FILE_UPLOAD * 1000000) {
            newItem.errorAdditional = MAX_UPLOAD_FILE_SIZE_LABEL;
            setResultItems(newItem);
            return false;
        }
        return true;
    }

    const handleAdditionalUpload = () => {
        if (validateAdditional()) {
            dispatch(queryclear());
            dispatch(uploadadditional(document_id, resultItems.additionalFile));
            var newItem = { ...resultItems };
            newItem.loadingAdditional = true;
            newItem.errorAdditional = undefined;
            newItem.additionalFile = undefined;
            setResultItems(newItem);
        }
    }

    const getLoadingAdditional = () => {
        if (resultItems.loadingAdditional === undefined) return false;
        return resultItems.loadingAdditional;
    }

    const handleLeave = () => {
        clear();
        setLeave("");
    }
    const handleGetDocEToolkitDownload = () => {
        window.open(GET_DOC_PAGE_STUDENT_URL + "/" + document_id + "?token=" + token, '_blank', 'noopener,noreferrer')
    }


    return (<>
        <div style={{ width: "100%" }}>
            <div className="App-student">
                <div className="student-wrapper">
                    <br />
                    {(init === 1) &&
                        <>
                            <div className="yellow-button" style={{ textAlign: "left", borderTopLeftRadius: "30px", borderTopRightRadius: "30px", height: "70px", paddingTop: "15px", paddingLeft: "15px" }}>
                                <h4 className="header">e-Toolkit</h4>
                            </div>
                            <br />
                            <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                                <div className="gray-button text-left" style={{ padding: "20px", borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}>
                                    ขั้นตอนการใช้งาน
                                </div>
                                <div className="bg-white text-left" style={{ padding: "20px", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px" }}>
                                    <div className='row'>
                                        <div className="col-12">
                                            1. Download ไฟล์ e-Toolkit เพื่อกรอกงบการเงินส่วนบุคคล
                                            <span className='ml-2'><a className='link' onClick={handleGetDocEToolkitDownload} ><img src={excellogo} height="36px" alt="excel" style={{ marginLeft: "-3px" }} /></a></span>
                                        </div>
                                        <div className="col-12">
                                            2. เมื่อครบเวลาที่กำหนด ให้นำข้อมูลที่ได้จากไฟล์ e-Toolkit มากรอกในระบบ
                                        </div>
                                        <div className="col-12">
                                            3. จากนั้นให้แนบไฟล์ e-Toolkit ที่กรอกข้อมูลงบการเงินส่วนบุคคลทุกส่วนเรียบร้อยแล้วในส่วนที่ 9
                                        </div>
                                    </div>
                                </div>
                                <div className="gray-button text-left" style={{ padding: "20px", borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}>
                                    ส่วนที่ 1: สรุปข้อมูลทั่วไป
                                </div>
                                <div className="bg-white text-left" style={{ padding: "20px", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px" }}>
                                    <b style={{ fontFamily: "DB Helvethaica Bold" }}>ข้อมูลส่วนตัว</b>
                                    <hr />
                                    <div className="row">
                                        <div className="col-md-2 text-md-right">
                                            ชื่อ-นามสกุล
                                        </div>
                                        <div className="col-md-4">
                                            <input className={"form-control " + ((getErrorName() != null) ? "is-invalid" : "")} maxLength={200} value={getName()} onChange={e => setName(e.currentTarget.value)} disabled={success} />
                                            <div className={(getErrorName() != null) ? "text-danger" : ""}><small>{getErrorName()}</small></div>
                                            <br />
                                        </div>
                                        <div className="col-md-2 text-md-right">
                                            ชื่อเล่น
                                        </div>
                                        <div className="col-md-4">
                                            <input className={"form-control " + ((getErrorNickName() != null) ? "is-invalid" : "")} maxLength={100} value={getNickName()} onChange={e => setNickName(e.currentTarget.value)} disabled={success} />
                                            <div className={(getErrorNickName() != null) ? "text-danger" : ""}><small>{getErrorNickName()}</small></div>
                                            <br />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-2 text-md-right">
                                            อายุ <span className="text-danger">*</span>
                                        </div>
                                        <div className="col-md-2">
                                            <input className={"form-control " + ((getErrorAge() != null) ? "is-invalid" : "")} maxLength={3} value={getAge()} onChange={e => setAge(e.currentTarget.value)} disabled={success} />
                                            <div className={(getErrorAge() != null) ? "text-danger" : ""}><small>{getErrorAge()}</small></div>
                                            <br />
                                        </div>
                                        <div className="col-md-2">
                                            ปี
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-2 text-md-right'>
                                            สถานะครอบครัว <span className="text-danger">*</span>
                                        </div>
                                        <div className='col-md-10'>
                                            {createFamilyStatus()}
                                            <br />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-2 text-md-right">
                                            จำนวนสมาชิกในครอบครัว <span className="text-danger">*</span>
                                        </div>
                                        <div className="col-md-2">
                                            <input className={"form-control " + ((getErrorMember() != null) ? "is-invalid" : "")} maxLength={10} value={getMember()} onChange={e => setMember(e.currentTarget.value)} disabled={success} />
                                            <div className={(getErrorMember() != null) ? "text-danger" : ""}><small>{getErrorMember()}</small></div>
                                            <br />
                                        </div>
                                        <div className="col-md-2">
                                            คน
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-2 text-md-right">
                                            สมาชิกในครอบครัวประกอบด้วย
                                        </div>
                                        <div className="col-md-10">
                                            <input className="form-control" value={getDescriptionMember()} onChange={e => setDescriptionMember(e.currentTarget.value)} disabled={success} />
                                            <br />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-2 text-md-right">
                                            ที่อยู่/ภูมิลำเนา  <span className="text-danger">*</span>
                                        </div>
                                        <div className="col-md-10">
                                            <input className={"form-control " + ((getErrorAddress() != null) ? "is-invalid" : "")} maxLength={1000} value={getAddress()} onChange={e => setAddress(e.currentTarget.value)} disabled={success} />
                                            <div className={(getErrorAddress() != null) ? "text-danger" : ""}><small>{getErrorAddress()}</small></div>
                                            <br />
                                        </div>
                                    </div>
                                    <br />
                                    <b style={{ fontFamily: "DB Helvethaica Bold" }}>ข้อมูลการทำงาน</b>
                                    <hr />
                                    <div className="row">
                                        <div className="col-md-2 text-md-right">
                                            บริษัท <span className="text-danger">*</span>
                                        </div>
                                        <div className="col-md-10">
                                            <input className={"form-control " + ((getErrorCompany() != null) ? "is-invalid" : "")} maxLength={1000} value={getCompany()} onChange={e => setCompany(e.currentTarget.value)} disabled={success} />
                                            <div className={(getErrorCompany() != null) ? "text-danger" : ""}><small>{getErrorCompany()}</small></div>
                                            <br />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-2 text-md-right">
                                            ตำแหน่ง <span className="text-danger">*</span>
                                        </div>
                                        <div className="col-md-10">
                                            <input className={"form-control " + ((getErrorPosition() != null) ? "is-invalid" : "")} maxLength={1000} value={getPosition()} onChange={e => setPosition(e.currentTarget.value)} disabled={success} />
                                            <div className={(getErrorPosition() != null) ? "text-danger" : ""}><small>{getErrorPosition()}</small></div>
                                            <br />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-2 text-md-right">
                                            สวัสดิการ <span className="text-danger">*</span>
                                        </div>
                                        <div className="col-md-10">
                                            <input className={"form-control " + ((getErrorWelfare() != null) ? "is-invalid" : "")} maxLength={1000} value={getWelfare()} onChange={e => setWelfare(e.currentTarget.value)} disabled={success} />
                                            <div className={(getErrorWelfare() != null) ? "text-danger" : ""}><small>{getErrorWelfare()}</small></div>
                                            <br />
                                        </div>
                                    </div>
                                </div>
                                <div className="gray-button text-left" style={{ padding: "20px", borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}>
                                    ส่วนที่ 2: สรุปข้อมูลการเงิน
                                </div>
                                <div className="bg-white text-left" style={{ padding: "20px", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px" }}>
                                    <div className="row">
                                        <div className="col-md-2 text-md-right">
                                            เป้าหมายที่ #1 <span className="text-danger">*</span>
                                        </div>
                                        <div className="col-md-10">
                                            <input className={"form-control " + ((getErrorPurpose1() != null) ? "is-invalid" : "")} maxLength={1000} value={getPurpose1()} onChange={e => setPurpose1(e.currentTarget.value)} disabled={success} />
                                            <div className={(getErrorPurpose1() != null) ? "text-danger" : ""}><small>{getErrorPurpose1()}</small></div>
                                            <br />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-2 text-md-right">
                                            เป้าหมายที่ #2
                                        </div>
                                        <div className="col-md-10">
                                            <input className="form-control" maxLength={1000} value={getPurpose2()} onChange={e => setPurpose2(e.currentTarget.value)} disabled={success} />
                                            <br />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-2 text-md-right">
                                            เป้าหมายที่ #3
                                        </div>
                                        <div className="col-md-10">
                                            <input className="form-control" maxLength={1000} value={getPurpose3()} onChange={e => setPurpose3(e.currentTarget.value)} disabled={success} />
                                            <br />
                                        </div>
                                    </div>
                                </div>
                                <div className="gray-button text-left" style={{ padding: "20px", borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}>
                                    ส่วนที่ 3: สรุปผลวิเคราะห์งบดุลส่วนบุคคล
                                </div>
                                <div className="bg-white text-left" style={{ borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px" }}>
                                    <div className="row">
                                        <div className="col-md-6 text-center pr-md-0">
                                            <div className="etoolkit-green">
                                                สินทรัพย์ <span className="text-danger">*</span>
                                            </div>
                                            <div className="light-gray-button" style={{ padding: "10px" }}>
                                                <textarea className={"form-control " + ((getErrorAsset() != null) ? "is-invalid" : "")} rows={4} maxLength={2000} value={getAsset()} onChange={e => setAsset(e.currentTarget.value)} disabled={success} />
                                                <div className={(getErrorAsset() != null) ? "text-danger" : ""}><small>{getErrorAsset()}</small></div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 text-center pl-md-0">
                                            <div className="etoolkit-red">
                                                หนี้สิน <span className="text-danger">*</span>
                                            </div>
                                            <div className="light-gray-button" style={{ padding: "10px" }}>
                                                <textarea className={"form-control " + ((getErrorDebt() != null) ? "is-invalid" : "")} rows={4} maxLength={2000} value={getDebt()} onChange={e => setDebt(e.currentTarget.value)} disabled={success} />
                                                <div className={(getErrorDebt() != null) ? "text-danger" : ""}><small>{getErrorDebt()}</small></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="gray-button text-left" style={{ padding: "20px", borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}>
                                    ส่วนที่ 4: สรุปผลวิเคราะห์งบรายได้และค่าใช้จ่าย
                                </div>
                                <div className="bg-white text-left" style={{ borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px" }}>
                                    <div className="row">
                                        <div className="col-md-6 text-center pr-md-0">
                                            <div className="etoolkit-green">
                                                รายได้  <span className="text-danger">*</span>
                                            </div>
                                            <div className="light-gray-button" style={{ padding: "10px" }}>
                                                <textarea className={"form-control " + ((getErrorIncome() != null) ? "is-invalid" : "")} rows={4} maxLength={2000} value={getIncome()} onChange={e => setIncome(e.currentTarget.value)} disabled={success} />
                                                <div className={(getErrorIncome() != null) ? "text-danger" : ""}><small>{getErrorIncome()}</small></div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 text-center pl-md-0">
                                            <div className="etoolkit-red">
                                                ค่าใช้จ่าย  <span className="text-danger">*</span>
                                            </div>
                                            <div className="light-gray-button" style={{ padding: "10px" }}>
                                                <textarea className={"form-control " + ((getErrorExpense() != null) ? "is-invalid" : "")} rows={4} maxLength={2000} value={getExpense()} onChange={e => setExpense(e.currentTarget.value)} disabled={success} />
                                                <div className={(getErrorExpense() != null) ? "text-danger" : ""}><small>{getErrorExpense()}</small></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="gray-button text-left" style={{ padding: "20px", borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}>
                                    ส่วนที่ 5: สรุปสภาพปัญหาการเงิน <span style={{ textDecoration: "underline" }}>ก่อน</span>ให้คำแนะนำด้านต่างๆ
                                    <div className='text-danger'>(กรุณากรอกข้อมูลสถานะทางการเงิน แดง เหลือง เขียว ที่ช่องใดช่องหนึ่งเท่านั้น สำหรับช่องที่ไม่ได้กรอกข้อมูล ให้ระบุคำว่า "ไม่มี" หรือ "-" ที่ช่องนั้น)</div>
                                </div>
                                <div className="bg-white text-left" style={{ borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px" }}>
                                    <div className="row">
                                        <div className="col-md-4 text-center pr-md-0">
                                            <div className="etoolkit-red">
                                                สถานะเป็น <span style={{ textDecoration: "underline" }}>ลบ</span>มีปัญหาหนี้ จัดการเองไม่ได้ ต้องแก้ไขปัญหาหนี้สิน  <span className="text-danger">*</span>
                                            </div>
                                            <div className="light-gray-button" style={{ padding: "10px" }}>
                                                <textarea className={"form-control " + ((getErrorNegativeProblem() != null) ? "is-invalid" : "")} rows={4} maxLength={2000} value={getNegativeProblem()} onChange={e => setNegativeProblem(e.currentTarget.value)} disabled={success} />
                                                <div className={(getErrorNegativeProblem() != null) ? "text-danger" : ""}><small>{getErrorNegativeProblem()}</small></div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 text-center p-md-0">
                                            <div className="etoolkit-yellow">
                                                สถานะ<span style={{ textDecoration: "underline" }}>ใกล้ศูนย์</span> มีปัญหาหนี้ แต่ยังจัดการได้ ต้องปรับพฤติกรรมการใช้เงิน <span className="text-danger">*</span>
                                            </div>
                                            <div className="light-gray-button" style={{ padding: "10px" }}>
                                                <textarea className={"form-control " + ((getErrorZeroProblem() != null) ? "is-invalid" : "")} rows={4} maxLength={2000} value={getZeroProblem()} onChange={e => setZeroProblem(e.currentTarget.value)} disabled={success} />
                                                <div className={(getErrorZeroProblem() != null) ? "text-danger" : ""}><small>{getErrorZeroProblem()}</small></div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 text-center pl-md-0">
                                            <div className="etoolkit-green">
                                                สถานะเป็น <span style={{ textDecoration: "underline" }}>บวก</span> ไม่มีปัญหาหนี้ มีเงินเหลือแต่ไม่มีเงินเก็บ ต้องสร้างวินัยการออม <span className="text-danger">*</span>
                                            </div>
                                            <div className="light-gray-button" style={{ padding: "10px" }}>
                                                <textarea className={"form-control " + ((getErrorPositiveProblem() != null) ? "is-invalid" : "")} rows={4} maxLength={2000} value={getPositiveProblem()} onChange={e => setPositiveProblem(e.currentTarget.value)} disabled={success} />
                                                <div className={(getErrorPositiveProblem() != null) ? "text-danger" : ""}><small>{getErrorPositiveProblem()}</small></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="gray-button text-left" style={{ padding: "20px", borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}>
                                    ส่วนที่ 6: สรุปคำแนะนำแนวทางแก้ไขปัญหาทางการเงิน <span className="text-danger">*</span>
                                </div>
                                <div className="bg-white text-left" style={{ borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px" }}>

                                    {
                                        createImprovement()
                                    }
                                    <div className="p-3">
                                        <div className="row">
                                            <div className="col-12">
                                                {(!success) &&
                                                    <Button block onClick={handleAddImprovement}><AddCircleOutline /> เพิ่มสรุปคำแนะนำแนวทางแก้ไขปัญหาทางการเงิน</Button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className={(getErrorImprovement() != null) ? "text-danger text-center" : ""}><small>{getErrorImprovement()}</small></div>
                                </div>
                                <div className="gray-button text-left" style={{ padding: "20px", borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}>
                                    ส่วนที่ 7: ผลลัพธ์จากการแก้ไขปัญหาเฉพาะและวางแผนทางการเงิน <span className="text-danger">*</span>
                                </div>
                                <div className="bg-white text-left" style={{ borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px" }}>
                                    <div className="p-3">
                                        <Table responsive="sm">
                                            <thead>
                                                <tr className="text-center">
                                                    <th>หมวดหมู่</th>
                                                    <th>เพิ่มขึ้น/ลดลง</th>
                                                    <th>ผลลัพธ์</th>
                                                    <th>ข้อมูลเพิ่มเติม</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="text-center">หนี้สิน</td>
                                                    <td style={{ textAlign: "center" }}>
                                                        <ArrowDownCircleIcon className={"flip-vertical " + (getDebtSign() ? "green" : "gray")} onClick={e => { setDebtSign(true) }} /><ArrowDownCircleIcon className={(getDebtSign() === false ? "red" : "gray")} onClick={e => setDebtSign(false)} />
                                                    </td>
                                                    {!success && (
                                                        <td>
                                                            <div className="row">
                                                                <div className="col-8">
                                                                    <input className="form-control" placeholder='ระบุจำนวนเงินเป็นตัวเลขเท่านั้น' maxLength={100} value={getDebtResult()} onChange={e => setDebtResult(e.currentTarget.value)} disabled={success} />
                                                                    <div className={(getErrorDebtResult() != null) ? "text-danger" : ""}><small>{getErrorDebtResult()}</small></div>
                                                                </div>
                                                                <div className="col-2 text-left">บาท</div>
                                                            </div>
                                                        </td>
                                                    )}
                                                    {success && (
                                                        <td>
                                                            <div className="row">
                                                                <div className="col-8">
                                                                    <input className="form-control" placeholder='ระบุจำนวนเงินเป็นตัวเลขเท่านั้น' maxLength={100} value={Util.numberWithCommas(getDebtResult())} disabled={success} />
                                                                    <div className={(getErrorDebtResult() != null) ? "text-danger" : ""}><small>{getErrorDebtResult()}</small></div>
                                                                </div>
                                                                <div className="col-2 text-left">บาท</div>
                                                            </div>
                                                        </td>
                                                    )}

                                                    <td><input className={"form-control " + ((getErrorDebtRemark() != null) ? "is-invalid" : "")} maxLength={1000} value={getDebtRemark()} onChange={e => setDebtRemark(e.currentTarget.value)} disabled={success} />
                                                        <div className={(getErrorDebtRemark() != null) ? "text-danger" : ""}><small>{getErrorDebtRemark()}</small></div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-center">ค่าใช้จ่าย</td>
                                                    <td style={{ textAlign: "center" }}>
                                                        <ArrowDownCircleIcon className={"flip-vertical " + (getExpenseSign() ? "green" : "gray")} onClick={e => setExpenseSign(true)} /><ArrowDownCircleIcon className={(getExpenseSign() === false ? "red" : "gray")} onClick={e => setExpenseSign(false)} />
                                                    </td>
                                                    {!success && (
                                                        <td>
                                                            <div className="row">
                                                                <div className="col-8"><input className="form-control" placeholder='ระบุจำนวนเงินเป็นตัวเลขเท่านั้น' maxLength={100} value={getExpenseResult()} onChange={e => setExpenseResult(e.currentTarget.value)} disabled={success} />
                                                                    <div className={(getErrorExpenseResult() != null) ? "text-danger" : ""}><small>{getErrorExpenseResult()}</small></div>
                                                                </div>
                                                                <div className="col-2 text-left">บาท</div>
                                                            </div>
                                                        </td>
                                                    )}
                                                    {success && (
                                                        <td>
                                                            <div className="row">
                                                                <div className="col-8"><input className="form-control" placeholder='ระบุจำนวนเงินเป็นตัวเลขเท่านั้น' maxLength={100} value={Util.numberWithCommas(getExpenseResult())} disabled={success} />
                                                                    <div className={(getErrorExpenseResult() != null) ? "text-danger" : ""}><small>{getErrorExpenseResult()}</small></div>
                                                                </div>
                                                                <div className="col-2 text-left">บาท</div>
                                                            </div>
                                                        </td>
                                                    )}

                                                    <td><input className={"form-control " + ((getErrorExpenseRemark() != null) ? "is-invalid" : "")} maxLength={1000} value={getExpenseRemark()} onChange={e => setExpenseRemark(e.currentTarget.value)} disabled={success} />
                                                        <div className={(getErrorExpenseRemark() != null) ? "text-danger" : ""}><small>{getErrorExpenseRemark()}</small></div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-center">เงินออม</td>
                                                    <td style={{ textAlign: "center" }}>
                                                        <ArrowDownCircleIcon className={"flip-vertical " + (getSavingSign() ? "green" : "gray")} onClick={e => setSavingSign(true)} /><ArrowDownCircleIcon className={(getSavingSign() === false ? "red" : "gray")} onClick={e => setSavingSign(false)} />
                                                    </td>
                                                    {!success && (
                                                        <td>
                                                            <div className="row">
                                                                <div className="col-8"><input className="form-control" placeholder='ระบุจำนวนเงินเป็นตัวเลขเท่านั้น' maxLength={100} value={getSavingResult()} onChange={e => setSavingResult(e.currentTarget.value)} disabled={success} />
                                                                    <div className={(getErrorSavingResult() != null) ? "text-danger" : ""}><small>{getErrorSavingResult()}</small></div>
                                                                </div>
                                                                <div className="col-2 text-left">บาท</div>
                                                            </div>
                                                        </td>
                                                    )}
                                                    {success && (
                                                        <td>
                                                            <div className="row">
                                                                <div className="col-8"><input className="form-control" placeholder='ระบุจำนวนเงินเป็นตัวเลขเท่านั้น' maxLength={100} value={Util.numberWithCommas(getSavingResult())} disabled={success} />
                                                                    <div className={(getErrorSavingResult() != null) ? "text-danger" : ""}><small>{getErrorSavingResult()}</small></div>
                                                                </div>
                                                                <div className="col-2 text-left">บาท</div>
                                                            </div>
                                                        </td>
                                                    )}

                                                    <td><input className={"form-control " + ((getErrorSavingRemark() != null) ? "is-invalid" : "")} maxLength={1000} value={getSavingRemark()} onChange={e => setSavingRemark(e.currentTarget.value)} disabled={success} />
                                                        <div className={(getErrorSavingRemark() != null) ? "text-danger" : ""}><small>{getErrorSavingRemark()}</small></div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                                <div className="gray-button text-left" style={{ padding: "20px", borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}>
                                    ส่วนที่ 8: สรุปสิ่งที่ได้จากการเข้าร่วมโครงการ Happy Money <span className="text-danger">*</span>
                                </div>
                                <div className="bg-white text-left" style={{ borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px" }}>
                                    {
                                        createBenefit()
                                    }
                                    <div className="p-3">
                                        <div className="row">
                                            <div className="col-12">
                                                {(!success) &&
                                                    <Button block onClick={handleAddBenefit}><AddCircleOutline /> สรุปสิ่งที่ได้จากการเข้าร่วมโครงการ Happy Money</Button>

                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className={(getErrorBenefit() != null) ? "text-danger text-center" : ""}><small>{getErrorBenefit()}</small></div>
                                </div>
                                <div className="gray-button text-left" style={{ padding: "20px", borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}>
                                    ส่วนที่ 9: แนบไฟล์ e-Toolkit ที่กรอกข้อมูลงบการเงินส่วนบุคคลทุกส่วนเรียบร้อยแล้ว
                                </div>
                                <div className="bg-white text-left" style={{ borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px" }}>
                                    <br />
                                    {(resultItems.additional !== undefined) &&
                                        <Container>
                                            <Row>
                                                <Col>
                                                    (<span className="pointer" style={{ textDecoration: "underline" }} onClick={e => { handleGetAdditional() }}>ดูเอกสารที่ส่งแล้ว</span>
                                                    {(!success) &&
                                                        <>
                                                            / <span className="pointer" style={{ textDecoration: "underline" }} onClick={e => handleEditAdditional()}>แก้ไขเอกสาร</span>
                                                        </>
                                                    }
                                                    )
                                                </Col>
                                            </Row>
                                        </Container>
                                    }
                                    {((resultItems.additional === undefined) || (resultItems.editAdditional === true)) &&
                                        <>
                                            <Container>
                                                <Row>
                                                    <Col md="5">
                                                        <input type="file" className={"form-control " + ((errorAdditionalUpload() !== null) ? "is-invalid" : "")} onChange={(e: any) => handleAdditionalUploadChange(e.target.files)} disabled={getLoadingAdditional() || success} />
                                                        <div className={(errorAdditionalUpload() !== null) ? "text-danger" : ""}><small>{errorAdditionalUpload()}</small></div>
                                                    </Col>
                                                    <Col md="3">
                                                        <Button className="btn btn-primary" disabled={getLoadingAdditional() || success} onClick={handleAdditionalUpload}>
                                                            {(loading || (updating)) &&
                                                                <img src={loadinglogo} width="30" height="30" className="d-inline-block align-top" alt="" />
                                                            }
                                                            {(!loading || (!updating)) &&
                                                                <>ส่งงาน</>
                                                            }
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </>
                                    }
                                    <br />
                                </div>
                                <div className="yellow-button text-left" style={{ padding: "20px", borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}>
                                    แบบสรุปความคิดเห็นวิทยากร
                                </div>
                                <div className="bg-white text-left" style={{ borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px" }}>
                                    <TrainerComment resultItems={resultItems} success={successTrainer} setResultItems={setTrainerComment} />
                                </div>
                            </div>
                        </>
                    }
                    <br />
                    {(((init < 1) || (loading) || (updating)) && (error === "")) &&
                        <div style={{ textAlign: "center" }}><img src={loadinglogo} width="30" height="30" className="d-inline-block align-top" alt="" /></div>
                    }
                    {(error !== "") &&
                        <div style={{ color: 'red', textAlign: "center" }}>{error}</div>
                    }
                    {(init === 1) && (!loading) && (!success) && (!updating) &&
                        <div style={{ justifyContent: "center", textAlign: "center" }}>
                            <button type="button" className="btn btn-outline-primary" style={{ width: "120px" }}
                                onClick={() => handleClose()} >
                                Close
                            </button>&nbsp;
                            <button type="button" className="btn btn-danger" style={{ width: "120px" }}
                                onClick={() => handleClear()} >
                                Reset
                            </button>&nbsp;
                            <button type="button" className="btn btn-success" style={{ width: "120px" }}
                                onClick={() => handleSubmit()} >
                                Submit
                            </button>
                        </div>
                    }
                    {(init === 1) && (!loading) && (!successTrainer) && (!updating) && (isTrainer) &&
                        <div style={{ justifyContent: "center", textAlign: "center" }}>
                            <button type="button" className="btn btn-outline-primary" style={{ width: "120px" }}
                                onClick={() => handleClose()} >
                                Close
                            </button>&nbsp;
                            <button type="button" className="btn btn-danger" style={{ width: "120px" }}
                                onClick={() => handleClear()} >
                                Reset
                            </button>&nbsp;
                            <button type="button" className="btn btn-success" style={{ width: "120px" }}
                                onClick={() => handleSubmit()} >
                                Submit
                            </button>
                        </div>
                    }
                    {(successTrainer) && (isTrainer) &&
                        <div style={{ justifyContent: "center", textAlign: "center" }}>
                            <button type="button" className="btn btn-outline-primary" style={{ width: "120px" }}
                                onClick={() => {
                                    handleClose();
                                }} >
                                Close
                            </button>
                        </div>
                    }
                    {(success) && (!isTrainer) &&
                        <div style={{ justifyContent: "center", textAlign: "center" }}>
                            <button type="button" className="btn btn-outline-primary" style={{ width: "120px" }}
                                onClick={() => {
                                    handleClose();
                                }} >
                                Close
                            </button>
                        </div>
                    }
                    <br />
                    <StudentFooter />
                </div>
            </div>
        </div>

        <Modal show={leave !== ""} onHide={() => { setLeave("") }}>
            <Modal.Header closeButton>
                <Modal.Title>ยกเลิกข้อมูลที่แก้ไข?</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => { setLeave("") }}>
                    Cancel
                </Button>
                <Button variant="danger" onClick={() => { handleLeave() }}>
                    OK
                </Button>
            </Modal.Footer>
        </Modal>

        <AlertContainer floatingTime={3000} />
    </>);
}

export default EToolKitAssignment;
