const StudentFooter = () => {
    return (<div className="student-wrapper-inner text-center" style={{ background: "#38383D", color: "#888888", fontSize: "10pt" }}>
        <br />
        ตลาดหลักทรัพย์แห่งประเทศไทย | สงวนลิขสิทธิ์<br />
        เนื้อหาทั้งหมดบนเว็บไซต์นี้ มีขึ้นเพื่อวัตถุประสงค์ในการให้ข้อมูลและเพื่อการศึกษาเท่านั้น ตลาดหลักทรัพย์ฯ มิได้ให้การรับรองและขอปฏิเสธต่อความรับผิดใด ๆ ในเว็บไซต์นี้<br />
        <span className="yellow link-hover" onClick={()=>{window.open('https://www.set.or.th/th/terms-and-conditions','_blank')}}>ข้อตกลงและเงื่อนไขการใช้งานเว็บไซต์</span> |&nbsp; 
        <span className="yellow link-hover" onClick={()=>{window.open('https://www.set.or.th/th/privacy-notice','_blank')}}>การคุ้มครองข้อมูลส่วนบุคคล</span> |&nbsp; 
        <span className="yellow link-hover" onClick={()=>{window.open('https://www.set.or.th/th/cookies-policy','_blank')}}>นโยบายการใช้คุกกี้</span> |&nbsp;
        <span className="yellow link-hover" onClick={()=>{window.open('https://www.set.or.th/th/thirdparty-terms','_blank')}}>เงื่อนไขการใช้ข้อมูลของผู้ให้บริการรายอื่น</span>    
        <br /><br />
    </div>);
}

export default StudentFooter;