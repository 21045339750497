import { Dispatch } from 'redux';
import { ActionType, SearchActionType } from '../search/types';
import { HistoryAPI } from '../../api/history-api';
import { dispatchError } from '../search/actions'; 

/**
 * จะได้ รายชื่อ บริษัท, หลักสูตร, สถานะ และผลรวมมาให้สำหรับ classid
 */
export const getresult = (class_id: number) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    HistoryAPI.listResult(class_id).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "result",
            page: "history",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("history",dispatch,error);
    })
};

/**
 * จะได้ประวัติของ student ทั้งหมด
 */
export const gethistory = (class_id: number,param: string,page: number,limit: number) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    HistoryAPI.listHistory(class_id,param,page,limit).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "history",
            page: "history",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("hisotry",dispatch,error);
    })
};

export const gettrainereachcourse = (class_id: number) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    HistoryAPI.listTrainerEachCourse(class_id).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "trainer",
            page: "history",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("history",dispatch,error);
    })
};

export const setseparategroup= (class_course_id: number,class_student_id: number,class_course_sessiont_trainer_id: number) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_UPDATING
    });
    HistoryAPI.setSeparateGroup(class_course_id,class_student_id,class_course_sessiont_trainer_id)
    .then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_UPDATED,
            payload: response.data,
            table: "separategroup",
            page: "history",
            action: ActionType.UPDATE
        });
    }, function (error) {
       dispatchError("history",dispatch,error);
    })
};

