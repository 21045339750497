import { useEffect } from 'react';
import { useDispatch, useStore } from 'react-redux';
import { studentLogout } from '../../store/auth/actions';
import { useHistory } from 'react-router-dom';
const StudentLogout = () => {
    const dispatch = useDispatch();
    const store = useStore();
    const history = useHistory();

    useEffect(() => {
        const unsubscribe = store.subscribe(() => {
            let res = store.getState().AuthReducer;
            if (!res.isLoggedIn) {
                history.push("/");
            } 
          })
        return unsubscribe;
    }, []);

    useEffect(() => {
        dispatch(studentLogout());
    }, []);

    return (<></>);
}

export default StudentLogout;