import React from 'react'
import { Button, Modal, ModalProps } from 'react-bootstrap'
import { PackageInteresting } from '../../../../model/response/partner-package';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CircleIcon from '@material-ui/icons/Lens';
type Props = ModalProps & {
    knowledgeItem?: PackageInteresting; 
    cancelButton?: string;
}

const PartnerPackageModal = (props: Props) => {
  return (
    <Modal
            {...props}
            dialogClassName="modal-80w"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            centered
        >
            <Modal.Body>
                {(props.knowledgeItem) && (
                    <div>
                        <table className='table mt-3' style={{ fontSize: "20px" }}>
                                <thead className='black-button'>
                                    <tr>
                                        <th style={{ width: '50%' }}>ประเภทหัวข้อที่สนใจ</th>
                                        <th style={{ width: '50%' }}>หมวดหมู่หัวข้อที่สนใจ</th>
                                    </tr>
                                </thead>
                                <tbody className='light-gray-button'>
                                    {props.knowledgeItem?.knowledgeList && props.knowledgeItem?.knowledgeList.map(x => (
                                        <tr key={'knowledge' + x.categoryId}>
                                            <td>{x.categoryName}</td>
                                            <td>
                                                {x.subCategories && x.subCategories.map(subCat => (
                                                    <div key={"subCat" + subCat.subCategoryId}>
                                                        {subCat.checked && <CheckCircleIcon fontSize="small" style={{ color: '#4ba31e' }} />}
                                                        {subCat.subCategoryName}
                                                    </div>
                                                ))}
                                                {!x.subCategories || x.subCategories.length == 0 && (
                                                    <div>
                                                        <CircleIcon fontSize="small" style={{ color: '#707070' }} />
                                                        ยังไม่สนใจ
                                                    </div>
                                                )}

                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                    </div>
                )}


            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.onHide}>
                    {props.cancelButton || 'Close'}
                </Button>
            </Modal.Footer>
        </Modal>
  )
}

export default PartnerPackageModal