import React, { useState } from 'react'
import ExpandMore from '@material-ui/icons/ExpandMore'
import ExpandLess from '@material-ui/icons/ExpandLess'
import { TrainingInfo } from '../../../../model/response/partner-activity-training'
import { CustomPage } from '../../../share/CustomPage';
import { Table } from 'react-bootstrap';
import ArrowDropDownIcon from '@material-ui/icons/ExpandMore';
import ArrowDropUpIcon from '@material-ui/icons/ExpandLess';
import { Util } from '../../../../api/util';
import iconNote from '../../../../img/iconNote.png'
import { getCourseType } from '../../../../api/define';
import { DEFAULT_FRONT } from '../../../../api/conf';
type Props = {
    items: TrainingInfo[];
    page: number;
    size: number;
    totalElement: number;
    loading: boolean;
    onPageChange?: (value: number) => void;
    onPageSizeChange?: (value: number) => void;
    setItems: (value: TrainingInfo[]) => void;
}

const PartnerActivityOrganizeTrainingPublic = ({ items, page, size, loading, totalElement, onPageChange, onPageSizeChange, setItems }: Props) => {
    const [expand, setExpand] = useState(true);
    const handleRow = (i: number) => {
        if (items !== null) {
            var newItems = [...items];
            newItems[i].show = ((newItems[i].show === undefined) || (newItems[i].show === false));
            setItems(newItems);
        }
    }
    const openProject = (item: TrainingInfo) => {
        window.open(DEFAULT_FRONT + "/admin/class/" + item.id, "_blank ")
    }
    const createCourse = (i: number) => {
        var res: any[] = [];
        if (items != null) {
            if (items[i].show === true) {
                if ((items[i].courses !== undefined) && items[i].courses.length > 0) {
                    res.push(
                        <tr>
                            <th></th>
                            <th style={{ fontFamily: "DB Helvethaica Bold" }}>หลักสูตร</th>
                            <th style={{ fontFamily: "DB Helvethaica Bold" }}>ประเภทการเรียน</th>
                            <th style={{ fontFamily: "DB Helvethaica Bold" }}>วันที่เรียน</th>
                            <th style={{ fontFamily: "DB Helvethaica Bold" }}>เวลา (ชม.)</th>
                            <th style={{ fontFamily: "DB Helvethaica Bold" }} className='text-right'>จำนวนผู้เข้าร่วมโครงการ</th>
                            <th style={{ fontFamily: "DB Helvethaica Bold" }} className='text-right'>จำนวนผู้ผ่านโครงการ</th>
                        </tr>
                    );
                }
                for (var j = 0; j < ((items[i].courses === undefined) ? 0 : items[i].courses.length); j++) {
                    var c = items[i].courses[j];
                    res.push(
                        <tr style={{ backgroundColor: "#EFF5FD" }}>
                            <td></td>
                            <td >{c.name}</td>
                            <td >{getCourseType(c.courseType as any)}</td>
                            <td >{c.sessionDates && c.sessionDates.map(x => (
                                <div className='row'>{Util.datetostr(new Date(x.fromDate))}</div>
                            ))}</td>
                            <td >{c.sessionDates && c.sessionDates.map(x => (
                                <div className='row'>{x.time}</div>
                            ))}</td>
                            <td className='text-right pr-3'>{c.cooperativeAmount}</td>
                            <td className='text-right pr-3'>{c.passAmount}</td>
                        </tr>);
                }
            }
        }
        return res;
    }
    return (
        <React.Fragment>
            <div style={{ background: "white" }} className='mt-2'>
                <div className="text-left" style={{ position: "relative", marginBottom: 5 }}>
                    <div className={'d-flex yellow-button'} style={{ padding: "15px", borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}>
                        {((expand === undefined) || (expand === false)) &&
                            <div onClick={(e) => setExpand(true)}><ExpandMore className="pointer mr-2" ></ExpandMore></div>
                        }
                        {(expand === true) &&
                            <div onClick={(e) => setExpand(false)}><ExpandLess className="pointer mr-2" ></ExpandLess></div>
                        }
                        <label className='ml-2 my-auto'><span style={{ fontWeight: 600, marginRight: 10 }}>ข้อมูลการจัดอบรมทั่วไป</span></label>
                    </div>
                    {(expand === true) && (
                        <div style={{ padding: "15px", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px", border: "3px solid #fab232" }}>
                            {((items !== null) && (items.length >= 1)) && (
                                <CustomPage current={page} pageSize={size} total={totalElement} onPageChange={onPageChange} onPageSizeChange={onPageSizeChange} loading={loading} postElement={<></>}>
                                    {((items !== null) && (items.length >= 1)) && <>
                                        <Table striped hover>
                                            <thead className="black-button">
                                                <tr>
                                                    <th></th>
                                                    <th className='text-left' style={{ width: '50%' }}>ชื่อโครงการ</th>
                                                    <th></th>
                                                    <th></th>
                                                    <th className='text-left'>รอบที่</th>
                                                    <th className='text-left'>วันที่เริ่ม-สิ้นสุดโครงการ</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody style={{ fontSize: "20px" }}>
                                                {items.map((item, index) => (
                                                    <React.Fragment key={'partner_activity-' + index}>
                                                        <tr>
                                                            <td>
                                                                {((item.show === undefined) || (item.show === false)) &&
                                                                    <div onClick={(e) => handleRow(index)}><ArrowDropDownIcon className="yellow-circle white pointer" ></ArrowDropDownIcon></div>
                                                                }
                                                                {(item.show === true) &&
                                                                    <div onClick={(e) => handleRow(index)}><ArrowDropUpIcon className="yellow-circle white pointer" ></ArrowDropUpIcon></div>
                                                                }
                                                            </td>
                                                            <td className='text-left'>{item.projectCode}: {item.projectName}</td>
                                                            <td></td>
                                                            <td></td>
                                                            <td className='text-left'>{item.session}</td>
                                                            <td className='text-left'>{Util.datetostr(new Date(item.startDate))} - {Util.datetostr(new Date(item.toDate))}</td>
                                                            <td> <img src={iconNote} onClick={(e) => openProject(item)} width={20} height={20} id={"icon_note_calculators" + index} style={{ cursor: "pointer" }} className='mr-2' /></td>
                                                        </tr>
                                                        {createCourse(index)
                                                        }
                                                    </React.Fragment>

                                                ))}
                                            </tbody>
                                        </Table>
                                    </>}
                                </CustomPage>
                            )}

                            {((items == null) || (items.length < 1)) && <span>ไม่พบข้อมูลการจัดอบรมทั่วไป</span>}
                            <br />
                        </div>
                    )}
                </div>
            </div>
        </React.Fragment>
    )
}

export default PartnerActivityOrganizeTrainingPublic