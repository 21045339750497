import React from 'react'

const tabStyle = { paddingLeft: "20px", paddingRight: "20px", paddingTop: "10px", paddingBottom: "10px", borderTopLeftRadius: "5px", borderTopRightRadius: "5px",minWidth:"25%" };
const tabPointerStyle = { cursor: "pointer", paddingLeft: "20px", paddingRight: "20px", paddingTop: "10px", paddingBottom: "10px", borderTopLeftRadius: "5px", borderTopRightRadius: "5px",minWidth:"25%"  };

const PartnerTab = (step: number, nextStep: boolean, handleProfile: Function | null,handleActivity: Function | null,handlePackage: Function | null) => {
    return (<div className="row" style={{ justifyContent: "left", paddingLeft: "20px" }}>
        <div className={(step === 1) ? "yellow-button" : "gray-button"} style={(handleProfile == null) ? tabStyle : tabPointerStyle} onClick={e => ((handleProfile != null) ? handleProfile() : () => { })}>
            <b>Profile</b>
        </div>&nbsp;
        <div className={(step === 2) ? "yellow-button" : "gray-button"} style={(handleActivity == null) ? tabStyle : tabPointerStyle} onClick={e => ((handleActivity != null) ? handleActivity() : () => { })}>
            <b>Activity</b>
        </div>&nbsp;
        <div className={(step === 3) ? "yellow-button" : "gray-button"} style={(handlePackage == null) ? tabStyle : tabPointerStyle} onClick={e => ((handlePackage != null) ? handlePackage() : () => { })}>
            <b>Package</b>
        </div>
        
    </div>);
}

export default PartnerTab;