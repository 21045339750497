import React, { useEffect, useState } from 'react'
import MainMenu from '../menu'
import PartnerGeneralInfo from '../managePartner/component/PartnerGeneralInfo';
import { PartnerProfileListResponse } from '../../../model/response/partner-profile';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useStore } from 'react-redux';
import { AdressResponse } from '../../../model/response/adress.model';
import { queryclear } from '../../../store/search/actions';
import { listorganization } from '../../../store/editpartner/actions';
import { getAdressInfomation, getFaculty } from '../../../store/publicPartner/action';
import { createLogRequestToPartner, getPartnerProfile } from '../../../store/partner/actions';
import PartnerOrgPrivateCompany from '../managePartner/component/PartnerOrgPrivateCompany';
import PartnerOrgGoverment from '../managePartner/component/PartnerOrgGoverment';
import PartnerOrgIndepandent from '../managePartner/component/PartnerOrgIndepandent';
import PartnerUniversity from '../managePartner/component/PartnerUniversity';
import PartnerVocation from '../managePartner/component/PartnerVocation';
import PartnerSchool from '../managePartner/component/PartnerSchool';
import PartnerWelfareBenefit from '../managePartner/component/PartnerWelfareBenefit';
import PartnerInfo from '../managePartner/component/PartnerInfo';

type Props = {}

const CreatePartnerToDatabase = (props: Props) => {
    const [init, setInit] = useState(1);
    const [partnerProfileListNew, setPartnerProfileListNew] = useState<PartnerProfileListResponse>();
    let { logRequestId } = useParams<{ logRequestId: string }>();
    let { orgTypeId } = useParams<{ orgTypeId: string }>();
    let { orgGroupId } = useParams<{ orgGroupId: string }>();
    let { partnerName } = useParams<{ partnerName: string }>();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const store = useStore();
    const history = useHistory();
    const [success, setSuccess] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [submittedMerge, setSubmittedMerge] = useState(false);
    const [orgTypeItem, setOrgTypeItem] = useState<any[]>([])
    const [province, setProvince] = useState<AdressResponse[]>([]);
    const [facultyItem, setFacultyItem] = useState<any[]>([]);
    const dispatch = useDispatch();
    useEffect(() => {
        const unsubscribe = store.subscribe(() => {
            let res = store.getState().SearchReducer;
            if (res.isLoading) {
                setError('');
                setLoading(true);
            } else if ((res.isLoaded) && (!res.isUpdated)) {
                setError('');
                setLoading(false);
                if (res.data != null) {
                    if (res.table === "org_type") {
                        setOrgTypeItem(res.data);
                    } else if (res.table === "address-infomation") {
                        setProvince(res.data);
                    } else if (res.table === "faculty") {
                        setFacultyItem(res.data);
                    } else if (res.table === "get_partner_profile") {
                        setPartnerProfileListNew(res.data);
                        setInit(1);
                    }
                }
            } else if (res.isUpdating) {
                setError('');
                setLoading(true);
            } else if (res.isUpdated) {
                setError('');
                setLoading(false);
                dispatch(queryclear());
                if (res.table == 'create_log_to_partner') {
                    alert({ message: 'บันทึกข้อมูลสำเร็จ', type: 'success' });
                    setTimeout(() => { history.push('/admin/log-request') }, 300)
                }


            } else if (res.isFailed) {
                setError(res.data.message);
                setLoading(false);
                alert({ message: res.data.message, type: 'error' });
            }

        })
        dispatch(listorganization(0, 0, true));
        dispatch(getAdressInfomation())
        dispatch(getFaculty())
        dispatch(getPartnerProfile(parseInt(logRequestId)));
        return unsubscribe;
    }, []);
    const getOrgTypeName = (id: any) => {
        if (orgTypeItem) {
            let orgTypeName = orgTypeItem.find(x => x.id == id)?.name;
            return orgTypeName ? orgTypeName : null
        }
        return null

    }
    const getOrgGroupName = (id: any) => {
        let orgGroup = null
        if (orgTypeItem) {

            orgTypeItem.forEach(x => {
                let orgGroupName = x.orgGroup.find((y: any) => y.id == id);
                if (orgGroupName) {
                    orgGroup = orgGroupName.name
                }
            })

        }
        return orgGroup
    }
    const handleBack = () => {
        history.push('/admin/log-request/merge-partner/' + logRequestId + '/' + orgTypeId + '/' + orgGroupId + '/' + partnerName);
    }
    const handleSubmit = () => {
        const body = { ...partnerProfileListNew }
        dispatch(createLogRequestToPartner(parseInt(logRequestId), body as any))
    }
    return (
        <div>
            <MainMenu>
                {init == 1 && (
                    <div className="query-wrapper">
                        <br />
                        <div className="yellow-button" style={{ textAlign: "left", borderTopLeftRadius: "30px", borderTopRightRadius: "30px", height: "70px", paddingTop: "15px", paddingLeft: "15px" }}><h4 className="header">บริหารจัดการข้อมูลองค์กรพันธมิตร - บันทึกข้อมูลลงฐานข้อมูล</h4></div>
                        <br />
                        <div style={{ background: "#fff7e9" }} className='mt-2'>
                            <PartnerGeneralInfo parnerProfileList={partnerProfileListNew as any}
                                success={success}
                                orgTypeItem={orgTypeItem}
                                submitted={submitted}
                                province={province}
                                readonly={true}
                                setParnerProfileListChange={setPartnerProfileListNew}
                                state='new'
                                submittedMerge={submittedMerge}
                                hideDropdown={true}
                            />
                        </div>
                        {(getOrgTypeName(partnerProfileListNew?.orgType) == 'เอกชน' || getOrgTypeName(partnerProfileListNew?.orgType) == 'รัฐวิสาหกิจ') && (
                            <div style={{ background: "#fff7e9" }} >
                                <PartnerOrgPrivateCompany parnerProfileList={partnerProfileListNew as any}
                                    submitted={submitted}
                                    setParnerProfileListChange={setPartnerProfileListNew}
                                    readonly={true}
                                    state='new'
                                    submittedMerge={submittedMerge}
                                    hideDropdown={true}
                                    success={success} />
                            </div>

                        )}
                        {(getOrgTypeName(partnerProfileListNew?.orgType) == 'รัฐ') && (
                            <div style={{ background: "#fff7e9" }} >
                                <PartnerOrgGoverment parnerProfileList={partnerProfileListNew as any}
                                    submitted={submitted}
                                    readonly={true}
                                    state='new'
                                    submittedMerge={submittedMerge}
                                    setParnerProfileListChange={setPartnerProfileListNew}
                                    hideDropdown={true}
                                    success={success} />
                            </div>

                        )}
                        {(getOrgTypeName(partnerProfileListNew?.orgType) == 'องค์กรอิสระ') && (
                            <div style={{ background: "#fff7e9" }} >
                                <PartnerOrgIndepandent parnerProfileList={partnerProfileListNew as any}
                                    submitted={submitted}
                                    readonly={true}
                                    state='new'
                                    submittedMerge={submittedMerge}
                                    setParnerProfileListChange={setPartnerProfileListNew}
                                    hideDropdown={true}
                                    success={success} />
                            </div>

                        )}
                        {(getOrgTypeName(partnerProfileListNew?.orgType) == 'สถาบันการศึกษา') && (<>
                            {(getOrgGroupName(partnerProfileListNew?.orgGroup) == 'มหาวิทยาลัย') && (
                                <div style={{ background: "#fff7e9" }} >
                                    <PartnerUniversity parnerProfileList={partnerProfileListNew as any}
                                        submitted={submitted}
                                        readonly={true}
                                        state='new'
                                        facultyItem={facultyItem}
                                        submittedMerge={submittedMerge}
                                        facultyOldItem={[]}
                                        setParnerProfileListChange={setPartnerProfileListNew}
                                        hideDropdown={true}
                                        success={success} />
                                </div>

                            )}
                            {(getOrgGroupName(partnerProfileListNew?.orgGroup) == 'วิทยาลัย') && (
                                <div style={{ background: "#fff7e9" }} >
                                    <PartnerUniversity parnerProfileList={partnerProfileListNew as any}
                                        submitted={submitted}
                                        readonly={true}
                                        state='new'
                                        facultyItem={facultyItem}
                                        submittedMerge={submittedMerge}
                                        facultyOldItem={[]}
                                        setParnerProfileListChange={setPartnerProfileListNew}
                                        hideDropdown={true}
                                        success={success} />
                                </div>
                            )}
                            {(getOrgGroupName(partnerProfileListNew?.orgGroup) == 'อาชีวศึกษา') && (
                                <div style={{ background: "#fff7e9" }} >
                                    <PartnerVocation parnerProfileList={partnerProfileListNew as any}
                                        readonly={true}
                                        state='new'
                                        submitted={submitted}
                                        submittedMerge={submittedMerge}
                                        setParnerProfileListChange={setPartnerProfileListNew}
                                        hideDropdown={true}
                                        success={success} />
                                </div>

                            )}
                            {(getOrgGroupName(partnerProfileListNew?.orgGroup) == 'โรงเรียน') && (
                                <div style={{ background: "#fff7e9" }} >
                                    <PartnerSchool parnerProfileList={partnerProfileListNew as any}
                                        readonly={true}
                                        state='new'
                                        submitted={submitted}
                                        submittedMerge={submittedMerge}
                                        setParnerProfileListChange={setPartnerProfileListNew}
                                        hideDropdown={true}
                                        success={success} />
                                </div>

                            )}
                            {(getOrgGroupName(partnerProfileListNew?.orgGroup) == 'อื่น ๆ') && (
                                <div style={{ background: "#fff7e9" }} >
                                    <PartnerSchool parnerProfileList={partnerProfileListNew as any}
                                        readonly={true}
                                        state='new'
                                        submitted={submitted}
                                        submittedMerge={submittedMerge}
                                        setParnerProfileListChange={setPartnerProfileListNew}
                                        hideDropdown={true}
                                        success={success} />
                                </div>

                            )}

                        </>)}
                        <div style={{ background: "#fff7e9" }} className='mt-2'>
                            <PartnerWelfareBenefit parnerProfileList={partnerProfileListNew as any}
                                readonly={true}
                                state='new'
                                success={success}
                                submitted={submitted}
                                submittedMerge={submittedMerge}
                                hideDropdown={true}
                                setParnerProfileListChange={setPartnerProfileListNew}
                            />
                        </div>
                        <div style={{ background: "#fff7e9" }} className='mt-2'>
                            <PartnerInfo parnerProfileList={partnerProfileListNew as any}
                                success={success}
                                submitted={submitted}
                                readonly={true}
                                state='new'
                                oldPartnerItem={[]}
                                handleResendEmail={() => { }}
                                submittedMerge={submittedMerge}
                                hideDropdown={true}
                                setParnerProfileListChange={setPartnerProfileListNew} />
                        </div>
                        {(!success) &&
                            <div className='d-flex mt-3'>
                                <div className='ml-auto mb-3'>
                                    <button type="button" className="btn btn-secondary" style={{ width: "120px" }}
                                        onClick={() => handleBack()} >
                                        Back
                                    </button>&nbsp;
                                    <button type="button" className="btn btn-success" style={{ width: "120px" }}
                                        onClick={() => handleSubmit()} >
                                        Save
                                    </button>
                                </div>
                            </div>
                        }
                    </div>
                )}
            </MainMenu>
        </div>
    )
}

export default CreatePartnerToDatabase