import { StudentResultTypeNoContactDetailRequest } from '../model/request/class-course.model';
import { StudentResultTypeNoContactDetailResponse, StudentResultTypeNoContactResponse } from '../model/response/class-course.model';
import { apiClient } from './axios-intercept';
import { DEFAULT_SERVER } from './conf';

const RESULT = "/api/v1/result";
const EXPORT = "/export";
const TEMPLATE = "/template";
const SEARCH_RESULT_URL = DEFAULT_SERVER + RESULT;
const SAVE_RESULT_URL = DEFAULT_SERVER + RESULT + "/class";
const IMPORT_RESULT_URL = DEFAULT_SERVER + RESULT + "/import";
const SEARCH_RESULT_NUMBER_URL = DEFAULT_SERVER + "/api/v1/result/type-no-contact/class"

export const EXPORT_RESULT_URL = DEFAULT_SERVER + RESULT + EXPORT;
export const TEMPLATE_RESULT_URL = DEFAULT_SERVER + RESULT + TEMPLATE;

export class ResultAPI {
    public static listResultTypeNoContactDetail = async (classId: number, partnerId: number | undefined, classCourseId: number | undefined): Promise<StudentResultTypeNoContactDetailResponse[] | any> => {
        return new Promise(function (resolve, reject) {
            var s = SEARCH_RESULT_NUMBER_URL + "/" + classId + "/detail";
            var queryParams: string[] = [];
            if (partnerId) {
                queryParams.push(`partnerId=${partnerId}`)
            }
            if (classCourseId) {
                queryParams.push(`classCourseId=${classCourseId}`)
            }
            apiClient.get(s + '?' + queryParams.join('&'),
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    return resolve(response);
                }).catch(err => {
                    console.log("list result error:", err);
                    return reject(err);
                })
        })
    }

    public static listResultTypeNoContact = async (classId: number, partnerId: number | undefined, classCourseId: number | undefined): Promise<StudentResultTypeNoContactResponse[] | any> => {
        return new Promise(function (resolve, reject) {
            var s = SEARCH_RESULT_NUMBER_URL + "/" + classId;
            var queryParams: string[] = [];
            if (partnerId) {
                queryParams.push(`partnerId=${partnerId}`)
            }
            if (classCourseId) {
                queryParams.push(`classCourseId=${classCourseId}`)
            }
            apiClient.get(s + '?' + queryParams.join('&'),
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    return resolve(response);
                }).catch(err => {
                    console.log("list result error:", err);
                    return reject(err);
                })
        })
    }

    public static list = async (classId: number, name: string, classCourseId: number, page: number, limit: number): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = SEARCH_RESULT_URL + "/class/" + classId + "/class_course/" + classCourseId + "?" + ((name !== "") ? "name=" + name : "") + "&page=" + page + "&limit=" + limit;
            apiClient.get(s,
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    console.log("list result response : ", response);
                    return resolve(response);
                }).catch(err => {
                    console.log("list result error:", err);
                    return reject(err);
                })
        })
    }

    public static save = async (id: number, classCourseId: number, item: any): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = SAVE_RESULT_URL + "/" + id + "/class_course/" + classCourseId;
            apiClient.post(s,
                //payload
                item
                , {//options
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then((response) => {
                    console.log("update result response : ", response);
                    return resolve(response);
                }).catch(err => {
                    console.log("update result error:", err);
                    return reject(err);
                })
        })
    }

    public static import = async (classId: number, classCourseId: number, file: FileList): Promise<any> => {
        var formData = new FormData();
        formData.append("file", file[0]);

        return new Promise(function (resolve, reject) {
            var s = IMPORT_RESULT_URL + "/" + classId + "/class_course/" + classCourseId;
            apiClient.post(s, formData,
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    console.log("import result response : ", response);
                    return resolve(response);
                }).catch(err => {
                    console.log("import result error:", err);
                    return reject(err);
                })
        })
    }

    public static listStatus = async (): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = SEARCH_RESULT_URL + "/status";
            apiClient.get(s,
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    console.log("list status response : ", response);
                    return resolve(response);
                }).catch(err => {
                    console.log("list status error:", err);
                    return reject(err);
                })
        })
    }

    public static saveResultTypeNoContactDetail = async (class_id: number, item: StudentResultTypeNoContactDetailRequest[]): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = SEARCH_RESULT_NUMBER_URL + "/" + class_id;
            apiClient.post(s,
                //payload
                item
                , {//options
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then((response) => {
                    console.log("update result response : ", response);
                    return resolve(response);
                }).catch(err => {
                    console.log("update result error:", err);
                    return reject(err);
                })
        })
    }
}