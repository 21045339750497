import { Dispatch } from 'redux';
import { ActionType, SearchActionType } from '../search/types';
import { dispatchError } from '../search/actions'; 
import { EToolkitAPI } from '../../api/etoolkit-api';

export const saveassignment = (documentId: number,item: any,studentEmail:string | null,saveByTrainer:boolean | null) => (dispatch: Dispatch<any>) => {
   dispatch({
        type: SearchActionType.QUERY_UPDATING
    });
    EToolkitAPI.saveAssignment(documentId,item,studentEmail,saveByTrainer).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_UPDATED,
            payload: response.data,
            table: "etoolkit_assignment",
            page: "etoolkit_assignment",
            action: ActionType.QUERY
        });
    }, function (error) {
        if ((error.response!==undefined) && (error.response.status===400)) {
            dispatch({
                type: SearchActionType.QUERY_ERROR,
                page: "etoolkit_assignment",
                payload: error.response.data.additional
            });
        } else 
            dispatchError("etoolkit_assignment",dispatch,error);
    })
};


export const getassignment = (documentId: number) => (dispatch: Dispatch<any>) => {
    dispatch({
         type: SearchActionType.QUERY_LOADING
     });
     EToolkitAPI.getAssignment(documentId,"","").then(function (response) {
         dispatch({
             type: SearchActionType.QUERY_LOADED,
             payload: response.data,
             table: "etoolkit_assignment",
             page: "etoolkit_assignment",
             action: ActionType.QUERY
         });
     }, function (error) {
         if ((error.response!==undefined) && (error.response.status===400)) {
             dispatch({
                 type: SearchActionType.QUERY_ERROR,
                 page: "etoolkit_assignment",
                 payload: error.response.data.additional
             });
         } else 
             dispatchError("etoolkit_assignment",dispatch,error);
     })
 };
 
 export const getassignmentbyemail = (documentId: number,email: string | null,studentEmail: string | null) => (dispatch: Dispatch<any>) => {
    dispatch({
         type: SearchActionType.QUERY_LOADING
     });
     EToolkitAPI.getAssignment(documentId,email,studentEmail).then(function (response) {
         dispatch({
             type: SearchActionType.QUERY_LOADED,
             payload: response.data,
             table: "etoolkit_assignment",
             page: "etoolkit_assignment",
             action: ActionType.QUERY
         });
     }, function (error) {
         if ((error.response!==undefined) && (error.response.status===400)) {
             dispatch({
                 type: SearchActionType.QUERY_ERROR,
                 page: "etoolkit_assignment",
                 payload: error.response.data.additional
             });
         } else 
             dispatchError("etoolkit_assignment",dispatch,error);
     })
 };

 export const uploadadditional = (documentId: string,file: FileList) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_UPDATING,
    });
    EToolkitAPI.uploadAdditional(documentId,file).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_UPDATED,
            payload: response.data,
            table: "additional",
            page: "etoolkit_assignment",
            action: ActionType.UPDATE
        });
    }, function (error) {
       dispatchError("etoolkit_assignment",dispatch,error);
    })
};