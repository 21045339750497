import React, { useEffect, useState } from 'react'
import MainMenu from '../../menu'
import loadinglogo from '../../../../img/loading.gif';
import PartnerTab from '../../../tab/partner-tab';
import { useDispatch, useStore } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import ManagePartnerSuggestion from '../../managePartner/component/ManagePartnerSuggestion';
import PartnerGeneralInfo from '../../managePartner/component/PartnerGeneralInfo';
import { PartnerProfileListResponse, QuestionResponse, ScopeResponse, SurveyProjectResponse, defaultPartner } from '../../../../model/response/partner-profile';
import { AdressResponse } from '../../../../model/response/adress.model';
import PartnerOrgPrivateCompany from '../../managePartner/component/PartnerOrgPrivateCompany';
import PartnerOrgGoverment from '../../managePartner/component/PartnerOrgGoverment';
import PartnerOrgIndepandent from '../../managePartner/component/PartnerOrgIndepandent';
import PartnerUniversity from '../../managePartner/component/PartnerUniversity';
import PartnerVocation from '../../managePartner/component/PartnerVocation';
import PartnerSchool from '../../managePartner/component/PartnerSchool';
import PartnerGeneralInfoPartner from './PartnerGeneralInfoPartner';
import { queryclear } from '../../../../store/search/actions';
import { getAdressInfomation, getFaculty } from '../../../../store/publicPartner/action';
import { listKnowledgeDepartment, listhr, listorganization } from '../../../../store/editpartner/actions';
import { createPartnerProfile, getMergeLogRequestPartnerProfile, getParnerProfile, getSurveyProjectForAdd, resendVerifyEmail, resendVerifyEmailByPartnerId, updatePartnerProfileByPartnerId } from '../../../../store/partner/actions';
import { EmployeeResponse } from '../../../../model/response/hr.model';
import { AlertContainer, alert } from 'react-custom-alert';
import PartnerWelfareBenefit from '../../managePartner/component/PartnerWelfareBenefit';
import PartnerInfo from '../../managePartner/component/PartnerInfo';
import { PartnerKnowledgeRegistration } from '../../../../model/request/partner-knowledge-regis';
import { message } from 'antd';
import PartnerExtraFund from './PartnerExtraFund';
import PartnerExtraMou from './PartnerExtraMou';
import { KnowledgeDepartmentResponse } from '../../../../model/response/manage-knowledge';
import PartnerExtraSurveyProject from './PartnerExtraSurveyProject';
import { listproject } from '../../../../store/class/actions';
type Props = {}

const PartnerProfile = (props: Props) => {
    const [init, setInit] = useState(0);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [partnerProfileList, setPartnerProfileList] = useState<PartnerProfileListResponse>({ admin: [] } as any);
    const [orgTypeItem, setOrgTypeItem] = useState<any[]>([])
    const [province, setProvince] = useState<AdressResponse[]>([]);
    const [facultyItem, setFacultyItem] = useState<any[]>([]);
    const [adminItem, setAdminItem] = useState<EmployeeResponse[]>([]);
    const [isDisableOrgGroup, setIsDisableOrgGroup] = useState(false);
    const [departmentItem, setDepartmentItem] = useState<KnowledgeDepartmentResponse[]>([]);
    const [surveyProjectForAdd,setSurveyProjectForAdd] = useState<SurveyProjectResponse[]>([]);
    const [projectItem, setProjectItem] = useState<Array<any>>([])
    const store = useStore();
    const history = useHistory();
    const dispatch = useDispatch();
    const [messageApi, contextHolder] = message.useMessage();
    let { id } = useParams<{ id: string }>();
    useEffect(() => {
        const unsubscribe = store.subscribe(() => {
            let res = store.getState().SearchReducer;
            if (res.isLoading) {
                setError('');
                setLoading(true);
            } else if ((res.isLoaded) && (!res.isUpdated)) {
                setError('');
                setLoading(false);
                if (res.data != null) {
                    if (res.table === "org_type") {
                        setOrgTypeItem(res.data);
                    } else if (res.table === "address-infomation") {
                        setProvince(res.data);
                    } else if (res.table === "faculty") {
                        setFacultyItem(res.data);
                    } else if (res.table === "get_parner_profile") {
                        setPartnerProfileList(res.data);
                        setInit(1);
                    } else if (res.table === "partner_resend_verify_email") {
                        alert({ message: 'ส่ง e-Mail สำเร็จ', type: 'success' });
                    } else if (res.table === "hr") {
                        setAdminItem(res.data);
                    }else if (res.table === 'list_knowledge_department') {
                        setDepartmentItem(res.data);
                    }else if (res.table === 'survey_project_for_add') {
                        setSurveyProjectForAdd(res.data)
                    }else if (res.table === "project") {
                        setProjectItem(res.data);
                    }
                }
            } else if (res.isUpdating) {
                setError('');
                setLoading(true);
            } else if (res.isUpdated) {
                setError('');
                setLoading(false);
                setSuccess(true);
                dispatch(queryclear());
                if (res.table == 'update_partner_profile') {
                    alert({ message: 'บันทึกข้อมูลสำเร็จ', type: 'success' });
                    setTimeout(() => { history.push('/admin/search/partner') }, 300)

                } else if (res.table == 'create_partner_profile') {
                    alert({ message: 'บันทึกข้อมูลสำเร็จ', type: 'success' });
                    setTimeout(() => { history.push('/admin/search/partner') }, 300)
                }


            } else if (res.isFailed) {
                setError(res.data.message);
                setLoading(false);
                alert({ message: res.data.message, type: 'error' });
            }

        })
        dispatch(getSurveyProjectForAdd());
        dispatch(listproject());
        dispatch(listorganization(0, 0, true));
        dispatch(getAdressInfomation())
        dispatch(getFaculty());
        dispatch(listhr());
        dispatch(listKnowledgeDepartment());
        
        if (id != "0") {
            dispatch(getParnerProfile(parseInt(id)))
            setIsDisableOrgGroup(true)
        } else {
            setInit(1);
            setIsDisableOrgGroup(false)
        }
        return unsubscribe;
    }, []);
    const handleProfile = () => {
        history.push("/admin/partner/profile/" + id);
    }
    const handleActivityOrganizeTraining = () => {
        history.push("/admin/partner/activity/" + id + "/organize-training");
    }
    const handlePackage = () => {
        history.push("/admin/partner/package/" + id);
    }
    const getOrgTypeName = (id: any) => {
        if (orgTypeItem) {
            let orgTypeName = orgTypeItem.find(x => x.id == id)?.name;
            return orgTypeName ? orgTypeName : null
        }
        return null

    }
    const getOrgGroupName = (id: any) => {
        let orgGroup = null
        if (orgTypeItem) {

            orgTypeItem.forEach(x => {
                let orgGroupName = x.orgGroup.find((y: any) => y.id == id);
                if (orgGroupName) {
                    orgGroup = orgGroupName.name
                }
            })

        }
        return orgGroup
    }
    const handleResendEmail = (partner: PartnerKnowledgeRegistration) => {
        // TODO RESEND MAIL
        dispatch(resendVerifyEmailByPartnerId((parseInt(id)), partner))
    }
    const handleBack = () => {
        history.push('/admin/search/partner')
    }
    const handleClear = () => {
        if (id != "0") {
            dispatch(getParnerProfile(parseInt(id)))
            setIsDisableOrgGroup(true)
        } else {
            var newItem = { ...defaultPartner };
            setPartnerProfileList(newItem as any)
        }
    }
    const validateRequired = (value: string | boolean | undefined | any[] | null | number | Date) => {
        return (value == null || value == undefined || value == '')
    }
    const validateRequireBenefit = (check: boolean, value: string | boolean | undefined | any[] | null | number) => {
        if (check) {
            return (value == null || value == undefined || value == '')
        } else {
            return false
        }
    }
    const invalidGeneralInfo = () => {
        let error = false;
        if (validateRequired(partnerProfileList?.orgType) ||
            validateRequired(partnerProfileList?.orgGroup) ||
            validateRequired(partnerProfileList?.houseNo) ||
            validateRequired(partnerProfileList?.province) ||
            validateRequired(partnerProfileList?.district) ||
            validateRequired(partnerProfileList?.subDistrict) ||
            validateRequired(partnerProfileList?.zipCode)) {
            error = true;
        }
        if (!partnerProfileList?.admin || partnerProfileList?.admin.length <= 0) {
            error = true
        }
        return error
    }
    const invalidOrganization = () => {
        let error = false
        if ((getOrgTypeName(partnerProfileList?.orgType) == 'เอกชน' || getOrgTypeName(partnerProfileList?.orgType) == 'รัฐวิสาหกิจ')) {
            if (validateRequired(partnerProfileList?.employee)) {
                error = true;
            }
        } else if ((getOrgTypeName(partnerProfileList?.orgType) == 'รัฐ')) {
            if (validateRequired(partnerProfileList?.personnel) ||
                validateRequired(partnerProfileList?.governmentOfficer) ||
                validateRequired(partnerProfileList?.governmentEmployee) ||
                validateRequired(partnerProfileList?.employee) ||
                validateRequired(partnerProfileList?.memberUnderSupervision)) {
                error = true;
            }
        } else if ((getOrgTypeName(partnerProfileList?.orgType) == 'องค์กรอิสระ')) {
            if (validateRequired(partnerProfileList?.employee) ||
                validateRequired(partnerProfileList?.memberUnderSupervision)) {
                error = true;
            }
        } else if ((getOrgTypeName(partnerProfileList?.orgType) == 'สถาบันการศึกษา')) {
            if (getOrgGroupName(partnerProfileList?.orgGroup) == 'มหาวิทยาลัย') {
                error = invalidUniversity()
            } else if (getOrgGroupName(partnerProfileList?.orgGroup) == 'วิทยาลัย') {
                error = invalidUniversity()
            } else if (getOrgGroupName(partnerProfileList?.orgGroup) == 'อาชีวศึกษา') {
                error = invalidVocation()
            } else {
                error = validateRequired(partnerProfileList?.teacherNo) ||
                    validateRequired(partnerProfileList?.personnel) ||
                    validateRequired(partnerProfileList?.studentNo)
            }
        }
        return error;
    }
    const invalidUniversity = () => {
        let error = false;
        if (validateRequired(partnerProfileList?.teacherNo) ||
            validateRequired(partnerProfileList?.personnel) ||
            validateRequired(partnerProfileList?.studentNo)) {
            error = true;
        }
        if (partnerProfileList?.faculty && partnerProfileList?.faculty.length > 0) {
            partnerProfileList?.faculty.forEach(faculty => {
                faculty.facultyInfo && faculty.facultyInfo.forEach(x => {
                    if (x.facultyName == 0) {
                        error = validateRequired(x.otherFacultyName) || validateRequired(x.teacherNo) || validateRequired(x.personnel) || validateRequired(x.studentNo)
                    } else {
                        error = validateRequired(x.teacherNo) || validateRequired(x.personnel) || validateRequired(x.studentNo)
                    }
                })
                faculty.branchInfo && faculty.branchInfo.forEach(x => {
                    error = validateRequired(x.branchName) || validateRequired(x.teacherNo) || validateRequired(x.teacherNo) || validateRequired(x.personnel) || validateRequired(x.studentNo)

                })
            })
        }
        return error
    }
    const invalidVocation = () => {
        let error = false;
        if (validateRequired(partnerProfileList?.teacherNo) ||
            validateRequired(partnerProfileList?.personnel) ||
            validateRequired(partnerProfileList?.studentNo)) {
            error = true;
        }
        if (partnerProfileList?.faculty && partnerProfileList?.faculty.length > 0) {
            partnerProfileList?.faculty.forEach(faculty => {
                faculty.branchInfo && faculty.branchInfo.forEach(x => {
                    error = validateRequired(x.branchName) || validateRequired(x.teacherNo) || validateRequired(x.teacherNo) || validateRequired(x.personnel) || validateRequired(x.studentNo)

                })
            })
        }
        return error
    }
    const invalidPartnerInfo = () => {
        let error = false;
        if (!partnerProfileList?.partnerList || partnerProfileList?.partnerList.length <= 0) {
            error = true
        }
        return error;
    }
    const invalidMou = () => {
        let error =false;
        if(partnerProfileList?.haveMOU){
            if(!partnerProfileList?.mouList || partnerProfileList?.mouList.length <= 0){
                error = true
            }
            else{
                error = partnerProfileList?.mouList.some(x=>{
                    return validateRequired(x.mouDepartment) || 
                    validateRequired(x.mouOrganization) ||
                    validateRequired(x.mouStartDate) ||
                    validateRequired(x.mouToDate) || 
                    invalidScopeList(x.scopeList)
                })
            }
        }
        return error
    }
    const invalidScopeList = (scopeList:ScopeResponse[]) => {
        let error =false;
        if(!scopeList || scopeList.length <= 0){
            error = true
        }else{
            error = scopeList.some(x=>(validateRequired(x.scpoeDescription)))
        }
        return error
    }
    const invalidSurveyProject = () => {
        let error = false;
        if(partnerProfileList?.surveyProject && partnerProfileList?.surveyProject.length>0){
            error = partnerProfileList?.surveyProject.some(x=>{
                return validateRequired(x.projectId) ||
                validateQuestion(x.question1) || 
                // validateQuestion(x.question2) ||
                // validateQuestion(x.question3) ||
                validateQuestion(x.question4) ||
                validateQuestionOther(x.question1,x.question1Other) ||
                // validateQuestionOther(x.question2,x.question2Other) ||
                // validateQuestionOther(x.question3,x.question3Other) ||
                validateQuestionOther(x.question4,x.question4Other) 
            })
        }
        return error;
    }
    const validateQuestion = (question:QuestionResponse[]) => {
        let error = false;
        error = !question.some(x=>x.checked);
        return error;
    }
    const validateQuestionOther = (question:QuestionResponse[],questionOther:string) => {
        let error = false;
        question.forEach(x=>{
            if(x.checked && x.description=='อื่นๆ โปรดระบุ'){
                error = validateRequired(questionOther)
            }
        })
        return error;
    }
    const invalidWelfareBenefit = () => {
        let error = false;
        if (validateRequireBenefit(partnerProfileList?.checkProvidentFund as any, partnerProfileList?.providentFundAmount)) {
            error = true;
        }
        if (validateRequireBenefit(partnerProfileList?.checkSocialSecurity as any, partnerProfileList?.socialSecurityAmount)) {
            error = true;
        }
        if (validateRequireBenefit(partnerProfileList?.checkGovPensionFund as any, partnerProfileList?.govPensionFundAmount)) {
            error = true;
        }
        if (validateRequireBenefit(partnerProfileList?.checkSavingsCooperative as any, partnerProfileList?.savingsCooperativeAmount)) {
            error = true;
        }
        if (partnerProfileList?.welfareBenefitList && partnerProfileList?.welfareBenefitList.length > 0) {
            error = partnerProfileList?.welfareBenefitList.some(x => (x.welfareBenefitName == null || x.welfareBenefitName == '' || validateRequired(x.memberAmount)))
        }
        return error
    }
    const handleSubmit = () => {
        let messageError = [];
        setSubmitted(true);
        if (invalidGeneralInfo()) {
            messageError.push('กรุณากรอกข้อมูลทั่วไปให้ครบถ้วน')
        }
        if (invalidOrganization()) {
            messageError.push('กรุณากรอกข้อมูลองค์กรให้ครบถ้วน')
        }
        if (invalidWelfareBenefit()) {
            messageError.push('กรุณากรอกข้อมูลสวัสดิการการออมให้ครบถ้วน')
        }
        if (invalidPartnerInfo()) {
            messageError.push('กรุณากรอกผู้ประสานงานอย่างน้อย 1 คน')
        }
        if (invalidMou()){
            messageError.push('กรุณาเพิ่ม MOU ให้ครบถ้วน');
        }
        if (invalidSurveyProject()){
            messageError.push('กรุณาตอบแบบสอบถามโครงการให้ครบ')
        }

        if (messageError.length > 0) {
            let content = <span className='text-left'>
                <b>กรุณากรอกแบบฟอร์มให้ครบ&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</b>
                {messageError.map(x => (<div>
                    - {x}
                </div>))}
            </span>
            messageApi.error({
                type: 'error',
                content: content,
                className: 'custom-class'
            });
        } else {
            const body = { ...partnerProfileList }
            if (id != "0") {
                dispatch(updatePartnerProfileByPartnerId(parseInt(id), body as any))
            } else {
                dispatch(createPartnerProfile(body as any))
            }


        }
    }
    return (
        <div>
            {contextHolder}
            <MainMenu>
                <div>
                    <div className="query-wrapper">
                        <br />
                        <div className="yellow-button" style={{ textAlign: "left", borderTopLeftRadius: "30px", borderTopRightRadius: "30px", height: "70px", paddingTop: "15px", paddingLeft: "15px" }}><h4 className="header">ข้อมูลองค์กร</h4></div>
                        {(init === 1) &&
                            <>
                                <br />
                                {PartnerTab(1, true, handleProfile, handleActivityOrganizeTraining, handlePackage)}
                                <br />
                                <ManagePartnerSuggestion />
                                <br />
                                <div style={{ background: "white" }} className='mt-2'>
                                    <PartnerGeneralInfoPartner parnerProfileList={partnerProfileList as any}
                                        success={success}
                                        orgTypeItem={orgTypeItem}
                                        submitted={submitted}
                                        province={province}
                                        readonly={false}
                                        adminItem={adminItem}
                                        setParnerProfileListChange={setPartnerProfileList}
                                        isDisableOrganizeGroup={isDisableOrgGroup}
                                    />
                                </div>
                                {(getOrgTypeName(partnerProfileList?.orgType) == 'เอกชน' || getOrgTypeName(partnerProfileList?.orgType) == 'รัฐวิสาหกิจ') && (
                                    <PartnerOrgPrivateCompany parnerProfileList={partnerProfileList as any}
                                        submitted={submitted}
                                        readonly={false}
                                        setParnerProfileListChange={setPartnerProfileList}
                                        success={success} />
                                )}
                                {(getOrgTypeName(partnerProfileList?.orgType) == 'รัฐ') && (
                                    <PartnerOrgGoverment parnerProfileList={partnerProfileList as any}
                                        submitted={submitted}
                                        readonly={false}
                                        setParnerProfileListChange={setPartnerProfileList}
                                        success={success} />
                                )}
                                {(getOrgTypeName(partnerProfileList?.orgType) == 'องค์กรอิสระ') && (
                                    <PartnerOrgIndepandent parnerProfileList={partnerProfileList as any}
                                        submitted={submitted}
                                        readonly={false}
                                        setParnerProfileListChange={setPartnerProfileList}
                                        success={success} />
                                )}
                                {(getOrgTypeName(partnerProfileList?.orgType) == 'สถาบันการศึกษา') && (<>
                                    {(getOrgGroupName(partnerProfileList?.orgGroup) == 'มหาวิทยาลัย') && (
                                        <PartnerUniversity parnerProfileList={partnerProfileList as any}
                                            submitted={submitted}
                                            facultyItem={facultyItem}
                                            setParnerProfileListChange={setPartnerProfileList}
                                            readonly={false}
                                            success={success} />
                                    )}
                                    {(getOrgGroupName(partnerProfileList?.orgGroup) == 'วิทยาลัย') && (
                                        <PartnerUniversity parnerProfileList={partnerProfileList as any}
                                            submitted={submitted}
                                            facultyItem={facultyItem}
                                            setParnerProfileListChange={setPartnerProfileList}
                                            readonly={false}
                                            success={success} />
                                    )}
                                    {(getOrgGroupName(partnerProfileList?.orgGroup) == 'อาชีวศึกษา') && (
                                        <PartnerVocation parnerProfileList={partnerProfileList as any}
                                            submitted={submitted}
                                            setParnerProfileListChange={setPartnerProfileList}
                                            readonly={false}
                                            success={success} />
                                    )}
                                    {(getOrgGroupName(partnerProfileList?.orgGroup) == 'โรงเรียน') && (
                                        <PartnerSchool parnerProfileList={partnerProfileList as any}
                                            submitted={submitted}
                                            setParnerProfileListChange={setPartnerProfileList}
                                            readonly={false}
                                            success={success} />
                                    )}
                                    {(getOrgGroupName(partnerProfileList?.orgGroup) == 'อื่น ๆ') && (
                                        <PartnerSchool parnerProfileList={partnerProfileList as any}
                                            submitted={submitted}
                                            setParnerProfileListChange={setPartnerProfileList}
                                            readonly={false}
                                            success={success} />
                                    )}

                                </>)}
                                <div style={{ background: "white" }} className='mt-2'>
                                    <PartnerWelfareBenefit parnerProfileList={partnerProfileList as any}
                                        success={success}
                                        readonly={false}
                                        submitted={submitted}
                                        setParnerProfileListChange={setPartnerProfileList}
                                    />
                                </div>
                                <div style={{ background: "white" }} className='mt-2'>
                                    <PartnerInfo parnerProfileList={partnerProfileList as any}
                                        success={success}
                                        submitted={submitted}
                                        readonly={false}
                                        handleResendEmail={handleResendEmail}
                                        setParnerProfileListChange={setPartnerProfileList} />
                                </div>
                                <hr />
                                <div className='text-left'><h5>ข้อมูลสำหรับให้ Admin กรอกเพิ่มเติม</h5></div>
                                <div style={{ background: "white" }} className='mt-2'>
                                    <PartnerExtraFund parnerProfileList={partnerProfileList as any}
                                        success={success}
                                        submitted={submitted}
                                        readonly={false}
                                        setParnerProfileListChange={setPartnerProfileList}
                                    />
                                </div>
                                <div style={{ background: "white" }} className='mt-2'>
                                    <PartnerExtraMou parnerProfileList={partnerProfileList as any}
                                        success={success}
                                        submitted={submitted}
                                        readonly={false}
                                        departmentItem={departmentItem}
                                        setParnerProfileListChange={setPartnerProfileList}/>
                                </div>
                                <div style={{ background: "white" }} className='mt-2' >
                                    <PartnerExtraSurveyProject parnerProfileList={partnerProfileList as any}
                                        success={success}
                                        submitted={submitted}
                                        readonly={false}
                                        surveyProjectForAdd={surveyProjectForAdd}
                                        projectItem={projectItem}
                                        setParnerProfileListChange={setPartnerProfileList}/>
                                </div>
                                {(!success) &&
                                    <div className='d-flex mt-3'>
                                        <div className='ml-auto mb-3'>
                                            <button type="button" className="btn btn-secondary" style={{ width: "120px" }}
                                                onClick={() => handleBack()} >
                                                Back
                                            </button>&nbsp;
                                            <button type="button" className="btn btn-warning" style={{ width: "120px" }}
                                                onClick={() => handleClear()} >
                                                Clear
                                            </button>&nbsp;
                                            <button type="button" className="btn btn-success" style={{ width: "120px" }}
                                                onClick={() => handleSubmit()} >
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                }
                            </>

                        }
                        {(((init < 1) || (loading)) && (error === "")) &&
                            <div style={{ textAlign: "center" }}><img src={loadinglogo} width="30" height="30" className="d-inline-block align-top" alt="" /></div>
                        }
                        {(error !== "") &&
                            <div style={{ color: 'red', textAlign: "center" }}>{error}</div>
                        }
                    </div>
                </div>
                <AlertContainer floatingTime={3000} />
            </MainMenu>
        </div>
    )
}
export default PartnerProfile