import { PartnerKnowledgeSummaryStructure } from "../model/request/partner-knowledge";
import { PartnerKnowledgeRegistration } from "../model/request/partner-knowledge-regis";
import { PartnerCategory } from "../model/response/partner-category";
import { PartnerProfileListResponse } from "../model/response/partner-profile";
import { apiPublicClient } from "./axios-intercept";
import { DEFAULT_SERVER } from "./conf";
const GET_KNOWLEDGE_CATEGORY = DEFAULT_SERVER + "/api/v1/public/partner/knowledge/category"
const GET_KNOWLEDGE_SUMMARY = DEFAULT_SERVER + "/api/v1/public/partner/knowledge/summary"
const REGISTER_KNOWLEDGE = DEFAULT_SERVER + "/api/v1/public/partner/knowledge/registration"
const VERIFY_EMAIL = DEFAULT_SERVER + "/api/v1/public/partner/knowledge/registration/verify-email"
const REQUEST_OTP = DEFAULT_SERVER + "/api/v1/public/partner/otp/{userKey}"
const REQUEST_OTP_FILL_MASTER_DATA = DEFAULT_SERVER + "/api/v1/public/partner/log-request";
const REQUEST_DETAIL_FILL_MASTER_DATA = DEFAULT_SERVER + "/api/v1/public/partner/log-request"
const GET_KNOWLEDGE_SUMMARY_STRUCTURE = DEFAULT_SERVER + "/api/v1/public/partner/knowledge/summary-structure"
const GET_ADRESS_INFOMATION = DEFAULT_SERVER + "/api/v1/public/address-information"
const GET_FACULTY = DEFAULT_SERVER + "/api/v1/public/faculty"
const PARTNER_VERIFY_EMAIL = DEFAULT_SERVER + "/api/v1/public/partner/verify-email";
const LIST_ORGANIZATION_URL = DEFAULT_SERVER + "/api/v1/public/organization";
const PARTNER_PROFILE = DEFAULT_SERVER + "/api/v1/public/partner/log-request"
const GET_ACTIVE_KNOWLEDGE_CATEGORY = DEFAULT_SERVER + "/api/v1/public/partner/active-category";
const GET_ACTIVE_KNOWLEDGE_SUB_CATEGORY = DEFAULT_SERVER + "/api/v1/public/partner/active-sub-category";
export class PublicPartnerAPI {
    public static listKnowledgeCategory = async (): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=GET_KNOWLEDGE_CATEGORY;
            apiPublicClient.get(s,
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                console.log("listKnowledgeCategory response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("listKnowledgeCategory error:", err);
                return reject(err); 
            })
        })
    }

    public static listKnowledgeSummary = async (categoryId:number,request:PartnerCategory[]): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=GET_KNOWLEDGE_SUMMARY;
            var queryParams:string[] = [];
            if(categoryId) {
                queryParams.push(`categoryId=${categoryId}`)
            }
            apiPublicClient.post(s+'?'+queryParams.join('&'),request,
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                console.log("listKnowledgeSummary response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("listKnowledgeSummary error:", err);
                return reject(err); 
            })
        })
    }

    public static listKnowledgeSummaryByPublicCode = async (publicAccessCode:string,categoryId:number|undefined): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=GET_KNOWLEDGE_SUMMARY+"/"+publicAccessCode;
            var queryParams:string[] = [];
            if(categoryId) {
                queryParams.push(`categoryId=${categoryId}`)
            }
            apiPublicClient.post(s+'?'+queryParams.join('&'),
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                console.log("listKnowledgeSummary response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("listKnowledgeSummary error:", err);
                return reject(err); 
            })
        })
    }

    public static registerKnowledge = async (body:PartnerKnowledgeRegistration): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=REGISTER_KNOWLEDGE;
            apiPublicClient.post(s,body,
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                console.log("registerKnowledge response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("registerKnowledge error:", err);
                return reject(err); 
            })
        })
    }

    public static verifyEmailKnowledge = async (body:PartnerKnowledgeRegistration): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=VERIFY_EMAIL;
            apiPublicClient.post(s,body,
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                console.log("verifyEmail response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("verifyEmail error:", err);
                return reject(err); 
            })
        })
    }

    public static requestOTP = async (userKey:string, body:PartnerKnowledgeSummaryStructure): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=REQUEST_OTP.replace("{userKey}", userKey);
            apiPublicClient.post(s, body,
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                console.log("requestOTP response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("requestOTP error:", err);
                return reject(err); 
            })
        })
    }

    public static requestOTPForPartnerFillMasterData = async (userKey:string,code:string,key:string, email:string): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=REQUEST_OTP_FILL_MASTER_DATA+"/"+userKey+"/"+code+"/"+key;
            var queryParams:string[] = [];
            if(email){
                queryParams.push(`email=`+encodeURIComponent(`${email}`))
            }
            apiPublicClient.post(s+'?'+queryParams.join('&'),
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                console.log("requestOTPForPartnerFillMasterData response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("requestOTPForPartnerFillMasterData error:", err);
                return reject(err); 
            })
        })
    }

    public static getSummaryStructure = async (body:PartnerKnowledgeSummaryStructure): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=GET_KNOWLEDGE_SUMMARY_STRUCTURE;
            apiPublicClient.post(s,body,
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                console.log("getSummaryStructure response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("getSummaryStructure error:", err);
                return reject(err); 
            })
        })
    }

    public static getLogRequestDetailByOtp = async (userKey:string,code:string,key:string,refCode: string | undefined,otp:string | undefined): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=REQUEST_DETAIL_FILL_MASTER_DATA+"/"+userKey+"/"+code+"/"+key;
            var queryParams:string[] = [];
            if(refCode) {
                queryParams.push(`refCode=${refCode}`)
            }
            if(otp) {
                queryParams.push(`otp=${otp}`)
            }
            apiPublicClient.get(s+'?'+queryParams.join('&'),
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                console.log("getSummaryStructure response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("getSummaryStructure error:", err);
                return reject(err); 
            })
        })
    }

    public static getAdressInfomation = async (): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=GET_ADRESS_INFOMATION;
            apiPublicClient.get(s,
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                console.log("getSummaryStructure response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("getSummaryStructure error:", err);
                return reject(err); 
            })
        })
    }

    public static getOrganization = async (page: number, limit: number, partnerProfile?: boolean): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = LIST_ORGANIZATION_URL + "?page=" + page + "&limit=" + limit;
            if (partnerProfile) {
                s = s + "&partnerProfile=true";
            }
            apiPublicClient.get(s,
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    console.log("list organization response : ", response);
                    return resolve(response);
                }).catch(err => {
                    console.log("list organization error:", err);
                    return reject(err);
                })
        })
    }

    public static updatePartnerProfilePublic = async (body: PartnerProfileListResponse,userKey: string,code: string,key: string): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s = PARTNER_PROFILE + "/" + userKey+"/"+ code+"/"+ key;
            var queryParams:string[] = [];
            apiPublicClient.put(s+'?'+queryParams.join('&'), body,
                {
                    headers: {
                        "Accept": "application/json"
                    }
                })
                .then((response) => {
                    console.log("updatePartnerProfile response : ", response);
                    return resolve(response);
                }).catch(err => {
                    console.log("updatePartnerProfile error:", err);
                    return reject(err);
                })
        })
    }

    public static getFaculty = async (): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=GET_FACULTY;
            apiPublicClient.get(s,
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                console.log("getFaculty response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("getFaculty error:", err);
                return reject(err); 
            })
        })
    }

    public static partnerVerifyEmail = async (userKey: string,refCode: string, otp: string): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=PARTNER_VERIFY_EMAIL+"/"+userKey+"/"+refCode+"/"+otp;
            apiPublicClient.post(s,
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                console.log("partnerVerifyEmail response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("partnerVerifyEmail error:", err);
                return reject(err); 
            })
        })
    }

    public static partnerVerifyEmailPartnerProfile = async (userKey: string,refCode: string, otp: string): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=PARTNER_VERIFY_EMAIL+"/partner-profile/"+userKey+"/"+refCode+"/"+otp;
            apiPublicClient.post(s,
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                console.log("partnerVerifyEmailPartnerProfile response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("partnerVerifyEmailPartnerProfile error:", err);
                return reject(err); 
            })
        })
    }

    public static partnerVerifyLinkEditPartnerProfile = async (userKey: string,refCode: string, otp: string): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=PARTNER_PROFILE+"/"+userKey+"/"+refCode+"/"+otp+"/validate-link";
            apiPublicClient.post(s,
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                console.log("partnerVerifyLinkEditPartnerProfile response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("partnerVerifyLinkEditPartnerProfile error:", err);
                return reject(err); 
            })
        })
    }

    public static listActiveKnowledgeCategory = async (): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=GET_ACTIVE_KNOWLEDGE_CATEGORY;
            apiPublicClient.get(s,
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                console.log("listActiveKnowledgeCategory response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("listActiveKnowledgeCategory error:", err);
                return reject(err); 
            })
        })
    }

    public static listActiveKnowledgeSubCategory = async (categoryId: number): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=`${GET_ACTIVE_KNOWLEDGE_SUB_CATEGORY}?category_id=${categoryId}`;
            apiPublicClient.get(s,
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                console.log("listActiveKnowledgeSubCategory response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("listActiveKnowledgeSubCategory error:", err);
                return reject(err); 
            })
        })
    }
}

