import { BrowserRouter } from 'react-router-dom';
import './App.css';
import './style/Common.scss';
import './style/Color.scss'
import ScreenNavigators from './components/navigator'

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScreenNavigators />
      </BrowserRouter>
    </div>
  );
}

export default App;


