import React, { useEffect, useState } from 'react'
import { FacultyInfo, PartnerProfileListResponse } from '../../../model/response/partner-profile';
import { message } from 'antd';
import { useDispatch, useStore } from 'react-redux';
import MainMenu from '../menu';
import MergePartnerToDatabaseSuggestion from './MergePartnerToDatabaseSuggestion';
import PartnerPartSetting from '../managePartner/component/PartnerPartSetting';
import PartnerGeneralInfo from '../managePartner/component/PartnerGeneralInfo';
import { AdressResponse } from '../../../model/response/adress.model';
import { useHistory, useParams } from 'react-router-dom';
import { listorganization } from '../../../store/editpartner/actions';
import { getAdressInfomation, getFaculty } from '../../../store/publicPartner/action';
import { getMergeLogRequestPartnerProfile, getPartnerFaculty, getPartnerProfile, mergeLogRequestToPartner } from '../../../store/partner/actions';
import { AlertContainer, alert } from 'react-custom-alert';
import PartnerOrgPrivateCompany from '../managePartner/component/PartnerOrgPrivateCompany';
import PartnerOrgGoverment from '../managePartner/component/PartnerOrgGoverment';
import PartnerOrgIndepandent from '../managePartner/component/PartnerOrgIndepandent';
import PartnerUniversity from '../managePartner/component/PartnerUniversity';
import PartnerVocation from '../managePartner/component/PartnerVocation';
import PartnerSchool from '../managePartner/component/PartnerSchool';
import PartnerWelfareBenefit from '../managePartner/component/PartnerWelfareBenefit';
import PartnerInfo from '../managePartner/component/PartnerInfo';
import loadinglogo from '../../../img/loading.gif';
import { queryclear } from '../../../store/search/actions';
type Props = {}

const MergePartnerToDatabase = (props: Props) => {
    const [init, setInit] = useState(0);
    const [initOld, setInitOld] = useState(0);
    const [partnerProfileListNew, setPartnerProfileListNew] = useState<PartnerProfileListResponse>();
    const [partnerProfileListOld, setPartnerProfileListOld] = useState<PartnerProfileListResponse>();
    const [messageApi, contextHolder] = message.useMessage();
    const dispatch = useDispatch();
    const [success, setSuccess] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [submittedMerge, setSubmittedMerge] = useState(false);
    const [orgTypeItem, setOrgTypeItem] = useState<any[]>([])
    const [province, setProvince] = useState<AdressResponse[]>([]);
    const [facultyItem, setFacultyItem] = useState<any[]>([])
    const [facultyOldItem, setFacultyOldItem] = useState<FacultyInfo[]>([])
    let { logRequestId } = useParams<{ logRequestId: string }>();
    let { orgTypeId } = useParams<{ orgTypeId: string }>();
    let { orgGroupId } = useParams<{ orgGroupId: string }>();
    let { partnerName } = useParams<{ partnerName: string }>();
    let { partnerId } = useParams<{ partnerId: string }>();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const store = useStore();
    const history = useHistory();
    useEffect(() => {
        const unsubscribe = store.subscribe(() => {
            let res = store.getState().SearchReducer;
            if (res.isLoading) {
                setError('');
                setLoading(true);
            } else if ((res.isLoaded) && (!res.isUpdated)) {
                setError('');
                setLoading(false);
                if (res.data != null) {
                    if (res.table === "org_type") {
                        setOrgTypeItem(res.data);
                    } else if (res.table === "address-infomation") {
                        setProvince(res.data);
                    } else if (res.table === "faculty") {
                        setFacultyItem(res.data);
                    } else if (res.table === "get_partner_profile") {
                        setPartnerProfileListNew(res.data);
                        setInit(1);
                    } else if (res.table === "get_merge_partner_profile") {
                        setPartnerProfileListOld(res.data);
                        setInitOld(1);
                    } else if (res.table === "get_partner_faculty") {
                        setFacultyOldItem(res.data);
                    }
                }
            } else if (res.isUpdating) {
                setError('');
                setLoading(true);
            } else if (res.isUpdated) {
                setError('');
                setLoading(false);
                dispatch(queryclear());
                if (res.table == 'merge_log_to_partner') {
                    alert({ message: 'บันทึกข้อมูลสำเร็จ', type: 'success' });
                    setTimeout(() => { history.push('/admin/log-request') }, 300)
                }


            } else if (res.isFailed) {
                setError(res.data.message);
                setLoading(false);
                alert({ message: res.data.message, type: 'error' });
            }

        })
        dispatch(listorganization(0, 0, true));
        dispatch(getAdressInfomation())
        dispatch(getFaculty())
        dispatch(getMergeLogRequestPartnerProfile(parseInt(partnerId)))
        dispatch(getPartnerFaculty(parseInt(partnerId)))
        dispatch(getPartnerProfile(parseInt(logRequestId)));
        return unsubscribe;
    }, []);
    const getOrgTypeName = (id: any) => {
        if (orgTypeItem) {
            let orgTypeName = orgTypeItem.find(x => x.id == id)?.name;
            return orgTypeName ? orgTypeName : null
        }
        return null

    }
    const getOrgGroupName = (id: any) => {
        let orgGroup = null
        if (orgTypeItem) {

            orgTypeItem.forEach(x => {
                let orgGroupName = x.orgGroup.find((y: any) => y.id == id);
                if (orgGroupName) {
                    orgGroup = orgGroupName.name
                }
            })

        }
        return orgGroup
    }
    const validateRequired = (value: string | boolean | undefined | any[] | null | number) => {
        return (value == null || value == undefined || value == '')
    }
    const invalidGeneralInfo = () => {
        let error = false;
        if (validateRequired(partnerProfileListNew?.mergeStateGeneralInfo)) {
            error = true;
        }
        return error
    }
    const invalidUniversity = () => {
        let error = false;
        if (validateRequired(partnerProfileListNew?.mergeStateOrganizationInfo)) {
            error = true;
        }
        if (partnerProfileListNew?.faculty && partnerProfileListNew?.faculty.length > 0) {
            partnerProfileListNew?.faculty.forEach(faculty => {
                if (faculty.mergeState == 'SAVE') {
                    error = validateRequired(faculty.mergeState) || validateRequired(faculty.selectedOldPartnerFacultyId)
                } else {
                    error = validateRequired(faculty.mergeState);
                }

            })
        }
        return error
    }
    const invalidOrganization = () => {
        let error = false
        if ((getOrgTypeName(partnerProfileListNew?.orgType) == 'เอกชน'
            || getOrgTypeName(partnerProfileListNew?.orgType) == 'รัฐ'
            || getOrgTypeName(partnerProfileListNew?.orgType) == 'องค์กรอิสระ'
            || getOrgTypeName(partnerProfileListNew?.orgType) == 'รัฐวิสาหกิจ')) {
            if (validateRequired(partnerProfileListNew?.mergeStateOrganizationInfo)) {
                error = true;
            }
        } else if ((getOrgTypeName(partnerProfileListNew?.orgType) == 'สถาบันการศึกษา')) {
            if (getOrgGroupName(partnerProfileListNew?.orgGroup) == 'มหาวิทยาลัย') {
                error = invalidUniversity()
            } else if (getOrgGroupName(partnerProfileListNew?.orgGroup) == 'วิทยาลัย') {
                error = invalidUniversity()
            } else {
                error = validateRequired(partnerProfileListNew?.mergeStateOrganizationInfo)
            }
        }
        return error;
    }
    const invalidWelfareBenefit = () => {
        let error = false;
        if (validateRequired(partnerProfileListNew?.mergeStateWelfareBenefit)) {
            error = true;
        }
        return error
    }
    const invalidParnerList = () => {
        let error = false;
        if (partnerProfileListNew?.partnerList && partnerProfileListNew?.partnerList.length > 0) {
            partnerProfileListNew?.partnerList.forEach(partner => {
                if (partner.mergeState == 'SAVE') {
                    error = validateRequired(partner.mergeState) || validateRequired(partner.selectedOldCoordinatorId)
                } else {
                    error = validateRequired(partner.mergeState);
                }
            })
        }
        return error
    }

    const handleBack = () => {
        history.push('/admin/log-request/merge-partner/' + logRequestId + '/' + orgTypeId + '/' + orgGroupId + '/' + partnerName);
    }
    const handleSubmit = () => {
        let messageError = [];
        setSubmittedMerge(true);
        if (invalidGeneralInfo()) {
            messageError.push('กรุณาเลือกบันทึข้อมูลทั่วไปให้ครบถ้วน')
        }
        if (invalidOrganization()) {
            messageError.push('กรุณาเลือกบันทึกข้อมูลองค์กรให้ครบถ้วน')
        }
        if (invalidWelfareBenefit()) {
            messageError.push('กรุณาเลือกบันทึกข้อมูลสวัสดิการการออมให้ครบถ้วน')
        }
        if (invalidParnerList()) {
            messageError.push('กรุณาเลือกบันทึกข้อมูลสวัสดิการการออมให้ครบถ้วน')
        }
        if (messageError.length > 0) {
            let content = <span className='text-left'>
                <b>กรุณากรอกแบบฟอร์มให้ครบ&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</b>
                {messageError.map(x => (<div>
                    - {x}
                </div>))}
            </span>
            messageApi.error({
                type: 'error',
                content: content,
                className: 'custom-class'
            });
        } else {
            const body = { ...partnerProfileListNew }
            dispatch(mergeLogRequestToPartner(parseInt(logRequestId), parseInt(partnerId), body as any))
        }
    }

    return (
        <div>
            {contextHolder}
            <MainMenu>
                <div>
                    {init + initOld == 2 && (
                        <div className="query-wrapper">
                            <br />
                            <div className="yellow-button" style={{ textAlign: "left", borderTopLeftRadius: "30px", borderTopRightRadius: "30px", height: "70px", paddingTop: "15px", paddingLeft: "15px" }}><h4 className="header">บริหารจัดการข้อมูลองค์กรพันธมิตร</h4></div>
                            <br />
                            <MergePartnerToDatabaseSuggestion />
                            <br />
                            <div style={{ background: "white" }}>
                                <PartnerPartSetting parnerProfileList={partnerProfileListNew as any}
                                    success={success}
                                    setParnerProfileListChange={setPartnerProfileListNew}
                                />
                            </div>
                            <div style={{ background: "white" }} className='mt-2'>
                                <PartnerGeneralInfo parnerProfileList={partnerProfileListOld as any}
                                    success={success}
                                    orgTypeItem={orgTypeItem}
                                    submitted={submitted}
                                    province={province}
                                    readonly={true}
                                    setParnerProfileListChange={setPartnerProfileListOld}
                                    state='old'
                                    isDisableOrganizeGroup={false}
                                />
                            </div>
                            <div style={{ background: "#fff7e9" }} className='mt-2'>
                                <PartnerGeneralInfo parnerProfileList={partnerProfileListNew as any}
                                    success={success}
                                    orgTypeItem={orgTypeItem}
                                    submitted={submitted}
                                    province={province}
                                    readonly={true}
                                    setParnerProfileListChange={setPartnerProfileListNew}
                                    state='new'
                                    submittedMerge={submittedMerge}
                                    isDisableOrganizeGroup={false}
                                />
                            </div>
                            {/* old organization */}
                            {(getOrgTypeName(partnerProfileListOld?.orgType) == null) && (
                                <PartnerOrgPrivateCompany parnerProfileList={partnerProfileListOld as any}
                                    submitted={submitted}
                                    setParnerProfileListChange={setPartnerProfileListOld}
                                    readonly={true}
                                    state='old'
                                    success={success} />
                            )}
                            {(getOrgTypeName(partnerProfileListOld?.orgType) == 'เอกชน' || getOrgTypeName(partnerProfileListOld?.orgType) == 'รัฐวิสาหกิจ') && (
                                <PartnerOrgPrivateCompany parnerProfileList={partnerProfileListOld as any}
                                    submitted={submitted}
                                    setParnerProfileListChange={setPartnerProfileListOld}
                                    readonly={true}
                                    state='old'
                                    success={success} />
                            )}
                            {(getOrgTypeName(partnerProfileListOld?.orgType) == 'รัฐ') && (
                                <PartnerOrgGoverment parnerProfileList={partnerProfileListOld as any}
                                    submitted={submitted}
                                    readonly={true}
                                    state='old'
                                    setParnerProfileListChange={setPartnerProfileListOld}
                                    success={success} />
                            )}
                            {(getOrgTypeName(partnerProfileListOld?.orgType) == 'องค์กรอิสระ') && (
                                <PartnerOrgIndepandent parnerProfileList={partnerProfileListOld as any}
                                    submitted={submitted}
                                    readonly={true}
                                    state='old'
                                    setParnerProfileListChange={setPartnerProfileListOld}
                                    success={success} />
                            )}
                            {(getOrgTypeName(partnerProfileListOld?.orgType) == 'สถาบันการศึกษา') && (<>
                                {(getOrgGroupName(partnerProfileListOld?.orgGroup) == 'มหาวิทยาลัย') && (
                                    <PartnerUniversity parnerProfileList={partnerProfileListOld as any}
                                        submitted={submitted}
                                        readonly={true}
                                        state='old'
                                        facultyItem={facultyItem}

                                        setParnerProfileListChange={setPartnerProfileListOld}
                                        success={success} />
                                )}
                                {(getOrgGroupName(partnerProfileListOld?.orgGroup) == 'วิทยาลัย') && (
                                    <PartnerUniversity parnerProfileList={partnerProfileListOld as any}
                                        submitted={submitted}
                                        readonly={true}
                                        state='old'
                                        facultyItem={facultyItem}

                                        setParnerProfileListChange={setPartnerProfileListOld}
                                        success={success} />
                                )}
                                {(getOrgGroupName(partnerProfileListOld?.orgGroup) == 'อาชีวศึกษา') && (
                                    <PartnerVocation parnerProfileList={partnerProfileListOld as any}
                                        readonly={true}
                                        state='old'
                                        submitted={submitted}
                                        setParnerProfileListChange={setPartnerProfileListOld}
                                        success={success} />
                                )}
                                {(getOrgGroupName(partnerProfileListOld?.orgGroup) == 'โรงเรียน') && (
                                    <PartnerSchool parnerProfileList={partnerProfileListOld as any}
                                        readonly={true}
                                        state='old'
                                        submitted={submitted}
                                        setParnerProfileListChange={setPartnerProfileListOld}
                                        success={success} />
                                )}
                                {(getOrgGroupName(partnerProfileListOld?.orgGroup) == 'อื่น ๆ') && (
                                    <PartnerSchool parnerProfileList={partnerProfileListOld as any}
                                        readonly={true}
                                        state='old'
                                        submitted={submitted}
                                        setParnerProfileListChange={setPartnerProfileListOld}
                                        success={success} />
                                )}

                            </>)}
                            {/* New Organization */}
                            {(getOrgTypeName(partnerProfileListNew?.orgType) == 'เอกชน' || getOrgTypeName(partnerProfileListNew?.orgType) == 'รัฐวิสาหกิจ') && (
                                <div style={{ background: "#fff7e9" }} >
                                    <PartnerOrgPrivateCompany parnerProfileList={partnerProfileListNew as any}
                                        submitted={submitted}
                                        setParnerProfileListChange={setPartnerProfileListNew}
                                        readonly={true}
                                        state='new'
                                        submittedMerge={submittedMerge}
                                        success={success} />
                                </div>

                            )}
                            {(getOrgTypeName(partnerProfileListNew?.orgType) == 'รัฐ') && (
                                <div style={{ background: "#fff7e9" }} >
                                    <PartnerOrgGoverment parnerProfileList={partnerProfileListNew as any}
                                        submitted={submitted}
                                        readonly={true}
                                        state='new'
                                        submittedMerge={submittedMerge}
                                        setParnerProfileListChange={setPartnerProfileListNew}
                                        success={success} />
                                </div>

                            )}
                            {(getOrgTypeName(partnerProfileListNew?.orgType) == 'องค์กรอิสระ') && (
                                <div style={{ background: "#fff7e9" }} >
                                    <PartnerOrgIndepandent parnerProfileList={partnerProfileListNew as any}
                                        submitted={submitted}
                                        readonly={true}
                                        state='new'
                                        submittedMerge={submittedMerge}
                                        setParnerProfileListChange={setPartnerProfileListNew}
                                        success={success} />
                                </div>

                            )}
                            {(getOrgTypeName(partnerProfileListNew?.orgType) == 'สถาบันการศึกษา') && (<>
                                {(getOrgGroupName(partnerProfileListNew?.orgGroup) == 'มหาวิทยาลัย') && (
                                    <div style={{ background: "#fff7e9" }} >
                                        <PartnerUniversity parnerProfileList={partnerProfileListNew as any}
                                            submitted={submitted}
                                            readonly={true}
                                            state='new'
                                            facultyItem={facultyItem}
                                            submittedMerge={submittedMerge}
                                            facultyOldItem={facultyOldItem}
                                            setParnerProfileListChange={setPartnerProfileListNew}
                                            success={success} />
                                    </div>

                                )}
                                {(getOrgGroupName(partnerProfileListNew?.orgGroup) == 'วิทยาลัย') && (
                                    <div style={{ background: "#fff7e9" }} >
                                        <PartnerUniversity parnerProfileList={partnerProfileListNew as any}
                                            submitted={submitted}
                                            readonly={true}
                                            state='new'
                                            facultyItem={facultyItem}
                                            submittedMerge={submittedMerge}
                                            facultyOldItem={facultyOldItem}
                                            setParnerProfileListChange={setPartnerProfileListNew}
                                            success={success} />
                                    </div>
                                )}
                                {(getOrgGroupName(partnerProfileListNew?.orgGroup) == 'อาชีวศึกษา') && (
                                    <div style={{ background: "#fff7e9" }} >
                                        <PartnerVocation parnerProfileList={partnerProfileListNew as any}
                                            readonly={true}
                                            state='new'
                                            submitted={submitted}
                                            submittedMerge={submittedMerge}
                                            setParnerProfileListChange={setPartnerProfileListNew}
                                            success={success} />
                                    </div>

                                )}
                                {(getOrgGroupName(partnerProfileListNew?.orgGroup) == 'โรงเรียน') && (
                                    <div style={{ background: "#fff7e9" }} >
                                        <PartnerSchool parnerProfileList={partnerProfileListNew as any}
                                            readonly={true}
                                            state='new'
                                            submitted={submitted}
                                            submittedMerge={submittedMerge}
                                            setParnerProfileListChange={setPartnerProfileListNew}
                                            success={success} />
                                    </div>

                                )}
                                {(getOrgGroupName(partnerProfileListNew?.orgGroup) == 'อื่น ๆ') && (
                                    <div style={{ background: "#fff7e9" }} >
                                        <PartnerSchool parnerProfileList={partnerProfileListNew as any}
                                            readonly={true}
                                            state='new'
                                            submitted={submitted}
                                            submittedMerge={submittedMerge}
                                            setParnerProfileListChange={setPartnerProfileListNew}
                                            success={success} />
                                    </div>

                                )}

                            </>)}
                            <div style={{ background: "white" }} className='mt-2'>
                                <PartnerWelfareBenefit parnerProfileList={partnerProfileListOld as any}
                                    readonly={true}
                                    state='old'
                                    success={success}
                                    submitted={submitted}
                                    setParnerProfileListChange={setPartnerProfileListOld}
                                />
                            </div>
                            <div style={{ background: "#fff7e9" }} className='mt-2'>
                                <PartnerWelfareBenefit parnerProfileList={partnerProfileListNew as any}
                                    readonly={true}
                                    state='new'
                                    success={success}
                                    submitted={submitted}
                                    submittedMerge={submittedMerge}
                                    setParnerProfileListChange={setPartnerProfileListNew}
                                />
                            </div>
                            <div style={{ background: "white" }} className='mt-2'>
                                <PartnerInfo parnerProfileList={partnerProfileListOld as any}
                                    success={success}
                                    submitted={submitted}
                                    readonly={true}
                                    state='old'
                                    handleResendEmail={() => { }}
                                    setParnerProfileListChange={setPartnerProfileListOld} />
                            </div>
                            <div style={{ background: "#fff7e9" }} className='mt-2'>
                                <PartnerInfo parnerProfileList={partnerProfileListNew as any}
                                    success={success}
                                    submitted={submitted}
                                    readonly={true}
                                    state='new'
                                    oldPartnerItem={partnerProfileListOld?.partnerList}
                                    handleResendEmail={() => { }}
                                    submittedMerge={submittedMerge}
                                    setParnerProfileListChange={setPartnerProfileListNew} />
                            </div>
                            {(!success) &&
                                <div className='d-flex mt-3'>
                                    <div className='ml-auto mb-3'>
                                        <button type="button" className="btn btn-secondary" style={{ width: "120px" }}
                                            onClick={() => handleBack()} >
                                            Back
                                        </button>&nbsp;
                                        <button type="button" className="btn btn-success" style={{ width: "120px" }}
                                            onClick={() => handleSubmit()} >
                                            Save
                                        </button>
                                    </div>
                                </div>
                            }
                        </div>
                    )}
                    {(((init + initOld < 2) || (loading)) && (error === "")) &&
                        <div style={{ textAlign: "center" }}><img src={loadinglogo} width="30" height="30" className="d-inline-block align-top" alt="" /></div>
                    }
                </div>
            </MainMenu>
            <AlertContainer floatingTime={3000} />
        </div>
    )
}

export default MergePartnerToDatabase