import React, { useState } from 'react'
import { PartnerFundListResponse, PartnerProfileListResponse } from '../../../../model/response/partner-profile';
import ExpandMore from '@material-ui/icons/ExpandMore'
import ExpandLess from '@material-ui/icons/ExpandLess'
import { formatRelative } from 'date-fns';
import AddIcon from '@material-ui/icons/Add';
import iconTrashRed from '../../../../img/iconTrashRed.png'
import ConfirmModal from '../../../share/ConfirmModal';

type Props = {
    parnerProfileList: PartnerProfileListResponse;
    success: boolean;
    submitted: boolean;
    setParnerProfileListChange: (parnerProfileList: PartnerProfileListResponse) => void;
    readonly: boolean;
}

const PartnerExtraFund = (props: Props) => {
    const [expand, setExpand] = useState(true);
    const [itemDeleteExtraFund, setItemDeleteExtraFund] = useState<any>();
    const [confirmDeleteExtraFund, setConfirmDeleteExtraFund] = useState(false);
    const setCheckExtraProvidentFund = (e: any) => {
        var newItem = { ...props.parnerProfileList };
        newItem.checkExtraProvidentFund = e;
        if (e == false) {
            newItem.extraProvidentFundPolicyAmount = '' as any;
            newItem.extraProvidentFundType = null;
            newItem.extraProvidentFundPlanAmount = '' as any;
            newItem.extraProvidentFundPolicyList = [];
            newItem.extraProvidentFundPlanList = [];
        } else {
            if (!newItem.extraProvidentFundPolicyList || newItem.extraProvidentFundPolicyList.length == 0) {
                newItem.extraProvidentFundPolicyList = []
                let newItemFund: PartnerFundListResponse = {
                    id: null,
                    description: '',
                    extraFundType: 'PROVIDENT_FUND_POLICY'
                }
                newItem.extraProvidentFundPolicyList.push(newItemFund)
            }
            if (!newItem.extraProvidentFundPlanList || newItem.extraProvidentFundPlanList.length == 0) {
                newItem.extraProvidentFundPlanList = []
                const newExtraProvident: PartnerFundListResponse = {
                    id: null,
                    description: '',
                    extraFundType: 'PROVIDENT_FUND_PLAN'
                }
                newItem.extraProvidentFundPlanList.push(newExtraProvident);
            }
        }
        props.setParnerProfileListChange(newItem);
    }
    const setCheckExtraGovPension = (e: any) => {
        var newItem = { ...props.parnerProfileList };
        newItem.checkExtraGovPension = e;
        if (e == false) {
            newItem.extraGovPensionPolicyAmount = '' as any;
            newItem.extraGovPensionPlanAmount = '' as any;
            newItem.extraGovPensionPolicyList = [];
            newItem.extraGovPensionPlanList = [];
        } else {
            if (!newItem.extraGovPensionPolicyList || newItem.extraGovPensionPolicyList.length == 0) {
                newItem.extraGovPensionPolicyList = []
                let newItemFund: PartnerFundListResponse = {
                    id: null,
                    description: '',
                    extraFundType: 'GOV_PENSION_POLICY'
                }
                newItem.extraGovPensionPolicyList.push(newItemFund)
            }
            if (!newItem.extraGovPensionPlanList || newItem.extraGovPensionPlanList.length == 0) {
                newItem.extraGovPensionPlanList = []
                const newExtraProvident: PartnerFundListResponse = {
                    id: null,
                    description: '',
                    extraFundType: 'GOV_PENSION_PLAN'
                }
                newItem.extraGovPensionPlanList.push(newExtraProvident);
            }
        }
        props.setParnerProfileListChange(newItem);
    }
    const setCheckExtraOther = (e:any) => {
        var newItem = { ...props.parnerProfileList };
        newItem.checkExtraOther = e;
        if (e == false) {
            newItem.extraFundOtherList = [];
        }else{
            if (!newItem.extraFundOtherList || newItem.extraFundOtherList.length == 0) {
                newItem.extraFundOtherList = []
                let newItemFund: PartnerFundListResponse = {
                    id: null,
                    description: '',
                    extraFundType: 'OTHER'
                }
                newItem.extraFundOtherList.push(newItemFund)
            }
        }
        props.setParnerProfileListChange(newItem);
        
    }
    const getExtraProvidentFundType = () => {
        return props.parnerProfileList?.extraProvidentFundType
    }
    const setExtraProvidentFundType = (e: any) => {
        var newItem = { ...props.parnerProfileList };
        newItem.extraProvidentFundType = e;
        props.setParnerProfileListChange(newItem);
    }
    const setExtraProvidentFundPolicyAmount = (e: any) => {
        var newItem = { ...props.parnerProfileList };
        newItem.extraProvidentFundPolicyAmount = e;
        props.setParnerProfileListChange(newItem);
    }
    const setExtraProvidentFundPlanAmount = (e: any) => {
        var newItem = { ...props.parnerProfileList };
        newItem.extraProvidentFundPlanAmount = e;
        props.setParnerProfileListChange(newItem);
    }
    const setExtraGovPensionPolicyAmount = (e: any) => {
        var newItem = { ...props.parnerProfileList };
        newItem.extraGovPensionPolicyAmount = e;
        props.setParnerProfileListChange(newItem);
    }
    const setExtraGovPensionPlanAmount = (e: any) => {
        var newItem = { ...props.parnerProfileList };
        newItem.extraGovPensionPlanAmount = e;
        props.setParnerProfileListChange(newItem);
    }
    const handleAddExtraProvidentFundPolicyList = () => {
        var newItem = { ...props.parnerProfileList };
        if (newItem?.extraProvidentFundPolicyList == null) {
            newItem.extraProvidentFundPolicyList = []
        }
        let newItemFund: PartnerFundListResponse = {
            id: null,
            description: '',
            extraFundType: 'PROVIDENT_FUND_POLICY'
        }
        newItem.extraProvidentFundPolicyList.push(newItemFund)
        props.setParnerProfileListChange(newItem);
    }
    const handleAddExtraProvidentFundPlanList = () => {
        var newItem = { ...props.parnerProfileList };
        if (newItem?.extraProvidentFundPlanList == null) {
            newItem.extraProvidentFundPlanList = []
        }
        let newItemFund: PartnerFundListResponse = {
            id: null,
            description: '',
            extraFundType: 'PROVIDENT_FUND_PLAN'
        }
        newItem.extraProvidentFundPlanList.push(newItemFund)
        props.setParnerProfileListChange(newItem);
    }
    const handleAddExtraGovPensionPolicyList = () => {
        var newItem = { ...props.parnerProfileList };
        if (newItem?.extraGovPensionPolicyList == null) {
            newItem.extraGovPensionPolicyList = []
        }
        let newItemFund: PartnerFundListResponse = {
            id: null,
            description: '',
            extraFundType: 'GOV_PENSION_POLICY'
        }
        newItem.extraGovPensionPolicyList.push(newItemFund)
        props.setParnerProfileListChange(newItem);
    }
    const handleAddExtraGovPensionPlanList = () => {
        var newItem = { ...props.parnerProfileList };
        if (newItem?.extraGovPensionPlanList == null) {
            newItem.extraGovPensionPlanList = []
        }
        let newItemFund: PartnerFundListResponse = {
            id: null,
            description: '',
            extraFundType: 'GOV_PENSION_PLAN'
        }
        newItem.extraGovPensionPlanList.push(newItemFund)
        props.setParnerProfileListChange(newItem);
    }
    const handleAddExtraFundOtherList = () => {
        var newItem = { ...props.parnerProfileList };
        if (newItem?.extraFundOtherList == null) {
            newItem.extraFundOtherList = []
        }
        let newItemFund: PartnerFundListResponse = {
            id: null,
            description: '',
            extraFundType: 'OTHER'
        }
        newItem.extraFundOtherList.push(newItemFund)
        props.setParnerProfileListChange(newItem);
    }
    const handleDeleteExtraFundList = (index: number, type: string) => {
        const item = {
            index: index,
            type: type
        }
        setItemDeleteExtraFund(item as any);
        setConfirmDeleteExtraFund(true);

    }
    const deleteExtraFund = () => {
        var newItem = { ...props.parnerProfileList };
        if (itemDeleteExtraFund) {
            const { index, type } = itemDeleteExtraFund as any
            if (type == 'PROVIDENT_FUND_POLICY') {
                newItem.extraProvidentFundPolicyList.splice(index, 1);
            } else if (type == 'PROVIDENT_FUND_PLAN') {
                newItem.extraProvidentFundPlanList.splice(index, 1);
            } else if (type == 'GOV_PENSION_POLICY') {
                newItem.extraGovPensionPolicyList.splice(index, 1);
            } else if (type == 'GOV_PENSION_PLAN') {
                newItem.extraGovPensionPlanList.splice(index, 1);
            } else if (type == 'OTHER') {
                newItem.extraFundOtherList.splice(index, 1);
            }

            props.setParnerProfileListChange(newItem);
        }
        setItemDeleteExtraFund(undefined);
        setConfirmDeleteExtraFund(false);
    }

    const setExtraProvidentFundPolicyList = (value: any, index: number) => {
        var newItem = { ...props.parnerProfileList };
        newItem.extraProvidentFundPolicyList[index].description = value;
        props.setParnerProfileListChange(newItem);
    }
    const setExtraProvidentFundPlanList = (value: any, index: number) => {
        var newItem = { ...props.parnerProfileList };
        newItem.extraProvidentFundPlanList[index].description = value;
        props.setParnerProfileListChange(newItem);
    }
    const setExtraGovPensionPolicyList = (value: any, index: number) => {
        var newItem = { ...props.parnerProfileList };
        newItem.extraGovPensionPolicyList[index].description = value;
        props.setParnerProfileListChange(newItem);
    }
    const setExtraGovPensionPlanList = (value: any, index: number) => {
        var newItem = { ...props.parnerProfileList };
        newItem.extraGovPensionPlanList[index].description = value;
        props.setParnerProfileListChange(newItem);
    }
    const setExtraFundOtherList = (value: any, index: number) => {
        var newItem = { ...props.parnerProfileList };
        newItem.extraFundOtherList[index].description = value;
        props.setParnerProfileListChange(newItem);
    }
    const createExtraProvidentFundPolicyList = () => {
        var res: any[] = [];
        if (props.parnerProfileList.extraProvidentFundPolicyList === undefined) return res;
        props.parnerProfileList.extraProvidentFundPolicyList.forEach((x, index) => {
            res.push(<div className='row col-11 ml-3 mt-1' key={'extraProvidentFundPolicyList' + index}>
                <div className='d-flex col-12'>
                    <div className='mr-2'>{index + 1}.</div>
                    <div><input type="text" disabled={props.readonly} style={{width:500}} className={'form-control mr-2 '} value={x.description as any} onChange={(e) => setExtraProvidentFundPolicyList(e.target.value, index)} id={"input-text-extraProvidentFundPolicyList" + index} /></div>
                    {props.parnerProfileList.extraProvidentFundPolicyList.length != 1 && (
                        <div className='ml-1'>
                            <img src={iconTrashRed} onClick={(e) => handleDeleteExtraFundList(index, 'PROVIDENT_FUND_POLICY')} width={20} height={20} id={"icon_trash_publishedDoc" + index} style={{ cursor: "pointer" }} className='mr-2' />
                        </div>

                    )}
                    {(index + 1) == props.parnerProfileList?.extraProvidentFundPolicyList.length && (
                        <div>
                            <AddIcon style={{ cursor: "pointer" }} className="blue ml-1" onClick={handleAddExtraProvidentFundPolicyList}></AddIcon>
                        </div>

                    )}
                </div>


            </div>)
        })
        return res;
    }

    const createExtraProvidentFundPlanList = () => {
        var res: any[] = [];
        if (props.parnerProfileList.extraProvidentFundPlanList === undefined) return res;
        props.parnerProfileList.extraProvidentFundPlanList.forEach((x, index) => {
            res.push(<div className='row col-11 ml-3 mt-1' key={'extraProvidentFundPlanList' + index}>
                <div className='d-flex col-12'>
                    <div className='mr-2'>{index + 1}.</div>
                    <div><input type="text" disabled={props.readonly} style={{width:500}} className={'form-control mr-2 '} value={x.description as any} onChange={(e) => setExtraProvidentFundPlanList(e.target.value, index)} id={"input-text-extraProvidentFundPlanList" + index} /></div>
                    {props.parnerProfileList.extraProvidentFundPlanList.length != 1 && (
                        <div className='ml-1'>
                            <img src={iconTrashRed} onClick={(e) => handleDeleteExtraFundList(index, 'PROVIDENT_FUND_PLAN')} width={20} height={20} id={"icon_trash_publishedDoc" + index} style={{ cursor: "pointer" }} className='mr-2' />
                        </div>

                    )}
                    {(index + 1) == props.parnerProfileList?.extraProvidentFundPlanList.length && (
                        <div>
                            <AddIcon style={{ cursor: "pointer" }} className="blue ml-1" onClick={handleAddExtraProvidentFundPlanList}></AddIcon>
                        </div>

                    )}
                </div>


            </div>)
        })
        return res;
    }

    const createExtraGovPensionPolicyList = () => {
        var res: any[] = [];
        if (props.parnerProfileList.extraGovPensionPolicyList === undefined) return res;
        props.parnerProfileList.extraGovPensionPolicyList.forEach((x, index) => {
            res.push(<div className='row col-11 ml-3 mt-1' key={'extraGovPensionPolicyList' + index}>
                <div className='d-flex col-12'>
                    <div className='mr-2'>{index + 1}.</div>
                    <div><input type="text" disabled={props.readonly} style={{width:500}} className={'form-control mr-2 '} value={x.description as any} onChange={(e) => setExtraGovPensionPolicyList(e.target.value, index)} id={"input-text-extraGovPensionPolicyList" + index} /></div>
                    {props.parnerProfileList.extraGovPensionPolicyList.length != 1 && (
                        <div className='ml-1'>
                            <img src={iconTrashRed} onClick={(e) => handleDeleteExtraFundList(index, 'GOV_PENSION_POLICY')} width={20} height={20} id={"icon_trash_publishedDoc" + index} style={{ cursor: "pointer" }} className='mr-2' />
                        </div>

                    )}
                    {(index + 1) == props.parnerProfileList?.extraGovPensionPolicyList.length && (
                        <div>
                            <AddIcon style={{ cursor: "pointer" }} className="blue ml-1" onClick={handleAddExtraGovPensionPolicyList}></AddIcon>
                        </div>

                    )}
                </div>


            </div>)
        })
        return res;
    }
    const createExtraGovPensionPlanList = () => {
        var res: any[] = [];
        if (props.parnerProfileList.extraGovPensionPlanList === undefined) return res;
        props.parnerProfileList.extraGovPensionPlanList.forEach((x, index) => {
            res.push(<div className='row col-11 ml-3 mt-1' key={'extraGovPensionPlanList' + index}>
                <div className='d-flex col-12'>
                    <div className='mr-2'>{index + 1}.</div>
                    <div><input type="text" disabled={props.readonly} style={{width:500}} className={'form-control mr-2 '} value={x.description as any} onChange={(e) => setExtraGovPensionPlanList(e.target.value, index)} id={"input-text-extraGovPensionPlanList" + index} /></div>
                    {props.parnerProfileList.extraGovPensionPlanList.length != 1 && (
                        <div className='ml-1'>
                            <img src={iconTrashRed} onClick={(e) => handleDeleteExtraFundList(index, 'GOV_PENSION_PLAN')} width={20} height={20} id={"icon_trash_publishedDoc" + index} style={{ cursor: "pointer" }} className='mr-2' />
                        </div>

                    )}
                    {(index + 1) == props.parnerProfileList?.extraGovPensionPlanList.length && (
                        <div>
                            <AddIcon style={{ cursor: "pointer" }} className="blue ml-1" onClick={handleAddExtraGovPensionPlanList}></AddIcon>
                        </div>

                    )}
                </div>


            </div>)
        })
        return res;
    }

    const createExtraOtherFundList = () => {
        var res: any[] = [];
        if (props.parnerProfileList.extraFundOtherList === undefined) return res;
        props.parnerProfileList.extraFundOtherList.forEach((x, index) => {
            res.push(<div className='row col-11 ml-3 mt-1' key={'extraFundOtherList' + index}>
                <div className='d-flex col-12'>
                    <div className='mr-2'>{index + 1}.</div>
                    <div><input type="text" disabled={props.readonly} style={{width:500}} className={'form-control mr-2 '} value={x.description as any} onChange={(e) => setExtraFundOtherList(e.target.value, index)} id={"input-text-extraFundOtherList" + index} /></div>
                    {props.parnerProfileList.extraFundOtherList.length != 1 && (
                        <div className='ml-1'>
                            <img src={iconTrashRed} onClick={(e) => handleDeleteExtraFundList(index, 'OTHER')} width={20} height={20} id={"icon_trash_publishedDoc" + index} style={{ cursor: "pointer" }} className='mr-2' />
                        </div>

                    )}
                    {(index + 1) == props.parnerProfileList?.extraFundOtherList.length && (
                        <div>
                            <AddIcon style={{ cursor: "pointer" }} className="blue ml-1" onClick={handleAddExtraFundOtherList}></AddIcon>
                        </div>

                    )}
                </div>


            </div>)
        })
        return res;
    }


    return (
        <React.Fragment>
            <div className="text-left" style={{ position: "relative", marginBottom: 5 }}>
                <div className={'d-flex yellow-button'} style={{ padding: "15px", borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}>
                    {((expand === undefined) || (expand === false)) &&
                        <div onClick={(e) => setExpand(true)}><ExpandMore className="pointer mr-2" ></ExpandMore></div>
                    }
                    {(expand === true) &&
                        <div onClick={(e) => setExpand(false)}><ExpandLess className="pointer mr-2" ></ExpandLess></div>
                    }
                    <label className='ml-2 my-auto'><span style={{ fontWeight: 600, marginRight: 10 }}>ข้อมูลสวัสดิการการออม (เพิ่มเติม)</span></label>
                </div>
                {(expand === true) && (
                    <div style={{ padding: "15px", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px", border: "3px solid #fab232" }}>
                        <div className='row mt-1'>
                            <div className="col-2 form-check checkbox-lg">
                                <input type="checkbox" disabled={props.readonly} className='form-input-check mr-2 mt-2' id="input-" checked={props.parnerProfileList?.checkExtraProvidentFund} onChange={(e) => { setCheckExtraProvidentFund(e.target.checked) }} /><span style={{ fontWeight: 600 }}>กองทุนสำรองเลี้ยงชีพ</span>
                            </div>
                        </div>
                        {props.parnerProfileList?.checkExtraProvidentFund && (
                            <div className='row ml-3'>
                                <div className='row col-9 mb-2'>
                                    <label className='col-2' style={{ fontWeight: 600 }}>ประเภทกองทุน</label>
                                    <div className='col-5'>
                                        <div className="col">
                                            <input type="radio" checked={getExtraProvidentFundType() === 'SINGLE'} onChange={(e) => { setExtraProvidentFundType('SINGLE') }} disabled={props.success} /> Single Fund
                                        </div>
                                        <div className="col">
                                            <input type="radio" checked={getExtraProvidentFundType() === 'POOLED'} onChange={(e) => { setExtraProvidentFundType('POOLED') }} disabled={props.success} /> Pooled Fund
                                        </div>
                                        <div className="col">
                                            <input type="radio" checked={getExtraProvidentFundType() === 'MASTER'} onChange={(e) => { setExtraProvidentFundType('MASTER') }} disabled={props.success} /> Master Fund
                                        </div>
                                    </div>

                                </div>
                                <div className='row col-9 mb-2'>
                                    <div className='col d-flex'>
                                        <div className='mr-2' style={{ fontWeight: 600 }}>จำนวนนโยบายกองทุน</div>
                                        <div><input type="number" disabled={props.readonly} className={'form-control mr-2 '} value={props.parnerProfileList?.extraProvidentFundPolicyAmount} onChange={(e) => setExtraProvidentFundPolicyAmount(e.target.value)} id={"input-number-extraProvidentFundPolicyAmount"} /></div>
                                        <div className='ml-2' style={{ fontWeight: 600 }}>นโยบาย (โปรดระบุรายละเอียด)</div>
                                    </div>
                                </div>
                                {createExtraProvidentFundPolicyList()}
                                <div className='row col-9 mt-2'>
                                    <div className='col d-flex'>
                                        <div className='mr-2' style={{ fontWeight: 600 }}>จำนวนแผนการลงทุน</div>
                                        <div><input type="number" disabled={props.readonly} className={'form-control mr-2 '} value={props.parnerProfileList?.extraProvidentFundPlanAmount} onChange={(e) => setExtraProvidentFundPlanAmount(e.target.value)} id={"input-number-extraProvidentFundPlanAmount"} /></div>
                                        <div className='ml-2' style={{ fontWeight: 600 }}>แผน (โปรดระบุรายละเอียด)</div>
                                    </div>
                                </div>
                                {createExtraProvidentFundPlanList()}
                            </div>
                        )}
                        <div className='row mt-1'>
                            <div className="col-3 form-check checkbox-lg">
                                <input type="checkbox" disabled={props.readonly} className='form-input-check mr-2 mt-2' id="input-" checked={props.parnerProfileList?.checkExtraGovPension} onChange={(e) => { setCheckExtraGovPension(e.target.checked) }} /><span style={{ fontWeight: 600 }}>กองทุนบำเหน็จบำนาญข้าราชการ</span>
                            </div>
                        </div>
                        {props.parnerProfileList?.checkExtraGovPension && (
                            <div className='row ml-3'>
                                <div className='row col-9 mb-2'>
                                    <div className='col d-flex'>
                                        <div className='mr-2' style={{ fontWeight: 600 }}>จำนวนนโยบายกองทุน</div>
                                        <div><input type="number" disabled={props.readonly} className={'form-control mr-2 '} value={props.parnerProfileList?.extraGovPensionPolicyAmount} onChange={(e) => setExtraGovPensionPolicyAmount(e.target.value)} id={"input-number-extraGovPensionPolicyAmount"} /></div>
                                        <div className='ml-2' style={{ fontWeight: 600 }}>นโยบาย (โปรดระบุรายละเอียด)</div>
                                    </div>
                                </div>
                                {createExtraGovPensionPolicyList()}
                                <div className='row col-9 mt-2'>
                                    <div className='col d-flex'>
                                        <div className='mr-2' style={{ fontWeight: 600 }}>จำนวนแผนการลงทุน</div>
                                        <div><input type="number" disabled={props.readonly} className={'form-control mr-2 '} value={props.parnerProfileList?.extraGovPensionPlanAmount} onChange={(e) => setExtraGovPensionPlanAmount(e.target.value)} id={"input-number-extraGovPensionPlanAmount"} /></div>
                                        <div className='ml-2' style={{ fontWeight: 600 }}>แผน (โปรดระบุรายละเอียด)</div>
                                    </div>
                                </div>
                                {createExtraGovPensionPlanList()}
                            </div>
                        )}
                        <div className='row mt-1'>
                            <div className="col-auto form-check checkbox-lg">
                                <input type="checkbox" disabled={props.readonly} className='form-input-check mr-2 mt-2' id="input-" checked={props.parnerProfileList?.checkExtraOther} onChange={(e) => { setCheckExtraOther(e.target.checked) }} /><span style={{ fontWeight: 600 }}>อื่นๆ โปรดระบุ</span>
                            </div>
                            <div className='col-9'>
                                {createExtraOtherFundList()}
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <ConfirmModal title={'กรุณากด "ยืนยัน" เพื่อลบรายการ'} name={''} show={confirmDeleteExtraFund}
                onHide={() => setConfirmDeleteExtraFund(false)} onOk={() => deleteExtraFund()} />
        </React.Fragment>
    )
}

export default PartnerExtraFund