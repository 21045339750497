import React, { useState } from 'react'
import ExpandMore from '@material-ui/icons/ExpandMore'
import ExpandLess from '@material-ui/icons/ExpandLess'
import { PartnerProfileListResponse } from '../../../../model/response/partner-profile';
type Props = {
    parnerProfileList: PartnerProfileListResponse;
    success: boolean;
    setParnerProfileListChange: (parnerProfileList: PartnerProfileListResponse) => void;
}

const PartnerPartSetting = (props: Props) => {
    const [expand, setExpand] = useState(true);
   
    const setShowGeneralInfo = (value: boolean) => {
        var newItem = {...props.parnerProfileList};
        newItem.showGeneralInfo = value;
        props.setParnerProfileListChange(newItem);
    }
    const setShowOrganizationInfo = (value: boolean) => {
        var newItem = {...props.parnerProfileList};
        newItem.showOrganizationInfo = value;
        props.setParnerProfileListChange(newItem);
    }
    const setShowPartner = (value: boolean) => {
        var newItem = {...props.parnerProfileList};
        newItem.showPartner = value;
        props.setParnerProfileListChange(newItem);
    }
    const setShowWelfareBenefit = (value: boolean) => {
        var newItem = {...props.parnerProfileList};
        newItem.showWelfareBenefit = value;
        props.setParnerProfileListChange(newItem);
    }
    return (
        <React.Fragment>
            <div className="text-left" style={{ position: "relative", marginBottom: 5 }}>
                <div className={'d-flex yellow-button'} style={{ padding: "15px", borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}>
                    {((expand === undefined) || (expand === false)) &&
                        <div onClick={(e) => setExpand(true)}><ExpandMore className="pointer mr-2" ></ExpandMore></div>
                    }
                    {(expand === true) &&
                        <div onClick={(e) => setExpand(false)}><ExpandLess className="pointer mr-2" ></ExpandLess></div>
                    }
                    <label className='ml-2 my-auto'><span style={{ fontWeight: 600, marginRight: 20 }}>ชื่อองค์กร</span><span style={{ fontWeight: 600, marginRight: 10,fontSize:'1.2rem' }}>{props.parnerProfileList?.orgName}</span> </label>
                </div>
                {(expand === true) && (
                    <div style={{ padding: "15px", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px", border: "3px solid #fab232" }}>
                        <div className='row mt-1'>
                            <div className="col-4">
                                <input type="checkbox" id="input-showGeneralInfo" checked={props.parnerProfileList?.showGeneralInfo} onChange={(e) => { setShowGeneralInfo(e.target.checked) }}  disabled={true}/> ข้อมูลทั่วไป
                            </div>
                            <div className="col-4">
                                <input type="checkbox" id="input-showOrganizationInfo" checked={props.parnerProfileList?.showOrganizationInfo} onChange={(e) => { setShowOrganizationInfo(e.target.checked) }} /> ข้อมูลองค์กร
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-4">
                                <input type="checkbox" id="input-showPartner" checked={props.parnerProfileList?.showPartner} onChange={(e) => { setShowPartner(e.target.checked) }} /> ข้อมูลผู้ประสาน
                            </div>
                            <div className="col-4">
                                <input type="checkbox" id="input-showWelfareBenefit" checked={props.parnerProfileList?.showWelfareBenefit} onChange={(e) => { setShowWelfareBenefit(e.target.checked) }} /> ข้อมูลสวัสดิการการออม
                            </div>
                        </div>
                    </div>)}
            </div>

        </React.Fragment>

    )
}

export default PartnerPartSetting