import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { logout } from '../../store/auth/actions';

const AdminLogout = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(logout());
        history.push("/admin");
    }, []);

    return (<></>);
}

export default AdminLogout;