import React from 'react'
import { Controller, useForm } from 'react-hook-form';
import { SearchPanel } from '../../../../style/tableStyle';
import loadinglogo from '../../../../img/loading.gif';
import Select from 'react-select';
import { ProjectGroupResponse } from '../../../../model/response/project.model';
type Props = {
    form: any;
    onSubmit: (data: any) => void;
    handleClear: () => void;
    projectGroupList: ProjectGroupResponse[];
    loading: boolean;
}

const AnnouceManageSearchForm = (props: Props) => {
    const { control, handleSubmit, formState: { errors }, reset, register } = props.form;
    const sectionList = [
        { label: 'Announcement', value: 'ANNOUNCEMENT' },
        { label: 'Top Banner', value: 'BANNER' }
    ]
    const onSubmit = (data: any) => {
        props.onSubmit(data);
    }
    const setSection = (value: string) => {
        let result = sectionList.find(x => x.value === value)
        if (result) {
            return { label: result.label, value: result.value }
        } else {
            return null
        }
    }

    const setProjectGroup = (value: number) => {
        let result = props.projectGroupList.find(x => x.id === value)
        if (result) {
            return { label: result.name, value: result.id }
        } else {
            return null
        }
    }
    const handleClear = () => {
        reset();
        props.handleClear();
    }
    const createProjectGroupList = () => {
        var res: any[] = [];
        for (var i = 0; i < props.projectGroupList.length; i++) {
            res.push({ label: props.projectGroupList[i].name, value: props.projectGroupList[i].id });
        }
        return res;

    }
    return (
        <div style={{ background: "white", padding: "15px", borderRadius: "5px" }}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <SearchPanel>
                    <div className='row'>
                        <div className='col-4'>
                            <label className="col-form-label">หัวข้อประชาสัมพันธ์</label>
                            <input {...register("keyword")} className={'form-control'} type="text" name="keyword" id="keyword" />
                        </div>
                        <div className='col-4'>
                            <label className="col-form-label">Section</label>
                            <Controller
                                control={control}
                                name={"section"}
                                render={({ field: { onChange, value, name, ref } }) => (
                                    <Select
                                        placeholder=""
                                        isClearable={true}
                                        value={setSection(value)}
                                        name={name}
                                        options={sectionList}
                                        onChange={(selectedOption: any) => {
                                            onChange(selectedOption?.value);
                                        }}
                                    />
                                )}
                            />
                        </div>
                        <div className='col-4'>
                            <label className="col-form-label">กลุ่มโครงการ</label>
                            <Controller
                                control={control}
                                name={"projectGroup"}
                                render={({ field: { onChange, value, name, ref } }) => (
                                    <Select
                                        placeholder=""
                                        isClearable={true}
                                        value={setProjectGroup(value)}
                                        name={name}
                                        options={createProjectGroupList()}
                                        onChange={(selectedOption: any) => {
                                            onChange(selectedOption?.value);
                                        }}
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <div className="row mt-3" style={{ justifyContent: "center" }}>
                        <button type="button" className="btn btn-outline-primary" style={{ width: "150px" }}
                            onClick={() => handleClear()} >
                            Clear
                        </button>&nbsp;
                        <button type="submit" className="btn btn-primary" style={{ width: "150px" }}
                            disabled={props.loading}>
                            <div>
                                {(props.loading) &&
                                    <div style={{ textAlign: "center" }}><img src={loadinglogo} width="30" height="30" className="d-inline-block align-top" alt="" /></div>
                                }
                                {(!props.loading) &&
                                    <>Search</>
                                }
                            </div>
                        </button>
                    </div>
                </SearchPanel>
            </form>
        </div>
    )
}

export default AnnouceManageSearchForm