import React, { useEffect } from 'react'
import PartnerHeader from './PartnerHeader'
import { Banner, CardBox, PartnerContainer } from '../../style/partnerStylemain'
import banner from '../../img/partnerBanner.png'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import PartnerStepper from './PartnerStepper';
import { StepPage } from './constant/StepPage';
type Props = {}

const PartnerThankyou = (props: Props) => {
    useEffect(()=>{
        sessionStorage.removeItem("listCategory");
    },[])
    return (
        <div className='bg-light-gray partner-page' style={{ height: "100vh", fontFamily: "DB Helvethaica" }}>
            <div style={{ position: 'sticky', top: 0 }}>
                <div className="student-wrapper-header-inner" style={{ background: "#38383D" }}>
                    <PartnerHeader />
                </div>
                <PartnerStepper stepNumber={StepPage.THANKYOU} />
            </div>
            <PartnerContainer className='mt-5'>
                <Banner src={banner}></Banner>
                <h2 className='text-center mt-3' ><br/></h2>
                <CardBox>
                    <div className='text-center'>
                        <CheckCircleIcon className='my-4' style={{fontSize:80,color:"#53a451"}}/>
                    </div>
                    <div className='text-center' style={{fontSize:22}}>
                        ขอขอบคุณที่ท่านให้ความสนใจส่งเสริมความรู้ในองค์กรร่วมกับ <br/> ตลาดหลักทรัพย์แห่งประเทศไทย​ โดยข้อมูลหลักสูตรและสื่อความรู้ที่ท่านเลือกไว้<br/>จะถูกจัดส่งไปยัง e-Mail ของท่าน<br/><br/>​ ทั้งนี้ เจ้าหน้าที่ตลาดหลักทรัพย์ฯ จะติดต่อกลับไปยังท่าน ภายใน 3 วันทำการ
                    </div>
                </CardBox>
            </PartnerContainer>
        </div>
    )
}

export default PartnerThankyou