import React, { useState } from 'react'
import ExpandMore from '@material-ui/icons/ExpandMore'
import ExpandLess from '@material-ui/icons/ExpandLess'
import AddIcon from '@material-ui/icons/Add';
import { Faculty, FacultyInfo, PartnerProfileListResponse, Transition } from '../../../../model/response/partner-profile';
import { Button } from 'react-bootstrap';
import PartnerUniversityFaculty from './PartnerUniversityFaculty';
import Select from 'react-select';
import { customStateStyles, greenStateStyles } from '../../../tab/search-component';
import { createSaveStateNoNew } from '../../../../api/define';
type Props = {
    parnerProfileList: PartnerProfileListResponse;
    success: boolean;
    submitted: boolean;
    facultyItem: any[];
    setParnerProfileListChange: (parnerProfileList: PartnerProfileListResponse) => void;
    readonly: boolean;
    state?: string;
    submittedMerge?: boolean;
    facultyOldItem?: FacultyInfo[];
    hideDropdown?:boolean;
    nextAction?: number;
    nextActionItem?: Transition[];
}

const PartnerUniversity = (props: Props) => {
    const [expand, setExpand] = useState(true);
    const [tabColor, setTabColor] = useState(props.state ? props.state == 'old' ? 'gray-button' : 'yellow-button' : 'yellow-button');
    const [borderColor, setBorderColor] = useState(props.state ? props.state == 'old' ? '#dddddd' : '#fab232' : '#fab232')
    const getRequire = (value: string | boolean | undefined | any[] | null | number) => {
        let moveTransition;
        if(props.nextActionItem){
            moveTransition = props?.nextActionItem.find(x => x.id == props.nextAction);
        }
        let isSendFormToCustomer = moveTransition?.transition == 'ส่งฟอร์มให้กรอกข้อมูล'
        if(isSendFormToCustomer){
            return false;
        }else{
            return (props.submitted && (value == null || value == undefined || value == ''))
        }
    }
    const setTeacherNo = (e: any) => {
        var newItem = { ...props.parnerProfileList };
        newItem.teacherNo = e;
        props.setParnerProfileListChange(newItem);
    }
    const setPersonnel = (e: any) => {
        var newItem = { ...props.parnerProfileList };
        newItem.personnel = e;
        props.setParnerProfileListChange(newItem);
    }
    const setStudentNo = (e: any) => {
        var newItem = { ...props.parnerProfileList };
        newItem.studentNo = e;
        props.setParnerProfileListChange(newItem);
    }
    const handleAddFaculty = () => {
        var newItem = { ...props.parnerProfileList };
        if (newItem?.faculty == null) {
            newItem.faculty = []
        }
        let newFaculty: Faculty = {
            facultyInfo: [{
                id: null,
                facultyName: null,
                facultyDisplayName: null,
                teacherNo: null,
                personnel: null,
                studentNo: null,
                otherFacultyName: null,
                relatedFinancial: false
            }],
            noBranch: false,
            branchInfo: []
        }
        newItem.faculty.push(newFaculty)
        props.setParnerProfileListChange(newItem);
    }

    const getRequireMerge = (value: string | boolean | undefined | any[] | null | number) => {
        return (props.submittedMerge && (value == null || value == undefined))
    }
    const setMergeStateOrganizationInfo = (e:any) => {
        var newItem = { ...props.parnerProfileList }; 
        newItem.mergeStateOrganizationInfo = e;
        props.setParnerProfileListChange(newItem);
    }
    const getMergeStateOrganizationInfo = () => {
        let dropdown = createSaveStateNoNew()
        for (var i = 0; i < dropdown.length; i++)
            if (dropdown[i].value === props.parnerProfileList?.mergeStateOrganizationInfo) {
                return { label: dropdown[i].label, value: props.parnerProfileList?.mergeStateOrganizationInfo };
            }
        return null;
    }

    return (
        <React.Fragment>
            <div className="text-left" style={{ position: "relative", marginBottom: 5 }}>
                <div className={'d-flex ' + tabColor} style={{ padding: "15px", borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}>
                    {((expand === undefined) || (expand === false)) &&
                        <div onClick={(e) => setExpand(true)}><ExpandMore className="pointer mr-2" ></ExpandMore></div>
                    }
                    {(expand === true) &&
                        <div onClick={(e) => setExpand(false)}><ExpandLess className="pointer mr-2" ></ExpandLess></div>
                    }
                    <label className='ml-2 my-auto'><span style={{ fontWeight: 600, marginRight: 10 }}>ข้อมูลองค์กร</span>{props.state && (<span style={{ fontWeight: 600, marginRight: 10 }}>{props.state == "old" ? "(เดิม)" : "(ใหม่)"}</span>)} </label>
                </div>
                {(expand === true) && (
                    <div style={{ padding: "15px", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px", border: "3px solid " + borderColor }}>
                        <div className={props.state == 'new'?'white-box':''}>
                        {props.state == 'new'  && !props.hideDropdown && <div className='row mt-1 d-flex justify-content-end mr-2'>
                            <span>
                                <div style={{ fontWeight: 600 }}>Next Action<span className='text-danger'>*</span></div>
                                <Select
                                    isSearchable={true} isMulti={false} placeholder=""
                                    onChange={(e: any) => setMergeStateOrganizationInfo(e == null ? null : e?.value)}
                                    value={getMergeStateOrganizationInfo()}
                                    options={createSaveStateNoNew()}
                                    styles={getRequireMerge(props.parnerProfileList?.mergeStateOrganizationInfo)?customStateStyles:greenStateStyles}
                                />
                           </span>
                        </div>}
                            <div className='row mt-1'>
                                <label className='col-2 my-auto' style={{ fontWeight: 600 }}>คณาจารย์ ทั้งหมด<span className='text-danger'>*</span></label>
                                <input type="number" disabled={props.readonly} className={'form-control col-4 ' + (getRequire(props.parnerProfileList?.teacherNo) ? "is-invalid" : "")} value={props.parnerProfileList?.teacherNo} onChange={(e) => setTeacherNo(e.target.value)} id={"input-number-teacherNo"} />
                                <label className='col-2 my-auto' style={{ fontWeight: 600 }}>คน</label>
                            </div>
                            <div className='row mt-1'>
                                <label className='col-2 my-auto' style={{ fontWeight: 600 }}>บุคคลากร ทั้งหมด<span className='text-danger'>*</span></label>
                                <input type="number" disabled={props.readonly} className={'form-control col-4 ' + (getRequire(props.parnerProfileList?.personnel) ? "is-invalid" : "")} value={props.parnerProfileList?.personnel} onChange={(e) => setPersonnel(e.target.value)} id={"input-number-personnel"} />
                                <label className='col-2 my-auto' style={{ fontWeight: 600 }}>คน</label>
                            </div>
                            <div className='row mt-1'>
                                <label className='col-2 my-auto' style={{ fontWeight: 600 }}>นิสิต/นักศึกษา ทั้งหมด<span className='text-danger'>*</span></label>
                                <input type="number" disabled={props.readonly} className={'form-control col-4 ' + (getRequire(props.parnerProfileList?.studentNo) ? "is-invalid" : "")} value={props.parnerProfileList?.studentNo} onChange={(e) => setStudentNo(e.target.value)} id={"input-number-studentNo"} />
                                <label className='col-2 my-auto' style={{ fontWeight: 600 }}>คน</label>
                            </div>
                        </div>

                        <div className='row mt-1'>
                            <label className='col-2' style={{ fontWeight: 600 }}>รายละเอียดคณะ<span className='text-danger'>*</span></label>
                            <div className='d-flex flex-column col-10 pl-0'>
                                <div>
                                    <PartnerUniversityFaculty
                                        success={props.success}
                                        submitted={props.submitted}
                                        facultyItem={props.facultyItem}
                                        parnerProfileList={props.parnerProfileList}
                                        setParnerProfileListChange={props.setParnerProfileListChange}
                                        readonly={props.readonly}
                                        state={props?.state}
                                        submittedMerge={props?.submittedMerge}
                                        facultyOldItem={props?.facultyOldItem}
                                        hideDropdown={props?.hideDropdown}
                                    />
                                    {(!props.success && !props.readonly) &&
                                        <div className='d-flex justify-content-center'>
                                            <Button type="button" className="btn btn-warning yellow-button"
                                                onClick={() => handleAddFaculty()} >
                                                <AddIcon />
                                                เพิ่มคณะ
                                            </Button>
                                        </div>

                                    }
                                </div>
                            </div>
                        </div>
                    </div>)}
            </div>

        </React.Fragment>
    )
}

export default PartnerUniversity