import { StudentResultTypeNoContactDetailRequest } from './../../model/request/class-course.model';
import { Dispatch } from 'redux';
import { ActionType, SearchActionType } from '../search/types';
import { ResultAPI } from '../../api/result-api';
import { ClassAPI } from '../../api/class-api';
import { dispatchError } from '../search/actions';

export const listcoursewithname = (class_id: number) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    ClassAPI.getClassCourseWithName(class_id.toString()).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "class_course",
            page: "result",
            action: ActionType.QUERY
        });
    }, function (error) {
        dispatchError("result", dispatch, error);
    })
};

export const liststatus = () => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    ResultAPI.listStatus().then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "status",
            page: "result",
            action: ActionType.QUERY
        });
    }, function (error) {
        dispatchError("result", dispatch, error);
    })
};

export const listresult = (class_id: number, name: string, classCourseId: number, page: number, limit: number) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    ResultAPI.list(class_id, name, classCourseId, page, limit).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "result",
            page: "result",
            action: ActionType.QUERY
        });
    }, function (error) {
        dispatchError("result", dispatch, error);
    })
};

export const save = (class_id: number, classCourseId: number, item: any) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_UPDATING,
        page: "edit_result"
    });
    ResultAPI.save(class_id, classCourseId, item).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_UPDATED,
            payload: response.data,
            table: "edit_result",
            page: "result",
            action: ActionType.UPDATE
        });
    }, function (error) {
        if ((error.response !== undefined) && (error.response.status === 400)) {
            dispatch({
                type: SearchActionType.QUERY_ERROR,
                page: "result",
                payload: error.response.data.additional
            });
        } else
            dispatchError("result", dispatch, error);
    })
};

export const importresult = (class_id: number, classCourseId: number, file: FileList) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_UPDATING,
        page: "edit_result"
    });
    ResultAPI.import(class_id, classCourseId, file).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_UPDATED,
            payload: response.data,
            table: "import_result",
            page: "result",
            action: ActionType.UPDATE
        });
    }, function (error) {
        if ((error.response !== undefined) && (error.response.status === 400)) {
            dispatch({
                type: SearchActionType.QUERY_ERROR,
                page: "result",
                payload: error.response.data.additional
            });
        } else
            dispatchError("result", dispatch, error);
    })
};

export const listResultTypeNoContactDetail = (classId: number, partnerId: number | undefined, classCourseId: number | undefined) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    ResultAPI.listResultTypeNoContactDetail(classId, partnerId, classCourseId).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "result_number_table",
            page: "result_number",
            action: ActionType.QUERY
        });
    }, function (error) {
        dispatchError("result", dispatch, error);
    })
};

export const saveResultTypeNoContactDetail = (class_id: number, item: StudentResultTypeNoContactDetailRequest[]) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_UPDATING
    });
    ResultAPI.saveResultTypeNoContactDetail(class_id, item).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_UPDATED,
            payload: response.data,
            table: "edit_result_number",
            page: "result",
            action: ActionType.UPDATE
        });
    }, function (error) {
        if ((error.response !== undefined) && (error.response.status === 400)) {
            dispatch({
                type: SearchActionType.QUERY_ERROR,
                page: "result",
                payload: error.response.data.additional
            });
        } else
            dispatchError("result", dispatch, error);
    })
};

export const listResultTypeNoContact = (classId: number, partnerId: number | undefined, classCourseId: number | undefined) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    ResultAPI.listResultTypeNoContact(classId, partnerId, classCourseId).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "result_number",
            page: "result_number",
            action: ActionType.QUERY
        });
    }, function (error) {
        dispatchError("result", dispatch, error);
    })
};


