import React from 'react';
import styled from 'styled-components';
import bannerTest from '../../../img/bannerTest.png';
import progresslogo from '../../../img/progress.png';
import manuallogo from '../../../img/manual.svg';
import passlogo from '../../../img/pass.png';
import faillogo from '../../../img/fail.png';
import { StepResultType } from '../../../model/response/student-step.model';
import { useHistory } from 'react-router-dom';
import { StudentAssignmentShortResponse } from '../../../model/response/student-course';
import { Util } from '../../../api/util';
import { CourseType, getCourseType } from '../../../api/define';
import CommonBanner from '../../../img/CommonBanner.png';
const CardDetail = styled.div`
    width: 100%;
    min-height: 140px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    &:hover{
        cursor: pointer;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    }
`
type Props = {
    item: StudentAssignmentShortResponse[] | undefined;
}
const StepCircle = styled.div<{ bgcolor?: string, textColor?: string, borderColor?: string; }>`
    background: ${p => p.bgcolor ? p.bgcolor : 'white'};
    border-radius: 50%;
    border: 1px solid ${p => p.borderColor ? p.borderColor : 'white'};
    width: 22px;
    color: ${p => p.textColor ? p.textColor : 'white'};
    height: 22px;
    text-align: center;
`
const StudentCourseList = (props: Props) => {
    const history = useHistory();
    const getStepColor = (stepResult: StepResultType | undefined, typeColor: 'bgColor' | 'borderColor'): string => {
        if (typeColor == 'bgColor') {
            switch (stepResult) {
                case StepResultType.DONE: return '#9ed062';
                case StepResultType.PROGRESS: return 'white';
                case StepResultType.NONE: return 'white';
                case StepResultType.FAIL: return '#f24726';
                default: return '#9ed062';

            }
        } else if (typeColor == 'borderColor') {
            switch (stepResult) {
                case StepResultType.DONE: return '#9ed062';
                case StepResultType.PROGRESS: return '#53b8f6';
                case StepResultType.NONE: return 'black';
                case StepResultType.FAIL: return '#f24726'
                default: return 'black'
            }
        } else {
            return 'white';
        }

    }
    const getCourseTypeColor = (t: string) => {
        if (t === CourseType.ONLINE) return "#e9f1fe";
        else if (t === CourseType.ONSITE) return "#e8f7de";
        else if (t === CourseType.ELEARNING) return "#fbe8c5";
        else if (t === CourseType.HYBRID) return "#f0def7";
        else if (t === CourseType.OTHER) return "white";
    }

    const getCourseTypeBorderColor = (t: string) => {
        if (t === CourseType.ONLINE) return "#528ff0";
        else if (t === CourseType.ONSITE) return "#9ccf5e";
        else if (t === CourseType.ELEARNING) return "#f4cf57";
        else if (t === CourseType.HYBRID) return "#c04af1";
        else if (t === CourseType.OTHER) return "black";
        return "black";
    }
    const handleGotoAssignment = (classId: number) => {
        history.push('/student/assignment/' + classId);
    }
    return (
        <div>
            {props.item && props.item.map(course => (
                <CardDetail key={'course' + course.classId} className='my-3 d-flex' onClick={() => { handleGotoAssignment(course.classId) }}>
                    <div className='col-auto px-0'>
                        <img src={course.banner?course.banner:CommonBanner} alt="" width={325} height={185} onError={(e: any) => { e.target.src = CommonBanner }} />
                    </div>
                    <div className='col my-2'>
                        <div style={{ fontSize: 22.5, fontWeight: 'bold' }}>{course.name} รอบที่ {course.session}</div>
                        <small className='text-muted'>{Util.datetostrInThai(new Date(course.fromDate))} - {Util.datetostrInThai(new Date(course.toDate))}</small>
                        <div>
                            {course.step && course.step.map((step, index) => (
                                <React.Fragment key={'step' + step.classCourseId}>
                                    {(course.hasMoreItem && (index + 1 == course.step.length)) && (
                                        <div style={{ borderLeft: "1px dashed black", width: "2px", height: "25px", zIndex: 0, marginLeft: 9, marginTop: 1 }} className='mb-1'></div>
                                    )}
                                    <div className='d-flex align-items-center'>
                                        <StepCircle bgcolor={getStepColor(step.stepResult, 'bgColor')} textColor='white' borderColor={getStepColor(step.stepResult, 'borderColor')}>
                                            {step.stepResult === StepResultType.DONE && (<img src={passlogo} style={{ height: "13px", marginTop: "-17px" }} alt="" />)}
                                            {step.stepResult === StepResultType.FAIL && (<img src={faillogo} style={{ height: "13px", marginTop: "-17px" }} alt="" />)}
                                            {step.stepResult === StepResultType.PROGRESS && (<img src={progresslogo} style={{ height: "21px",width:"21px", marginTop: "-17px", marginLeft: "-1px" }} alt="" />)}
                                        </StepCircle>
                                        {step.courseType != CourseType.OTHER && (
                                            <>
                                                <small className='mx-2' style={{fontSize:15.3}}>{step.stepNumber}. {step.courseName}</small>
                                                {step.courseType && (<span style={{ fontSize: "10pt", backgroundColor: getCourseTypeColor(step.courseType), borderRadius: "5px", padding: "ๅpx", border: "1px solid " + getCourseTypeBorderColor(step.courseType) }}>&nbsp;{getCourseType(step.courseType)}&nbsp;</span>)}
                                            </>
                                        )}
                                        {step.courseType == CourseType.OTHER && (
                                            <><small className='mx-2'>{step.stepNumber}. Other</small></>
                                        )}
                                    </div>
                                </React.Fragment>

                            ))}
                        </div>
                    </div>
                </CardDetail>
            ))}
        </div>
    )
}

export default StudentCourseList;

interface CourseDetail {
    courseName: string;
    period: string;
    stepper: any[];
}