import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { AuthReducer } from "./auth/reducers";
import { SearchReducer } from "./search/reducers";
import { MenuReducer } from "./menu/reducers";
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';

const middleware = [thunk]

const persistConfig = {
	key: 'root',
	storage,
}

const rootReducer = combineReducers({
	AuthReducer,SearchReducer,MenuReducer
});
const persistedReducer = persistReducer(persistConfig, rootReducer);

export type RootState = ReturnType<typeof rootReducer>;

function configureStore() {
	const store = createStore(persistedReducer,
		composeWithDevTools(applyMiddleware(...middleware))
	);
	let persistor = persistStore(store);
	return { store, persistor };
}

export const { store, persistor } = configureStore();
