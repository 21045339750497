import { Button, Col, Form, Modal, ModalProps, Row } from "react-bootstrap";
import { SingleValue } from 'react-select';
import { PartnerMediaRequest, PartnerMediaResponse } from "../../../../model/response/partner-activity-media";
import { useEffect, useState } from "react";
import { BasicResponse } from "../../../../api/define";
import { Controller, useForm } from "react-hook-form";
import { SingleSearch } from "../../../tab/search-component";

type Props = ModalProps & {
    okButton?: string
    cancelButton?: string,
    modalItem?: PartnerMediaResponse,
    category: BasicResponse[],
    mediaType: BasicResponse[],
    department: BasicResponse[],
    subCategory: BasicResponse[],
    onOpen?: boolean,
    onOk: (item: PartnerMediaRequest) => void,
    onSelectCategory: (categoryId: number) => void,
    onClear: () => void
}

const PartnerActivityReceiveMediaModal = (props: Props) => {
    const [disableSubCategory, setDisableSubCategory] = useState(true);
    const [submitted, setSubmitted] = useState(false);
    const { control, reset, setValue, getValues, register,
        formState: { isValid } } = useForm({ mode: 'all' });

    const convertDateFormate = (date: Date): string => {
        return date.toLocaleDateString('en-GB', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        }).split('/').reverse().join('-');
    }

    const today = convertDateFormate(new Date());

    const convertStringDateFormate = (date?: Date): string => {
        if (date) {
            return convertDateFormate(new Date(date));
        } else {
            return today;
        }
    }
    const onSubmit = () => {
        setSubmitted(true);
        const param = getValues();
        var request: PartnerMediaRequest = {
            id: props.modalItem?.partnerMediaId,
            name: param.name!,
            amount: param.amount!,
            receivedDate: new Date(param.receivedDate!),
            organizerDeptId: param.department!,
            fileType: param.mediaType!,
            categoryId: param.categoryId!,
            subCategoryId: param.subCategoryId!,
        };
        if (isValid) {
            props.onOk(request);
        }
    }

    const onClear = () => {
        props.onClear();
        setDisableSubCategory(true);
        reset();
    }

    const onSelectCategory = (item: SingleValue<BasicResponse>) => {
        props.onSelectCategory(item?.value);
        setValue('subCategoryId', null);
        if (item) {
            setDisableSubCategory(false);
            setValue('categoryId', item.value);
        } else {
            setDisableSubCategory(true)
            setValue('categoryId', null);
        }
    }


    const getRequireGroup = (value: any) => {
        return (submitted && (value == null || value === undefined || value === ''))
    }

    useEffect(() => {
        if (props.modalItem) {
            setValue("categoryId", props.modalItem?.categoryId, { shouldValidate: true });
            setValue("subCategoryId", props.modalItem?.subCategoryId, { shouldValidate: true });
            setValue("mediaType", props.modalItem?.fileType, { shouldValidate: true });
            setValue("department", props.modalItem?.organizerDeptId, { shouldValidate: true });
            setValue("name", props.modalItem?.name, { shouldValidate: true });
            setValue("amount", props.modalItem?.amount, { shouldValidate: true });
            setValue("receivedDate", convertStringDateFormate(props.modalItem?.receivedDate));
            setDisableSubCategory(!props.modalItem?.subCategoryId);
        } else {
            setValue("categoryId", null, { shouldValidate: true });
            setValue("subCategoryId", null, { shouldValidate: true });
            setValue("mediaType", null, { shouldValidate: true });
            setValue("department", null, { shouldValidate: true });
            setValue("name", null, { shouldValidate: true });
            setValue("amount", null, { shouldValidate: true });
            setValue("receivedDate", today);
            setDisableSubCategory(true);
        }
        setSubmitted(false);
    }, [props.onOpen]);

    return (<>
        <Modal
            {...props}
            dialogClassName="modal-80w"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    ข้อมูลการขอรับสื่อ
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form validated={submitted} >
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2">
                            ประเภทความรู้ <span className="text-danger">*</span>
                        </Form.Label>
                        <Col sm="4">
                            <Controller
                                control={control}
                                name="categoryId"
                                rules={{ required: true }}
                                render={({ field: { value } }) => (
                                    <SingleSearch isError={getRequireGroup(value)}
                                        isSearchable={true}
                                        isMulti={false}
                                        onChange={(id: string, e: any) => onSelectCategory(e)}
                                        value={props.category.find((c) => c.value === value) || null}
                                        options={props?.category}
                                    />
                                )}
                            />
                        </Col>
                        <Form.Label column sm="2">
                            หมวดหมู่ความรู้ <span className="text-danger">*</span>
                        </Form.Label>
                        <Col sm="4">
                            <Controller
                                control={control}
                                name="subCategoryId"
                                rules={{ required: true }}
                                render={({ field: { onChange, value } }) => (
                                    <SingleSearch isError={getRequireGroup(value)}
                                        isSearchable={true}
                                        isMulti={false}
                                        onChange={(id: string, e: any) => onChange(e?.value)}
                                        value={props.subCategory.find((c) => c.value === value) || null}
                                        options={props?.subCategory}
                                        disabled={disableSubCategory}
                                    />
                                )}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2">
                            ประเภทสื่อ <span className="text-danger">*</span>
                        </Form.Label>
                        <Col sm="4">
                            <Controller
                                control={control}
                                name="mediaType"
                                rules={{ required: true }}
                                render={({ field: { onChange, value } }) => (
                                    <SingleSearch isError={getRequireGroup(value)}
                                        isSearchable={true}
                                        isMulti={false}
                                        onChange={(id: string, e: any) => onChange(e?.value)}
                                        value={props.mediaType.find((c) => c.value === value) || null}
                                        options={props?.mediaType}
                                    />
                                )}
                            />
                        </Col>
                        <Form.Label column sm="2">
                            ฝ่ายงานเจ้าของสื่อ <span className="text-danger">*</span>
                        </Form.Label>
                        <Col sm="4">
                            <Controller
                                control={control}
                                name="department"
                                rules={{ required: true }}
                                render={({ field: { onChange, value } }) => (
                                    <SingleSearch isError={getRequireGroup(value)}
                                        isSearchable={true}
                                        isMulti={false}
                                        onChange={(id: string, e: any) => onChange(e?.value)}
                                        value={props.department.find((c) => c.value === value) || null}
                                        options={props?.department}
                                    />
                                )}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                        <Form.Label column sm="2">
                            ชื่อสื่อความรู้ <span className="text-danger">*</span>
                        </Form.Label>
                        <Col sm="10">
                            <Form.Control {...register("name", {required: true})} type="text" required />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2">
                            จำนวน <span className="text-danger">*</span>
                        </Form.Label>
                        <Col sm="4">
                            <Form.Control {...register("amount", {required: true})} type="number" required min={0} />
                        </Col>
                        <Form.Label column sm="2">
                            วันที่รับข้อมูล
                        </Form.Label>
                        <Col sm="4">
                            <Form.Control {...register("receivedDate")} type="date" defaultValue={today} />
                        </Col>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer className="justify-content-md-center">
                <Button type="button" variant="secondary" onClick={onClear}>
                    {props.cancelButton || 'Clear'}
                </Button>
                <Button type="submit" variant="success" onClick={onSubmit}>
                    {props.okButton || 'Save'}
                </Button>
            </Modal.Footer>
        </Modal>
    </>
    )
}

export default PartnerActivityReceiveMediaModal;
