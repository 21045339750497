import { ProjectManageInfoRequest } from '../model/request/project.model';
import { apiClient } from './axios-intercept';
import { DEFAULT_SERVER } from './conf';

export const PROJECT = "/api/v1/project";


export const LIST_PROJECT_URL = DEFAULT_SERVER + PROJECT;
export const LIST_MANAGE_PROJECT_INFO_URL = DEFAULT_SERVER + '/api/v1/project/management-info';
export const LIST_MANAGE_PROJECT_INFO_URL_NEXT_CODE = DEFAULT_SERVER + '/api/v1/project/management-info/next-code';
export const LIST_MANAGE_PROJECT_INFO_URL_CREATE = DEFAULT_SERVER + '/api/v1/project/management-info/create';
export const LIST_MANAGE_PROJECT_INFO_URL_UPDATE = DEFAULT_SERVER + '/api/v1/project/management-info/update';
export const EXPORT_PROJECT_INFO_URL = DEFAULT_SERVER + '/api/v1/project/management-info/export';
export class ProjectAPI {
    public static list = async (): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=LIST_PROJECT_URL;
            apiClient.get(s,
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                console.log("list project response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("list project error:", err);
                return reject(err); 
            })
        })
    }
    public static getProjectManageInfo = async (projectName: string | undefined,projectGroupId: number | undefined,organizerDeptId: string | undefined,page:number,size:number,sort: string | undefined): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=LIST_MANAGE_PROJECT_INFO_URL;
            var queryParams:string[] = [];
            if(projectName) {
                queryParams.push(`projectName=${projectName}`)
            }
            if(projectGroupId) {
                queryParams.push(`projectGroupId=${projectGroupId}`)
            }
            if(organizerDeptId) {
                queryParams.push(`organizerDeptId=${organizerDeptId}`)
            }
            queryParams.push(`page=${page}`);
            queryParams.push(`size=${size}`);
            if(sort) {
                queryParams.push(`sort=${sort}`)
            }
            
            apiClient.get(s+'?'+queryParams.join('&'),
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                return resolve(response);
            }).catch(err => {
                console.log("list project error:", err);
                return reject(err); 
            })
        })
    }

    public static getProjectCode = async (projectGroupId: number): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=LIST_MANAGE_PROJECT_INFO_URL_NEXT_CODE;
            var queryParams:string[] = [];
            if(projectGroupId) {
                queryParams.push(`projectGroupId=${projectGroupId}`)
            }
            apiClient.get(s+'?'+queryParams.join('&'),
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                return resolve(response);
            }).catch(err => {
                console.log("list project next code error:", err);
                return reject(err); 
            })
        })
    }

    public static addProjectInfo = async (body:ProjectManageInfoRequest): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=LIST_MANAGE_PROJECT_INFO_URL_CREATE;
            apiClient.post(s,body
                ,{//options
                    headers: {
                        //"Content-Type": "multipart/form-data",
                        "Accept": "application/json"
                    }
                })
            .then((response) => {
                return resolve(response);
            }).catch(err => {
                console.log("add project doc error:", err);
                return reject(err); 
            })
        })
    }
    public static updateProjectInfo = async (body:ProjectManageInfoRequest,projectId:number): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=LIST_MANAGE_PROJECT_INFO_URL_UPDATE+`/${projectId}`;
            apiClient.put(s,body
                ,{//options
                    headers: {
                        //"Content-Type": "multipart/form-data",
                        "Accept": "application/json"
                    }
                })
            .then((response) => {
               
                return resolve(response);
            }).catch(err => {
                console.log("add project doc error:", err);
                return reject(err); 
            })
        })
    }
    public static getDownloadParam = (projectName: string | undefined,projectGroupId: number | undefined,organizerDeptId: string | undefined,token:any) => {
        var queryParams:string[] = [];
        if(projectName) {
            queryParams.push(`projectName=${projectName}`)
        }
        if(projectGroupId) {
            queryParams.push(`projectGroupId=${projectGroupId}`)
        }
        if(organizerDeptId) {
            queryParams.push(`organizerDeptId=${organizerDeptId}`)
        }
        if(token){
            queryParams.push(`token=${token}`)
        }
        return queryParams.length>0?queryParams.join('&'):'';
    }
}