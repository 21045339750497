import React, { useState } from 'react'
import { MouResponse, PartnerProfileListResponse } from '../../../../model/response/partner-profile';
import ExpandMore from '@material-ui/icons/ExpandMore'
import ExpandLess from '@material-ui/icons/ExpandLess'
import { Button, InputGroup } from 'react-bootstrap';
import { ThaiDateTimePicker } from '../../../tab/thai-datetime-picker';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import AddIcon from '@material-ui/icons/Add';
import iconTrashRed from '../../../../img/iconTrashRed.png'
import ConfirmModal from '../../../share/ConfirmModal';
import { SingleSearch } from '../../../tab/search-component';
import { KnowledgeDepartmentResponse } from '../../../../model/response/manage-knowledge';
type Props = {
    parnerProfileList: PartnerProfileListResponse;
    success: boolean;
    submitted: boolean;
    setParnerProfileListChange: (parnerProfileList: PartnerProfileListResponse) => void;
    readonly: boolean;
    departmentItem: KnowledgeDepartmentResponse[];
}

const PartnerExtraMou = (props: Props) => {
    const [expand, setExpand] = useState(true);
    const [itemDeleteExtraMou, setItemDeleteExtraMou] = useState<any>();
    const [confirmDeleteExtraMou, setConfirmDeleteExtraMou] = useState(false);
    const getRequire = (value: string | boolean | undefined | any[] | null | number | Date) => {
        return (props.submitted && (value == null || value == undefined || value == ''))
    }
    const setHaveMOU = (e: boolean) => {
        const newItem = { ...props.parnerProfileList };
        newItem.haveMOU = e;
        if (e) {
            if (newItem?.mouList == null || newItem?.mouList == undefined) {
                newItem.mouList = []
            }
            let newItemFund: MouResponse = {
                id: null,
                mouName: '',
                mouOrganization: '',
                mouStartDate: null,
                mouToDate: null,
                scopeList: [{
                    id: null,
                    scpoeDescription: ''
                }],
                mouDepartment: null
            }
            newItem.mouList.push(newItemFund)
        } else {
            newItem.mouList = [];
        }
        props.setParnerProfileListChange(newItem);
    }
    const getHaveMou = () => {
        return props.parnerProfileList?.haveMOU
    }
    const setMouName = (value: any, index: number) => {
        var newItem = { ...props.parnerProfileList };
        newItem.mouList[index].mouName = value;
        props.setParnerProfileListChange(newItem);
    }
    const setMouOrganization = (value: any, index: number) => {
        var newItem = { ...props.parnerProfileList };
        newItem.mouList[index].mouOrganization = value;
        props.setParnerProfileListChange(newItem);
    }
    const setMouStartDate = (index: number, value: Date) => {
        var newItem = { ...props.parnerProfileList };
        newItem.mouList[index].mouStartDate = value
        props.setParnerProfileListChange(newItem);
    }
    const setMouToDate = (index: number, value: Date) => {
        var newItem = { ...props.parnerProfileList };
        newItem.mouList[index].mouToDate = value
        props.setParnerProfileListChange(newItem);
    }
    const setScopeDescription = (value: string, index: number, scopeIndex: number) => {
        var newItem = { ...props.parnerProfileList };
        newItem.mouList[index].scopeList[scopeIndex].scpoeDescription = value;
        props.setParnerProfileListChange(newItem);
    }

    const handleDeleteScopeList = (index: number, scopeIndex: number) => {
        var newItem = { ...props.parnerProfileList };
        newItem.mouList[index].scopeList.splice(scopeIndex, 1)
        props.setParnerProfileListChange(newItem);
    }
    const handleAddScopeList = (index: number) => {
        var newItem = { ...props.parnerProfileList };
        newItem.mouList[index].scopeList.push({
            id: null,
            scpoeDescription: ''
        })
        props.setParnerProfileListChange(newItem);
    }
    const handleDeleteMouList = (index: number) => {
        const item = {
            index: index
        }
        setItemDeleteExtraMou(item as any);
        setConfirmDeleteExtraMou(true);
    }

    const deleteExtraMou = () => {
        var newItem = { ...props.parnerProfileList };
        if (itemDeleteExtraMou) {
            const { index } = itemDeleteExtraMou as any
            newItem.mouList.splice(index, 1);
        }
        props.setParnerProfileListChange(newItem);
        setItemDeleteExtraMou(undefined);
        setConfirmDeleteExtraMou(false);
    }

    const setDepartment = (index: number, e: any) => {
        var newItem = { ...props.parnerProfileList };
        newItem.mouList[index].mouDepartment = e.value
        props.setParnerProfileListChange(newItem);
    }
    const getDepartment = (index: number) => {
        var c = props.parnerProfileList.mouList[index];
        if ((c.mouDepartment === null) || (c.mouDepartment === undefined)) return null;
        for (var i = 0; i < props.departmentItem.length; i++) {
            if (props.departmentItem[i].id === c.mouDepartment) {
                return { label: props.departmentItem[i].name, value: c.mouDepartment };
            }
        }
        return null;
    }
    const createDepartment = () => {
        var res: any[] = [];

        for (var i = 0; i < props.departmentItem.length; i++) {
            res.push({ label: props.departmentItem[i].name, value: props.departmentItem[i].id });
        }
        return res;
    }

    const createMouList = () => {
        var res: any[] = [];
        if (props.parnerProfileList.mouList === undefined) return res;
        props.parnerProfileList.mouList.forEach((x, index) => {
            res.push(<div className='gray-button mb-2' style={{ padding: "15px", borderTopLeftRadius: "5px", borderTopRightRadius: "5px", position: 'relative' }} key={'moulist' + index}>
                <div className='row mt-1'>
                    <label className='col-3' style={{ fontWeight: 600 }}>ชื่อ MOU<span className='text-danger'>*</span></label>
                    <input type="text" disabled={props.readonly} className={'form-control col-8 ' + (getRequire(x.mouName) ? "is-invalid" : "")} value={x.mouName} onChange={(e) => setMouName(e.target.value, index)} id={"input-text-mouName"} />
                </div>
                <div className='row mt-1'>
                    <label className='col-3' style={{ fontWeight: 600 }}>โปรดระบุหน่วยงานที่ MOU<span className='text-danger'>*</span></label>
                    <input type="text" disabled={props.readonly} className={'form-control col-8 ' + (getRequire(x.mouOrganization) ? "is-invalid" : "")} value={x.mouOrganization} onChange={(e) => setMouOrganization(e.target.value, index)} id={"input-text-houseNo"} />
                </div>
                <div className='row mt-1'>
                    <label className='col-3' style={{ fontWeight: 600 }}>โปรดระบุระยะเวลา<span className='text-danger'>*</span></label>
                    <div className="col-3 pl-0">
                        <label className="col-form-label">วันที่เริ่ม</label><br />
                        <InputGroup hasValidation>
                            <label className='position-relative'>
                                <ThaiDateTimePicker className={"form-control " + (getRequire(x.mouStartDate) ? "is-invalid" : "")} selected={x.mouStartDate} onChange={(date: any) => setMouStartDate(index, date)} disabled={props.success} />
                                <div className="search-icon" style={{ visibility: getRequire(x.mouStartDate) ? "hidden" : "visible", position: "absolute", right: "10px", top: "5px", zIndex: 0 }}>
                                    <CalendarTodayIcon style={{ color: "gray" }} />
                                </div>
                            </label>
                        </InputGroup>
                    </div>
                    <div className="col-3 pl-0">
                        <label className="col-form-label">วันที่สิ้นสุด</label><br />
                        <InputGroup hasValidation>
                            <label className='position-relative'>
                                <ThaiDateTimePicker className={"form-control " + (getRequire(x.mouToDate) ? "is-invalid" : "")} selected={x.mouToDate} onChange={(date: any) => setMouToDate(index, date)} disabled={props.success} />
                                <div className="search-icon" style={{ visibility: getRequire(x.mouToDate) ? "hidden" : "visible", position: "absolute", right: "10px", top: "5px", zIndex: 0 }}>
                                    <CalendarTodayIcon style={{ color: "gray" }} />
                                </div>
                            </label>
                        </InputGroup>
                    </div>
                </div>
                <div className='row mt-1'>
                    <label className='col-3' style={{ fontWeight: 600 }}>โปรดระบุขอบเขตความร่วมมือ<span className='text-danger'>*</span></label>
                    <div className='col-9 pl-0'>
                        {x.scopeList && x.scopeList.map((scope, scopeIndex) => (
                            <div key={'scope' + index} className='d-flex mb-2'>
                                <div><input type="text" disabled={props.readonly} style={{ width: 500 }} className={'form-control mr-2 ' + (getRequire(scope.scpoeDescription) ? "is-invalid" : "")} value={scope.scpoeDescription as any} onChange={(e) => setScopeDescription(e.target.value, index, scopeIndex)} id={"input-text-scope" + index + '-' + scopeIndex} /></div>
                                {x.scopeList.length != 1 && (
                                    <div className='ml-1'>
                                        <img src={iconTrashRed} onClick={(e) => handleDeleteScopeList(index, scopeIndex)} width={20} height={20} id={"icon_trash_scoprList" + index + '-' + scopeIndex} style={{ cursor: "pointer" }} className='mr-2' />
                                    </div>

                                )}
                                {(scopeIndex + 1) == x.scopeList.length && (
                                    <div>
                                        <AddIcon style={{ cursor: "pointer" }} className="blue ml-1" onClick={() => handleAddScopeList(index)}></AddIcon>
                                    </div>

                                )}
                            </div>
                        ))}
                    </div>

                </div>
                <div className='row mt-1'>
                    <label className='col-3' style={{ fontWeight: 600 }}>ฝ่ายงานที่รับผิดชอบ MOU<span className='text-danger'>*</span></label>
                    <SingleSearch id={"input-dropdown-department-" + index} isSearchable={true} isMulti={false} placeholder=""
                        onChange={(id: number, e: any) => setDepartment(index, e)}
                        value={getDepartment(index)}
                        options={createDepartment()}
                        isError={getRequire(x.mouDepartment)}
                        style={{ width: "400px" }}
                    />
                </div>
                {props.parnerProfileList.mouList.length != 1 && (
                    <img src={iconTrashRed} width={20} height={20} id={"icon_trash_mou" + index + "_"} style={{ position: "absolute", right: "5px", top: "5px", zIndex: 10, cursor: 'pointer' }} onClick={e => { handleDeleteMouList(index) }} className='mr-2' />
                )}

            </div>)
        })
        return res;
    }
    const handleAddMou = () => {
        var newItem = { ...props.parnerProfileList };
        if (newItem?.mouList == null || newItem?.mouList == undefined) {
            newItem.mouList = []
        }
        let newItemFund: MouResponse = {
            id: null,
            mouName: '',
            mouOrganization: '',
            mouStartDate: null,
            mouToDate: null,
            scopeList: [{
                id: null,
                scpoeDescription: ''
            }],
            mouDepartment: null
        }
        newItem.mouList.push(newItemFund)
        props.setParnerProfileListChange(newItem);
    }
    return (
        <React.Fragment>
            <div className="text-left" style={{ position: "relative", marginBottom: 5 }}>
                <div className={'d-flex yellow-button'} style={{ padding: "15px", borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}>
                    {((expand === undefined) || (expand === false)) &&
                        <div onClick={(e) => setExpand(true)}><ExpandMore className="pointer mr-2" ></ExpandMore></div>
                    }
                    {(expand === true) &&
                        <div onClick={(e) => setExpand(false)}><ExpandLess className="pointer mr-2" ></ExpandLess></div>
                    }
                    <label className='ml-2 my-auto'><span style={{ fontWeight: 600, marginRight: 10 }}>ข้อมูลบันทึกข้อตกลงความร่วมมือกับตลาดหลักทรัพย์แห่งประเทศไทย</span></label>
                </div>
                {(expand === true) && (
                    <div style={{ padding: "15px", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px", border: "3px solid #fab232" }}>
                        <div className='row mt-1'>
                            <div className="col-2 form-check checkbox-lg">
                                <input type="radio" disabled={props.readonly} className='form-input-check mr-2 mt-2' id="input-" checked={getHaveMou() == true} onChange={(e) => { setHaveMOU(true) }} /><span style={{ fontWeight: 600 }}>มี MOU</span>
                            </div>
                            {getHaveMou() == true && <div className='d-flex flex-column col-10 pl-0'>
                                {createMouList()}
                                {(!props.success) &&
                                    <div className='d-flex justify-content-center'>
                                        <Button type="button" className="btn btn-warning yellow-button"
                                            onClick={() => handleAddMou()} >
                                            <AddIcon />
                                            เพิ่ม MOU
                                        </Button>

                                    </div>

                                }
                            </div>}
                        </div>
                        <div className='row mt-1'>
                            <div className="col-2 form-check checkbox-lg">
                                <input type="radio" disabled={props.readonly} className='form-input-check mr-2 mt-2' id="input-" checked={getHaveMou() == false} onChange={(e) => { setHaveMOU(false) }} /><span style={{ fontWeight: 600 }}>ไม่มี MOU</span>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <ConfirmModal title={'กรุณากด "ยืนยัน" เพื่อลบรายการ'} name={''} show={confirmDeleteExtraMou}
                onHide={() => setConfirmDeleteExtraMou(false)} onOk={() => deleteExtraMou()} />
        </React.Fragment>
    )
}

export default PartnerExtraMou