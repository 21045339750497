import React, { useEffect, useState } from 'react'
import { Button, Modal, ModalProps } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useDispatch, useStore } from 'react-redux'
import loadinglogo from '../../img/loading.gif';
import styled from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';
import { SurveyType } from '../../api/define';
import { Util } from '../../api/util';
import parse from 'html-react-parser'
import { getsurvey } from '../../store/survey/actions';
import { PreviewQuestionRequest, SurveyQuestionRequest } from '../../model/response/survey.model';
const CustomModal = styled(Modal)`
    .description{
        color: #5F6062;
        margin-top: 10px;
    }
    .button-close{
        position: absolute;
        top:-30px;
        right: -30px;
        color:white;
        &:hover{
            cursor: pointer;
            transform:scale(1.3,1.3);
            -webkit-transform:scale(1.3,1.3);
            -moz-transform:scale(1.3,1.3);
        }
    }
`
type Props = ModalProps &
{
    editItem: any | PreviewQuestionRequest[];
    onOk: (value: any) => void
}
const PreviewQuestionModal = (props: Props) => {
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [resultItems, setResultItems] = useState<any>({});
    const [items, setItems] = useState<PreviewQuestionRequest[]>([]);
    const [success, setSuccess] = useState(true);
    const dispatch = useDispatch();
    const [init, setInit] = useState(0);
    const store = useStore();
    useEffect(() => {
        if (props.show) {
            if (props.editItem) {
                setItems(props.editItem);
                setInit(1);
            } else {
                setInit(1);
            }
        }
    }, [props.show])
    const hideModal = () => {
        props.onHide()
    }
    const createSurvey = () => {
        var res: any[] = [];

        for (var i = 0; i < items.length; i++) {
            var item = items[i];
            if ((item.surveyType === SurveyType.MULTIPLE) || (item.surveyType === SurveyType.ASSISTANT)) {
                var itemres: any[] = [];
                for (var j = 0; j < item.item.length; j++) {
                    var it = item.item[j];
                    itemres.push(
                        <>
                            <input type="checkbox" name={"checkbox_" + i} value="1" id={i + "_" + j} disabled={success} />
                            &nbsp;{it.title}<br />
                        </>
                    )
                }
                res.push(<>
                    <div style={{ textAlign: "left" }}>
                        {(item.required) ? <span className="red">*</span> : <></>} <b>{i + 1}. {item.title}</b><br /><br />
                        <div style={{ paddingLeft: "50px" }}>
                            {itemres}
                        </div>
                    </div>
                    
                    <br />
                </>);
            } else if (item.surveyType === SurveyType.CHOICE) {
                var itemres: any[] = [];
                for (var j = 0; j < item.item.length; j++) {
                    var it = item.item[j];
                    itemres.push(
                        <>
                            <input type="radio" name={"choice_" + i} value="1" id={i + "_" + j} disabled={success} />
                            &nbsp;{it.title}<br />
                        </>
                    )
                }
                res.push(<>
                    <div style={{ textAlign: "left" }}>
                        {(item.required) ? <span className="red">*</span> : <></>} <b>{i + 1}. {item.title}</b><br /><br />
                        <div style={{ paddingLeft: "50px" }}>
                            {itemres}
                        </div>
                    </div>
                    
                    <br />
                </>);
            } else if ((item.surveyType === SurveyType.DEGREE) || (item.surveyType === SurveyType.TRAINER) || (item.surveyType === SurveyType.COURSE) || (item.surveyType === SurveyType.ASSISTANT2)) {
                var itemres: any[] = [];
                for (var j = 0; j < item.item.length; j++) {
                    var it = item.item[j];
                    itemres.push(
                        <><tr className={(j % 2 === 0) ? "gray-button" : "light-gray-button"} style={{ textAlign: "center", justifyContent: "center" }}>
                            <td style={{ textAlign: "left" }}>{it.title?parse(it.title):''}</td>
                            <td style={{ textAlign: "center", justifyContent: "center" }}><input style={{ height: "2em" }} name={"choice_" + i + "_" + j} type="radio" value="1" id={i + "_" + j}  disabled={success} /></td>
                            <td style={{ textAlign: "center", justifyContent: "center" }}><input style={{ height: "2em" }} name={"choice_" + i + "_" + j} type="radio" value="1" id={i + "_" + j}  disabled={success} /></td>
                            <td style={{ textAlign: "center", justifyContent: "center" }}><input style={{ height: "2em" }} name={"choice_" + i + "_" + j} type="radio" value="1" id={i + "_" + j}  disabled={success} /></td>
                            <td style={{ textAlign: "center", justifyContent: "center" }}><input style={{ height: "2em" }} name={"choice_" + i + "_" + j} type="radio" value="1" id={i + "_" + j}  disabled={success} /></td>
                            <td style={{ textAlign: "center", justifyContent: "center" }}><input style={{ height: "2em" }} name={"choice_" + i + "_" + j} type="radio" value="1" id={i + "_" + j}  disabled={success} /></td>
                        </tr></>
                    )
                }
                res.push(<>
                    <div style={{ textAlign: "left" }}>
                        {(item.required) ? <span className="red">*</span> : <></>} <b>{i + 1}. {item.title?parse(item.title):''}</b><br /><br />
                        <div style={{ paddingLeft: "50px" }}>
                            <table style={{ width: "100%" }}>
                                <tr style={{ textAlign: "center" }}><th></th><th style={{ width: "150px" }}>มากที่สุด</th><th style={{ width: "150px" }}>มาก</th><th style={{ width: "150px" }}>ปานกลาง</th><th style={{ width: "150px" }}>น้อย</th><th style={{ width: "150px" }}>น้อยที่สุด</th></tr>
                                {itemres}
                            </table>
                        </div>
                    </div>
                    
                    <br />
                </>);
            } else if ((item.surveyType === SurveyType.INPUT) || (item.surveyType === SurveyType.NUMBER)) {
                var itemres: any[] = [];
                for (var j = 0; j < item.item.length; j++) {
                    var it = item.item[j];
                    if (item.surveyType === SurveyType.INPUT) {
                        itemres.push(
                            <>{it.title}<br />
                                <div className="row">
                                    <div className={"col-12"}>
                                        <input className="form-control" type="text" id={i + "_" + j}  disabled={success} />
                                    </div>
                                </div>
                                <br /></>
                        )
                    } else {
                        itemres.push(
                            <>
                                <div className="row">
                                    <table>
                                        <tr>
                                            <td>
                                                {it.title}&nbsp;
                                            </td>
                                            <td>
                                                <input className="form-control" type="text" id={i + "_" + j}  disabled={success} />
                                            </td>
                                            <td>
                                                &nbsp;{it.unit}
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <br /></>
                        )
                    }
                }
                res.push(<>
                    <div style={{ textAlign: "left" }}>
                        {(item.required) ? <span className="red">*</span> : <></>} <b>{i + 1}. {item.title}</b><br /><br />
                        <div style={{ paddingLeft: "50px" }}>
                            {itemres}
                        </div>
                    </div>
                    
                    <br />
                </>);
            } else if (item.surveyType === SurveyType.RANKING) {
                var itemres: any[] = [];
                for (var j = 0; j < item.item.length; j++) {
                    var it = item.item[j];
                    itemres.push(
                        <>
                            <div className="row">
                                <div className="col-2">
                                    <input className="form-control" type="text" id={i + "_" + j}  disabled={success} />
                                </div>
                                <div className="col-10">
                                    {it.title}
                                </div>
                            </div>
                            <br /></>
                    )
                }
                res.push(<>
                    <div style={{ textAlign: "left" }}>
                        {(item.required) ? <span className="red">*</span> : <></>} <b>{i + 1}. {item.title}</b><br /><br />
                        <div style={{ paddingLeft: "50px" }}>
                            {itemres}
                        </div>
                    </div>
                   
                    <br />
                </>);
            } else {
                res.push(<>
                    <div style={{ textAlign: "left" }}>
                        {(item.required) ? <span className="red">*</span> : <></>} <b>{i + 1}. {item.title}</b><br /><br />
                        <div style={{ paddingLeft: "50px" }}>
                            <textarea className="form-control" maxLength={250} cols={50} id={i.toString()}  disabled={success}></textarea>
                        </div>
                    </div>
                    
                    <br />
                </>);
            }
        }

        return res;
    }
    return (
        <CustomModal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <a className='button-close' onClick={hideModal}><CloseIcon /></a>

            <Modal.Body>
                {(init === 1) && (<>
                    {createSurvey()}
                </>)}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={hideModal}>
                    Close
                </Button>
            </Modal.Footer>

        </CustomModal>
    )
}

export default PreviewQuestionModal