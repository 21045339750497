import React, { useEffect, useState } from 'react';
import { useDispatch, useStore } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import MainMenu from './menu';
import Footer from './adminfooter';
import { Button, Form, InputGroup, Modal } from 'react-bootstrap';
import { getsurveygroup, createsurveygroup, updatesurveygroup, deletesurveygroup, listproject, listorganization } from '../../store/editsurveygroup/actions';
import { queryclear } from '../../store/search/actions';
import loadinglogo from '../../img/loading.gif';
import AddIcon from '@material-ui/icons/Add';
import { AlertContainer, alert } from 'react-custom-alert';
import 'react-custom-alert/dist/index.css'; // import css file from root.
import ImageIcon from '@material-ui/icons/Image';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { basicSurveyDropdown, BasicSurveyType, createSurveyType, QuestionType, SurveyPermissionType, SurveyType } from '../../api/define';
import { INTERNAL_ERROR_LABEL, MAX_UPLOAD_FILE_SIZE_LABEL } from '../../api/label';
import { SingleSearch } from '../tab/search-component';
import { ActionType } from '../../store/search/types';
import { PREVIEW_SURVEY_URL } from '../../api/survey-api';
import { MAX_FILE_UPLOAD } from '../../api/conf';
import PreviewQuestionModal from './previewQuestionModal';
import { listQuestionGroup, listQuestionGroupGetSurvey, previewSurveyQuestion } from '../../store/surveygroup/actions';
import { Util } from '../../api/util';

const defaultConst: any = {};

const EditSurveyGroup = (props: any) => {
  const [init, setInit] = useState(0)
  const [initProject, setInitProject] = useState(0)
  const [initOrganizer, setInitOrganizer] = useState(0);
  const [initQuestionGroup, setInitQuestionGroup] = useState(0)
  const [item, setItem] = useState<any>({ ...defaultConst })
  const [projectItem, setProjectItem] = useState<Array<any>>([])
  const [organizerItem, setOrganizerItem] = useState<Array<any>>([])
  const [defaultItem, setDefaultItem] = useState<any>(JSON.parse(JSON.stringify(defaultConst)))
  const [logoFile, setLogoFile] = useState<FileList>();
  const [headerFile, setHeaderFile] = useState<FileList>();
  const [errorLogoFile, setErrorLogoFile] = useState("");
  const [errorHeaderFile, setErrorHeaderFile] = useState("");
  const [loading, setLoading] = useState(false)
  const [updating, setUpdating] = useState(false)
  const [success, setSuccess] = useState(false)
  const [edit, setEdit] = useState(false)
  const [validate, setValidate] = useState(false)
  const [leave, setLeave] = useState("")
  const [deleteId, setDeleteId] = useState<string>("");
  const [answered, setAnswered] = useState<boolean>(false);
  const [error, setError] = useState('');
  const store = useStore();
  const dispatch = useDispatch();
  const history = useHistory();
  const search = useLocation().search;
  const duplicate = new URLSearchParams(search).get('duplicate');;
  let { id } = useParams<{ id: string }>();
  const [modalShow, setModalShow] = useState<boolean | undefined>();
  const [modalItem, setModalItem] = useState<any>();
  const [questionGroupItem, setQuestionGroupItem] = useState<Array<{ id: number, name: string, surveyQuestionList: any[] }>>([]);

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      let res = store.getState().SearchReducer;
      if (res.page !== "edit_surveygroup") return;
      if (res.isLoading) {
        setError('');
        setLoading(true);
      } else if (res.isLoaded) {
        setError('');
        setLoading(false);
        if (res.table === "project") {
          setInitProject(1);
          setProjectItem(res.data);
        } else if (res.table === "organizer") {
          setInitOrganizer(1);
          setOrganizerItem(res.data);
        } else if (res.table === "preview_survey_question") {
          setModalItem(res.data);
          setModalShow(true);
        } else if (res.table === "list_question_group") {
          setInitQuestionGroup(1);
          setQuestionGroupItem(res.data);
        } else if (res.data != null) {
          var newData = res.data;
          setDefaultItem(JSON.parse(JSON.stringify(newData)));
          setItem(newData);
          setAnswered(res.data.answered);
        } else {
          setError(INTERNAL_ERROR_LABEL);
        }
        setInit(1);
      } else if (res.isUpdating) {
        setError('');
        setUpdating(true);
      } else if (res.isUpdated) {
        setError('');
        setUpdating(false);
        setEdit(false);
        setSuccess(true);
        if (res.action === ActionType.DELETE) {
          alert({ message: 'Deleted', type: 'success' });
          setItem({ name: "", description: "" });
        } else {
          alert({ message: 'Saved', type: 'success' });
          setDefaultItem(JSON.parse(JSON.stringify(res.data)));
          setItem(res.data);
          if (res.table === "surveygroup" && id == null) {
            history.push('/admin/survey_group/' + res.data.id);
          }
        }
        dispatch(queryclear());
      } else if (res.isFailed) {
        if (res.data.success !== undefined) {
          console.log(JSON.stringify(res.data));
          setItem(res.data);
          alert({ message: res.data.popup ?? 'Error', type: 'error' });
          window.scrollTo(0, 0);
        }
        setError(res.data.message);
        setLoading(false);
        setUpdating(false);
        dispatch(queryclear());
      }
    });
    setItem({ ...defaultConst }); //reset
    setDefaultItem(JSON.parse(JSON.stringify(defaultConst)));

    dispatch(queryclear());
    dispatch(listQuestionGroupGetSurvey());
    dispatch(listproject());
    dispatch(listorganization());

    window.scroll(0, 0);
    if (id != null) {
      dispatch(getsurveygroup(id));
    } else {
      setInit(1);
    }
    return unsubscribe;
  }, [])

  const clear = () => {
    var newItem = JSON.parse(JSON.stringify(defaultItem));
    setItem(newItem);
    setEdit(false);
    setValidate(false);
  }

  const validateForm = () => {
    setValidate(true);
    var check = true;
    //reset
    setErrorLogoFile("");
    setErrorHeaderFile("");
    if (logoFile !== undefined) {
      if (logoFile[0].size > MAX_FILE_UPLOAD * 1000000) {
        check = false;
        setErrorLogoFile(MAX_UPLOAD_FILE_SIZE_LABEL);
      }
    }
    if (headerFile !== undefined) {
      if (headerFile[0].size > MAX_FILE_UPLOAD * 1000000) {
        check = false;
        setErrorHeaderFile(MAX_UPLOAD_FILE_SIZE_LABEL);
      }
    }
    return check;
  }

  const handleClear = () => {
    if (edit) setLeave("clear");
  }

  const handleSubmit = () => {
    if (validateForm()) {
      dispatch(queryclear());
      if (id == null) dispatch(createsurveygroup(item, logoFile, headerFile)); else dispatch(updatesurveygroup(parseInt(id), item, logoFile, headerFile));
    }
  }

  const handleClose = () => {
    history.push("/admin/search/survey");
  }

  const handleBack = () => {
    if (edit) setLeave("/admin/search/survey");
    else history.push("/admin/search/survey");
  }

  const handleDelete = () => {
    setDeleteId(id.toString());
  }

  const handleDeleteClose = () => {
    setDeleteId("");
  }

  const handleDeleteConfirm = () => {
    dispatch(queryclear());
    dispatch(deletesurveygroup(deleteId));
    setDeleteId("");
  }

  const handleReedit = () => {
    setSuccess(false);
  }

  const handleLeave = () => {
    if (leave === "clear") {
      clear();
    } else {
      history.push(leave);
    }
    setLeave("");
  }

  const getErrorSurvey = () => {
    return item.errorSurvey;
  }

  const setName = (v: string) => {
    var newItem: any = { ...item };
    newItem.name = v;
    setItem(newItem);
    setEdit(true);
  }

  const getName = () => {
    return item.name;
  }

  const getErrorName = () => {
    return item.errorName;
  }

  const setNickname = (v: string) => {
    var newItem: any = { ...item };
    newItem.nickname = v;
    setItem(newItem);
    setEdit(true);
  }

  const getNickName = () => {
    return item.nickname;
  }

  const getErrorNickname = () => {
    return item.errorNickname;
  }

  const setPowerBiUrl = (v: string) => {
    var newItem: any = { ...item };
    newItem.powerBiUrl = v;
    setItem(newItem);
    setEdit(true);
  }
  const getPowerBiUrl = () => {
    return item.powerBiUrl;
  }

  const setDescription = (v: string) => {
    var newItem: any = { ...item };
    newItem.description = v;
    setItem(newItem);
    setEdit(true);
  }

  const getDescription = () => {
    return item.description;
  }

  const getErrorDescription = () => {
    return item.errorDescription;
  }

  const getThankYou = () => {
    return item.thankYou;
  }

  const setThankYou = (v: string) => {
    var newItem: any = { ...item };
    newItem.thankYou = v;
    setItem(newItem);
    setEdit(true);
  }

  const setProjectId = (e: number) => {
    var newItem: any = { ...item };
    newItem.projectId = e;
    setItem(newItem);
    setEdit(true);
  }

  const getProjectId = () => {
    for (var i = 0; i < projectItem.length; i++) {
      if (projectItem[i].id === item.projectId) {
        return { label: projectItem[i].shortName + " : " + projectItem[i].name, value: item.projectId };
      }
    }
    return null;
  }

  const createProjectIdFilter = () => {
    var res: any[] = [];
    for (var i = 0; i < projectItem.length; i++) {
      res.push({ label: projectItem[i].shortName + " : " + projectItem[i].name, value: projectItem[i].id });
    }
    return res;
  }

  const getErrorProjectId = () => {
    return (item.errorProjectId === undefined || item.errorProjectId === null) ? null : item.getErrorProjectId;
  }

  const setOrganizer = (e: String) => {
    var newItem: any = { ...item };
    newItem.organizerId = e;
    setItem(newItem);
    setEdit(true);
  }

  const getOrganizer = () => {
    for (var i = 0; i < organizerItem.length; i++) {
      if (organizerItem[i].name === item.organizerId) {
        return { label: organizerItem[i].name, value: item.organizerId };
      }
    }
    return null;
  }

  const createOrganizerFilter = () => {
    var res: any[] = [];
    for (var i = 0; i < organizerItem.length; i++) {
      res.push({ label: organizerItem[i].name, value: organizerItem[i].name });
    }
    return res;
  }

  const getErrorOrganizerId = () => {
    return (item.errorOrganizerId === undefined || item.errorOrganizerId === null) ? null : item.getErrorOrganizerId;
  }

  const getPermission = () => {
    return item.permission;
  }

  const setPermission = (e: string) => {
    var newItem: any = { ...item };
    newItem.permission = e;
    if (e !== SurveyPermissionType.PROJECT) {
      newItem.projectId = null;
    }
    if (e !== SurveyPermissionType.ORGANIZER) {
      newItem.organizerId = null;
    }
    setItem(newItem);
    setEdit(true);
  }

  const getErrorPermission = () => {
    return (item.errorPermission === undefined || item.errorPermission === null) ? "" : item.errorPermission;
  }

  const setLogoFileChange = (selectorFiles: FileList | null) => {
    if (selectorFiles !== null) {
      setLogoFile(selectorFiles);
    }
  }

  const getLogoURL = () => {
    return (item.logoUrl === undefined) ? null : item.logoUrl;
  }

  const getErrorLogoFile = () => {
    if (errorLogoFile !== "") return errorLogoFile;
    return (item.errorLogo === undefined || item.errorLogo === null) ? "" : item.errorLogo;
  }

  const setHeaderFileChange = (selectorFiles: FileList | null) => {
    if (selectorFiles !== null) {
      setHeaderFile(selectorFiles);
    }
  }

  const getHeaderURL = () => {
    return (item.headerUrl === undefined) ? null : item.headerUrl;
  }

  const getErrorHeaderFile = () => {
    if (errorHeaderFile !== "") return errorHeaderFile;
    return (item.errorHeader === undefined || item.errorHeader === null) ? "" : item.errorHeader;
  }

  const setMultipleBasicSurvey = (id: number[], v: boolean) => {
    var newItem: any = { ...item };
    if (newItem.basicSurvey === undefined) {
      newItem.basicSurvey = [];
    }
    var found = -1;
    var newBasicSurvey: any[] = [];
    for (var i = 0; i < ((item.basicSurvey === undefined) ? 0 : item.basicSurvey.length); i++) {
      var inside = false;
      for (var k = 0; k < id.length; k++)
        if (item.basicSurvey[i].basic === id[k]) inside = true;
      if (inside) {
        found = i;
      } else {
        newBasicSurvey.push(item.basicSurvey[i]);
      }
    }
    if ((!v) && (found !== -1)) {
      newItem.basicSurvey = newBasicSurvey;
    }
    if ((v) && (found === -1)) {
      for (var i = 0; i < id.length; i++)
        newItem.basicSurvey.push({ basic: id[i] });
    }
    setItem(newItem);
    setEdit(true);
  }

  const setBasicSurvey = (id: number, v: boolean) => {
    var newItem: any = { ...item };
    if (newItem.basicSurvey === undefined) {
      newItem.basicSurvey = [];
    }
    var found = -1;
    var newBasicSurvey: any[] = [];
    for (var i = 0; i < ((item.basicSurvey === undefined) ? 0 : item.basicSurvey.length); i++) {
      if (item.basicSurvey[i].basic === id) {
        found = i;
      } else {
        newBasicSurvey.push(item.basicSurvey[i]);
      }
    }
    if ((!v) && (found !== -1)) {
      newItem.basicSurvey = newBasicSurvey;
    }
    if ((v) && (found === -1)) {
      newItem.basicSurvey.push({ basic: id });
    }
    setItem(newItem);
    setEdit(true);
  }

  const getBasicSurvey = (id: number) => {
    if ((item.basicSurvey === undefined) || (item.basicSurvey === null)) return false;
    for (var i = 0; i < item.basicSurvey.length; i++) {
      if (item.basicSurvey[i].basic === id) return true;
    }
    return false;
  }

  const setSurveyType = (id: number, v: SurveyType | '-') => {
    var newItem = { ...item };
    newItem.survey[id].surveyType = v == '-' ? undefined : v;
    setItem(newItem);
    setEdit(true);
  }
  const getSurveyType = (id: number) => {
    if(item.survey[id].surveyType == SurveyType.COURSE || 
      item.survey[id].surveyType == SurveyType.TRAINER ||
      item.survey[id].surveyType == SurveyType.ASSISTANT || 
      item.survey[id].surveyType == SurveyType.ASSISTANT2){
        return SurveyType.DEGREE;
      }
    return (item.survey[id].surveyType) ?? undefined;
  }

  const setBasicSurveyType = (id: number, v: BasicSurveyType) => {
    var newItem = { ...item };
    newItem.survey[id].basicSurveyType = v;
    setItem(newItem);
    setEdit(true);
  }
  


  const setQuestionType = (id: number, v: QuestionType) => {
    var newItem = { ...item };
    newItem.survey[id].questionType = v;
    if (v == QuestionType.CUSTOM) {
      newItem.survey[id].surveyType = SurveyType.CHOICE;
    } else {
      newItem.survey[id].surveyType = undefined;
    }

    setItem(newItem);
    setEdit(true);
  }

  const getQuestion = (i: number) => {
    return (item.survey[i].title);
  }

  const setQuestion = (i: number, v: string) => {
    var newItem = { ...item };
    newItem.survey[i].title = v;
    setItem(newItem);
    setEdit(true);
  }

  const getRequired = (i: number) => {
    return (item.survey[i].required);
  }

  const setRequired = (i: number, v: boolean) => {
    var newItem = { ...item };
    newItem.survey[i].required = v;
    setItem(newItem);
    setEdit(true);
  }

  const getOtherAnswer = (i: number) => {
    return (item.survey[i].otherAnswer);
  }

  const setOtherAnswer = (i: number, v: boolean) => {
    var newItem = { ...item };
    newItem.survey[i].otherAnswer = v;
    setItem(newItem);
    setEdit(true);
  }


  const getAnswer = (i: number, k: number) => {
    return (item.survey[i].item[k].title);
  }

  const setAnswer = (i: number, k: number, v: string) => {
    var newItem = { ...item };
    newItem.survey[i].item[k].title = v;
    setItem(newItem);
    setEdit(true);
  }

  const getErrorAnswer = (i: number, k: number) => {
    return item.survey[i].item[k].errorTitle;
  }

  const getUnit = (i: number, k: number) => {
    return (item.survey[i].item[k].unit);
  }

  const setUnit = (i: number, k: number, v: string) => {
    var newItem = { ...item };
    newItem.survey[i].item[k].unit = v;
    setItem(newItem);
    setEdit(true);
  }

  const getErrorUnit = (i: number, k: number) => {
    return item.survey[i].item[k].errorUnit;
  }


  const getErrorMainAnswer = (i: number) => {
    return item.survey[i].errorItem;
  }

  const getErrorSurveyType = (i: number) => {
    return item.survey[i].errorSurveyType;
  }

  const getErrorBasicSurveyType = (i: number) => {
    return item.survey[i].errorBasicSurveyType;
  }


  const getQuestionType = (i: number) => {
    return item.survey[i].questionType ?? QuestionType.BASIC;
  }

  const getBasicSurveyType = (i: number) => {
    return item.survey[i].basicSurveyType ? item.survey[i].basicSurveyType : undefined;
  }

  const getErrorQuestion = (i: number) => {
    return item.survey[i].errorTitle;
  }

  const getErrorQuestionGroupId = (i: number) => {
    return item.survey[i].errorQuestionGroupId;
  }
  const createQuestionGroupSearchFilter = (i: number) => {
    return (
      <>
        <option value={undefined}></option>
        {questionGroupItem.map(x => (
          <React.Fragment key={x.id}>
            <option value={x.id}>{x.name}</option>
          </React.Fragment>
        ))}
      </>
    )
  }

  const createQuestionSearchFilter = (i: number) => {
    let questionGroupIndex = questionGroupItem.findIndex(x => x.id == item.survey[i].questionGroupId)
    return (
      <>
        <option value={undefined}></option>
        {questionGroupIndex > -1 && questionGroupItem[questionGroupIndex].surveyQuestionList.map(x => (
          <React.Fragment key={x.id}>
            {showQuestion(x.id, i) && (
              <option value={x.id}>{x.name} {x.version ? '(' + Util.datetimetostr(new Date(x.version)) + ')' : ''}</option>
            )}
          </React.Fragment>
        ))}
      </>
    )
  }

  const showQuestionGroup = (value: number, surveyIndex: number) => {
    let result = item.survey.findIndex((x: any) => x.questionGroupId === value);
    if (result > -1 && item.survey[surveyIndex].questionGroupId !== value) {
      return undefined;
    } else {
      return true;
    }
  }

  const showQuestion = (value: number, surveyIndex: number) => {
    let result = item.survey.findIndex((x: any) => x.questionId === value);
    if (result > -1 && item.survey[surveyIndex].questionId !== value) {
      return undefined;
    } else {
      return true;
    }
  }

  const setQuestionGroupId = (index: number, v: string) => {
    var newItem: any = { ...item };
    newItem.survey[index].questionGroupId = Number(v);
    newItem.survey[index].questionId = null;
    setItem(newItem);
    setEdit(true);
  }

  const setQuestionId = (index: number, v: string) => {
    var newItem: any = { ...item };
    newItem.survey[index].questionId = Number(v);
    let questionGroupIndex = questionGroupItem.findIndex(x => x.id == newItem.survey[index].questionGroupId)
    let question = questionGroupItem[questionGroupIndex].surveyQuestionList.find(x => x.id == Number(v));
    newItem.survey[index].surveyType = question.surveyType;
    setItem(newItem);
    setEdit(true);
  }

  const getQuestionId = (index: number) => {
    return item.survey[index].questionId ? item.survey[index].questionId : undefined;
  }

  const getQuestionGroupId = (index: number) => {
    return item.survey[index].questionGroupId ? item.survey[index].questionGroupId : undefined;
  }


  const handleAddAnswer = (id: number) => {
    var newItem = { ...item };
    if (newItem.survey == null) return;
    if (newItem.survey[id].item == null) newItem.survey[id].item = [];
    newItem.survey[id].item.push({});
    setItem(newItem);
    setEdit(true);
  }

  const handleDeleteAnswer = (sid: number, id: number) => {
    var newItem = { ...item };
    //correct id
    var newAnswer: any[] = [];
    var k = 0;
    for (var i = 0; i < newItem.survey[sid].item.length; i++) {
      if (i < id) { newAnswer.push({ ...newItem.survey[sid].item[i] }); k++; }
      else if (i > id) {
        var c = { ...newItem.survey[sid].item[i] };
        c.priority = k;
        newAnswer.push(c);
        k++;
      }
    }
    newItem.survey[sid].item = newAnswer;
    setItem(newItem);
    setEdit(true);
  }

  const handleAddSurvey = () => {
    var newItem = { ...item };
    if (newItem.survey === undefined) newItem.survey = [];
    newItem.survey.push({
      questionType: QuestionType.BASIC,
      surveyType: undefined,
      item: []
    });
    setItem(newItem);
    setEdit(true);
  }

  const handleDeleteSurvey = (id: number) => {
    var newItem = { ...item };
    var newSurvey: any[] = [];
    for (var i = 0; i < item.survey.length; i++) {
      if (i !== id) newSurvey.push(item.survey[i]);
    }
    newItem.survey = newSurvey;
    setItem(newItem);
    setEdit(true);
  }

  const handleMoveUpSurvey = (id: number) => {
    if (id === 0) return;
    var newItem = { ...item };
    if (newItem.survey == null) newItem.survey = [];
    var temp = { ...newItem.survey[id - 1] };
    newItem.survey[id - 1] = newItem.survey[id];
    newItem.survey[id] = temp;
    setItem(newItem);
    setEdit(true);
  }

  const handleMoveDownSurvey = (id: number) => {
    if (id === item.survey.length - 1) return;
    var newItem = { ...item };
    if (newItem.survey == null) newItem.survey = [];
    var temp = { ...newItem.survey[id + 1] };
    newItem.survey[id + 1] = newItem.survey[id];
    newItem.survey[id] = temp;
    setItem(newItem);
    setEdit(true);
  }

  const handlePreview = () => {
    window.open(PREVIEW_SURVEY_URL + "/" + (item.id) + "/preview");
  }
  const handlePreviewQuestion = (i: number) => {
    setModalItem([item.survey[i]]);
    setModalShow(true);
  }

  const handlePreviewQuestionBasic = (i: number) => {
    let questionId = item.survey[i].questionId;
    dispatch(previewSurveyQuestion(questionId, null, null))
  }

  const createBasicSurveyTypeDropdown = (i: number) => {
    //var res = [];
    return (
      <>
        <option value={undefined}></option>
        {basicSurveyDropdown().map(x => (
          <React.Fragment key={x.value}>
            {showBasicSurveyType(x.value, i) && (
              <option value={x.value}>{x.label}</option>
            )}
          </React.Fragment>
        ))}
      </>
    )
  }

  const showBasicSurveyType = (value: BasicSurveyType, surveyIndex: number) => {
    let result = item.survey.findIndex((x: any) => x.basicSurveyType === value);
    if (result > -1 && item.survey[surveyIndex].basicSurveyType !== value) {
      return undefined;
    } else {
      return true;
    }
  }

  const createAnswer = (i: number, unit: boolean) => {
    var res = [];
    var s = item.survey[i].item;
    for (var k = 0; k < s.length; k++) {
      res.push(<>
        <div className="row">
          <div className={(unit) ? "col-5" : "col-10"}>
            <InputGroup hasValidation>
              <input type="text" id={i + "_" + k} maxLength={200} className={"form-control " + ((getErrorAnswer(i, k) != null) ? "is-invalid" : "")} value={getAnswer(i, k)} onChange={e => setAnswer(parseInt(e.currentTarget.id.split("_")[0]), parseInt(e.currentTarget.id.split("_")[1]), e.target.value)} disabled={success || answered} /><br />
            </InputGroup>
            <div className={(getErrorAnswer(i, k) != null) ? "text-danger" : ""}><small>{getErrorAnswer(i, k)}</small></div>
          </div>
          {(unit) &&
            <div className={"col-5"}>
              <InputGroup hasValidation>
                <input type="text" id={i + "_" + k} maxLength={200} className={"form-control " + ((getErrorUnit(i, k) != null) ? "is-invalid" : "")} value={getUnit(i, k)} onChange={e => setUnit(parseInt(e.currentTarget.id.split("_")[0]), parseInt(e.currentTarget.id.split("_")[1]), e.target.value)} disabled={success || answered} /><br />
              </InputGroup>
              <div className={(getErrorUnit(i, k) != null) ? "text-danger" : ""}><small>{getErrorUnit(i, k)}</small></div>
            </div>
          }
          {(!success && !answered) &&
            <div className="col-2" id={i + "_" + k} style={{ paddingTop: "5px", marginLeft: "-10px" }} onClick={(e) => { handleDeleteAnswer(parseInt(e.currentTarget.id.split("_")[0]), parseInt(e.currentTarget.id.split("_")[1])) }}>
              <RemoveCircleOutlineIcon className="red"></RemoveCircleOutlineIcon>
            </div>
          }
        </div><br /></>);
    }
    return res;
  }

  const createMultiple = (i: number) => {
    return (<>

      <div className="row">
        <div className="col-2">
          <label className="col-form-label">ประเภทคำตอบ</label>
        </div>
        <div className="col-6">
          <InputGroup hasValidation>
            <select id={i.toString()} className={"form-control " + ((getErrorSurveyType(i) != null) ? "is-invalid" : "")} onChange={e => setSurveyType(parseInt(e.currentTarget.id), e.target.value as SurveyType)} value={SurveyType.MULTIPLE} disabled={success || answered}>
              {createSurveyType()}
            </select><br />
          </InputGroup>
          <div className={(getErrorSurveyType(i) != null) ? "invalid-feedback" : ""}>{getErrorSurveyType(i)}</div>
        </div>
        <div style={{ paddingTop: "10px" }}>
          <input type="checkbox" checked={getRequired(i)} onChange={(e) => { setRequired(i, e.currentTarget.checked) }} disabled={updating || success || answered} />
        </div>
        <div className="col-2" style={{ textAlign: "left" }}>
          <label className="col-form-label">จำเป็นต้องตอบ</label>
        </div>

      </div>
      <br />
      <div className="row">
        <div className="col-2">
          <label className="col-form-label">คำถาม</label>
        </div>
        <div className="col-10">
          <InputGroup hasValidation>
            <input type="text" id={i.toString()} maxLength={200} className={"form-control " + ((getErrorQuestion(i) != null) ? "is-invalid" : "")} onChange={e => setQuestion(parseInt(e.currentTarget.id), e.target.value)} value={getQuestion(i)} disabled={success || answered} />
            <a id={i.toString()} onClick={(e) => handlePreviewQuestion(parseInt(e.currentTarget.id))} className="link mx-2">ดูตัวอย่างคำถาม</a>
          </InputGroup>

          <div className={(getErrorQuestion(i) != null) ? "text-danger" : ""}><small>{getErrorQuestion(i)}</small></div>
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-2">
          <label className="col-form-label">คำตอบ</label>
        </div>
        <div className="col-10">
          {createAnswer(i, false)}
          {(!success && !answered) &&
            <div className="col-1" id={i.toString()} style={{ paddingTop: "0px", paddingLeft: "5px" }} onClick={(e) => { handleAddAnswer(parseInt(e.currentTarget.id.split("_")[0])) }}>
              <AddCircleOutlineIcon className="blue"></AddCircleOutlineIcon>
            </div>
          }
          <div className={(getErrorMainAnswer(i) != null) ? "text-danger" : ""}><small>{getErrorMainAnswer(i)}</small></div>
        </div>
      </div>
    </>);
  }
  const createUndefinedChoice = (i: number) => {
    console.log("Test undefined")
    return (<>
      <div className="row">
        <div className="col-2">
          <label className="col-form-label">ประเภทคำตอบ</label>
        </div>
        <div className="col-6">
          <InputGroup hasValidation>
            <select id={i.toString()} className={"form-control " + ((getErrorSurveyType(i) != null) ? "is-invalid" : "")} onChange={e => setSurveyType(parseInt(e.currentTarget.id), e.target.value as SurveyType)} value={undefined} disabled={success || answered}>
              {createSurveyType()}
            </select><br />
          </InputGroup>
          <div className={(getErrorSurveyType(i) != null) ? "invalid-feedback" : ""}>{getErrorSurveyType(i)}</div>
        </div>
        <div style={{ paddingTop: "10px" }}>
          <input type="checkbox" checked={getRequired(i)} onChange={(e) => { setRequired(i, e.currentTarget.checked) }} disabled={updating || success || answered} />
        </div>
        <div className="col-2" style={{ textAlign: "left" }}>
          <label className="col-form-label">จำเป็นต้องตอบ</label>
        </div>

        
      </div>
    </>)
  }

  const createChoice = (i: number) => {
    return (<>
      <div className="row">
        <div className="col-2">
          <label className="col-form-label">ประเภทคำตอบ</label>
        </div>
        <div className="col-6">
          <InputGroup hasValidation>
            <select id={i.toString()} className={"form-control " + ((getErrorSurveyType(i) != null) ? "is-invalid" : "")} onChange={e => setSurveyType(parseInt(e.currentTarget.id), e.target.value as SurveyType)} value={SurveyType.CHOICE} disabled={success || answered}>
              {createSurveyType()}
            </select><br />
          </InputGroup>
          <div className={(getErrorSurveyType(i) != null) ? "invalid-feedback" : ""}>{getErrorSurveyType(i)}</div>
        </div>
        <div style={{ paddingTop: "10px" }}>
          <input type="checkbox" checked={getRequired(i)} onChange={(e) => { setRequired(i, e.currentTarget.checked) }} disabled={updating || success || answered} />
        </div>
        <div className="col-2" style={{ textAlign: "left" }}>
          <label className="col-form-label">จำเป็นต้องตอบ</label>
        </div>

      </div>
      <br />
      <div className="row">
        <div className="col-2">
          <label className="col-form-label">คำถาม</label>
        </div>
        <div className="col-10">
          <InputGroup hasValidation>
            <input type="text" id={i.toString()} maxLength={200} className={"form-control " + ((getErrorQuestion(i) != null) ? "is-invalid" : "")} onChange={e => setQuestion(parseInt(e.currentTarget.id), e.target.value)} value={getQuestion(i)} disabled={success || answered} />
            <a id={i.toString()} onClick={(e) => handlePreviewQuestion(parseInt(e.currentTarget.id))} className="link mx-2">ดูตัวอย่างคำถาม</a>
          </InputGroup>

          <div className={(getErrorQuestion(i) != null) ? "text-danger" : ""}><small>{getErrorQuestion(i)}</small></div>
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-2">
          <label className="col-form-label">คำตอบ</label>
        </div>
        <div className="col-10">
          {createAnswer(i, false)}
          {(!success && !answered) &&
            <div className="col-1" id={i.toString()} style={{ paddingTop: "0px", paddingLeft: "5px" }} onClick={(e) => { handleAddAnswer(parseInt(e.currentTarget.id.split("_")[0])) }}>
              <AddCircleOutlineIcon className="blue"></AddCircleOutlineIcon>
            </div>
          }
          <div className={(getErrorMainAnswer(i) != null) ? "text-danger" : ""}><small>{getErrorMainAnswer(i)}</small></div>
        </div>
      </div>
    </>);
  }

  const createDegree = (i: number) => {
    return (<>
      <div className="row">
        <div className="col-2">
          <label className="col-form-label">ประเภทคำตอบ</label>
        </div>
        <div className="col-6">
          <InputGroup hasValidation>
            <select id={i.toString()} className={"form-control " + ((getErrorSurveyType(i) != null) ? "is-invalid" : "")} onChange={e => setSurveyType(parseInt(e.currentTarget.id), e.target.value as SurveyType)} value={SurveyType.DEGREE} disabled={success || answered}>
              {createSurveyType()}
            </select><br />
          </InputGroup>
          <div className={(getErrorSurveyType(i) != null) ? "invalid-feedback" : ""}>{getErrorSurveyType(i)}</div>

        </div>
        <div style={{ paddingTop: "10px" }}>
          <input type="checkbox" checked={getRequired(i)} onChange={(e) => { setRequired(i, e.currentTarget.checked) }} disabled={updating || success || answered} />
        </div>
        <div className="col-2" style={{ textAlign: "left" }}>
          <label className="col-form-label">จำเป็นต้องตอบ</label>
        </div>

      </div>
      <br />
      <div className="row">
        <div className="col-2">
          <label className="col-form-label">คำถาม</label>
        </div>
        <div className="col-10">
          <InputGroup hasValidation>
            <input type="text" id={i.toString()} maxLength={200} className={"form-control " + ((getErrorQuestion(i) != null) ? "is-invalid" : "")} onChange={e => setQuestion(parseInt(e.currentTarget.id), e.target.value)} value={getQuestion(i)} disabled={success || answered} />
            <a id={i.toString()} onClick={(e) => handlePreviewQuestion(parseInt(e.currentTarget.id))} className="link mx-2">ดูตัวอย่างคำถาม</a>
          </InputGroup>

          <div className={(getErrorQuestion(i) != null) ? "text-danger" : ""}><small>{getErrorQuestion(i)}</small></div>
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-2">
          <label className="col-form-label">คำตอบ</label>
        </div>
        <div className="col-10">
          {createAnswer(i, false)}
          {(!success && !answered) &&
            <div className="col-1" id={i.toString()} style={{ paddingTop: "0px", paddingLeft: "5px" }} onClick={(e) => { handleAddAnswer(parseInt(e.currentTarget.id.split("_")[0])) }}>
              <AddCircleOutlineIcon className="blue"></AddCircleOutlineIcon>
            </div>
          }
          <div className={(getErrorMainAnswer(i) != null) ? "text-danger" : ""}><small>{getErrorMainAnswer(i)}</small></div>
        </div>
      </div>
    </>);
  }

  const createBasicSurvey = (i: number) => {
    return (<>
      <div className="row">
        <div className="col-2">
          <label className="col-form-label">ประเภทคำตอบ</label>
        </div>
        <div className="col-6">
          <InputGroup hasValidation>
            <select id={i.toString()} className={"form-control " + ((getErrorSurveyType(i) != null) ? "is-invalid" : "")} onChange={e => setSurveyType(parseInt(e.currentTarget.id), e.target.value as SurveyType)} value={getSurveyType(i)} disabled={true}>
              {createSurveyType()}
            </select><br />
          </InputGroup>
          <div className={(getErrorSurveyType(i) != null) ? "invalid-feedback" : ""}>{getErrorSurveyType(i)}</div>

        </div>
        <div style={{ paddingTop: "10px" }}>
          <input type="checkbox" checked={getRequired(i)} onChange={(e) => { setRequired(i, e.currentTarget.checked) }} disabled={updating || success || answered} />
        </div>
        <div className="col-2" style={{ textAlign: "left" }}>
          <label className="col-form-label">จำเป็นต้องตอบ</label>
        </div>

      </div>
      <br />
      <div className='row'>
        <div className="col-2">
          <label className="col-form-label">กลุ่มคำถาม</label>
        </div>
        <div className="col-5" style={{ paddingBottom: "10px" }}>
          {/* <SingleSearch
            disabled={success || answered}
            isError={getErrorQuestionGroupId(i)}
            isSearchable={true} isMulti={false} isClearable={true} placeholder=""
            onChange={(id: string, e: any) => setQuestionGroup(e?.value, parseInt(e.currentTarget.id))}
            value={getQuestionGroup(i)}
            options={createQuestionGroupSearchFilter()}
          /> */}
          <InputGroup hasValidation>
            <select id={i.toString()} className={"form-control " + ((getErrorQuestion(i) != null) ? "is-invalid" : "")} value={getQuestionGroupId(i)} onChange={e => setQuestionGroupId(parseInt(e.currentTarget.id), e.target.value)} disabled={success || answered}>
              {createQuestionGroupSearchFilter(i)}
            </select>
            <div id={i.toString()} className={(getErrorQuestionGroupId(i) != null) ? "text-danger" : ""}><small>{getErrorQuestionGroupId(i)}</small></div>
          </InputGroup>

        </div>
      </div>
      <div className="row">
        <div className="col-2">
          <label className="col-form-label">คำถาม</label>
        </div>
        <div className="col-7">
          <InputGroup hasValidation>
            {/* <SingleSearch
              id={i.toString()}
              disabled={success || answered}
              isError={getErrorQuestion(i)}
              isSearchable={true} isMulti={false} isClearable={true} placeholder=""
              onChange={(id: string, e: any) => setQuestionId(e == null ? null : e?.value, parseInt(e.currentTarget.id))}
              value={getQuestionId(i)}
              options={createQuestionSearchFilter(i)}
            /> */}
            <select id={i.toString()} className={"form-control " + ((getErrorQuestion(i) != null) ? "is-invalid" : "")} value={getQuestionId(i)} onChange={e => setQuestionId(parseInt(e.currentTarget.id), e.target.value)} disabled={success || answered}>
              {createQuestionSearchFilter(i)}
            </select>
            <a id={i.toString()} onClick={(e) => handlePreviewQuestionBasic(parseInt(e.currentTarget.id))} className="link mx-2">ดูตัวอย่างคำถาม</a>
            <br />
          </InputGroup>

          <div className={(getErrorQuestion(i) != null) ? "text-danger" : ""}><small>{getErrorQuestion(i)}</small></div>
        </div>
      </div>
    </>)
  }

  const createRanking = (i: number) => {
    return (<>
      <div className="row">
        <div className="col-2">
          <label className="col-form-label">ประเภทคำตอบ</label>
        </div>
        <div className="col-4">
          <InputGroup hasValidation>
            <select id={i.toString()} className={"form-control " + ((getErrorSurveyType(i) != null) ? "is-invalid" : "")} onChange={e => setSurveyType(parseInt(e.currentTarget.id), e.target.value as SurveyType)} value={SurveyType.RANKING} disabled={success || answered}>
              {createSurveyType()}
            </select><br />
          </InputGroup>
          <div className={(getErrorSurveyType(i) != null) ? "invalid-feedback" : ""}>{getErrorSurveyType(i)}</div>
        </div>
        <div style={{ paddingTop: "10px" }}>
          <input type="checkbox" checked={getRequired(i)} onChange={(e) => { setRequired(i, e.currentTarget.checked) }} disabled={updating || success || answered} />
        </div>
        <div className="col-2" style={{ textAlign: "left" }}>
          <label className="col-form-label">จำเป็นต้องตอบ</label>
        </div>

        <div className="col-2" style={{ textAlign: "right" }}>
          <label className="col-form-label">เพิ่มคำตอบอื่นๆ</label>
        </div>
        <div className="col-1" style={{ paddingTop: "10px" }}>
          <input type="checkbox" checked={getOtherAnswer(i)} onChange={(e) => { setOtherAnswer(i, e.currentTarget.checked) }} disabled={updating || success || answered} />
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-2">
          <label className="col-form-label">คำถาม</label>
        </div>
        <div className="col-10">
          <InputGroup hasValidation>
            <input type="text" id={i.toString()} maxLength={200} className={"form-control " + ((getErrorQuestion(i) != null) ? "is-invalid" : "")} onChange={e => setQuestion(parseInt(e.currentTarget.id), e.target.value)} value={getQuestion(i)} disabled={success || answered} />
            <a id={i.toString()} onClick={(e) => handlePreviewQuestion(parseInt(e.currentTarget.id))} className="link mx-2">ดูตัวอย่างคำถาม</a>
          </InputGroup>

          <div className={(getErrorQuestion(i) != null) ? "text-danger" : ""}><small>{getErrorQuestion(i)}</small></div>
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-2" >
          <label className="col-form-label">คำตอบ</label>
        </div>
        <div className="col-10">
          {createAnswer(i, false)}
          {(!success && !answered) &&
            <div className="col-1" id={i.toString()} style={{ paddingTop: "0px", paddingLeft: "5px" }} onClick={(e) => { handleAddAnswer(parseInt(e.currentTarget.id.split("_")[0])) }}>
              <AddCircleOutlineIcon className="blue"></AddCircleOutlineIcon>
            </div>
          }
          <div className={(getErrorMainAnswer(i) != null) ? "text-danger" : ""}><small>{getErrorMainAnswer(i)}</small></div>
        </div>
      </div>
    </>);
  }

  const createText = (i: number) => {
    return (<>
      <div className="row">
        <div className="col-2">
          <label className="col-form-label">ประเภทคำตอบ</label>
        </div>
        <div className="col-6">
          <InputGroup hasValidation>
            <select id={i.toString()} className={"form-control " + ((getErrorSurveyType(i) != null) ? "is-invalid" : "")} onChange={e => setSurveyType(parseInt(e.currentTarget.id), e.target.value as SurveyType)} value={SurveyType.TEXT} disabled={success || answered}>
              {createSurveyType()}
            </select><br />
          </InputGroup>
          <div className={(getErrorSurveyType(i) != null) ? "invalid-feedback" : ""}>{getErrorSurveyType(i)}</div>
        </div>
        <div style={{ paddingTop: "10px" }}>
          <input type="checkbox" checked={getRequired(i)} onChange={(e) => { setRequired(i, e.currentTarget.checked) }} disabled={updating || success || answered} />
        </div>
        <div className="col-2" style={{ textAlign: "left" }}>
          <label className="col-form-label">จำเป็นต้องตอบ</label>
        </div>

      </div>
      <br />
      <div className="row">
        <div className="col-2">
          <label className="col-form-label">คำถาม</label>
        </div>
        <div className="col-10">
          <InputGroup hasValidation>
            <input type="text" id={i.toString()} maxLength={200} className={"form-control " + ((getErrorQuestion(i) != null) ? "is-invalid" : "")} onChange={e => setQuestion(parseInt(e.currentTarget.id), e.target.value)} value={getQuestion(i)} disabled={success || answered} />
            <a id={i.toString()} onClick={(e) => handlePreviewQuestion(parseInt(e.currentTarget.id))} className="link mx-2">ดูตัวอย่างคำถาม</a>
          </InputGroup>

          <div className={(getErrorQuestion(i) != null) ? "text-danger" : ""}><small>{getErrorQuestion(i)}</small></div>
        </div>
      </div>
    </>);
  }

  const createNumber = (i: number) => {
    return (<>
      <div className="row">
        <div className="col-2">
          <label className="col-form-label">ประเภทคำตอบ</label>
        </div>
        <div className="col-6">
          <InputGroup hasValidation>
            <select id={i.toString()} className={"form-control " + ((getErrorSurveyType(i) != null) ? "is-invalid" : "")} onChange={e => setSurveyType(parseInt(e.currentTarget.id), e.target.value as SurveyType)} value={SurveyType.NUMBER} disabled={success || answered}>
              {createSurveyType()}
            </select><br />
          </InputGroup>
          <div className={(getErrorSurveyType(i) != null) ? "invalid-feedback" : ""}>{getErrorSurveyType(i)}</div>
        </div>
        <div style={{ paddingTop: "10px", justifyContent: "right" }}>
          <input type="checkbox" checked={getRequired(i)} onChange={(e) => { setRequired(i, e.currentTarget.checked) }} disabled={updating || success || answered} />
        </div>
        <div className="col-2" style={{ textAlign: "left" }}>
          <label className="col-form-label">จำเป็นต้องตอบ</label>
        </div>

      </div>
      <br />
      <div className="row">
        <div className="col-2">
          <label className="col-form-label">คำถาม</label>
        </div>
        <div className="col-10">
          <InputGroup hasValidation>
            <input type="text" maxLength={200} id={i.toString()} className={"form-control " + ((getErrorQuestion(i) != null) ? "is-invalid" : "")} onChange={e => setQuestion(parseInt(e.currentTarget.id), e.target.value)} value={getQuestion(i)} disabled={success || answered} />
            <a id={i.toString()} onClick={(e) => handlePreviewQuestion(parseInt(e.currentTarget.id))} className="link mx-2">ดูตัวอย่างคำถาม</a>
          </InputGroup>

          <div className={(getErrorQuestion(i) != null) ? "text-danger" : ""}><small>{getErrorQuestion(i)}</small></div>
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-2">
          <label className="col-form-label">คำตอบ</label>
        </div>
        <div className="col-10">
          {createAnswer(i, true)}
          {(!success && !answered) &&
            <div className="col-1" id={i.toString()} style={{ paddingTop: "0px", paddingLeft: "5px" }} onClick={(e) => { handleAddAnswer(parseInt(e.currentTarget.id.split("_")[0])) }}>
              <AddCircleOutlineIcon className="blue"></AddCircleOutlineIcon>
            </div>
          }
          <div className={(getErrorMainAnswer(i) != null) ? "text-danger" : ""}><small>{getErrorMainAnswer(i)}</small></div>
        </div>
      </div>
    </>);
  }

  const createSurvey = () => {
    var res: any[] = [];
    for (var i: number = 0; i < ((item.survey !== undefined) ? item.survey.length : 0); i++) {
      var s = item.survey[i];
      res.push(<div className="light-gray-button" style={{ position: "relative", padding: "20px", textAlign: "left", justifyContent: "left", borderRadius: "5px" }}><br />
        <div className='row'>
          <div className='col-2'>
            <label className="col-form-label">เลือกใช้คำถามที่มีอยู่ในระบบ</label>
          </div>
          <div className="col-1">
            <label className="col-form-label">
              <input id={i.toString()} type="radio" checked={getQuestionType(i) === QuestionType.BASIC} onChange={(e) => { setQuestionType(parseInt(e.currentTarget.id), QuestionType.BASIC) }} disabled={success || answered} /> ใช้
            </label>

          </div>
          <div className="col-1">
            <label className="col-form-label">
              <input id={i.toString()} type="radio" checked={getQuestionType(i) === QuestionType.CUSTOM} onChange={(e) => { setQuestionType(parseInt(e.currentTarget.id), QuestionType.CUSTOM) }} disabled={success || answered} /> ไม่ใช้
            </label>

          </div>

        </div>

        {getQuestionType(i) === QuestionType.CUSTOM && (
          <>
            {(!s.surveyType) && createUndefinedChoice(i)}
            {(s.surveyType === SurveyType.CHOICE) && createChoice(i)}
            {(s.surveyType === SurveyType.MULTIPLE) && createMultiple(i)}
            {(s.surveyType === SurveyType.DEGREE) && createDegree(i)}
            {(s.surveyType === SurveyType.RANKING) && createRanking(i)}
            {(s.surveyType === SurveyType.TEXT) && createText(i)}
            {(s.surveyType === SurveyType.NUMBER) && createNumber(i)}
          </>)}
        {getQuestionType(i) === QuestionType.BASIC && (
          <>
            {createBasicSurvey(i)}
          </>
        )}

        <div style={{ fontSize:"20px", position: "absolute", top: "-25px", right: "10px", zIndex: 0 }}>
          Question {i + 1}
        </div>
        {(!success && !answered) &&
          <div className="search-icon" id={i.toString()} style={{ position: "absolute", right: "5px", top: "5px", zIndex: 10 }} onClick={e => { handleDeleteSurvey(parseInt(e.currentTarget.id as string)) }}>
            <RemoveCircleOutlineIcon className="red" ></RemoveCircleOutlineIcon>
          </div>
        }
        {((i > 0) && (!success) && (!answered)) &&
          <div className="search-icon" id={i.toString()} style={{ cursor: "pointer", background: "white", position: "absolute", right: "-30px", top: "30px", zIndex: 10 }} onClick={e => { handleMoveUpSurvey(parseInt(e.currentTarget.id as string)) }}>
            <ExpandLessIcon></ExpandLessIcon>
          </div>
        }
        {((i < item.survey.length - 1) && (!success) && (!answered)) &&
          <div className="search-icon" id={i.toString()} style={{ cursor: "pointer", background: "white", position: "absolute", right: "-30px", top: "60px", zIndex: 10 }} onClick={e => { handleMoveDownSurvey(parseInt(e.currentTarget.id as string)) }}>
            <ExpandMoreIcon  ></ExpandMoreIcon>
          </div>
        }
      </div>);
      res.push(<br />);
    }
    return res;
  }

  return (<>
    <MainMenu>
      <div>
        <div className="query-wrapper">
          <br />
          <div className="yellow-button" style={{ textAlign: "left", borderTopLeftRadius: "30px", borderTopRightRadius: "30px", height: "70px", paddingTop: "15px", paddingLeft: "15px" }}><h4 className="header">แบบสอบถาม</h4></div>
          {(init + initProject + initOrganizer + initQuestionGroup === 4) &&
            <>
              <div className="light-gray-button" style={{ borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px', paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", textAlign: "left" }}>
                <br />      
                <div className="row">
                  <div className="col-2">
                    <label className="col-form-label">ชื่อแบบประเมินผลที่แสดง<span className='red'>*</span></label>
                  </div>
                  <div className="col-10">
                    <InputGroup hasValidation>
                      <Form.Control maxLength={200} className="form-control" type="text" onChange={(e: any) => setNickname(e.target.value)} value={getNickName()}
                        isInvalid={(getErrorNickname() != null)} disabled={success} />
                      <Form.Control.Feedback type="invalid">{getErrorNickname()}</Form.Control.Feedback>
                    </InputGroup>
                  </div>
                </div>
                <br/>
                <div className="row">
                  <div className="col-2">
                    <label className="col-form-label">ชื่อที่แสดงในแบบประเมินผล<span className='red'>*</span></label>
                  </div>
                  <div className="col-10">
                    <InputGroup hasValidation>
                      <Form.Control maxLength={200} className="form-control" type="text" onChange={(e: any) => setName(e.target.value)} value={getName()}
                        isInvalid={(getErrorName() != null)} disabled={success} />
                      <Form.Control.Feedback type="invalid">{getErrorName()}</Form.Control.Feedback>
                    </InputGroup>
                  </div>
                </div>
                <br/>
                <div className="row">
                  <div className="col-2">
                    <label className="col-form-label">คำอธิบายแบบประเมินผล<span className='red'>*</span></label>
                  </div>
                  <div className="col-10">
                    <InputGroup hasValidation>
                      <textarea rows={5} maxLength={1000} className={"form-control " + ((getErrorDescription()) ? "is-invalid" : "")} onChange={(e: any) => setDescription(e.target.value)}
                        disabled={success} value={getDescription()}></textarea>
                      <Form.Control.Feedback type="invalid">{getErrorDescription()}</Form.Control.Feedback>
                    </InputGroup>
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col-2">
                    <label className="col-form-label">ระดับการเข้าถึง<span className='red'>*</span></label>
                  </div>
                  <div className="col-10">
                    {
                      /*<div className="row">
                          <div className="col-12">
                          <input type="radio" value={SurveyPermissionType.PRIVATE} checked={getPermission()===SurveyPermissionType.PRIVATE} name="permission" onChange={(e)=>{setPermission(SurveyPermissionType.PRIVATE)}} disabled={updating || success}/> Private
                          </div>
                      </div>*/
                    }
                    <br />
                    <div className="row">
                      <div className="col-12">
                        <input type="radio" value={SurveyPermissionType.PUBLIC} checked={getPermission() === SurveyPermissionType.PUBLIC} name="permission" onChange={(e) => { setPermission(SurveyPermissionType.PUBLIC) }} disabled={updating || success} /> Public
                      </div>
                    </div>
                    <br />
                    <div className="row">
                      <div className="col-3">
                        <input type="radio" value={SurveyPermissionType.PROJECT} checked={getPermission() === SurveyPermissionType.PROJECT} name="permission" onChange={(e) => { setPermission(SurveyPermissionType.PROJECT) }} disabled={updating || success} /> โครงการ
                      </div>
                      <div className="col-9">
                        <SingleSearch isError={getErrorProjectId() !== null} isSearchable={true} isMulti={false} placeholder=""
                          onChange={(id: string, e: any) => setProjectId(e.value)}
                          value={getProjectId()}
                          options={createProjectIdFilter()}
                          disabled={success || (getPermission() !== SurveyPermissionType.PROJECT)}
                          style={{ width: "300px" }}
                        />
                      </div>
                    </div>
                    <br />
                    <div className="row">
                      <div className="col-3">
                        <input type="radio" value={SurveyPermissionType.ORGANIZER} checked={getPermission() === SurveyPermissionType.ORGANIZER} name="permission" onChange={(e) => { setPermission(SurveyPermissionType.ORGANIZER) }} disabled={updating || success} /> ฝ่ายผู้รับผิดชอบโครงการ
                      </div>

                      <div className="col-9">
                        <SingleSearch isError={getErrorOrganizerId() !== null} isSearchable={true} isMulti={false} placeholder=""
                          onChange={(id: string, e: any) => setOrganizer(e.value)}
                          value={getOrganizer()}
                          options={createOrganizerFilter()}
                          disabled={success || (getPermission() !== SurveyPermissionType.ORGANIZER)}
                          style={{ width: "300px" }}
                        />
                      </div>
                    </div>
                    <div className={(getErrorPermission() !== "") ? "text-danger" : ""}><small>{getErrorPermission()}</small></div>
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col-1">
                    <label className="col-form-label">Logo (300x150px)</label>
                  </div>
                  {(getLogoURL() !== null) &&
                    <div className="col-1 text-right">
                      <img src={getLogoURL()} height="64px" width="64px" alt="" />
                    </div>
                  }
                  {(getLogoURL() === null) &&
                    <div className="col-1 text-right" style={{ paddingTop: "5px" }}>
                      <ImageIcon></ImageIcon>
                    </div>
                  }
                  <div className="col-10">
                    <InputGroup hasValidation>
                      <input type="file" className={"form-control " + ((getErrorLogoFile() !== "") ? "is-invalid" : "")} accept="image/jpeg, image/png" id="contained-button-file" onChange={(e: any) => setLogoFileChange(e.target.files)} disabled={updating || success} />
                    </InputGroup>
                    <div className={(getErrorLogoFile() !== "") ? "text-danger" : ""}><small>{getErrorLogoFile()}</small></div>
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col-1">
                    <label className="col-form-label">Header (1360x150px)</label>
                  </div>
                  {(getHeaderURL() !== null) &&
                    <div className="col-1 text-right">
                      <img src={getHeaderURL()} height="64px" width="64px" alt="" />
                    </div>
                  }
                  {(getHeaderURL() === null) &&
                    <div className="col-1 text-right" style={{ paddingTop: "5px" }}>
                      <ImageIcon></ImageIcon>
                    </div>
                  }
                  <div className="col-10">
                    <InputGroup hasValidation>
                      <input type="file" className={"form-control " + ((getErrorHeaderFile() !== "") ? "is-invalid" : "")} accept="image/jpeg, image/png" id="contained-button-file" onChange={(e: any) => setHeaderFileChange(e.target.files)} disabled={updating || success} />
                    </InputGroup>
                    <div className={(getErrorHeaderFile() !== "") ? "text-danger" : ""}><small>{getErrorHeaderFile()}</small></div>
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col-2">
                    <label className="col-form-label">ข้อความขอบคุณ</label>
                  </div>
                  <div className="col-10">
                    <InputGroup hasValidation>
                      <textarea rows={5} maxLength={250} className={"form-control"} onChange={(e: any) => setThankYou(e.target.value)}
                        disabled={success} value={getThankYou()}></textarea>
                    </InputGroup>
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col-2">
                    <label className="col-form-label">Power BI URL</label>
                  </div>
                  <div className="col-10">
                    <InputGroup hasValidation>
                      <Form.Control maxLength={200} className="form-control" type="text" onChange={(e: any) => setPowerBiUrl(e.target.value)} value={getPowerBiUrl()}
                        disabled={success} />
                    </InputGroup>
                  </div>
                </div>
                {/* <div className="row">
                  <div className="col-2">
                    <label className="col-form-label">เลือกคำถามพื้นฐาน</label>
                  </div>
                  <div className="col-10">
                    <input type="checkbox" checked={getBasicSurvey(1)} value="1" onChange={(e) => { setBasicSurvey(1, e.currentTarget.checked) }} disabled={updating || success} />&nbsp;ประเมินความพึงพอใจหลักสูตร<br />
                    <input type="checkbox" checked={getBasicSurvey(2)} value="1" onChange={(e) => { setMultipleBasicSurvey([2, 3, 4], e.currentTarget.checked); }} disabled={updating || success} />&nbsp;ประเมินวิทยากร (รวมถึงวิทยากรผู้ช่วย ถ้ามี)<br />
                    <input type="checkbox" checked={getBasicSurvey(5)} value="1" onChange={(e) => { setBasicSurvey(5, e.currentTarget.checked) }} disabled={updating || success} />&nbsp;ประเมินรูปแบบการเรียนรู้และระยะเวลา<br />
                    <input type="checkbox" checked={getBasicSurvey(6)} value="1" onChange={(e) => { setBasicSurvey(6, e.currentTarget.checked) }} disabled={updating || success} />&nbsp;ประเมินเจ้าหน้าที่จัดอบรม<br />
                  </div>
                </div> */}
              </div>
              <br />
              <div style={{ background: "white", padding: "40px", borderRadius: "5px" }}>
                {createSurvey()
                }
                <div className={(getErrorSurvey() != null) ? "text-danger" : ""}><small>{getErrorSurvey()}</small></div>
                <br />
                {(!success && !answered) &&
                  <Button type="button" className="btn btn-warning yellow-button"
                    onClick={() => handleAddSurvey()} >
                    <AddIcon />
                    เพิ่มคำถาม
                  </Button>
                }
              </div>
            </>
          }
          <br />
          {(((init + initProject + initOrganizer + initQuestionGroup < 4) || (loading) || (updating)) && (error === "")) &&
            <div style={{ textAlign: "center" }}><img src={loadinglogo} width="30" height="30" className="d-inline-block align-top" alt="" /></div>
          }
          {(error !== "") &&
            <div style={{ color: 'red', textAlign: "center" }}>{error}</div>
          }
          {(init + initProject + initOrganizer + initQuestionGroup === 4) && (!loading) && (!success) && (!updating) &&
            <div style={{ justifyContent: "right", textAlign: "right" }}>
              <button type="button" className="btn btn-outline-primary" style={{ width: "120px" }}
                onClick={() => handleBack()} >
                Back
              </button>&nbsp;
              {(id !== undefined) && (!answered) &&
                <><button type="button" className="btn btn-danger" style={{ width: "120px" }}
                  onClick={() => handleDelete()} >
                  Delete
                </button>&nbsp;</>
              }
              <button type="button" className="btn btn-warning" style={{ width: "120px" }}
                onClick={() => handleClear()} >
                Reset
              </button>&nbsp;
              <button type="button" className="btn btn-success" style={{ width: "120px" }}
                onClick={() => handleSubmit()} >
                Save
              </button>&nbsp;
              {((id !== undefined) && (edit === false)) &&
                <button type="button" className="btn purple-button" style={{ width: "120px" }}
                  onClick={() => handlePreview()} >
                  Preview
                </button>
              }
            </div>
          }
          {(success) &&
            <div style={{ justifyContent: "center", textAlign: "center" }}>
              <button type="button" className="btn btn-outline-primary" style={{ width: "120px" }}
                onClick={() => handleClose()} >
                Back
              </button>&nbsp;
              <button type="button" className="btn yellow-button" style={{ width: "120px" }}
                onClick={() => handleReedit()} >
                Edit
              </button>&nbsp;

              {(item.id !== undefined) &&
                <button type="button" className="btn purple-button" style={{ width: "120px" }}
                  onClick={() => handlePreview()} >
                  Preview
                </button>
              }
            </div>
          }
        </div>
        <br />
        <Footer />
      </div>
    </MainMenu>
    <Modal show={leave !== ""} onHide={() => { setLeave("") }}>
      <Modal.Header closeButton>
        <Modal.Title>ยกเลิกข้อมูลที่แก้ไข?</Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => { setLeave("") }}>
          Cancel
        </Button>
        <Button variant="danger" onClick={() => { handleLeave() }}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>

    <Modal show={deleteId !== ""} onHide={handleDeleteClose}>
      <Modal.Header closeButton>
        <Modal.Title>ยืนยันการลบ?</Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleDeleteClose}>
          Cancel
        </Button>
        <Button variant="danger" onClick={handleDeleteConfirm}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
    <PreviewQuestionModal backdrop="static" show={modalShow != undefined} onHide={() => setModalShow(undefined)} onOk={() => { }} editItem={modalItem} />
    <AlertContainer floatingTime={10000} />
  </>)
}

export default EditSurveyGroup;
