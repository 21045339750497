import React, { useEffect, useState } from 'react'
import { AlertContainer, alert } from 'react-custom-alert';
import MainMenu from './menu';
import ProjectTab from './projecttab';
import { useDispatch, useStore } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { queryclear } from '../../store/search/actions';
import { SearchPanel, TableHeaderBlack } from '../../style/tableStyle';
import loadinglogo from '../../img/loading.gif';
import { useForm } from 'react-hook-form';
import { deleteProjectGroup, getProjecGroupInfo } from '../../store/editProjectGroup/action';
import { ProjectGroupResponse } from '../../model/response/projectGroupResponse.model';
import { CustomPage } from '../share/CustomPage';
import Sort from '../share/Sort';
import { Util } from '../../api/util';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import { Button, Modal } from 'react-bootstrap';
import EditProjectGroupModal from './editProjectGroupModal';
import CancelIcon from '@material-ui/icons/Cancel';

type Props = {}

const EditProjectGroupInfo = (props: Props) => {
    const [init, setInit] = useState(0);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [modalShow, setModalShow] = useState<boolean | undefined>();
    const [modalItem, setModalItem] = useState<ProjectGroupResponse>();
    const [projectGroupItem, setProjectGroupItem] = useState<ProjectGroupResponse[]>([]);
    const [deleteId, setDeleteId] = useState<string>("");
    const store = useStore();
    const history = useHistory();
    const dispatch = useDispatch();
    const [page, setPage] = useState<number>(0);
    const [size, setSize] = useState<number>(10);
    const [sortBy, setSortBy] = useState<string | undefined>();
    const [sortType, setSortType] = useState<'asc' | 'desc'>('desc');
    const [totalElement, setTotalElwment] = useState<number>(0);
    const { control, handleSubmit, formState: { errors }, reset, getValues, register } = useForm();
    const handleDoc = () => {
        history.push("/admin/project/doc");
    }
    const handleProject = () => {
        history.push("/admin/project/info");
    }
    const handleProjectGroup = () => {
        history.push("/admin/project-group/info");
    }
    useEffect(() => {
        const unsubscribe = store.subscribe(() => {
            let res = store.getState().SearchReducer;
            if (res.isLoading) {
                setError('');
                setLoading(true);
            } else if ((res.isLoaded) && (!res.isUpdated)) {
                setError('');
                setLoading(false);
                if (res.data != null) {
                    if (res.table === "project_group_info_item") {
                        setProjectGroupItem(res.data.elements);
                        setTotalElwment(res.data.totalElements);
                        setInit(1);
                    }

                }
            } else if (res.isUpdating) {
                setError('');
            } else if (res.isUpdated) {
                setError('');
                dispatch(queryclear());
                alert({ message: 'Saved', type: 'success' });
            } else if (res.isFailed) {
                setError(res.data.message);
                setLoading(false);
                alert({ message: res.data.message, type: 'error' });
            }

        })
        removeSession();
        return unsubscribe;
    }, []);

    useEffect(() => {

        dispatch(getProjecGroupInfo(undefined, page, size, undefined))
    }, []);


    const removeSession = () => {
        sessionStorage.removeItem("searchDetails");
        sessionStorage.removeItem("searchPartnerDetails");
        sessionStorage.removeItem("searchSurveyDetails");
        sessionStorage.removeItem("searchTrainerDetails");
        sessionStorage.removeItem("searchManageQuestion");
        sessionStorage.removeItem("searchLogRequest");
        sessionStorage.removeItem("searchLogRequestHistory");
    }

    const onSubmit = (data: any) => {
        setPage(0);
        setSortBy(undefined);
        dispatch(queryclear());
        dispatch(getProjecGroupInfo(data?.keyword, 0, size, undefined))
    }
    const refresh = (e: any) => {
        setModalShow(undefined);
        setModalItem(undefined);
        setPage(0);
        setSortBy(undefined);
        dispatch(queryclear());
        const searchParam = getValues();
        dispatch(getProjecGroupInfo(searchParam?.key, 0, size, undefined))
    }
    const handleClear = () => {
        reset();
        setPage(0);
        setSortBy(undefined);
        setModalItem(undefined);
        dispatch(queryclear());
        dispatch(getProjecGroupInfo(undefined, 0, size, undefined))

    }
    const onSort = (sortName: string, newSortType: 'asc' | 'desc') => {
        setSortBy(sortName);
        setSortType(newSortType);
        const searchParam = getValues();
        dispatch(getProjecGroupInfo(searchParam?.keyword, page, size, sortName + ',' + newSortType));
    }
    const onPageChange = (value: number) => {
        setPage(value);
        let sort = sortBy ? sortBy + ',' + sortType : undefined;
        const searchParam = getValues();
        dispatch(getProjecGroupInfo(searchParam?.keyword, value, size, sort));
    }
    const onPageSizeChange = (value: number) => {
        setPage(0);
        setSize(value);
        let sort = sortBy ? sortBy + ',' + sortType : undefined;
        const searchParam = getValues();
        dispatch(getProjecGroupInfo(searchParam?.keyword, 0, value, sort));
    }

    const handleEdit = (item: ProjectGroupResponse) => {
        setModalItem(item);
        setModalShow(true);

    }
    const handleAdd = () => {
        setModalItem(undefined);
        setModalShow(true);
    }
    const handleDelete = (id: number) => {
        setDeleteId(id.toString());
    }

    const handleDeleteClose = () => {
        setDeleteId("");
    }

    const handleDeleteConfirm = () => {
        dispatch(queryclear());
        dispatch(deleteProjectGroup(parseInt(deleteId)));
        setDeleteId("");
    }
    const addElement: any = () => {
        return <>
            <Button type="button" className="btn btn-warning yellow-button" style={{ marginInline: 10 }}
                onClick={() => handleAdd()} >
                <AddIcon />
                เพิ่มโครงการ
            </Button>
        </>
    }


    return (
        <div>
            <MainMenu>
                <div>
                    <div className="query-wrapper">
                        <br />
                        <div className="yellow-button" style={{ textAlign: "left", borderTopLeftRadius: "30px", borderTopRightRadius: "30px", height: "70px", paddingTop: "15px", paddingLeft: "15px" }}><h4 className="header">การจัดการข้อมูลรายโครงการ</h4></div>
                        {(init === 1) &&
                            <>
                                <br />
                                {ProjectTab(3, true, handleDoc, handleProject, handleProjectGroup)}
                                <div style={{ background: "white", padding: "15px", borderRadius: "5px" }}>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <SearchPanel>
                                            <div className='row'>
                                                <div className='col-4'>
                                                    <label className="col-form-label">ชื่อย่อ/ชื่อกลุ่มโครงการ</label>
                                                    <input {...register("keyword")} className={'form-control'} type="text" name="keyword" id="keyword" />
                                                </div>
                                            </div>
                                            <div className="row mt-3" style={{ justifyContent: "center" }}>
                                                <button type="button" className="btn btn-outline-primary" style={{ width: "150px" }}
                                                    onClick={() => handleClear()} >
                                                    Clear
                                                </button>&nbsp;
                                                <button type="submit" className="btn btn-primary" style={{ width: "150px" }}
                                                    disabled={loading}>
                                                    <div>
                                                        {(loading) &&
                                                            <div style={{ textAlign: "center" }}><img src={loadinglogo} width="30" height="30" className="d-inline-block align-top" alt="" /></div>
                                                        }
                                                        {(!loading) &&
                                                            <>Search</>
                                                        }
                                                    </div>
                                                </button>
                                            </div>
                                        </SearchPanel>
                                    </form>
                                </div>
                                <CustomPage current={page} pageSize={size} total={totalElement} onPageChange={onPageChange} onPageSizeChange={onPageSizeChange} loading={loading && (modalShow !== true)} postElement={addElement()}>
                                    <TableHeaderBlack striped hover className='mt-2'>
                                        <thead>
                                            <tr>
                                                <th className='text-left' style={{ verticalAlign: "top" }}>
                                                    <Sort label='ชื่อย่อกลุ่มโครงการ' currentSort={sortBy} sortName='shortName' handleClick={onSort} sortType={sortType} />
                                                </th>
                                                <th className='text-left' style={{ verticalAlign: "top" }}>
                                                    <Sort label='ชื่อกลุ่มโครงการ' currentSort={sortBy} sortName='name' handleClick={onSort} sortType={sortType} />
                                                </th>
                                                <th className='text-left' style={{ verticalAlign: "top" }}>
                                                    ชื่อกลุ่มโครงการที่แสดงผล
                                                </th>
                                                <th style={{ verticalAlign: "top" }}>
                                                    <Sort label='วันที่สร้าง' currentSort={sortBy} sortName='createDate' handleClick={onSort} sortType={sortType} />
                                                </th>
                                                <th style={{ verticalAlign: "top" }}>
                                                    <Sort label='แก้ไขล่าสุด' currentSort={sortBy} sortName='updateDate' handleClick={onSort} sortType={sortType} />
                                                </th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {projectGroupItem && projectGroupItem.map((item: ProjectGroupResponse, index: number) => (
                                                <tr key={'projectGroupTable' + item.id}>
                                                    <td className='text-left'>{item.shortName}</td>
                                                    <td className='text-left'>{item.name}</td>
                                                    <td className='text-left'>{item.displayName}</td>
                                                    <td>{Util.datetimetostr(new Date(item.createDate))}</td>
                                                    <td>{Util.datetimetostr(new Date(item.updateDate))}</td>
                                                    <td>
                                                        <div className='row'>
                                                            <div onClick={(e) => handleEdit(item)}><EditIcon className="yellow pointer" ></EditIcon></div>&nbsp;
                                                            <div onClick={(e) => handleDelete(item.id)}><CancelIcon className="red pointer"></CancelIcon></div>
                                                        </div>

                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </TableHeaderBlack>
                                </CustomPage>
                                <br />
                            </>
                        }
                        {(((init < 1) || (loading) && (modalShow !== true)) && (error === "")) &&
                            <div style={{ textAlign: "center" }}><img src={loadinglogo} width="30" height="30" className="d-inline-block align-top" alt="" /></div>
                        }
                        {(error !== "") &&
                            <div style={{ color: 'red', textAlign: "center" }}>{error}</div>
                        }
                    </div>
                </div>
            </MainMenu>
            <Modal show={deleteId !== ""} onHide={handleDeleteClose}>
                <Modal.Header closeButton>
                    <Modal.Title>ยืนยันการลบ?</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleDeleteClose}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleDeleteConfirm}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
            <EditProjectGroupModal backdrop="static" show={modalShow != undefined} onHide={() => setModalShow(undefined)} onOk={refresh} editItem={modalItem} />
            <AlertContainer floatingTime={3000} />
        </div>
    )
}

export default EditProjectGroupInfo