import { useEffect, useState } from 'react';
import { useSelector, useDispatch, useStore } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import loadinglogo from '../../img/loading.gif';
import logob from '../../img/logob.png';
import classcourseicon from '../../img/classcourseicon.png';
import classicon from '../../img/classicon.png';
import knowledgeicon from '../../img/knowledge.png';
import k1icon from '../../img/k1.png';
import k2icon from '../../img/k2.png';
import k3icon from '../../img/k3.png';
import k4icon from '../../img/k4.png';
import k5icon from '../../img/k5.png';
import knowledge1icon from '../../img/knowledge1.png';
import knowledge2icon from '../../img/knowledge2.png';
import knowledge3icon from '../../img/knowledge3.png';
import announceImage from '../../img/landingProject.png';
import knext from '../../img/knext.png';
import { landing } from '../../store/studentlanding/actions';
import { queryclear } from '../../store/search/actions';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import StudentHeader from './studentheader';
import StudentFooter from './studentfooter';
import { Util } from '../../api/util';
import { GO_ELEARNING_URL } from '../../api/page-student-api';
import { getAccessToken, getIsStudent, getRefreshToken, getIsLoggedIn } from '../../store/auth/selectors';
import styled from 'styled-components';
import { AnnouceResponse } from '../../model/response/announce.model';
import StudentLandingAnnounceModal from './studentLandingAnnounceModal';
import noPhoto from '../../img/noPhoto.png';
import { checkServerIdentity } from 'tls';
const CardAnnounce = styled.div`
    border-radius: 5px;
    border: 1px solid rgb(255 255 255 / 13%);
    img.card-img-top{
        height: 196px;
        filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.1));
        border-radius: 5px 5px 0px 0px;
    }
    .card-body{
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
        border-radius: 0px 0px 5px 5px;

    }
    .title-orange{
        font-size: 1.2rem;
        color: #FCB034;
        font-weight: 800;
    }
    .card-text{
        font-size: 0.95rem;
        color: #5F6062;
        display: -webkit-box;
        max-width: 400px;
        min-height: 46px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    span.see-more{
        font-size: 0.95rem;
        color: black;
        font-weight: 800;
        
        &:hover{
            cursor: pointer;
            text-decoration: underline;
        }
    }
`
const KnowledgeCard = styled.div`
    box-shadow: 5px 5px 5px 2px lightgray;
    &:hover {
        cursor: pointer;
        opacity: 0.7;
        transition: opacity 0.3s ease-in-out;
    }
    
    
`
const ImageKnowledgeCard = styled.img`
    &:hover {
        cursor: pointer;
        opacity: 0.7;
        transition: opacity 0.3s ease-in-out;
    }
`


const StudentLanding = (props:any) => {
    const accessToken = useSelector(getAccessToken);
    const sessionToken = useSelector(getRefreshToken);
    const isStudent = useSelector(getIsStudent);
    const isLoggedIn = useSelector(getIsLoggedIn);
    const redirectPath = props.pathname;
    const isRedirect = props.isRedirect;
    const [init, setInit] = useState(0)
    const [items, setItems] = useState<any>({})
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('');
    const [modalShowAnnounce, setModalShowAnnounce] = useState<AnnouceResponse | undefined>(undefined);
    const store = useStore();
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        const unsubscribe = store.subscribe(() => {
            let res = store.getState().SearchReducer;
            if (res.isLoading) {
                setError('');
                setLoading(true);
            } else if ((res.isLoaded) && (!res.isUpdated)) {
                setError('');
                setLoading(false);
                if (res.data != null) {
                    if (res.table === "landing") {
                        setItems(res.data);
                        setInit(1);
                        if (res.data.classCourse.length > 0) {
                            if ((isLoggedIn) && (isStudent)) {
                                handleAssignment(parseInt(res.data.classCourse[0].classId))
                            }

                        }
                    }
                }
            } else if (res.isFailed) {
                setError(res.data.message);
                setLoading(false);
            }
        })
        console.log(isRedirect)
        if(isRedirect){
            checkRedirectIsRedirect()
        }
        dispatch(queryclear());
        dispatch(landing("", (isLoggedIn) && (isStudent)));
        return unsubscribe;
    }, [])

    const checkRedirectIsRedirect = () => {
        sessionStorage.setItem('redirectPath',redirectPath);  
    }

    const handleAssignment = (classId: number) => {
        // history.push("/student/assignment/" + classId);
    }

    const handleELearning = (classCourseId: number) => {
        window.open(GO_ELEARNING_URL + "/" + classCourseId + "?token=" + accessToken + "&session=" + sessionToken, '_blank');
    }


    const createCard = (classId: number, classCourseId: number, img: string, title: string, from: Date, to: Date, coming: boolean, elearning: boolean) => {
        return (
            <>
                <Col md="3">
                    <div className="text-center justify-content-center" style={{ display: "flex", height: "320px", width: "100%" }}>
                        <div id={classId.toString()} className="white-button" style={{ textAlign: "left", maxWidth: "240px", height: "300px", borderRadius: "5px", padding: "5px", cursor: "pointer", boxShadow: "5px 5px 5px 2px lightgray" }}
                            onClick={e => {
                                    handleAssignment(parseInt(e.currentTarget.id))
                            }}>
                            <img src={img === null ? logob : img} width="100%" />
                            <div style={{ padding: "10px" }}>{title}</div>
                            {(coming) &&
                                <div style={{ padding: "10px", fontSize: "8pt", bottom: "20px", position: "absolute" }}>เริ่ม: {Util.datetostr(from)}</div>
                            }
                            {(!coming) &&
                                <div style={{ padding: "10px", fontSize: "8pt", bottom: "20px", position: "absolute" }}>เรียน: {Util.datetostr(from)} - {Util.datetostr(to)}</div>
                            }
                        </div>
                    </div>
                </Col>
            </>
        );
    }

    const createKnowledgeCard = (img: any, title: string, msg: string, url: string) => {
        return (
            <>
                <KnowledgeCard onClick={() => window.open(url, '_blank')} className="text-left" style={{ position: "relative", height: "320px", paddingTop: "20px", paddingLeft: "30px", paddingRight: "30px", border: "solid", borderColor: "orange", borderRadius: "3px", borderTopWidth: "3px", borderLeftWidth: "3px", borderRightWidth: "3px", borderBottomWidth: "10px" }}>
                    <img src={img} alt="know" style={{ marginLeft: "-5px", width: "95%", maxWidth: "200px", maxHeight: "150px" }} /><br />
                    <span style={{ color: "orange", fontSize: "15pt" }}>{title}</span><br />
                    {msg}
                    <br /><br />
                    <div className="row text-right" style={{ display: "flex", justifyContent: "right", position: "absolute", right: "20px", bottom: "10px" }}>
                        <span className='more'>ดูเพิ่มเติม</span><img src={knext} height="60px" alt="next" style={{ marginTop: "-15px" }} />
                    </div>
                </KnowledgeCard>
                <br /><br />
            </>
        );
    }


    const createBanner = () => {
        var res: any[] = [];
        for (var i = 0; i < items.banner.length; i++) {
            res.push(<div key={'banner' + i} id={'banner-'+i} className="each-slide-effect light-gray-button"
                onClick={(e) => handleClickBanner(e.currentTarget.id)}
                style={{ cursor: items.banner[i]?.externalLink ? 'pointer' : 'context-menu' }} >
                <img src={items.banner[i].banner} style={{ width: "100%" }} alt="" />
            </div>)
        }
        return res;
    }
    const handleClickBanner = (index: string) => {
        let newIndex = index.replace('banner-','');
        let externalLink = items.banner[newIndex]?.externalLink
        if (externalLink) window.open(externalLink, '_blank')
    }

    const openAnnouce = (item: AnnouceResponse) => {
        setModalShowAnnounce(item);
    }

    return (<><div className="App-student">
        <div className="student-wrapper">
            <div className="student-wrapper-header-inner" style={{ background: "#38383D" }}>
                <StudentHeader />
                <div className="student-wrapper-inner" style={{ background: "white" }}>
                </div>
                {(((init === 0) || (loading)) && (error === "")) &&
                    <div style={{ textAlign: "center" }}><img src={loadinglogo} width="30" height="30" className="d-inline-block align-top" alt="" /></div>
                }
                {(error !== "") &&
                    <div style={{ color: 'red', textAlign: "center" }}>{error}</div>
                }
            </div>
            {(init === 1) &&
                <>
                    <div className="student-wrapper-inner">
                        <div style={{ width: "100%", display: 'flex', alignItems: 'center', justifyContent: "center", textAlign: "center" }}>
                            <div style={{ width: "100%" }}>
                                <Slide prevArrow={<div style={{
                                    marginLeft: "20px", borderRadius: "50%",
                                    height: "40px",
                                    width: "40px",
                                    backgroundColor: "rgb(255,255,255,0.5)"
                                }}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" style={{ marginLeft: "8px", marginTop: "8px" }}>
                                        <path style={{ fill: "white" }} d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
                                    </svg>
                                </div>}

                                    nextArrow={<div style={{
                                        marginRight: "20px", borderRadius: "50%",
                                        height: "40px",
                                        width: "40px",
                                        backgroundColor: "rgb(255,255,255,0.5)"
                                    }}>
                                        <svg width="24" height="24" viewBox="0 0 24 24" style={{ marginLeft: "8px", marginTop: "8px" }}>
                                            <path style={{ fill: "white" }} d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
                                        </svg>
                                    </div>}>
                                    {
                                        createBanner()
                                    }
                                </Slide>
                            </div>
                        </div>
                    </div>
                    <div className="student-wrapper-inner">
                        <div className="yellow-button text-center mt-4" style={{ width: "160px", borderTopLeftRadius: "5px", borderTopRightRadius: "5px", fontFamily: 'DB Helvethaica Bold', zIndex: 999 }}>
                            ประชาสัมพันธ์
                        </div>

                        <div className="text-left white-button" style={{ padding: "20px", borderTopRightRadius: "5px", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px", boxShadow: "0px 0px 10px 0px lightgray" }} >
                            <Row>
                                {items.announcement && items.announcement.map((item: AnnouceResponse, index: number) => (
                                    <Col md="4" key={'announce' + index}>
                                        <CardAnnounce className="card">
                                            <img src={item.banner} className="card-img-top" alt="..." onError={(e: any) => { e.target.src = noPhoto }} />
                                            <div className="card-body">
                                                <h6 className="card-title title-orange">{item.topic}</h6>
                                                <p className="card-text" style={{fontSize:20}}>{item.description}</p>
                                                <div className="row text-right" style={{ display: "flex", justifyContent: "right" }}>
                                                    <span className="see-more" onClick={() => openAnnouce(item)}>ดูเพิ่มเติม</span> <img src={knext} height="30px" alt="next" style={{ marginTop: "-5px" }} />
                                                </div>
                                            </div>
                                        </CardAnnounce>
                                    </Col>
                                ))}
                            </Row>
                        </div>
                    </div>
                </>
            }
            <div className="student-wrapper-inner text-left">
                <div className="yellow-button text-center mt-4" style={{ width: "230px", borderTopLeftRadius: "5px", borderTopRightRadius: "5px", fontFamily: 'DB Helvethaica Bold', zIndex: 999 }}>
                    <img src={knowledgeicon} height='30' alt="" />แหล่งความรู้ที่เกี่ยวข้อง
                </div>
                <div className="text-left white-button" style={{ padding: "20px", borderTopRightRadius: "5px", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px", boxShadow: "0px 0px 10px 0px lightgray" }} >
                    <Container>
                        <Row>
                            <Col md="6">
                                {createKnowledgeCard(k1icon, "วางแผนเรื่องเงิน", "แหล่งรวมความรู้ทางการเงินสำหรับคนไทยทุกช่วงวัย", 'https://www.set.or.th/th/education-research/education/happymoney/overview')}
                            </Col>
                            <Col md="6">
                                {createKnowledgeCard(k2icon, "มือใหม่เริ่มลงทุน", "แหล่งรวมความรู้และเครื่องมือช่วยลงทุนสำหรับมือใหม่", 'https://www.setinvestnow.com/th/home')}
                            </Col>
                        </Row>
                    </Container>
                    <Container>
                        <Row>
                            <Col lg="4">
                                {createKnowledgeCard(k4icon, "เส้นทางวิชาชีพตลาดทุน", "พัฒนาความรู้และทักษะที่สำคัญ เพื่อก้าวสู่การเป็นผู้ประกอบวิชาชีพที่มีคุณภาพของตลาดทุนไทย", 'https://www.set.or.th/th/education-research/education/professional/overview')}
                            </Col>
                            <Col lg="4">
                                {createKnowledgeCard(k3icon, "Startup & SME", "เสริมสร้างรากฐานความรู้และทักษะการเป็นผู้ประกอบการ เพื่อเสริมสร้างความแข็งแกร่งสู่การระดมทุนในตลาดทุน", 'https://www.live-platforms.com/education')}
                            </Col>
                            <Col lg="4">
                                {createKnowledgeCard(k5icon, "ความรู้บริษัทจดทะเบียน", "เสริมสร้างความรู้เพื่อพัฒนาบุคลากรบริษัทจดทะเบียนและตลาดทุน สู่การเติบโตอย่างยั่งยืน", 'https://www.setlink.set.or.th/education')}
                            </Col>
                        </Row>
                    </Container>
                    <Container>
                        <Row>
                            <Col lg="4">
                                <ImageKnowledgeCard src={knowledge1icon} onClick={() => { window.open('https://www.cma.in.th/cma/home/', '_blank') }} width="100%" alt="" /><br /><br />
                            </Col>
                            <Col lg="4">
                                <ImageKnowledgeCard src={knowledge2icon} onClick={() => { window.open('https://www.maruey.com/', '_blank') }} width="100%" alt="" /><br /><br />
                            </Col>
                            <Col lg="4">
                                <ImageKnowledgeCard src={knowledge3icon} onClick={() => { window.open('https://investory.set.or.th/', '_blank') }} width="100%" alt="" /><br /><br />
                            </Col>
                        </Row>
                    </Container>
                </div>

            </div>
            <StudentFooter />
        </div>
    </div>
        <StudentLandingAnnounceModal backdrop="static" show={modalShowAnnounce != undefined} item={modalShowAnnounce!} onHide={() => setModalShowAnnounce(undefined)} />
    </>)
}

export default StudentLanding;

