import { Dispatch } from 'redux';
import { ProjectGroupAPI } from "../../api/project-group-api";
import { dispatchError } from "../search/actions";
import { ActionType, SearchActionType } from "../search/types";
import { ProjectGroupRequest } from '../../model/request/projectGroup.model';

export const getProjecGroupInfo = (keyword: string | undefined, page: number, size: number, sort: string | undefined) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING,
        page: "edit_project_group_info"
    });
    ProjectGroupAPI.listProjectGroupInfo(keyword, page, size, sort).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "project_group_info_item",
            page: "edit_project_group_info",
            action: ActionType.QUERY
        });
    }, function (error) {
        dispatchError("edit_project_group_info", dispatch, error);
    })
};

export const updatedProjectGroupInfo = (body:ProjectGroupRequest,projectGroupIdd:number) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_UPDATING
    });
    ProjectGroupAPI.updateProjectGroupInfo(body,projectGroupIdd).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_UPDATED,
            payload: response.data,
            table: "update_project_group_info",
            page: "edit_project_group_info_modal",
            action: ActionType.UPDATE
        });
    }, function (error) {
       dispatchError("edit_project_group_info_modal",dispatch,error);
    })
};

export const addProjectGroupInfo = (body:ProjectGroupRequest) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_UPDATING
    });
    ProjectGroupAPI.addProjectGroupInfo(body).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_UPDATED,
            payload: response.data,
            table: "add_project_group_info",
            page: "edit_project_group_info_modal",
            action: ActionType.UPDATE
        });
    }, function (error) {
       dispatchError("edit_project_group_info_modal",dispatch,error);
    })
};

export const deleteProjectGroup = (id: number) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_UPDATING,
        page: "edit_project_group_info"
    });
    ProjectGroupAPI.deleteProjectGroup(id).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_UPDATED,
            payload: response.data,
            table: "delete_project_group_info",
            page: "edit_project_group_info",
            action: ActionType.DELETE
        });
    }, function (error) {
       dispatchError("edit_project_group_info",dispatch,error);
    })
};