import { Dispatch } from 'redux';
import { ActionType, SearchActionType } from '../search/types';
import { SurveyAPI } from '../../api/survey-api';
import { dispatchError } from '../search/actions'; 
import { ListSurveyQuestionResponse, SurveyQuestionRequest } from '../../model/response/survey.model';


export const listsurveygroup = (name: string,project: string, page: number,limit: number) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    SurveyAPI.listPage(name,project,page,limit).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "surveygroup",
            page: "surveygroup",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("surveygroup",dispatch,error);
    })
};
export const listProjectForSurvey = () => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    SurveyAPI.listProjectForSurvey().then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "listProjectForSurvey",
            page: "surveygroup",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("listProjectForSurvey",dispatch,error);
    })
};

export const listSurveyQuestion = (questionGroup: string,keyword: string, page: number,limit: number) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    SurveyAPI.listSurveyQuestionPage(questionGroup,keyword,page,limit).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data as ListSurveyQuestionResponse,
            table: "list_survey_question",
            page: "list_survey_question",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("list_survey_question",dispatch,error);
    })
};

export const previewSurveyQuestion = (questionId: string | null,required: string | null, otherAnswer: boolean | null) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    SurveyAPI.previewSurveyQuestion(questionId,required,otherAnswer).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "preview_survey_question",
            page: "edit_surveygroup",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("preview_survey_question",dispatch,error);
    })
};

export const listQuestionGroup = () => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    SurveyAPI.listSurveyQuestionGroup(false).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "list_question_group",
            page: "list_survey_question",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("list_question_group",dispatch,error);
    })
};

export const listQuestionGroupGetSurvey = () => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    SurveyAPI.listSurveyQuestionGroup(true).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "list_question_group",
            page: "edit_surveygroup",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("list_question_group",dispatch,error);
    })
};

export const getQuestionDetail = (id:string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    SurveyAPI.getQuestionDetail(id).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "get_question_detail",
            page: "list_survey_question",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("list_question_group",dispatch,error);
    })
};

export const duplicateSurveyQuestion = (id:string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    SurveyAPI.duplicateSurveyQuestion(id).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "duplicateSurveyQuestion",
            page: "list_survey_question",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("duplicateSurveyQuestion",dispatch,error);
    })
};

export const deletesurveygroup = (id: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_UPDATING
    });
    SurveyAPI.deleteSurveyGroup(id).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_UPDATED,
            payload: response.data,
            table: "surveygroup",
            page: "surveygroup",
            action: ActionType.DELETE
        });
    }, function (error) {
       dispatchError("surveygroup",dispatch,error);
    })
};

export const deleteSurveyQuestion = (id: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_UPDATING
    });
    SurveyAPI.deleteSurveyQuestion(id).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_UPDATED,
            payload: response.data,
            table: "deleteSurveyQuestion",
            page: "surveygroup",
            action: ActionType.DELETE
        });
    }, function (error) {
       dispatchError("deleteSurveyQuestion",dispatch,error);
    })
};

export const createSurveyQuestion = (body: SurveyQuestionRequest) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_UPDATING
    });
    SurveyAPI.createSurveyQuestion(body).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_UPDATED,
            payload: response.data,
            table: "createSurveyQuestion",
            page: "editSurveyQuestion",
            action: ActionType.UPDATE
        });
    }, function (error) {
       dispatchError("createSurveyQuestion",dispatch,error);
    })
};

export const updateSurveyQuestion = (body: SurveyQuestionRequest) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_UPDATING
    });
    SurveyAPI.updateSurveyQuestion(body).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_UPDATED,
            payload: response.data,
            table: "updateSurveyQuestion",
            page: "editSurveyQuestion",
            action: ActionType.UPDATE
        });
    }, function (error) {
       dispatchError("updateSurveyQuestion",dispatch,error);
    })
};

export const duplicateSurveyGroup = (id: number) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    SurveyAPI.duplicateSurveyGroup(id).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "duplicate_survey",
            page: "surveygroup",
            action: ActionType.UPDATE
        });
    }, function (error) {
       dispatchError("surveygroup",dispatch,error);
    })
};

