import { PartnerKnowledgeRegistration } from "../request/partner-knowledge-regis";
import { AdminEmployeeRresponse, EmployeeResponse } from "./hr.model";
import { ManageKnowledgeResponse } from "./manage-knowledge";

export interface PartnerProfileListResponse {
    orgName: string;
    showGeneralInfo: boolean;
    showOrganizationInfo: boolean;
    showPartner: boolean;
    showWelfareBenefit: boolean;
    completed: boolean;
    mergeStateGeneralInfo: "NOTSAVE" | "SAVE"
    mergeStateOrganizationInfo: "NOTSAVE" | "SAVE"
    mergeStateWelfareBenefit: "NOTSAVE" | "SAVE"
    orgType: number;
    orgGroup: number;
    partnerShortname: string;
    listedCompanyType: "SET" | "MAI" | "NONE";
    houseNo: string;
    building: string;
    road: string;
    province: number;
    district: number;
    subDistrict: number;
    zipCode: number | undefined;
    personnel: number | any; //บุคลากร
    governmentOfficer: number | null; //ข้าราชการ
    governmentEmployee: number | null; // พนักงานราชการ
    employee: number | null; //ลูกจ้าง พนักงงาน
    memberUnderSupervision: number | null;
    teacherNo: number | any;
    studentNo: number | any;
    faculty: Faculty[]
    checkSocialSecurity: boolean;
    checkProvidentFund: boolean;
    checkGovPensionFund: boolean;
    checkSavingsCooperative: boolean;
    socialSecurityAmount: number;
    providentFundAmount: number;
    govPensionFundAmount: number;
    savingsCooperativeAmount: number;
    welfareBenefitList: WelfareBenefit[];
    partnerList: PartnerKnowledgeRegistration[];
    knowledgeList: ManageKnowledgeResponse[];
    transitions: Transition[];
    moveTransition: Transition;
    sendEmailToCustomer?: PartnerKnowledgeRegistration;
    code?: string;
    admin: AdminEmployeeRresponse[]
    publicAccessCode?: string;
    partnerRegistrationHistoryResponses?: PartnerRegistrationHistoryResponse[]
    checkExtraProvidentFund: boolean;
    checkExtraGovPension: boolean;
    checkExtraOther: boolean;
    extraProvidentFundType: 'SINGLE'|'POOLED'|'MASTER'| null;
    extraProvidentFundPolicyAmount: number;
    extraProvidentFundPolicyList: PartnerFundListResponse[];
    extraProvidentFundPlanAmount: number;
    extraProvidentFundPlanList: PartnerFundListResponse[];
    extraGovPensionPolicyAmount: number;
    extraGovPensionPolicyList: PartnerFundListResponse[];
    extraGovPensionPlanAmount: number;
    extraGovPensionPlanList: PartnerFundListResponse[];
    extraFundOtherList: PartnerFundListResponse[];
    haveMOU: boolean;
    mouList: MouResponse[];
    surveyProject: SurveyProjectResponse[];
}

export interface PartnerFundListResponse{
    id: number | null;
    description: string;
    extraFundType: 'PROVIDENT_FUND_POLICY'|'PROVIDENT_FUND_PLAN'|'GOV_PENSION_POLICY'|'GOV_PENSION_PLAN' | 'OTHER'|null
}

export interface MouResponse{
    id: number | null;
    mouName: string;
    mouOrganization: string;
    mouStartDate: Date | null;
    mouToDate: Date | null;
    scopeList: ScopeResponse[];
    mouDepartment: string | null;
}

export interface ScopeResponse{
    id: number | null;
    scpoeDescription: string;
}

export interface SurveyProjectResponse{
    id: number;
    projectId: number;
    question1: QuestionResponse[];
    question1Other: string;
    // question2: QuestionResponse[];
    // question2Other: string;
    // question3: QuestionResponse[];
    // question3Other: string;
    question4: QuestionResponse[];
    question4Other: string;
    otherInfo: string;
}

export interface QuestionResponse{
    questionId: number;
    description: string;
    checked: false;
}

export interface PartnerRegistrationHistoryResponse{
    id: number;
    createDate: string;
    createdBy: string;
    trainsition: string;
}

export const defaultPartner = {
    orgName:"",
    showGeneralInfo: true,
    showOrganizationInfo: false,
    showPartner: false,
    showWelfareBenefit: false,
    completed: false,
    mergeStateGeneralInfo: undefined,
    mergeStateOrganizationInfo: undefined,
    mergeStateWelfareBenefit: undefined,
    orgType: null,
    orgGroup: null,
    partnerShortname: "",
    listedCompanyType: null,
    houseNo: '',
    building: '',
    road: '',
    province: null,
    district: null,
    subDistrict: null,
    zipCode: '',
    personnel: null, //บุคลากร
    governmentOfficer: null, //ข้าราชการ
    governmentEmployee:null, // พนักงานราชการ
    employee: null, //ลูกจ้าง พนักงงาน
    memberUnderSupervision:null,
    teacherNo:null,
    studentNo: null,
    faculty: [],
    checkSocialSecurity: false,
    checkProvidentFund: false,
    checkGovPensionFund: false,
    checkSavingsCooperative: false,
    socialSecurityAmount: null,
    providentFundAmount: null,
    govPensionFundAmount: null,
    savingsCooperativeAmount: null,
    welfareBenefitList: [],
    partnerList: [],
    knowledgeList: [],
    transitions: [],
    moveTransition: null,
    admin: []
}

export interface Transition {
    id: number;
    transition: string;
    transitionTo: string;
    transitionToDescription: string;
}
export interface WelfareBenefit {
    id: number | null;
    welfareBenefitName: string | null;
    memberAmount: number | null;
}




export interface Faculty {
    mergeState?: "NOTSAVE" | "SAVE" | "NEW"
    selectedOldPartnerFacultyId?: number | null
    facultyInfo: FacultyInfo[];
    noBranch: boolean;
    branchInfo: BranchInfo[];
}


export interface FacultyInfo {
    id: number | null;
    facultyName: number | null;
    facultyDisplayName: string | null;
    teacherNo: number | null;
    personnel: number | null;
    studentNo: number | null;
    otherFacultyName: string | null;
    relatedFinancial: boolean;
}

export interface BranchInfo {
    id: number | null
    branchName: string | null;
    teacherNo: number | null;
    personnel: number | null;
    studentNo: number | null;
    relateFinance: boolean;

}

export interface PartnerInfoResponse {
    partnerId: number;
    partnerName: string;
    orgType: number;
    orgTypeName: string;
    orgGroup: number;
    orgGroupName: string;
}