import { ProjectGroupRequest } from "../model/request/projectGroup.model";
import { apiClient } from "./axios-intercept";
import { DEFAULT_SERVER } from "./conf";

export const PROJECT_GROUP_ACTIVE = DEFAULT_SERVER + "/api/v1/project-group/active";
export const PROJECT_GROUP_INFO = DEFAULT_SERVER + "/api/v1/project-group/management-info"
export const LIST_MANAGE_PROJECT_GROUP_INFO_URL_UPDATE = DEFAULT_SERVER + "/api/v1/project-group/management-info/update"
const LIST_MANAGE_PROJECT_GROUP_INFO_URL_CREATE = DEFAULT_SERVER + "/api/v1/project-group/management-info/create"
export class ProjectGroupAPI{
    public static listGroupActive = async (): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=PROJECT_GROUP_ACTIVE;
            apiClient.get(s,
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                return resolve(response);
            }).catch(err => {
                console.log("list project error:", err);
                return reject(err); 
            })
        })
    }

    public static listProjectGroupInfo = async (keyword: string | undefined,page:number,size:number,sort: string | undefined): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=PROJECT_GROUP_INFO;
            var queryParams:string[] = [];
            if(keyword) {
                queryParams.push(`keyword=${keyword}`)
            }

            queryParams.push(`page=${page}`);
            queryParams.push(`size=${size}`);
            if(sort) {
                queryParams.push(`sort=${sort}`)
            }
            apiClient.get(s+'?'+queryParams.join('&'),
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                return resolve(response);
            }).catch(err => {
                console.log("list project error:", err);
                return reject(err); 
            })
        })
    }

    public static updateProjectGroupInfo = async (body:ProjectGroupRequest,projectGroupId:number): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=LIST_MANAGE_PROJECT_GROUP_INFO_URL_UPDATE;
            apiClient.put(s,body
                ,{//options
                    headers: {
                        "Accept": "application/json"
                    }
                })
            .then((response) => {
               
                return resolve(response);
            }).catch(err => {
                console.log("add project doc error:", err);
                return reject(err); 
            })
        })
    }

    public static addProjectGroupInfo = async (body:ProjectGroupRequest): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=LIST_MANAGE_PROJECT_GROUP_INFO_URL_CREATE;
            apiClient.post(s,body
                ,{//options
                    headers: {
                        //"Content-Type": "multipart/form-data",
                        "Accept": "application/json"
                    }
                })
            .then((response) => {
                return resolve(response);
            }).catch(err => {
                console.log("add project doc error:", err);
                return reject(err); 
            })
        })
    }

    public static deleteProjectGroup = async (id: number): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=PROJECT_GROUP_INFO+"/"+id;
            apiClient.delete(s,
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                console.log("delete partner response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("delete partner error:", err);
                return reject(err); 
            })
        })
    }


}