import { Dispatch } from 'redux';
import { ActionType, SearchActionType } from "../search/types";
import { LogRequestAPI } from "../../api/log-request-api";
import { dispatchError } from "../search/actions";
import { PartnerRegistrationStatus } from '../../api/define';
import { RegisterStatus } from '../../model/response/log-request';

export const listLogRequest = (responsibleDeptIds:string[]|undefined, partnerName:string|undefined, subCategoryIds:number[]|undefined, fromDate:Date|null|undefined, toDate:Date|null|undefined, statusId:number|null|undefined, page:number, size:number, sort: string|undefined,logRequestPage:"NEW"|"HISTORY") => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING,
        page: "log_request"
    });
    LogRequestAPI.listLogRequest(responsibleDeptIds,partnerName,subCategoryIds,fromDate,toDate,statusId,page,size,sort,logRequestPage).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "log_request",
            page: "log_request",
            action: ActionType.QUERY
        });
    }, function (error) {
        dispatchError("log_request", dispatch, error);
    })
};

export const getKnowledgeCategoryDropdown = () => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING,
        page: "log_request"
    });
    LogRequestAPI.getKnowledgeCategoryDropdown().then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "knowledge_cat_dropdown",
            page: "log_request",
            action: ActionType.QUERY
        });
    }, function (error) {
        dispatchError("knowledge_cat_dropdown", dispatch, error);
    })
};

export const getRegisterStatus = (historyPage:boolean|false) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING,
        page: "log_request"
    });
    LogRequestAPI.getRegisterStatus(historyPage).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "register_status",
            page: "log_request",
            action: ActionType.QUERY
        });
    }, function (error) {
        dispatchError("register_status", dispatch, error);
    })
};