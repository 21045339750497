import React, { useEffect, useState } from 'react'
import { StepPage } from './constant/StepPage'
import PartnerHeader from './PartnerHeader'
import PartnerStepper from './PartnerStepper'
import { Banner, CardBox, PButton, PButtonBack, PartnerContainer } from '../../style/partnerStylemain'
import banner from '../../img/partnerBanner.png';
import '../../style/PartnerCheckbox.scss'
import { PartnerCategory, PartnerSubCategory } from '../../model/response/partner-category'
import PartnerChooseTopicStepper from './PartnerChooseTopicStepper'
import { AlertContainer, alert } from 'react-custom-alert';
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useStore } from 'react-redux'
import { NOT_FOUND_LABEL } from '../../api/label'
import { queryclear } from '../../store/search/actions'
import { listKnowledgeCategory } from '../../store/publicPartner/action'
import loadinglogo from '../../img/loading.gif';

type Props = {}

const PartnerChooseTopic = (props: Props) => {
    const [listCategory, setListCategory] = useState<PartnerCategory[]>([]);
    const [stepCat, setStepCat] = useState(0);
    const [init, setInit] = useState(0);
    const [loading, setLoading] = useState(false);
    const store = useStore();
    const dispatch = useDispatch();
    const history = useHistory();
    const search = new URLSearchParams(useLocation().search);
    const back = search.get('back');
    useEffect(() => {
        const unsubscribe = store.subscribe(() => {
            var res = store.getState().SearchReducer;
            if (res.isLoading) {
                setLoading(true);
            } else if (res.isLoaded) {
                setLoading(false);
                if (res.data != null) {
                    if (res.table === "category") {
                        setInit(1);
                        setListCategory(res.data);
                    }
                } else {
                    alert({ message: NOT_FOUND_LABEL, type: 'error' });
                }
            } else if (res.isNotFound) {
                alert({ message: NOT_FOUND_LABEL, type: 'error' });
                setListCategory([]);
                setLoading(false);
            } else if (res.isUpdated) {
                dispatch(queryclear);
            } else if (res.isFailed) {
                alert({ message: res.data.message, type: 'error' });
                setListCategory([]);
                setLoading(false);
            }
        });
        if (sessionStorage.getItem("listCategory")) {
            let listFromSession = JSON.parse(sessionStorage.getItem("listCategory") as any) as PartnerCategory[]
            setListCategory(listFromSession)
            setInit(1);
            if (back) {
                setStepCat(listFromSession.length - 1);
                search.delete('back');
                history.replace({
                    search: search.toString(),
                })
            }
        } else {
            dispatch(listKnowledgeCategory());
        }
        return unsubscribe;
    }, [])

    const handleCheckSubCategory = (e: any, index: number, subCatIndex: number): void => {
        let newItem = [...listCategory]
        newItem[index].subCategories[subCatIndex].checked = e.target.checked;
        newItem[index].notInterested = false;
        setListCategory(newItem);
    }
    const handleCheckNotInterest = (e: any, index: number) => {
        let newItem = [...listCategory]
        newItem[index].notInterested = e.target.checked;
        newItem[index].subCategories.forEach(x => x.checked = false);
        setListCategory(newItem);
    }
    const goNextStep = () => {
        if (notCheckAnything(stepCat)) {
            alert({ message: 'กรุณาเลือกสิ่งที่สนใจ', type: 'error' });
        } else {
            if (stepCat < listCategory.length - 1) {
                setStepCat(stepCat + 1)
            }
        }
    }
    const notCheckAnything = (step: number): boolean => {
        let isCheckNotInterest = listCategory[step].notInterested;
        if (isCheckNotInterest) {
            return false;
        } else {
            let checkChooseSomeQuestion = listCategory[step].subCategories.some(x => x.checked)
            return checkChooseSomeQuestion ? false : true
        }
    }

    const goBackStep = () => {
        if (stepCat > 0) {
            setStepCat(stepCat - 1)
        }
    }
    const goToSummaryCategory = () => {
        if (notCheckAnything(stepCat)) {
            alert({ message: 'กรุณาเลือกสิ่งที่สนใจ', type: 'error' });
        } else {
            if (notCheckEverything()) {
                history.push("/partner/thankNotInterest")
            } else {
                sessionStorage.setItem("listCategory", JSON.stringify(listCategory))
                history.push("/partner/summary-suggestion")
            }

        }

    }
    const notCheckEverything = () => {
        return !(listCategory.some(x=>!x.notInterested))
    }

    return (
        <div className='bg-light-gray partner-page' style={{ height: "100vh", fontFamily: "DB Helvethaica" }}>
            <div style={{ position: 'sticky', top: 0 }}>
                <div className="student-wrapper-header-inner" style={{ background: "#38383D" }}>
                    <PartnerHeader />
                </div>
                <PartnerStepper stepNumber={StepPage.CHOOSE_TOPIC} />
            </div>

            <PartnerContainer className='mt-2'>
                <h5 className='text-center mt-2' >แจ้งความสนใจส่งเสริมความรู้ในองค์กร</h5>
                <div className='text-center mb-2'>กรุณาเลือกความรู้ที่ท่านสนใจ เพื่อให้ทางตลาดหลักทรัพย์ฯ<br/> ได้เสนอหลักสูตรและสื่อความรู้ได้อย่างครบถ้วนและเหมาะสมกับความต้องการของท่าน</div>
                {init == 1 && (
                    <>
                        <CardBox style={{ minHeight: "55vh", maxHeight: "55vh", overflowY: "auto" }}>
                            {listCategory && listCategory.map((x: PartnerCategory, index: number) => (
                                <div key={"category" + x.categoryId}>
                                    {stepCat == index && (
                                        <React.Fragment>
                                            <h6 className='p-text-orange' >{x.categoryName}</h6>
                                            {x.subCategories.map((subcat: PartnerSubCategory, subcatIndex: number) => (
                                                <div key={"subCat" + subcatIndex + "_" + subcat.subCategoryId}>

                                                    <div className='d-flex'>
                                                        <span className='mt-1'><input type="checkbox" key={"subCatCheck" + subcatIndex + "_" + subcat.subCategoryId} className='largerCheckbox' checked={subcat.checked} id={"subquestion" + subcat.subCategoryId} onClick={(e) => handleCheckSubCategory(e, index, subcatIndex)} /></span>
                                                        <label className='ml-2'><span style={{ fontWeight: 600 }}>{subcat.subCategoryName} </span><br /><span style={{ color: '#5F6062' }}>{subcat.subCategoryDescription}</span></label>
                                                    </div>

                                                </div>
                                            ))}
                                            <div className='d-flex'>
                                                <span className='mt-1'><input type="checkbox" className='largerCheckbox' checked={x.notInterested as any} id={"notInterested" + index} onClick={(e) => handleCheckNotInterest(e, index)} /></span>
                                                <label className='ml-2'><span style={{ fontWeight: 600 }}>ยังไม่สนใจ </span></label>
                                            </div>
                                        </React.Fragment>
                                    )}


                                </div>

                            ))}
                        </CardBox>
                        <div className='d-flex mt-2'>
                            <div className='mr-auto'>
                                {stepCat != 0 && <PButtonBack onClick={goBackStep} id="button-back">&lt; ย้อนกลับ</PButtonBack>}
                                {stepCat == 0 && (<PButtonBack style={{ visibility: 'hidden' }} id="button-back">&lt; ย้อนกลับ</PButtonBack>)}

                            </div>
                            <div className='mx-auto'>
                                <PartnerChooseTopicStepper listCategory={listCategory} step={stepCat} />
                            </div>
                            <div className='ml-auto'>
                                {(listCategory.length != 0 && stepCat != listCategory.length - 1) && <PButton onClick={goNextStep} id="button-next">ถัดไป &gt;</PButton>}
                                {(listCategory.length != 0 && stepCat == listCategory.length - 1) && <PButton onClick={goToSummaryCategory} id="button-go-to-summary">สรุปผลการแนะนำ &gt;</PButton>}
                            </div>

                        </div>
                    </>
                )}
                {(init < 1) &&
                    <>
                        <div className='d-flex justify-content-center'><img src={loadinglogo} width="30" height="30" className="d-inline-block align-top" alt="" /></div>
                    </>
                }

            </PartnerContainer>
            <AlertContainer floatingTime={3000} />
        </div>
    )
}

export default PartnerChooseTopic