import React, { useEffect, useState } from 'react'
import PartnerHeader from '../PartnerHeader'
import PartnerStepper from '../PartnerStepper'
import { StepPage } from '../constant/StepPage'
import { PartnerSummaryContainer } from '../../../style/partnerStylemain'
import { PartnerCategory, PartnerKnowledgeSummary } from '../../../model/response/partner-category'
import { useHistory } from 'react-router-dom'
import PartnerTabCategory from './component/PartnerTabCategory'
import { NOT_FOUND_LABEL } from '../../../api/label'
import { useDispatch, useStore } from 'react-redux'
import { queryclear } from '../../../store/search/actions'
import { listKnowledgeSummary, listKnowledgeSummaryByPublicCode } from '../../../store/publicPartner/action'
import PartnerInfoGraphic from './component/PartnerInfoGraphic'
import PartnerVideo from './component/PartnerVideo'
import PartnerArticle from './component/PartnerArticle'
import PartnerElearning from './component/PartnerElearning'
import PartnerEbook from './component/PartnerEbook'
import PartnerInhouse from './component/PartnerInhouse'
import PartnerCalculator from './component/PartnerCalculator'
import PartnerFooter from '../PartnerFooter'
import PartnerLoadingModal from '../PartnerLoadingModal'
import { setTimeout } from 'timers'
import PartnerPublishedDoc from './component/PartnerPublishedDoc'
import { AlertContainer, alert } from "react-custom-alert";
type Props = {}

const PartnerSummarySuggestion = (props: Props) => {
    const [listCategory, setListCategory] = useState<PartnerCategory[]>([]);
    const [activeCategory, setActiveCategory] = useState<number | undefined>();
    const [activeSubCategory, setActiveSubcategory] = useState<number | undefined>();
    const [listSummary, setSummary] = useState<PartnerKnowledgeSummary[]>();
    const [init, setInit] = useState(0);
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const store = useStore();
    const dispatch = useDispatch();
    useEffect(() => {
        const unsubscribe = store.subscribe(() => {
            var res = store.getState().SearchReducer;
            if (res.isLoading) {
                setLoading(true);
            } else if (res.isLoaded) {
                setLoading(false);
                if (res.data != null) {
                    if (res.table === "summary-knowledge") {
                        setTimeout(() => {
                            setInit(1);
                        }, 3000)
                        setSummary(res.data);
                        if (res.data.length > 0) {
                            setActiveSubcategory(res.data[0].subCategoryId)
                        }
                    } else if (res.table === "summary-knowledge-public-code") {
                        setTimeout(() => {
                            setInit(1);
                        }, 3000)
                        setSummary(res.data);
                        if (res.data.length > 0) {
                            let listFromSession = res.data[0].knowledgeList as PartnerCategory[]
                            let listInterest = listFromSession.filter(x => (x.notInterested == false))
                            setListCategory(listInterest);
                            setActiveCategory(res.data[0].categoryId)
                            setActiveSubcategory(res.data[0].subCategoryId)
                            
                            
                        }
                    }
                } else {
                    alert({ message: NOT_FOUND_LABEL, type: 'error' });
                }
            } else if (res.isNotFound) {
                alert({ message: NOT_FOUND_LABEL, type: 'error' });
                setSummary(undefined);
                setLoading(false);
            } else if (res.isUpdated) {
                dispatch(queryclear);
            } else if (res.isFailed) {
                alert({ message: res.data.message, type: 'error' });
                setSummary(undefined);
                setLoading(false);
            }
        });
        if (sessionStorage.getItem("listCategory")) {
            let listFromSession = JSON.parse(sessionStorage.getItem("listCategory") as any) as PartnerCategory[]
            let listInterest = listFromSession.filter(x => (x.notInterested == false))
            setListCategory(listInterest);
            if (listInterest.length > 0) {
                setActiveCategory(listInterest[0].categoryId)
                dispatch(listKnowledgeSummary(listInterest[0].categoryId, listFromSession));
            }
        } else if (sessionStorage.getItem("publicAccessCode")) {
            let publicCode = JSON.parse(sessionStorage.getItem("publicAccessCode") as any)
            dispatch(listKnowledgeSummaryByPublicCode(publicCode, undefined))
        } else {
            history.push("/partner")
        }
        return unsubscribe;
    }, []);
    const handleActiveCategoryChange = (categoryId: any) => {
        setActiveCategory(categoryId);
        if (sessionStorage.getItem("listCategory")) {
            let listFromSession = JSON.parse(sessionStorage.getItem("listCategory") as any) as PartnerCategory[]
            dispatch(listKnowledgeSummary(categoryId, listFromSession));
        } else if (sessionStorage.getItem("publicAccessCode")) {
            let publicCode = JSON.parse(sessionStorage.getItem("publicAccessCode") as any)
            dispatch(listKnowledgeSummaryByPublicCode(publicCode, categoryId))
        }


    }
    const handleActiveSubCategoryChange = (subCategoryId: any) => {
        setActiveSubcategory(subCategoryId);

    }
    const modalBodyLoadingData = () => {
        return <span><h4>ระบบกำลังประมวลผล</h4><br />
            หลักสูตรและสื่อความรู้ที่ท่านสนใจ</span>
    }

    const summaryStateCompleted = () => {
        return sessionStorage.getItem("summaryState") && sessionStorage.getItem("summaryState") === "COMPLETED";
    }

    return (
        <div className='bg-light-gray partner-page' style={{ height: "100vh", fontFamily: "DB Helvethaica" }}>
            <div style={{ position: 'sticky', top: 0, zIndex: 30 }}>
                <div className="student-wrapper-header-inner" style={{ background: "#38383D" }}>
                    <PartnerHeader />
                </div>
                {!summaryStateCompleted() && <> <PartnerStepper stepNumber={StepPage.SUMMARY} /> </>}
            </div>
            {init > 0 && (<>
                <PartnerSummaryContainer>
                    <div>
                        <h5 className='mt-3'>แนะนำหลักสูตรและสื่อความรู้ที่สนใจ</h5>
                        <PartnerTabCategory listCategory={listCategory}
                            activeCategory={activeCategory}
                            activeSubCategory={activeSubCategory}
                            handleActiveCategoryChange={handleActiveCategoryChange}
                            handleActiveSubCategoryChange={handleActiveSubCategoryChange}
                        ></PartnerTabCategory>
                        <>
                            <br />
                            {listSummary?.filter(x => x.subCategoryId == activeSubCategory).map(subcat => (
                                <React.Fragment key={"subcat" + subcat.subCategoryId}>

                                    {subcat?.infographics != null && subcat?.infographics.length > 0 && (
                                        <PartnerInfoGraphic knowledge={subcat?.infographics} />
                                    )}
                                    {subcat?.videos != null && subcat?.videos.length > 0 && (
                                        <><br />
                                            <PartnerVideo knowledge={subcat?.videos} />
                                        </>
                                    )}
                                    {subcat?.articles != null && subcat?.articles.length > 0 && (
                                        <><br />
                                            <PartnerArticle knowledge={subcat?.articles} />
                                        </>
                                    )}
                                    {subcat?.elearnings != null && subcat?.elearnings.length > 0 && (
                                        <><br />
                                            <PartnerElearning knowledge={subcat?.elearnings} />
                                        </>
                                    )}
                                    {subcat?.ebooks != null && subcat?.ebooks.length > 0 && (
                                        <><br />
                                            <PartnerEbook knowledge={subcat?.ebooks} />
                                        </>
                                    )}
                                    {subcat?.publishedDocuments != null && subcat?.publishedDocuments.length > 0 && (
                                        <><br />
                                            <PartnerPublishedDoc knowledge={subcat?.publishedDocuments} />
                                        </>
                                    )}
                                    {subcat?.inHouses != null && subcat?.inHouses.length > 0 && (
                                        <><br />
                                            <PartnerInhouse knowledge={subcat?.inHouses} />
                                        </>
                                    )}
                                    {subcat?.calculators != null && subcat?.calculators.length > 0 && (
                                        <><br />
                                            <PartnerCalculator knowledge={subcat?.calculators} />
                                        </>
                                    )}
                                </React.Fragment>

                            ))}

                        </>
                    </div>
                </PartnerSummaryContainer>

                {!summaryStateCompleted() && <>
                    <div style={{ position: 'fixed', bottom: 0, zIndex: 3, width: '100%' }}>
                        <PartnerFooter />
                    </div>
                </>}

            </>)}

            <PartnerLoadingModal body={modalBodyLoadingData()} show={init == 0} />
            <AlertContainer floatingTime={3000} />
        </div>
    )
}

export default PartnerSummarySuggestion