import { apiClient } from './axios-intercept';
import { DEFAULT_SERVER } from './conf';
import { DocumentType } from './define';

const DOC ="/api/v1/document";
const SEARCH_DOC_URL = DEFAULT_SERVER + DOC;
const ADD_DOC_URL = DEFAULT_SERVER + DOC;
const UPDATE_DOC_URL = DEFAULT_SERVER + DOC;
const DELETE_DOC_URL = DEFAULT_SERVER + DOC;

//doc with id
export const GET_DOC_URL = DEFAULT_SERVER + DOC+"/file";
//doc with filename
export const GET_DOC_FILE_URL =DEFAULT_SERVER + DOC+"/file";

export class DocAPI {
        
    public static list = async (classId: number, classCourseId: number,page: number,limit: number): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=SEARCH_DOC_URL+"/class/"+classId+"?"+((isNaN(classCourseId))?"":"class_course_id="+classCourseId)+"&page="+page+"&limit="+limit; 
            apiClient.get(s,
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                console.log("list doc response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("list doc error:", err);
                return reject(err); 
            })
        })
    }

    public static add = async (classId: string,classCourseId: string,caption: string,url: string,description: string,docType: DocumentType,file: FileList|undefined): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var formData = new FormData();
            if (file!==undefined) formData.append("file", file[0]);
            formData.append("doc_type", docType);
            formData.append("caption", caption);
            formData.append("url", url);
            formData.append("description", description);

            var s=ADD_DOC_URL+"/"+((classId==="")?"class_course/"+classCourseId:"class/"+classId);
            console.log(s);
            apiClient.post(s,formData
                ,{//options
                    headers: {
                        //"Content-Type": "multipart/form-data",
                        "Accept": "application/json"
                    }
                })
            .then((response) => {
                console.log("add doc response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("add doc error:", err);
                return reject(err); 
            })
        })
    }

    public static update = async (classId: string,classCourseId: string,id:number,caption: string,url: string,description: string,docType: DocumentType,file: FileList | undefined): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var formData = new FormData();
            if (file!==undefined) formData.append("file", file[0]);
            formData.append("doc_type", docType);
            formData.append("caption", caption);            
            formData.append("url", url);
            formData.append("description", description);
            if (classId!=="") formData.append("class_id", classId);
            else if (classCourseId!=="") formData.append("class_course_id", classCourseId);
            var s=UPDATE_DOC_URL+"/"+id;
            apiClient.post(s,formData
                ,{//options
                    headers: {
                        //"Content-Type": "multipart/form-data",
                        "Accept": "application/json"
                    }
                })
            .then((response) => {
                console.log("update doc response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("update doc error:", err);
                return reject(err); 
            })
        })
    }

    public static delete = async (id: string): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=DELETE_DOC_URL+"/"+id;
            apiClient.delete(s,
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                console.log("delete doc response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("delete doc error:", err);
                return reject(err); 
            })
        })
    }

    public static listProject = async (name: string,page: number,limit: number): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var s=SEARCH_DOC_URL+"/project?"+((name==="")?"":"name="+name)+"&page="+page+"&limit="+limit; 
            apiClient.get(s,
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                console.log("list project doc response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("list project doc error:", err);
                return reject(err); 
            })
        })
    }

    public static addProject = async (projectId: number,caption: string,url: string,description: string,docType: DocumentType,file: FileList|undefined): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var formData = new FormData();
            if (file!==undefined) formData.append("file", file[0]);
            formData.append("doc_type", docType);
            formData.append("caption", caption);
            formData.append("url", url);
            formData.append("description", description);

            var s=ADD_DOC_URL+"/project/"+projectId;
            console.log(s);
            apiClient.post(s,formData
                ,{//options
                    headers: {
                        //"Content-Type": "multipart/form-data",
                        "Accept": "application/json"
                    }
                })
            .then((response) => {
                console.log("add project doc response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("add project doc error:", err);
                return reject(err); 
            })
        })
    }

    public static updateProject = async (projectId: number,id:number,caption: string,url: string,description: string,docType: DocumentType,file: FileList | undefined): Promise<any> => {
        return new Promise(function (resolve, reject) {
            var formData = new FormData();
            if (file!==undefined) formData.append("file", file[0]);
            formData.append("project_id",projectId.toString());
            formData.append("doc_type", docType);
            formData.append("caption", caption);            
            formData.append("url", url);
            formData.append("description", description);
            var s=UPDATE_DOC_URL+"/"+id;
            apiClient.post(s,formData
                ,{//options
                    headers: {
                        //"Content-Type": "multipart/form-data",
                        "Accept": "application/json"
                    }
                })
            .then((response) => {
                console.log("update project doc response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("update project doc error:", err);
                return reject(err); 
            })
        })
    }
}