import { Dispatch } from 'redux';
import { ActionType, SearchActionType } from '../search/types';
import { DocAPI } from '../../api/doc-api';
import { ProjectAPI } from '../../api/project-api';
import { dispatchError } from '../search/actions'; 
import { DocumentType } from '../../api/define';

export const listproject = () => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING,
        page: "edit_class"
    });
    ProjectAPI.list().then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "list_project",
            page: "edit_project_doc",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("edit_class",dispatch,error);
    })
};

export const listdoc = ( name: string,page: number,limit: number) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    DocAPI.listProject(name,page,limit).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "doc",
            page: "edit_project_doc",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("edit_project_doc",dispatch,error);
    })
};

export const adddoc = (projectId:number,caption: string,url: string,description: string,docType: DocumentType,file: FileList | undefined) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_UPDATING
    });
    DocAPI.addProject(projectId,caption,url,description,docType,file).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_UPDATED,
            payload: response.data,
            table: "doc",
            page: "edit_project_doc",
            action: ActionType.UPDATE
        });
    }, function (error) {
       dispatchError("edit_project_doc",dispatch,error);
    })
};

export const updatedoc = (projectId:number,id: string,caption: string,url: string,description: string,docType: DocumentType,file: FileList | undefined) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_UPDATING
    });
    DocAPI.updateProject(projectId,parseInt(id),caption,url,description,docType,file).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_UPDATED,
            payload: response.data,
            table: "doc",
            page: "edit_project_doc",
            action: ActionType.UPDATE
        });
    }, function (error) {
       dispatchError("edit_project_doc",dispatch,error);
    })
};


export const deletedoc = (id: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_UPDATING
    });
    DocAPI.delete(id).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_UPDATED,
            payload: response.data,
            table: "doc",
            page: "edit_project_doc",
            action: ActionType.DELETE
        });
    }, function (error) {
       dispatchError("edit_project_doc",dispatch,error);
    })
};
