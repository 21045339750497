import React from 'react'
import { PartnerCategory } from '../../../../model/response/partner-category';
import styled from 'styled-components';

type Props = {
    listCategory: PartnerCategory[];
    activeCategory: number | undefined;
    activeSubCategory: number | undefined;
    handleActiveCategoryChange?: (value: number) => void;
    handleActiveSubCategoryChange?: (value: number) => void;
}
const CategoryBox = styled.div<{ backgroudColor?: string }>`
    min-width: 150px;
    height: 68px;
    padding: 21px 15px 21px 12px;
    border-radius: 10px 30px 0px 0px;
    border: 2px solid #FCB034;
    border-bottom: none;
    background: ${p => p.backgroudColor ? p.backgroudColor : 'white'};
    text-align: center !important;
    line-height: 1.0;
    transition: all 0.5s;
    &:hover{
        cursor: pointer;
        filter: brightness(95%);
    }
    span{
        font-size: 22px;
        
    }
`
const CategoryBoxContainer = styled.div`
    display: flex;
    border-bottom: 3px solid #FCB034;
`
const SubCategoryContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 14px 10px;
`

const SubCategoryBox = styled.div<{ backgroudColor?: string }>`
    padding: 3px 16px 3px 16px;
    border-radius: 50px;
    border: 1px;
    background: ${p => p.backgroudColor ? p.backgroudColor : 'white'};
    border: 1px solid #FED757;
    text-align: center !important;
    transition: all 0.5s;
    &:hover{
        cursor: pointer;
        filter: brightness(95%);
    }
    span{
        font-size: 18px;
        
    }
`
const PartnerTabCategory = (props: Props) => {
    const getActiveCategory = (categoryId: number) => {
        if (categoryId == props.activeCategory) {
            return '#FCB034';
        } else {
            return 'white';
        }
    }
    const getActiveSubCategory = (subCategoryId: number) => {
        if (subCategoryId == props.activeSubCategory) {
            return '#FED757';
        } else {
            return 'white';
        }
    }
    return (
        <div>
            <CategoryBoxContainer>
                {props.listCategory.map(x => (
                    <React.Fragment key={"category_box" + x.categoryId}>
                        <CategoryBox backgroudColor={getActiveCategory(x.categoryId)} onClick={() => props.handleActiveCategoryChange?.(x.categoryId)}><span>{x.categoryName}</span></CategoryBox>
                    </React.Fragment>
                ))}
            </CategoryBoxContainer>
            <br />
            <SubCategoryContainer>
                {props.listCategory.filter(x => x.categoryId == props.activeCategory)[0]?.subCategories.map(subCat => (
                    <React.Fragment key={"sub_category_box" + subCat.subCategoryId}>
                        {subCat.checked && (
                            <SubCategoryBox backgroudColor={getActiveSubCategory(subCat.subCategoryId)}
                                onClick={() => props.handleActiveSubCategoryChange?.(subCat.subCategoryId)}
                            ><span>{subCat.subCategoryName}</span></SubCategoryBox>
                        )}

                    </React.Fragment>
                ))}
            </SubCategoryContainer>


        </div>
    )
}

export default PartnerTabCategory