import React, { useState } from 'react'
import { PartnerProfileListResponse, Transition } from '../../../../model/response/partner-profile';
import ExpandMore from '@material-ui/icons/ExpandMore'
import ExpandLess from '@material-ui/icons/ExpandLess'
import { createSaveStateNoNew } from '../../../../api/define';
import Select from 'react-select';
import { customStateStyles, greenStateStyles } from '../../../tab/search-component';
import { Tooltip } from 'antd';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

type Props = {
    parnerProfileList: PartnerProfileListResponse;
    success: boolean;
    submitted: boolean;
    setParnerProfileListChange: (parnerProfileList: PartnerProfileListResponse) => void;
    readonly: boolean;
    state?: string;
    submittedMerge?: boolean;
    hideDropdown?:boolean;
    nextAction?: number;
    nextActionItem?: Transition[];
}

const getDescription = () => {
    return <p>องค์กรที่มีจำนวนสมาชิกอยู่ภายใต้การดูแล เช่น จำนวนสมาชิกกองทุนบำเหน็จบำนาญข้าราชการ (กบข.), จำนวนผู้กู้ยืมเงินกองทุน (กยศ.) ​, จำนวนสมาชิกกองทุนการออมแห่งชาติ (กอช.) เป็นต้น หากกรณีองค์กรไม่มีจำนวนสมาชิกที่อยู่ภายใต้การดูแลให้กรอกข้อมูลเป็น 0 (ศูนย์)​</p>
}

const PartnerOrgGoverment = (props: Props) => {
    const [expand, setExpand] = useState(true);
    const [tabColor,setTabColor] = useState(props.state?props.state=='old'?'gray-button':'yellow-button':'yellow-button');
    const [borderColor,setBorderColor] = useState(props.state?props.state=='old'?'#dddddd':'#fab232':'#fab232')
    const getRequire = (value: string | boolean | undefined | any[] | null | number) => {
        let moveTransition;
        if(props.nextActionItem){
            moveTransition = props?.nextActionItem.find(x => x.id == props.nextAction);
        }
        let isSendFormToCustomer = moveTransition?.transition == 'ส่งฟอร์มให้กรอกข้อมูล'
        if(isSendFormToCustomer){
            return false;
        }else{
            return (props.submitted && (value == null || value == undefined || value == ''))
        }
    }
    const setEmployee = (e: any) => {
        var newItem = { ...props.parnerProfileList };
        newItem.employee = e;
        props.setParnerProfileListChange(newItem);
    }
    const setPersonnel = (e: any) => {
        var newItem = { ...props.parnerProfileList };
        newItem.personnel = e;
        props.setParnerProfileListChange(newItem);
    }
    const setGovernmentOfficer = (e: any) => {
        var newItem = { ...props.parnerProfileList };
        newItem.governmentOfficer = e;
        props.setParnerProfileListChange(newItem);
    }
    const setGovernmentEmployee = (e: any) => {
        var newItem = { ...props.parnerProfileList };
        newItem.governmentEmployee = e;
        props.setParnerProfileListChange(newItem);
    }
    const setMemberUnderSupervision = (e: any) => {
        var newItem = { ...props.parnerProfileList };
        newItem.memberUnderSupervision = e;
        props.setParnerProfileListChange(newItem);
    }
    const getRequireMerge = (value: string | boolean | undefined | any[] | null | number) => {
        return (props.submittedMerge && (value == null || value == undefined))
    }
    const setMergeStateOrganizationInfo = (e:any) => {
        var newItem = { ...props.parnerProfileList }; 
        newItem.mergeStateOrganizationInfo = e;
        props.setParnerProfileListChange(newItem);
    }
    const getMergeStateOrganizationInfo = () => {
        let dropdown = createSaveStateNoNew()
        for (var i = 0; i < dropdown.length; i++)
            if (dropdown[i].value === props.parnerProfileList?.mergeStateOrganizationInfo) {
                return { label: dropdown[i].label, value: props.parnerProfileList?.mergeStateOrganizationInfo };
            }
        return null;
    }

    return (
        <React.Fragment>
            <div className="text-left" style={{ position: "relative", marginBottom: 5 }}>
                <div className={'d-flex '+tabColor} style={{ padding: "15px", borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}>
                    {((expand === undefined) || (expand === false)) &&
                        <div onClick={(e) => setExpand(true)}><ExpandMore className="pointer mr-2" ></ExpandMore></div>
                    }
                    {(expand === true) &&
                        <div onClick={(e) => setExpand(false)}><ExpandLess className="pointer mr-2" ></ExpandLess></div>
                    }
                    <label className='ml-2 my-auto'><span style={{ fontWeight: 600, marginRight: 10 }}>ข้อมูลองค์กร</span>{props.state && (<span style={{ fontWeight: 600, marginRight: 10 }}>{props.state=="old"? "(เดิม)":"(ใหม่)"}</span>)} </label>
                </div>
                {(expand === true) && (
                    <div style={{ padding: "15px", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px", border: "3px solid "+borderColor }}>
                        {props.state == 'new' && !props.hideDropdown && <div className='row mt-1 d-flex justify-content-end mr-2'>
                            <span>
                                <div style={{ fontWeight: 600 }}>Next Action<span className='text-danger'>*</span></div>
                                <Select
                                    isSearchable={true} isMulti={false} placeholder=""
                                    onChange={(e: any) => setMergeStateOrganizationInfo(e == null ? null : e?.value)}
                                    value={getMergeStateOrganizationInfo()}
                                    options={createSaveStateNoNew()}
                                    styles={getRequireMerge(props.parnerProfileList?.mergeStateOrganizationInfo)?customStateStyles:greenStateStyles}                                
                                />
                            </span>
                        </div>}
                        <div className='row mt-1'>
                            <label className='col-2 my-auto' style={{ fontWeight: 600 }}>บุคลากร<span className='text-danger'>*</span></label>
                            <input type="number" disabled={props.readonly} className={'form-control col-4 ' + (getRequire(props.parnerProfileList?.personnel) ? "is-invalid" : "")} value={props.parnerProfileList?.personnel} onChange={(e) => setPersonnel(e.target.value)} id={"input-number-personnel"} />
                            <label className='col-2 my-auto' style={{ fontWeight: 600 }}>คน</label>
                        </div>
                        <div className='row mt-1'>
                            <label className='col-2 my-auto' style={{ fontWeight: 600 }}>ข้าราชการ<span className='text-danger'>*</span></label>
                            <input type="number" disabled={props.readonly} className={'form-control col-4 ' + (getRequire(props.parnerProfileList?.governmentOfficer) ? "is-invalid" : "")} value={props.parnerProfileList?.governmentOfficer} onChange={(e) => setGovernmentOfficer(e.target.value)} id={"input-number-governmentOfficer"} />
                            <label className='col-2 my-auto' style={{ fontWeight: 600 }}>คน</label>
                        </div>
                        <div className='row mt-1'>
                            <label className='col-2 my-auto' style={{ fontWeight: 600 }}>พนักงานราชการ<span className='text-danger'>*</span></label>
                            <input type="number" disabled={props.readonly} className={'form-control col-4 ' + (getRequire(props.parnerProfileList?.governmentEmployee) ? "is-invalid" : "")} value={props.parnerProfileList?.governmentEmployee} onChange={(e) => setGovernmentEmployee(e.target.value)} id={"input-number-governmentEmployee"} />
                            <label className='col-2 my-auto' style={{ fontWeight: 600 }}>คน</label>
                        </div>
                        <div className='row mt-1'>
                            <label className='col-2 my-auto' style={{ fontWeight: 600 }}>ลูกจ้าง<span className='text-danger'>*</span></label>
                            <input type="number" disabled={props.readonly} className={'form-control col-4 ' + (getRequire(props.parnerProfileList?.employee) ? "is-invalid" : "")} value={props.parnerProfileList?.employee} onChange={(e) => setEmployee(e.target.value)} id={"input-number-employee"} />
                            <label className='col-2 my-auto' style={{ fontWeight: 600 }}>คน</label>
                        </div>
                        <div className='row mt-1'>
                            <label className='col-2 my-auto' style={{ fontWeight: 600 }}>จำนวนสมาชิกที่อยู่ภายใต้การดูแล<span className='text-danger'>*</span>
                                <Tooltip placement="right" title={getDescription()}>
                                    <InfoOutlinedIcon className="blue" style={{ cursor: 'pointer' ,fontSize:'20px' }} />
                                </Tooltip>
                            </label>
                            <input type="number" disabled={props.readonly} className={'form-control col-4 ' + (getRequire(props.parnerProfileList?.memberUnderSupervision) ? "is-invalid" : "")} value={props.parnerProfileList?.memberUnderSupervision} onChange={(e) => setMemberUnderSupervision(e.target.value)} id={"input-number-memberUnderSupervision"} />
                            <label className='col-2 my-auto' style={{ fontWeight: 600 }}>คน</label>
                        </div>

                    </div>)}
            </div>

        </React.Fragment>
    )
}

export default PartnerOrgGoverment