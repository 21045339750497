import { Dispatch } from 'redux';
import { ActionType, SearchActionType } from '../search/types';
import { PageStudentAPI } from '../../api/page-student-api';
import { ResultAPI } from '../../api/result-api';
import { dispatchError } from '../search/actions'; 

export const liststatus = () => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    ResultAPI.listStatus().then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "status",
            page: "student_result",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("student_result",dispatch,error);
    })
};

export const result = (search: string,page:number,limit: number) => (dispatch: Dispatch<any>) => {
    dispatch({
         type: SearchActionType.QUERY_LOADING
     });
     PageStudentAPI.result(search,page,limit).then(function (response) {
         dispatch({
             type: SearchActionType.QUERY_LOADED,
             payload: response.data,
             table: "result",
             page: "student_result",
             action: ActionType.QUERY
         });
     }, function (error) {
        dispatchError("student_result",dispatch,error);
     })
 };
 