import React, { useEffect, useState } from 'react'
import MainMenu from './menu';
import loadinglogo from '../../img/loading.gif';
import ProjectTab from './projecttab';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { queryclear } from '../../store/search/actions';
import { ProjectGroupResponse, ProjectManagementInfoResponse, ProjectResponse } from '../../model/response/project.model';
import { Button, InputGroup, Table } from 'react-bootstrap';
import styled from 'styled-components';
import { SearchPanel, TableHeaderBlack } from '../../style/tableStyle';
import { getProjectInfo, listGroupActive, listOrganizationMapProject } from '../../store/editprojectinfo/action';
import { Controller, useForm } from "react-hook-form";
import Select from 'react-select';
import { HRDepartment } from '../../model/response/organization.model';
import Sort from '../share/Sort';
import { CustomPage } from '../share/CustomPage';
import { Util } from '../../api/util';
import AddIcon from '@material-ui/icons/Add';
import excellogo from '../../img/excel.png';
import { EditProjectInfoModal } from './editprojectinfoModal';
import { AlertContainer, alert } from 'react-custom-alert';
import { EXPORT_PROJECT_INFO_URL, ProjectAPI } from '../../api/project-api';
import { getAccessToken } from '../../store/auth/selectors';
import EditIcon from '@material-ui/icons/Edit';
const EditProjectInfo = () => {
    const [init, setInit] = useState(0);
    const [initProjectGroup, setInitProjectGroup] = useState(0);
    const [initOrganization, setInitOrganization] = useState(0);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [updating, setUpdating] = useState(false)
    const [search, setSearch] = useState("");
    const [projectGroupList, setProjectGroupList] = useState<ProjectGroupResponse[]>([]);
    const [organizationList, setOrganizationList] = useState<HRDepartment[]>([]);
    const [projectItem, setProjectItem] = useState<ProjectManagementInfoResponse[]>([]);
    const [page, setPage] = useState<number>(0);
    const [size, setSize] = useState<number>(10);
    const [sortBy, setSortBy] = useState<string | undefined>();
    const [sortType, setSortType] = useState<'asc' | 'desc'>('desc');
    const [totalElement, setTotalElwment] = useState<number>(0);
    const [modalShow, setModalShow] = useState<boolean | undefined>();
    const [modalItem, setModalItem] = useState<ProjectManagementInfoResponse>();
    const store = useStore();
    const history = useHistory();
    const dispatch = useDispatch();
    const token = useSelector(getAccessToken);
    const { control, handleSubmit, formState: { errors }, reset, getValues, register } = useForm();
    const handleDoc = () => {
        history.push("/admin/project/doc");
    }
    const handleProject = () => {
        history.push("/admin/project/info");
    }
    const handleProjectGroup = () => {
        history.push("/admin/project-group/info");
    }
    useEffect(() => {
        const unsubscribe = store.subscribe(() => {
            let res = store.getState().SearchReducer;
            if (res.isLoading) {
                setError('');
                setLoading(true);
            } else if ((res.isLoaded) && (!res.isUpdated)) {
                setError('');
                setLoading(false);
                if (res.data != null) {
                    if (res.table === "list_project_group_active") {
                        setProjectGroupList(res.data);
                        setInitProjectGroup(1);
                    } else if (res.table === 'list_org_map_project') {
                        setOrganizationList(res.data);
                        setInitOrganization(1);
                    } else if (res.table === "project_info_item") {
                        setProjectItem(res.data.elements);
                        setTotalElwment(res.data.totalElements);
                        setInit(1);
                    }

                }
            } else if (res.isUpdating) {
                setError('');
                setUpdating(true);
            } else if (res.isUpdated) {
                setError('');
                setUpdating(false);
                dispatch(queryclear());
                alert({ message: 'Saved', type: 'success' });
            } else if (res.isFailed) {
                setError(res.data.message);
                setLoading(false);
                alert({ message: res.data.message, type: 'error' });
            }

        })
        return unsubscribe;
    }, [search])
    useEffect(() => {
        dispatch(queryclear());
        dispatch(listGroupActive());
        dispatch(listOrganizationMapProject())
        dispatch(getProjectInfo(undefined, undefined, undefined, page, size, undefined))
    }, []);

    const createProjectGroupFilter = (): { label: string, value: number }[] => {
        var res: any[] = [];
        for (var i = 0; i < projectGroupList.length; i++) {
            res.push({ label: projectGroupList[i].shortName + ":" + projectGroupList[i].name, value: projectGroupList[i].id });
        }
        return res;
    }
    const createOrganizationList = (): { label: string, value: string }[] => {
        var res: any[] = [];
        for (var i = 0; i < organizationList.length; i++) {
            res.push({ label: organizationList[i].name, value: organizationList[i].id });
        }
        return res;
    }
    const onSubmit = (data: any) => {
        setPage(0);
        setSortBy(undefined);
        dispatch(queryclear());
        dispatch(getProjectInfo(data?.projectName, data?.projectGroup, data?.organization, 0, size, undefined))
    }
    const refresh = (e: any) => {
        setModalShow(undefined);
        setModalItem(undefined);
        setPage(0);
        setSortBy(undefined);
        dispatch(queryclear());
        const searchParam = getValues();
        dispatch(getProjectInfo(searchParam?.projectName, searchParam?.projectGroup, searchParam?.organization, 0, size, undefined))
    }
    const setProjectGroup = (value: number) => {
        let result = projectGroupList.find(x => x.id === value)
        if (result) {
            return { label: result.shortName + ':' + result.name, value: result.id }
        } else {
            return null
        }
    }
    const setOrganization = (value: string) => {
        let result = organizationList.find(x => x.id === value)
        if (result) {
            return { label: result.name, value: result.id }
        } else {
            return null
        }
    }
    const handleClear = () => {
        reset();
        setPage(0);
        setSortBy(undefined);
        setModalItem(undefined);
        dispatch(queryclear());
        dispatch(getProjectInfo(undefined, undefined, undefined, 0, size, undefined))

    }
    const onSort = (sortName: string, newSortType: 'asc' | 'desc') => {
        setSortBy(sortName);
        setSortType(newSortType);
        const searchParam = getValues();
        dispatch(getProjectInfo(searchParam?.projectName, searchParam?.projectGroup, searchParam?.organization, page, size, sortName + ',' + newSortType));
    }
    const onPageChange = (value: number) => {
        setPage(value);
        let sort = sortBy ? sortBy + ',' + sortType : undefined;
        const searchParam = getValues();
        dispatch(getProjectInfo(searchParam?.projectName, searchParam?.projectGroup, searchParam?.organization, value, size, sort));
    }
    const onPageSizeChange = (value: number) => {
        setPage(0);
        setSize(value);
        let sort = sortBy ? sortBy + ',' + sortType : undefined;
        const searchParam = getValues();
        dispatch(getProjectInfo(searchParam?.projectName, searchParam?.projectGroup, searchParam?.organization, 0, value, sort));
    }

    const addElement: any = () => {
        return <>
            <Button type="button" className="btn btn-warning yellow-button" style={{ marginInline: 10 }}
                onClick={() => handleAdd()} >
                <AddIcon />
                เพิ่มโครงการ
            </Button>
            {excelElement()}
        </>
    }

    const handleAdd = () => {
        setModalItem(undefined);
        setModalShow(true);
    }

    const excelElement: any = () => {
        return <Button type="button" className="btn btn-success green-button" style={{ width: "50px" }}
            onClick={() => handleExcel()} >
            <img src={excellogo} height="26px" alt="excel" style={{ marginLeft: "-3px" }} />
        </Button>
    }

    const handleExcel = () => {
        const searchParam = getValues();
        window.open(EXPORT_PROJECT_INFO_URL + "?" + ProjectAPI.getDownloadParam(searchParam?.projectName, searchParam?.projectGroup, searchParam?.organization, encodeURIComponent(token)));
    }
    const handleEdit = (item: ProjectManagementInfoResponse) => {
        setModalItem(item);
        setModalShow(true);

    }

    return (
        <div>
            <MainMenu>
                <div>
                    <div className="query-wrapper">
                        <br />
                        <div className="yellow-button" style={{ textAlign: "left", borderTopLeftRadius: "30px", borderTopRightRadius: "30px", height: "70px", paddingTop: "15px", paddingLeft: "15px" }}><h4 className="header">การจัดการข้อมูลรายโครงการ</h4></div>
                        {(init + initProjectGroup + initOrganization === 3) &&
                            <>
                                <br />
                                {ProjectTab(1, true, handleDoc, handleProject,handleProjectGroup)}
                                <div style={{ background: "white", padding: "15px", borderRadius: "5px" }}>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <SearchPanel>
                                            <div className='row'>
                                                <div className='col-4'>
                                                    <label className="col-form-label">ชื่อย่อ/ชื่อโครงการ</label>
                                                    <input {...register("projectName")} className={'form-control'} type="text" name="projectName" id="projectName" />
                                                </div>
                                                <div className='col-4'>
                                                    <label className="col-form-label">กลุ่มโครงการ</label>
                                                    <Controller
                                                        control={control}
                                                        name={"projectGroup"}
                                                        render={({ field: { onChange, value, name, ref } }) => (
                                                            <Select
                                                                placeholder=""
                                                                isClearable={true}
                                                                value={setProjectGroup(value)}
                                                                name={name}
                                                                options={createProjectGroupFilter()}
                                                                onChange={(selectedOption: any) => {
                                                                    onChange(selectedOption?.value);
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                                <div className='col-4'>
                                                    <label className="col-form-label">ฝ่ายงาน</label>
                                                    <Controller
                                                        control={control}
                                                        name={"organization"}
                                                        render={({ field: { onChange, value, name, ref } }) => (
                                                            <Select
                                                                placeholder=""
                                                                isClearable={true}
                                                                value={setOrganization(value)}
                                                                name={name}
                                                                options={createOrganizationList()}
                                                                onChange={(selectedOption: any) => {
                                                                    onChange(selectedOption?.value);
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mt-3" style={{ justifyContent: "center" }}>
                                                <button type="button" className="btn btn-outline-primary" style={{ width: "150px" }}
                                                    onClick={() => handleClear()} >
                                                    Clear
                                                </button>&nbsp;
                                                <button type="submit" className="btn btn-primary" style={{ width: "150px" }}
                                                    disabled={loading}>
                                                    <div>
                                                        {(loading) &&
                                                            <div style={{ textAlign: "center" }}><img src={loadinglogo} width="30" height="30" className="d-inline-block align-top" alt="" /></div>
                                                        }
                                                        {(!loading) &&
                                                            <>Search</>
                                                        }
                                                    </div>
                                                </button>
                                            </div>
                                        </SearchPanel>
                                    </form>
                                </div>
                                <CustomPage current={page} pageSize={size} total={totalElement} onPageChange={onPageChange} onPageSizeChange={onPageSizeChange} loading={loading && (modalShow !== true)} postElement={addElement()}>
                                    <TableHeaderBlack striped hover className='mt-2'>
                                        <thead>
                                            <tr>
                                                <th className='text-left' style={{ verticalAlign: "top" }}>
                                                    <Sort label='กลุ่มโครงการ' currentSort={sortBy} sortName='projectGroupEntity.name' handleClick={onSort} sortType={sortType} />
                                                </th>
                                                <th className='text-left' style={{ verticalAlign: "top" }}>
                                                    <Sort label='ชื่อโครงการ' currentSort={sortBy} sortName='code' handleClick={onSort} sortType={sortType} />
                                                </th>
                                                <th className='text-left' style={{ verticalAlign: "top" }}>
                                                    <Sort label='ฝ่ายผู้รับผิดชอบโครงการ' currentSort={sortBy} sortName='organizerDeptId' handleClick={onSort} sortType={sortType} />
                                                </th>
                                                <th style={{ verticalAlign: "top" }}>
                                                    <Sort label='วันที่สร้าง' currentSort={sortBy} sortName='createTime' handleClick={onSort} sortType={sortType} />
                                                </th>
                                                <th style={{ verticalAlign: "top" }}>
                                                    <Sort label='แก้ไขล่าสุด' currentSort={sortBy} sortName='lastUpdateTime' handleClick={onSort} sortType={sortType} />
                                                </th>
                                                <th style={{ verticalAlign: "top" }}>
                                                    <Sort label='สถานะ' currentSort={sortBy} sortName='status' handleClick={onSort} sortType={sortType} />
                                                </th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {projectItem && projectItem.map((item: ProjectManagementInfoResponse, index: number) => (
                                                <tr key={'projectTable' + item.projectId}>
                                                    <td className='text-left'>{item.projectGroupNameDisplay}</td>
                                                    <td className='text-left'>{item.projectNameDisplay}</td>
                                                    <td className='text-left'>{item.organizer}</td>
                                                    <td>{Util.datetimetostr(new Date(item.createDate))}</td>
                                                    <td>{Util.datetimetostr(new Date(item.updateDate))}</td>
                                                    <td>{item.statusDisplay}</td>
                                                    <td>
                                                        <div onClick={(e) => handleEdit(item)}><EditIcon className="yellow pointer" ></EditIcon></div>&nbsp;
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </TableHeaderBlack>
                                </CustomPage>
                                <br />
                            </>
                        }
                        {(((init + initProjectGroup + initOrganization < 3) || (loading) && (modalShow !== true)) && (error === "")) &&
                            <div style={{ textAlign: "center" }}><img src={loadinglogo} width="30" height="30" className="d-inline-block align-top" alt="" /></div>
                        }
                        {(error !== "") &&
                            <div style={{ color: 'red', textAlign: "center" }}>{error}</div>
                        }
                    </div>
                </div>
            </MainMenu>
            <EditProjectInfoModal backdrop="static" show={modalShow != undefined} onHide={() => setModalShow(undefined)} onOk={refresh} editItem={modalItem} />
            <AlertContainer floatingTime={3000} />
        </div>
    )
}

export default EditProjectInfo