import { useSelector } from 'react-redux';
import { getUsername } from '../../store/auth/selectors';
import { Button } from 'react-bootstrap';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useHistory } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import logoSetLearnScape from "../../img/Logo-SET-Learn-Scape.png"
import PersonIcon from '@material-ui/icons/Person';
import { getIsStudent, getIsLoggedIn } from '../../store/auth/selectors';
import { SET_MEMBER_SERVER } from '../../api/conf';

const StudentHeader = () => {
    const isStudent = useSelector(getIsStudent);
    const isLoggedIn = useSelector(getIsLoggedIn);
    const username = useSelector(getUsername);
    const history = useHistory();

    const handleResult = () => {
        history.push("/student/result")
    }

    const handleCourse = () => {
        history.push("/student/course")
    }
    const handleLogout = () => {
        history.push("/student/logout");
    }

    const handleMain = () => {
        history.push("/student/landing");
    }

    const handleLogin = () => {

        if(SET_MEMBER_SERVER){
            window.location.href = SET_MEMBER_SERVER;
        }
        
    }

    return (
        <div className="row">
            <div className="col-2 text-left">
                <img src={logoSetLearnScape} className="d-inline-block align-top" alt="" style={{ cursor: "pointer", height: 50 }} onClick={handleMain} />
            </div>
            <div className="col-2">
            </div>
            <div className="col-8 text-right justify-content-right">
                {((isLoggedIn) && (isStudent)) &&
                    <Dropdown>
                        <Dropdown.Toggle variant="text" id="dropdown-basic" className='white'>
                            <b> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{username}</b>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={handleMain}>Home</Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item onClick={handleCourse}>โครงการของฉัน</Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item onClick={handleResult}>ประวัติการเรียน</Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item onClick={handleLogout}><ExitToAppIcon />ลงชื่อออก</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                }
                {!((isLoggedIn) && (isStudent)) &&
                    <Button className="dark-button white border-0 btn-no-focus" onClick={handleLogin}><PersonIcon /> เข้าสู่ระบบ</Button>
                }
            </div>
        </div>
    );
}

export default StudentHeader;