import React, { useEffect } from 'react'
import { Button, Modal, ModalProps } from 'react-bootstrap'
import { ManageKnowledge } from '../../../../model/response/manage-knowledge';
import LinkIcon from '@material-ui/icons/OpenInNew';

type Props = ModalProps &
{
    mediaItem?: ManageKnowledge;
    okButton?: string;
    cancelButton?: string;
}
const ManageKnowledgeMediaDescriptionModal = (props: Props) => {
    useEffect(() => {
        console.log(props.mediaItem)
    }, [])
    return (
        <Modal
            {...props}
            dialogClassName="modal-30w"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            centered
        >
            <Modal.Body>
                {(props.mediaItem?.description || props.mediaItem?.link) && (
                    <div>
                        <div>{props.mediaItem?.description}</div>
                        {props.mediaItem?.link && (<>
                            <br />
                            <div><a href={props.mediaItem?.link} target='_blank'><LinkIcon /> {props.mediaItem?.link}</a></div>
                        </>)}

                    </div>
                )}
                {(!props.mediaItem?.description && !props.mediaItem?.link) && (
                    <div>ไม่มีคำอธิบาย</div>
                )}

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.onHide}>
                    {props.cancelButton || 'Close'}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ManageKnowledgeMediaDescriptionModal