import { Dispatch } from 'redux';
import { ActionType, SearchActionType } from '../search/types';
import { PartnerAPI } from '../../api/partner-api';
import { dispatchError } from '../search/actions';
import { PartnerProfileListResponse } from '../../model/response/partner-profile';
import { PartnerKnowledgeRegistration } from '../../model/request/partner-knowledge-regis';
import { PartnerMediaRequest } from '../../model/response/partner-activity-media';

export const listpartner = (name: string, code: string, orgType: string | null, page: number, limit: number, orgGroup: string | null) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING,
        page: "partner"
    });
    PartnerAPI.list(name, code, orgType, page, limit,orgGroup).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "partner",
            page: "partner",
            action: ActionType.QUERY
        });
    }, function (error) {
        dispatchError("partner", dispatch, error);
    })
};

export const listpartnerByClassId = (classId: number) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING,
        page: "partner"
    });
    PartnerAPI.listByClassId(classId).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "partner_by_classId",
            page: "partner",
            action: ActionType.QUERY
        });
    }, function (error) {
        dispatchError("partner", dispatch, error);
    })
};

export const listorganizationGroup = () => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    PartnerAPI.listOrganizationGroup().then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "org_type_group",
            page: "partner",
            action: ActionType.QUERY
        });
    }, function (error) {
        dispatchError("partner", dispatch, error);
    })
};

export const listorganization = (page: number, limit: number) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING,
        page: "partner"
    });
    PartnerAPI.listOrganization(page, limit).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "org_type",
            page: "partner",
            action: ActionType.QUERY
        });
    }, function (error) {
        dispatchError("partner", dispatch, error);
    })
};


export const deletepartner = (id: number) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_UPDATING,
        page: "partner"
    });
    PartnerAPI.delete(id).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_UPDATED,
            payload: response.data,
            table: "org_type",
            page: "partner",
            action: ActionType.DELETE
        });
    }, function (error) {
        dispatchError("partner", dispatch, error);
    })
};

export const getPartnerProfile = (registrationId: number) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    PartnerAPI.getPartnerProfile(registrationId).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "get_partner_profile",
            page: "partner_profile",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("partner_profile",dispatch,error);
    })
};

export const getMergeLogRequestPartnerProfile = (partnerId: number) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    PartnerAPI.getMergeLogRequestPartnerProfile(partnerId).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "get_merge_partner_profile",
            page: "merge_partner_profile",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("merge_partner_profile",dispatch,error);
    })
};

export const getParnerProfile = (partnerId: number) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    PartnerAPI.getParnerProfile(partnerId).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "get_parner_profile",
            page: "partner_profile",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("partner_profile",dispatch,error);
    })
};

export const getSurveyProjectForAdd = () => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    PartnerAPI.getSurveyProjectForAdd().then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "survey_project_for_add",
            page: "partner_profile",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("partner_profile",dispatch,error);
    })
};

export const getPackageSubcategoryInterest = (partnerId: number) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    PartnerAPI.getPackageSubcategoryInterest(partnerId).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "get_parner_package",
            page: "partner_package",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("partner_package",dispatch,error);
    })
};

export const getPaticipantActivity = (partnerId: number) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    PartnerAPI.getPaticipantActivity(partnerId).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "get_partner_participant",
            page: "partner_activity",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("partner_activity",dispatch,error);
    })
};

export const getInfoTrainingOrganizationPartner = (partnerId: number,page: number,size: number) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    PartnerAPI.getInfoTrainingOrganizationPartner(partnerId,page,size).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "get_info_training_partner",
            page: "partner_activity",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("partner_pactivity",dispatch,error);
    })
};

export const getInfoTrainingOrganizationPublic = (partnerId: number,page: number,size: number) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    PartnerAPI.getInfoTrainingOrganizationPublic(partnerId,page,size).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "get_info_training_public",
            page: "partner_activity",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("partner_pactivity",dispatch,error);
    })
};

export const getPartnerFaculty = (partnerId: number) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    PartnerAPI.getPartnerFaculty(partnerId).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "get_partner_faculty",
            page: "merge_partner_profile",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("merge_partner_profile",dispatch,error);
    })
};

export const updatePartnerProfileByRegistraion = (registrationId: number,body:PartnerProfileListResponse) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_UPDATING
    });
    PartnerAPI.updatePartnerProfileByRegistraion(registrationId,body).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_UPDATED,
            payload: response.data,
            table: "update_partner_profile",
            page: "partner_profile",
            action: ActionType.UPDATE
        });
    }, function (error) {
        dispatchError("partner_profile",dispatch,error);
    })
};

export const updatePartnerProfileByPartnerId = (partnerId: number,body:PartnerProfileListResponse) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_UPDATING
    });
    PartnerAPI.updatePartnerProfileByPartnerId(partnerId,body).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_UPDATED,
            payload: response.data,
            table: "update_partner_profile",
            page: "partner_profile",
            action: ActionType.UPDATE
        });
    }, function (error) {
        dispatchError("partner_profile",dispatch,error);
    })
};
export const createPartnerProfile = (body:PartnerProfileListResponse) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_UPDATING
    });
    PartnerAPI.createPartnerProfile(body).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_UPDATED,
            payload: response.data,
            table: "create_partner_profile",
            page: "partner_profile",
            action: ActionType.UPDATE
        });
    }, function (error) {
        dispatchError("partner_profile",dispatch,error);
    })
};

export const mergeLogRequestToPartner= (registrationId: number,partnerId: number,body:PartnerProfileListResponse) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_UPDATING
    });
    PartnerAPI.mergeLogRequestToPartner(registrationId,partnerId,body).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_UPDATED,
            payload: response.data,
            table: "merge_log_to_partner",
            page: "merge_partner",
            action: ActionType.UPDATE
        });
    }, function (error) {
       dispatchError("merge_partner",dispatch,error);
    })
};

export const createLogRequestToPartner= (registrationId: number,body:PartnerProfileListResponse) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_UPDATING
    });
    PartnerAPI.createLogRequestToPartner(registrationId,body).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_UPDATED,
            payload: response.data,
            table: "create_log_to_partner",
            page: "merge_partner",
            action: ActionType.UPDATE
        });
    }, function (error) {
       dispatchError("merge_partner",dispatch,error);
    })
};

export const resendVerifyEmail = (registrationId: number,body:PartnerKnowledgeRegistration) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    PartnerAPI.resendVerifyEmail(registrationId,body).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "partner_resend_verify_email",
            page: "partner_profile",
            action: ActionType.QUERY
        });
    }, function (error) {
        dispatchError("partner_profile",dispatch,error);
    })
};

export const resendVerifyEmailByPartnerId = (partnerId: number,body:PartnerKnowledgeRegistration) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    PartnerAPI.resendVerifyEmailByPartnerId(partnerId,body).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "partner_resend_verify_email",
            page: "partner_profile",
            action: ActionType.QUERY
        });
    }, function (error) {
        dispatchError("partner_profile",dispatch,error);
    })
};


export const searchMergePartner = (keyword: string | undefined,orgType: number|undefined,orgGroup: number|undefined) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    PartnerAPI.searchMergePartner(keyword,orgType,orgGroup).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "merge_partner_search",
            page: "merge_partner",
            action: ActionType.UPDATE
        });
    }, function (error) {
        dispatchError("merge_partner",dispatch,error);
    })
};

export const getReceiveMediaOverview = (partnerId: number) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    PartnerAPI.getReceiveMediaOverview(partnerId).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "partner_media_overview",
            page: "partner_media_overview",
            action: ActionType.UPDATE
        });
    }, function (error) {
        dispatchError("partner_media_overview", dispatch, error);
    })
};

export const listPartnerMedia = (partnerId: number, page: number, limit: number, name?: string | undefined, categoryId?: number | undefined, fileType?: string | undefined) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    PartnerAPI.listPartnerMedia(partnerId, page, limit, name, categoryId, fileType).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "partner_media_search",
            page: "partner_media_search",
            action: ActionType.UPDATE
        });
    }, function (error) {
        dispatchError("partner_media_search", dispatch, error);
    })
};

export const createPartnerMedia = (partnerId: number, item: PartnerMediaRequest) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    PartnerAPI.createPartnerMedia(partnerId, item).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "partner_media_create",
            page: "partner_media_create",
            action: ActionType.UPDATE
        });
    }, function (error) {
        dispatchError("partner_media_create", dispatch, error);
    })
};

export const updatePartnerMedia = (partnerId: number, item: PartnerMediaRequest) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    PartnerAPI.updatePartnerMedia(partnerId, item).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "partner_media_update",
            page: "partner_media_update",
            action: ActionType.UPDATE
        });
    }, function (error) {
        dispatchError("partner_media_update", dispatch, error);
    })
};
export const deletePartnerMedia = (partnerId: number, partnerMediaId: number) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    PartnerAPI.deletePartnerMedia(partnerId, partnerMediaId).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "partner_media_delete",
            page: "partner_media_delete",
            action: ActionType.UPDATE
        });
    }, function (error) {
        dispatchError("partner_media_delete", dispatch, error);
    })
};
