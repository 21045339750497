import { ProjectAPI } from './../../api/project-api';

import { Dispatch } from 'redux';
import { OrganizationAPI } from '../../api/organization-api';
import { ProjectGroupAPI } from "../../api/project-group-api";
import { dispatchError } from "../search/actions";
import { ActionType, SearchActionType } from "../search/types";
import { HRAPI } from '../../api/hr-api';
import { ProjectManageInfoRequest } from '../../model/request/project.model';

export const listGroupActive = () => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    ProjectGroupAPI.listGroupActive().then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "list_project_group_active",
            page: "edit_project_info",
            action: ActionType.QUERY
        });
    }, function (error) {
        dispatchError("edit_project_info", dispatch, error);
    })
}

export const listOrganizationMapProject = () => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING
    });
    OrganizationAPI.listOraganizationMapProject().then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "list_org_map_project",
            page: "edit_project_info",
            action: ActionType.QUERY
        });
    }, function (error) {
        dispatchError("edit_project_info", dispatch, error);
    })
}

export const getProjectInfo = (projectName: string | undefined, projectGroup: number | undefined, organization: string | undefined, page: number, size: number, sort: string | undefined) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING,
        page: "edit_project_info"
    });
    ProjectAPI.getProjectManageInfo(projectName, projectGroup, organization, page, size, sort).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "project_info_item",
            page: "edit_project_info",
            action: ActionType.QUERY
        });
    }, function (error) {
        dispatchError("edit_project_info", dispatch, error);
    })
};

export const getProjectCode = (projectGroupId: number) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING,
        page: "edit_project_info_modal"
    });
    ProjectAPI.getProjectCode(projectGroupId).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "edit_project_info_project_code",
            page: "edit_project_info_modal",
            action: ActionType.QUERY
        });
    }, function (error) {
        dispatchError("edit_project_info", dispatch, error);
    })
};

export const getListOrganization = () => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_LOADING,
        page: "edit_project_info_modal"
    });
    HRAPI.listDepartment().then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_LOADED,
            payload: response.data,
            table: "organizer",
            page: "edit_project_info_modal",
            action: ActionType.QUERY
        });
    }, function (error) {
       dispatchError("edit_project_info_modal",dispatch,error);
    })
};

export const addProjectInfo = (body:ProjectManageInfoRequest) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_UPDATING
    });
    ProjectAPI.addProjectInfo(body).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_UPDATED,
            payload: response.data,
            table: "add_project_info",
            page: "edit_project_info_modal",
            action: ActionType.UPDATE
        });
    }, function (error) {
       dispatchError("edit_project_info_modal",dispatch,error);
    })
};

export const updatedProjectInfo = (body:ProjectManageInfoRequest,projectOd:number) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SearchActionType.QUERY_UPDATING
    });
    ProjectAPI.updateProjectInfo(body,projectOd).then(function (response) {
        dispatch({
            type: SearchActionType.QUERY_UPDATED,
            payload: response.data,
            table: "update_project_info",
            page: "edit_project_info_modal",
            action: ActionType.UPDATE
        });
    }, function (error) {
       dispatchError("edit_project_info_modal",dispatch,error);
    })
};