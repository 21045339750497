import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux';
import { getAccessToken, getRefreshToken } from '../../../store/auth/selectors';
import StudentHeader from '../studentheader';
import loadinglogo from '../../../img/loading.gif';
import openBookLogo from '../../../img/openBook.png';
import clockLogo from '../../../img/Clock.png';
import warrantyLogo from '../../../img/Warranty.png';
import StudentCourseList from './studentcourseList';
import { useHistory } from 'react-router-dom';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { StudentAssignmentShortResponse } from '../../../model/response/student-course';
import { queryclear } from '../../../store/search/actions';
import { getCourseList } from '../../../store/studentcourse/action';

type Props = {}

const StudentCourse = (props: Props) => {
    const token = useSelector(getAccessToken);
    const sessionToken = useSelector(getRefreshToken);
    const [init, setInit] = useState(0)
    const [loading, setLoading] = useState(false);
    const [assignmentProcess, setAssignmentProcess] = useState<StudentAssignmentShortResponse[]>();
    const [assignmentComing, setAssignmentComing] = useState<StudentAssignmentShortResponse[]>();
    const [assignmentCompleted, setAssignmentCompleted] = useState<StudentAssignmentShortResponse[]>();
    const [totalAssignProcess, setTotalAssignProcess] = useState<number>(0);
    const [totalAssignComing, setTotalAssignComing] = useState<number>(0);
    const [totalAssignCompleted, setTotalAssignCompleted] = useState<number>(0);
    const [error, setError] = useState('');
    const history = useHistory();
    const store = useStore();
    const dispatch = useDispatch();
    useEffect(() => {
        const unsubscribe = store.subscribe(() => {
            let res = store.getState().SearchReducer;
            if (res.isLoading) {
                setError('');
                setLoading(true);
            } else if ((res.isLoaded) && (!res.isUpdated)) {
                setError('');
                setLoading(false);
                if (res.data != null) {
                    if (res.table === "assignment_process") {
                        setAssignmentProcess(res.data.elements);
                        setTotalAssignProcess(res.data.totalElements);
                    } else if (res.table === "assignment_coming") {
                        setAssignmentComing(res.data.elements);
                        setTotalAssignComing(res.data.totalElements)
                    } else if (res.table === "assignment_completed") {
                        setAssignmentCompleted(res.data.elements);
                        setTotalAssignCompleted(res.data.totalElements)
                    }
                    setInit(1);
                }
            } else if (res.isFailed) {
                alert({ message: res.data.message, type: 'error' })
                setLoading(false);
            }
        })
        return unsubscribe;
    }, [])
    useEffect(() => {
        dispatch(queryclear());
        dispatch(getCourseList('process', 0, 2, undefined));
        dispatch(getCourseList('coming', 0, 2, undefined));
        dispatch(getCourseList('completed', 0, 2, undefined));
    }, [])
    const seeMore = (assigntmentType: 'process' | 'coming' | 'completed') => {
        history.push('/student/course/' + assigntmentType)
    }
    return (
        <div><div className="App-student">
            <div className="student-wrapper">
                <div className="student-wrapper-header-inner" style={{ background: "#38383D" }}>
                    <StudentHeader />
                    {(((init === 0) || (loading)) && (error === "")) &&
                        <div style={{ textAlign: "center" }}><img src={loadinglogo} width="30" height="30" className="d-inline-block align-top" alt="" /></div>
                    }
                    {(error !== "") &&
                        <div style={{ color: 'red', textAlign: "center" }}>{error}</div>
                    }
                </div>
                {(init === 1) &&
                    <>
                        <div className="student-wrapper-inner" style={{ background: "white", minHeight: '92vh' }}>

                            <div className='pt-4 text-left px-4'>
                                <div className='mb-2'>
                                    <img src={openBookLogo} width="25" className="d-inline-block align-top" /><span className='text-title ml-2' >โครงการที่เปิดให้เข้าเรียน</span>
                                </div>
                                <div style={{ justifyContent: "left", textAlign: "left", marginBlock: "auto" }}>
                                    จำนวนทั้งหมด {totalAssignProcess ?? 0} รายการ
                                </div>
                                <StudentCourseList item={assignmentProcess as any[]} />
                                {totalAssignProcess > 2 && (
                                    <div className='d-flex justify-content-end'> <a className='link-black' onClick={() => seeMore('process')}>แสดงทั้งหมด &gt;&gt;</a></div>
                                )}

                            </div>


                            <div className='pt-4 text-left px-4'>
                                <div className='mb-2'>
                                    <img src={clockLogo} width="25" className="d-inline-block align-top" /><span className='text-title ml-2' >โครงการที่กำลังมาถึง </span>
                                </div>
                                <div style={{ justifyContent: "left", textAlign: "left", marginBlock: "auto" }}>
                                    จำนวนทั้งหมด {totalAssignComing ?? 0} รายการ
                                </div>
                                <StudentCourseList item={assignmentComing as any[]} />
                                {totalAssignComing > 2 && (
                                    <div className='d-flex justify-content-end'> <a className='link-black' onClick={() => seeMore('coming')}>แสดงทั้งหมด &gt;&gt;</a></div>
                                )}

                            </div>
                                <div className='pt-4 text-left px-4'>
                                    <div className='mb-2'>
                                        <img src={warrantyLogo} width="25" className="d-inline-block align-top" /><span className='text-title ml-2' >โครงการที่เรียนผ่านไปแล้ว</span>
                                    </div>
                                    <div style={{ justifyContent: "left", textAlign: "left", marginBlock: "auto" }}>
                                        จำนวนทั้งหมด {totalAssignCompleted ?? 0} รายการ
                                    </div>
                                    <StudentCourseList item={assignmentCompleted as any[]} />
                                    {totalAssignCompleted > 2 && (
                                        <div className='d-flex justify-content-end'> <a className='link-black' onClick={() => seeMore('completed')}>แสดงทั้งหมด &gt;&gt;</a></div>
                                    )}

                                    <br />
                                </div>
                            

                        </div>
                    </>}
            </div>

        </div>
        </div>
    )
}

export default StudentCourse