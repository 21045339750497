import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import iconTrashRed from '../../../../img/iconTrashRed.png'
import iconEdit from '../../../../img/iconEdit.png'
import loadinglogo from '../../../../img/loading.gif';
import Select from 'react-select';
import React from "react";
import { useState } from "react";
import { ListPartnerMediaResponse, PartnerMediaResponse, SearchPartnerMedia } from "../../../../model/response/partner-activity-media";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { TableHeaderBlack } from "../../../../style/tableStyle";
import { CustomPage } from "../../../share/CustomPage";
import { BasicResponse } from "../../../../api/define";

type Props = {
    category: BasicResponse[],
    mediaType: BasicResponse[],
    department: BasicResponse[],
    list: ListPartnerMediaResponse | undefined,
    loading: boolean,
    onSearch: (item: SearchPartnerMedia) => void
    onAdd: () => void
    onEdit: (item: PartnerMediaResponse) => void
    onDelete: (partnerMediaId: number) => void
}

const PartnerActivityReceiveMediaManagement = (props: Props) => {
    const [expand, setExpand] = useState(true);
    const [page, setPage] = useState<number>(0);
    const [size, setSize] = useState<number>(10);
    const [partnerMediaName, setPartnerMediaName] = useState<string>("");
    const [partnerMediaType, setPartnerMediaType] = useState<BasicResponse | null>();
    const [partnerMediaCategory, setPartnerMediaCategory] = useState<BasicResponse | null>();

    const addElement: any = () => {
        return <>
            <a onClick={props.onAdd} style={{ cursor: 'pointer', fontWeight: 600 }}><AddCircleOutlineIcon className="yellow mr-2" />เพิ่มข้อมูลการรับสื่อ</a>
        </>
    }

    const onPageChange = (value: number) => {
        setPage(value);
        onSearch();
    }
    const onPageSizeChange = (value: number) => {
        setPage(0);
        setSize(value);
        onSearch();
    }

    const onClear = () => {
        setPartnerMediaCategory(null);
        setPartnerMediaName("");
        setPartnerMediaType(null);
    }

    const onSearch = () => {
        props.onSearch({ page, size, name: partnerMediaName, categoryId: partnerMediaCategory?.value, mediaType: partnerMediaType?.value });
    }

    const convertDeptIdToName = (id?: string): string => {
        const item = props.department.find(f => f.value === id);
        return item ? item.label : '-';
    }

    const convertMediaTypeToName = (id?: string): string => {
        const item = props.mediaType.find(f => f.value === id);
        return item ? item.label : '-';
    }

    return (<React.Fragment>
        <div style={{ background: "white" }} className='mt-2'>
            <div className="text-left" style={{ position: "relative", marginBottom: 5 }}>
                <div className={'d-flex yellow-button'} style={{ padding: "15px", borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}>
                    {((expand === undefined) || (expand === false)) &&
                        <div onClick={(_e) => setExpand(true)}><ExpandMore className="pointer mr-2" ></ExpandMore></div>
                    }
                    {(expand === true) &&
                        <div onClick={(_e) => setExpand(false)}><ExpandLess className="pointer mr-2" ></ExpandLess></div>
                    }
                    <label className='ml-2 my-auto'><span style={{ fontWeight: 600, marginRight: 10 }}>ข้อมูลการรับสื่อ</span></label>
                </div>
                {(expand === true) && (<div style={{ padding: "15px", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px", border: "3px solid #fab232" }}>
                    <Card className="mb-3">
                        <Card.Body>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="2">
                                    ประเภทความรู้
                                </Form.Label>
                                <Col sm="4">
                                    <Select
                                        isClearable={true}
                                        isSearchable={true}
                                        isMulti={false}
                                        onChange={(e: any) => setPartnerMediaCategory(e)}
                                        options={props.category}
                                        value={partnerMediaCategory}
                                    />
                                </Col>
                                <Form.Label column sm="2">
                                    ชื่อสื่อความรู้
                                </Form.Label>
                                <Col sm="4">
                                    <Form.Control className="form-control" type="text" value={partnerMediaName} onChange={(e: any) => setPartnerMediaName(e == null ? null : e.target.value)} />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="2">
                                    ประเภทสื่อ
                                </Form.Label>
                                <Col sm="4">
                                    <Select
                                        isClearable={true}
                                        isSearchable={true}
                                        isMulti={false}
                                        onChange={(e: any) => setPartnerMediaType(e)}
                                        options={props.mediaType}
                                        value={partnerMediaType}
                                    />
                                </Col>
                                <Col sm="2"></Col>
                                <Col sm="4">
                                    <Button variant="secondary" className="px-5" onClick={onClear}>Clear</Button>
                                    <Button variant="warning" className="ml-1 yellow-button px-5" onClick={onSearch} disabled={props.loading}>
                                        {(props.loading) &&
                                            <div style={{ textAlign: "center" }}><img src={loadinglogo} width="30" height="30" className="d-inline-block align-top" alt="" /></div>
                                        }
                                        {(!props.loading) &&
                                            <>Search</>
                                        }
                                    </Button>
                                </Col>
                            </Form.Group>
                        </Card.Body>
                    </Card>
                    {addElement()}

                    {(!props.list || props.list?.totalItem === 0) && (<div className="row py-2">
                        <label className="col">ไม่พบข้อมูลการรับสื่อ</label>
                    </div>)}
                    {props.list && props.list?.totalItem > 0 && (<>
                        <CustomPage current={page} pageSize={size} total={props.list?.totalItem || 0} onPageChange={onPageChange} onPageSizeChange={onPageSizeChange} loading={props.loading} postElement={<></>} >
                            <TableHeaderBlack striped hover className='mt-2' key={'partner-media-table'}>
                                <thead key={'partner-media-table-header'}>
                                    <tr>
                                        <th className='text-left' style={{ verticalAlign: "top", width: "20%" }}>
                                            ประเภทความรู้/หมวดหมู่ความรู้
                                        </th>
                                        <th className='text-left' style={{ verticalAlign: "top", width: "20%" }}>
                                            ประเภทสื่อ/ฝ่ายงานเจ้าของสื่อ
                                        </th>
                                        <th className='text-left' style={{ verticalAlign: "top", width: "20%" }}>
                                            ชื่อสื่อความรู้
                                        </th>
                                        <th className='text-right' style={{ verticalAlign: "top", width: "5%" }}>
                                            จำนวน
                                        </th>
                                        <th className='text-center' style={{ verticalAlign: "top", width: "10%" }}>
                                            วันที่ได้รับสื่อ
                                        </th>
                                        <th className='text-left' style={{ verticalAlign: "top", width: "17%" }}>
                                            แก้ไขล่าสุด
                                        </th>
                                        <th className='text-center' style={{ verticalAlign: "top", width: "8%" }}>

                                        </th>
                                    </tr>
                                </thead>
                                <tbody key={'partner-media-table-body'}>
                                    {props.list?.partnerMediaResponseList.map((value, index) => {
                                        return (<>
                                            <tr key={'partner-media-table-content' + index}>
                                                <td>{value.categoryName} <br />
                                                    {value.subCategoryName}</td>
                                                <td>{convertMediaTypeToName(value.fileType)} <br />
                                                    {convertDeptIdToName(value.organizerDeptId)}</td>
                                                <td>{value.name}</td>
                                                <td className='text-right'>{value.amount}</td>
                                                <td className='text-center'>{value.receivedDateString}</td>
                                                <td className='text-left'>{value.updateByName} <br /> {value.updateDate}
                                                </td>
                                                <td className='text-center'>
                                                    <img src={iconEdit} width={20} height={20} id={"icon_edit_media"} style={{ cursor: 'pointer' }} onClick={_e => { props.onEdit(value) }} className='mr-2' />
                                                    <img src={iconTrashRed} width={20} height={20} id={"icon_trash_media"} style={{ cursor: 'pointer' }} onClick={_e => { props.onDelete(value.partnerMediaId) }} className='mr-2' />
                                                </td>
                                            </tr>
                                        </>)
                                    })}
                                </tbody>
                            </TableHeaderBlack>
                        </CustomPage>
                        <br />
                    </>)}
                </div>)}
            </div>
        </div>
    </React.Fragment>)
}

export default PartnerActivityReceiveMediaManagement;