import React, { useState } from 'react'
import ExpandMore from '@material-ui/icons/ExpandMore'
import ExpandLess from '@material-ui/icons/ExpandLess'
import { Table } from 'react-bootstrap'
import { Util } from '../../../../api/util'
import { PackageInteresting } from '../../../../model/response/partner-package'
import iconNote from '../../../../img/iconNote.png'
import CircleIcon from '@material-ui/icons/Lens';
type Props = {
    items: PackageInteresting[];
    openPartnerPackageModal: (item: PackageInteresting) => void;
}

const PartnerPackageInteresting = ({ items,openPartnerPackageModal }: Props) => {
    const [expand, setExpand] = useState(true);
    const openKnowledge = (item: PackageInteresting) => {
        openPartnerPackageModal(item);
    }
    return (
        <div>
            <div style={{ background: "white" }} className='mt-2'>
                <div className="text-left" style={{ position: "relative", marginBottom: 5 }}>
                    <div className={'d-flex yellow-button'} style={{ padding: "15px", borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}>
                        {((expand === undefined) || (expand === false)) &&
                            <div onClick={(e) => setExpand(true)}><ExpandMore className="pointer mr-2" ></ExpandMore></div>
                        }
                        {(expand === true) &&
                            <div onClick={(e) => setExpand(false)}><ExpandLess className="pointer mr-2" ></ExpandLess></div>
                        }
                        <label className='ml-2 my-auto'><span style={{ fontWeight: 600, marginRight: 10 }}>ข้อมูลความสนใจ</span></label>
                    </div>
                    {(expand === true) && (
                        <div style={{ padding: "15px", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px", border: "3px solid #fab232" }}>
                            {((items !== null) && (items.length >= 1)) && <>
                                <Table striped hover>
                                    <thead className="black-button">
                                        <tr>
                                            <th style={{ width: '20%' }}>วันที่ให้ข้อมูล</th>
                                            <th className='text-left' style={{ width: '70%' }}>ประเภทความรู้</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ fontSize: "22px" }}>
                                        {items.map((item, index) => (
                                            <React.Fragment key={'partner_package-' + index}>
                                                <tr>
                                                    <td>{Util.datetimetostr(new Date(item.registrationDate))}</td>
                                                    <td>
                                                        {item.interestedCategory && item.interestedCategory.map((x,jIndex) => (
                                                            <div key={"interestedCategory" + jIndex}>
                                                                <CircleIcon fontSize="small" style={{ color: '#628295',fontSize:'12px' }} className='mr-1'/>
                                                                {x}
                                                            </div>
                                                        ))}
                                                    </td>
                                                    <td>
                                                        <img src={iconNote} onClick={(e) => openKnowledge(item)} width={20} height={20} id={"icon_note_calculators" + index} style={{ cursor: "pointer" }} className='mr-2' />
                                                    </td>
                                                </tr>
                                            </React.Fragment>))}
                                            
                                    </tbody>
                                </Table>
                            </>}
                            {(!items || items.length == 0) && <span>ไม่พบข้อมูลความสนใจ</span> }
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default PartnerPackageInteresting