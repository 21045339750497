import { AuthActionType } from './types'
import { Dispatch } from 'redux';
import { AuthAPI } from '../../api/auth-api';
import { TIME_OUT_LABEL } from '../../api/label';
import { LoginType } from '../../api/define';
import { store } from '../store';

export const getToken = (code: string, sessionState: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: AuthActionType.LOGIN_LOADING,
    });
    AuthAPI.getToken(code, sessionState).then(function (response) {
        response.data.usertype=LoginType.ADMIN;
        dispatch({
            type: AuthActionType.LOGIN_SUCCESS,
            payload: response.data
        });
    }, function (error) {
        dispatch({
            type: AuthActionType.LOGIN_FAIL,
            payload: (error.response!=null)?error.response.data.msg:TIME_OUT_LABEL 
        });
    })
};

export const logout = () => (dispatch: Dispatch<any>) => {
    dispatch({
        type: AuthActionType.LOGOUT,
    });
};

export const studentGetToken= (token:string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: AuthActionType.LOGIN_LOADING,
    });
    AuthAPI.studentGetToken(token).then(function (response) {
        response.data.usertype=response.data.userType??LoginType.STUDENT;
        dispatch({
            type: AuthActionType.LOGIN_SUCCESS,
            payload: response.data
        });
    }, function (error) {
        dispatch({
            type: AuthActionType.LOGIN_FAIL,
            payload: (error.response!=null)?error.response.data.msg:TIME_OUT_LABEL 
        });
    })
};

export const studentLogout = () => (dispatch: Dispatch<any>) => {
    var access_token=store.getState().AuthReducer.token.access_token;
    const refresh_token=store.getState().AuthReducer.token.refresh_token;
    AuthAPI.studentLogout(refresh_token).then(function (response) {
        dispatch({
            type: AuthActionType.LOGOUT
        });
    }, function (error) {
        dispatch({
            type: AuthActionType.LOGOUT
        });
    })
};